import React, { Fragment, useEffect, useState, useRef } from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import ReactDOM from "react-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  Container,
  Grid,
  GridList,
  GridListTile,
  Paper,
  LinearProgress,
  Typography,
  Select,
  MenuItem,
  Badge,
  FormControl,
  FormLabel ,
  FormControlLabel ,
  RadioGroup ,
  Radio ,
  Modal
} from "@material-ui/core";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { webStore } from "../../stores/webStore";
import { observer } from "mobx-react-lite";
import CloseIcon from "@material-ui/icons/Close";

import { useHistory } from "react-router-dom";

import { dateStr, dateStrAPI,dateString,dateStringFullMothName } from "../../utils";

import { SubBar } from "../Dashboard/DashboardSI";

import LuxonUtils from "@date-io/luxon";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import {
  withStyles,
  Theme,
  makeStyles
} from '@material-ui/core/styles';
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import { HTML5_FMT } from "moment";
import DatePicker from 'sassy-datepicker';
import TodayIcon from '@material-ui/icons/Today';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CachedIcon from '@material-ui/icons/Cached';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { columnsTotalWidthSelector } from "@material-ui/data-grid";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useSnackbar } from "notistack";
import { CreateCalendar,GetCalenderByDoctor } from "../../services/api/telemedicine/BookingService";

const CustomButton = withStyles((theme: Theme) => ({
  root: {
    // color: theme.palette.getContrastText('#3f51b5'),
    backgroundColor: '#fff',
    width: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: '1px solid #e1dfdf',
    padding: '20px 60px ', 
    color: 'black', 
    fontSize: '16px',
    '&.Mui-selected' :{
      backgroundColor: '#0687f53d',
      border: '1px solid #4684e2',
    },
    '&.Mui-selected:hover':{
      backgroundColor: '#0687f53d'
    },
    '&.MuiToggleButtonGroup-groupedHorizontal' : {
      borderLeft: '1px solid #e1dfdf'
    },
    '&.Mui-disabled' : {
      color: '#00000070'
    }
  },
  toggle: {
    width:50,
   '& .Mui-checked': {
      color: '#109125',
      transform:'translateX(25px) !important'
  }
}
}))(ToggleButton);

const CalendarBookingModal = observer(
  ({
    isCalenderBooking,
    label,
    note,
    dateArray,
    onClick,
    setIsCalenderBooking,
    setCalenderBookingValue,
    buttonName = "Confirm",
    startDate, 
    setStartDate, 
    endDate,
    setEndDate
  }: {
    isCalenderBooking: boolean;
    setIsCalenderBooking: any;
    label: string;
    note?: any;
    dateArray: any;
    onClick?: any;
    type?: string;
    setCalenderBookingValue?: any;
    buttonName?: string;
    startDate: Date;
    setStartDate: any;
    endDate: Date;
    setEndDate: any;
  }) => {

    const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setCalenderBookingValue({note: event.target.value as string});
    };
    const [value, setValue] = React.useState('0');
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue((event.target as HTMLInputElement).value);
      let value = parseInt(event.target.value);
      let startDateValue = new Date(startDate);
      let calculateEndDate;
      if(value === 1) {
        // if (startDateValue.getMonth() == 11) {
        //   calculateEndDate = new Date(startDateValue.getFullYear() + 1, 0, 1);
        // } else {
        //   calculateEndDate = new Date(startDateValue.getFullYear(), startDateValue.getMonth() + 1, 1);
        // }
        calculateEndDate = new Date(startDateValue.setMonth(startDateValue.getMonth() + 1));
      }else{
        calculateEndDate = addDays(startDateValue , value);
      }
      console.log("calclate end Date:", calculateEndDate);
      setEndDate(calculateEndDate ? new Date(calculateEndDate.toString()) : null);
    };
    const addDays = (dateObj : any, numDays : number) => {
      if(numDays === 0 ){
        dateObj.setDate(dateObj.getDate() + numDays);
      }else{
        dateObj.setDate(dateObj.getDate() + numDays - 1);
      }
      
      return dateObj;
    }

    console.log('date array', dateArray)
    return (
      <Modal
        open={isCalenderBooking}
        onClose={() => {}}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          css={{ backgroundColor: "#fff", width: 500, borderRadius: 30, border: 'solid 2px #3f51b5' }}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
            mt={1}
          >
            <Box css={{ fontWeight: 600 }}>
              <h3>{label}</h3> 
            </Box>
            <Box
              onClick={() => {
                setIsCalenderBooking(false);
                setCalenderBookingValue({note: ""});
              }}
            >
              <CloseIcon css={{ cursor: "pointer" }} />
            </Box>
          </Box>
          <br/>
          <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={LuxonUtils}>
                      <KeyboardDatePicker
                          style={{
                          margin: 0,
                          width: "100%",
                          backgroundColor: "#fff",
                          }}
                          
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="from"
                          inputVariant="outlined"
                          label="Start Date"
                          autoOk={true}
                          value={startDate}
                          onChange={(date) => {
                          setStartDate(date ? new Date(date.toString()) : null);
                          // onSearch();
                          }}
                          KeyboardButtonProps={{
                          "aria-label": "change date",
                          }}
                      />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={LuxonUtils}>
                      <KeyboardDatePicker
                          style={{
                          margin: 0,
                          width: "100%",
                          backgroundColor: "#fff",
                          }}
                          
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="from"
                          inputVariant="outlined"
                          label="End Date"
                          autoOk={true}
                          value={endDate}
                          onChange={(date) => {
                          setEndDate(date ? new Date(date.toString()) : null);
                          // onSearch();
                          }}
                          KeyboardButtonProps={{
                          "aria-label": "change date",
                          }}
                      />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
          </Box>
          <br/>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
            mt={1}
          >
            <Box css={{ fontWeight: 600 }}>
              <h3>Note:</h3> 
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
            margin="dense"
            >
            <TextareaAutosize  name="remark-label" placeholder="Please Enter Note"
             rowsMin={3} value={note} onChange={handleOnChange} 
             style={{border:'solid 1px', borderColor:'#c4c4c4', padding: "10px", borderRadius: '10px'}}
             />
            </FormControl>
            
          </Box>
          <br/>
          <Box>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <CachedIcon/> <span style={{fontWeight: 600}}>Repeat:</span> 
                </Grid>
                <Grid item xs={12}>
                <FormControl component="fieldset" style={{display :'felx'}}>
                  <RadioGroup row aria-label="repeat" name="repeat" value={value} onChange={handleChange}>
                    <FormControlLabel value="0" control={<Radio color="primary" />} label="Never" />
                    <FormControlLabel value="3" control={<Radio color="primary" />} label="3 days" />
                    <FormControlLabel value="7" control={<Radio color="primary" />} label="1 week" />
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="1 month" />
                  </RadioGroup>
                </FormControl>
                </Grid>
              </Grid>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            <Button
              onClick={onClick}
              color="primary"
              size="medium"
              variant="contained"
              style={{marginRight: '3%'}}
            >
              {buttonName}
            </Button>

            <Button
              onClick={() =>{
                setIsCalenderBooking(false);
                setCalenderBookingValue({note: ""});
              }}
              size="medium"
              variant="contained"
            >
              Cancel
            </Button>
          </Box>
          <br/>
          
        </Box>
      </Modal>
    );
  }
);

export const Booking  = observer(() => {
  const [startDate, setStartDate] = useState<Date>(
        webStore.startDate || new Date()
      );
  const [endDate, setEndDate ] = useState<Date>(
    webStore.endDate || new Date()
  );
  const [chooseDate, setChooseDate ] = useState<Date>(new Date());
  const { enqueueSnackbar } = useSnackbar();

  const [isCalenderBooking, setIsCalenderBooking] = useState(false);
  const [ calenderBookingValue, setCalenderBookingValue ] = useState<any>();
  // const [ show]
  const [ formats, setFormats] = React.useState(() => []);
  const [ secondRowTime , setSecondRowTime ] = React.useState(() => []);
  const [ thirdRowTime , setThirdRowTime ] = React.useState(() => []);
  const [ fourthRowTime, setFourthRowTime ] = React.useState(() => []);
  const [ fifthRowTime, setFifthRowTime ] = React.useState(() => []);
  const [ sixRowTime, setSixRowTime ] = React.useState(() => []);
  const [ result, setResultArray ] = React.useState(()=> []);

  const [ firstRow_buttonOne,  set_firstRow_buttonOne ] = useState(false);
  const [ firstRow_buttonTwo,  set_firstRow_buttonTwo ] = useState(false);
  const [ firstRow_buttonThree,  set_firstRow_buttonThree ] = useState(false);
  const [ firstRow_buttonFour,  set_firstRow_buttonFour ] = useState(false);
  const [ firstRow_buttonFive,  set_firstRow_buttonFive ] = useState(false);
  const [ firstRow_buttonSix,  set_firstRow_buttonSix ] = useState(false);

  const [ secondRow_buttonOne, set_secondRow_buttonOne ] = useState(false);
  const [ secondRow_buttonTwo, set_secondRow_buttonTwo ] = useState(false);
  const [ secondRow_buttonThree, set_secondRow_buttonThree ] = useState(false);
  const [ secondRow_buttonFour, set_secondRow_buttonFour ] = useState(false);
  const [ secondRow_buttonFive, set_secondRow_buttonFive ] = useState(false);
  const [ secondRow_buttonSix, set_secondRow_buttonSix ] = useState(false);

  const [ thirdRow_buttonOne, set_thirdRow_buttonOne ] = useState(false);
  const [ thirdRow_buttonSecond, set_thirdRow_buttonSecond ] = useState(false);
  const [ thirdRow_buttonThird, set_thirdRow_buttonThird ] = useState(false);
  const [ thirdRow_buttonFourth, set_thirdRow_buttonFourth ] = useState(false);
  const [ thirdRow_buttonFifth, set_thirdRow_buttonFifth ] = useState(false);
  const [ thirdRow_buttonSixth, set_thirdRow_buttonSixth ] = useState(false);

  const[ fourthRow_butonOne, set_fourthRowOne ] = useState(false);
  const[ fourthRow_butonTwo, set_fourthRowTwo ] = useState(false);
  const[ fourthRow_butonThird, set_fourthRowThird ] = useState(false);
  const[ fourthRow_butonFourth, set_fourthRowFourth ] = useState(false);
  const[ fourthRow_butonFifth, set_fourthRowFifth ] = useState(false);
  const[ fourthRow_butonSixth, set_fourthRowSixth ] = useState(false);

  const[ fifthRow_buttonOne, set_fifthRowOne ] = useState(false);
  const[ fifthRow_buttonTwo, set_fifthRowTwo ] = useState(false);
  const[ fifthRow_buttonThree, set_fifthRowThree ] = useState(false);
  const[ fifthRow_buttonFourth, set_fifthRowFourth ] = useState(false);
  const[ fifthRow_buttonFifth, set_fifthRowFifth ] = useState(false);
  const[ fifthRow_buttonSixth, set_fifthRowSixth ] = useState(false);

  const[ sixthRow_buttonOne, set_sixthRowOne ] = useState(false);
  const[ sixthRow_buttonTwo, set_sixthRowTwo ] = useState(false);
  const[ sixthRow_buttonThree, set_sixthRowThree ] = useState(false);
  const[ sixthRow_buttonFourth, set_sixthRowFourth ] = useState(false);
  const[ sixthRow_buttonFifth, set_sixthRowFifth ] = useState(false);
  const[ sixthRow_buttonSixth, set_sixthRowSixth ] = useState(false);

  const [ showUpdateButton, set_showUpdateButton ] = useState(false);

  
  const {fire_create_calendar, loading_create_calendar, error_create_calendar} = CreateCalendar();
  const { result_CalendarData, firstRow, secondRow, thirdRow, fourthRow, fifthRow, sixthRow, allBookingTimeData, loading_CalendarData } = GetCalenderByDoctor(chooseDate);

  const handleFormat = (event: React.MouseEvent<HTMLElement>, newFormats: []) => {
    setFormats(newFormats);
  };

  const handleSecondRowTime = (event: React.MouseEvent<HTMLElement>, newFormats: []) => {
    setSecondRowTime(newFormats);
  };

  const handleThirdRowTime = (event: React.MouseEvent<HTMLElement>, newFormats: []) => {
    setThirdRowTime(newFormats);
  };

  const handleFourthRowTime = (event: React.MouseEvent<HTMLElement>, newFormats: []) => {
    setFourthRowTime(newFormats);
  };

  const handleFifthRowTime = (event: React.MouseEvent<HTMLElement>, newFormats: []) => {
    setFifthRowTime(newFormats);
  };

  const handleSixRowTime = (event: React.MouseEvent<HTMLElement>, newFormats: []) => {
    setSixRowTime(newFormats);
  };

  const history = useHistory();

  const materialTheme = createMuiTheme({
    overrides: {
        MuiToolbar : {
        },
        MuiButton: {
          // Name of the rule
          text: {
            // Some CSS
            color: 'white',
          },
        },
      },
    });

  useEffect(() => {
    setFormats(firstRow);
    setSecondRowTime(secondRow);
    setThirdRowTime(thirdRow);
    setFourthRowTime(fourthRow);
    setFifthRowTime(fifthRow);
    setSixRowTime(sixthRow);
    for(let i = 0 ; i< firstRow.length; i ++){
      if(firstRow[i] === '07:00-07:15'){
        set_firstRow_buttonOne(true);
      }else if(firstRow[i] === '07:15-07:30'){
        set_firstRow_buttonTwo(true);
      }else if(firstRow[i] === '07:30-07:45'){
        set_firstRow_buttonThree(true);
      }else if(firstRow[i] === '07:45-08:00'){
        set_firstRow_buttonFour(true);
      }else if(firstRow[i] === '08:00-08:15'){
        set_firstRow_buttonFive(true);
      }else if(firstRow[i] === '08:15-08:30'){
        set_firstRow_buttonSix(true);
      }
    }

    for(let i= 0 ; i< secondRow.length; i++){
      if(secondRow[i] === '08:30-08:45'){
        set_secondRow_buttonOne(true);
      }else if(secondRow[i] === '08:45-09:00'){
        set_secondRow_buttonTwo(true);
      }else if(secondRow[i] === '09:00-09:15'){
        set_secondRow_buttonThree(true);
      }else if(secondRow[i] === '09:15-09:30'){
        set_secondRow_buttonFour(true);
      }else if(secondRow[i] === '09:30-09:45'){
        set_secondRow_buttonFive(true);
      }else if(secondRow[i] === '09:45-10:00'){
        set_secondRow_buttonSix(true);
      }
    }

    for(let i= 0 ; i< thirdRow.length; i++){
      if(thirdRow[i] === '10:00-10:15'){
        set_thirdRow_buttonOne(true);
      }else if(thirdRow[i] === '10:15-10:30'){
        set_thirdRow_buttonSecond(true);
      }else if(thirdRow[i] === '10:30-10:45'){
        set_thirdRow_buttonThird(true);
      }else if(thirdRow[i] === '10:45-11:00'){
        set_thirdRow_buttonFourth(true);
      }else if(thirdRow[i] === '11:00-11:15'){
        set_thirdRow_buttonFifth(true);
      }else if(thirdRow[i] === '11:15-11:30'){
        set_thirdRow_buttonSixth(true);
      }
    }

    for(let i= 0 ; i< fourthRow.length; i++){
      if(fourthRow[i] === '11:30-11:45'){
        set_fourthRowOne(true);
      }else if(fourthRow[i] === '11:45-12:00'){
        set_fourthRowTwo(true);
      }else if(fourthRow[i] === '12:00-12:15'){
        set_fourthRowThird(true);
      }else if(fourthRow[i] === '12:15-12:30'){
        set_fourthRowFourth(true);
      }else if(fourthRow[i] === '12:30-12:45'){
        set_fourthRowFifth(true);
      }else if(fourthRow[i] === '12:45-13:00'){
        set_fourthRowSixth(true);
      }
    }

    for(let i= 0 ; i< fifthRow.length; i++){
      if(fifthRow[i] === '13:00-13:15'){
        set_fifthRowOne(true);
      }else if(fifthRow[i] === '13:15-13:30'){
        set_fifthRowTwo(true);
      }else if(fifthRow[i] === '13:30-13:45'){
        set_fifthRowThree(true);
      }else if(fifthRow[i] === '13:45-14:00'){
        set_fifthRowFourth(true);
      }else if(fifthRow[i] === '14:00-14:15'){
        set_fifthRowFifth(true);
      }else if(fifthRow[i] === '14:15-14:30'){
        set_fifthRowSixth(true);
      }
    }

    for(let i= 0 ; i< sixthRow.length; i++){
      if(sixthRow[i] === '14:30-14:45'){
        set_sixthRowOne(true);
      }else if(sixthRow[i] === '14:45-15:00'){
        set_sixthRowTwo(true);
      }else if(sixthRow[i] === '15:00-15:15'){
        set_sixthRowThree(true);
      }else if(sixthRow[i] === '15:15-15:30'){
        set_sixthRowFourth(true);
      }else if(sixthRow[i] === '15:30-15:45'){
        set_sixthRowFifth(true);
      }else if(sixthRow[i] === '15:45-16:00'){
        set_sixthRowSixth(true);
      }
    }

  }, [result_CalendarData]);

  const onChange = (date: any) => {
    console.log(date.toString());
    setChooseDate(date);
    setFormats([]);
    setSecondRowTime([]);
    setThirdRowTime([]);
    setFourthRowTime([]);
    setFifthRowTime([]);
    setSixRowTime([]);
    set_firstRow_buttonOne(false);
    set_firstRow_buttonTwo(false);
    set_firstRow_buttonThree(false);
    set_firstRow_buttonFour(false);
    set_firstRow_buttonFive(false);
    set_firstRow_buttonSix(false);
    set_secondRow_buttonOne(false);
    set_secondRow_buttonTwo(false);
    set_secondRow_buttonThree(false);
    set_secondRow_buttonFour(false);
    set_secondRow_buttonFive(false);
    set_secondRow_buttonSix(false);
    set_thirdRow_buttonOne(false);
    set_thirdRow_buttonSecond(false);
    set_thirdRow_buttonThird(false);
    set_thirdRow_buttonFourth(false);
    set_thirdRow_buttonFifth(false);
    set_thirdRow_buttonSixth(false);
    set_fourthRowOne(false);
    set_fourthRowTwo(false);
    set_fourthRowThird(false);
    set_fourthRowFourth(false);
    set_fourthRowFifth(false);
    set_fourthRowSixth(false);
    set_fifthRowOne(false);
    set_fifthRowTwo(false);
    set_fifthRowThree(false);
    set_fifthRowFourth(false);
    set_fifthRowFifth(false);
    set_fifthRowSixth(false);
    set_sixthRowOne(false);
    set_sixthRowTwo(false);
    set_sixthRowThree(false);
    set_sixthRowFifth(false);
    set_sixthRowFourth(false);
    set_sixthRowSixth(false);

  };
  
  return (
    <>
        <Container maxWidth="xl" style={{ maxWidth: 1500 }} className="py-4 pb-16" >
            <SubBar title={"Calendar Booking"} back={true} reload={false}/>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    {/* <Calendar date={new Date} onChange={(date) => {setStartDate(date ? new Date(date.toString()) : null)}}/> */}
                    <Box fontSize="h6.fontSize" fontWeight={500}>
                        Select Month <DateRangeIcon style={{fontSize: '25px'}}/>
                    </Box>
                    <br/>
                    <DatePicker onChange={onChange} style={{minWidth: "100%", minHeight: '50%'}} />
                    <br/>
                    <Box 
                        style={{
                            width: "100%",
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 5
                        }}
                    >
                        {/* <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <DatePicker
                                style={{}}
                                disableToolbar
                                value={startDate}
                                onChange={(date) => {
                                    setStartDate(date ? new Date(date.toString()) : null);
                                }}
                                variant="static"
                            />
                        </MuiPickersUtilsProvider> */}
                        {
                          showUpdateButton ?
                          <Button variant="contained" color="primary"
                            // onClick={()=> {
                            //   setResultArray([...formats, ...secondRowTime, ...thirdRowTime, ...fourthRowTime, ...fifthRowTime, ...sixRowTime] as any);
                            //   setIsCalenderBooking(true);
                            // }}
                            > Update Calendar  </Button>
                          :
                          <Button variant="contained" color="primary"
                          onClick={()=> {
                            setResultArray([...formats, ...secondRowTime, ...thirdRowTime, ...fourthRowTime, ...fifthRowTime, ...sixRowTime] as any);
                            setIsCalenderBooking(true);
                          }}> Create Calendar  </Button>
                        }
                        
                    </Box>
                    <CalendarBookingModal
                      {...calenderBookingValue}
                      isCalenderBooking={isCalenderBooking}
                      setIsCalenderBooking={setIsCalenderBooking}
                      setCalenderBookingValue={setCalenderBookingValue}
                      startDate = {startDate}
                      setStartDate = {setStartDate}
                      endDate = {endDate}
                      setEndDate = {setEndDate}
                      dateArray={result}
                      label = {"Telemedicine"}
                      onClick={async () => {
                        try {
                          fire_create_calendar(result, startDate, endDate, allBookingTimeData)
                            .then(() => {
                              enqueueSnackbar("Success", {
                                variant: "success",
                                autoHideDuration: 3000,
                                anchorOrigin: {
                                  vertical: "top",
                                  horizontal: "center",
                                },
                              });
                              setIsCalenderBooking(false);
                            })
                            .catch((ex) => {
                              enqueueSnackbar(
                                "Error" +
                                  (ex ? " : " + ex.toString() : ""),
                                {
                                  variant: "error",
                                  autoHideDuration: 3000,
                                  anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                  },
                                }
                              );
                              setIsCalenderBooking(false);
                            });
                        } catch (ex) {}
                      }}/>
                </Grid>

                <Grid item xs={12} md={8}>
                    {/* <Calendar date={new Date} onChange={(date) => {setStartDate(date ? new Date(date.toString()) : null)}}/> */}
                    <Box fontSize="h6.fontSize" fontWeight={500}>
                        Select Time <AccessTimeIcon style={{fontSize: '25px'}}/>
                        {
                          showUpdateButton ? 
                            <Button
                            // color="primary"
                            onClick={() => {
                              setFormats(firstRow);
                              setSecondRowTime(secondRow);
                              setThirdRowTime(thirdRow);
                              setFourthRowTime(fourthRow);
                              setFifthRowTime(fifthRow);
                              setSixRowTime(sixthRow);
                              for(let i = 0 ; i< firstRow.length; i ++){
                                if(firstRow[i] === '07:00-07:15'){
                                  set_firstRow_buttonOne(true);
                                }else if(firstRow[i] === '07:15-07:30'){
                                  set_firstRow_buttonTwo(true);
                                }else if(firstRow[i] === '07:30-07:45'){
                                  set_firstRow_buttonThree(true);
                                }else if(firstRow[i] === '07:45-08:00'){
                                  set_firstRow_buttonFour(true);
                                }else if(firstRow[i] === '08:00-08:15'){
                                  set_firstRow_buttonFive(true);
                                }else if(firstRow[i] === '08:15-08:30'){
                                  set_firstRow_buttonSix(true);
                                }
                              }

                              for(let i= 0 ; i< secondRow.length; i++){
                                if(secondRow[i] === '08:30-08:45'){
                                  set_secondRow_buttonOne(true);
                                }else if(secondRow[i] === '08:45-09:00'){
                                  set_secondRow_buttonTwo(true);
                                }else if(secondRow[i] === '09:00-09:15'){
                                  set_secondRow_buttonThree(true);
                                }else if(secondRow[i] === '09:15-09:30'){
                                  set_secondRow_buttonFour(true);
                                }else if(secondRow[i] === '09:30-09:45'){
                                  set_secondRow_buttonFive(true);
                                }else if(secondRow[i] === '09:45-10:00'){
                                  set_secondRow_buttonSix(true);
                                }
                              }

                              for(let i= 0 ; i< thirdRow.length; i++){
                                if(thirdRow[i] === '10:00-10:15'){
                                  set_thirdRow_buttonOne(true);
                                }else if(thirdRow[i] === '10:15-10:30'){
                                  set_thirdRow_buttonSecond(true);
                                }else if(thirdRow[i] === '10:30-10:45'){
                                  set_thirdRow_buttonThird(true);
                                }else if(thirdRow[i] === '10:45-11:00'){
                                  set_thirdRow_buttonFourth(true);
                                }else if(thirdRow[i] === '11:00-11:15'){
                                  set_thirdRow_buttonFifth(true);
                                }else if(thirdRow[i] === '11:15-11:30'){
                                  set_thirdRow_buttonSixth(true);
                                }
                              }

                              for(let i= 0 ; i< fourthRow.length; i++){
                                if(fourthRow[i] === '11:30-11:45'){
                                  set_fourthRowOne(true);
                                }else if(fourthRow[i] === '11:45-12:00'){
                                  set_fourthRowTwo(true);
                                }else if(fourthRow[i] === '12:00-12:15'){
                                  set_fourthRowThird(true);
                                }else if(fourthRow[i] === '12:15-12:30'){
                                  set_fourthRowFourth(true);
                                }else if(fourthRow[i] === '12:30-12:45'){
                                  set_fourthRowFifth(true);
                                }else if(fourthRow[i] === '12:45-13:00'){
                                  set_fourthRowSixth(true);
                                }
                              }

                              for(let i= 0 ; i< fifthRow.length; i++){
                                if(fifthRow[i] === '13:00-13:15'){
                                  set_fifthRowOne(true);
                                }else if(fifthRow[i] === '13:15-13:30'){
                                  set_fifthRowTwo(true);
                                }else if(fifthRow[i] === '13:30-13:45'){
                                  set_fifthRowThree(true);
                                }else if(fifthRow[i] === '13:45-14:00'){
                                  set_fifthRowFourth(true);
                                }else if(fifthRow[i] === '14:00-14:15'){
                                  set_fifthRowFifth(true);
                                }else if(fifthRow[i] === '14:15-14:30'){
                                  set_fifthRowSixth(true);
                                }
                              }

                              for(let i= 0 ; i< sixthRow.length; i++){
                                if(sixthRow[i] === '14:30-14:45'){
                                  set_sixthRowOne(true);
                                }else if(sixthRow[i] === '14:45-15:00'){
                                  set_sixthRowTwo(true);
                                }else if(sixthRow[i] === '15:00-15:15'){
                                  set_sixthRowThree(true);
                                }else if(sixthRow[i] === '15:15-15:30'){
                                  set_sixthRowFourth(true);
                                }else if(sixthRow[i] === '15:30-15:45'){
                                  set_sixthRowFifth(true);
                                }else if(sixthRow[i] === '15:45-16:00'){
                                  set_sixthRowSixth(true);
                                }
                              }
                              set_showUpdateButton(false);

                            }}
                            startIcon={<CloseIcon />}
                            size="small"
                            variant="text"
                            style={{float: 'right'}}
                          >
                            Close Edit Mode
                          </Button>
                        :
                          <Button
                            // color="primary"
                            onClick={() => {
                              set_firstRow_buttonOne(false);
                              set_firstRow_buttonTwo(false);
                              set_firstRow_buttonThree(false);
                              set_firstRow_buttonFour(false);
                              set_firstRow_buttonFive(false);
                              set_firstRow_buttonSix(false);
                              set_secondRow_buttonOne(false);
                              set_secondRow_buttonTwo(false);
                              set_secondRow_buttonThree(false);
                              set_secondRow_buttonFour(false);
                              set_secondRow_buttonFive(false);
                              set_secondRow_buttonSix(false);
                              set_thirdRow_buttonOne(false);
                              set_thirdRow_buttonSecond(false);
                              set_thirdRow_buttonThird(false);
                              set_thirdRow_buttonFourth(false);
                              set_thirdRow_buttonFifth(false);
                              set_thirdRow_buttonSixth(false);
                              set_fourthRowOne(false);
                              set_fourthRowTwo(false);
                              set_fourthRowThird(false);
                              set_fourthRowFourth(false);
                              set_fourthRowFifth(false);
                              set_fourthRowSixth(false);
                              set_fifthRowOne(false);
                              set_fifthRowTwo(false);
                              set_fifthRowThree(false);
                              set_fifthRowFourth(false);
                              set_fifthRowFifth(false);
                              set_fifthRowSixth(false);
                              set_sixthRowOne(false);
                              set_sixthRowTwo(false);
                              set_sixthRowThree(false);
                              set_sixthRowFifth(false);
                              set_sixthRowFourth(false);
                              set_sixthRowSixth(false);
                              set_showUpdateButton(true);
                            }}
                            startIcon={<BorderColorIcon />}
                            size="small"
                            variant="text"
                            style={{float: 'right'}}
                          >
                            Edit
                          </Button>
                        }
                    </Box>
                    <br/>
                    <Box 
                        style={{
                            width: "100%",
                            borderRadius: 5,
                        }}
                    >

                        <ToggleButtonGroup value={formats} onChange={handleFormat} aria-label="text formatting"
                        >
                          <CustomButton disabled={firstRow_buttonOne} value="07:00-07:15">
                            07:00<br/> - <br/>07:15
                          </CustomButton>
                          <CustomButton disabled={firstRow_buttonTwo} value="07:15-07:30">
                            07:15 <br/> - <br/>07:30
                          </CustomButton>
                          <CustomButton disabled={firstRow_buttonThree} value="07:30-07:45">
                            07:30<br/> - <br/>07:45
                          </CustomButton>
                          <CustomButton disabled={firstRow_buttonFour} value="07:45-8:00">
                            07:45 <br/>- <br/>08:00
                          </CustomButton>
                          <CustomButton disabled={firstRow_buttonFive} value="08:00-08:15">
                            08:00 <br/> - <br/>08:15
                          </CustomButton>
                          <CustomButton disabled={firstRow_buttonSix} value="08:15-08:30">
                            08:15 <br/> - <br/>08:30
                          </CustomButton>
                        </ToggleButtonGroup>

                        <ToggleButtonGroup value={secondRowTime} onChange={handleSecondRowTime}>
                          <CustomButton disabled={secondRow_buttonOne} value="08:30-08:45">
                              08:30<br/> - <br/>08:45
                          </CustomButton>
                          <CustomButton disabled={secondRow_buttonTwo} value="08:45-09:00">
                              08:45 <br/> - <br/>09:00
                          </CustomButton>
                          <CustomButton disabled={secondRow_buttonThree} value="09:00-09:15">
                              09:00 <br/> - <br/>09:15
                          </CustomButton>
                          <CustomButton disabled={secondRow_buttonFour} value="09:15-09:30">
                              09:15 <br/> - <br/>09:30
                          </CustomButton>
                          <CustomButton disabled={secondRow_buttonFive} value="09:30-09:45">
                              09:30 <br/> - <br/>09:45
                          </CustomButton>
                          <CustomButton disabled={secondRow_buttonSix} value="09:45-10:00">
                             09:45 <br/> - <br/>10:00
                          </CustomButton>
                        </ToggleButtonGroup>

                        <ToggleButtonGroup value={thirdRowTime} onChange={handleThirdRowTime}>
                          <CustomButton disabled={thirdRow_buttonOne} value="10:00-10:15">
                          10:00 <br/> - <br/>10:15
                          </CustomButton>
                          <CustomButton disabled={thirdRow_buttonSecond} value="10:15-10:30">
                          10:15<br/> - <br/>10:30
                          </CustomButton>
                          <CustomButton disabled={thirdRow_buttonThird} value="10:30-10:45">
                          10:30<br/>- <br/>10:45
                          </CustomButton>
                          <CustomButton disabled={thirdRow_buttonFourth} value="10:45-11:00">
                          10:45<br/> - <br/>11:00
                          </CustomButton>
                          <CustomButton disabled={thirdRow_buttonFifth} value="11:00-11:15">
                          11:00<br/> - <br/>11:15
                          </CustomButton>
                          <CustomButton disabled={thirdRow_buttonSixth} value="11:15-11:30">
                          11:15<br/> - <br/>11:30
                          </CustomButton>
                        </ToggleButtonGroup>

                        <ToggleButtonGroup value={fourthRowTime} onChange={handleFourthRowTime}>
                          <CustomButton disabled={fourthRow_butonOne} value="11:30-11:45">
                          11:30<br/> - <br/>11:45
                          </CustomButton>
                          <CustomButton disabled={fourthRow_butonTwo} value="11:45-12:00">
                          11:45<br/> - <br/>12:00
                          </CustomButton>
                          <CustomButton disabled={fourthRow_butonThird} value="12:00-12:15">
                          12:00<br/> - <br/>12:15
                          </CustomButton>
                          <CustomButton disabled={fourthRow_butonFourth} value="12:15-12:30">
                          12:15<br/> - <br/>12:30
                          </CustomButton>
                          <CustomButton disabled={fourthRow_butonFifth} value="12:30-12:45">
                          12:30<br/> - <br/>12:45
                          </CustomButton>
                          <CustomButton disabled={fourthRow_butonSixth} value="12:45-13:00">
                          12:45<br/> - <br/>13:00
                          </CustomButton>
                        </ToggleButtonGroup>

                        <ToggleButtonGroup value={fifthRowTime} onChange={handleFifthRowTime}>
                          <CustomButton disabled={fifthRow_buttonOne} value="13:00-13:15">
                          13:00<br/> - <br/>13:15
                          </CustomButton>
                          <CustomButton disabled={fifthRow_buttonTwo} value="13:15-13:30">
                          13:15<br/> - <br/>13:30
                          </CustomButton>
                          <CustomButton disabled={fifthRow_buttonThree} value="13:30-13:45">
                          13:30<br/> - <br/>13:45
                          </CustomButton>
                          <CustomButton disabled={fifthRow_buttonFourth} value="13:45-14:00">
                          13:45<br/> - <br/>14:00
                          </CustomButton>
                          <CustomButton disabled={fifthRow_buttonFifth} value="14:00-14:15">
                          14:00<br/> - <br/>14:15
                          </CustomButton>
                          <CustomButton disabled={fifthRow_buttonSixth} value="14:15-14:30">
                          14:15<br/> - <br/>14:30
                          </CustomButton>
                        </ToggleButtonGroup>

                        <ToggleButtonGroup value={sixRowTime} onChange={handleSixRowTime}>
                          <CustomButton disabled={sixthRow_buttonOne} value="14:30-14:45">
                            14:30<br/> - <br/>14:45
                          </CustomButton>
                          <CustomButton disabled={sixthRow_buttonTwo} value="14:45-15:00">
                          14:45<br/> - <br/>15:00
                          </CustomButton>
                          <CustomButton disabled={sixthRow_buttonThree} value="15:00-15:15">
                          15:00<br/> - <br/>15:15
                          </CustomButton>
                          <CustomButton disabled={sixthRow_buttonFourth} value="15:15-15:30">
                          15:15<br/> - <br/>15:30
                          </CustomButton>
                          <CustomButton disabled={sixthRow_buttonFifth} value="15:30-15:45">
                          15:30<br/> - <br/>15:45
                          </CustomButton>
                          <CustomButton disabled={sixthRow_buttonSixth} value="15:45-16:00">
                          15:45<br/> - <br/>16:00
                          </CustomButton>
                        </ToggleButtonGroup>
                    </Box>
                    
                </Grid>
              </Grid>
        </Container>
          
    </>
  );
});