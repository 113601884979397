import React, { Fragment, useEffect, useState, useRef } from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import ReactDOM from "react-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  Container,
  Grid,
  GridList,
  GridListTile,
  Paper,
  LinearProgress,
  Typography,
  Select,
  MenuItem,
  Badge,
  FormControl,
  FormLabel ,
  FormControlLabel ,
  RadioGroup ,
  Radio 
} from "@material-ui/core";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { webStore } from "../../stores/webStore";
import { DataGrid, ColDef, ValueGetterParams } from "@material-ui/data-grid";

import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useReadUserByGender } from "../../services/api/dashboard_report/useReadUserByGender";

import { useReadUserByAge } from "../../services/api/dashboard_report/useReadUserByAge";
import { useReadUser } from "../../services/api/user/useReadUser";
import { useListUser } from "../../services/api/user/useListUser";
import { useReadLengthOfStay } from "../../services/api/dashboard_report/useReadLengthOfStay";
import {
  Chart,
  PieSeries,
  Title,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";

import { Animation } from "@devexpress/dx-react-chart";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";

import { useHistory } from "react-router-dom";
import { useListSOSUser } from "../../services/api/user/useListSOSUser";
import { useListSOSUserComplete } from "../../services/api/user/useListSOSUserComplete";
import { dateStr, dateStrAPI,dateString,dateStringFullMothName,dateStrFormatTime,dateStrFormatLogin } from "../../utils";
import { User, UserSOS } from "../../types/user";
import WarningIcon from "@material-ui/icons/Warning";
import { DeviceValueTab, OnlineTab } from "../Dashboard/CardUserList";
import { userDevice } from "../../types/device";
import CheckIcon from "@material-ui/icons/Check";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Pagination from "@material-ui/lab/Pagination";
import { Places } from "../../types/places";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import DoneIcon from '@material-ui/icons/Done';
import AgoraRTC from "agora-rtc-sdk-ng";
import VideocamIcon from '@material-ui/icons/Videocam';
import AppointmentJsonData from './Booking.json';
import {
    ClientConfig,
    IAgoraRTCRemoteUser,
    ICameraVideoTrack,
    IMicrophoneAudioTrack,
  } from "agora-rtc-sdk-ng";

  import {
    AgoraVideoPlayer,
    createClient,
    createMicrophoneAndCameraTracks,
  } from "agora-rtc-react";
  
import AgoraUIKit,{ layout }from 'agora-react-uikit';
import { SubBar } from "../Dashboard/DashboardSI";
import  BlueUserIcon  from '../../components/assets/BlueUserIcon.jpg';
import PersonIcon from '@material-ui/icons/Person';
import LuxonUtils from "@date-io/luxon";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { AppointmentData } from '../../types/Appointment';
import { GetAppointmentData } from "../../services/api/telemedicine/BookingService";  
import { useParams } from "react-router-dom";


export const AcceptNewAppointment  = observer(() => {
    const [startDate, setStartDate] = useState<Date | null>(
        webStore.startDate || new Date()
      );
  const history = useHistory();
  const jsonData  = AppointmentJsonData;
  const appointment_data = jsonData.data;
  const docter_info = jsonData.data[0].doctor;
  const { result_appointment, loading_appointment } = GetAppointmentData();
  console.log("result appointment: ", result_appointment);
  const { index, name }: any = useParams();
  let historyLog = [
    { 
      id: 1,
      name: 'Test TestingSurname 1'
    },
    { 
      id: 2,
      name: 'Test TestingSurname 2'
    },
    { 
      id: 3,
      name: 'Test TestingSurname 3'
    },
    { 
      id: 4,
      name: 'Test TestingSurname 4'
    },
    { 
      id: 5,
      name: 'Test TestingSurname 5'
    }
  ];

  useEffect(() => {
  }, []);

  return (
    <>
          <SubBar title={"New Appointment"} back={true} reload={false}/>
          <Grid item xs={12} css={{ padding: "0 30px" , paddingTop: '30px'}}>
            {/* <span style={{padding: '10px', fontWeight: 'bold', color: '#3f51b5', fontSize:'22px'}}>
                Today {" " + dateString(new Date())}
            </span> */}
            
            <Box
                style={{position: "relative", overflow: "auto", height: '90vh' }}
                css={{ borderRadius: 5 }}
                >
                    <Box
                        css={{ backgroundColor: "#fff", 
                        width: "100%", 
                        borderRadius: 20, 
                        border: 'solid 2px #3f51b5'
                        }}
                        p={2}
                        mb={1}
                        mt={1}
                        display="flex"
                        onClick={() => {
                            // setName(contactInfo.name);
                        }}
                    >
                        <Box pr={8} pt={5} pl={3}
                        >
                        <Avatar style={{ width: 80, height: 80, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <PersonIcon style={{fontSize: '60px'}}/>
                        </Avatar>
                        </Box>

                        <Grid container>
                            <Grid item xs={6}>
                                <Box mb={2} position="relative">
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    css={{ color: "#35353F", marginTop:'4px' }}
                                >
                                    <Box fontSize={15} mr={1}>
                                    <span style={{fontWeight: 'bold'}}> Name: </span>  {name}
                                    </Box>
                                </Box>
                                    <br/>
                                    <Box fontSize={15} mr={1}>
                                    <span style={{fontWeight: 'bold'}}> Gender: </span>  
                                    {/* { contactInfo?.patient?.gender === 'm' ? "Male"
                                        : contactInfo?.patient?.gender === 'f' ? "Female"
                                        : ""
                                    } */} Male
                                    </Box>
                                    <br/>
                                    <Box fontSize={15} mr={1}>
                                    <span style={{fontWeight: 'bold'}}> Booking Type:</span> Video Call
                                    {/* {contactInfo?.appointment_type} */}
                                    </Box>
                                    <br/>
                                    <Box fontSize={15} mr={1}>
                                    <span style={{fontWeight: 'bold'}}> Date: </span>  31 March, 2022
                                    {/* {dateStringFullMothName(contactInfo?.start_time)} */}
                                    </Box>
                                    <br/>
                                    <Box fontSize={15} mr={1}>
                                    <span style={{fontWeight: 'bold'}}>Start Time: </span>  10.00 น.
                                    </Box>
                                    <br/>
                                    <Box fontSize={15} mr={1}>
                                    <span style={{fontWeight: 'bold'}}>Session: </span>  15 นาที 
                                    {/* {dateStrFormatTime(contactInfo?.end_time)} */}
                                    </Box>
                                    {/* <br/> */}
                                    {/* <Box fontSize={15} mr={1}>
                                    <span style={{fontWeight: 'bold'}}> Session: </span>  30 นาที
                                    </Box> */}
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box mb={2} position="relative">
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    css={{ color: "#35353F", marginTop:'4px' }}
                                >
                                    <Box fontSize={15} mr={1}>
                                    <span style={{fontWeight: 'bold'}}> Symptom case: </span>
                                    </Box>
                                </Box>
                                    <br/>
                                    <Box fontSize={15} mr={1}>
                                        {/* {
                                            // contactInfo?.patient?.symptom_case
                                            contactInfo?.symptom
                                        } */}
                                        มีผื่นที่ผิวหนัง และคันตามผิวหนัง
                                        <br/>
                                        คัดจมูก น้ํามูกไหล จาม
                                    </Box>
                                    <br/>
                                    <br/>
                                    {/* <Button  style={{textAlign: 'center'}}
                                        variant="contained" color="primary"
                                        onClick = {() => {
                                        history.push("/telemedicine/viewCase/" + contactInfo?.patient?.id + "/" + contactInfo?.patient?.name + "/" + contactInfo?.patient?.surname );
                                        }}
                                        > View Case
                                    </Button> */}
                                </Box>
                            </Grid>
                            <br/>
                            <Grid item xs={12}>
                                <span style={{fontSize: '18px', fontWeight: 600}}> Please Confirm this appointment </span>

                                <Button  style={{textAlign: 'center', margin: '0 30px'}}
                                    variant="contained"
                                    color= 'primary'
                                    onClick = {() => {
                                    // history.push("/telemedicine/videocall/" + index + "/" + name + "/" + surname );
                                    }}
                                > Accept
                                </Button>

                                <Button  style={{textAlign: 'center'}}
                                    variant="contained" color="secondary"
                                    // onClick = {() => {
                                    //     setIsPostpone(true);
                                    // }}
                                    > Postpone
                                </Button>
                            </Grid>
                        </Grid>

                    </Box>
            </Box>
        </Grid>
    </>
  );
});