import React, { Fragment, useEffect, useState, useRef } from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import ReactDOM from "react-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  Container,
  Grid,
  GridList,
  GridListTile,
  Paper,
  LinearProgress,
  Typography,
  Select,
  MenuItem,
  Badge,
  FormControl,
  FormLabel ,
  FormControlLabel ,
  RadioGroup ,
  Radio 
} from "@material-ui/core";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { webStore } from "../../stores/webStore";
import { DataGrid, ColDef, ValueGetterParams } from "@material-ui/data-grid";

import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useReadUserByGender } from "../../services/api/dashboard_report/useReadUserByGender";

import { useReadUserByAge } from "../../services/api/dashboard_report/useReadUserByAge";
import { useReadUser } from "../../services/api/user/useReadUser";
import { useListUser } from "../../services/api/user/useListUser";
import { useReadLengthOfStay } from "../../services/api/dashboard_report/useReadLengthOfStay";
import {
  Chart,
  PieSeries,
  Title,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";

import { Animation } from "@devexpress/dx-react-chart";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";

import { useHistory } from "react-router-dom";
import { useListSOSUser } from "../../services/api/user/useListSOSUser";
import { useListSOSUserComplete } from "../../services/api/user/useListSOSUserComplete";
import { dateStr, dateStrAPI,dateString,dateStringFullMothName,dateStrFormatTime,dateStrFormatLogin } from "../../utils";
import { User, UserSOS } from "../../types/user";
import WarningIcon from "@material-ui/icons/Warning";
import { DeviceValueTab, OnlineTab } from "../Dashboard/CardUserList";
import { userDevice } from "../../types/device";
import CheckIcon from "@material-ui/icons/Check";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Pagination from "@material-ui/lab/Pagination";
import { Places } from "../../types/places";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import DoneIcon from '@material-ui/icons/Done';
import AgoraRTC from "agora-rtc-sdk-ng";
import VideocamIcon from '@material-ui/icons/Videocam';
import AppointmentJsonData from './Booking.json';
import {
    ClientConfig,
    IAgoraRTCRemoteUser,
    ICameraVideoTrack,
    IMicrophoneAudioTrack,
  } from "agora-rtc-sdk-ng";

  import {
    AgoraVideoPlayer,
    createClient,
    createMicrophoneAndCameraTracks,
  } from "agora-rtc-react";
  
import AgoraUIKit,{ layout }from 'agora-react-uikit';
import { SubBar } from "../Dashboard/DashboardSI";
import  BlueUserIcon  from '../../components/assets/BlueUserIcon.jpg';
import PersonIcon from '@material-ui/icons/Person';
import LuxonUtils from "@date-io/luxon";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { AppointmentData } from '../../types/Appointment';
import { GetAppointmentData } from "../../services/api/telemedicine/BookingService";  
export const Appointment  = observer(() => {
    const [startDate, setStartDate] = useState<Date | null>(
        webStore.startDate || new Date()
      );
  const history = useHistory();
  const jsonData  = AppointmentJsonData;
  const appointment_data = jsonData.data;
  const docter_info = jsonData.data[0].doctor;
  const { result_appointment, loading_appointment } = GetAppointmentData();
  console.log("result appointment: ", result_appointment);

  let historyLog = [
    { 
      id: 1,
      name: 'Test TestingSurname 1'
    },
    { 
      id: 2,
      name: 'Test TestingSurname 2'
    },
    { 
      id: 3,
      name: 'Test TestingSurname 3'
    },
    { 
      id: 4,
      name: 'Test TestingSurname 4'
    },
    { 
      id: 5,
      name: 'Test TestingSurname 5'
    }
  ];

  useEffect(() => {
  }, []);

  return (
    <>
          <SubBar title={"Appointments"} back={true} reload={false}/>
          <Grid container>
            <Grid item md={3}  xs={12} style={{padding: '20px'}}>
                <Grid container xs={12}>
                    <Grid item xs={12} css={{ padding: "0 5px" }}>
                        <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <KeyboardDatePicker
                                style={{
                                margin: 0,
                                width: "100%",
                                backgroundColor: "#fff",
                                }}
                                
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="from"
                                inputVariant="outlined"
                                label="Choose Date"
                                autoOk={true}
                                value={startDate}
                                onChange={(date) => {
                                setStartDate(date ? new Date(date.toString()) : null);
                                // onSearch();
                                }}
                                KeyboardButtonProps={{
                                "aria-label": "change date",
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    
                </Grid>
                <br/>
                
                <Grid container>
                    <Grid item xs={12} css={{ padding: "0 5px",  backgroundColor: '#fff'}}>
                        <h6 style={{padding: '10px', fontWeight: 'bold'}}>
                            New Appointment
                        </h6>
                        
                        <Box
                            style={{position: "relative", overflow: "auto" }}
                            css={{ borderRadius: 5, backgroundColor: "#fff" }}
                        >
                            {(historyLog || []).map((contactInfo : any) => {
                                
                                return ( 
                                <Box
                                    css={{ backgroundColor: "#fff", 
                                    width: "100%", 
                                    borderRadius: 20, 
                                    border: 'solid 2px #3f51b5',
                                    "&:hover": {
                                        backgroundColor: "rgba(3,169,244,0.08)",
                                        marginTop: '2%'
                                        }
                                    }}
                                    p={2}
                                    mb={1}
                                    mt={1}
                                    display="flex"
                                    onClick = {() => {
                                        history.push("/telemedicine/acceptNewAppointment/" + contactInfo?.id + "/" + contactInfo?.name );
                                     }}
                                >
                                    <Box pr={2}
                                    >
                                    <Avatar css={{ width: 55, height: 55 }}>
                                        <PersonIcon style={{fontSize: '40px'}}/>
                                    </Avatar>
                                    </Box>

                                    <Grid container>
                                    <Grid item xs={12}>
                                        <Box mb={2} position="relative">
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            css={{ color: "#35353F", marginTop:'4px' }}
                                        >
                                            <Box fontWeight={600} fontSize={15} mr={1}>
                                            {contactInfo.name}
                                            </Box>
                                            
                                        </Box>
                                        <Box>
                                            <p style={{fontSize: '12px',color: 'grey'}}> 21 February 2022 11.00 น. </p>
                                        </Box>
                                        </Box>
                                    </Grid>
                                    </Grid>

                                </Box>
                                );
                            })}
                        </Box>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item md={6}  xs={12} style={{height: '100vh', padding: '20px'}} >
            <Grid container>
            <Grid container>
                    {/* <Grid item xs={12} css={{ padding: "0 5px" }}>
                        <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <KeyboardDatePicker
                                style={{
                                margin: 0,
                                width: "100%",
                                backgroundColor: "#fff",
                                }}
                                
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="from"
                                inputVariant="outlined"
                                label="Choose Date"
                                autoOk={true}
                                value={startDate}
                                onChange={(date) => {
                                setStartDate(date ? new Date(date.toString()) : null);
                                // onSearch();
                                }}
                                KeyboardButtonProps={{
                                "aria-label": "change date",
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid> */}
                    
                </Grid>
                    <Grid item xs={12} css={{ padding: "0 5px" , paddingTop: '30px'}}>
                        {/* <span style={{padding: '10px', fontWeight: 'bold', color: '#3f51b5', fontSize:'22px'}}>
                            Today {" " + dateString(new Date())}
                        </span> */}
                        
                        <Box
                            style={{position: "relative", overflow: "auto", height: '90vh' }}
                            css={{ borderRadius: 5 }}
                            >
                            {(result_appointment || []).map((contactInfo : any, index) => {
                            // {(appointment_data || []).map((contactInfo : any, index) => {
                                
                                return ( 
                                <Box
                                    css={{ backgroundColor: "#fff", 
                                    width: "100%", 
                                    borderRadius: 20, 
                                    border: 'solid 2px #3f51b5',
                                    "&:hover": {
                                        backgroundColor: "rgba(3,169,244,0.08)",
                                        marginTop: '2%'
                                        }
                                    }}
                                    p={2}
                                    mb={1}
                                    mt={1}
                                    display="flex"
                                    onClick={() => {
                                        // setName(contactInfo.name);
                                    }}
                                >
                                    <Box pr={4} pt={5}
                                    >
                                    <Avatar style={{ width: 60, height: 60, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                        {/* <PersonIcon style={{fontSize: '60px'}}/> */}
                                        {/* <img src={contactInfo?.patient?.avartar} alt="" style={{height:'100%'}}/> */}
                                        {
                                            contactInfo?.patient?.avartar ? 
                                            <img src={contactInfo?.patient?.avartar} alt="" style={{height:'100%'}}/>
                                            :
                                            <PersonIcon style={{fontSize: '30px'}}/>
                                        }
                                    </Avatar>
                                    </Box>

                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Box mb={2} position="relative">
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                css={{ color: "#35353F", marginTop:'4px' }}
                                            >
                                                <Box fontSize={15} mr={1}>
                                                <span style={{fontWeight: 'bold'}}> Name: </span>  {contactInfo?.patient?.name + " " + contactInfo?.patient?.surname}
                                                </Box>
                                            </Box>
                                                <br/>
                                                <Box fontSize={15} mr={1}>
                                                <span style={{fontWeight: 'bold'}}> Gender: </span>  
                                                { contactInfo?.patient?.gender === 'm' ? "Male"
                                                  : contactInfo?.patient?.gender === 'f' ? "Female"
                                                  : ""
                                                }
                                                </Box>
                                                <br/>
                                                <Box fontSize={15} mr={1}>
                                                <span style={{fontWeight: 'bold'}}> Booking Type: </span>  {contactInfo?.appointment_type}
                                                </Box>
                                                <br/>
                                                <Box fontSize={15} mr={1}>
                                                <span style={{fontWeight: 'bold'}}> Date: </span>  {dateStringFullMothName(contactInfo?.start_time)}
                                                </Box>
                                                <br/>
                                                <Box fontSize={15} mr={1}>
                                                <span style={{fontWeight: 'bold'}}>Start Time: </span>  {dateStrFormatTime(contactInfo?.start_time)}
                                                </Box>
                                                <br/>
                                                <Box fontSize={15} mr={1}>
                                                <span style={{fontWeight: 'bold'}}>End Time: </span>  {dateStrFormatTime(contactInfo?.end_time)}
                                                </Box>
                                                {/* <br/> */}
                                                {/* <Box fontSize={15} mr={1}>
                                                <span style={{fontWeight: 'bold'}}> Session: </span>  30 นาที
                                                </Box> */}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box mb={2} position="relative">
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                css={{ color: "#35353F", marginTop:'4px' }}
                                            >
                                                <Box fontSize={15} mr={1}>
                                                <span style={{fontWeight: 'bold'}}> Symptom case: </span>
                                                </Box>
                                            </Box>
                                                <br/>
                                                <Box fontSize={15} mr={1}>
                                                    {
                                                        // contactInfo?.patient?.symptom_case
                                                        contactInfo?.symptom
                                                    }
                                                    {/* มีผื่นที่ผิวหนัง และคันตามผิวหนัง
                                                    <br/>
                                                    คัดจมูก น้ํามูกไหล จาม */}
                                                </Box>
                                                <br/>
                                                <br/>
                                                <Button  style={{textAlign: 'center'}}
                                                 variant="contained" color="primary"
                                                 onClick = {() => {
                                                    history.push("/telemedicine/viewCase/" + contactInfo?.patient?.id + "/" + 
                                                    contactInfo?.patient?.name + "/" + contactInfo?.patient?.surname + "/" +
                                                    contactInfo?.chat_channel?.channel_name + "/" + contactInfo?.chat_channel?.channel_type + "/" +
                                                    contactInfo?.video_channel?.channel_name + "/" + contactInfo?.video_channel?.channel_type + "/" + contactInfo?.id );
                                                 }}
                                                 > View Case
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </Box>
                                );
                            })}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={3}  xs={12}>
                <Grid container> 
                    <Grid item xs={12} style={{backgroundColor: '#fff', padding: '20px'}}>
                        <Box 
                            style={{ 
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingTop: '20px'
                            }}
                        >
                             <Avatar style={{ width: 100, height: 100,  textAlign:'center'}}>
                                {/* <PersonIcon style={{fontSize: '60px'}}/> */}
                                <img src={docter_info?.profile_image} alt="" />
                            </Avatar>
                        </Box>
                       <Box 
                            style={{ 
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingTop: '10px'
                            }}
                        >
                             <p style={{fontWeight: 'bold'}}>Dr. {docter_info?.first_name + " " + docter_info?.last_name}</p>
                        </Box>
                        <Box 
                            style={{ 
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                             <p style={{ fontSize: '12px', color: 'gray'}}>{docter_info?.department?.name}</p>
                        </Box>
                        <br/>
                        <hr/>
                        <br/>
                       <Grid container>
                            <Grid item xs={8}>
                                <p style={{ fontSize: '12px', color: 'gray', paddingLeft: '10px'}}>Overall Rating:</p>
                            </Grid>
                            <Grid item xs={4}>
                                <p style={{ fontSize: '14px', fontWeight: 'bold'}}>4.8</p>
                            </Grid>
                       </Grid>
                       <br/>
                       <Grid container>
                            <Grid item xs={8}>
                                <p style={{ fontSize: '12px', color: 'gray' , paddingLeft: '10px'}}>Available Slot:</p>
                            </Grid>
                            <Grid item xs={4}>
                                <p style={{ fontSize: '14px', fontWeight: 'bold'}}>38/140</p>
                            </Grid>
                       </Grid>
                       <br/>
                       <Grid container>
                            <Grid item xs={8}>
                                <p style={{ fontSize: '12px', color: 'gray',  paddingLeft: '10px'}}>Total Patients:</p>
                            </Grid>
                            <Grid item xs={4}>
                                <p style={{ fontSize: '14px', fontWeight: 'bold'}}>246</p>
                            </Grid>
                       </Grid>
                       <br/>
                       <br/>
                    </Grid>
                </Grid>
            </Grid>
          </Grid>
    </>
  );
});