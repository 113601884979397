import React, { Fragment, useEffect, useState } from "react";
import { Global, css } from "@emotion/react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import "swiper/swiper-bundle.min.css";
import "./App.css";
import { PrivateRoute } from "./components/PrivateRoute";
import { Login } from "./features/Login";
import { SnackbarProvider } from "notistack";
import { AfterLogin } from "./features/AfterLogin";
import { webStore } from "./stores/webStore";
import { observer } from "mobx-react-lite";
//
import { Dashboard, DashboardMobile } from "./features/Dashboard/Dashboard";
import { UserList, UserListMobile } from "./features/UserList/UserList";
import { UserCreate, UserCreateMobile } from "./features/UserManage/UserCreate";
import { UserCreateHospital, UserCreateHospitalMobile } from "./features/UserManage/UserCreateHospital";
import { UserCreateSI, UserCreateSIMobile } from './features/UserManage/UserCreateSI';
import { UserImport } from "./features/UserManage/UserImport";
import {
  UserPreview,
  UserPreviewMobile,
} from "./features/UserManage/UserPreview";
import { UserDetail, UserDetailMobile } from "./features/UserManage/UserDetail";
import { UserDetailSeeMore, UserDetailSeeMoreMobile } from "./features/UserManage/UserDetailSeeMore";
import {
  ViewNoteList
} from "./features/UserManage/ViewNoteList";

import { ContactHistory } from "./features/UserManage/ContactHistory";
// import {
//   UserPreviewSOS,
//   UserPreviewSOSMobile,
// } from "./features/UserManage/UserPreviewSOS";
import { createMuiTheme, MuiThemeProvider, Avatar } from "@material-ui/core";
import { LoggingDashboard } from "./features/LoggingDashboard/LoggingDashboard";
import { Notification } from "./features/Notification/Notification";
import { PushData } from "./features/Setting/PushData";
import { CreatePushData } from "./features/Setting/CreatePushData";
// import { logImei } from "./features/Setting/logImei";
import { Geofence } from "./features/Setting/Geofence/Geofence";
import { GeofenceEdit } from "./features/Setting/Geofence/GeofenceEdit";
import { GeofenceCreate } from "./features/Setting/Geofence/GeofenceCreate";
// import { Alert } from "./features/Alert/Alert";
import { Alert, AlertMobile } from "./features/Alert/Alert";
import { ChooseReport } from "./features/Report/ChooseReport";
import { DailyReport } from "./features/Report/DailyReport";
import { ImportDailyForm } from './features/Report/ImportDailyForm';
import { ImporMentalHealthForm } from './features/Report/ImportMentalHealthForm';
import { Setting } from "./features/Setting/Setting";
import { ChangePassword } from "./features/Setting/ChangePassword";
import { SetNotification } from "./features/Setting/Notification";
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { getToken, onMessageListener } from './firebase';
import firebase from 'firebase/compat/app';
import { initializeApp } from "firebase/app"
// import 'firebase/messaging';
import { getMessaging, getToken,onMessage } from "firebase/messaging";
import { API_PATH_GO } from "./utils/const";
import axios, { AxiosRequestConfig } from "axios";
import toast, { Toaster } from 'react-hot-toast';
import { MentalReport } from "./features/Report/MentalReport";
import { CardUserListByStatus } from "./features/Dashboard/CardUserListByStatus";
import { DashboardMobileSI, DashboardSI } from "./features/Dashboard/DashboardSI";
import { UserListMobileSmartInfirmary, UserListSmartInfirmary } from "./features/UserList/UserListSI";
import { VitalSign } from "./features/UserManage/VitalSign";
import { VideoCall } from "./features/Telemedicine/VideoCall";
import { DiagonosesReport } from "./features/UserManage/DiagnosesReport";
import { dateStrFormatLogin } from "./utils/index";
import { Appointment } from "./features/Telemedicine/Appointment";
import { ViewCase } from "./features/Telemedicine/ViewCase";
import { Booking } from "./features/Telemedicine/Booking";
import { MedicalCertificate } from "./features/UserManage/MedicalCertificate";
import { AcceptNewAppointment } from "./features/Telemedicine/AcceptNewAppointment";
import { DischargeForm } from "./features/UserManage/DischargeForm";
import { Chat } from "./features/Telemedicine/Chat";
import { Finance } from "./features/Telemedicine/Finance";
import { Pharmacy } from "./features/Telemedicine/Pharmacy";
import { ReportTelemedicine } from "./features/Telemedicine/ReportTelemedicine";
import { MedicalCertificateTelemed } from "./features/Telemedicine/MedicalCertificateTelemed";
// var firebaseConfig = {
//   apiKey: "AIzaSyDzef8AiE3Fw8jPGnvJZHEreKYgl4kGWp0",
//   authDomain: "aqua-dashboard.firebaseapp.com",
//   databaseURL: "https://aqua-dashboard.firebaseio.com",
//   projectId: "aqua-dashboard",
//   storageBucket: "aqua-dashboard.appspot.com",
//   messagingSenderId: "515081409051",
//   appId: "1:515081409051:web:e5ecb915c3c9818f405ebe",
//   measurementId: "G-RRXEWY2CJD"
// };

// vapidKey: BNwAxADuq0dpiRcQg2QysgSAt6oynpkPfg_7vYRNify9Hy7p7ynUmvDNXUCLaNjCH5UFiN4MenMxv3GMof39E6g

const firebaseApp = initializeApp({
  apiKey: "AIzaSyDvkf7Az7jrp0mSUZIMFdT3QYPcRGeY53g",
  authDomain: "home-isolation-9bc2b.firebaseapp.com",
  projectId: "home-isolation-9bc2b",
  storageBucket: "home-isolation-9bc2b.appspot.com",
  messagingSenderId: "485087116672",
  appId: "1:485087116672:web:44bcb8e38a49bbf9079565",
  measurementId: "G-JZXM4QJQYE"
});

// firebase.initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getTokenApp = () => {
  return getToken(messaging, {vapidKey: 'BOo-HvZFv9ySBYrSc9nFeOww6a2hQl4ChPyeCLghiS7vUJOe9Dp43bnSIQnuLC8KdBEjCoyRLHNI_Pn15OCkT-s'}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      localStorage.setItem("firebaseToken", currentToken);
      axios.post(API_PATH_GO+'/v2/notification-token/create', 
        { 
          provider: "firebase",
          token: localStorage.getItem("firebaseToken"),
          user_id: localStorage.getItem("userId")
        }, 
        {
          headers: {
            "Content-Type": "application/json",
            "Project-Key": localStorage.getItem("projectKey"),
            "Authorization": "Bearer " + localStorage.getItem("token"),
            "Platform" : "web"
          }
        }
      ).then((res) => {
        console.log("data2",res.data.message)
      }).catch((error) => {
        console.log("ERROR2",error)
      });
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    // messaging.onMessage((payload) => {
    //   resolve(payload);
    // });
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

const formLabelsTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
  },
});
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = observer(() => {
  useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    webStore.setDevice(mobile ? "mobile" : "desktop");
    console.log(webStore.device);
  }, []);
  
  getTokenApp();
  if(typeof messaging !== 'undefined'){
    onMessage(messaging, (payload) => {
      toast.custom((t) => (
        <div
          className={`${
            t.visible ? 'animate-enter' : 'animate-leave'
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          css={{backgroundColor: "#C83728"}}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0 pt-0.5">
                <Avatar css={{ width: 32, height: 32 }}>
                    {payload.notification?.body?.substring(0, 1)}
                </Avatar>
                {/* <img
                  className="h-10 w-10 rounded-full"
                  src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=6GHAjsWpt9&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                  alt=""
                /> */}
              </div>
              <div className="ml-3 flex-1">
                <p className="text-sm font-medium text-gray-900" css={{color: "white !important"}}>
                  {payload.notification?.title} {payload.notification?.body}
                </p>
                <p className="mt-1 text-sm text-gray-500" css={{color: "white !important"}}>
                  Just now!!!
                </p>

                {/*  incomoing change
                <img
                  className="h-10 w-10 rounded-full"
                  src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=6GHAjsWpt9&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                  alt=""
                />
              </div>
              <div className="ml-3 flex-1">
                <p className="text-sm font-medium text-gray-900" css={{color: "white"}}>
                  {payload.notification.title}
                </p>
                <p className="mt-1 text-sm text-gray-500" css={{color: "white"}}>
                  {payload.notification.body} */}
              </div>
            </div>
          </div>
          <div className="flex border-l border-gray-200">
            <button
              onClick={() => toast.dismiss(t.id)}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              css={{color: "white !important"}}
            >
              Close
            </button>
          </div>
        </div>
      ))
    });
  }
  console.log(webStore.device);
  if (webStore.device === "unknown") {
    return <></>;
  } else if (webStore.device === "desktop") {

  }

  axios.interceptors.response.use(function (response) {
    const expired_date = localStorage.getItem("token_expired_date");
    const date_now = new Date(Date.now());
    if(expired_date?.toLocaleString()) {
      if(date_now?.toLocaleString() > expired_date?.toLocaleString()) {
        // localStorage.clear();
      }
    }

    return response;
  }, function (error) {
    if (error?.request?.status === 401) {
      localStorage.clear();
    }
    return Promise.reject(error);
  });
  
  return (
    <MuiThemeProvider theme={formLabelsTheme}>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{ duration: 10000000 }}
      />
      {/* toastOptions={{
          // Define default options
          className: '',
          duration: 1000000,
        }} */}
      <SnackbarProvider>
        <Global
          styles={css`
            .MuiPickersBasePicker-container
              .MuiPickersToolbarButton-toolbarBtn
              .MuiTypography-subtitle1 {
              color: rgba(255, 255, 255, 1) !important;
              text-decoration: underline;
              font-weight: bold;
            }
          `}
        />
        <Router>
          <ScrollToTop />
          <div key={webStore?.profile?.id}>
            <Switch>
              <Route path="/login" component={Login} />
              <PrivateRoute
                path="/"
                title="Dashboard"
                exact
                component={(props: any) => {
                  if (
                    webStore.selectedProject?.is_quarantine === 0 ||
                    window.location.href.indexOf("?log") >= 0
                  ) {
                    return <LoggingDashboard {...props} />;
                  }
                  if(localStorage.getItem("projectType") === "SI" || localStorage.getItem("projectType") === 'isolation'){
                    return webStore.device === "desktop" ? (
                      <DashboardSI {...props} />
                    ) : (
                      <DashboardMobileSI {...props} />
                    );
                  }
                  return webStore.device === "desktop" ? (
                    <Dashboard {...props} />
                  ) : (
                    <DashboardMobile {...props} />
                  );
                }}
              />
              <PrivateRoute
                path="/user/list"
                exact
                component={
                  webStore.device === "desktop" ? UserList : UserListMobile
                }
              />

              <PrivateRoute
                path="/user/listSI"
                exact
                component={
                  webStore.device === "desktop" ? UserListSmartInfirmary : UserListMobileSmartInfirmary
                }
              />
            
              <PrivateRoute
                path="/user/create"
                exact
                component={
                  webStore.device === "desktop" ? UserCreate : UserCreateMobile
                }
              />

              <PrivateRoute
                path="/user/createUser"
                exact
                component={
                  webStore.device === "desktop" ? UserCreateHospital : UserCreateHospitalMobile
                }
              />

              <PrivateRoute
                path="/user/createUserSI"
                exact
                component={
                  webStore.device === "desktop" ? UserCreateSI : UserCreateSIMobile
                }
              />

              <PrivateRoute
                path="/user/import"
                exact
                component={webStore.device === "desktop" ? UserImport : null}
              />
              <PrivateRoute
                path="/user/:userId/preview"
                exact
                component={
                  webStore.device === "desktop"
                    ? UserPreview
                    : UserPreviewMobile
                }
              />
              
              <PrivateRoute
                path="/user/:userId/detail"
                exact
                component={
                  webStore.device === "desktop" 
                  ? UserDetail
                  : UserDetailMobile
                }
              />

              <PrivateRoute
                path ="/user/:userId/seeMoreDetail"
                exact 
                component ={
                  webStore.device === "desktop"
                  ? UserDetailSeeMore
                  : UserDetailSeeMoreMobile
                }
              />

              <PrivateRoute path="/user/:quarantineId/noteList" exact component={ ViewNoteList } />
              <PrivateRoute path="/user/:userId/contactTracerHistory/:startDate/:endDate" exact component={ ContactHistory } />
              

              <PrivateRoute path="/user/:userId/vitalSigns" exact component={ VitalSign } />
              <PrivateRoute path="/user/:userId/diagonoses/:onboardId" exact component={ DiagonosesReport } />
              <PrivateRoute path= "/user/:userId/discharge/:onboardId" component = { DischargeForm } />
              <PrivateRoute path="/user/:userId/medicalCertificate" exact component = { MedicalCertificate } />
              {/* <PrivateRoute
                path="/user/:userId/previewUserSOS"
                exact
                component={
                  webStore.device === "desktop"
                    ? UserPreviewSOS
                    : UserPreviewSOSMobile
                }
              /> */}
              <PrivateRoute
                path="/notification"
                exact
                component={Notification}
              />
              <PrivateRoute path="/setting" exact component={Setting} />
              <PrivateRoute
                path="/setting/pushdata"
                exact
                component={PushData}
              />
              <PrivateRoute
                path="/setting/pushdata/create"
                exact
                component={CreatePushData}
              />
              <PrivateRoute
                path="/setting/geofence"
                exact
                component={Geofence}
              />
              <PrivateRoute
                path="/setting/geofence/add"
                exact
                component={GeofenceCreate}
              />
              <PrivateRoute
                path="/setting/geofence/edit/:id"
                exact
                component={GeofenceEdit}
              />
              <PrivateRoute
                path="/setting/changepassword"
                exact
                component={ChangePassword}
              />
              <PrivateRoute
                path="/setting/notification"
                exact
                component={SetNotification}
              />
              {/* <PrivateRoute
                path="/logImei"
                exact
                component={logImei}
              /> */}
              <PrivateRoute path="/alert" exact component={Alert} />
              <PrivateRoute path="/report" exact component={ChooseReport} />
              <PrivateRoute path="/report/daily" exact component={DailyReport} />
              <PrivateRoute path= "/report/mental" exact component = { MentalReport }/>
              <PrivateRoute path="/report/importDailyForm" exact component={ImportDailyForm}/>
              <PrivateRoute path="/report/imporMentalHealthForm" exact component={ImporMentalHealthForm}/> 
              <PrivateRoute path='/telemedicine/videocall/:index/:name/:surname/:videoChannel/:videoChannelType' exact component={ VideoCall }/>
              <PrivateRoute path='/telemedicine/appiontment' exact component ={ Appointment } />
              <PrivateRoute path='/telemedicine/viewCase/:index/:name/:surname/:chatChannel/:chatChannelType/:videoChannel/:videoChannelType/:appointmentId' exact component = {ViewCase} />
              <PrivateRoute path='/telemedicine/booking' exact component = { Booking } />
              <PrivateRoute path="/telemedicine/acceptNewAppointment/:index/:name" component = { AcceptNewAppointment }/>
              <PrivateRoute path="/telemedicine/chat/:index/:name/:surname/:chatChannel/:chatChannelType" component = { Chat } />
              <PrivateRoute path="/telemedicine/finance" component = { Finance } />
              <PrivateRoute path= "/telemedicine/pharmacy" component = { Pharmacy }/>
              <PrivateRoute path= "/telemedicine/report" component = { ReportTelemedicine }/>
              <PrivateRoute path= "/telemedicine/medicalCertificate" component = { MedicalCertificateTelemed }/>
            </Switch>
          </div>
        </Router>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
});

export default App;
