import { AppointmentData, PatientData, CalendarData,AppointmentDataById } from "../../../types/Appointment";
import { dateStrAPI,timeString } from "../../../utils";
import { useCallAPISpacetrax } from "../../useCallAPI";

export const GetAppointmentData = () => {
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
        data: AppointmentData
      }>(
        {
          // url:  `/v2/video-call/token?channel_name=11121150`,
          url:  `v2/smart-infirmary/booking/doctor`,
          method: "GET",
          // data: {
          //   userId,
          // },
        }
      );
    
      return {
        result_appointment: res?.data ? res?.data : null,
        loading_appointment: loading,
        error_appoinment: error
      };
  }

  export const GetPatientData = (id : string) => {
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
        data: PatientData
      }>(
        {
          // url:  `/v2/video-call/token?channel_name=11121150`,
          url:  `v2/smart-infirmary/booking/patient/` + id + "/all",
          method: "GET",
          // data: {
          //   userId,
          // },
        }
      );
    
      return {
        result_patientData: res?.data ? res?.data : null,
        loading_patientData: loading,
        error_patientData: error
      };
  }

  export const CreateCalendar = () =>{
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
      code: 0 | 1;
      message: string;
      // data: User;
      data : any;
    }>(
      {
        // url: `/api/v1/users/register/tourist/quarantines`,
        url:  `/v2/dashboard/calendar`,
        method: "POST",
      },
      {
        manual: true,
      }
    );
    return {
      result_create_calendar: res?.code === 1 ? res?.data : null,
      loading_create_calendar: loading,
      error_create_calendar: res?.code === 0 ? res?.message : null,
      fire_create_calendar: (dateArray: any, startDate: Date, endDate: Date, allBookingTimeData: any) => {
        let filterDateArray = dateArray
                 .filter((x : {x: any}) => !allBookingTimeData.includes(x))
                 .concat(allBookingTimeData.filter( (x: {x: any}) => !dateArray.includes(x)));
        
        let inputData = {
          period : filterDateArray,
          start_date: dateStrAPI(startDate) + " GMT +07:00",
          end_date: dateStrAPI(endDate)+ " GMT +07:00"
        };
        console.log("input data:", inputData);
        return new Promise((resolve, reject) => {
          fire({
            data: inputData,
          })
            .then(({ data: { data, code, message } }) => {
              if (code === 0) {
                reject(message);
              } else {
                resolve(data);
              }
            })
            .catch((ex) => {
              reject(ex.toString());
            });
        });
      },
    };
  }

  export const GetCalenderByDoctor = (date: Date) => {
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
        data: CalendarData
      }>(
        {
          url:  `https://api.space-trax.com/v2/dashboard/calendar`,
          method: "GET",
          params: {
            date: dateStrAPI(date)
          },
        }
      );

      let result_data = res?.data;
      let firstRow :any = [];
      let secondRow :any = [];
      let thirdRow : any = [];
      let fourthRow : any = [];
      let fifthRow : any = [];
      let sixthRow : any = [];
      let AllBookingTimeData : any = [];

      if(result_data){
        for(let i = 0 ; i < result_data.length ; i ++) {
          let startTime = timeString(result_data[i].start_time);
          let endTime = timeString(result_data[i].end_time);
          console.log('start time: ', startTime, endTime);
          if( startTime === "07:00" || startTime === "07:15" || startTime === "07:30" || startTime === "07:45"
            || startTime === "08:00" || startTime === "08:15"){
              firstRow.push(startTime+ '-'+ endTime);
              AllBookingTimeData.push(startTime+ '-'+ endTime);
          }
          if( startTime === "08:30" || startTime === "08:45" || startTime === "09:00" || startTime === "09:15"
            || startTime === "09:30" || startTime === "09:45"){
              secondRow.push(startTime+ '-'+ endTime);
              AllBookingTimeData.push(startTime+ '-'+ endTime);
          }
          if( startTime === "10:00" || startTime === "10:15" || startTime === "10:30" || startTime === "10:45"
            || startTime === "11:00" || startTime === "11:15"){
              thirdRow.push(startTime+ '-'+ endTime);
              AllBookingTimeData.push(startTime+ '-'+ endTime);
          }
          if( startTime === "11:30" || startTime === "11:45" || startTime === "12:00" || startTime === "12:15"
            || startTime === "12:30" || startTime === "12:45"){
              fourthRow.push(startTime+ '-'+ endTime);
              AllBookingTimeData.push(startTime+ '-'+ endTime);              
          }
          if( startTime === "13:00" || startTime === "13:15" || startTime === "13:30" || startTime === "13:45"
            || startTime === "14:00" || startTime === "14:15"){
              fifthRow.push(startTime+ '-'+ endTime);
              AllBookingTimeData.push(startTime+ '-'+ endTime);
          }
          if( startTime === "14:30" || startTime === "14:45" || startTime === "15:00" || startTime === "15:15"
            || startTime === "15:30" || startTime === "15:45"){
              sixthRow.push(startTime+ '-'+ endTime);
              AllBookingTimeData.push(startTime+ '-'+ endTime);
          }
          // console.log('first row:', firstRow);
          // console.log('second row:', secondRow);
          // console.log('third row:', thirdRow);
          // console.log('fourth row:', fourthRow);
          // console.log('fifth row:', fifthRow);
          // console.log('sixth row:', sixthRow);
          // console.log("all booking time:", AllBookingTimeData);
        }
      }
    
      return {
        result_CalendarData: res?.data ? res?.data : null,
        firstRow: firstRow,
        secondRow: secondRow,
        thirdRow: thirdRow,
        fourthRow: fourthRow,
        fifthRow: fifthRow,
        sixthRow: sixthRow,
        allBookingTimeData: AllBookingTimeData, 
        loading_CalendarData: loading,
        error_Calendar: error
      };
  }

  export const GetBookingInfoById = (id : string) => {
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
        data: AppointmentDataById
      }>(
        {
          // url:  `/v2/video-call/token?channel_name=11121150`,
          url:  `/v2/smart-infirmary/booking/doctor/` + id ,
          method: "GET",
          // data: {
          //   userId,
          // },
        }
      );
    
      return {
        result_bookingData: res?.data ? res?.data : null,
        loading_bookingData: loading,
        error_bookingData: error
      };
  }