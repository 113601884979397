import { css, jsx } from "@emotion/react";
import React, { Fragment, useEffect, useState } from "react";
import _, { truncate } from "lodash";
import {
  Box,
  Container,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  FormHelperText,
  Button,
  CircularProgress,
  Paper,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  RadioGroup,
  Radio,
  Typography,
  MuiThemeProvider,
  createMuiTheme,
  Avatar,
  LinearProgress,
  Badge,
} from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import { GetWardList,GetTitleName, GetDiseaseList, GetMedicineList } from "../../services/api/user/useListUser";
import { SubBar } from "../Dashboard/Dashboard";
import { useHistory, useParams } from "react-router-dom";
import { useReadUser } from "../../services/api/user/useReadUser";
import LuxonUtils from "@date-io/luxon";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker,KeyboardTimePicker,KeyboardDateTimePicker } from "material-ui-thai-datepickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { webStore } from "../../stores/webStore";
import { observer } from "mobx-react-lite";
import { UserInfoCard } from "./UserInfoCard";
import { DeviceHistoryDatagrid } from "./DeviceHistoryDatagrid";
import { DateFilter } from "../../components/DateFilter";

import { BodyTempGraph } from "./BodyTempGraph";
import { HeartRateGraph } from "./HeartRateGraph";
import { BloodPressureGraph } from "./BloodPressureGrph";
import { UserCreateInput,UserCreateQuarantine, UserCreateSmartInfirmaryInput } from "../../types/user";
import { useCreateUserHospital,CheckImeiPair,UserCreateSIFunction } from "../../services/api/user/useCreateUser";
import { useSnackbar } from "notistack";
import { useListHospital } from "../../services/api/user/useListHospital";
import { useListAgencies } from "../../services/api/user/useListAgencies";
import { useListBranches, useListProject } from "../../services/api/user/useListBranches";
import { ToolbarComponentProps } from "@material-ui/pickers/Picker/Picker";
import { useUploadImage } from "../../services/api/user/useUploadImage";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { arrayIncludes } from "@material-ui/pickers/_helpers/utils";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from "@material-ui/icons/Close";
import 'moment/locale/th';
import moment from 'moment';
import AddCircleIcon from '@material-ui/icons/AddCircle';

export const NON_REQUIRE_USER_FIELD = [
  // "serviceDate",
  // "time",
  // "genderStatus",
  // "ward",
  // "branch_id",
  // "martialStatus",
  // "name",
  // "surname",
  // "date_of_birth",
  // "age",
  "relegion",
  "phoneNumber",
  "contactPersonName1",
  "relationship1",
  "contactPersonPhone1",
  "contactPersonName2",
  "contactPersonPhone2",
  "relationship2",
  "contactPersonName3",
  "contactPersonPhone3",
  "relationship3",
  "treatmentType",
  "elderAssociation",
  "emergencyHospital",
  "NR",
  "noCpr",
  "noIncubation",
  "noForward",
  "noHeal",
  "specifySpecialCase",
  "otherSpecialCase",
  "specifyOtherSpecialCase",
  "HT",
  "DM",
  "HD",
  "Anemina",
  "HPL",
  "CVA",
  "Dementia",
  "CA",
  "otherDisease",
  "specifyOtherDisease",
  "identity_number",
  "allergic",
  "foodAllergic",
  "specifyAllergic",
  "specifyFoodAllergic",
  "height",
  "weight",
  "heart_rate",
  "bloodSugar",
  "temp",
  "blood_diastolic",
  "blood_systolic",
  "o2",
  "eyesOptions",
  "armOptions",
  "speakingOptions",
  "canBreathe",
  "onBiBap",
  "onCPap",
  "onTube",
  "onO2",
  "specifyBreathing",
  "isTired",
  "isCough",
  "isSputum",
  "otherCoughCase",
  "specifyCoughCase",
  "eatingOptions",
  "specifyFood",
  "excretion",
  "excretionProblem",
  "specifyExcretion",
  "weakness", 
  "specifyWeakness",
  "swelling", 
  "specifySwelling",
  "wound",
  "specifyWound",
  "rash",
  "specifyRash",
  "pain",
  "specifyPain", 
  "vision", 
  "specifyVision",
  "hearing",
  "specifyHearing",
  "sleep",
  "specifySleep",
  "mentalState",
  "specifyMentalState",
  "riskAssessment",
  "specifyRisk",
  "selfCare",
  "specifySelfcare",
  "currentDrug",
  "specifyCurrentDrug"
];

const LabelForm = (props: any) => {
  return (
    <Box fontSize={16} css={{ color: "#000000de" }} mt={1} mb={1}>
      {props.children}
    </Box>
  );
};

const InputText = ({ input, form, onChange, ...rest }: any) => {
  return (
    <TextField
      label={input.label}
      value={form[input.name]}
      onChange={onChange}
      name={input.name}
      placeholder={"Please enter " + input.label}
      // helperText={`Please enter ${input.label}`}
      fullWidth
      margin="dense"
      // InputLabelProps={{
      //   shrink: true,
      // }}
      variant="outlined"
      size="small"
      {...rest}
    />
  );
};

export const ConfirmModal = observer(
  ({
    isConfirm,
    onClick,
    setIsConfirm,
    buttonName = "CONFIRM",
  }: {
    isConfirm: boolean;
    setIsConfirm: any;
    onClick?: any;
    buttonName?: string;
  }) => {

    return (
      <Modal
        open={isConfirm}
        onClose={() => {}}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          css={{ backgroundColor: "#fff", width: 350, borderRadius: 5 }}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Box css={{ color: "#4B4C56" }} fontSize={14}>

            </Box>
            <Box
              onClick={() => {
                setIsConfirm(false);
              }}
            >
              <CloseIcon css={{ cursor: "pointer" }} />
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            <p style={{ color:'grey', textAlign: 'center', fontSize: '18px'}}>Do you confirm to add user?</p>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            <Button
              onClick={() => {
                setIsConfirm(false);
              }}
              size="medium"
              variant="outlined"
            >
              CANCEL
            </Button>

            <Button
              onClick={onClick}
              color="primary"
              size="medium"
              variant="outlined"
              style={{marginLeft: '3%'}}
            >
              {buttonName}
            </Button> 
          </Box>
          <br/>
        </Box>
      </Modal>
    );
  }
);

export const ImeiPairModal = observer(
  ({
    isImeiPair,
    Imei1,
    Imei2,
    onClick,
    setIsImeiPair,
    result_imeiPair,
    result_imeiPair_user,
    result_devicePair,
    buttonName = "OK",
  }: {
    isImeiPair: boolean;
    setIsImeiPair: any;
    Imei1?: any;
    Imei2?: any;
    result_imeiPair?: any;
    result_imeiPair_user?: any;
    result_devicePair?: any;
    onClick?: any;
    buttonName?: string;
  }) => {

    return (
      <Modal
        open={isImeiPair}
        onClose={() => {}}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          css={{ backgroundColor: "#fff", width: 350, borderRadius: 5 }}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Box css={{ color: "#4B4C56" }} fontSize={14}>

            </Box>
            <Box
              onClick={() => {
                setIsImeiPair(false);
              }}
            >
              <CloseIcon css={{ cursor: "pointer" }} />
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            {
              Imei1 && result_imeiPair === true ? 
              // <p style={{ color:'grey', textAlign: 'center', fontSize: '18px'}}>
              //   Imei No-1. <b>{Imei1} </b> is already paired. 
              // </p> 
              <p style={{ color:'grey', textAlign: 'center', fontSize: '18px'}}>
                Imei No-1. <b>{Imei1} </b> is already paired. 
                {result_imeiPair_user.name} Do you want to change imei to pair new user.
              </p> 
              : <></>
            }

          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            {
              Imei2 && result_devicePair=== undefined? 
              // <p style={{ color:'grey', textAlign: 'center', fontSize: '18px'}}>
              //   Imei No-2. <b>{Imei2} </b> is already paired.
              // </p> 
              <p style={{ color:'grey', textAlign: 'center', fontSize: '18px'}}>
                Imei No-1. <b>{Imei2} </b> is already paired. 
                {result_imeiPair_user.name} Do you want to change imei to pair new user.
              </p> 
              : <></>
            }
            
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            <Button
              onClick={onClick}
              color="primary"
              size="medium"
              variant="outlined"
            >
              {buttonName}
            </Button> 
          </Box>
          <br/>
        </Box>
      </Modal>
    );
  }
);

const InputDropdown = observer(
  ({
    option,
    input,
    value,
    onChange,
    error,
  }: {
    option: any;
    input: any;
    value: any;
    onChange: any;
    error: boolean;
  }) => {
    return (
      <FormControl
        variant="outlined"
        style={{ width: "100%" }}
        disabled={(option || []).length === 0}
        required={NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0}
        size="small"
        margin="dense"
      >
        <InputLabel id="demo-simple-select-outlined-label">
          {input.label}
        </InputLabel>
        <Select
          disabled={(option || []).length === 0}
          labelId="demo-simple-select-outlined-label"
          name={input.name}
          label={input.label}
          value={value}
          onChange={onChange}
          fullWidth
          required={NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0}
          error={error}
        >
          {option?.map((v: any) => {
            return <MenuItem value={v.value}>{v.name}</MenuItem>;
          })}
        </Select>
      </FormControl>
    );
  }
);

export const InputDate = observer(
  ({
    input,
    form,
    onChange,
    error,
    value,
  }: {
    input: any;
    form?: any;
    onChange: any;
    error: boolean;
    value?: any;
  }) => {
    const f: any = form;
    const v: any = form ? f[input.name] : value;
    return (
      <FormControl
        required={NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0}
        style={{ width: "100%" }}
        size="small"
        margin="dense"
      >
        <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
          <KeyboardDatePicker
            required={NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0}
            style={{ margin: 0, width: "100%" }}
            autoOk
            format="dd/MM/yyyy"
            margin="normal"
            id="from"
            size="small"
            ToolbarComponent={(props: ToolbarComponentProps) => {
              return (
                <div
                  style={{
                    background: "#494592",
                  }}
                >
                  <Box p={2}>
                    <Button
                      size="small"
                      css={css`
                        font-size: 0.8rem;
                        color: white;
                        line-height: 1rem;
                        font-weight: bold;
                        text-transform: none;
                      `}
                      onClick={() => {
                        props.setOpenView("date");
                      }}
                    >
                      {props.date?.weekdayLong + " " + props.date?.day}
                    </Button>
                    <Grid>
                      <Button
                        size="small"
                        css={css`
                          font-size: 1.5rem;
                          line-height: 1.65rem;
                          font-weight: bold;
                          color: white;
                          text-transform: none;
                        `}
                        onClick={() => {
                          props.setOpenView("month");
                        }}
                      >
                        {props.date?.monthLong}
                      </Button>
                      <Button
                        size="small"
                        css={css`
                          font-size: 1.5rem;
                          line-height: 1.5rem;
                          font-weight: bold;
                          color: white;
                          text-transform: none;
                        `}
                        onClick={() => {
                          props.setOpenView("year");
                        }}
                      >
                        {props.date?.year ? props?.date?.year + 543 : props?.date?.year}
                      </Button>
                    </Grid>
                  </Box>
                </div>
              );
            }}
            inputVariant="outlined"
            label={input.label}
            value={v}
            onChange={onChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            error={error}
            yearOffset={543}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
    );
  }
);

export const InputTimePicker = observer(
    ({
      input,
      form,
      onChange,
      error,
      value,
    }: {
      input: any;
      form?: any;
      onChange: any;
      error: boolean;
      value?: any;
    }) => {
      const f: any = form;
      const v: any = form ? f[input.name] : value;
      const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
      const handleDateChange = (date : any) => {
            setSelectedDate(date);
        };
      return (
        <FormControl
          required={NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0}
          style={{ width: "100%" }}
          size="small"
          margin="dense"
        >
          <MuiPickersUtilsProvider utils={LuxonUtils}>
          <KeyboardTimePicker
                required = {NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0}
                style = {{margin : 0 , width : "100%"}}
                margin="normal"
                id="form"
                autoOk
                format="hh:mm a"
                value={v}
                label={input.label}
                onChange={onChange}
                KeyboardButtonProps={{
                    'aria-label': 'change time',
                }}
                inputVariant="outlined"
                size = "small"
            />

            {/* <TextField
                id="form"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                variant="outlined"
                required = {NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0}
                style = {{margin : 0 , width : "100%"}}
                margin="dense"
                value={v}
                label={input.label}
                onChange={onChange}
                size = "small"
              /> */}
            
          </MuiPickersUtilsProvider>
        </FormControl>
      );
    }
  );

//
export const UserCreateSI = observer(() => {
  const [isConfirm, setIsConfirm] = useState(false);
  const [specialCase, setSpecialCase] = React.useState({
        NR: true,
        noCpr: false,
        noIncubation: false,
        noForward: false,
        noHeal: false,
        otherSpecialCase: false
    });
 const [disease, setDisease] = React.useState(
     {
        HT: true,
        DM: false,
        HD: false,
        Anemina: false,
        HPL: false,
        CVA: false,
        CRF: false,
        Dementia: false,
        CA: false,
        otherDisease: false
     }
 );

 const [breathing, setBreathing] = React.useState({
    canBreathe: true,
    onBiPap: false,
    onCPap: false,
    onTube: false,
    onO2: false
 });

 const[coughing, setCoughing] = React.useState({
  isTired: true,
  isCough: false,
  isSputum: false,
  otherCoughCase: false
 })

 const [checkAllergic, setCheckAllergic] = React.useState('1');
 const [checkFoodAllergic, setCheckFoodAllergic] = React.useState('1');
 const [checkExcretion, setCheckExcretion] = React.useState('1');
 const [checkWeakness, setCheckWeakness] = React.useState('1');
 const [checkSwelling, setCheckSwelling] = React.useState('1');
 const [checkWound, setCheckWound] = React.useState('1');
 const [checkRash, setCheckRash] = React.useState('1');
 const [checkPain, setCheckPain] = React.useState('1');
 const [checkVision, setCheckVision] = React.useState('1');
 const [checkHearing, setCheckHearing] = React.useState('1');
 const [checkSleep, setCheckSleep] = React.useState('1');
 const [checkMentalState, setCheckMentalState] = React.useState('1');
 const [checkRiskAssessment, setCheckRisk] = React.useState('1');
 const [checkSelfcare, setCheckSelfcare] = React.useState('1');
 const [checkCurrentDrug, setCheckCurrentDrug] = React.useState({
   currentDrug: ''
 });

const { NR, noCpr, noIncubation, noForward, noHeal, otherSpecialCase } = specialCase;
const { HT, DM, HD, Anemina, HPL, CVA, CRF, Dementia, CA, otherDisease} = disease;
const {canBreathe, onBiPap, onCPap, onTube, onO2 } = breathing;
const {isTired, isCough, isSputum, otherCoughCase} = coughing;
const {currentDrug} = checkCurrentDrug;
const { wardList, result_wardlist, loading_wardList } = GetWardList();
const { titleName, result_titleName, loading_titleName } = GetTitleName();
const { medicineList, result_medicine, loading_medicine } = GetMedicineList();
const { diseaseList, result_disease, loading_disease } = GetDiseaseList();

  const handleChangeSpecialCase = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSpecialCase({ ...specialCase, [event.target.name]: event.target.checked });
    setVal(event.target.name, event.target.checked);
  };

  const handleChangehDisease = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisease({ ...disease, [event.target.name]: event.target.checked });
    setVal(event.target.name, event.target.checked);
  };

  const handleChangeBreathing = (event: React.ChangeEvent<HTMLInputElement>) => {
  setBreathing({ ...breathing, [event.target.name]: event.target.checked });
  setVal(event.target.name, event.target.checked);
  };

  const handleChangeCoughing = (event: React.ChangeEvent<HTMLInputElement>) => {
  setCoughing({ ...coughing, [event.target.name]: event.target.checked });
  setVal(event.target.name, event.target.checked);
  };

  const handleChangeCheckAllergic = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckAllergic((event.target as HTMLInputElement).value);
    setVal(event.target.name, event.target.value);
    
  };

  const handleChangeFoodAllergic = (event: React.ChangeEvent<HTMLInputElement>) =>{
    setCheckFoodAllergic((event.target as HTMLInputElement).value);
    setVal(event.target.name, event.target.value);
  }

  const handleChangeCheckExcreion = (event: React.ChangeEvent<HTMLInputElement>) => {
  setCheckExcretion((event.target as HTMLInputElement).value);
  setVal(event.target.name, event.target.value);
  };

  const handleChangeCheckWeakness = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckWeakness((event.target as HTMLInputElement).value);
    setVal(event.target.name, event.target.value);
  };

  const handleChangeCheckSwelling = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckSwelling((event.target as HTMLInputElement).value);
    setVal(event.target.name, event.target.value);
  };

  const handleChangeCheckWound = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckWound((event.target as HTMLInputElement).value);
    setVal(event.target.name, event.target.value);
  };

  const handleChangeCheckRash = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckRash((event.target as HTMLInputElement).value);
    setVal(event.target.name, event.target.value);
  };

  const handleChangeCheckPain = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckPain((event.target as HTMLInputElement).value);
    setVal(event.target.name, event.target.value);
  };

  const handleChangeCheckVision = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckVision((event.target as HTMLInputElement).value);
    setVal(event.target.name, event.target.value);
  };

  const handleChangeCheckHearing = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckHearing((event.target as HTMLInputElement).value);
    setVal(event.target.name, event.target.value);
  };

  const handleChangeCheckSleep = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckSleep((event.target as HTMLInputElement).value);
    setVal(event.target.name, event.target.value);
  };

  const handleChangeCheckMentalState = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckMentalState((event.target as HTMLInputElement).value);
    setVal(event.target.name, event.target.value);
  };

  const handleChangeCheckRisk = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckRisk((event.target as HTMLInputElement).value);
    setVal(event.target.name, event.target.value);
  };

  const handleChangeCheckSelfcare = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckSelfcare((event.target as HTMLInputElement).value);
    setVal(event.target.name, event.target.value);
  };

  const handleChangeCheckCurrentDrug = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckCurrentDrug( { ...checkCurrentDrug,  [event.target.name]: (event.target as HTMLInputElement).value });
    setVal(event.target.name, event.target.value);
    // setCoughing({ ...coughing, [event.target.name]: event.target.checked });
  };

  

  const [form, setForm] = useState<UserCreateSmartInfirmaryInput>({
    serviceDate: null,
    time: null,
    genderStatus: "",
    identity_number: "",
    ward: "",
    branch_id: "",
    gender: "",
    phoneNumber: "",
    martialStatus: "1",
    name: "",
    surname: "",
    date_of_birth:null,
    age: "",
    relegion: "",
    contactPersonName1: "",
    relationship1: "",
    contactPersonPhone1: "",
    contactPersonName2: "", 
    contactPersonPhone2:"",
    relationship2: "",
    contactPersonName3: "", 
    relationship3: "",
    contactPersonPhone3: "",
    treatmentType: "",
    elderAssociation: "",
    emergencyHospital: "",
    NR: NR,
    noCpr: noCpr,
    noIncubation: noIncubation,
    noForward: noForward,
    noHeal: noHeal,
    specifySpecialCase: "",
    otherSpecialCase: otherSpecialCase,
    specifyOtherSpecialCase: "",
    HT: HT,
    DM: DM,
    HD: HD,
    Anemina: Anemina,
    HPL: HPL,
    CVA: CVA,
    CRF: CRF,
    Dementia: Dementia,
    CA: CA,
    otherDisease: otherDisease,
    specifyOtherDisease: "",
    allergic: checkAllergic,
    foodAllergic: checkFoodAllergic,
    specifyFoodAllergic: "",
    specifyAllergic: "",
    height: "",
    weight: "",
    heart_rate: "",
    blood_systolic: "",
    temp: "",
    bloodSugar: "",
    blood_diastolic: "",
    o2: "",
    eyesOptions: "",
    armOptions: "",
    speakingOptions: "",
    canBreathe: canBreathe,
    onBiPap: onBiPap,
    onCPap: onCPap,
    onTube: onTube,
    onO2: onO2,
    specifyBreathing: "",
    isTired: isTired,
    isCough: isCough,
    isSputum: isSputum,
    otherCoughCase: otherCoughCase,
    specifyCoughCase: "",
    eatingOptions: "",
    specifyFood: "",
    excretion: checkExcretion,
    excretionProblem: "",
    specifyExcretion: "",
    weakness: checkWeakness, 
    specifyWeakness: "",
    swelling: checkSwelling, 
    specifySwelling: "",
    wound: checkWound,
    specifyWound: "",
    rash: checkRash,
    specifyRash: "",
    pain: checkPain,
    specifyPain: "", 
    vision: checkVision, 
    specifyVision: "",
    hearing: checkHearing,
    specifyHearing: "",
    sleep: checkSleep,
    specifySleep: "",
    mentalState: checkMentalState,
    specifyMentalState: "",
    riskAssessment: checkRiskAssessment,
    specifyRisk: "",
    selfCare: checkSelfcare,
    specifySelfcare: "",
    currentDrug: currentDrug,
    specifyCurrentDrug: ""
  }); //

  const calculateAge = (date : any) => {
    let birthDate = new Date(date.toString());
    let difference = Date.now() - birthDate.getTime();
    
    let ageDate = new Date(difference);
    let calculatedAge = Math.abs(ageDate.getUTCFullYear() -1970);
    let newV: any = { ...form };
    newV["age"] = calculatedAge;
    setForm(newV);

    newV["date_of_birth"] = new Date(date.toString());
    setForm(newV);
  }

  const [ERROR_REQUIRE, setErrorRequired] = useState(Array());
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const { fire_user_create_SI, loading_user_create_SI } = UserCreateSIFunction();
//   const {result_imeiPair, loading_imeiPair } = CheckImeiPair(form.device_id_1);
  const [isImeiPair, setIsImeiPair] = useState(false);

  const { fire_upload_image, loading_upload_image } = useUploadImage();
  
  const { result_agency, result_branch, loading_project, error_project} = useListProject();
  const [martialStatusValue, setMartialStatusValue] = React.useState(form.martialStatus);
  const [showContactPerson, setShowContactPerson] = useState(false);
  const [showThirdContactPerson, setThirdContactPerson] = useState(false);
  // const []

  const history = useHistory();
  const setVal = (k: any, v: any) => {
    let newV: any = { ...form };
    newV[k] = v;
    setForm(newV);
  };
  const onChange = (e: any) => {
    if (e.target.name) {
      setVal(e.target.name, e.target.value);
    }
    if (e.target.value !== null || e.target.value !== "") {
      setErrorRequired(ERROR_REQUIRE.filter((x) => x != e.target.name));
    }
  };

  const handleChangeMartialStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMartialStatusValue((event.target as HTMLInputElement).value);
  };

  const getHospital = (branch: any) => {
    let hospitalList;
    if(branch){
      hospitalList = branch[0].hospital_project;
    }
    // console.log("Hospital list:", hospitalList);
    // if(hospitalList.length === 0){
    //   hospitalList = null;
    // }  
    // console.log("Hospital list after:", hospitalList);
    return hospitalList;
  }

  let option: any = [];
//   option["gender"] = [
//     { value: "m", name: "Male" },
//     { value: "f", name: "Female" },
//   ];

  option["ward"] = wardList;

  option["genderStatus"] = titleName;

  option["medicineAllergies"] = [
    {value:'1', name: "Cetirizine"},
    {value:'2', name: "Desloratadine"}
  ]

  option["foodAllergies"] = [
    {value:'1', name: "sea food"},
    {value:'2', name: "peach"}
  ];

  option["branch_id"] = loading_project
  ? [] 
  :result_branch?.map((h: any) => {
      return { value: h.id, name: h.name_th };
    });

  option["hospital_id"] = loading_project
  ? []
  : getHospital(result_branch)?.map((h: any) => {
      return { value: h.hospitals.id, name: h.hospitals.name_th };
    });
  // option["medicineRegulary"] = [
  //   {value:'1', name: "medicine 1"},
  //   {value:'2', name: "medicine 2"},
  //   {value:'3', name: "medicine 3"},
  //   {value:'4', name: "medicine 4"},
  //   {value:'5', name: "medicine 5"}
  // ]

  option["treatmentType"] = [
      {value: '1', name: 'ข้าราชการ'},
      {value: '2', name: 'หลักประกันสุขภาพ'},
      {value: '3', name: 'ประกันสังคม'},
      {value: '4', name: 'ต่างด้าว'},
      {value: '5', name: 'ไม่มีสิทธิ'},
      {value: '6', name: 'อื่นๆ ระบุ'},

  ];

  option["eyesOptions"] = [
    {value: '1', name: 'E1 ไม่ลืมตา'},
    {value: '2', name: 'E2 ลืมตาเมื่อเจ็บ'},
    {value: '3', name: 'E3 ลืมตาเมื่อเรียก'},
    {value: '4', name: 'E4 ลืมตาเอง'},
  ]

  option["armOptions"] = [
    {value: '1', name: 'M1 ไม่ขยับ'},
    {value: '2', name: 'M2 แขนเหยียดเมื่อเจ็บ'},
    {value: '3', name: 'M3 แขนงอเมื่อเจ็บ'},
    {value: '4', name: 'M4 ขยับเมื่อเจ็บ'},
    {value: '5', name: 'M5 เอามือปัดตำแหน่งเจ็บได'},
    {value: '6', name: 'M6 ทำตามคำสั่งได้'}
  ];

  option["speakingOptions"] = [
    {value: '1', name: 'V1 ไม่ออกเสียง'},
    {value: '2', name: 'V2 พูดไม่เป็นภาษาไม่มีความหมาย'},
    {value: '3', name: 'V3 พูดเป็นคำๆ มีความหมาย'},
    {value: '4', name: 'V4 พูดเป็นประโยคแต่สับสน'},
    {value: '5', name: 'V5 พูดคุยปกติ'}
  ];

  option["eatingOptions"] = [
    {value: '1', name: 'รับประทานอาหารได้เอง'},
    {value: '2', name: 'ต้องมีคนช่วยป้อน'},
    {value: '3', name: 'อาหารปั่น'},
    {value: '4', name: 'อาหารสายยาง NG/PEG'}
  ];

  option["gender"] = [
    { value: "m", name: "Male" },
    { value: "f", name: "Female" },
  ];

  useEffect(() => {
  }, [form]);

  return (
    <>
      <Container maxWidth="md" className="py-4">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SubBar
              reload={false}
              back={true}
              title={"Create User"}
              // importExcel={"import"}
            />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md" className="py-2">
        <Paper variant="outlined" css={{ borderWidth: 0 }}>
          <Box py={2} px={{ xs: 2, md: 6 }}>
            <Box fontSize="h5.fontSize" fontWeight={600} mb={1}>
              User information
            </Box>
            <Grid container>
              <Box order={{ xs: 2, md: 0 }} width={{ xs: "100%", md: "100%" }}>
                <Grid container>
                <Grid item xs={12}>
                    <Box style={{border: "1px solid #80808040"}}>
                        <Grid container spacing={1} style={{padding: '7px'}}>
                                <Grid item md={3} xs={12}>
                                    <InputDate
                                        form={form}
                                        input={{
                                        name: "serviceDate",
                                        label: "วันที่เข้ารับบริการ",
                                        }}
                                        onChange={(date: MaterialUiPickersDate) => {
                                        setVal(
                                            "serviceDate",
                                            date ? new Date(date.toString()) : null
                                            
                                        );
                                        if (date) {
                                            setErrorRequired(
                                            ERROR_REQUIRE.filter((x) => x != "serviceDate")
                                            );
                                        }
                                        }}
                                        error={ERROR_REQUIRE.indexOf("serviceDate") >= 0}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <InputTimePicker
                                    form = {form}
                                    input = {{
                                        name: "time",
                                        label: "เวลา"
                                    }}
                                    onChange={(date: MaterialUiPickersDate) =>{
                                        setVal(
                                            "time",
                                            date? new Date(date.toString()) : null
                                            // date? date : null
                                        );
                                        if (date){
                                            setErrorRequired(
                                                ERROR_REQUIRE.filter((x) => x != "time" )
                                            );
                                        }
                                    }}
                                    error = {ERROR_REQUIRE.indexOf("time") >= 0}
                                    />
                                </Grid>

                              <Grid item md={3} xs={12}>
                                <InputDropdown
                                  option={option["branch_id"]}
                                  input={{ name: "branch_id", label: "Branch" }}
                                  value={form["branch_id"]}
                                  onChange={onChange}
                                  error={ERROR_REQUIRE.indexOf("branch_id") >= 0}
                                />
                              </Grid>

                              <Grid item md={3} xs={12}>
                                <InputDropdown
                                    option={option["ward"]}
                                    input={{ name: "ward", label: "Ward" }}
                                    value={form["ward"]}
                                    onChange={onChange}
                                    error={ERROR_REQUIRE.indexOf("ward") >= 0}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                  
                    <Box style={{border: "1px solid #80808040", marginTop : '3%'}}>
                        <Grid container spacing={1} style={{padding: '7px'}}>
                            <Grid item xs={12}>
                                <LabelForm>ข้อมูลส่วนบุคคล</LabelForm>
                            </Grid>

                            <Grid item md={3} xs={12}>
                                <InputDropdown
                                    option={option["genderStatus"]}
                                    input={{ name: "genderStatus", label: "คำนำหน้า" }}
                                    value={form["genderStatus"]}
                                    onChange={onChange}
                                    error={ERROR_REQUIRE.indexOf("genderStatus") >= 0}
                                />
                            </Grid>

                            {[{ name: "name", label: "ชื่อ" },
                                {name: "surname", label: "นามสกุล"},
                                {name: "phoneNumber", label: "Phone No."}
                            ].map(
                                (input) => {
                                    return (
                                    <Grid item md={3} xs={12}>
                                        <InputText
                                        {...{
                                            onChange,
                                            form,
                                            input,
                                            required:
                                            NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                            0,
                                            error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                        }}
                                        />
                                    </Grid>
                                    );
                                }
                                )}

                                {[{ name: "identity_number", label: "เลขประจําตัวประชาชน" }].map(
                                    (input) => {
                                    return (
                                        <Grid item md={3} xs={12}>
                                        <InputText
                                            {...{
                                            onChange,
                                            form,
                                            input,
                                            required:
                                                NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0,
                                            error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                            }}
                                        />
                                        </Grid>
                                    );
                                    }
                                )}

                                <Grid item md={2} xs={12}>
                                  <InputDropdown
                                    option={option["gender"]}
                                    input={{ name: "gender", label: "Gender" }}
                                    value={form["gender"]}
                                    onChange={onChange}
                                    error={ERROR_REQUIRE.indexOf("gender") >= 0}
                                  />
                                </Grid>

                                <Grid item md={3} xs={12}>
                                    <InputDate
                                        form={form}
                                        input={{
                                        name: "date_of_birth",
                                        label: "วัน/เดือน/ปี เกิด",
                                        }}
                                        onChange={(date: MaterialUiPickersDate) => {
                                          setVal(
                                            "date_of_birth",
                                              date ? new Date(date.toString()) : null
                                          );

                                          if (date) {
                                              calculateAge(date);
                                              setErrorRequired(
                                              ERROR_REQUIRE.filter((x) => x != "date_of_birth")
                                              );
                                          }
                                        }}
                                        error={ERROR_REQUIRE.indexOf("date_of_birth") >= 0}
                                    />
                                </Grid>
                                
                                {[{ name: "age", label: "อายุ (ปี)" },
                                {name:"relegion", label: "ศาสนา"}
                                ].map(
                                (input) => {
                                    return (
                                    <Grid item md={2} xs={12}>
                                        <InputText
                                        {...{
                                            onChange,
                                            form,
                                            input,
                                            required:
                                            NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                            0,
                                            error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                        }}
                                        />
                                    </Grid>
                                    );
                                }
                                )}

                                <Grid item xs={12} style={{padding: '9px'}}>
                                    <FormControl required={NON_REQUIRE_USER_FIELD.indexOf("gender") < 0}
                                        style={{ width: "100%" }}
                                        size="small"
                                        margin="dense">
                                        <div style={{display: 'flex'}}>
                                            <LabelForm>สถานภาพสมรส: </LabelForm>
                                            <RadioGroup
                                             row aria-label="martialStatus" name="martialStatus" value={martialStatusValue}
                                              onChange={handleChangeMartialStatus} style={{marginLeft: '2%'}}>
                                                <FormControlLabel value="1" control={<Radio  color="primary" />} label="โสด" />
                                                <FormControlLabel value="2" control={<Radio color="primary"/>} label="หม้าย" color="default" />
                                                <FormControlLabel value="3" control={<Radio color="primary"/>} label="แยก" color="default" />
                                                <FormControlLabel value="4" control={<Radio color="primary"/>} label="สมณะ" color="default"/>
                                                <FormControlLabel value="5" control={<Radio color="primary"/>} label="ไม่ทราบ" color="default"/>
                                            </RadioGroup>
                                        </div>
                                        
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <LabelForm>บุคคลที่สามารถติดต่อได้ กรณีฉุกเฉิน</LabelForm>
                                </Grid>

                                {[{ name: "contactPersonName1", label: "ชื่อ" },
                                    {name: "relationship1", label: "ความสัมพันธ์"}
                                    ].map(
                                    (input) => {
                                        return (
                                        <Grid item md={4} xs={12}>
                                            <InputText
                                            {...{
                                                onChange,
                                                form,
                                                input,
                                                required:
                                                NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                0,
                                                error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                            }}
                                            />
                                        </Grid>
                                        );
                                    }
                                )}
                                {[{ name: "contactPersonPhone1", label: "โทร" }].map(
                                    (input) => {
                                        return (
                                        <Grid item md={3} xs={12}>
                                            <InputText
                                            {...{
                                                onChange,
                                                form,
                                                input,
                                                required:
                                                NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                0,
                                                error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                            }}
                                            />
                                        </Grid>
                                        );
                                    }
                                )}

                                <Grid item md ={1} xs={12}>
                                    {
                                        showContactPerson ?
                                        <></>
                                        :  
                                        <AddCircleIcon style={{ fontSize: 40, color: "#6b7280b8", marginTop: '8px' }} 
                                        onClick={() => {setShowContactPerson(true)}} />
                                    }
                                </Grid>

                                {
                                    showContactPerson ?
                                    <>
                                        {[{ name: "contactPersonName2", label: "ชื่อ" },
                                            {name: "relationship2", label: "ความสัมพันธ์"}
                                            ].map(
                                                (input) => {
                                                    return (
                                                    <Grid item md={4} xs={12}>
                                                        <InputText
                                                        {...{
                                                            onChange,
                                                            form,
                                                            input,
                                                            required:
                                                            NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                            0,
                                                            error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                        }}
                                                        />
                                                    </Grid>
                                                    );
                                                }
                                                )}
                                            {[{ name: "contactPersonPhone2", label: "โทร" }].map(
                                                (input) => {
                                                    return (
                                                    <Grid item md={3} xs={12}>
                                                        <InputText
                                                        {...{
                                                            onChange,
                                                            form,
                                                            input,
                                                            required:
                                                            NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                            0,
                                                            error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                        }}
                                                        />
                                                    </Grid>
                                                    );
                                                }
                                                )}
                                            <Grid item md ={1} xs={12}>
                                                {
                                                    showThirdContactPerson ?
                                                    <></>
                                                    :  
                                                    <AddCircleIcon style={{ fontSize: 40, color: "#6b7280b8", marginTop: '8px' }} 
                                                    onClick={() => {setThirdContactPerson(true)}} />
                                                }
                                            </Grid>
                                    </>
                                    :
                                    <></>
                                }

                                {
                                    showThirdContactPerson ?
                                    <>
                                        {[{ name: "contactPersonName3", label: "ชื่อ" },
                                            {name: "relationship3", label: "ความสัมพันธ์"}
                                            ].map(
                                                (input) => {
                                                    return (
                                                    <Grid item md={4} xs={12}>
                                                        <InputText
                                                        {...{
                                                            onChange,
                                                            form,
                                                            input,
                                                            required:
                                                            NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                            0,
                                                            error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                        }}
                                                        />
                                                    </Grid>
                                                    );
                                                }
                                                )}
                                            {[{ name: "contactPersonPhone3", label: "โทร" }].map(
                                                (input) => {
                                                    return (
                                                    <Grid item md={3} xs={12}>
                                                        <InputText
                                                        {...{
                                                            onChange,
                                                            form,
                                                            input,
                                                            required:
                                                            NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                            0,
                                                            error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                        }}
                                                        />
                                                    </Grid>
                                                    );
                                                }
                                                )}
                                    </>
                                    :
                                    <></>
                                }

                        </Grid>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                        <Box style={{border: "1px solid #80808040", marginTop : '3%'}}>
                            <Grid container spacing={1} style={{padding: '7px'}}>
                                
                                <Grid item md={4} xs={12}>
                                    <InputDropdown
                                        option={option["treatmentType"]}
                                        input={{ name: "treatmentType", label: "สิทธิการรักษา" }}
                                        value={form["treatmentType"]}
                                        onChange={onChange}
                                        error={ERROR_REQUIRE.indexOf("treatmentType") >= 0}
                                    />
                                </Grid>

                                {[{ name: "elderAssociation", label: "สมาคมผู้สูงอายุ" },
                                { name: 'emergencyHosptial', label: "กรณีฉุกเฉินให้ส่งไปรักษาที่" }
                                    ].map(
                                    (input) => {
                                        return (
                                        <Grid item md={4} xs={12}>
                                            <InputText
                                            {...{
                                                onChange,
                                                form,
                                                input,
                                                required:
                                                NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                0,
                                                error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                            }}
                                            />
                                        </Grid>
                                        );
                                    }
                                )}

                                <Grid item md={1} xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox checked={NR} onChange={handleChangeSpecialCase} name="NR" color="primary"/>}
                                        label="NR"
                                    />
                                </Grid>

                                <Grid item md={2} xs={6}>
                                  <FormControlLabel
                                        control={<Checkbox checked={noCpr} onChange={handleChangeSpecialCase} name="noCpr" color="primary"/>}
                                        label="No CPR"
                                    />
                                </Grid>
                                <Grid item md={2} xs={6}>
                                  <FormControlLabel
                                      control={<Checkbox checked={noIncubation} onChange={handleChangeSpecialCase} name="noIncubation" color="primary"/>}
                                      label="ไม่ใส่ท่อช่วยหายใจ"
                                  />
                                </Grid>
                                <Grid item md={2} xs={6}>
                                  <FormControlLabel
                                        control={<Checkbox checked={noForward} onChange={handleChangeSpecialCase} name="noForward" color="primary"/>}
                                        label="ไม่ส่งต่อ"
                                    />
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox checked={noHeal} onChange={handleChangeSpecialCase} name="noHeal" color="primary"/>}
                                        label="ไม่รักษา"
                                    />
                                    
                                </Grid>

                                {[{ name: "specifySpecialCase", label: "ระบุ" }
                                        ].map(
                                        (input) => {
                                            return (
                                            <Grid item md={3} xs={12}>
                                                <InputText
                                                {...{
                                                    onChange,
                                                    form,
                                                    input,
                                                    required:
                                                    NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                    0,
                                                    error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                }}
                                                />
                                            </Grid>
                                            );
                                        }
                                )}
                                <Grid item md={2} xs={12}>
                                    <div style={{display: 'flex', padding: '3px'}}>
                                        
                                            <FormControlLabel
                                                control={<Checkbox checked={otherSpecialCase} onChange={handleChangeSpecialCase} name="otherSpecialCase" color="primary"/>}
                                                label="อื่น ๆ"
                                            />
                                        
                                    </div>


                                </Grid>
                                {[{ name: "specifyOtherSpecialCase", label: "ระบุ" }
                                        ].map(
                                        (input) => {
                                            return (
                                            <Grid item md={10} xs={12}>
                                                <InputText
                                                {...{
                                                    onChange,
                                                    form,
                                                    input,
                                                    required:
                                                    NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                    0,
                                                    error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                }}
                                                />
                                            </Grid>
                                            );
                                        }
                                )}
                            </Grid>

                            
                        </Box>
                  </Grid>

                  <Grid item xs={12}>
                        <Box style={{border: "1px solid #80808040", marginTop : '3%'}}>
                            <Grid container spacing={1} style={{padding: '7px'}}>
                                <Grid item xs={12}>
                                    <LabelForm>ประวัติการเจ็บป่วยและการรักษา/โรค/ปัญหาสุขภาพ*</LabelForm>
                                </Grid>
                                <Grid item md={1} xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox checked={HT} onChange={handleChangehDisease} name="HT" color="primary"/>}
                                        label="HT"
                                    />
                                </Grid>
                                <Grid item md={1} xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox checked={DM} onChange={handleChangehDisease} name="DM" color="primary"/>}
                                        label="DM"
                                    />
                                </Grid>
                                <Grid item md={1} xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox checked={HD} onChange={handleChangehDisease} name="HD" color="primary"/>}
                                        label="HD"
                                    />
                                </Grid>
                                <Grid item md={2} xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox checked={Anemina} onChange={handleChangehDisease} name="Anemina" color="primary"/>}
                                        label="Anemina"
                                    />
                                </Grid>
                                <Grid item md={1} xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox checked={HPL} onChange={handleChangehDisease} name="HPL" color="primary"/>}
                                        label="HPL"
                                    />
                                </Grid>
                                <Grid item md={1} xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox checked={CVA} onChange={handleChangehDisease} name="CVA" color="primary"/>}
                                        label="CVA"
                                    />
                                </Grid>
                                <Grid item md={1} xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox checked={CRF} onChange={handleChangehDisease} name="CRF" color="primary"/>}
                                        label="CRF"
                                    />
                                </Grid>
                                <Grid item md={2} xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox checked={Dementia} onChange={handleChangehDisease} name="Dementia" color="primary"/>}
                                        label="Dementia"
                                    />
                                </Grid>
                                <Grid item md={1} xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox checked={CA} onChange={handleChangehDisease} name="CA" color="primary"/>}
                                        label="CA"
                                    />
                                </Grid>
                                <Grid item md={1} xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox checked={otherDisease} onChange={handleChangehDisease} name="otherDisease" color="primary"/>}
                                        label="อื่นๆ"
                                    />
                                </Grid>
                                {
                                  otherDisease ? <>
                                      <Grid item xs={12}>
                                        {[{ name: "specifyOtherDisease", label: "อื่นๆ ระบุ" }
                                                ].map(
                                                (input) => {
                                                    return (
                                                      <Grid item xs={12}>
                                                          <Autocomplete
                                                            multiple
                                                            id="specifyOtherDisease"
                                                            options={diseaseList.map((option : any) => option.name)}
                                                            freeSolo
                                                            onChange={(event, value) =>{
                                                                setVal(
                                                                  input.name,
                                                                  value ? value : ""
                                                                );
                                                                if (value) {
                                                                  setErrorRequired(
                                                                    ERROR_REQUIRE.filter((x) => x != input.name)
                                                                  );
                                                                }
                                                            }}
                                                            renderTags={(value: string[], getTagProps) =>
                                                              value.map((option: string, index: number) => (
                                                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                              ))
                                                            }
                                                            renderInput={(params) => (
                                                              <InputText {...params}
                                                                {...{
                                                                  onChange,
                                                                  form,
                                                                  input,
                                                                  required:
                                                                    NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0,
                                                                  error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                                }}
                                                              />
                                                            )}
                                                          />
                                                      </Grid>
                                                    );
                                                }
                                        )}
                                      
                                    </Grid>
                                  </> :
                                  <></>
                                }
                                
                                <Grid container>
                                  <Grid item md={4}  xs={12} style={{padding: '9px'}}>
                                      <FormControl required={NON_REQUIRE_USER_FIELD.indexOf("allergic") < 0}
                                          style={{ width: "100%" }}
                                          size="small"
                                          margin="dense">
                                          <div style={{display: 'flex'}}>
                                              <LabelForm>การแพ้ยา: </LabelForm>
                                              <RadioGroup
                                              row aria-label="allergic" name="allergic" value={checkAllergic}
                                                onChange={handleChangeCheckAllergic} style={{marginLeft: '4%'}}>
                                                  <FormControlLabel value="1" control={<Radio  color="primary" />} label="ไม่มี" />
                                                  <FormControlLabel value="2" control={<Radio color="primary"/>} label="มี" color="default" />
                                              </RadioGroup>
                                          </div>
                                          
                                      </FormControl>
                                  </Grid>

                                  {
                                      checkAllergic === '2'? 
                                      <>
                                        {[{ name: "specifyAllergic", label: "ระบุ" }
                                              ].map(
                                              (input) => {
                                                  return (
                                                  <Grid item md={8} xs={12} style={{marginTop: "1%"}}>
                                                    <Autocomplete
                                                    multiple
                                                    id="specifyAllergic"
                                                    options={medicineList.map((option : any) => option.name)}
                                                    freeSolo
                                                    onChange={(event, value) =>{
                                                      setVal(
                                                        input.name,
                                                        value ? value : ""
                                                      );
                                                      if (value) {
                                                        setErrorRequired(
                                                          ERROR_REQUIRE.filter((x) => x != input.name)
                                                        );
                                                      }
                                                    }}
                                                    renderTags={(value: any[], getTagProps) =>
                                                        value.map((option: any, index: number) => (
                                                          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                    ))
                                                    }
                                                    renderInput={(params) => (
                                                      <InputText {...params}
                                                        {...{
                                                          onChange,
                                                          form,
                                                          input,
                                                          required:
                                                            NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0,
                                                          error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                        }}
                                                      />
                                                    )}
                                                  />
                                              </Grid>
                                                  );
                                              }
                                      )}
                                      </> : <></>
                                    }
                                </Grid>
                              <Grid container>
                                <Grid item md={4}  xs={12} style={{padding: '9px'}}>
                                      <FormControl required={NON_REQUIRE_USER_FIELD.indexOf("foodAllergic") < 0}
                                          style={{ width: "100%" }}
                                          size="small"
                                          margin="dense">
                                          <div style={{display: 'flex'}}>
                                              <LabelForm>แพ้อาหาร: </LabelForm>
                                              <RadioGroup
                                                row aria-label="foodAllergics" name="foodAllergics" value={checkFoodAllergic}
                                                onChange={handleChangeFoodAllergic} style={{marginLeft: '4%'}}>
                                                  <FormControlLabel value="1" control={<Radio  color="primary" />} label="ไม่มี" />
                                                  <FormControlLabel value="2" control={<Radio color="primary"/>} label="มี" color="default" />
                                              </RadioGroup>
                                          </div>
                                          
                                      </FormControl>
                                  </Grid>

                                  {
                                    checkFoodAllergic === '2'? 
                                    <>
                                    {[{ name: "specifyFoodAllergic", label: "ระบุ" }
                                          ].map(
                                          (input) => {
                                              return (
                                              <Grid item md={8} xs={12} style={{marginTop: "1%"}}>
                                                <Autocomplete
                                                multiple
                                                id="specifyFoodAllergic"
                                                options={option["foodAllergies"].map((option : any) => option.name)}
                                                freeSolo
                                                onChange={(event, value) =>{
                                                  setVal(
                                                    input.name,
                                                    value ? value : ""
                                                  );
                                                  if (value) {
                                                    setErrorRequired(
                                                      ERROR_REQUIRE.filter((x) => x != input.name)
                                                    );
                                                  }
                                                }}
                                                renderTags={(value: any[], getTagProps) =>
                                                  value.map((option: any, index: number) => (
                                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                  ))
                                                }
                                                renderInput={(params) => (
                                                  <InputText {...params}
                                                    {...{
                                                      onChange,
                                                      form,
                                                      input,
                                                      required:
                                                        NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0,
                                                      error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                    }}
                                                  />
                                                )}
                                              />
                                          </Grid>
                                              );
                                          }
                                  )}
                                  </> : <></>
                                }
                              </Grid>
                              
                            </Grid>
                        </Box>
                        
                  </Grid>
                  
                  <Grid item xs={12}>
                    <Box style={{border: "1px solid #80808040", marginTop : '3%'}}>
                      <Grid container spacing={1} style={{padding: '7px'}}>
                        <Grid item xs={12}>
                            <LabelForm>อาการแรกรับ</LabelForm>
                        </Grid>

                        {[{ name: "weight", label: "น้ําหนัก(kg)" },
                         {name: "height", label: "ส่วนสูง(cm)" },
                                        ].map(
                                        (input) => {
                                            return (
                                            <Grid item md={6} xs={12} style={{marginTop: "1%"}}>
                                                <InputText
                                                {...{
                                                    onChange,
                                                    form,
                                                    input,
                                                    required:
                                                    NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                    0,
                                                    error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                }}
                                                />
                                            </Grid>
                                            );
                                        }
                        )}

                        <Grid item xs={12} style={{paddingTop: '27px'}}>
                            Vital Signs:
                        </Grid>
                        {[
                         {name: "temp", label: "Temp(C)" },
                         {name: "heart_rate", label: "Heart Rate" },
                         {name: "blood_diastolic", label: "Blood Diastolic" },
                         {name: "blood_systolic", label: "Blood Systolic" },
                         { name: "bloodSugar", label: "Blood Sugar" },
                         {name: "o2", label: "O2 Sat(%)" },
                            ].map(
                            (input) => {
                                return (
                                <Grid item md={2} xs={12} style={{marginTop: "1%"}}>
                                    <InputText
                                    {...{
                                        onChange,
                                        form,
                                        input,
                                        required:
                                        NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                        0,
                                        error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                    }}
                                    />
                                </Grid>
                                );
                            }
                        )}

                          <Grid item md={2} xs={12} style={{paddingTop: '27px'}}>
                            ระดับความรู้สึกตัว:
                          </Grid>
                          
                          <Grid item md={3} xs={12}>
                              <InputDropdown
                                  option={option["eyesOptions"]}
                                  input={{ name: "eyesOptions", label: "E" }}
                                  value={form["eyesOptions"]}
                                  onChange={onChange}
                                  error={ERROR_REQUIRE.indexOf("eyesOptions") >= 0}
                              />
                          </Grid>

                          <Grid item md={3} xs={12}>
                              <InputDropdown
                                  option={option["armOptions"]}
                                  input={{ name: "armOptions", label: "M" }}
                                  value={form["armOptions"]}
                                  onChange={onChange}
                                  error={ERROR_REQUIRE.indexOf("armOptions") >= 0}
                              />
                          </Grid>

                          <Grid item md={4} xs={12}>
                              <InputDropdown
                                  option={option["speakingOptions"]}
                                  input={{ name: "speakingOptions", label: "V" }}
                                  value={form["speakingOptions"]}
                                  onChange={onChange}
                                  error={ERROR_REQUIRE.indexOf("speakingOptions") >= 0}
                              />
                          </Grid>
                          
                          <Grid item md={2} xs={12} style={{paddingTop: '14px'}}>
                              การหายใจ*:
                          </Grid>
                          <Grid item md={3} xs={6}>
                              <FormControlLabel
                                  control={<Checkbox checked={canBreathe} onChange={handleChangeBreathing} name="canBreathe" color="primary"/>}
                                  label="หายใจได้"
                              />
                          </Grid>

                          <Grid item md={3} xs={6}>
                              <FormControlLabel
                                  control={<Checkbox checked={onBiPap} onChange={handleChangeBreathing} name="onBiPap" color="primary"/>}
                                  label="on BiPap"
                              />
                          </Grid>

                          <Grid item md={3} xs={6}>
                              <FormControlLabel
                                  control={<Checkbox checked={onCPap} onChange={handleChangeBreathing} name="onCPap" color="primary"/>}
                                  label="on CPap"
                              />
                          </Grid>

                          <Grid item md={2} xs={12}></Grid>
                          
                          <Grid item md={3} xs={6} style={{paddingTop: '11px'}}>
                              <FormControlLabel
                                  control={<Checkbox checked={onTube} onChange={handleChangeBreathing} name="onTube" color="primary"/>}
                                  label="Tracheostomy Tube"
                              />
                          </Grid>

                          <Grid item md={3} xs={6} style={{paddingTop: '11px'}}>
                              <FormControlLabel
                                  control={<Checkbox checked={onO2} onChange={handleChangeBreathing} name="onO2" color="primary"/>}
                                  label="on O2"
                              />
                          </Grid>

                          {[{ name: "specifyBreathing", label: "ระบุ" }].map(
                                (input) => {
                                    return (
                                    <Grid item md={4} xs={12}>
                                        <InputText
                                        {...{
                                            onChange,
                                            form,
                                            input,
                                            required:
                                            NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                            0,
                                            error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                        }}
                                        />
                                    </Grid>
                                    );
                                }
                            )}

                            <Grid item md={2} xs={6} style={{paddingTop: '11px'}}>
                              <FormControlLabel
                                  control={<Checkbox checked={isTired} onChange={handleChangeCoughing} name="isTired" color="primary"/>}
                                  label="หอบเหนื่อย"
                              />
                            </Grid>

                            <Grid item md={2} xs={6} style={{paddingTop: '11px'}}>
                              <FormControlLabel
                                  control={<Checkbox checked={isCough} onChange={handleChangeCoughing} name="isCough" color="primary"/>}
                                  label="ไอ"
                              />
                            </Grid>

                            <Grid item md={2} xs={6} style={{paddingTop: '11px'}}>
                              <FormControlLabel
                                  control={<Checkbox checked={isSputum} onChange={handleChangeCoughing} name="isSputum" color="primary"/>}
                                  label="มีเสมหะ"
                              />
                            </Grid>

                            <Grid item md={2} xs={6} style={{paddingTop: '11px'}}>
                              <FormControlLabel
                                  control={<Checkbox checked={otherCoughCase} onChange={handleChangeCoughing} name="otherCoughCase" color="primary"/>}
                                  label="อื่นๆ"
                              />
                            </Grid>

                            {[{ name: "specifyCoughCase", label: "อื่น ๆ ระบุ" }].map(
                                (input) => {
                                    return (
                                    <Grid item md={4} xs={12}>
                                        <InputText
                                        {...{
                                            onChange,
                                            form,
                                            input,
                                            required:
                                            NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                            0,
                                            error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                        }}
                                        />
                                    </Grid>
                                    );
                                }
                            )}

                            <Grid item md={4} xs={12}>
                                <InputDropdown
                                    option={option["eatingOptions"]}
                                    input={{ name: "eatingOptions", label: "การรับประทานอาหาร" }}
                                    value={form["eatingOptions"]}
                                    onChange={onChange}
                                    error={ERROR_REQUIRE.indexOf("eatingOptions") >= 0}
                                />
                            </Grid>

                            {[{ name: "specifyFood", label: "ระบุอาหาร* (ปกติ/อ่อน/เฉพาะโรค/พลังงาน/ปริมาณ)" }].map(
                                (input) => {
                                    return (
                                    <Grid item md={8} xs={12}>
                                        <InputText
                                        {...{
                                            onChange,
                                            form,
                                            input,
                                            required:
                                            NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                            0,
                                            error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                        }}
                                        />
                                    </Grid>
                                    );
                                }
                            )}

                                <Grid item xs={12} style={{padding: '9px'}}>
                                    <FormControl required={NON_REQUIRE_USER_FIELD.indexOf("excretion") < 0}
                                        style={{ width: "100%" }}
                                        size="small"
                                        margin="dense">
                                        <div style={{display: 'flex'}}>
                                            <LabelForm>การขับถ่าย*: </LabelForm>
                                            <RadioGroup
                                             row aria-label="excretion" name="excretion" value={checkExcretion}
                                              onChange={handleChangeCheckExcreion} style={{marginLeft: '4%'}}>
                                                <FormControlLabel value="1" control={<Radio  color="primary" />} label="ไม่มีปัญหา" />
                                                <FormControlLabel value="2" control={<Radio color="primary"/>} label="มีปัญหา"/>
                                                <FormControlLabel value="3" control={<Radio color="primary"/>} label="ใส่ผ้าอ้อม"/>
                                                <FormControlLabel value="4" control={<Radio color="primary"/>} label="on Foley's Cath"/>
                                                <FormControlLabel value="5" control={<Radio color="primary"/>} label="อื่นๆ"/>
                                            </RadioGroup>
                                        </div>
                                        
                                    </FormControl>
                                </Grid>

                                {
                                  checkExcretion === "2" ? 
                                  <>
                                    {[{ name: "excretionProblem", label: "มีปัญหา? ระบุ" }
                                    ].map(
                                        (input) => {
                                            return (
                                            <Grid item xs={12}>
                                                <InputText
                                                {...{
                                                    onChange,
                                                    form,
                                                    input,
                                                    required:
                                                    NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                    0,
                                                    error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                }}
                                                />
                                            </Grid>
                                            );
                                        }
                                    )}
                                  </>
                                  :
                                  checkExcretion === "5" ? 
                                  <>
                                      {[{ name: "specifyExcretion", label: "อื่นๆ, ระบุ" }
                                        ].map(
                                            (input) => {
                                                return (
                                                <Grid item xs={12}>
                                                    <InputText
                                                    {...{
                                                        onChange,
                                                        form,
                                                        input,
                                                        required:
                                                        NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                        0,
                                                        error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                    }}
                                                    />
                                                </Grid>
                                                );
                                            }
                                        )}
                                  </>
                                  :
                                  <></>
                                }

                                  <Grid item md={5} xs={12} style={{padding: '9px'}}>
                                      <FormControl required={NON_REQUIRE_USER_FIELD.indexOf("weakness") < 0}
                                          style={{ width: "100%" }}
                                          size="small"
                                          margin="dense">
                                          <div style={{display: 'flex'}}>
                                              <LabelForm>อาการอ่อนแรง/ชา* : </LabelForm>
                                              <RadioGroup
                                              row aria-label="weakness" name="weakness" value={checkWeakness}
                                                onChange={handleChangeCheckWeakness} style={{marginLeft: '4%'}}>
                                                  <FormControlLabel value="1" control={<Radio  color="primary" />} label="ไม่มี" />
                                                  <FormControlLabel value="2" control={<Radio color="primary"/>} label="มี"/>
                                              </RadioGroup>
                                          </div>
                                          
                                      </FormControl>
                                </Grid>
                                {
                                  checkWeakness === '2' ?
                                  <>
                                      {[{ name: "specifyWeakness", label: "ระบุ" }
                                        ].map(
                                            (input) => {
                                                return (
                                                <Grid item md={7} xs={12} style={{marginTop: "0.5%"}}>
                                                    <InputText
                                                    {...{
                                                        onChange,
                                                        form,
                                                        input,
                                                        required:
                                                        NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                        0,
                                                        error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                    }}
                                                    />
                                                </Grid>
                                                );
                                            }
                                        )}
                                  </>
                                  :<></>
                                }
                                
                                <Grid container>
                                  <Grid item md={5} xs={12} style={{padding: '9px'}}>
                                        <FormControl required={NON_REQUIRE_USER_FIELD.indexOf("swelling") < 0}
                                            style={{ width: "100%" }}
                                            size="small"
                                            margin="dense">
                                            <div style={{display: 'flex'}}>
                                                <LabelForm>อาการบวม* : </LabelForm>
                                                <RadioGroup
                                                row aria-label="swelling" name="swelling" value={checkSwelling}
                                                  onChange={handleChangeCheckSwelling} style={{marginLeft: '4%'}}>
                                                    <FormControlLabel value="1" control={<Radio  color="primary" />} label="ไม่มี" />
                                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="มี"/>
                                                </RadioGroup>
                                            </div>
                                            
                                        </FormControl>
                                  </Grid>

                                  {
                                    checkSwelling === '2' ?
                                    <>
                                        {[{ name: "specifySwelling", label: "ระบุ" }
                                          ].map(
                                              (input) => {
                                                  return (
                                                  <Grid item md={7} xs={12} style={{marginTop: "0.5%"}}>
                                                      <InputText
                                                      {...{
                                                          onChange,
                                                          form,
                                                          input,
                                                          required:
                                                          NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                          0,
                                                          error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                      }}
                                                      />
                                                  </Grid>
                                                  );
                                              }
                                          )}
                                    </>
                                    :
                                    <></>
                                  }
                                </Grid>
                                <Grid container>
                                  <Grid item md={8} xs={12} style={{padding: '9px'}}>
                                      <FormControl required={NON_REQUIRE_USER_FIELD.indexOf("wound") < 0}
                                          style={{ width: "100%" }}
                                          size="small"
                                          margin="dense">
                                          <div style={{display: 'flex'}}>
                                              <LabelForm>แผล/แผลกดทับ*: </LabelForm>
                                              <RadioGroup
                                              row aria-label="wound" name="wound" value={checkWound}
                                                onChange={handleChangeCheckWound} style={{marginLeft: '4%'}}>
                                                  <FormControlLabel value="1" control={<Radio  color="primary" />} label="ไม่เสี่ยง" />
                                                  <FormControlLabel value="2" control={<Radio color="primary"/>} label="เสี่ยง"/>
                                                  <FormControlLabel value="3" control={<Radio color="primary"/>} label="ไม่มีแผล"/>
                                                  <FormControlLabel value="4" control={<Radio color="primary"/>} label="มีแผล"/>
                                              </RadioGroup>
                                          </div>
                                          
                                      </FormControl>
                                  </Grid>

                                  {
                                    checkWound === '4' ?
                                    <>
                                        {[{ name: "specifyWound", label: "ระบุ" }
                                          ].map(
                                              (input) => {
                                                  return (
                                                  <Grid item md={4} xs={12} style={{marginTop: '0.5%'}}>
                                                      <InputText
                                                      {...{
                                                          onChange,
                                                          form,
                                                          input,
                                                          required:
                                                          NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                          0,
                                                          error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                      }}
                                                      />
                                                  </Grid>
                                                  );
                                              }
                                      )}
                                    </>
                                    :<></>
                                  }
                                </Grid>

                                
                                <Grid container>
                                  <Grid item md={5} xs={12} style={{padding: '9px'}}>
                                        <FormControl required={NON_REQUIRE_USER_FIELD.indexOf("rash") < 0}
                                            style={{ width: "100%" }}
                                            size="small"
                                            margin="dense">
                                            <div style={{display: 'flex'}}>
                                                <LabelForm>ผื่น/คัน* : </LabelForm>
                                                <RadioGroup
                                                row aria-label="rash" name="rash" value={checkRash}
                                                  onChange={handleChangeCheckRash} style={{marginLeft: '4%'}}>
                                                    <FormControlLabel value="1" control={<Radio  color="primary" />} label="ไม่มี" />
                                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="มี"/>
                                                </RadioGroup>
                                            </div>
                                            
                                        </FormControl>
                                  </Grid>

                                  {
                                    checkRash === '2' ?
                                    <>
                                        {[{ name: "specifyRash", label: "ระบุ" }
                                          ].map(
                                              (input) => {
                                                  return (
                                                  <Grid item md={7} xs={12} style={{marginTop: "0.5%"}}>
                                                      <InputText
                                                      {...{
                                                          onChange,
                                                          form,
                                                          input,
                                                          required:
                                                          NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                          0,
                                                          error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                      }}
                                                      />
                                                  </Grid>
                                                  );
                                              }
                                          )}
                                    </>
                                    :
                                    <></>
                                  }
                                </Grid>
                                
                                <Grid container>
                                  <Grid item md={5} xs={12} style={{padding: '9px'}}>
                                        <FormControl required={NON_REQUIRE_USER_FIELD.indexOf("pain") < 0}
                                            style={{ width: "100%" }}
                                            size="small"
                                            margin="dense">
                                            <div style={{display: 'flex'}}>
                                                <LabelForm>อาการปวด* : </LabelForm>
                                                <RadioGroup
                                                row aria-label="pain" name="pain" value={checkPain}
                                                  onChange={handleChangeCheckPain} style={{marginLeft: '4%'}}>
                                                    <FormControlLabel value="1" control={<Radio  color="primary" />} label="ไม่มี" />
                                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="มี"/>
                                                </RadioGroup>
                                            </div>
                                            
                                        </FormControl>
                                  </Grid>

                                  {
                                    checkPain === '2'?
                                    <>
                                        {[{ name: "specifyPain", label: "ระบุ" }
                                          ].map(
                                              (input) => {
                                                  return (
                                                  <Grid item md={7} xs={12} style={{marginTop: "0.5%"}}>
                                                      <InputText
                                                      {...{
                                                          onChange,
                                                          form,
                                                          input,
                                                          required:
                                                          NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                          0,
                                                          error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                      }}
                                                      />
                                                  </Grid>
                                                  );
                                              }
                                          )}
                                    </>
                                    :<></>
                                  }
                                </Grid>

                                <Grid container>
                                  <Grid item md={5} xs={12} style={{padding: '9px'}}>
                                        <FormControl required={NON_REQUIRE_USER_FIELD.indexOf("vision") < 0}
                                            style={{ width: "100%" }}
                                            size="small"
                                            margin="dense">
                                            <div style={{display: 'flex'}}>
                                                <LabelForm>การมองเห็น* : </LabelForm>
                                                <RadioGroup
                                                row aria-label="vision" name="vision" value={checkVision}
                                                  onChange={handleChangeCheckVision} style={{marginLeft: '4%'}}>
                                                    <FormControlLabel value="1" control={<Radio  color="primary" />} label="ปกติ" />
                                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="ไม่ปกติ"/>
                                                </RadioGroup>
                                            </div>
                                            
                                        </FormControl>
                                  </Grid>

                                  {
                                    checkVision ==='2'?
                                    <>
                                        {[{ name: "specifyVision", label: "ระบุ" }
                                          ].map(
                                              (input) => {
                                                  return (
                                                  <Grid item md={7} xs={12} style={{marginTop: "0.5%"}}>
                                                      <InputText
                                                      {...{
                                                          onChange,
                                                          form,
                                                          input,
                                                          required:
                                                          NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                          0,
                                                          error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                      }}
                                                      />
                                                  </Grid>
                                                  );
                                              }
                                          )}
                                    </>
                                    :
                                    <></>
                                  }
                                </Grid>

                                <Grid container>
                                  <Grid item md={5} xs={12} style={{padding: '9px'}}>
                                        <FormControl required={NON_REQUIRE_USER_FIELD.indexOf("hearing") < 0}
                                            style={{ width: "100%" }}
                                            size="small"
                                            margin="dense">
                                            <div style={{display: 'flex'}}>
                                                <LabelForm>การได้ยิน* : </LabelForm>
                                                <RadioGroup
                                                row aria-label="hearing" name="hearing" value={checkHearing}
                                                  onChange={handleChangeCheckHearing} style={{marginLeft: '4%'}}>
                                                    <FormControlLabel value="1" control={<Radio  color="primary" />} label="ปกติ" />
                                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="ไม่ปกติ"/>
                                                </RadioGroup>
                                            </div>
                                            
                                        </FormControl>
                                  </Grid>
                                  {
                                    checkHearing === "2" ?
                                    <>
                                        {[{ name: "specifyHearing", label: "ระบุ" }
                                          ].map(
                                              (input) => {
                                                  return (
                                                  <Grid item md={7} xs={12} style={{marginTop: "0.5%"}}>
                                                      <InputText
                                                      {...{
                                                          onChange,
                                                          form,
                                                          input,
                                                          required:
                                                          NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                          0,
                                                          error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                      }}
                                                      />
                                                  </Grid>
                                                  );
                                              }
                                          )}
                                    </>
                                    :
                                    <></>
                                  }
                                </Grid>
                                <Grid container>
                                    <Grid item md={5} xs={12} style={{padding: '9px'}}>
                                          <FormControl required={NON_REQUIRE_USER_FIELD.indexOf("sleep") < 0}
                                              style={{ width: "100%" }}
                                              size="small"
                                              margin="dense">
                                              <div style={{display: 'flex'}}>
                                                  <LabelForm>การนอนหลับ : </LabelForm>
                                                  <RadioGroup
                                                  row aria-label="sleep" name="sleep" value={checkSleep}
                                                    onChange={handleChangeCheckSleep} style={{marginLeft: '4%'}}>
                                                      <FormControlLabel value="1" control={<Radio  color="primary" />} label="ปกติ" />
                                                      <FormControlLabel value="2" control={<Radio color="primary"/>} label="ไม่ปกติ"/>
                                                  </RadioGroup>
                                              </div>
                                              
                                          </FormControl>
                                    </Grid>

                                    {
                                      checkSleep === '2' ?
                                      <>
                                          {[{ name: "specifySleep", label: "ระบุ" }
                                            ].map(
                                                (input) => {
                                                    return (
                                                    <Grid item md={7} xs={12} style={{marginTop: "0.5%"}}>
                                                        <InputText
                                                        {...{
                                                            onChange,
                                                            form,
                                                            input,
                                                            required:
                                                            NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                            0,
                                                            error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                        }}
                                                        />
                                                    </Grid>
                                                    );
                                                }
                                            )}
                                      </>
                                      :
                                      <></>
                                    }
                                </Grid>
                                
                                <Grid container>
                                  <Grid item md={6} xs={12} style={{padding: '9px'}}>
                                        <FormControl required={NON_REQUIRE_USER_FIELD.indexOf("mentalState") < 0}
                                            style={{ width: "100%" }}
                                            size="small"
                                            margin="dense">
                                            <div style={{display: 'flex'}}>
                                                <LabelForm>สภาพอารมณ์/จิตใจ : </LabelForm>
                                                <RadioGroup
                                                row aria-label="mentalState" name="mentalState" value={checkMentalState}
                                                  onChange={handleChangeCheckMentalState} style={{marginLeft: '4%'}}>
                                                    <FormControlLabel value="1" control={<Radio  color="primary" />} label="ปกติ" />
                                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="ไม่ปกติ"/>
                                                </RadioGroup>
                                            </div>
                                            
                                        </FormControl>
                                  </Grid>

                                  {
                                    checkMentalState === '2' ?
                                    <>
                                        {[{ name: "specifyMentalState", label: "ระบุ" }
                                          ].map(
                                              (input) => {
                                                  return (
                                                  <Grid item md={6} xs={12} style={{marginTop: "0.5%"}}>
                                                      <InputText
                                                      {...{
                                                          onChange,
                                                          form,
                                                          input,
                                                          required:
                                                          NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                          0,
                                                          error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                      }}
                                                      />
                                                  </Grid>
                                                  );
                                              }
                                          )}
                                    </>
                                    :<></>
                                  }
                                </Grid>
                              
                                <Grid container>
                                  <Grid item md={6} xs={12} style={{padding: '9px'}}>
                                        <FormControl required={NON_REQUIRE_USER_FIELD.indexOf("riskAssessment") < 0}
                                            style={{ width: "100%" }}
                                            size="small"
                                            margin="dense">
                                            <div style={{display: 'flex'}}>
                                                <LabelForm>Fall Risk Assessment : </LabelForm>
                                                <RadioGroup
                                                row aria-label="riskAssessment" name="riskAssessment" value={checkRiskAssessment}
                                                  onChange={handleChangeCheckRisk} style={{marginLeft: '4%'}}>
                                                    <FormControlLabel value="1" control={<Radio  color="primary" />} label="ปกติ" />
                                                    <FormControlLabel value="2" control={<Radio color="primary"/>} label="ไม่ปกติ"/>
                                                </RadioGroup>
                                            </div>
                                            
                                        </FormControl>
                                  </Grid>
                                  {
                                    checkRiskAssessment === '2'? 
                                    <>
                                        {[{ name: "specifyRisk", label: "ระบุ" }
                                          ].map(
                                              (input) => {
                                                  return (
                                                  <Grid item md={6} xs={12} style={{marginTop: "0.5%"}}>
                                                      <InputText
                                                      {...{
                                                          onChange,
                                                          form,
                                                          input,
                                                          required:
                                                          NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                          0,
                                                          error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                      }}
                                                      />
                                                  </Grid>
                                                  );
                                              }
                                          )}
                                    </>
                                    :<></>
                                  }
                                </Grid>
                      </Grid> 
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                        <Box style={{border: "1px solid #80808040", marginTop : '3%'}}>
                          <Grid container spacing={1} style={{padding: '7px'}}>
                            <Grid item xs={12}>
                                <LabelForm>ความสามารถในการดูแลตนเอง</LabelForm>
                            </Grid>
                            <Grid item xs={12} style={{padding: '9px'}}>
                                    <FormControl required={NON_REQUIRE_USER_FIELD.indexOf("selfCare") < 0}
                                        style={{ width: "100%" }}
                                        size="small"
                                        margin="dense">
                                        <div style={{display: 'flex'}}>
                                            {/* <LabelForm>Fall Risk Assessment : </LabelForm> */}
                                            <RadioGroup
                                            row aria-label="slefCare" name="selfCare" value={checkSelfcare}
                                              onChange={handleChangeCheckSelfcare} style={{marginLeft: '4%'}}>
                                                <FormControlLabel value="1" control={<Radio  color="primary" />} label="ดูแลตัวเองได้ดี" />
                                                <FormControlLabel value="2" control={<Radio color="primary"/>} label="ดูแลตัวเองได้บางส่วน"/>
                                                <FormControlLabel value="3" control={<Radio color="primary"/>} label="ไม่สามารถช่วยเหลือตัวเองได้"/>
                                            </RadioGroup>
                                        </div>
                                    </FormControl>
                              </Grid>
                              <Grid container spacing={1}>
                                    <Grid item md={5} xs={12} style={{paddingTop: '19px', paddingLeft: '10px'}}>
                                      รายละเอียดอื่นๆ / ความต้องการในการดูแล:
                                    </Grid>
                                    {[{ name: "specifySelfcare", label: "ระบุ" }
                                      ].map(
                                          (input) => {
                                              return (
                                              <Grid item md={7} xs={12}>
                                                  <InputText
                                                  {...{
                                                      onChange,
                                                      form,
                                                      input,
                                                      required:
                                                      NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                                      0,
                                                      error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                  }}
                                                  />
                                              </Grid>
                                              );
                                          }
                                      )}
                              </Grid>
                          </Grid>
                        </Box>
                  </Grid>

                  <Grid item xs={12}>
                        <Box style={{border: "1px solid #80808040", marginTop : '3%'}}>
                          <Grid container spacing={1} style={{padding: '7px'}}>
                              <Grid item xs={12} style={{padding: '9px'}}>
                                  <FormControl required={NON_REQUIRE_USER_FIELD.indexOf("currentDrug") < 0}
                                      style={{ width: "100%" }}
                                      size="small"
                                      margin="dense">
                                      <div style={{display: 'flex'}}>
                                          <LabelForm>ยาที่ใช้ปัจจุบัน : </LabelForm>
                                          <RadioGroup
                                          row aria-label="currentDrug" name="currentDrug" value={currentDrug}
                                            onChange={handleChangeCheckCurrentDrug} style={{marginLeft: '4%'}}>
                                              <FormControlLabel value="1" control={<Radio  color="primary" />} label="ไม่มี" />
                                              <FormControlLabel value="2" control={<Radio color="primary"/>} label="มี ระบุ"/>
                                          </RadioGroup>
                                      </div>
                                      
                                  </FormControl>
                              </Grid>
                              {
                                currentDrug === '2'? 
                                <>
                                  {[{ name: "specifyCurrentDrug", label: "ระบุ" }
                                  ].map(
                                      (input) => {
                                          return (

                                            <Grid item xs={12} style={{marginTop: '0.5%'}}>
                                            <Autocomplete
                                              multiple
                                              id="specifyCurrentDrug"
                                              options={medicineList.map((option : any) => option.name)}
                                              freeSolo
                                              onChange={(event, value) =>{
                                                setVal(
                                                  input.name,
                                                  value ? value : ""
                                                );
                                                if (value) {
                                                  setErrorRequired(
                                                    ERROR_REQUIRE.filter((x) => x != input.name)
                                                  );
                                                }
                                              }}
                                              renderTags={(value: string[], getTagProps) =>
                                                value.map((option: string, index: number) => (
                                                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                ))
                                              }
                                              renderInput={(params) => (
                                                <InputText {...params}
                                                  {...{
                                                    onChange,
                                                    form,
                                                    input,
                                                    required:
                                                      NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0,
                                                    error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                                  }}
                                                />
                                              )}
                                            />
                                        </Grid>

                                          );
                                      }
                                  )}
                                </> : <></>
                              }
                          </Grid>
                        </Box>
                  </Grid>
              </Box>
              <Grid item xs={12}>
                    <Grid item md={12} xs={12}>
                      <Box mt={6}>
                        <Button
                          fullWidth
                          // css={{ backgroundColor: "#279825", color: "#fff" }}
                          onClick={async () => {
                            setErrorRequired(Array());
                            var valid = _.every(_.keys(form), (v, i) => {
                              if (NON_REQUIRE_USER_FIELD.indexOf(v) >= 0) {
                                return true;
                              }
                              const f: any = form;
                              return f[v] !== null && f[v] !== "";
                            });

                            if (valid) {
                                setIsConfirm(true);          
                            } else {
                              setErrorRequired(() => {
                                return _.keys(form).map((v) => {
                                  console.log("set error requried.")
                                  const f: any = form;
                                  if (
                                    NON_REQUIRE_USER_FIELD.indexOf(v) < 0 &&
                                    (f[v] == null || f[v] == "")
                                  ) {
                                    return v;
                                  }
                                });
                              });
                            }
                          }}
                          size="large"
                          variant="contained"
                          color="primary"
                        >
                          {loading_user_create_SI && (
                            <CircularProgress size="1rem" color="inherit" />
                          )}
                          Submit
                        </Button>
                      </Box>
                    </Grid>
                </Grid>

                <ConfirmModal
                      isConfirm={isConfirm}
                      setIsConfirm={setIsConfirm}
                      onClick={async () => {
                        setLoading(true);
                        try {
                          fire_user_create_SI(form)
                            .then(() => {
                              history.goBack();
                              if(localStorage.getItem("projectKey") === "gmjsfwyncjckmfywgiswygpkmekjrqmsuifwnjnc"){
                                enqueueSnackbar("Registartion Success", {
                                  variant: "success",
                                  autoHideDuration: 3000,
                                  anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                  },
                                });
                              }else{
                                enqueueSnackbar("สมัครสมาชิกสำเร็จ", {
                                  variant: "success",
                                  autoHideDuration: 3000,
                                  anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                  },
                                });
                              }
                            })
                            .catch((ex) => {
                              if(localStorage.getItem("projectKey") === "gmjsfwyncjckmfywgiswygpkmekjrqmsuifwnjnc"){
                                enqueueSnackbar("Failed to register", {
                                  variant: "success",
                                  autoHideDuration: 3000,
                                  anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                  },
                                });
                              }else{
                                enqueueSnackbar(
                                  "สมัครสมาชิกไม่สำเร็จ" +
                                    (ex ? " : " + ex.toString() : ""),
                                  {
                                    variant: "error",
                                    autoHideDuration: 3000,
                                    anchorOrigin: {
                                      vertical: "top",
                                      horizontal: "center",
                                    },
                                  }
                                );
                              }
                              
                            });
                        } catch (ex) {}
                        setIsConfirm(false);
                        setLoading(false);
                        
                      }}
                    />

            </Grid>
          </Box>
        </Paper>
      </Container>
    </>
  );
});
export const UserCreateSIMobile = UserCreateSI;
