import React, { Fragment, useEffect, useState, useRef } from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import ReactDOM from "react-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  Container,
  Grid,
  GridList,
  GridListTile,
  Paper,
  LinearProgress,
  Typography,
  Select,
  MenuItem,
  Badge,
  FormControl,
  FormLabel ,
  FormControlLabel ,
  RadioGroup ,
  Radio,
  AccordionDetails,
  AccordionSummary,
  Modal
} from "@material-ui/core";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { webStore } from "../../stores/webStore";
import { DataGrid, ColDef, ValueGetterParams } from "@material-ui/data-grid";

import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useReadUserByGender } from "../../services/api/dashboard_report/useReadUserByGender";

import { useReadUserByAge } from "../../services/api/dashboard_report/useReadUserByAge";
import { useReadUser } from "../../services/api/user/useReadUser";
import { useListUser } from "../../services/api/user/useListUser";
import { useReadLengthOfStay } from "../../services/api/dashboard_report/useReadLengthOfStay";
import {
  Chart,
  PieSeries,
  Title,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";

import { Animation } from "@devexpress/dx-react-chart";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useListSOSUser } from "../../services/api/user/useListSOSUser";
import { useListSOSUserComplete } from "../../services/api/user/useListSOSUserComplete";
import { dateStr, dateStrAPI,dateString,dateStringFullMothName,dateStrFormatTime,timeStr,dateStrFormat } from "../../utils";
import { User, UserSOS } from "../../types/user";
import WarningIcon from "@material-ui/icons/Warning";
import { DeviceValueTab, OnlineTab } from "../Dashboard/CardUserList";
import { userDevice } from "../../types/device";
import CheckIcon from "@material-ui/icons/Check";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Pagination from "@material-ui/lab/Pagination";
import { Places } from "../../types/places";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import DoneIcon from '@material-ui/icons/Done';
import AgoraRTC from "agora-rtc-sdk-ng";
import VideocamIcon from '@material-ui/icons/Videocam';
import MuiAccordion from "@material-ui/core/Accordion";
import {
    ClientConfig,
    IAgoraRTCRemoteUser,
    ICameraVideoTrack,
    IMicrophoneAudioTrack,
  } from "agora-rtc-sdk-ng";

  import {
    AgoraVideoPlayer,
    createClient,
    createMicrophoneAndCameraTracks,
  } from "agora-rtc-react";
  
import AgoraUIKit,{ layout }from 'agora-react-uikit';
import { SubBar } from "../Dashboard/DashboardSI";
import  BlueUserIcon  from '../../components/assets/BlueUserIcon.jpg';
import PersonIcon from '@material-ui/icons/Person';
import LuxonUtils from "@date-io/luxon";
import { GetPatientData,GetBookingInfoById } from "../../services/api/telemedicine/BookingService";  
import {
    GetUserProfile
  } from "../../services/api/user/useReadUser";
import CloseIcon from "@material-ui/icons/Close";
import TextField from '@material-ui/core/TextField';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AppointmentJsonData from './Booking.json';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    // textField: {
    //   marginLeft: theme.spacing(1),
    // //   marginRight: theme.spacing(1),
    //   width: '100%'
    // },
  }),
);


const PostponeModal = observer(
    ({
      isPostpone, 
      setIsPostpone,
      postponeDate,
      setPostponeDate,
      postponeTime, 
      setPostponeTime,
      isSuccessPostpone,
      setIsSuccessPostpone
    }: {
      isPostpone: boolean;
      setIsPostpone: any;
      postponeDate : Date;
      setPostponeDate: any;
      postponeTime : string;
      setPostponeTime : any;
      isSuccessPostpone: boolean;
      setIsSuccessPostpone: any;
    }) => {
      return (
        <Modal
          open={isPostpone}
          onClose={() => {}}
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            css={{ backgroundColor: "#fff", width: 500, borderRadius: 30, border: 'solid 2px #3f51b5' }}
            p={2}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
              mt={1}
            >
              <Box css={{ fontWeight: 600}}>
                <h3 style={{fontSize : "20px" }}>Postpone</h3> 
              </Box>
              <Box
                onClick={() => {
                  setIsPostpone(false);
                //   setCalenderBookingValue({note: ""});
                }}
              >
                <CloseIcon css={{ cursor: "pointer" }} />
              </Box>
            </Box>
            <br/>
                
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <KeyboardDatePicker
                            style={{
                            margin: 0,
                            width: "100%",
                            backgroundColor: "#fff",
                            }}
                            
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="from"
                            inputVariant="outlined"
                            label="Select Date"
                            autoOk={true}
                            value={postponeDate}
                            onChange={(date) => {
                                setPostponeDate(date ? new Date(date.toString()) : null);
                            // onSearch();
                            }}
                            KeyboardButtonProps={{
                            "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                        id="time"
                        label="Select Time"
                        type="time"
                        defaultValue="10:00"
                        variant="outlined"
                        onChange={(event) => {
                            console.log("time changes:", event.target.value);
                            setPostponeTime(event.target.value);
                        }}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        inputProps={{
                        step: 900
                        }}
                        style={{width: '100%'}}
                    />
                    </Grid>
                </Grid>
            </Box>
            <br/>
            {
              postponeTime ?
              <Grid container>
                <Grid item xs={12} style={{textAlign:'center'}}>  {"The Appointment will be postponed to "} </Grid>

                <Grid item xs={12} style={{textAlign:'center'}}>  
                    { dateStrFormat(postponeDate) + " ,"} 
                    {
                      postponeTime ? 
                      <>
                        {
                          postponeTime > "12:00" || postponeTime === '12:00'?
                          <>
                            {postponeTime + "PM"}
                          </>
                          :
                          <>
                            {postponeTime + "AM"}
                          </>
                        }
                      </>
                      : '10:00AM' } .
                </Grid>
              </Grid>
              : <></>
            }
                
            <br/>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt ={2}
            >
              <Button
                onClick={()=> {
                  setIsSuccessPostpone(true);
                  setIsPostpone(false);
                }}
                color="primary"
                size="medium"
                variant="contained"
                style={{marginRight: '3%'}}
              >
                Update
              </Button>
  
              <Button
                onClick={() =>{
                  setIsPostpone(false);
                //   setCalenderBookingValue({note: ""});
                }}
                size="medium"
                variant="contained"
              >
                Cancel
              </Button>
            </Box>
            <br/>
            
          </Box>
        </Modal>
      );
    }
  );


  const SuccessPostponeModal = observer(
    ({
      isSuccessPostpone, 
      setIsSuccessPostpone,
      isPostpone, 
      setIsPostpone
    }: {
      isSuccessPostpone: boolean;
      setIsSuccessPostpone: any;
      isPostpone: boolean;
      setIsPostpone: any;
    }) => {
  
      
      return (
        <Modal
          open={isSuccessPostpone}
          onClose={() => {}}
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            css={{ backgroundColor: "#fff", width: 500, borderRadius: 30, border: 'solid 2px #3f51b5' }}
            p={2}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
              mt={1}
            >
              <Box css={{ fontWeight: 600}}>
                
              </Box>
              <Box
                onClick={() => {
                  setIsSuccessPostpone(false);
                  setIsPostpone(false);
                }}
              >
                <CloseIcon css={{ cursor: "pointer" }} />
              </Box>
            </Box>
            <br/>
                
            <Box style={{textAlign : 'center', alignItems: 'center', color: 'green'}}>
              <CheckCircleIcon style={{fontSize: '60px'}} />
            </Box>
            <br/>
            
            <p style={{textAlign : 'center', alignItems: 'center', fontSize: '18px', fontWeight: 'bold'}} > POSTPONE SUCCESSFULLY </p>    
            <br/>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt ={2}
            >
              <Button
                onClick={() =>{
                  setIsSuccessPostpone(false);
                  setIsPostpone(false);
                }}
                color="primary"
                size="medium"
                variant="contained"
              >
                Close
              </Button>
            </Box>
            <br/>
            
          </Box>
        </Modal>
      );
    }
  );

export const ViewCase  = observer(() => {
    const [startDate, setStartDate] = useState<Date | null>(
        webStore.startDate || new Date()
      );
    const { index, name, surname, chatChannel, chatChannelType, videoChannel, videoChannelType,appointmentId }: any = useParams();
    const history = useHistory();
    const [expanded, setExpanded] = React.useState<string | false>("panel0");
    const [expandedOne, setExpandedOne] = React.useState<string | false>("panel0");
    const [expandedTwo, setExpandedTwo] = React.useState<string | false>("panel0");
    const [value, setValue] = React.useState('yes');
    const [labReportVale, setLabReportValue ] = React.useState('yes');
    const [followUpValue, setFollowupValue ] = React.useState('yes');
    const jsonData  = AppointmentJsonData;
    const appointment_data = jsonData.data[index];
    const docter_info = jsonData.data[0].doctor;
    const { result_patientData, loading_patientData } = GetPatientData(index);
    const { result_getUserProfile,fire_userRead, loading_getUserProfile, ...rest } = GetUserProfile(index);
    const { result_bookingData, loading_bookingData } = GetBookingInfoById(appointmentId);

    const [ isPostpone, setIsPostpone ] = useState(false);
    const [ postponeDate , setPostponeDate ] = useState<Date>(new Date());
    const [ postponeTime, setPostponeTime ] = useState("");
    const [ isSuccessPostpone, setIsSuccessPostpone ] = useState(false);

    const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const handleChangeLabReport = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLabReportValue((event.target as HTMLInputElement).value);
    };

    const handleChangeFollowup = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFollowupValue((event.target as HTMLInputElement).value);
    };

    const handleChangeHealthData = (panel: string) => (
        event: React.ChangeEvent<{}>,
        newExpanded: boolean
      ) => {
        setExpanded(newExpanded ? panel : false);
      };

    const handleChange = (panel: string) => (
        event: React.ChangeEvent<{}>,
        newExpanded: boolean
      ) => {
        setExpanded(newExpanded ? panel : false);
      };
    
      const handleChangeOne = (panel: string) => (
        event: React.ChangeEvent<{}>,
        newExpanded: boolean
      ) => {
        setExpandedOne(newExpanded ? panel : false);
      };

      const handleChangeTwo = (panel: string) => (
        event: React.ChangeEvent<{}>,
        newExpanded: boolean
      ) => {
        setExpandedTwo(newExpanded ? panel : false);
      };
  useEffect(() => {
  }, []);

  const Accordion = withStyles({
    root: {
      borderBottom: "1px solid rgba(0, 0, 0, .1)",
      boxShadow: "none",
      "&:last-child": {
        borderBottom: 0,
      },
      "&$expanded": {
        margin: "auto",
      },
    },
    expanded: {},
  })(MuiAccordion);

  const DeviceField = observer((props: any) => {
    return (
      <Box
        borderRight={props.border ? props.border : 0}
        css={{ borderColor: "rgba(0, 0, 0, 0.2)" }}
      >
        <Box css={{ color: "#8F919C" }} fontSize={12} mb={0.5}>
          {props.label}
        </Box>
        <Box fontSize={18}>{props.children}</Box>
      </Box>
    );
  });

  return (
    <>
    <Container maxWidth="xl" style={{ maxWidth: 1500 }} className="py-4 pb-16" >
        <SubBar title={"View Case"} back={true} reload={false}/>
        <Box>
            <Box
            css={{ borderRadius: 5, backgroundColor: "#2B5698", color: "#fff" }}
            >
            <Box display={{ xs: "block", md: "flex" }}>
                <Box
                p={4}
                width={{ xs: "100%", md: "20%" }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                >
                    <Avatar style={{ width: 100, height: 100,  textAlign:'center'}}>
                        {/* <img src={result_patientData?.patient?.profile_image} alt="" style={{height:'100%'}}/> */}
                        <PersonIcon style={{fontSize: '50px'}}/>
                    </Avatar>
                </Box>
                <Box py={4} px={6} width={{ xs: "100%", md: "40%" }}>
                    {/* <Grid container>
                        <Grid item xs={4}>
                            <p style={{fontWeight: 'bold'}}>Name:</p>
                        </Grid>
                        <Grid item xs={6}>
                            {result_patientData?.name + " " + result_patientData?.surname}
                        </Grid>
                    </Grid> */}
                    
                    <Grid container>
                        <Grid item xs={4}>
                            <p style={{fontWeight: 'bold'}}>Name:</p>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                name + " " + surname
                            }
                        </Grid>
                    </Grid>
                    {/* <Grid container>
                        <Grid item xs={4}>
                            <p style={{fontWeight: 'bold'}}>Booking Name:</p>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                result_patientData ?
                                <>
                                    {result_patientData[0]?.appointment_name}
                                </>
                                :
                                <></>
                            }
                            
                        </Grid>
                    </Grid> */}

                    <Grid container>
                        <Grid item xs={4}>
                            <p style={{fontWeight: 'bold'}}>Date:</p>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                result_patientData ?
                                <>
                                    {dateStringFullMothName(result_patientData[0]?.start_time)}
                                </>
                                :
                                <></>
                            }
                            
                        </Grid>
                    </Grid>
                    
                    <Grid container>
                        <Grid item xs={4}>
                            <p style={{fontWeight: 'bold'}}>Start Time:</p>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                result_patientData ?
                                <>
                                    {dateStrFormatTime(result_patientData[0]?.start_time)}
                                </>
                                :
                                <></>
                            }
                            {/* {dateStrFormatTime(appointment_data?.meeting_start_date)} */}
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={4}>
                            <p style={{fontWeight: 'bold'}}>End Time:</p>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                result_patientData ?
                                <>
                                    {dateStrFormatTime(result_patientData[0]?.end_time)}
                                </>
                                :
                                <></>
                            }
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={4}>
                            <p style={{fontWeight: 'bold'}}>Booking Type:</p>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                result_patientData ?
                                <>
                                    {result_patientData[0]?.appointment_type}
                                </>
                                :
                                <></>
                            }
                            
                        </Grid>
                    </Grid>
                    
                    {/* <Grid container>
                        <Grid item xs={4}>
                            <p style={{fontWeight: 'bold'}}>Session:</p>
                        </Grid>
                        <Grid item xs={6}>
                            15 นาที
                        </Grid>
                    </Grid> */}
                    <br/>
                    <Button  style={{textAlign: 'center'}}
                        variant="contained" color="secondary" size='small'
                        onClick = {() => {
                            setIsPostpone(true);
                        }}
                        > Postpone
                    </Button>

                    <PostponeModal
                      isPostpone = { isPostpone }
                      setIsPostpone = { setIsPostpone }
                      postponeDate = {postponeDate}
                      setPostponeDate = { setPostponeDate }
                      postponeTime = {postponeTime}
                      setPostponeTime = {setPostponeTime}
                      isSuccessPostpone = { isSuccessPostpone }
                      setIsSuccessPostpone = { setIsSuccessPostpone }
                    />

                    <SuccessPostponeModal
                        isSuccessPostpone = {isSuccessPostpone}
                        setIsSuccessPostpone = { setIsSuccessPostpone }
                        isPostpone = { isPostpone }
                        setIsPostpone = { setIsPostpone }
                      />
                </Box>
                <Box py={4} px={6} width={{ xs: "100%", md: "40%" }}>
                    <Box mb={2} position="relative">
                        <Box
                            display="flex"
                            alignItems="center"
                            css={{ marginTop:'4px' }}
                        >
                            <Box fontSize={15} mr={1}>
                            <p style={{fontWeight: 'bold'}}> Symptom case: </p>
                            </Box>
                        </Box>
                            <br/>
                            <Box fontSize={15} mr={1}>
                            {
                                result_patientData ?
                                <>
                                    {result_patientData[0]?.symptom}
                                </>
                                :
                                <></>
                            }
                            </Box>
                            <br/>
                            <br/>
                            <Button  style={{textAlign: 'center', color : '#2b5698'}}
                                variant="contained"
                                onClick = {() => {
                                history.push("/telemedicine/videocall/" + index + "/" + name + "/" + surname + '/' + videoChannel + "/" + videoChannelType );
                                }}
                                > Start Video Call
                            </Button>
                            <Button  style={{textAlign: 'center', color : '#2b5698', marginLeft: '10px'}}
                                variant="contained"
                                onClick = {() => {
                                history.push("/telemedicine/chat/" + index + "/" + name + "/" + surname + '/' + chatChannel + "/" + chatChannelType );
                                }}
                                > Start Messaging
                            </Button>

                        </Box>
                    </Box>
            </Box>
            </Box>
        </Box>
        <br/>
        <Box className="flex justify-between items-center" mt={8} pb={2}>
            <Grid container>
                <Grid item md={4} xs={12}>
                <Box fontSize="h5.fontSize" fontWeight={500}>
                    Health Report 
                </Box>
                </Grid>
            </Grid>
        </Box>

        <Box>
        {/* {(result_getUserProfile?.health || []).map((deivce, key) => {
          return ( */}
        {
            result_getUserProfile?.health ?
            <Accordion
            square
            expanded={expanded === `panel0`}
            onChange={handleChangeHealthData(`panel0`)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                display={{ xs: "block", md: "flex" }}
                alignItems="center"
                px={{ xs: 0, md: 3 }}
                py={2}
              >
                <Box
                  fontSize={12}
                  css={{ color: "#4B4C56" }}
                  mt={{ xs: 2, md: 0 }}
                >
                  Latest update :
                  {
                    result_getUserProfile?.health?.updated_at ?
                    dateStrAPI(result_getUserProfile?.health?.updated_at) + ", " + timeStr(result_getUserProfile?.health?.updated_at)
                    :
                    ""
                  }
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box px={{ xs: 0, md: 3 }} width="100%">
                <Grid container xs={12}>
                  <Grid item xs={12} md={6}>
                    <Box
                      display="flex"
                      width="100%"
                      paddingRight={{ md: 5 }}
                      mb={{ xs: 4, md: 0 }}
                    >
                      <Grid item xs={4}>
                        <DeviceField label="Body temp">
                          <Box fontWeight={600}>
                            {result_getUserProfile?.health?.body_temp ? result_getUserProfile?.health?.body_temp : "N/A"}
                          </Box>
                        </DeviceField>
                      </Grid>
                      <Grid item xs={4}>
                        <DeviceField label="Heart rate">
                          <Box fontWeight={600}>
                            {result_getUserProfile?.health?.heart_rate ? result_getUserProfile?.health?.heart_rate : "N/A"}
                          </Box>
                        </DeviceField>
                      </Grid>
                      <Grid item xs={4}>
                        <DeviceField
                          label="Blood pressure"
                          border={{ xs: 0, md: 1 }}
                        >
                          <Box fontWeight={600}>
                            { result_getUserProfile?.health?.blood_systolic && result_getUserProfile?.health?.blood_diastolic ?
                            `${result_getUserProfile?.health?.blood_systolic}/${result_getUserProfile?.health?.blood_diastolic}`
                            :
                            "N/A"
                            }</Box>
                        </DeviceField>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box display="flex" width="100%" mb={{ xs: 4, md: 0 }}>
                      <Grid item xs={4}>
                        <DeviceField label="Blood Sugar">
                          <Box fontWeight={600}>
                            {result_getUserProfile?.health?.blood_sugar ? result_getUserProfile?.health?.blood_sugar : "N/A"}
                          </Box>
                        </DeviceField>
                      </Grid>
                      <Grid item xs={4}>
                        <DeviceField
                          label="Blood Oxygen"
                        >
                          <Box fontWeight={600}>
                            {result_getUserProfile?.health?.blood_oxygen ? result_getUserProfile?.health?.blood_oxygen : "N/A"}
                          </Box>
                        </DeviceField>
                      </Grid>
                      <Grid item xs={4}>
                        <DeviceField
                          label="Respiratoin(per min)"
                        >
                          <Box fontWeight={600}>
                              {result_getUserProfile?.health?.breath ? result_getUserProfile?.health?.breath : "N/A"}
                          </Box>
                        </DeviceField>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
          :
          <></>
        }
            
          {/* ); */}
        {/* // })} */}
      </Box>
        <br/>
        <Box fontSize="h5.fontSize" fontWeight={500}>
          History
        </Box>
        <Box>
            {
                result_patientData ? 
                <>
                    {
                        result_patientData?.length > 0 ?
                        
                        <>
                            <Accordion
                                square
                                expanded={expanded === `panel1`}
                                onChange={handleChange(`panel1`)}
                                >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Box
                                    css={{ borderRadius: 5}}
                                    >
                                        <Box display={{ xs: "block", md: "flex" }}>
                                            <Box
                                            p={4}
                                            pl={10}
                                            width={{ xs: "100%", md: "20%" }}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            >
                                                <Avatar style={{ width: 70, height: 70,  textAlign:'center'}}>
                                                    <img src={docter_info?.profile_image} alt="" />
                                                </Avatar>
                                            </Box>
                                            <Box py={4} px={6} width={{ xs: "100%", md: "80%" }}>
                                                <p style={{fontSize:'20px', fontWeight:'bold'}}>
                                                    {
                                                        result_patientData ?
                                                        <>
                                                            Dr. {result_patientData[0]?.doctor?.name + " " + result_patientData[0]?.doctor?.surname}
                                                        </>
                                                        :
                                                        <></>
                                                    }
                                                    
                                                </p>
                                                <p style={{ fontSize: '14px', color: 'gray'}}>
                                                    {
                                                        result_patientData ?
                                                        <>
                                                        {result_patientData[0]?.doctor?.specialized}
                                                        </>
                                                        :
                                                        <></>
                                                    }
                                                
                                                </p>
                                                <p style={{ fontSize: '14px', color: 'gray'}}>
                                                    {
                                                        result_patientData ?
                                                        <>
                                                            {dateStringFullMothName(result_patientData[0]?.start_time) + ", " + dateStrFormatTime(result_patientData[0]?.start_time)}
                                                        </>
                                                        :
                                                        <></>
                                                    }
                                                </p>
                                            </Box>
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box px={{ xs: 0, md: 3 }} width="100%">
                                        <Grid container spacing={3}>
                                            <Grid item xs={6} >
                                                <p>
                                                <span style={{fontWeight: 'bold'}}>Symptom Case </span> 
                                                </p>
                                            <br/>
                                            <Box
                                                css={{ backgroundColor: "#fff", borderRadius: 30, border: 'solid 2px #3f51b5' }}
                                                p={2}
                                                >
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    mb={1}
                                                    mt={1}
                                                >
                                                    
                                                </Box>

                                                

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <FormControl
                                                    variant="outlined"
                                                    style={{ width: "100%" }}
                                                    size="small"
                                                    margin="dense"
                                                    >
                                                    <TextareaAutosize  name="remark-label" placeholder="Type ....."
                                                    rowsMin={5} 
                                                    value = {result_patientData?  result_patientData[0]?.symptom : ""}
                                                    // value={note} onChange={handleOnChange}
                                                    />
                                                    </FormControl>
                                                    
                                                </Box>
                                                <br/>
                                                </Box>
                                                <br/>
                                            </Grid>
                                            <Grid item xs={6}>
                                            <p>
                                                <span style={{fontWeight: 'bold'}}>Diagnoses </span> 
                                            </p>
                                            <br/>
                                            <Box
                                                css={{ backgroundColor: "#fff", borderRadius: 30, border: 'solid 2px #3f51b5' }}
                                                p={2}
                                                >
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    mb={1}
                                                    mt={1}
                                                >
                                                    
                                                </Box>

                                                

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <FormControl
                                                    variant="outlined"
                                                    style={{ width: "100%" }}
                                                    size="small"
                                                    margin="dense"
                                                    >
                                                    <TextareaAutosize  name="remark-label" placeholder="Type ....."
                                                    rowsMin={5} 
                                                    value = {""}
                                                    // value={note} onChange={handleOnChange}
                                                    />
                                                    </FormControl>
                                                    
                                                </Box>
                                                <br/>
                                                </Box>
                                                <br/>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                        <FormControl component="fieldset" style={{display :'felx'}}>
                                            <FormLabel component="legend" style={{fontWeight: 'bold'}}>Medicine</FormLabel>
                                            <RadioGroup row aria-label="medicine" name="medicine" value={value} onChange={handleChangeValue}>
                                            <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                        </Grid>
                                        <br/>
                                        <Grid container>
                                        <FormControl component="fieldset" style={{display :'flex'}}>
                                            <FormLabel component="legend" style={{fontWeight: 'bold'}}>Lab Report</FormLabel>
                                            <RadioGroup row aria-label="medicine" name="medicine" value={labReportVale} onChange={handleChangeLabReport}>
                                            <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                        </Grid>
                                        <br/>
                                        <Grid container>
                                        <FormControl component="fieldset" style={{display :'flex'}}>
                                            <FormLabel component="legend" style={{fontWeight: 'bold'}}>Follow Up</FormLabel>
                                            <RadioGroup row aria-label="medicine" name="medicine" value={followUpValue} onChange={handleChangeFollowup}>
                                            <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                        </Grid>
                                    </Box>
                                    
                                </AccordionDetails>
                            </Accordion>
                        </>

                        :<></>
                    }
                </>
                :
                <></>
            }
            
            <br/>
            <Accordion
                square
                expanded={expandedOne === `panel1`}
                onChange={handleChangeOne(`panel1`)}
                >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box
                    css={{ borderRadius: 5}}
                    >
                        <Box display={{ xs: "block", md: "flex" }}>
                            <Box
                            p={4}
                            pl={10}
                            width={{ xs: "100%", md: "20%" }}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            >
                                <Avatar style={{ width: 70, height: 70,  textAlign:'center'}}>
                                        <PersonIcon style={{fontSize: '60px'}}/>
                                </Avatar>
                            </Box>
                            <Box py={4} px={6} width={{ xs: "100%", md: "80%" }}>
                                <p style={{fontSize:'20px', fontWeight:'bold'}}>Dr. Sandra Bennett</p>
                                <p style={{ fontSize: '14px', color: 'gray'}}>Physician</p>
                                <p style={{ fontSize: '14px', color: 'gray'}}>{dateStringFullMothName("2022-01-05T10:01:00.000Z") + ", " + dateStrFormatTime("2022-01-05T10:01:00.000Z") } </p>
                            </Box>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Box px={{ xs: 0, md: 3 }} width="100%">
                        <Grid container spacing={3}>
                            <Grid item xs={6} >
                                <p>
                                <span style={{fontWeight: 'bold'}}>Symptom Case </span> 
                                </p>
                            <br/>
                            <Box
                                css={{ backgroundColor: "#fff", borderRadius: 30, border: 'solid 2px #3f51b5' }}
                                p={2}
                                >
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    mb={1}
                                    mt={1}
                                >
                                    
                                </Box>

                                

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <FormControl
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                    size="small"
                                    margin="dense"
                                    >
                                    <TextareaAutosize  name="remark-label" placeholder="Type ....."
                                    rowsMin={5} 
                                    value = {"มีผื่นที่ผิวหนัง และคันตามผิวหนังคัดจมูก น้ํามูกไหล จาม"}
                                    // value={note} onChange={handleOnChange}
                                    />
                                    </FormControl>
                                    
                                </Box>
                                <br/>
                                </Box>
                                <br/>
                            </Grid>
                            <Grid item xs={6}>
                            <p>
                                <span style={{fontWeight: 'bold'}}>Diagnoses </span> 
                            </p>
                            <br/>
                            <Box
                                css={{ backgroundColor: "#fff", borderRadius: 30, border: 'solid 2px #3f51b5' }}
                                p={2}
                                >
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    mb={1}
                                    mt={1}
                                >
                                    
                                </Box>

                                

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <FormControl
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                    size="small"
                                    margin="dense"
                                    >
                                    <TextareaAutosize  name="remark-label" placeholder="Type ....."
                                    rowsMin={5} 
                                    value = {"ภูมิแพ้ทางเดินหายใจ"}
                                    // value={note} onChange={handleOnChange}
                                    />
                                    </FormControl>
                                    
                                </Box>
                                <br/>
                                </Box>
                                <br/>
                            </Grid>
                        </Grid>
                        <Grid container>
                        <FormControl component="fieldset" style={{display :'felx'}}>
                            <FormLabel component="legend" style={{fontWeight: 'bold'}}>Medicine</FormLabel>
                            <RadioGroup row aria-label="medicine" name="medicine" value={value} onChange={handleChangeValue}>
                            <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        </Grid>
                        <br/>
                        <Grid container>
                        <FormControl component="fieldset" style={{display :'flex'}}>
                            <FormLabel component="legend" style={{fontWeight: 'bold'}}>Lab Report</FormLabel>
                            <RadioGroup row aria-label="medicine" name="medicine" value={labReportVale} onChange={handleChangeLabReport}>
                            <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        </Grid>
                        <br/>
                        <Grid container>
                        <FormControl component="fieldset" style={{display :'flex'}}>
                            <FormLabel component="legend" style={{fontWeight: 'bold'}}>Follow Up</FormLabel>
                            <RadioGroup row aria-label="medicine" name="medicine" value={followUpValue} onChange={handleChangeFollowup}>
                            <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        </Grid>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <br/>
            <Accordion
                square
                expanded={expandedTwo === `panel1`}
                onChange={handleChangeTwo(`panel1`)}
                >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box
                    css={{ borderRadius: 5}}
                    >
                        <Box display={{ xs: "block", md: "flex" }}>
                            <Box
                            p={4}
                            pl={10}
                            width={{ xs: "100%", md: "20%" }}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            >
                                <Avatar style={{ width: 70, height: 70,  textAlign:'center'}}>
                                        <PersonIcon style={{fontSize: '60px'}}/>
                                </Avatar>
                            </Box>
                            <Box py={4} px={6} width={{ xs: "100%", md: "80%" }}>
                                <p style={{fontSize:'20px', fontWeight:'bold'}}>Dr. James</p>
                                <p style={{ fontSize: '14px', color: 'gray'}}>Physician</p>
                                <p style={{ fontSize: '14px', color: 'gray'}}>{dateStringFullMothName("2022-01-03T12:30:00.000Z") + ", " + dateStrFormatTime("2022-01-03T12:30:00.000Z") }</p>
                            </Box>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Box px={{ xs: 0, md: 3 }} width="100%">
                        <Grid container spacing={3}>
                            <Grid item xs={6} >
                                <p>
                                <span style={{fontWeight: 'bold'}}>Symptom Case </span> 
                                </p>
                            <br/>
                            <Box
                                css={{ backgroundColor: "#fff", borderRadius: 30, border: 'solid 2px #3f51b5' }}
                                p={2}
                                >
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    mb={1}
                                    mt={1}
                                >
                                    
                                </Box>

                                

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <FormControl
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                    size="small"
                                    margin="dense"
                                    >
                                    <TextareaAutosize  name="remark-label" placeholder="Type ....."
                                    rowsMin={5} 
                                    value = {"มีผื่นที่ผิวหนัง และคันตามผิวหนังคัดจมูก น้ํามูกไหล จาม"}
                                    // value={note} onChange={handleOnChange}
                                    />
                                    </FormControl>
                                    
                                </Box>
                                <br/>
                                </Box>
                                <br/>
                            </Grid>
                            <Grid item xs={6}>
                            <p>
                                <span style={{fontWeight: 'bold'}}>Diagnoses </span> 
                            </p>
                            <br/>
                            <Box
                                css={{ backgroundColor: "#fff", borderRadius: 30, border: 'solid 2px #3f51b5' }}
                                p={2}
                                >
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    mb={1}
                                    mt={1}
                                >
                                    
                                </Box>

                                

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <FormControl
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                    size="small"
                                    margin="dense"
                                    >
                                    <TextareaAutosize  name="remark-label" placeholder="Type ....."
                                    rowsMin={5} 
                                    value = {"ภูมิแพ้ทางเดินหายใจ"}
                                    // value={note} onChange={handleOnChange}
                                    />
                                    </FormControl>
                                    
                                </Box>
                                <br/>
                                </Box>
                                <br/>
                            </Grid>
                        </Grid>
                        <Grid container>
                        <FormControl component="fieldset" style={{display :'felx'}}>
                            <FormLabel component="legend" style={{fontWeight: 'bold'}}>Medicine</FormLabel>
                            <RadioGroup row aria-label="medicine" name="medicine" value={value} onChange={handleChangeValue}>
                            <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        </Grid>
                        <br/>
                        <Grid container>
                        <FormControl component="fieldset" style={{display :'flex'}}>
                            <FormLabel component="legend" style={{fontWeight: 'bold'}}>Lab Report</FormLabel>
                            <RadioGroup row aria-label="medicine" name="medicine" value={labReportVale} onChange={handleChangeLabReport}>
                            <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        </Grid>
                        <br/>
                        <Grid container>
                        <FormControl component="fieldset" style={{display :'flex'}}>
                            <FormLabel component="legend" style={{fontWeight: 'bold'}}>Follow Up</FormLabel>
                            <RadioGroup row aria-label="medicine" name="medicine" value={followUpValue} onChange={handleChangeFollowup}>
                            <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        </Grid>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    </Container>
          
    </>
  );
});