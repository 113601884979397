import React, { Fragment, useEffect, useState,useRef } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
  withStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Modal,
  LinearProgress,
  Avatar,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import '../../App.css';
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import Skeleton from "@material-ui/lab/Skeleton";
import { StickyContainer, Sticky } from "react-sticky";
import { SubBar } from "../Dashboard/Dashboard";
import { useParams } from "react-router-dom";
import axios, { AxiosRequestConfig, Method } from 'axios';
import {
  ReadUserFilter,
  useReadUser,
  useReadUserUpdate,
  GetStatusForPhuketUser,
  UpdatePhuketUserQuarantineStatus,
  CallDailyReports,
  CreateNote,
  GetContactTracerInfo,
  ContactTracerHistroyLog,
  GetUserProfile,
  CallDailyReportsIsolation,
  CallMentalReportsIsolation,
  GetPoccUserId
} from "../../services/api/user/useReadUser";
import LuxonUtils from "@date-io/luxon";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { webStore } from "../../stores/webStore";
import { observer } from "mobx-react-lite";
import { EditField, UserInfoCard, UserProfile } from "./UserInfoCard";
import { UserInfoCardSI,UserProfileDetail } from "./UserInfoCardSI";
import { DeviceHistoryDatagrid } from "./DeviceHistoryDatagrid";
import { DateFilter } from "../../components/DateFilter";
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import { BodyTempGraph } from "./BodyTempGraph";
import { HeartRateGraph } from "./HeartRateGraph";
import { BloodPressureGraph } from "./BloodPressureGrph";
import { OximeterGraph } from "./OximeterGraph";
import { RespirationGraph } from "./RespirationGraph";
import { Map } from "../../components/Map";
import { ContactTracerMap } from "../../components/ContactTracerMap";
import CheckIcon from "@material-ui/icons/Check";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordion from "@material-ui/core/Accordion";
import WatchIcon from "@material-ui/icons/Watch";
import NightsStayIcon from '@material-ui/icons/NightsStay';
import NightsStayOutlinedIcon from '@material-ui/icons/NightsStayOutlined';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined';
import FilterDramaIcon from '@material-ui/icons/FilterDrama';
import { dateStr, dateStrAPI, timeStr, dateStrAPIFormat,DateTimeString,dateStringAPI } from "../../utils";
import { useUpdateUser } from "../../services/api/user/useUpdateUser";
import { useSnackbar } from "notistack";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { UserPreviewData } from "./UserPreview";

import {
  BatteryHistoryReport,
  HeartRateHistoryReport,
  BloodPressureHistoryReport,
  WearingHistoryReport,
  TempHistoryReport,
  LocationsReport,
  OximeterHistoryReport,
  BreathHistoryReport,
  BloodSugarHistoryReport,
} from "../../types/personalReport";
import { dailyReport } from "../../types/user";
import { localStorageAvailable, RightEmptyCell } from "@material-ui/data-grid";
import { useHistory } from "react-router-dom";
import CachedIcon from "@material-ui/icons/Cached";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from '@material-ui/icons/Edit';
import BorderColorIcon from "@material-ui/icons/BorderColor";
import CloseIcon from "@material-ui/icons/Close";
// import { Graph } from "react-d3-graph";
import Graph  from 'react-graph-vis';
import mapBackground from '../../components/assets/mapBackground.jpg';
import  BlueUserIcon  from '../../components/assets/BlueUserIcon.jpg';
import  RedUserIcon  from '../../components/assets/RedUserIcon.jpg';

import Pdf from "react-to-pdf";
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
// const QuarantineOptions = [
//   { value: "1", label: "In Quarantine" },
//   { value: "2", label: "Waiting For Quarantine" },
//   { value: "3", label: "Waiting For Approve" },
//   { value: "4", label: "Completed" },
//   { value: "5", label: "Re-Quarantine" }
// ];

let QuarantineOptions = [];

const onCapture = () =>{
  // htmlToImage.toPng(document.getElementById("savePNG") as HTMLElement)
  //     .then(function (dataUrl) {
  //     saveAs(dataUrl,  'download.png')
  //     });
  const input = document.getElementById('savePNG') as HTMLElement;
  html2canvas(input)
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p');
      pdf.addImage(imgData, 'PNG', 5, 3, 190, 290);
      pdf.save("download.pdf");
    })
  ;
}

const TitleBar = observer(
  ({
    title,
    back,
    reload,
    status, 
    id,
    children,
  }: {
    title: string | React.ReactElement;
    children?: React.ReactElement;
    back?: boolean;
    reload?: any;
    id?: any;
    status?: string;
  }) => {
    const [ statusArray, setStatusArray] = useState<any>();
    useEffect(() => {
      webStore.setLatestTimeStamp(new Date());
      webStore.resetFilter();
    }, []);
    const history = useHistory();
    const [isEdit, setIsEdit] = useState(false);
    const [isUpdateStatus, setIsUpdateStatus] = useState(false);
    const [editValue, setEditValue] = useState<any>();
    const [remarkValue, setRemarkValue] = useState<any>("");
    const [loading, setLoading] = useState(false);
    const [QuarantineOptions, setQuarantineOptions] = useState<any>();
    let quarantineStatus = status;
    const { enqueueSnackbar } = useSnackbar();
    const { fire_quarantineStatus_update, loading_quarantineStatus_update } = UpdatePhuketUserQuarantineStatus(id);
    const { result_getStatus, loading_getStatus } = GetStatusForPhuketUser(id);

    const getStatus = (status: string) => {
     
      let qStatus : string;
      if (status === "1") {
        qStatus = "In Quarantine";
      }else if ( status === "2") {
        qStatus = "Waiting for Quarantine";
      }else if ( status === "3") {
        qStatus = "Waiting For Approve";
      }else if (status === "4"){
        qStatus= "Completed";
      }else if (status === "5"){
        qStatus = "Re-Quarantine";
      }else {
        qStatus = "";
      }
      return qStatus;
    }
    const getStatusOption = () => {
      let statusOption = [];
       if(result_getStatus?.length > 0){
         for(let i = 0; i<result_getStatus.length; i++){
            if(result_getStatus[i] === 1){
              statusOption.push({ value: "1", label: "In Quarantine" });
            }else if (result_getStatus[i] === 2){
              statusOption.push({ value: "2", label: "Waiting For Quarantine" });
            }
            else if (result_getStatus[i] === 3){
              statusOption.push({ value: "3", label: "Waiting For Approve" });
            }
            else if (result_getStatus[i] === 4){
              statusOption.push({ value: "4", label: "Completed" });
            }else if (result_getStatus[i] === 5){
              statusOption.push({ value: "5", label: "Re-Quarantine" });
            }else {
              statusOption.push({ value: "1", label: "In Quarantine" })
            }
            
         }
       }
       
       setQuarantineOptions(statusOption);
      return statusOption;
    }
    const [statusQuarantine, setStatusQuarantine] = useState<string>('');

    return (
      <Box className="flex justify-between items-center" pt={4} pb={0}>
        <Grid container alignItems="center">
          {back && (
            <IconButton
              onClick={() => {
                const res = history.goBack();
                // console.log(res);
              }}
            >
              <ArrowBackIcon css={{ color: "black" }} />
            </IconButton>
          )}

          <Box fontSize="h5.fontSize" fontWeight={500}>
            {title}
          </Box>
        </Grid>
          {/* <Box width={400}>
            <Box className="flex justify-between items-center" css={{float:'right'}}>
              <Box style={{fontSize: '16px'}} fontWeight={400}>
                  <Button
                  color="primary"
                  onClick={() => {
                    if(id){
                        history.push("/user/" + id + "/seeMoreDetail");
                    }else{
                        history.push("/user/userId_empty/seeMoreDetail");
                        console.log("there is no id for see more information");
                    }
                  }}
                  size="small"
                  variant="text"
                  style={{float: 'right'}}
                >
                    <u>See More Information</u>
                </Button>
              </Box>
              
              
            </Box>
            
          </Box> */}
          {status && (
          <Box width={400}>
            <Box className="flex justify-between items-center" css={{float:'right'}}>
              <Box style={{fontSize: '16px'}} fontWeight={400}>
                  <span>
                    Status:{statusQuarantine ?  <span>{statusQuarantine}</span> : <span> {getStatus(status)} </span> }
                  </span>
                  {/* Status: {getStatus(status)} */}
                  <Button
                  color="primary"
                  onClick={() => {
                    getStatusOption();
                    setIsEdit(true);
                  }}
                  startIcon={<BorderColorIcon />}
                  size="small"
                  variant="text"
                  style={{float: 'right'}}
                >
                </Button>
              </Box>
              
              
            </Box>
            
          </Box>
        )}

        {children}
        
        <EditQuarantine
        {...editValue}
        {...remarkValue}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        setRemarkValue={setRemarkValue}
        setValue={setEditValue}
        label = {"Update Quarantine Status"}
        status = {quarantineStatus}
        getStatusOption = {QuarantineOptions}
        onClick={async () => {
          setLoading(true);
            fire_quarantineStatus_update(
                editValue.value, remarkValue.remark
            )
              .then(({ data }: { data: any }) => {
                let resultStatus: string;
                resultStatus = getStatus(data.data.quarantine_result_status.toString());
                setStatusQuarantine(resultStatus);
                setRemarkValue({remark: ""});
                enqueueSnackbar(`Update Status Success`, {
                  key: "editSuccess",
                  variant: "success",
                  autoHideDuration: 3000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                });
              })
              .catch((ex: any) => {
                enqueueSnackbar("Update Status Failed", {
                  key: "editError",
                  variant: "error",
                  autoHideDuration: 3000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                });
              });
            setIsEdit(false);
            setLoading(false);
        }}
      />
      </Box>
    );
  }
);

const EditQuarantine = observer(
  ({
    isEdit,
    label,
    value,
    remark,
    field,
    onClick,
    setIsEdit,
    getStatusOption,
    type = "text",
    setRemarkValue,
    setValue,
    status,
    buttonName = "CONFIRM",
  }: {
    isEdit: boolean;
    setIsEdit: any;
    label: string;
    field: string;
    value: any;
    remark: any;
    onClick?: any;
    type?: string;
    setRemarkValue: any;
    setValue: any;
    getStatusOption: any;
    status: string;
    buttonName?: string;
  }) => {
    
    const [selectValue, setSelectedValue] = React.useState('');

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      // setSelectedValue(event.target.value as string);
      setValue({value: event.target.value as string});
      if (event.target.value === "1") {
        setSelectedValue("Status will be changed to \n In Quarantine");
      }else if ( event.target.value === "2") {
        setSelectedValue("Status will be changed to \n Waiting for Quarantine");
      }else if ( event.target.value === "3") {
        setSelectedValue("Status will be changed to \n Waiting For Approve");
      }else if (event.target.value === "4"){
        setSelectedValue("Status will be changed to \n Completed");
      }else if (event.target.value === "5"){
        setSelectedValue("Status will be changed to \n Re-Quarantine");
      }else {
        setSelectedValue("Please choose status");
      }
    };

    const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setRemarkValue({remark: event.target.value as string});
    };

    return (
      <Modal
        open={isEdit}
        onClose={() => {}}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          css={{ backgroundColor: "#fff", width: 350, borderRadius: 5 }}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Box css={{ color: "#4B4C56" }} fontSize={14}>
              {label}
            </Box>
            <Box
              onClick={() => {
                setIsEdit(false);
                setSelectedValue("");
                setValue({value: ""});
                setRemarkValue({remark: ""});
              }}
            >
              <CloseIcon css={{ cursor: "pointer" }} />
            </Box>
          </Box>
          <Box>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
            margin="dense"
            required
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Quarantine Status
            </InputLabel>
            
            <Select
              labelId="demo-simple-select-outlined-label"
              name={"Quarantine_Status"}
              label={"Quarantine Status"}
              value={value}
              onChange={handleChange}
              // fullWidth
            >
              {getStatusOption?.map((v: any) => {
                return <MenuItem value={v.value}>{v.label}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <br/>
              
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            <p style={{ color:'#2B5698', textAlign: 'center', fontSize: '13px'}}>{selectValue}</p>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt ={2}
          >
            <p style={{ color:'grey', fontSize: '13px'}}>Remark</p>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
            margin="dense"
          >
            <TextareaAutosize  name="remark-label"
             rowsMin={4} value={remark} onChange={handleOnChange}
             style={{border:'solid 1px', borderColor:'#c4c4c4', padding: "10px"}}
             />
             {/* to do textarea inside textbox padding */}
          </FormControl>
            
          </Box>
          {/* <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            {
              setIsUpdateStatus ? <p style={{ color:'red', textAlign: 'center', fontSize: '13px'}}>Please select the status to update.</p>
              : <></>
            }
            
          </Box> */}

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            <Button
              onClick={() => {
                setIsEdit(false);
                setSelectedValue("");
                setValue({value: ""});
                setRemarkValue({remark: ""});
              }}
              size="medium"
              variant="outlined"
            >
              CANCEL
            </Button>

            <Button
              onClick={onClick}
              color="primary"
              size="medium"
              variant="outlined"
              style={{marginLeft: '3%'}}
            >
              {buttonName}
            </Button>
          </Box>
          <br/>
        </Box>
      </Modal>
    );
  }
);

const AddNoteModal = observer(
  ({
    isAddNote,
    label,
    note,
    onClick,
    setIsAddNote,
    setAddNoteValue,
    buttonName = "Save",
  }: {
    isAddNote: boolean;
    setIsAddNote: any;
    label: string;
    note?: any;
    onClick?: any;
    type?: string;
    setAddNoteValue?: any;
    buttonName?: string;
  }) => {

    const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setAddNoteValue({note: event.target.value as string});
    };

    return (
      <Modal
        open={isAddNote}
        onClose={() => {}}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          css={{ backgroundColor: "#fff", width: 350, borderRadius: 30, border: 'solid 2px #3f51b5' }}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
            mt={1}
          >
            <Box css={{ color: "#4B4C56" }}>
              <h3>{label}</h3> 
            </Box>
            <Box
              onClick={() => {
                setIsAddNote(false);
                setAddNoteValue({note: ""});
              }}
            >
              <CloseIcon css={{ cursor: "pointer" }} />
            </Box>
          </Box>

          

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
            margin="dense"
            >
            <TextareaAutosize  name="remark-label" placeholder="Please Enter Note"
             rowsMin={3} value={note} onChange={handleOnChange}
             />
            </FormControl>
            
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            <Button
              onClick={onClick}
              color="primary"
              size="medium"
              variant="contained"
            >
              {buttonName}
            </Button>
          </Box>
          <br/>
        </Box>
      </Modal>
    );
  }
);

// export type UserPreviewData = {
//   loading: boolean;
//   result_temp_history: TempHistoryReport | null;
//   result_oximeter_history: OximeterHistoryReport | null;
//   result_heart_rate_history: HeartRateHistoryReport | null;
//   result_blood_pressure_history: BloodPressureHistoryReport | null;
//   result_breath_history: BreathHistoryReport | null;
//   result_blood_sugar_history: BloodSugarHistoryReport | null;
// };

export type DailyReportPreviewData ={
  loading : boolean;
  result_dailyReport: dailyReport | null;
}

const DeviceField = observer((props: any) => {
  return (
    <Box
      borderRight={props.border ? props.border : 0}
      css={{ borderColor: "rgba(0, 0, 0, 0.2)" }}
    >
      <Box css={{ color: "#8F919C" }} fontSize={12} mb={0.5}>
        {props.label}
      </Box>
      <Box fontSize={18}>{props.children}</Box>
    </Box>
  );
});

function badgeStyles() {
  return {
    badgeActive: {
      backgroundColor: "#10940E",
    },
  };
}

export const UserDetail = observer(() => {
  const { userId }: any = useParams();
  const ref : any = React.createRef();
  const [ user_id, setUserId ] = React.useState('');
  const [ redirectURL, setRedirectURL ] = useState('');
  // const {
  //   result_userRead,
  //   result_userRead_raw,
  //   fire_userRead,
  //   loading_userRead,
  //   ...rest
  // } = useReadUserUpdate(userId, webStore.readUserFilter);

  const { result_getUserProfile,fire_userRead, loading_getUserProfile, ...rest } = GetUserProfile(userId,webStore.readUserFilter);
  const { result_data_userId, loading_data } = GetPoccUserId(userId);
  const [ exportButton, setExportButton ] = useState(true);
  useEffect(() => {
      webStore.resetFilter();
      webStore.setStartDate(startDate);
      webStore.setEndDate(endDate);
      webStore.set_filter_hour(filterHour);
      setUserId(result_data_userId);
      console.log("user id pocc in user detail:", result_data_userId);
      if(result_getUserProfile?.created_at){
        if(dateStringAPI(result_getUserProfile?.created_at) <= '2022-03-21'){
            setExportButton(true);
        }else{
           setExportButton(false);
        }

      }
      
      
  }, [result_data_userId, result_getUserProfile]);
  const parseData: UserPreviewData = {
    loading: loading_getUserProfile,
    ...rest,
  };

  const {
    result_dailyReport,
    loading_dailyReport,
    error_dailyReport
  } = CallDailyReportsIsolation(userId);

  const {
    result_metalReport,
    loading_mentalReport, 
    error_mentalReport
  } = CallMentalReportsIsolation(userId);

  // const dailyReportData : DailyReportPreviewData = {
  //   loading: loading_dailyReport,
  //   result_dailyReport : result_dailyReport 
  // };

  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [dailyReportExpanded, setDailyReportExpanded] = React.useState<String | false>("panel1");
  const [showHistory, setShowHistory] = React.useState(false);
  // const { fire_createNote, loading_createNote } = CreateNote(result_userRead_raw?.id);
  const { fire_createNote, loading_createNote } = CreateNote(userId);

  const mapRef = useRef<any>();
  const rootUserName = result_getUserProfile?.name;

  const options = {
    layout: {
      hierarchical: false,
    },
    edges: {
      dashes: true,
    },
  };

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangeDailyReport = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setDailyReportExpanded(newExpanded ? panel : false);
  };


  const Accordion = withStyles({
    root: {
      borderBottom: "1px solid rgba(0, 0, 0, .1)",
      boxShadow: "none",
      "&:last-child": {
        borderBottom: 0,
      },
      "&$expanded": {
        margin: "auto",
      },
    },
    expanded: {},
  })(MuiAccordion);

  const classes = makeStyles(badgeStyles)();
  const [startDate, setStartDate] = useState<Date | null>(
    webStore.startDate || new Date()
  );
  const [endDate, setEndDate] = useState<Date | null>(
    webStore.endDate || new Date()
  );
  const [filterHour, setFilterHour] = useState<number | null>(
    webStore.filter_hour
  );
  const [startDateTracer, setStartDateTracer] = useState<Date | null>(
    webStore.startDateTracer || new Date()
  );
  const [endDateTracer, setEndDateTracer] = useState<Date | null>(
    webStore.endDateTracer || new Date()
  );
  // const { result_historyLog, loading_historyLog } = ContactTracerHistroyLog(userId, startDateTracer, endDateTracer);
  // const { result_getContactTracerInfo,
  //   result_contactInfo, 
  //   loading_getContactTracerInfo,
  //   result_nodeInfo,
  //   result_edgeArray
  //   } = GetContactTracerInfo(userId);
//   console.log("history log contact tracer:", result_historyLog);
//   console.log("contact info in user detail:", result_contactInfo);
  // const graphList = {
  //   nodes: result_nodeInfo,
  //   edges: result_edgeArray
  // };

//   console.log("graphList:", graphList);

  const onSearch = () => {
    webStore.setStartDate(startDate);
    webStore.setEndDate(endDate);
    webStore.set_filter_hour(filterHour);
  };

  const getHistory = () => {
    webStore.setStartDateTracer(startDateTracer);
    webStore.setEndDateTracer(endDateTracer);
  }

  const excelExport = () => {
    let instance = axios.create({  baseURL: "https://api.space-trax.com" }); 
    const method = 'GET';
    // /v2/dashboard/user/8dd0e063-79dd-4db0-866a-971df493a2c2/export?start_date=2021-11-05&end_date=2021-11-10

    const url = "https://api.space-trax.com/v2/dashboard/user/"+ userId 
    +"/export?start_date="+ dateStrAPI(startDate)
    +"&end_date=" +  dateStrAPI(endDate);
    const headers = {
      "Project-Key": localStorage.getItem("projectKey"),
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    let options: AxiosRequestConfig = { 
      url,
      method,
      responseType: 'blob' ,
      headers
    };

    return instance.request<any>(options)
    .then(response => { 
      // let filename = response.headers['content-disposition']
      //   .split(';')
      //   .find((n: any) => n.includes('filename='))
      //   .replace('filename=', '')
      //   .trim();  
      let filename = "Report_" + result_getUserProfile?.name + " " 
      +result_getUserProfile?.surname+ "_"+ dateStrAPI(new Date()) + ":" + timeStr(new Date()) + ".xlsx" 
      let url = window.URL
        .createObjectURL(new Blob([response.data]));     
      saveAs(url, filename);    
  });
  }

  // console.log("startDate", startDate ,":", "", endDate);

  const [isAddDevice, setIsAddDevice] = useState(false);
  const [newIMEI, setNewIMEI] = useState("");
  const [isAddNote, setIsAddNote] = useState(false);
  const [ addNoteValue, setAddNoteValue] = useState<any>();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  return (
    <>
      <Container maxWidth="xl" style={{ maxWidth: 1500 }} className="py-4 pb-16" id={"savePNG"}>
      {/* <SubBar title={"User Detail"} reload={false} back={true} /> */}
      {/* <Button onClick={onCapture}> Save PNG </Button> */}
      {
        localStorage.getItem("projectKey") === 'g9cntq4kj1ylkuc812i4bf4qrv4fmk90b2jpf7zl' ?
        <TitleBar title={"User Detail"} reload={false} back={true} 
          status={result_getUserProfile?.onboard?.quarantine_result_status.toString()}
          id = {result_getUserProfile?.onboard?.id}
        />
        :
        <TitleBar title={"User Detail"} reload={false} back={true} 
        />
      }

      {/* <div className="App">
        <Pdf targetRef={ref} filename="code-example.pdf">
          {({ toPdf } : {toPdf: any}) => <button onClick={toPdf}>Generate Pdf</button>}
        </Pdf>
        <div>
          <h1>Hello CodeSandbox</h1>
          <h2>Start editing to see some magic happen!</h2>
        </div>
      </div> */}
    {/* <button onClick={generatePDF}>test pdf</button> */}
      <Box>
        <Box
          css={{ borderRadius: 5, backgroundColor: "#2B5698", color: "#fff" }}
        >
          <Box display={{ xs: "block", md: "flex" }}>
            <Box
              p={4}
              width={{ xs: "100%", md: "40%" }}
              css={{ borderRight: "1px solid rgba(255,255,255,0.5)" }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {result_getUserProfile && (
                <UserProfileDetail reload={fire_userRead} user={result_getUserProfile} />
                // <UserProfile reload={fire_userRead} user={result_getUserProfile} userRaw={result_userRead_raw} />
              )}
            </Box>
            <Box py={4} px={6} width={{ xs: "100%", md: "60%" }}>
              {result_getUserProfile && (
                <UserInfoCardSI
                  reload={fire_userRead}
                  user={result_getUserProfile}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="flex justify-between items-center" mt={8} pb={2}>
        <Grid container>
            <Grid item md={4} xs={12}>
              <Box fontSize="h5.fontSize" fontWeight={500}>
                Health Report 
              </Box>
            </Grid>
            <Grid item md={8} xs={12}>
                  {
                      localStorage.getItem("projectKey") === 'r65nxurwl4rnoqlbv6n8jh2gxnddamhej11t6xml' || localStorage.getItem("projectKey") === 'xrsponolu71cznf0zr4pc9g3le5qghobbyigowog' 
                      || localStorage.getItem("projectKey") === 'g9cntq4kj1ylkuc812i4bf4qrv4fmk90b2jpf7zl' || localStorage.getItem("projectKey") === 'qk6oyhtc7c9ptbnffkd90xdpji2iye11v5lhw4qy'
                      || localStorage.getItem("projectKey") === 'pi7iotuircb2x90nqqizi4v91x0whrd5xgm42cro' || localStorage.getItem("projectKey") === '7zwp8sxengxpq37stixi4za1asmtm3fvx8dvkrzj'
                      || localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' 
                      ? 
                      <>
                        {
                           result_getUserProfile?.created_at ? 
                           <>
                              {
                                new Date(result_getUserProfile?.created_at) <= new Date('2022-03-22') ?
                                <Button startIcon={<SaveAltIcon />} 
                                  color="primary" size="medium" variant="contained" style={{float: 'right'}}
                                  onClick={()=> {
                                    history.push("/user/" + userId + "/diagonoses/" + result_getUserProfile?.onboard?.id);
                                    localStorage.setItem("user_id", user_id);
                                  }}
                                  >
                                      Export
                                </Button>
                                :
                                <a onClick={()=> {
                                      history.push("/user/" + userId + "/discharge/" + result_getUserProfile?.onboard?.id);
                                      localStorage.setItem("user_id", user_id);
                                    }}
                                  target="_blank">
                                      <Button startIcon={<SaveAltIcon />} 
                                        color="primary" size="medium" variant="contained" style={{float: 'right', marginRight: '2%'}}
                                        >
                                            Export
                                      </Button>
                                </a>
                              }
                           </>
                           :
                           <></>
                        }
                        
                      </>
                      :
                      <>
                        {
                          localStorage.getItem("projectKey") === '6mkx2x8oag4u63nfa8dhc287w7lknstsvcjk1xbb' ?
                          <></> :
                          <>
                              <Button startIcon={<SaveAltIcon />} 
                                color="primary" size="medium" variant="contained" style={{float: 'right'}}
                                onClick={()=> {
                                  history.push("/user/" + userId + "/vitalSigns");
                                }}
                                >
                                    Export
                              </Button>
                          </>
                        }
                        
                      </>
                    }
                    {
                      // localStorage.getItem("projectKey") === 'r65nxurwl4rnoqlbv6n8jh2gxnddamhej11t6xml' ||
                      localStorage.getItem("projectKey") === 'xrsponolu71cznf0zr4pc9g3le5qghobbyigowog' 
                      // || localStorage.getItem("projectKey") === 'g9cntq4kj1ylkuc812i4bf4qrv4fmk90b2jpf7zl' || localStorage.getItem("projectKey") === 'qk6oyhtc7c9ptbnffkd90xdpji2iye11v5lhw4qy'
                      // || localStorage.getItem("projectKey") === 'pi7iotuircb2x90nqqizi4v91x0whrd5xgm42cro' || localStorage.getItem("projectKey") === '7zwp8sxengxpq37stixi4za1asmtm3fvx8dvkrzj'
                      ? 
                      <>
                        {/* <a href={"https://isolation.pmhsolution.com/admin/get-user-export/"+ user_id +"?project_key=" + localStorage.getItem("projectKey")}
                        target="_blank">
                            <Button startIcon={<SaveAltIcon />} 
                              color="primary" size="medium" variant="contained" style={{float: 'right', marginRight: '2%'}}
                              >
                                  Patient care report 
                            </Button>
                        </a>  */}
                      </>
                      :
                      <></>
                    }
                    {/* <Button startIcon={<SaveAltIcon />} 
                      color="primary" size="medium" variant="contained" style={{float: 'right' , marginRight: '2%'}}
                      onClick={()=> {
                        history.push("/user/" + userId + "/medicalCertificate");
                      }}
                      >
                          Medical Certificate
                    </Button> */}
            </Grid>
        </Grid>
        
      </Box>

      <Box>
        {/* {(result_getUserProfile?.health || []).map((deivce, key) => {
          return ( */}
        {
            result_getUserProfile?.health ?
            <Accordion
            square
            expanded={expanded === `panel1`}
            onChange={handleChange(`panel1`)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                display={{ xs: "block", md: "flex" }}
                alignItems="center"
                px={{ xs: 0, md: 3 }}
                py={2}
              >
                <Box
                  fontSize={12}
                  css={{ color: "#4B4C56" }}
                  mt={{ xs: 2, md: 0 }}
                >
                  Latest update :
                  {
                    result_getUserProfile?.health?.updated_at ?
                    dateStrAPI(result_getUserProfile?.health?.updated_at) + ", " + timeStr(result_getUserProfile?.health?.updated_at)
                    :
                    ""
                  }
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box px={{ xs: 0, md: 3 }} width="100%">
                <Grid container xs={12}>
                  <Grid item xs={12} md={6}>
                    <Box
                      display="flex"
                      width="100%"
                      paddingRight={{ md: 5 }}
                      mb={{ xs: 4, md: 0 }}
                    >
                      <Grid item xs={4}>
                        <DeviceField label="Body temp">
                          <Box fontWeight={600}>
                            {result_getUserProfile?.health?.body_temp ? result_getUserProfile?.health?.body_temp : "N/A"}
                          </Box>
                        </DeviceField>
                      </Grid>
                      <Grid item xs={4}>
                        <DeviceField label="Heart rate">
                          <Box fontWeight={600}>
                            {result_getUserProfile?.health?.heart_rate ? result_getUserProfile?.health?.heart_rate : "N/A"}
                          </Box>
                        </DeviceField>
                      </Grid>
                      <Grid item xs={4}>
                        <DeviceField
                          label="Blood pressure"
                          border={{ xs: 0, md: 1 }}
                        >
                          <Box fontWeight={600}>
                            { result_getUserProfile?.health?.blood_systolic && result_getUserProfile?.health?.blood_diastolic ?
                            `${result_getUserProfile?.health?.blood_systolic}/${result_getUserProfile?.health?.blood_diastolic}`
                            :
                            "N/A"
                            }</Box>
                        </DeviceField>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box display="flex" width="100%" mb={{ xs: 4, md: 0 }}>
                      <Grid item xs={4}>
                        <DeviceField label="Blood Sugar">
                          <Box fontWeight={600}>
                            {result_getUserProfile?.health?.blood_sugar ? result_getUserProfile?.health?.blood_sugar : "N/A"}
                          </Box>
                        </DeviceField>
                      </Grid>
                      <Grid item xs={4}>
                        <DeviceField
                          label="Blood Oxygen"
                        >
                          <Box fontWeight={600}>
                            {result_getUserProfile?.health?.blood_oxygen ? result_getUserProfile?.health?.blood_oxygen : "N/A"}
                          </Box>
                        </DeviceField>
                      </Grid>
                      <Grid item xs={4}>
                        <DeviceField
                          label="Respiratoin(per min)"
                        >
                          <Box fontWeight={600}>
                              {result_getUserProfile?.health?.breath ? result_getUserProfile?.health?.breath : "N/A"}
                          </Box>
                        </DeviceField>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
          :
          <></>
        }
            
          {/* ); */}
        {/* // })} */}
      </Box>

      {
        localStorage.getItem("projectKey") === '6mkx2x8oag4u63nfa8dhc287w7lknstsvcjk1xbb' ?
        <></> :
        <>
            <Box className="flex justify-between items-center" mt={8} pb={2}>
        <Grid container>
          <Grid item md={8} xs={12}>
            <Box fontSize="h5.fontSize" fontWeight={500}>
              Daily Report
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
              
              <Button
                color="primary"
                size="medium"
                variant="contained"
                style={{marginLeft: '3%', float:'right'}}
                onClick={() => {
                  if(userId){
                    history.push("/user/" + userId + "/noteList");
                  }else{
                    history.push("/user/" + "empty_Id" + "/noteList");
                  }
                  // if(result_userRead_raw?.id){
                  //   history.push("/user/" + result_userRead_raw?.id + "/noteList");
                  // }else{
                  //   history.push("/user/" + "empty_Id" + "/noteList");
                  // }
                  
                }}
              >
                View Notes
              </Button>

              <Button startIcon={<NoteAddIcon />} 
              color="primary" size="medium" variant="contained" style={{float: 'right'}}
              onClick={()=> {
                setIsAddNote(true);
              }}
              >
                    Add Note
              </Button>
          </Grid>
        </Grid>

        <AddNoteModal
        {...addNoteValue}
        isAddNote={isAddNote}
        setIsAddNote={setIsAddNote}
        setAddNoteValue={setAddNoteValue}
        label = {dateStrAPIFormat(new Date())}
        onClick={async () => {
          setLoading(true);
          fire_createNote(
              addNoteValue.note
          )
            .then(({ data }: { data: any }) => {    
              setAddNoteValue({note: ""});
              enqueueSnackbar(`Create Note Success`, {
                key: "editSuccess",
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              });
            })
            .catch((ex: any) => {
              enqueueSnackbar("Create Note Failed", {
                key: "editError",
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              });
            });
          setIsAddNote(false);
          setLoading(false);
          
        }}
      />
        
      </Box>
      <Box css={{ borderRadius: 5, backgroundColor: "#fff" }}>
        {
          result_dailyReport?.length > 0 ?
          <Button
          startIcon={<ExpandMoreIcon style={{fontSize: '26px', marginLeft: '3px'}}/>}
          size="small"
          onClick={()=> setShowHistory(!showHistory)}
          style={{float: 'right', color: 'grey', borderRadius: '50%', padding: '12px'}}
        >
        </Button> : <>
        <p style={{color:'grey', textAlign: 'center', paddingTop:'3%'}}> There is no data. </p>
        </>
        }      
        
        <br/>  
        {
                    result_dailyReport?.length > 0 ?
                    <>
                    <Box px={{ xs: 0, md: 3 }} width="100%">
                        <Grid container xs={12}>
                        {/* #0000ff blue */}
                        {
                          result_dailyReport[0].assesment_period === 1 ? 
                            <Grid item xs={12} md={1}>
                              <Box
                                  display="flex"
                                  width="100%"
                                  paddingRight={{ md: 5 }}
                                  paddingTop = {{ md: 5 }}
                                  mb={{ xs: 3, md: 0 }}>
                                    <Grid item>
                                      <Box display="flex" alignItems="center">
                                        <WbSunnyOutlinedIcon style={{fontSize: '28px', color: 'gold'}}/>
                                        <Box ml={2} mr={4} fontSize="h5.fontsize" fontWeight={600} style={{color:'gold'}}>
                                              เช้า
                                        </Box>
                                      </Box>
                                  </Grid>
                            </Box>
                          </Grid> :
                            result_dailyReport[0].assesment_period === 2 ?
                          <Grid item xs={12} md={1}>
                          <Box
                              display="flex"
                              width="100%"
                              paddingRight={{ md: 5 }}
                              paddingTop = {{ md: 5 }}
                              mb={{ xs: 3, md: 0 }}>
                                <Grid item>
                                      <Box display="flex" alignItems="center">
                                        <FilterDramaIcon style={{fontSize: '28px', color: 'midnightblue'}}/>
                                        <Box ml={2} mr={4} fontSize="h5.fontsize" fontWeight={600} style={{color: 'midnightblue'}}>
                                            บ่าย
                                        </Box>
                                      </Box>
                                  </Grid>
                                
                          </Box>
                          </Grid>
                          :
                          <Grid item xs={12} md={1}>
                            <Box
                                display="flex"
                                width="100%"
                                paddingRight={{ md: 5 }}
                                paddingTop = {{ md: 5 }}
                                mb={{ xs: 3, md: 0 }}>
                                <Grid item>
                                    <Box display="flex" alignItems="center">
                                      <NightsStayOutlinedIcon style={{fontSize: '28px', color: 'midnightblue'}}/>
                                      <Box ml={2} mr={4} fontSize="h5.fontsize" fontWeight={600} style={{color: 'midnightblue'}}>
                                          เย็น
                                      </Box>
                                    </Box>
                                </Grid>
                            </Box>
                          </Grid>
                        }
                          <Grid item xs={12} md={2}>
                              <Box
                                  display="flex"
                                  width="100%"
                                  paddingRight={{ md: 5 }}
                                  paddingTop = {{ md: 5 }}
                                  mb={{ xs: 3, md: 0 }}>
                                {
                                  result_dailyReport[0].symptom_case === 2 ?
                                  <Grid item>
                                    <Box display="flex" alignItems="center">
                                      <Box ml={2} 
                                        css={{ color: "#ff0000" }}
                                      fontSize="h5.fontsize" fontWeight={600}>
                                          มีอาการผิดปกติ
                                      </Box>
                                    </Box>  
                                  </Grid>
                                  :
                                  <Grid item>
                                    <Box display="flex" alignItems="center">
                                      <Box ml={2}
                                        css={{ color: "#10940E" }}
                                      fontSize="h5.fontsize" fontWeight={600}>
                                          สุขภาพปกติ
                                      </Box>
                                    </Box>
                                  </Grid>
                                }
                              
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={4}>
                              <Box
                                display="flex"
                                width="100%"
                                paddingRight={{ md: 5 }}
                                mb={{ xs: 3, md: 0 }}
                              >
                                <Grid item xs={4}>
                                  <DeviceField
                                    label="Temperature"
                                  >
                                    <Box
                                      fontWeight={600} mt={2.6}
                                    >
                                      {
                                        result_dailyReport[0].tempurature >= 38.5 || result_dailyReport[0].tempurature <= 35 ? 
                                        <span style={{color: 'red'}}>{result_dailyReport[0].tempurature} </span>
                                        :
                                        <>{result_dailyReport[0].tempurature}</>
                                      }
                                    </Box>
                                  </DeviceField>
                                </Grid>

                                <Grid item xs={4}>
                                  <DeviceField
                                    label="Heart Rate"
                                  >
                                    <Box
                                      fontWeight={600} mt={2.6}
                                    >
                                      {
                                        result_dailyReport[0].heart_rate <= 60 || result_dailyReport[0].heart_rate >= 125 ? 
                                          <span style={{color: 'red'}}> 
                                              {result_dailyReport[0].heart_rate}
                                          </span>
                                        : 
                                        <>{result_dailyReport[0].heart_rate}</>
                                      } 
                                    </Box>
                                  </DeviceField>
                                </Grid>

                                <Grid item xs={4}>
                                <DeviceField
                                    label="Blood Pressure"
                                  >
                                    <Box
                                      fontWeight={600} mt={2.6}
                                    >{result_dailyReport[0].blood_systolic + "/" + result_dailyReport[0].blood_diastolic}</Box>
                                  </DeviceField>
                                </Grid>
                              </Box>
                          </Grid>

                          <Grid item xs={12} md={3} style={{marginLeft:'-2%'}}>
                              <Box
                                display="flex"
                                width="100%"
                                paddingRight={{ md: 5 }}
                                mb={{ xs: 3, md: 0 }}
                              >
                              <Grid item xs={3}>
                                  <Box>
                                      <Box css={{ color: "#8F919C" }} fontSize={12} mb={0.5}>
                                          SPO<sub>2</sub>
                                      </Box>
                                      <Box css={{ color: "#8F919C" }} fontSize={8} mb={0.5}>
                                          (before)
                                      </Box>
                                      <Box fontSize={18}>
                                        <Box fontWeight={600}>
                                           { result_dailyReport[0].oxygen_after <= 94 ?
                                              <span style={{color: 'red'}}> {result_dailyReport[0].oxygen_after} </span>
                                              :
                                              <span> {result_dailyReport[0].oxygen_after} </span>
                                            }
                                        </Box>
                                      </Box>
                                  </Box>
                                </Grid>
                                
                                <Grid item xs={3}>
                                  <Box>
                                      <Box css={{ color: "#8F919C" }} fontSize={12} mb={0.5}>
                                          SPO<sub>2</sub>
                                      </Box>
                                      <Box css={{ color: "#8F919C" }} fontSize={8} mb={0.5}>
                                          (after)
                                      </Box>
                                      <Box fontSize={18}>
                                        <Box fontWeight={600}>
                                          { result_dailyReport[0].oxygen_before <= 94 ?
                                            <span style={{color: 'red'}}> {result_dailyReport[0].oxygen_before} </span>
                                            :
                                            <span> {result_dailyReport[0].oxygen_before} </span>
                                          }
                                          
                                        </Box>
                                      </Box>
                                  </Box>
                                </Grid>
                                
                                <Grid item xs={3}>
                                  <DeviceField
                                    label="ปัสสาวะ"
                                  >
                                    <Box
                                      fontWeight={600} mt={2.6}
                                    >{result_dailyReport[0].urination_among}</Box>
                                  </DeviceField>
                                </Grid>
                                
                                <Grid item xs={3}>
                                  <DeviceField
                                    label="อุจจาระ"
                                  >
                                    <Box
                                      fontWeight={600} mt={2.6}
                                    >{result_dailyReport[0].defecation_among}</Box>
                                  </DeviceField>
                                </Grid>
                              </Box>
                          </Grid>

                          <Grid item xs={12} md={1} style={{marginRight :"2%"}}>
                                  <DeviceField
                                    label="อาการผิดปกติ"
                                  >
                                    <Box
                                      mt={2.6}
                                    >
                                      {
                                        result_dailyReport[0].symptoms_one ?
                                        <Grid item xs={12}>
                                          <Box
                                            fontSize={12}
                                            css={{ color: "#ff0000" }}
                                            mt={{ xs: 2, md: 0 }}
                                          >
                                          {result_dailyReport[0].symptoms_one + ", "+ result_dailyReport[0].symptoms_two}
                                          </Box>
                                        </Grid>
                                        :
                                        <></>
                                      }
                                    
                                    </Box>
                                  </DeviceField>
                          </Grid>

                          <Grid item xs={12} md={1}>
                                  <DeviceField
                                    label="Latest update:"
                                  >
                                    <Box
                                    fontSize={12}
                                    css={{ color: "#4B4C56" }}
                                      mt={2.6}
                                    >
                                        {dateStrAPI(result_dailyReport[0].updated_at)} : <br/>
                                        {timeStr(result_dailyReport[0].updated_at)}
                                    
                                    </Box>
                                  </DeviceField>
                          </Grid>
                        </Grid>
                        </Box>
                          <br/></>
                        : <></>
                  }

          {
            result_dailyReport.length>1 ? 
            <hr style={{
              color: 'grey',
              backgroundColor: 'grey',
              height: '5',
              width: '100%'
          }} /> : <> 

          </>
          }        
      </Box>
      <Box css={{ borderRadius: 5, backgroundColor: "#fff"}}>
        <br/>
      {
                    result_dailyReport?.length > 1 ? <>
                    <Box px={{ xs: 0, md: 3 }} width="100%">
                      <Grid container xs={12}>
                      {/* #0000ff blue */}
                      {
                        result_dailyReport[1].assesment_period === 1 ? 
                        <Grid item xs={12} md={1}>
                          <Box
                              display="flex"
                              width="100%"
                              paddingRight={{ md: 5 }}
                              paddingTop = {{ md: 5 }}
                              mb={{ xs: 3, md: 0 }}>
                                <Grid item>
                                  <Box display="flex" alignItems="center">
                                    <WbSunnyOutlinedIcon style={{fontSize: '28px', color: 'gold'}}/>
                                    <Box ml={2} mr={4} fontSize="h5.fontsize" fontWeight={600} style={{color:'gold'}}>
                                          เช้า
                                    </Box>
                                  </Box>
                              </Grid>
                        </Box>
                      </Grid> :
                        result_dailyReport[1].assesment_period === 2 ?
                        <Grid item xs={12} md={1}>
                        <Box
                          display="flex"
                          width="100%"
                          paddingRight={{ md: 5 }}
                          paddingTop = {{ md: 5 }}
                          mb={{ xs: 3, md: 0 }}>
                            <Grid item>
                                  <Box display="flex" alignItems="center">
                                    <FilterDramaIcon style={{fontSize: '28px', color: 'midnightblue'}}/>
                                    <Box ml={2} mr={4} fontSize="h5.fontsize" fontWeight={600} style={{color: 'midnightblue'}}>
                                        บ่าย
                                    </Box>
                                  </Box>
                              </Grid>
                            
                        </Box>
                        </Grid>
                        :
                        <Grid item xs={12} md={1}>
                        <Box
                            display="flex"
                            width="100%"
                            paddingRight={{ md: 5 }}
                            paddingTop = {{ md: 5 }}
                            mb={{ xs: 3, md: 0 }}>
                             <Grid item>
                                <Box display="flex" alignItems="center">
                                  <NightsStayOutlinedIcon style={{fontSize: '28px', color: 'midnightblue'}}/>
                                  <Box ml={2} mr={4} fontSize="h5.fontsize" fontWeight={600} style={{color: 'midnightblue'}}>
                                      เย็น
                                  </Box>
                                </Box>
                            </Grid> 
                        </Box>
                        </Grid>

                    }
                      <Grid item xs={12} md={2}>
                          <Box
                              display="flex"
                              width="100%"
                              paddingRight={{ md: 5 }}
                              paddingTop = {{ md: 5 }}
                              mb={{ xs: 3, md: 0 }}>
                            {
                              result_dailyReport[1].symptom_case === 2 ?
                              <Grid item>
                                <Box display="flex" alignItems="center">
                                  <Box ml={2} 
                                    css={{ color: "#ff0000" }}
                                  fontSize="h5.fontsize" fontWeight={600}>
                                      มีอาการผิดปกติ
                                  </Box>
                                </Box>  
                              </Grid>
                              :
                              <Grid item>
                                <Box display="flex" alignItems="center">
                                  <Box ml={2}
                                    css={{ color: "#10940E" }}
                                  fontSize="h5.fontsize" fontWeight={600}>
                                      สุขภาพปกติ
                                  </Box>
                                </Box>
                              </Grid>
                            }
                          
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4}>
                          <Box
                            display="flex"
                            width="100%"
                            paddingRight={{ md: 5 }}
                            mb={{ xs: 3, md: 0 }}
                          >
                            <Grid item xs={4}>
                              <DeviceField
                                label="Temperature"
                              >
                                <Box
                                  fontWeight={600} mt={2.6}
                                >
                                  {
                                    result_dailyReport[1].tempurature >= 38.5 || result_dailyReport[1].tempurature <= 35 ? 
                                    <span style={{color: 'red'}}>{result_dailyReport[1].tempurature} </span>
                                    :
                                    <>{result_dailyReport[1].tempurature}</>
                                  }
                                </Box>
                              </DeviceField>
                            </Grid>

                            <Grid item xs={4}>
                              <DeviceField
                                label="Heart Rate"
                              >
                                <Box
                                  fontWeight={600} mt={2.6}
                                >
                                  {
                                    result_dailyReport[1].heart_rate <= 60 || result_dailyReport[1].heart_rate >= 125 ? 
                                      <span style={{color: 'red'}}> 
                                          {result_dailyReport[1].heart_rate}
                                      </span>
                                    : 
                                    <>{result_dailyReport[1].heart_rate}</>
                                  }
                                </Box>
                              </DeviceField>
                            </Grid>

                            <Grid item xs={4}>
                            <DeviceField
                                label="Blood Pressure"
                              >
                                <Box
                                  fontWeight={600} mt={2.6}
                                >{result_dailyReport[1].blood_systolic + "/" + result_dailyReport[1].blood_diastolic}</Box>
                              </DeviceField>
                            </Grid>
                          </Box>
                      </Grid>

                      <Grid item xs={12} md={3} style={{marginLeft:'-2%'}}>
                          <Box
                            display="flex"
                            width="100%"
                            paddingRight={{ md: 5 }}
                            mb={{ xs: 3, md: 0 }}
                          >
                          <Grid item xs={3}>
                              <Box>
                                  <Box css={{ color: "#8F919C" }} fontSize={12} mb={0.5}>
                                      SPO<sub>2</sub>
                                  </Box>
                                  <Box css={{ color: "#8F919C" }} fontSize={8} mb={0.5}>
                                      (before)
                                  </Box>
                                  <Box fontSize={18}>
                                    <Box fontWeight={600}>
                                      { result_dailyReport[1].oxygen_after <= 94 ?
                                        <span style={{color: 'red'}}> {result_dailyReport[1].oxygen_after} </span>
                                        :
                                        <span> {result_dailyReport[1].oxygen_after} </span>
                                      }
                                    </Box>
                                  </Box>
                              </Box>
                            </Grid>
                            
                            <Grid item xs={3}>
                              <Box>
                                  <Box css={{ color: "#8F919C" }} fontSize={12} mb={0.5}>
                                      SPO<sub>2</sub>
                                  </Box>
                                  <Box css={{ color: "#8F919C" }} fontSize={8} mb={0.5}>
                                      (after)
                                  </Box>
                                  <Box fontSize={18}>
                                    <Box fontWeight={600}>
                                      { result_dailyReport[1].oxygen_before <= 94 ?
                                        <span style={{color: 'red'}}> {result_dailyReport[1].oxygen_before} </span>
                                        :
                                        <span> {result_dailyReport[1].oxygen_before} </span>
                                      }
                                      
                                    </Box>
                                  </Box>
                              </Box>
                            </Grid>
                            
                            <Grid item xs={3}>
                              <DeviceField
                                label="ปัสสาวะ"
                              >
                                <Box
                                  fontWeight={600} mt={2.6}
                                >{result_dailyReport[1].urination_among}</Box>
                              </DeviceField>
                            </Grid>
                            
                            <Grid item xs={3}>
                              <DeviceField
                                label="อุจจาระ"
                              >
                                <Box
                                  fontWeight={600} mt={2.6}
                                >{result_dailyReport[1].defecation_among}</Box>
                              </DeviceField>
                            </Grid>
                          </Box>
                      </Grid>

                      <Grid item xs={12} md={1} style={{marginRight :"2%"}}>
                              <DeviceField
                                label="อาการผิดปกติ"
                              >
                                <Box
                                  mt={2.6}
                                >
                                  {
                                    result_dailyReport[1].symptoms_one ?
                                    <Grid item xs={12}>
                                      <Box
                                        fontSize={12}
                                        css={{ color: "#ff0000" }}
                                        mt={{ xs: 2, md: 0 }}
                                      >
                                      {result_dailyReport[1].symptoms_one + ", "+ result_dailyReport[1].symptoms_two}
                                      </Box>
                                    </Grid>
                                    :
                                    <></>
                                  }
                                
                                </Box>
                              </DeviceField>
                      </Grid>

                      <Grid item xs={12} md={1}>
                              <DeviceField
                                label="Latest update:"
                              >
                                <Box
                                fontSize={12}
                                css={{ color: "#4B4C56" }}
                                  mt={2.6}
                                >
                                    {dateStrAPI(result_dailyReport[1].updated_at)} : <br/>
                                    {timeStr(result_dailyReport[1].updated_at)}
                                
                                </Box>
                              </DeviceField>
                      </Grid>
                    </Grid>
                    </Box> <br/> </>
                    : <></>
                  }
          {
            result_dailyReport.length>2 ? 
            <hr style={{
              color: 'grey',
              backgroundColor: 'grey',
              height: '5',
              width: '100%'
          }} /> : <> 

          </>
          }  
      </Box>


      <Box css={{ borderRadius: 5, backgroundColor: "#fff"}}>
        <br/>
      {
                    result_dailyReport?.length > 2 ? <>
                    <Box px={{ xs: 0, md: 3 }} width="100%">
                      <Grid container xs={12}>
                      {/* #0000ff blue */}
                      {
                        result_dailyReport[2].assesment_period === 1 ? 
                        <Grid item xs={12} md={1}>
                          <Box
                              display="flex"
                              width="100%"
                              paddingRight={{ md: 5 }}
                              paddingTop = {{ md: 5 }}
                              mb={{ xs: 3, md: 0 }}>
                                <Grid item>
                                  <Box display="flex" alignItems="center">
                                    <WbSunnyOutlinedIcon style={{fontSize: '28px', color: 'gold'}}/>
                                    <Box ml={2} mr={4} fontSize="h5.fontsize" fontWeight={600} style={{color:'gold'}}>
                                          เช้า
                                    </Box>
                                  </Box>
                              </Grid>
                        </Box>
                      </Grid> :
                        result_dailyReport[2].assesment_period === 2 ?
                        <Grid item xs={12} md={1}>
                        <Box
                          display="flex"
                          width="100%"
                          paddingRight={{ md: 5 }}
                          paddingTop = {{ md: 5 }}
                          mb={{ xs: 3, md: 0 }}>
                            <Grid item>
                                  <Box display="flex" alignItems="center">
                                    <FilterDramaIcon style={{fontSize: '28px', color: 'midnightblue'}}/>
                                    <Box ml={2} mr={4} fontSize="h5.fontsize" fontWeight={600} style={{color: 'midnightblue'}}>
                                        บ่าย
                                    </Box>
                                  </Box>
                              </Grid>
                            
                        </Box>
                        </Grid>
                        :
                        <Grid item xs={12} md={1}>
                        <Box
                            display="flex"
                            width="100%"
                            paddingRight={{ md: 5 }}
                            paddingTop = {{ md: 5 }}
                            mb={{ xs: 3, md: 0 }}>
                             <Grid item>
                                <Box display="flex" alignItems="center">
                                  <NightsStayOutlinedIcon style={{fontSize: '28px', color: 'midnightblue'}}/>
                                  <Box ml={2} mr={4} fontSize="h5.fontsize" fontWeight={600} style={{color: 'midnightblue'}}>
                                      เย็น
                                  </Box>
                                </Box>
                            </Grid> 
                        </Box>
                        </Grid>

                    }
                      <Grid item xs={12} md={2}>
                          <Box
                              display="flex"
                              width="100%"
                              paddingRight={{ md: 5 }}
                              paddingTop = {{ md: 5 }}
                              mb={{ xs: 3, md: 0 }}>
                            {
                              result_dailyReport[2].symptom_case === 2 ?
                              <Grid item>
                                <Box display="flex" alignItems="center">
                                  <Box ml={2} 
                                    css={{ color: "#ff0000" }}
                                  fontSize="h5.fontsize" fontWeight={600}>
                                      มีอาการผิดปกติ
                                  </Box>
                                </Box>  
                              </Grid>
                              :
                              <Grid item>
                                <Box display="flex" alignItems="center">
                                  <Box ml={2}
                                    css={{ color: "#10940E" }}
                                  fontSize="h5.fontsize" fontWeight={600}>
                                      สุขภาพปกติ
                                  </Box>
                                </Box>
                              </Grid>
                            }
                          
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4}>
                          <Box
                            display="flex"
                            width="100%"
                            paddingRight={{ md: 5 }}
                            mb={{ xs: 3, md: 0 }}
                          >
                            <Grid item xs={4}>
                              <DeviceField
                                label="Temperature"
                              >
                                <Box
                                  fontWeight={600} mt={2.6}
                                >
                                  {
                                    result_dailyReport[2].tempurature >= 38.5 || result_dailyReport[2].tempurature <= 35 ? 
                                    <span style={{color: 'red'}}>{result_dailyReport[2].tempurature} </span>
                                    :
                                    <>{result_dailyReport[2].tempurature}</>
                                  }
                                </Box>
                              </DeviceField>
                            </Grid>

                            <Grid item xs={4}>
                              <DeviceField
                                label="Heart Rate"
                              >
                                <Box
                                  fontWeight={600} mt={2.6}
                                >
                                  {
                                    result_dailyReport[2].heart_rate <= 60 || result_dailyReport[2].heart_rate >= 125 ? 
                                      <span style={{color: 'red'}}> 
                                          {result_dailyReport[2].heart_rate}
                                      </span>
                                    : 
                                    <>{result_dailyReport[2].heart_rate}</>
                                  }
                                </Box>
                              </DeviceField>
                            </Grid>

                            <Grid item xs={4}>
                            <DeviceField
                                label="Blood Pressure"
                              >
                                <Box
                                  fontWeight={600} mt={2.6}
                                >{result_dailyReport[2].blood_systolic + "/" + result_dailyReport[2].blood_diastolic}</Box>
                              </DeviceField>
                            </Grid>
                          </Box>
                      </Grid>

                      <Grid item xs={12} md={3} style={{marginLeft:'-2%'}}>
                          <Box
                            display="flex"
                            width="100%"
                            paddingRight={{ md: 5 }}
                            mb={{ xs: 3, md: 0 }}
                          >
                          <Grid item xs={3}>
                              <Box>
                                  <Box css={{ color: "#8F919C" }} fontSize={12} mb={0.5}>
                                      SPO<sub>2</sub>
                                  </Box>
                                  <Box css={{ color: "#8F919C" }} fontSize={8} mb={0.5}>
                                      (before)
                                  </Box>
                                  <Box fontSize={18}>
                                    <Box fontWeight={600}>
                                      { result_dailyReport[2].oxygen_after <= 94 ?
                                        <span style={{color: 'red'}}> {result_dailyReport[2].oxygen_after} </span>
                                        :
                                        <span> {result_dailyReport[2].oxygen_after} </span>
                                      }
                                    </Box>
                                  </Box>
                              </Box>
                            </Grid>
                            
                            <Grid item xs={3}>
                              <Box>
                                  <Box css={{ color: "#8F919C" }} fontSize={12} mb={0.5}>
                                      SPO<sub>2</sub>
                                  </Box>
                                  <Box css={{ color: "#8F919C" }} fontSize={8} mb={0.5}>
                                      (after)
                                  </Box>
                                  <Box fontSize={18}>
                                    <Box fontWeight={600}>
                                      { result_dailyReport[2].oxygen_before <= 94 ?
                                        <span style={{color: 'red'}}> {result_dailyReport[2].oxygen_before} </span>
                                        :
                                        <span> {result_dailyReport[2].oxygen_before} </span>
                                      }
                                      
                                    </Box>
                                  </Box>
                              </Box>
                            </Grid>
                            
                            <Grid item xs={3}>
                              <DeviceField
                                label="ปัสสาวะ"
                              >
                                <Box
                                  fontWeight={600} mt={2.6}
                                >{result_dailyReport[2].urination_among}</Box>
                              </DeviceField>
                            </Grid>
                            
                            <Grid item xs={3}>
                              <DeviceField
                                label="อุจจาระ"
                              >
                                <Box
                                  fontWeight={600} mt={2.6}
                                >{result_dailyReport[2].defecation_among}</Box>
                              </DeviceField>
                            </Grid>
                          </Box>
                      </Grid>

                      <Grid item xs={12} md={1} style={{marginRight :"2%"}}>
                              <DeviceField
                                label="อาการผิดปกติ"
                              >
                                <Box
                                  mt={2.6}
                                >
                                  {
                                    result_dailyReport[2].symptoms_one ?
                                    <Grid item xs={12}>
                                      <Box
                                        fontSize={12}
                                        css={{ color: "#ff0000" }}
                                        mt={{ xs: 2, md: 0 }}
                                      >
                                      {result_dailyReport[2].symptoms_one + ", "+ result_dailyReport[2].symptoms_two}
                                      </Box>
                                    </Grid>
                                    :
                                    <></>
                                  }
                                
                                </Box>
                              </DeviceField>
                      </Grid>

                      <Grid item xs={12} md={1}>
                              <DeviceField
                                label="Latest update:"
                              >
                                <Box
                                fontSize={12}
                                css={{ color: "#4B4C56" }}
                                  mt={2.6}
                                >
                                    {dateStrAPI(result_dailyReport[2].updated_at)} : <br/>
                                    {timeStr(result_dailyReport[2].updated_at)}
                                
                                </Box>
                              </DeviceField>
                      </Grid>
                    </Grid>
                    </Box> <br/> </>
                    : <></>
                  } 
      </Box>
      
      {
        showHistory ? <>
        <Box className="flex justify-between items-center" mt={8} pb={2}>
          <Box fontSize="h6.fontSize" fontWeight={300}>
            Daily Report History
          </Box>
        </Box>
        <Box css={{ borderRadius: 5, backgroundColor: "#fff"}}>
      {(result_dailyReport || []).map((dailyReport, key) => {
              return (
                <>
                <br/>
                <Box px={{ xs: 0, md: 3 }} width="100%">
                <Grid container xs={12}>
                {/* #0000ff blue */}
                {
                  dailyReport.assesment_period === 1 ? 
                    <Grid item xs={12} md={1}>
                      <Box
                          display="flex"
                          width="100%"
                          paddingRight={{ md: 5 }}
                          paddingTop = {{ md: 5 }}
                          mb={{ xs: 3, md: 0 }}>
                            <Grid item>
                              <Box display="flex" alignItems="center">
                                <WbSunnyOutlinedIcon style={{fontSize: '28px', color: 'gold'}}/>
                                <Box ml={2} mr={4} fontSize="h5.fontsize" fontWeight={600} style={{color:'gold'}}>
                                      เช้า
                                </Box>
                              </Box>
                          </Grid>
                    </Box>
                  </Grid> :
                    dailyReport.assesment_period === 2 ?
                  <Grid item xs={12} md={1}>
                  <Box
                      display="flex"
                      width="100%"
                      paddingRight={{ md: 5 }}
                      paddingTop = {{ md: 5 }}
                      mb={{ xs: 3, md: 0 }}>
                        <Grid item>
                            <Box display="flex" alignItems="center">
                              <FilterDramaIcon style={{fontSize: '28px', color: 'midnightblue'}}/>
                              <Box ml={2} mr={4} fontSize="h5.fontsize" fontWeight={600} style={{color: 'midnightblue'}}>
                                  บ่าย
                              </Box>
                            </Box>
                        </Grid>
                  </Box>
                  </Grid>
                  :
                  <Grid item xs={12} md={1}>
                    <Box
                        display="flex"
                        width="100%"
                        paddingRight={{ md: 5 }}
                        paddingTop = {{ md: 5 }}
                        mb={{ xs: 3, md: 0 }}>
                        <Grid item>
                            <Box display="flex" alignItems="center">
                              <NightsStayOutlinedIcon style={{fontSize: '28px', color: 'midnightblue'}}/>
                              <Box ml={2} mr={4} fontSize="h5.fontsize" fontWeight={600} style={{color: 'midnightblue'}}>
                                  เย็น
                              </Box>
                            </Box>
                        </Grid>
                    </Box>
                  </Grid>

                }
                  <Grid item xs={12} md={2}>
                      <Box
                          display="flex"
                          width="100%"
                          paddingRight={{ md: 5 }}
                          paddingTop = {{ md: 5 }}
                          mb={{ xs: 3, md: 0 }}>
                        {
                           dailyReport.symptom_case === 2 ?
                           <Grid item>
                             <Box display="flex" alignItems="center">
                              <Box ml={2} 
                                css={{ color: "#ff0000" }}
                              fontSize="h5.fontsize" fontWeight={600}>
                                  มีอาการผิดปกติ
                              </Box>
                            </Box>  
                          </Grid>
                          :
                          <Grid item>
                            <Box display="flex" alignItems="center">
                              <Box ml={2}
                                css={{ color: "#10940E" }}
                               fontSize="h5.fontsize" fontWeight={600}>
                                  สุขภาพปกติ
                              </Box>
                            </Box>
                          </Grid>
                        }
                      
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                      <Box
                        display="flex"
                        width="100%"
                        paddingRight={{ md: 5 }}
                        mb={{ xs: 3, md: 0 }}
                      >
                        <Grid item xs={4}>
                          <DeviceField
                            label="Temperature"
                          >
                            <Box
                              fontWeight={600} mt={2.6}
                            >
                              {
                                dailyReport.tempurature >= 38.5 || dailyReport.tempurature <= 35 ? 
                                <span style={{color: 'red'}}>{dailyReport.tempurature} </span>
                                :
                                <>{dailyReport.tempurature}</>
                              }
                            </Box>
                          </DeviceField>
                        </Grid>

                        <Grid item xs={4}>
                          <DeviceField
                            label="Heart Rate"
                          >
                            <Box
                              fontWeight={600} mt={2.6}
                            >
                              {
                                dailyReport.heart_rate <= 60 || dailyReport.heart_rate >= 125 ? 
                                  <span style={{color: 'red'}}> 
                                      {dailyReport.heart_rate}
                                  </span>
                                : 
                                <>{dailyReport.heart_rate}</>
                              }
                             
                            </Box>
                          </DeviceField>
                        </Grid>

                        <Grid item xs={4}>
                        <DeviceField
                            label="Blood Pressure"
                          >
                            <Box
                              fontWeight={600} mt={2.6}
                            >{dailyReport.blood_systolic + "/" + dailyReport.blood_diastolic}</Box>
                          </DeviceField>
                        </Grid>
                      </Box>
                  </Grid>

                  <Grid item xs={12} md={3} style={{marginLeft:'-2%'}}>
                      <Box
                        display="flex"
                        width="100%"
                        paddingRight={{ md: 5 }}
                        mb={{ xs: 3, md: 0 }}
                      >
                      <Grid item xs={3}>
                          <Box>
                              <Box css={{ color: "#8F919C" }} fontSize={12} mb={0.5}>
                                  SPO<sub>2</sub>
                              </Box>
                              <Box css={{ color: "#8F919C" }} fontSize={8} mb={0.5}>
                                  (before)
                              </Box>
                              <Box fontSize={18}>
                                <Box fontWeight={600}>
                                  { dailyReport.oxygen_after <= 94 ?
                                    <span style={{color: 'red'}}> {dailyReport.oxygen_after}</span>
                                    :
                                    <span> {dailyReport.oxygen_after} </span>
                                  }
                                </Box>
                              </Box>
                          </Box>
                        </Grid>
                        
                        <Grid item xs={3}>
                          <Box>
                              <Box css={{ color: "#8F919C" }} fontSize={12} mb={0.5}>
                                  SPO<sub>2</sub>
                              </Box>
                              <Box css={{ color: "#8F919C" }} fontSize={8} mb={0.5}>
                                  (after)
                              </Box>
                              <Box fontSize={18}>
                                <Box fontWeight={600}>
                                  { dailyReport.oxygen_before <= 94 ?
                                    <span style={{color: 'red'}}> {dailyReport.oxygen_before}</span>
                                    :
                                    <span> {dailyReport.oxygen_before} </span>
                                  }
                                  
                                </Box>
                              </Box>
                          </Box>
                        </Grid>
                        
                        <Grid item xs={3}>
                          <DeviceField
                            label="ปัสสาวะ"
                          >
                            <Box
                              fontWeight={600} mt={2.6}
                            >{dailyReport.urination_among}</Box>
                          </DeviceField>
                        </Grid>
                        
                        <Grid item xs={3}>
                          <DeviceField
                            label="อุจจาระ"
                          >
                            <Box
                              fontWeight={600} mt={2.6}
                            >{dailyReport.defecation_among}</Box>
                          </DeviceField>
                        </Grid>
                      </Box>
                  </Grid>

                  <Grid item xs={12} md={1} style={{marginRight :"2%"}}>
                          <DeviceField
                            label="อาการผิดปกติ"
                          >
                            <Box
                              mt={2.6}
                            >
                              {
                                dailyReport.symptoms_one ?
                                <Grid item xs={12}>
                                  <Box
                                    fontSize={12}
                                    css={{ color: "#ff0000" }}
                                    mt={{ xs: 2, md: 0 }}
                                  >
                                  {dailyReport.symptoms_one + ", "+ dailyReport.symptoms_two}
                                  </Box>
                                </Grid>
                                :
                                <></>
                              }
                            
                            </Box>
                          </DeviceField>
                  </Grid>

                  <Grid item xs={12} md={1}>
                          <DeviceField
                            label="Latest update:"
                          >
                            <Box
                            fontSize={12}
                            css={{ color: "#4B4C56" }}
                              mt={2.6}
                            >
                                {dateStrAPI(dailyReport.updated_at)} : <br/>
                                {timeStr(dailyReport.updated_at)}
                            
                            </Box>
                          </DeviceField>
                  </Grid>
                </Grid>
                </Box></>
                );
              })}
          </Box> </>: <></>
      }
      
      <Box className="flex justify-between items-center" mt={8} pb={2}>
        <Grid container>
          <Grid item md={8} xs={12}>
            <Box fontSize="h5.fontSize" fontWeight={500}>
                Mental Health Report
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box css={{ borderRadius: 5, backgroundColor: "#fff" }}>
        {
          result_metalReport?.length > 0 ?
          <>
            <TableContainer style={{maxHeight: '300'}}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" style={{ minWidth: '200px' }}>Latest Update</TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>No. of Assessment Time</TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>ท่านมีอาการเหล่านี้หรือไม่ </TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>เบื่อ ทําอะไรก็ไม่เพลิดเพลิน <br/> ไม่สนใจอยากทําอะไร </TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>ไม่สบายใจ ซึมเศร้า ท้อแท้ </TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>หลับยากหรือหลับๆ ตื่นๆ <br/> หรือหลับมากเกินไป</TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>เหนื่อยง่ายหรือไม่ค่อยมีแรง </TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>เบื่ออาหารหรือกินมากเกินไป </TableCell>
                        <TableCell align="center" style={{ minWidth: '350px' }}>รู้สึกไม่ดีกับตัวเอง คิดว่าตัวเองล้มเหลว <br/>หรือเป็นคนทําให้ตัวเองหรือครอบครัวผิดหวัง</TableCell>
                        <TableCell align="center" style={{ minWidth: '350px' }}> สมาธิไม่ดีเวลาทําอะไร เช่น ดูโทรทัศน์ <br/> ฟังวิทยุ หรือทํางานที่ต้องใช้ความตั้งใจ </TableCell>
                        <TableCell align="center" style={{ minWidth: '350px' }}> พูดหรือทําอะไรช้าจนคนอื่นสังเกตเห็น <br/> หรือกระสับกระส่ายไม่สามารถอยู่นิ่งได้เหมือนเคย </TableCell>
                        <TableCell align="center" style={{ minWidth: '350px' }}> คิดทําร้ายตนเอง <br/> หรือคิดว่าถ้าตายไปเสียคงจะดี </TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}> Total PHQ9  </TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}> ผลลัพธ์  </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {(result_metalReport || []).map((mental, key) => (
                      
                      <TableRow key={key}>

                        {
                            mental.updated_at ? 
                            <TableCell align="center">
                              {dateStrAPI(mental.updated_at)} : {timeStr(mental.updated_at)}
                            </TableCell>
                            :
                            <TableCell align="center">N/A</TableCell>
                          }

                          {
                            mental.assesment_number ? 
                            <TableCell align="center">
                              {
                                mental.assesment_number === 1 ? "1st Time" :
                                mental.assesment_number === 2 ? "2st Time" :
                                mental.assesment_number === 3 ? "3rd Time" :
                                "N/A"
                              }
                            </TableCell>
                            :
                            <TableCell align="center">N/A</TableCell>
                          }

                          {
                            mental.symptoms_mental ? 
                            <TableCell align="center">{mental.symptoms_mental }</TableCell>
                            :
                            <TableCell align="center">N/A</TableCell>
                          }

                          {
                            mental.mental_one? 
                            <TableCell align="center">{ mental.mental_one} </TableCell>
                            :
                            <TableCell align="center">0</TableCell>
                          }

                          {
                            mental.mental_two? 
                            <TableCell align="center">{ mental.mental_two} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span>0</span>
                            </TableCell>
                          }
                          
                          {
                            mental.mental_three? 
                            <TableCell align="center">{ mental.mental_three} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> 0 </span>
                            </TableCell>
                          }

                          {
                            mental.mental_four? 
                            <TableCell align="center">{ mental.mental_four} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> 0 </span>
                            </TableCell>
                          }

                          {
                            mental.mental_five? 
                            <TableCell align="center">{ mental.mental_five} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> 0 </span>
                            </TableCell>
                          }

                          {
                            mental.mental_six? 
                            <TableCell align="center">{ mental.mental_six} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> 0 </span>
                            </TableCell>
                          }

                          {
                            mental.mental_seven? 
                            <TableCell align="center">{ mental.mental_seven} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> 0 </span>
                            </TableCell>
                          }

                          {
                            mental.mental_eight? 
                            <TableCell align="center">{ mental.mental_eight} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> 0 </span>
                            </TableCell>
                          }

                          {
                            mental.mental_nine? 
                            <TableCell align="center">{ mental.mental_nine} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> 0 </span>
                            </TableCell>
                          }

                          {/* {
                            mental.mental_ten? 
                            <TableCell align="center">{ mental.mental_ten} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> 0 </span>
                            </TableCell>
                          } */}

                          {
                            mental.total? 
                            <TableCell align="center">{ mental.total} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> 0 </span>
                            </TableCell>
                          }

                          {
                            mental.result? 
                            <TableCell align="center">{ mental.result} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> N/A </span>
                            </TableCell>
                          }
                          
                          
                          
                      </TableRow>
                    ))}
                    
                    </TableBody>
                  </Table>
                </TableContainer>
          </> 
          
          :
          <>
             <p style={{color:'grey', textAlign: 'center', paddingTop:'3%'}}> There is no data. </p>
          </>
        }      
        <br/>          
      </Box>
        </>
      }
      


      {/* Health log */}
      <Box
        className="justify-between items-center"
        mt={8}
        pb={2}
        display={{ xs: "block", md: "flex" }}
      >
        <Box fontSize="h5.fontSize" fontWeight={500} mb={{ xs: 2, md: 0 }}>
          Health Log
        </Box>
        
        <Grid item md={6} xs={12}>
          <Box
            display={{ sm: "none", xs: "none", md: "block" }}
            css={{ minWidth: "fit-content" }}
          >
            
          {
            localStorage.getItem("projectKey") === '6mkx2x8oag4u63nfa8dhc287w7lknstsvcjk1xbb' ?
            <Button startIcon={<SaveAltIcon />} 
              color="primary" size="medium" variant="contained" style={{float: 'right'}}
              onClick={()=> {
                // history.push("/user/" + userId + "/diagonoses/" + result_getUserProfile?.onboard?.id);
                // localStorage.setItem("user_id", user_id);
              }}
              >
                  Export
            </Button>
             :
             <></>
          }
  

          </Box>
          
        </Grid>
        
        <Grid container md={4} xs={12}>
          <Grid item xs={5} css={{ padding: "0 5px" }}>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <KeyboardDatePicker
                style={{
                  margin: 0,
                  width: "100%",
                  backgroundColor: "#fff",
                }}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="from"
                inputVariant="outlined"
                label="Date Start"
                autoOk={true}
                value={startDate}
                onChange={(date) => {
                  setStartDate(date ? new Date(date.toString()) : null);
                  // onSearch();
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={5} css={{ padding: "0 5px" }}>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <KeyboardDatePicker
                style={{ margin: 0, width: "100%", backgroundColor: "#fff" }}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="to"
                label="Date End"
                inputVariant="outlined"
                value={endDate}
                autoOk={true}
                onChange={(date) => {
                  setEndDate(date ? new Date(date.toString()) : null);
                  // onSearch();
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={2} css={{ padding: "0 5px" }}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <IconButton onClick={onSearch} color="primary">
                <SearchIcon fontSize="large" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item md={8} xs={12}>
          <Box
            paddingRight={{ xs: 0, md: 3 }}
            css={{ backgroundColor: "transparent" }}
          >
            <Grid
              item
              xs={12}
              style={{ height: 380, overflow: "hidden", marginBottom: 15 }}
            >
              <BodyTempGraph data={parseData} />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ height: 380, overflow: "hidden", marginBottom: 15 }}
            >
              <HeartRateGraph data={parseData} />
            </Grid>
            {/* {
              localStorage.getItem("projectType") === "isolation" ?
              <></>: */}
              <Grid item xs={12} style={{ height: 380, overflow: "hidden" , marginBottom: 15}}>
                <BloodPressureGraph data={parseData} />
              </Grid>
            {/* } */}
            
            <Grid item xs={12} style={{ height: 380, overflow: "hidden" , marginBottom: 15 }}>
              <OximeterGraph data={parseData} />
            </Grid>

            {
              localStorage.getItem("projectKey") === 'egoey4j6avjc5rv3jkxktthj6ovqkubmdhg2hrq6' || localStorage.getItem("projectKey") === '7tr84n2xhsvepht7t99wqc5k4db5yk5kpitekq3d' ?
              <Grid item xs={12} style={{ height: 380, overflow: "hidden" , marginBottom: 15 }}>
                <RespirationGraph data={parseData}/>
              </Grid>
                :
                <></>
            }
          </Box>
        </Grid>
        <Grid item md={4} xs={12}>
          <StickyContainer
            style={{
              width: "100%",
              flex: 1,
              minHeight: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Sticky topOffset={-80}>
              {({ style, isSticky }: any) => (
                <div style={style}>
                  <div
                    style={{
                      position: "relative",
                      height: "calc(100vh - 4rem)",
                      top: !isSticky ? 0 : "4rem",
                    }}
                  >
                    <DeviceHistoryDatagrid data={parseData} />
                  </div>
                </div>
              )}
            </Sticky>
          </StickyContainer>
        </Grid>
      </Grid>
      
      {/* <Box mt={2}>
        <Box
          style={{ height: 400, position: "relative" }}
          css={{ borderRadius: 5, backgroundColor: "#fff" }}
        >
          {result_userRead_raw && (
            <Map
              locations={(result_userRead_raw.locations || [])
                .filter(({ latitude, longitude }) => {
                  return (
                    latitude > 0 &&
                    longitude > 0 &&
                    Math.abs(latitude) <= 90 &&
                    Math.abs(longitude) <= 180
                  );
                })
                .map(({ latitude, longitude }, i) => {
                  return {
                    lat: latitude,
                    lng: longitude,
                    active: true,
                    name: (i + 1).toString(),
                    dot: true,
                  };
                })}
            ></Map>
          )}
        </Box>
      </Box> */}
    </Container>
    </>
  );
});
export const UserDetailMobile = UserDetail;
