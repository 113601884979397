import React, { Fragment, useEffect, useState,useCallback,useRef } from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
  Box,
  Button,
  CardActionArea,
  Container,
  Grid,
  Paper,
  Typography,
  LinearProgress,
  Divider,
  Tab,
  Modal,
  FormControlLabel ,
  RadioGroup ,
  CircularProgress,
  FormControl,
  TextareaAutosize
} from "@material-ui/core";
import { webStore } from "../../stores/webStore";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
// import { Table } from 'react-bootstrap';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
// import TableRow from '@material-ui/core/TableRow';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
// import Chart from "react-apexcharts"; 
import { ApexOptions } from "apexcharts";
import { Line } from "react-chartjs-2";
// import Chart from 'react-chartjs-2';
// import ChartDataLabels from "chartjs-plugin-datalabels";
import { useParams } from "react-router-dom";
import {
  GetVitalSignData,GetDiagnosesData,GetDiagnosesExportData, UpdateDiagnosesExportData,
  GetFirstPageData, Image_atk_Data, Image_IdCard_Data,UpdateDischargeInfo,UpdateVitalSigns
} from "../../services/api/user/GetVitalSignsData";

import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import { calculateAge } from './UserInfoCardSI';

// Chart.register(ChartDataLabels);

import {  makeStyles, useTheme, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { RemoveShoppingCartOutlined } from "@material-ui/icons";
import Checkbox from '@material-ui/core/Checkbox';
import Radio from "@material-ui/core/Radio";
import ContentEditable from 'react-contenteditable';
import TextField from '@material-ui/core/TextField';
import { ExportUpdateData } from "../../types/ExportUpdateData";
import { FirstReceipt, UpdateDischargeData, VitalSignData } from "../../types/first_receipt";
import { Input } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from "material-ui-thai-datepickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import LuxonUtils from "@date-io/luxon";
import { referenceEnhancer } from "mobx/dist/internal";
import printJS from "print-js";
import { useSnackbar } from "notistack";
import { isNull } from "lodash";
import CloseIcon from "@material-ui/icons/Close";
import {
  UpdateQuarantineStatus,
  UpdatePhuketUserQuarantineStatus,
  GetStatusForPhuketUser
} from "../../services/api/user/useReadUser";

import moment from 'moment';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { dateStr,dateStrAPI,dateStrAPIFormat, dateStrFormatBuddhist } from "../../utils";
import { AnyRecord } from "dns";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
// import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import takuapaLogo from '../../components/assets/takuapaLogo.png';
import hospitalLogo from '../../components/assets/hospitalLogo.png';
import signature from '../../components/assets/signature.png';
import signature_prison from '../../components/assets/signature_prison.png';
import signature_prison_nurse from '../../components/assets/signature_prison_nurse.png';
import { DischargeForm } from "./DischargeForm";
import EditIcon from '@material-ui/icons/Edit';

const theme = createMuiTheme({
  typography: {
    "fontFamily": "Poppins"
   }
});


const onCapture = () =>{
  const input = document.getElementById('savePNG') as HTMLElement;
  html2canvas(input)
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'pt', 'a4');
      pdf.addImage(imgData, 'PNG', 1, 3, 207, 250, undefined,'FAST');
      pdf.save("Pateint_Care_Record.pdf");
    })
  ;
}

export const ConfirmStatusModal = observer(
  ({
    isConfirm,
    onClick,
    setIsConfirm,
    status,
    buttonName = "CONFIRM",
  }: {
    isConfirm: boolean;
    setIsConfirm: any;
    onClick?: any;
    status: any;
    buttonName?: string;
  }) => {

    return (
      <Modal
        open={isConfirm}
        onClose={() => {}}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          css={{ backgroundColor: "#fff", width: 350, borderRadius: 5 }}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Box css={{ color: "#4B4C56" }} fontSize={14}>

            </Box>
            <Box
              onClick={() => {
                setIsConfirm(false);
              }}
            >
              <CloseIcon css={{ cursor: "pointer" }} />
            </Box>
          </Box>
          <Box
            mt ={2}
          >
            <p style={{ color:'grey', textAlign: 'center'}}>Status will be changed to </p>
            <p style={{ color:'#3f51b5', textAlign: 'center'}}> {status} .</p>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            <Button
              onClick={() => {
                setIsConfirm(false);
              }}
              size="medium"
              variant="outlined"
            >
              CANCEL
            </Button>

            <Button
              onClick={onClick}
              color="primary"
              size="medium"
              variant="outlined"
              style={{marginLeft: '3%'}}
            >
              {buttonName}
            </Button> 
          </Box>
          <br/>
        </Box>
      </Modal>
    );
  }
);

export const HideAtkPhotoModal = observer(
  ({
    isHideAtk,
    onClick,
    setIsHideAtk,
    buttonName = "CONFIRM",
  }: {
    isHideAtk: boolean;
    setIsHideAtk: any;
    onClick?: any;
    buttonName?: string;
  }) => {

    return (
      <Modal
        open={isHideAtk}
        onClose={() => {}}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          css={{ backgroundColor: "#fff", width: 350, borderRadius: 5 }}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Box css={{ color: "#4B4C56" }} fontSize={14}>

            </Box>
            <Box
              onClick={() => {
                setIsHideAtk(false);
              }}
            >
              <CloseIcon css={{ cursor: "pointer" }} />
            </Box>
          </Box>
          <Box
            mt ={2}
          >
            <p style={{ color:'grey', textAlign: 'center'}}>Are you sure to hide the image?</p>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            <Button
              onClick={() => {
                setIsHideAtk(false);
              }}
              size="medium"
              variant="outlined"
            >
              CANCEL
            </Button>

            <Button
              onClick={onClick}
              color="primary"
              size="medium"
              variant="outlined"
              style={{marginLeft: '3%'}}
            >
              {buttonName}
            </Button> 
          </Box>
          <br/>
        </Box>
      </Modal>
    );
  }
);

export const HideIdPhotoModal = observer(
  ({
    isHideIdPhoto,
    onClick,
    setIsHideIdPhoto,
    buttonName = "CONFIRM",
  }: {
    isHideIdPhoto: boolean;
    setIsHideIdPhoto: any;
    onClick?: any;
    buttonName?: string;
  }) => {

    return (
      <Modal
        open={isHideIdPhoto}
        onClose={() => {}}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          css={{ backgroundColor: "#fff", width: 350, borderRadius: 5 }}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Box css={{ color: "#4B4C56" }} fontSize={14}>

            </Box>
            <Box
              onClick={() => {
                setIsHideIdPhoto(false);
              }}
            >
              <CloseIcon css={{ cursor: "pointer" }} />
            </Box>
          </Box>
          <Box
            mt ={2}
          >
            <p style={{ color:'grey', textAlign: 'center'}}>Are you sure to hide the image?</p>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            <Button
              onClick={() => {
                setIsHideIdPhoto(false);
              }}
              size="medium"
              variant="outlined"
            >
              CANCEL
            </Button>

            <Button
              onClick={onClick}
              color="primary"
              size="medium"
              variant="outlined"
              style={{marginLeft: '3%'}}
            >
              {buttonName}
            </Button> 
          </Box>
          <br/>
        </Box>
      </Modal>
    );
  }
);


export const CropImageModal = observer(
  ({
    isAtkCrop,
    onClick,
    setIsAtkCrop,
    imageData,
    set_image_atk_data,
    setCropData, 
    cropData,
    buttonName = "CLOSE",
  }: {
    isAtkCrop: boolean;
    setIsAtkCrop: any;
    onClick?: any;
    imageData: any;
    set_image_atk_data: any;
    setCropData: any;
    cropData: any;
    buttonName?: string;
  }) => {

    const [crop, setCrop] = useState<Point>({ x: 100, y: 100 });
    const [zoom, setZoom] = useState(1);
    
    const [image, setImage] = useState(imageData);
    const [cropper, setCropper] = useState<any>();

    const onChange = (e: any) => {
      e.preventDefault();
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as any);
      };
      reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
      if (typeof cropper !== "undefined") {
        setIsAtkCrop(false);
        setCropData(cropper.getCroppedCanvas().toDataURL());
      }
    };

    return (
      <Modal
        open={isAtkCrop}
        onClose={() => {}}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          css={{ backgroundColor: "#fff", width: 600, borderRadius: 5, height: "75%" }}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Box css={{ color: "#4B4C56" }} fontSize={14}>

            </Box>
            <Box
              onClick={() => {
                setIsAtkCrop(false);
              }}
            >
              <CloseIcon css={{ cursor: "pointer" }} />
            </Box>
        </Box>
          <Box
          >
            <br/>
            <br/>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Cropper
                      style={{ height: '250px', width: '100%' }}
                      zoomTo={0.1}
                      initialAspectRatio={1}
                      preview=".img-preview"
                      src={URL.createObjectURL(image)}
                      viewMode={1}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      background={false}
                      responsive={true}
                      autoCropArea={1}
                      checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                      onInitialized={(instance) => {
                        setCropper(instance);
                      }}
                      guides={true}
                      rotatable={true}
                    />
              </Grid>
              <Grid item xs={6}>
                {
                      cropData !== "#" ?
                      <Box style={{display: 'flex', justifyContent: 'center',}}> 
                        <img style={{ height: 250 }} src={cropData} alt="cropped" />
                      </Box>
                      :
                        <></>
                    }
              </Grid>
            </Grid>
            <br/>
              
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            <Button variant="contained" color="primary" style={{marginRight: '10px', float: 'right'}}
                  onClick={() => {cropper.rotate(-90);}}
              > Roatate Image </Button>
            <Button variant="contained" color="primary" style={{marginRight: '10px', float: 'right'}}
                  onClick={getCropData}
              > Crop Image </Button>

            {/* <Button
              onClick={() => {
                setIsAtkCrop(false);
              }}
              color="primary"
              size="medium"
              variant="outlined"
              style={{marginLeft: '3%'}}
            >
              {buttonName}
            </Button>  */}
          </Box>
          <br/>
        </Box>
      </Modal>
    );
  }
);

export const CropIdCardImageModal = observer(
  ({
    isCropIdCard,
    onClick,
    setIsCropIdCard,
    imageData,
    setCropIdCardData, 
    cropData,
    buttonName = "CLOSE",
  }: {
    isCropIdCard: boolean;
    setIsCropIdCard: any;
    onClick?: any;
    imageData: any;
    setCropIdCardData: any;
    cropData: any;
    buttonName?: string;
  }) => {    
    const [image, setImage] = useState(imageData);
    const [cropper, setCropper] = useState<any>();

    const onChange = (e: any) => {
      e.preventDefault();
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as any);
      };
      reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
      if (typeof cropper !== "undefined") {
        setIsCropIdCard(false);
        setCropIdCardData(cropper.getCroppedCanvas().toDataURL());
      }
    };

    return (
      <Modal
        open={isCropIdCard}
        onClose={() => {}}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          css={{ backgroundColor: "#fff", width: 600, borderRadius: 5, height: "75%"}}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Box css={{ color: "#4B4C56" }} fontSize={14}>

            </Box>
            <Box
              onClick={() => {
                setIsCropIdCard(false);
              }}
            >
              <CloseIcon css={{ cursor: "pointer" }} />
            </Box>
        </Box>
          <Box
          >
            <br/>
            <br/>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Cropper
                      style={{ height: '250px', width: '100%' }}
                      zoomTo={0.1}
                      initialAspectRatio={1}
                      preview=".img-preview"
                      src={URL.createObjectURL(image)}
                      viewMode={1}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      background={false}
                      responsive={true}
                      autoCropArea={1}
                      checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                      onInitialized={(instance) => {
                        setCropper(instance);
                      }}
                      guides={true}
                      rotatable={true}
                    />
              </Grid>
              <Grid item xs={6}>
                {
                      cropData !== "#" ?
                      <Box style={{display: 'flex', justifyContent: 'center',}}> 
                        <img style={{ height: 250 }} src={cropData} alt="cropped" />
                      </Box>
                      :
                        <></>
                    }
              </Grid>
            </Grid>
            <br/>
              
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
             <Button variant="contained" color="primary" style={{marginRight: '10px', float: 'right'}}
                  onClick={() => {cropper.rotate(-90);}}
              > Roatate Image </Button>

             <Button variant="contained" color="primary" style={{marginRight: '10px', float: 'right'}}
                  onClick={getCropData}
              > Crop Image </Button>

            {/* <Button
              onClick={() => {
                setIsCropIdCard(false);
              }}
              color="primary"
              size="medium"
              variant="outlined"
              style={{marginLeft: '3%'}}
            >
              {buttonName}
            </Button>  */}
          </Box>
          <br/>
        </Box>
      </Modal>
    );
  }
);

export const onCapturePNGEng = () =>{
  htmlToImage.toPng(document.getElementById("savePNGEng") as HTMLElement)
    .then(function (dataUrl) {
    // saveAs(dataUrl,  'Pateint_Care_Record.png');
        var link = document.createElement('a');
        link.download = 'my-image-name.jpeg';
        const pdf = new jsPDF();
        const imgProps= pdf.getImageProperties(dataUrl);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        console.log("pdfHeight:", pdfHeight, imgProps.height);
        pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight, undefined,'FAST');
        pdf.autoPrint({variant: 'non-conform'});
        pdf.save("Pateint_Care_Record.pdf");   
    });
// }

}

export const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%'
  },
  container: {
    maxHeight: 1000,
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderColor: 'grey',
    borderStyle: 'solid',
  },
});

const EditSymptomsModal = observer(
  ({
    isEdit,
    onClick,
    setIsEdit,
    editData,
    setEditData,
    buttonName = "Update",
    setChangeState
  }: {
    isEdit: boolean;
    setIsEdit: any;
    onClick?: any;
    editData : any,
    setEditData : any,
    buttonName?: string;
    setChangeState: any;
  }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [ date, setDate ] = useState("");
    const [ oxygen, setOxygen ] = useState<any>();
    const [ bodyTemp, setBodyTemp ] = useState<any>();
    const [ symptomOne, setSymptomOne ] = useState("");
    const [ symptomTwo, setSypmtomTwo ] = useState("");
    const [ recorder, setRecorder ] = useState("");
    const [ heartRate, setHeartRate ] = useState<any>();
    const { result_updateVitalSigns, fire_updateVitalSigns, loading_updateVitalSigns } = UpdateVitalSigns();

    const [updateData, setUpdateData] = React.useState<VitalSignData>({
      id: editData?.id,
      assesment_period: editData?.assessmentPeriod,
      blood_diastolic : editData?.blood_diastolic,
      blood_sugar: editData?.blood_sugar,
      blood_systolic: editData?.blood_systolic,
      breath: editData?.breath,
      defecation_among : editData?.defecation_among,
      heart_rate: heartRate,
      is_abnormal: editData?.is_abnormal,
      oxygen_after: oxygen,
      oxygen_before: editData?.oxygen_before,
      recorder: recorder,
      symptom_case: editData?.symptoms_case,
      symptoms_one: symptomOne,
      symptoms_two: symptomTwo,
      tempurature: bodyTemp,
      urination_among: editData?.urination_among
    });

    useEffect(() =>{
       console.log("edit data:", editData);
       if(editData){
         setDate(editData?.date);
         setOxygen(editData?.oxygen_before);
         setBodyTemp(editData?.temprature?.toFixed(1));
         setSymptomOne(editData?.symptoms_one);
         setSypmtomTwo(editData?.symptoms_two);
         setRecorder(editData?.recorder);
         setHeartRate(editData?.heart_rate);

         setUpdateData({
           ...updateData, 
            id: editData?.id,
            assesment_period: editData?.assessmentPeriod,
            blood_diastolic : editData?.blood_diastolic,
            blood_sugar: editData?.blood_sugar,
            blood_systolic: editData?.blood_systolic,
            breath: editData?.breath,
            defecation_among : editData?.defecation_among,
            heart_rate: editData?.heart_rate,
            is_abnormal: editData?.is_abnormal,
            oxygen_after: editData?.oxygen_before,
            oxygen_before: editData?.oxygen_after,
            recorder: editData?.recorder,
            symptom_case: editData?.symptoms_case,
            symptoms_one: editData?.symptoms_one,
            symptoms_two: editData?.symptoms_two,
            tempurature: editData?.temprature?.toFixed(1),
            urination_among: editData?.urination_among
         })
       }
    }, [editData]);

    const setUpdateVal = (k: any, v: any) => {
      let newV: any = { ...updateData };
      newV[k] = v;
      setUpdateData(newV);
    };

    return (
      <Modal
        open={isEdit}
        onClose={() => {}}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          css={{ backgroundColor: "#fff", width: 600, borderRadius: 20, border: 'solid 2px #3f51b5' }}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
            mt={1}
          >
            <Box css={{ color: "#4B4C56" }}>
              <h3>Edit Data</h3> 
            </Box>
            <Box
              onClick={() => {
                setIsEdit(false);
              }}
            >
              <CloseIcon css={{ cursor: "pointer" }} />
            </Box>
          </Box>
          <Box>
            <br/>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} style={{paddingTop:'8px', paddingLeft:'5px', paddingRight : '10px'}}>
                <label style={{fontFamily: 'Poppins', fontWeight: 400}}>O<sub>2</sub> sat%</label>
                <br/>
                  <TextField
                      id="margin-none"
                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                      type="number"
                      fullWidth
                      value={oxygen} 
                      name="oxygen_after"
                      onChange={(event) =>{
                         setOxygen(event.target.value);
                         setUpdateVal(event.target.name, event.target.value);
                      }
                    } 
                  />
              </Grid>
              <Grid item xs={12} md={6} style={{paddingTop:'8px', paddingLeft:'5px', paddingRight : '10px'}}>
                <label style={{fontFamily: 'Poppins', fontWeight: 400}}>Heart Rate(bpm)</label>
                <br/>
                  <TextField
                      id="margin-none"
                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                      type="number"
                      fullWidth
                      value={heartRate} 
                      name="heart_rate"
                      onChange={(event) =>{
                        setHeartRate(event.target.value);
                        setUpdateVal(event.target.name, event.target.value);
                      }
                    } 
                  />
              </Grid>
            </Grid>
            <br/>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6} style={{paddingTop:'8px', paddingLeft:'5px', paddingRight : '10px'}}>
                <label style={{fontFamily: 'Poppins', fontWeight: 400}}>Body Temperature( <sup>0</sup>C) </label>
                <br/>
                  <TextField
                      id="margin-none"
                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                      type="number"
                      fullWidth
                      value={bodyTemp} 
                      name="tempurature"
                      onChange={(event) =>{
                        setBodyTemp(event.target.value);
                        setUpdateVal(event.target.name, event.target.value);
                      }
                    } 
                  />
              </Grid>
              <Grid item xs={12} md={6} style={{paddingTop:'8px', paddingLeft:'5px', paddingRight : '10px'}}>
                <label style={{fontFamily: 'Poppins', fontWeight: 400}}>ผู้ประเมิน</label>
                <br/>
                  <TextField
                      id="margin-none"
                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                      type="text"
                      fullWidth
                      value={recorder} 
                      name="recorder"
                      onChange={(event) =>{
                        setRecorder(event.target.value);
                        setUpdateVal(event.target.name, event.target.value);
                      }
                    } 
                  />
              </Grid>
            </Grid>
            <br/>
            
            <Grid container spacing={3}>
              <Grid item xs={12} style={{paddingTop:'8px', paddingLeft:'5px', paddingRight : '10px'}}>
                <label style={{fontFamily: 'Poppins', fontWeight: 400}}>Symptom One </label>
                <br/>
                  <TextField
                      id="margin-none"
                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                      type="text"
                      fullWidth
                      value={symptomOne} 
                      name="symptoms_one"
                      onChange={(event) =>{
                        setSymptomOne(event.target.value);
                        setUpdateVal(event.target.name, event.target.value);
                      }
                    } 
                  />
                  {/* <TextareaAutosize name="symptoms_one"
                    rowsMin={3} value={symptomOne} onChange={(event) =>{
                      setSymptomOne(event.target.value);
                      setUpdateVal(event.target.name, event.target.value);
                    }}
                    /> */}
              </Grid>
              <Grid item xs={12} style={{paddingTop:'8px', paddingLeft:'5px', paddingRight : '10px'}}>
                <label style={{fontFamily: 'Poppins', fontWeight: 400}}>Symptoms Two</label>
                <br/>
                  <TextField
                      id="margin-none"
                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                      type="text"
                      fullWidth
                      value={symptomTwo} 
                      name="symptoms_two"
                      onChange={(event) =>{
                        setSypmtomTwo(event.target.value);
                        setUpdateVal(event.target.name, event.target.value);
                      }
                    } 
                  />
              </Grid>
            </Grid>
            <br/>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            <Button
              onClick={async () => {
                try {
                  fire_updateVitalSigns(updateData)
                    .then(() => {
                      enqueueSnackbar("Success update data", {
                        variant: "success",
                        autoHideDuration: 3000,
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "center",
                        },
                      });
                      setIsEdit(false);
                      setChangeState(false);
                    })
                    .catch((ex) => {
                      enqueueSnackbar(
                        "Error" +
                          (ex ? " : " + ex.toString() : ""),
                        {
                          variant: "error",
                          autoHideDuration: 3000,
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                          },
                        }
                      );
                      setIsEdit(false);
                      setChangeState(false);
                    });
                } catch (ex) {}}
              }
              color="primary"
              size="medium"
              variant="contained"
            >
              {buttonName}
            </Button>
          </Box>
          <br/>
        </Box>
      </Modal>
    );
  }
);

export const DiagonosesReport = () =>{
    const contentEditable = React.createRef<HTMLElement>();
    const { userId, onboardId }: any = useParams();
    const [ changeState, setChangeState ] = useState(false);
    const { result_vitalSign, dateArray , reportData, loading_vitalSign, error_vitalSign } = GetDiagnosesData(userId, changeState);
    const { result_exportData, loading_exportData } = GetDiagnosesExportData(userId);
    const { result_updateData, fire_updateData, loading_updateData, error_updateData } = UpdateDiagnosesExportData(userId);
    const { result_updateDischargeData, fire_updateDischargeData, loading_updateDischargeData, error_updateDischargeData } = UpdateDischargeInfo(userId);
    const { result_data, error_data,loading_data } = GetFirstPageData(userId); 
    const { result_image_atk_data, error_image_atk_data,loading_image_atk_data } = Image_atk_Data(userId);
    const { result_image_IdCard_data, error_image_IdCard_data,loading_image_IdCard_data } = Image_IdCard_Data(userId);
    const [ show_image_atk_data, set_show_image_atk_data] = React.useState(true);
    const [ show_image_IdCard_data, set_show_image_IdCard_data] = React.useState(true);

    const [ image_atk, set_image_atk ] = useState<any>();
    const [ image_atk_data, set_image_atk_data ] = React.useState("");
    const [cropData, setCropData] = useState("#");

    const [ cropIdCardData, setCropIdCardData ] = useState("#");
    const [ isCropIdCard, setIsCropIdCard ] = useState(false);

    const [ isHideAtk, setIsHideAtk ] = useState(false);
    const [ isHideIdPhoto, setIsHideIdPhoto ] = useState(false);

    const [ showButton, setShowButton ] = useState(true);

    const [isConfirm, setIsConfirm] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { fire_quarantineStatus_update, loading_quarantineStatus_update } = UpdatePhuketUserQuarantineStatus(onboardId);
    
    const [ isEdit, setIsEdit ] = useState(false);
    const [ editData, setEditData ] = useState<any>({});
    // console.log("result_export data:", result_exportData);
    const classes = useStyles();
    let historylog = [1,2,3,4,5,6,7,8,9,10];

    const [ depression, setDepression ] = React.useState(false);
    const [ anxiety, setAnxiety ] = React.useState(false);
    const [ is_stigma, setIsStigma ] = React.useState(false);
    const [ is_other, setIsOther ] = React.useState(false);
    const [ other, setOther ] = React.useState("");

    const [ is_patient_plan, setPatientPlan ] = React.useState(false);
    const [ is_cheer_up, setCheerUp ] = React.useState(false);
    const [ is_empty, setIsEmpty ] = React.useState(false);
    const [empty, setEmpty] = React.useState("");
    const [psychiatrist_need, setPsychiatristNeed] = React.useState(false);
    const[ psychotherapist_need, setPsychotherapistNeed] = React.useState(false);

    const [ is_andrographis_paniculata, setAndrographis ] = React.useState(false);
    const [ andrographis_paniculata_start, set_andrographis_paniculata_start] = React.useState<Date | null>(null);
    const [ andrographis_paniculata_end, set_andrographis_paniculata_end ] = React.useState<Date | null>(null);
    const [ is_favipiravir, set_is_favipiravir ] = React.useState(false);
    const [ favipiravir_start, set_favipiravir_start] = React.useState<Date | null>(null);
    const [ favipiravir_end, set_favipiravir_end ] = React.useState<Date | null>(null);
    const [ is_empty_medicine, set_is_empty_medicine ] = React.useState(false);
    const [ empty_medicine, set_empty_medicine ] = React.useState("");

    const [ is_tired, set_is_tired ] = React.useState(false);
    const [ is_cough, set_is_cough ] = React.useState(false);
    const [ is_fever, set_is_fever ] = React.useState(false);
    const [ is_chest_pain, set_is_chest_pain ] = React.useState(false);
    const [ is_other_complication, set_is_other_complication ] = React.useState(false);
    const [ other_complication, set_other_complication ] = React.useState("");

    const [ is_o2_therapy, set_is_o2_therapy ] = React.useState(false);
    const [ o2_therapy, set_o2_therapy ] = React.useState ('');
    const [ is_prone, set_is_prone ] = React.useState(false);
    const [ is_medication, set_is_medication ] = React.useState(false);
    const [ medication, set_medication ] = React.useState('');
    const [ is_refer, set_is_refer ] = React.useState(false);
    const [ refer, set_refer ] = React.useState('');
    const [ is_other_treament, set_is_other_treament ] = React.useState(false);
    const [ other_treatment, set_other_treament ] = React.useState('');

    const [ body_temp, set_body_temp ]  = React.useState<number | 0>();
    const [ pulse, set_pulse ] = React.useState<number | 0>();
    const [ respiration_rate, set_respiration_rate ] = React.useState<number | 0>();
    const [ symptoms, set_symptoms ] = React.useState("");
    const [ is_full_day, set_is_full_day ] = React.useState(false);
    const [ is_refer_summary, set_is_refer_summary ] = React.useState(false);
    const [ refer_date, set_refer_date ] = React.useState<Date | null>(null);
    const [ is_hospital, set_is_hospital ] = React.useState(false);
    const [ hospital, set_hospital ] = React.useState('');
    const [ is_back_home, set_is_back_home ] = React.useState(false);
    const [ home, set_home] = React.useState('');
    const [ is_other_summary, set_is_other_summary ] = React.useState(false);
    const [ other_summary, set_other_summary ] = React.useState('');

    const [ is_home_infect, set_is_home_infect ] = React.useState(false);
    const [ home_infect, set_home_infect ] = React.useState<number | 0>();
    const [ is_home_risk, set_is_home_risk ] = React.useState(false);
    const [ home_risk, set_home_risk ] = React.useState<number | 0>();
    const [ is_pui, set_is_pui] = React.useState(false);
    const [ pui, set_pui] = React.useState<number | 0>();
    const [ is_home_treatment, set_is_home_treatment ] = React.useState(false);
    const [ home_treatment, set_home_treatment ] = React.useState<number | 0>();
    const [ is_special, set_is_special ] = React.useState(false);
    const [ is_poor, set_is_poor ] = React.useState(false);

    const [ is_new, set_is_new ] = React.useState(false);
    const [ is_refer_urgent, set_is_refer_urgent ] = React.useState(false);
    const [ is_recommend, set_is_recommend ] = React.useState(false);
    const [is_new_patient , set_is_new_patient ] = React.useState(false);
    const [ is_refer_planSupport, set_is_refer_planSupport ] = React.useState(false);
    const [ psychiatrist_need_planSupport, set_psychiatrist_need_planSupport ] = React.useState(false);
    const [ psychotherapist_need_planSupport, set_psychotherapist_need_planSupport ] = React.useState(false);
    const [ longterm_care_need, set_longterm_care_need ] = React.useState(false);
    const [ is_other_planSupport, set_is_other_planSupport ] = React.useState(false);
    const [ other_planSupport, set_other_planSupport ] = React.useState("");
    const [ socia_work, set_socia_work ] = React.useState(false);
    const [showDownload, setShowDownload] = React.useState(false);
    const [lang, setLang] = React.useState('th');

    const [hn_number, setHn_number] = React.useState("");
    const [authen_code, setAuthen_code] = React.useState("");
    const [inspection_unit, setInspection_unit] = React.useState("");
    const [inspection_unit_code, setInspection_unit_code] = React.useState("");
    const [date_admitted, setDate_admitted] = React.useState("");
    const [date_finished, setDate_finished] = React.useState("");
    const [title_name, setTitle_name] = React.useState("");
    const [name, setName] = React.useState("");
    const [surname, setSurname] = React.useState("");
    const [pid, setPid] = React.useState("");
    const [age, setAge] = React.useState("");
    const [gender, setGender] = React.useState("");
    const [right_a, setRight_a] = React.useState("");
    const [phone_number, setPhone_number] = React.useState("");
    const [date_of_birth, setDate_of_birth] = React.useState("");
    const [id_line, setId_line] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [symptom, setSymptom] = React.useState("");
    const [recorder, setRecorder] = React.useState("");
    const [recorder_code, setRecorder_code] = React.useState("");
    const [created_at, setCreated_at ] = React.useState('');
    const [ date_result, setDate_result ] = React.useState('');
    const [ id, setId ] = React.useState('');
    const [ updated_at, setUpdated_at ] = React.useState('');

    const [ showAtk, setShowAtk ] = React.useState(false);
    const [ atk_date, setAtkDate] = React.useState("");
    const [ screeningUnit, setScreeningUnit ] = React.useState("");
    // symptoms
    // normal_symptoms
    const [cough, setCough] =  React.useState(false);
    const [fever_low, setFever_low] =  React.useState(false);
    const [no_symptom, setNo_symptom] =  React.useState(false);
    const [snot, setSnot] =  React.useState(false);

    //risk_symptoms
    const [chest_tightness, setChest_tightness] =  React.useState(false);
    const [fatigue, setFatigue] =  React.useState(false);
    const [fever, setFever] =  React.useState(false);
    const [liquid_stool, setLiquid_stool] =  React.useState(false);
    const [poor_appetite, setPoor_appetite] =  React.useState(false);
    const [puke, setPuke] =  React.useState(false);
    const [severe_cough, setSevere_cough] =  React.useState(false);

    //risk_factor
    const [absolute_lymphocyte, setAbsolute_lymphocyte] =  React.useState(false);
    const [age_risk_factor, setAge_risk_factor] =  React.useState(false);
    const [bmi, setBmi] =  React.useState(false);
    const [chronic_heart_disease, setChronic_heart_disease] =  React.useState(false);
    const [cirrhosis, setCirrhosis] =  React.useState(false);
    const [ckd, setCkd] =  React.useState(false);
    const [copd, setCopd] =  React.useState(false);
    const [cva, setCva] =  React.useState(false);
    const [immunocompromise, setImmunocompromise] =  React.useState(false);
    const [t2dm, setT2dm] =  React.useState(false);

    const [feeling, setFeeling] =  React.useState(false);
    const [is_effective, setIs_effective] =  React.useState(false);
    const [mental_one, setMental_one] =  React.useState("");
    const [mental_two, setMental_two] =  React.useState("false");

    const [az_one, setAz_one] =  React.useState(false);
    const [az_two, setAz_two] =  React.useState(false);
    const [get_vaccine, setGet_vaccine] =  React.useState("false");
    const [vaccine, set_vaccine ] = React.useState("");
    const [is_other_vaccine, setIs_other_vaccine] =  React.useState(false);
    const [other_vaccine, setOther_vaccine] =  React.useState("");
    const [sino_one_az_one, setSino_one_az_one] =  React.useState(false);
    const [sino_two_az_one, setSino_two_az_one] =  React.useState(false);
    const [sino_two_az_two, setSino_two_az_two] =  React.useState(false);
    const [siv_one, setSiv_one] =  React.useState(false);
    const [siv_two, setSiv_two] =  React.useState(false);

    const [blood_pressure, setBlood_pressure] =  React.useState("");
    const [body_temp_first, setBody_temp_first] =  React.useState("");
    const [height, setHeight] =  React.useState("");
    const [lmp, setLmp] =  React.useState("");
    const [pulse_first, setPulse_first] =  React.useState("");
    const [respiration_rate_first, setRespiration_rate_first] =  React.useState("");
    const [weight, setWeight] =  React.useState("");

    const [is_positive, setIs_positive] =  React.useState("");
    const [oxygen_after, setOxygen_after] =  React.useState("");
    const [oxygen_before, setOxygen_before] =  React.useState("");

    const [is_normal, setIs_normal] =  React.useState("");
    const [is_xray, setIs_xray] =  React.useState("");
    const [result, setResult] =  React.useState(""); 

    // idcard and passport 
    const [ idcardNo, setIDcardNo ] = React.useState("");
    const [ passportNo , setPassportNo ] = React.useState("");
    const [is_rapid_test, setIs_rapid_test] =  React.useState(""); 
    const [is_rtpcr_test, setIs_rtpcr_test] =  React.useState(""); 
    const [rapid_place, setRapid_place] =  React.useState(""); 
    const [rapid_result, setRapid_result] =  React.useState(""); 
    const [rtpcr_date, setRtpcr_date] =  React.useState(""); 
    const [rtpcr_place, setRtpcr_place] =  React.useState(""); 
    const [rtpcr_result, setRtpcr_result] =  React.useState(""); 
    const [rapid_date, setRapid_date] =  React.useState(""); 
    const [ healthChecked_created_at, set_healthChecked_created_at ] = React.useState("");
    const [ healthChecked_updated_at, set_healthChecked_updated_at ] = React.useState("");
    const [ healthChecked_id, set_healthChecked_id ] = useState("");

    const [ autoclave_bag, set_autoclave_bag ] = useState(false);
    const [ inhaler, set_inhaler ] = useState(false);
    const [ mask, set_mask ] = useState(false);
    const [ medicine, set_medicine ] = useState(false);
    const [ oximeter, set_oximeter ] = useState(false);
    const [ oxygen_concentrator, set_oxygen_concentrator ] = useState(false);
    const [ thermometer, set_thermometer ] = useState(false);

    const [is_hi_shph, setIs_hi_shph] = React.useState(false);

    const [ isAtkCrop, setIsAtkCrop ] = useState(false);
    const [ length_reportData, setLength_reportData] = useState<number>();
    const [ swapImage, setSwapImage ] = useState(false);

    const [form, setForm] = React.useState<ExportUpdateData>({
      is_depression : depression,
      is_anxiety: anxiety,
      is_stigma: is_stigma,
      is_other: is_other,
      other: other,
      is_patient_plan: is_patient_plan,
      is_cheer_up: is_cheer_up,
      is_empty: is_empty,
      empty: empty,
      psychiatrist_need: psychiatrist_need,
      psychotherapist_need: psychotherapist_need,
      is_andrographis_paniculata: is_andrographis_paniculata,
      andrographis_paniculata_start: andrographis_paniculata_start,
      andrographis_paniculata_end : andrographis_paniculata_end,
      is_favipiravir: is_favipiravir,
      favipiravir_start : favipiravir_start,
      favipiravir_end: favipiravir_end,
      is_empty_medicine: is_empty_medicine,
      empty_medicine: empty_medicine,
      is_tired: is_tired,
      is_cough: is_cough,
      is_fever: is_fever,
      is_chest_pain: is_chest_pain,
      is_other_complication: is_other_complication,
      other_complication: other_complication,
      is_o2_therapy : is_o2_therapy,
      o2_therapy : o2_therapy,
      is_prone: is_prone,
      is_medication: is_medication,
      medication: medication,
      is_refer: is_refer,
      refer: refer,
      is_other_treament: is_other_treament,
      other_treatment: other_treatment,
      body_temp: body_temp,
      pulse: pulse,
      respiration_rate: respiration_rate,
      symptoms: symptoms,
      is_full_day: is_full_day,
      is_refer_summary: is_refer_summary,
      refer_date: refer_date,
      is_hospital: is_hospital,
      hospital: hospital,
      is_back_home: is_back_home,
      home: home,
      is_other_summary: is_other_summary,
      other_summary: other_summary,
      is_home_infect: is_home_infect,
      home_infect: home_infect,
      is_home_risk: is_home_risk,
      home_risk: home_risk,
      is_pui: is_pui,
      pui: pui,
      is_home_treatment: is_home_treatment,
      home_treatment: home_treatment,
      is_special: is_special,
      is_poor: is_poor,
      is_new: is_new,
      is_refer_urgent: is_refer_urgent,
      is_recommend: is_recommend,
      is_new_patient: is_new_patient,
      is_refer_planSupport: is_refer_planSupport,
      psychiatrist_need_planSupport: psychiatrist_need_planSupport,
      psychotherapist_need_planSupport :psychotherapist_need_planSupport,
      longterm_care_need: longterm_care_need,
      is_other_planSupport: is_other_planSupport,
      other_planSupport: other_planSupport,
      socia_work: socia_work
    }); //


    const [formFirst, setFormFirst] = React.useState<FirstReceipt>({
      // user
      user:{
        hn_number : hn_number,
        authen_code: authen_code,
        inspection_unit: inspection_unit,
        inspection_unit_code: inspection_unit_code,
        date_admitted: date_admitted,
        date_finished: date_finished,
        title_name: title_name,
        name: name,
        surname: surname,
        pid: pid,
        age: age,
        gender: gender,
        right_a: right_a,
        phone_number: phone_number,
        date_of_birth: date_of_birth,
        id_line: id_line,
        address: address,
        symptom: symptom,
        recorder: recorder,
        recorder_code: recorder_code,
      },

      symptoms:{
        normal_symptoms:{
          cough: cough,
          fever_low: fever_low,
          no_symptom: no_symptom,
          snot: snot,
        },
        risk_symptoms:{
          chest_tightness: chest_tightness,
          fatigue: fatigue,
          fever: fever,
          liquid_stool: liquid_stool,
          poor_appetite: poor_appetite,
          puke: puke,
          severe_cough: severe_cough,
      },
        risk_factor:{
          absolute_lymphocyte: absolute_lymphocyte,
          age: age_risk_factor,
          bmi: bmi,
          chronic_heart_disease: chronic_heart_disease,
          cirrhosis: cirrhosis,
          ckd: ckd,
          copd: copd,
          cva: cva,
          immunocompromise: immunocompromise,
          t2dm: t2dm,
        },
        mental:{
            feeling: feeling,
            is_effective: is_effective,
            mental_one: mental_one,
            mental_two: mental_two,
        },

        vaccine:{
          az_one: az_one,
          az_two: az_two,
          get_vaccine: get_vaccine,
          is_other: is_other,
          other: other,
          sino_one_az_one: sino_one_az_one,
          sino_two_az_one: sino_two_az_one,
          sino_two_az_two: sino_two_az_two,
          siv_one: siv_one,
          siv_two: siv_two,
        }
      },
      health_checked:{
        health_checked:{
          blood_pressure: blood_pressure,
          body_temp_first: body_temp_first,
          height: height,
          lmp: lmp,
          pulse_first: pulse_first,
          respiration_rate_first: respiration_rate_first,
          weight: weight,
        },
        test_exercise:{
            is_positive: is_positive,
            oxygen_after: oxygen_after,
            oxygen_before: oxygen_before,
        },
        lab_results:{
            is_normal: is_normal,
            is_xray: is_xray,
            result: result,
        },
        screening_results:{
            is_rapid_test: is_rapid_test,
            is_rtpcr_test: is_rtpcr_test,
            rapid_date: rapid_date,
            rapid_place: rapid_place,
            rapid_result: rapid_result,
            rtpcr_date: rtpcr_date,
            rtpcr_place: rtpcr_place,
            rtpcr_result: rtpcr_result,
        }
      }
      
    }); 

    const [dischargeDataForm, setDischargeDataForm ] = React.useState<UpdateDischargeData>({
        hn_number : hn_number,
        authen_code: authen_code,
        // inspection_unit: inspection_unit,
        // inspection_unit_code: inspection_unit_code,
        date_admitted: date_admitted,
        date_finished: date_finished,
        title_name: title_name,
        name: name,
        surname: surname,
        pid: pid,
        age: age,
        gender: gender,
        right_a: right_a,
        phone_number: phone_number,
        date_of_birth: date_of_birth,
        id_line: id_line,
        address: address,
        symptom: symptom,
        recorder: recorder,
        recorder_code: recorder_code,
        created_at: created_at,
        date_result: date_result ? dateStrFormatBuddhist(date_result) : "",
        id: id,
        updated_at: updated_at,
        healthChecked_id: healthChecked_id,
        healthChecked_created_at: healthChecked_created_at,
        healthChecked_updated_at: healthChecked_updated_at,
        autoclave_bag: autoclave_bag,
        inhaler: inhaler,
        mask: mask,
        medicine: medicine,
        oximeter: oximeter,
        oxygen_concentrator: oxygen_concentrator,
        thermometer: thermometer,
        cough: cough,
        fever_low: fever_low,
        no_symptom: no_symptom,
        snot: snot,
        chest_tightness: chest_tightness,
        fatigue: fatigue,
        fever: fever,
        liquid_stool: liquid_stool,
        poor_appetite: poor_appetite,
        puke: puke,
        severe_cough: severe_cough,
        absolute_lymphocyte: absolute_lymphocyte,
        risk_factor_age: age_risk_factor,
        bmi: bmi,
        chronic_heart_disease: chronic_heart_disease,
        cirrhosis: cirrhosis,
        ckd: ckd,
        copd: copd,
        cva: cva,
        immunocompromise: immunocompromise,
        t2dm: t2dm,
        feeling: feeling,
        is_effective: is_effective,
        mental_one: mental_one,
        mental_two: mental_two,
        az_one: az_one,
        az_two: az_two,
        get_vaccine: get_vaccine,
        is_other: is_other_vaccine,
        other: other_vaccine,
        sino_one_az_one: sino_one_az_one,
        sino_two_az_one: sino_two_az_one,
        sino_two_az_two: sino_two_az_two,
        siv_one: siv_one,
        siv_two: siv_two,
        blood_pressure: blood_pressure,
        body_temp_first: body_temp_first,
        height: height,
        lmp: lmp,
        pulse_first: pulse_first,
        respiration_rate_first: respiration_rate_first,
        weight: weight,
        is_positive: is_positive,
        oxygen_after: oxygen_after,
        oxygen_before: oxygen_before,
        is_normal: is_normal,
        is_xray: is_xray,
        result: result,
        is_rapid_test: is_rapid_test,
        is_rtpcr_test: is_rtpcr_test,
        rapid_date: rapid_date,
        rapid_place: rapid_place,
        rapid_result: rapid_result,
        rtpcr_date: rtpcr_date,
        rtpcr_place: rtpcr_place,
        rtpcr_result: rtpcr_result,
        symptoms_created_at: "",
        symptoms_id: "",
        symptoms_updated_at: "",
        vaccine: vaccine,
        treatmentOrder_created_at: "",
        treatmentOrder_id: "",
        treatmentOrder_updated_at: "",
        bromhexine: false,
        cetirizine: false,
        cpm: false,
        dextromethorphan: null,
        green : false,
        indian_gooseberry : false,
        is_patient_medicines : false,
        paracetamol : false,
        patient_medicines : "",
        ci: "",
        further_hospital: "",
        hi_hospital: "",
        hi_shph: "",
        hospital: "",
        is_admit: false,
        is_ci: false,
        is_further_hospital: false,
        is_hi_hospital: false,
        is_hi_shph: false,
        is_not_participate: false,
        is_refer_hospital: false,
        red: false,
        andrographis_paniculata: false,
        day_five: "",
        day_one: false,
        favipiravia: false,
        is_day_five: false,
        yellow: false
    }); 

    const onCapturePNG = (event: React.MouseEvent<HTMLButtonElement>) =>{
      setShowButton(false);
      event.preventDefault();
      const full_name = event.currentTarget.value;  
        htmlToImage.toPng(document.getElementById("savePNG") as HTMLElement)
          .then(function (dataUrl) {
          // saveAs(dataUrl,  'Pateint_Care_Record.png');
              var link = document.createElement('a');
              link.download = 'my-image-name.jpeg';
              const pdf = new jsPDF();
              const imgProps= pdf.getImageProperties(dataUrl);
              const pdfWidth = pdf.internal.pageSize.getWidth();
              const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
              console.log("pdfHeight:", pdfHeight, imgProps.height);
              pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight, undefined,'FAST');
              // pdf.addImage(dataUrl, 'PNG', 13, 0, 185 , 297, undefined,'FAST');
              pdf.addPage();

              htmlToImage.toPng(document.getElementById("saveAnotherPNG") as HTMLElement)
              .then(function (dataUrl) {
                var link = document.createElement('a');
                link.download = 'my-image-name.jpeg';
                const imgProps= pdf.getImageProperties(dataUrl);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                console.log("pdfHeight:", pdfHeight, imgProps.height);
                pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight, undefined,'FAST');

                  if(localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23'){
                    pdf.autoPrint({variant: 'non-conform'});
                    pdf.save(full_name + ".pdf");
                  }else{
                    pdf.addPage();
                    if( show_image_IdCard_data === true && show_image_atk_data === true ) {
                      htmlToImage.toPng(document.getElementById("saveImagePNG_ATK") as HTMLElement)
                      .then(function (dataUrl) {
                        // btn_close
                      // saveAs(dataUrl,  'Pateint_Care_Record.png');
                          var link = document.createElement('a');
                          link.download = 'my-image-name.jpeg';
                          const imgProps= pdf.getImageProperties(dataUrl);
                          const pdfWidth = pdf.internal.pageSize.getWidth();
                          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                          console.log("pdfHeight:", pdfHeight, imgProps.height);
                          pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight, undefined,'FAST');
                          pdf.addPage();
                      });
                      htmlToImage.toPng(document.getElementById("saveImagePNG_IDcard") as HTMLElement)
                      .then(function (dataUrl) {
                        var link = document.createElement('a');
                        link.download = 'my-image-name.jpeg';
                        const imgProps= pdf.getImageProperties(dataUrl);
                        const pdfWidth = pdf.internal.pageSize.getWidth();
                        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                        console.log("pdfHeight:", pdfHeight, imgProps.height);
                        pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight, undefined,'FAST');
                        pdf.autoPrint({variant: 'non-conform'});
                        pdf.save(full_name + ".pdf"); 
                      });
                    }else if(show_image_IdCard_data === true && show_image_atk_data === false){
                      htmlToImage.toPng(document.getElementById("saveImagePNG_IDcard") as HTMLElement)
                      .then(function (dataUrl) {
                        var link = document.createElement('a');
                        link.download = 'my-image-name.jpeg';
                        const imgProps= pdf.getImageProperties(dataUrl);
                        const pdfWidth = pdf.internal.pageSize.getWidth();
                        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                        console.log("pdfHeight:", pdfHeight, imgProps.height);
                        pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight, undefined,'FAST');
                        pdf.autoPrint({variant: 'non-conform'});
                        pdf.save(full_name + ".pdf"); 
                      });
                    }else if(show_image_IdCard_data === false && show_image_atk_data === true){
                      htmlToImage.toPng(document.getElementById("saveImagePNG_ATK") as HTMLElement)
                      .then(function (dataUrl) {
                        var link = document.createElement('a');
                        link.download = 'my-image-name.jpeg';
                        const imgProps= pdf.getImageProperties(dataUrl);
                        const pdfWidth = pdf.internal.pageSize.getWidth();
                        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                        console.log("pdfHeight:", pdfHeight, imgProps.height);
                        pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight, undefined,'FAST');
                        pdf.autoPrint({variant: 'non-conform'});
                        pdf.save(full_name + ".pdf"); 
                      });
                    }else{
                      pdf.autoPrint({variant: 'non-conform'});
                      pdf.save(full_name + ".pdf");
                    }
                  }
                  
                    
              });
          });
      // }
      
    }

    useEffect(() => {
      setDepression(result_exportData?.psychological_problem?.is_depression);
      setAnxiety(result_exportData?.psychological_problem?.is_anxiety);
      setIsStigma(result_exportData?.psychological_problem?.is_stigma);
      setIsOther(result_exportData?.psychological_problem?.is_other);
      setOther(result_exportData?.psychological_problem?.other);

      setPatientPlan(result_exportData?.plan_support_one?.is_patient_plan);
      setCheerUp(result_exportData?.plan_support_one?.is_cheer_up);
      setIsEmpty(result_exportData?.plan_support_one?.is_empty);
      setEmpty(result_exportData?.plan_support_one?.empty);
      setPsychiatristNeed(result_exportData?.plan_support_one?.psychiatrist_need);
      setPsychotherapistNeed(result_exportData?.plan_support_one?.psychotherapist_need);

      setAndrographis(result_exportData?.medicines?.is_andrographis_paniculata);
      set_andrographis_paniculata_start(result_exportData?.medicines?.andrographis_paniculata_start ?   new Date(changeDateFormat(result_exportData?.medicines?.andrographis_paniculata_start?.toString())) : null);
      set_andrographis_paniculata_end(result_exportData?.medicines?.andrographis_paniculata_end ? new Date(changeDateFormat(result_exportData?.medicines?.andrographis_paniculata_end?.toString())) : null);
      set_is_favipiravir(result_exportData?.medicines?.is_favipiravir);
      set_favipiravir_start(result_exportData?.medicines?.favipiravir_start ? new Date(changeDateFormat(result_exportData?.medicines?.favipiravir_start?.toString())) : null);
      set_favipiravir_end(result_exportData?.medicines?.favipiravir_end ? new Date(changeDateFormat(result_exportData?.medicines?.favipiravir_end?.toString())) : null);
      set_is_empty_medicine(result_exportData?.medicines?.is_empty);
      set_empty_medicine(result_exportData?.medicines?.empty);
    
      set_is_tired(result_exportData?.complications?.is_tired);
      set_is_cough(result_exportData?.complications?.is_cough);
      set_is_fever(result_exportData?.complications?.is_fever);
      set_is_chest_pain(result_exportData?.complications?.is_chest_pain);
      set_is_other_complication(result_exportData?.complications?.is_other);
      set_other_complication(result_exportData?.complications?.other);

      set_is_o2_therapy(result_exportData?.treatments?.is_o2_therapy);
      set_o2_therapy(result_exportData?.treatments?.o2_therapy);
      set_is_prone(result_exportData?.treatments?.is_prone);
      set_is_medication(result_exportData?.treatments?.is_medication);
      set_medication(result_exportData?.treatments?.medication);
      set_is_refer(result_exportData?.treatments?.is_refer);
      set_refer(result_exportData?.treatments?.refer);
      set_is_other_treament(result_exportData?.treatments?.is_other);
      set_other_treament(result_exportData?.treatments?.other);

      set_body_temp(result_exportData?.summary?.body_temp);
      set_pulse(result_exportData?.summary?.pulse);
      set_respiration_rate(result_exportData?.summary?.respiration_rate);
      set_symptoms(result_exportData?.summary?.symptoms);
      set_is_full_day(result_exportData?.summary?.is_full_day);
      set_is_refer_summary(result_exportData?.summary?.is_refer);
      set_refer_date(result_exportData?.summary?.refer_date ? new Date(changeDateFormat(result_exportData?.summary?.refer_date?.toString())) : null);
      set_is_hospital(result_exportData?.summary?.is_hospital);
      set_hospital(result_exportData?.summary?.hospital);
      set_is_back_home(result_exportData?.summary?.is_back_home);
      set_home(result_exportData?.summary?.home);
      set_is_other_summary(result_exportData?.summary?.is_other);
      set_other_summary(result_exportData?.summary?.other);

      set_is_home_infect(result_exportData?.social_issues?.is_home_infect);
      set_home_infect(result_exportData?.social_issues?.home_infect);
      set_is_home_risk(result_exportData?.social_issues?.is_home_risk);
      set_home_risk(result_exportData?.social_issues?.home_risk);
      set_is_pui(result_exportData?.social_issues?.is_pui);
      set_pui(result_exportData?.social_issues?.pui);
      set_is_home_treatment(result_exportData?.social_issues?.is_home_treatment);
      set_home_treatment(result_exportData?.social_issues?.home_treatment);
      set_is_special(result_exportData?.social_issues?.is_special);
      set_is_poor(result_exportData?.social_issues?.is_poor);

      set_is_new(result_exportData?.plan_support_two?.is_new);
      set_is_refer_urgent(result_exportData?.plan_support_two?.is_refer_urgent);
      set_is_recommend(result_exportData?.plan_support_two?.is_recommend);
      set_is_new_patient(result_exportData?.plan_support_two?.is_new_patient);
      set_is_refer_planSupport(result_exportData?.plan_support_two?.is_refer);
      set_psychiatrist_need_planSupport(result_exportData?.plan_support_two?.psychiatrist_need);
      set_psychotherapist_need_planSupport(result_exportData?.plan_support_two?.psychotherapist_need);
      set_longterm_care_need(result_exportData?.plan_support_two?.longterm_care_need);
      set_is_other_planSupport(result_exportData?.plan_support_two?.is_other);
      set_other_planSupport(result_exportData?.plan_support_two?.other);
      set_socia_work(result_exportData?.plan_support_two?.socia_work);

      setHn_number(result_data?.user?.hn_number);
      setAuthen_code(result_data?.user?.authen_code);
      if(localStorage.getItem("projectKey") === "xrsponolu71cznf0zr4pc9g3le5qghobbyigowog"){
        setInspection_unit("โรงพยาบาลถลาง");
        setInspection_unit_code("11356");
      }else{
        setInspection_unit(result_data?.user?.inspection_unit);
        setInspection_unit_code(result_data?.user?.inspection_unit_code);
      }
      setDate_admitted(changeDateFormat(result_data?.user?.date_admitted));
      setDate_finished(changeDateFormat(result_data?.user?.date_finished));
      setTitle_name(result_data?.user?.title_name);
      setName(result_data?.user?.name);
      setSurname(result_data?.user?.surname);
      setPid(result_data?.user?.pid);
      setAge(result_data?.user?.age);
      setGender((result_data?.user?.gender == 'M' ? 'ชาย' : 'หญิง'));
      setRight_a(result_data?.user?.right_a);
      setPhone_number(result_data?.user?.phone_number);
      setDate_of_birth(moment(result_data?.user?.date_of_birth).add('543','year').format('DD/MM/YYYY'));
      setId_line(result_data?.user?.id_line);
      setAddress(result_data?.user?.address);
      setSymptom(result_data?.user?.symptom);
      setRecorder(result_data?.user?.recorder);
      setRecorder_code(result_data?.user?.recorder_code);
      setCreated_at(result_data?.user?.created_at);
      setDate_result(dateStrFormatBuddhist(result_data?.user?.date_result));
      setId(result_data?.user?.id);
      setUpdated_at(result_data?.user?.updated_at);
      
      setCough(result_data?.symptoms?.normal_symptoms?.cough);
      setFever_low(result_data?.symptoms?.normal_symptoms?.fever_low);
      setNo_symptom(result_data?.symptoms?.normal_symptoms?.no_symptom);
      setSnot(result_data?.symptoms?.normal_symptoms?.snot);

      setChest_tightness(result_data?.symptoms?.risk_symptoms?.chest_tightness);
      setFatigue(result_data?.symptoms?.risk_symptoms?.fatigue);
      setFever(result_data?.symptoms?.risk_symptoms?.fever);
      setLiquid_stool(result_data?.symptoms?.risk_symptoms?.liquid_stool);
      setPoor_appetite(result_data?.symptoms?.risk_symptoms?.poor_appetite);
      setPuke(result_data?.symptoms?.risk_symptoms?.puke);
      setSevere_cough(result_data?.symptoms?.risk_symptoms?.severe_cough);

      setAbsolute_lymphocyte(result_data?.symptoms?.risk_factor?.absolute_lymphocyte);
      setAge_risk_factor(result_data?.symptoms?.risk_factor?.age);
      setBmi(result_data?.symptoms?.risk_factor?.bmi);
      setCirrhosis(result_data?.symptoms?.risk_factor?.cirrhosis);
      setChronic_heart_disease(result_data?.symptoms?.risk_factor?.chronic_heart_disease);
      setCkd(result_data?.symptoms?.risk_factor?.ckd);
      setCopd(result_data?.symptoms?.risk_factor?.copd);
      setCva(result_data?.symptoms?.risk_factor?.cva);
      setImmunocompromise(result_data?.symptoms?.risk_factor?.immunocompromise);
      setT2dm(result_data?.symptoms?.risk_factor?.t2dm);

      setFeeling(result_data?.symptoms?.mental?.feeling);
      setIs_effective(result_data?.symptoms?.mental?.is_effective);
      setMental_one(result_data?.symptoms?.mental?.mental_one ? "true" : "false");
      setMental_two(result_data?.symptoms?.mental?.mental_two ? "true" : "false");

      setAz_one(result_data?.symptoms?.viccine?.az_one);
      setAz_two(result_data?.symptoms?.viccine?.az_two);
      if(localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23'){
        setGet_vaccine("true");
      }else{
        setGet_vaccine(result_data?.symptoms?.viccine?.get_vaccine ? "true" : "false");
      }
      setIs_other_vaccine(result_data?.symptoms?.viccine?.is_other);
      setOther_vaccine(result_data?.symptoms?.viccine?.other);
      setSino_one_az_one(result_data?.symptoms?.viccine?.sino_one_az_one);
      setSino_two_az_one(result_data?.symptoms?.viccine?.sino_two_az_one);
      setSino_two_az_two(result_data?.symptoms?.viccine?.sino_two_az_two);
      setSiv_one(result_data?.symptoms?.viccine?.siv_one);
      setSiv_two(result_data?.symptoms?.viccine?.siv_two);

      setBlood_pressure(result_data?.health_checked?.health_checked?.blood_pressure);
      setBody_temp_first(result_data?.health_checked?.health_checked?.body_temp);
      setHeight(result_data?.health_checked?.health_checked?.height);
      setLmp(result_data?.health_checked?.health_checked?.lmp);
      setPulse_first(result_data?.health_checked?.health_checked?.pulse);
      setRespiration_rate_first(result_data?.health_checked?.health_checked?.respiration_rate);
      setWeight(result_data?.health_checked?.health_checked?.weight);

      setIs_positive(result_data?.health_checked?.test_exercise?.is_positive ? "true" : "false");
      setOxygen_after(result_data?.health_checked?.test_exercise?.oxygen_after);
      setOxygen_before(result_data?.health_checked?.test_exercise?.oxygen_before);

      setIs_normal(result_data?.health_checked?.lab_results?.is_normal ? "true" : "false");
      setIs_xray(result_data?.health_checked?.lab_results?.is_xray ? "true" : "false");
      setResult(result_data?.health_checked?.lab_results?.result);
      
      
      if(localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23') {
        setIs_rapid_test("true");
      }else{
        setIs_rapid_test(result_data?.health_checked.screening_results?.is_rapid_test ? "true" : "false");
      }
      setRapid_date(result_data?.health_checked.screening_results?.rapid_date);
      setRapid_result(result_data?.health_checked.screening_results?.rapid_result);
      setRapid_place(result_data?.health_checked?.screening_results?.rapid_place);
      setIs_rtpcr_test(result_data?.health_checked.screening_results?.is_rtpcr_test ? "true" : "false");
      setRtpcr_place(result_data?.health_checked.screening_results?.rtpcr_place);
      setRtpcr_date(result_data?.health_checked.screening_results?.rtpcr_date);
      setRtpcr_result(result_data?.health_checked.screening_results?.rtpcr_result);
      setIs_hi_shph(result_data?.treatment_orders?.level_three?.is_hi_shph);

      set_healthChecked_created_at(result_data?.health_checked?.created_at);
      set_healthChecked_updated_at(result_data?.health_checked?.updated_at);
      set_healthChecked_id(result_data?.health_checked?.id);
      
      if(result_data?.treatment_orders?.level_one?.paracetamol === true || result_data?.treatment_orders?.level_one?.cetirizine === true || 
        result_data?.treatment_orders?.level_one?.bromhexine === true || result_data?.treatment_orders?.level_one?.cpm === true ||
        result_data?.treatment_orders?.level_one?.indian_gooseberry === true || result_data?.treatment_orders?.level_one?.is_patient_medicines === true ||
        result_data?.treatment_orders?.level_two?.day_one === true || result_data?.treatment_orders?.level_two?.favipiravia === true || 
        result_data?.treatment_orders?.level_two?.is_day_five === true || result_data?.treatment_orders?.level_two?.andrographis_paniculata === true){
          set_mask(true)
      }

      // set_autoclave_bag(result_data?.health_checked?.medicine_prepare?.autoclave_bag);
      // set_inhaler(result_data?.health_checked?.medicine_prepare?.inhaler);
      // set_mask(result_data?.health_checked?.medicine_prepare?.mask);
      // set_medicine(result_data?.health_checked?.medicine_prepare?.medicine);
      // set_oximeter(result_data?.health_checked?.medicine_prepare?.oximeter);
      // set_oxygen_concentrator(result_data?.health_checked?.medicine_prepare?.oxygen_concentrator);
      // set_thermometer(result_data?.health_checked?.medicine_prepare?.thermometer);

      dateArray.map((data, index)=> {
        if(dateArray[index] !== ""){
          const date_count = index + 1;
          setLength_reportData(date_count);
          set_is_full_day(true);
        }
      });
      
      historylog?.map((data, index)=>{
        if(reportData[index]?.temprature !== 0 && result_exportData?.summary?.body_temp === 0){
          set_body_temp(Number(reportData[index]?.temprature.toFixed(1)));
        }
        
        if(reportData[index]?.heart_rate !== 0 && result_exportData?.summary?.pulse === 0){
          set_pulse(Number(reportData[index]?.heart_rate.toFixed(1)));
        }

        if(reportData[index]?.symptoms_one !== "" && result_exportData?.summary?.symptoms === ""){
          set_symptoms(reportData[index]?.symptoms_one.toString());
        }
          
      });
      console.log("length_reportData", length_reportData);

      if(result_data?.symptoms?.normal_symptoms?.cough == false && result_data?.symptoms?.normal_symptoms?.fever_low == false && result_data?.symptoms?.normal_symptoms?.no_symptom == false && result_data?.symptoms?.normal_symptoms?.snot == false){
        setNo_symptom(true);
      }

      if(result_data?.treatment_orders?.level_three?.is_hi_shph === false && result_data?.treatment_orders?.level_three?.is_further_hospital === false){
        setIs_hi_shph(true);
      }
      console.log("is_hi_shph",is_hi_shph)
      setFormFirst({
        ...formFirst,
      });

      setDischargeDataForm({
        ...dischargeDataForm,
        hn_number : result_data?.user?.hn_number,
        authen_code: result_data?.user?.authen_code,
        // inspection_unit: result_data?.user?.inspection_unit,
        // inspection_unit_code: result_data?.user?.inspection_unit_code,
        date_admitted: result_data?.user?.date_admitted,
        date_finished: result_data?.user?.date_finished,
        title_name: result_data?.user?.title_name,
        name: result_data?.user?.name,
        surname: result_data?.user?.surname,
        pid: result_data?.user?.pid,
        age: result_data?.user?.age,
        gender: result_data?.user?.gender,
        right_a: result_data?.user?.right_a,
        phone_number: result_data?.user?.phone_number,
        date_of_birth: dateStrAPI(result_data?.user?.date_of_birth),
        id_line: result_data?.user?.id_line,
        address: result_data?.user?.address,
        symptom: result_data?.user?.symptom,
        recorder: result_data?.user?.recorder,
        recorder_code: result_data?.user?.recorder_code,
        created_at: result_data?.user?.created_at,
        date_result: dateStrFormatBuddhist(result_data?.user?.date_result),
        id: result_data?.user?.id,
        updated_at: result_data?.user?.updated_at,

        cough: result_data?.symptoms?.normal_symptoms?.cough,
        fever_low: result_data?.symptoms?.normal_symptoms?.fever_low,
        no_symptom: result_data?.symptoms?.normal_symptoms?.no_symptom,
        snot: result_data?.symptoms?.normal_symptoms?.snot,
        chest_tightness: result_data?.symptoms?.risk_symptoms?.chest_tightness,
        fatigue: result_data?.symptoms?.risk_symptoms?.fatigue,
        fever: result_data?.symptoms?.risk_symptoms?.fever,
        liquid_stool: result_data?.symptoms?.risk_symptoms?.liquid_stool,
        poor_appetite: result_data?.symptoms?.risk_symptoms?.poor_appetite,
        puke: result_data?.symptoms?.risk_symptoms?.puke,
        severe_cough: result_data?.symptoms?.risk_symptoms?.severe_cough,
        absolute_lymphocyte: result_data?.symptoms?.risk_factor?.absolute_lymphocyte,
        risk_factor_age: result_data?.symptoms?.risk_factor?.age,
        bmi: result_data?.symptoms?.risk_factor?.bmi,
        chronic_heart_disease: result_data?.symptoms?.risk_factor?.chronic_heart_disease,
        cirrhosis: result_data?.symptoms?.risk_factor?.cirrhosis,
        ckd: result_data?.symptoms?.risk_factor?.ckd,
        copd: result_data?.symptoms?.risk_factor?.copd,
        cva: result_data?.symptoms?.risk_factor?.cva,
        immunocompromise: result_data?.symptoms?.risk_factor?.immunocompromise,
        t2dm: result_data?.symptoms?.risk_factor?.t2dm,
        feeling: result_data?.symptoms?.mental?.feeling,
        is_effective: result_data?.symptoms?.mental?.is_effective,
        mental_one: result_data?.symptoms?.mental?.mental_one,
        mental_two: result_data?.symptoms?.mental?.mental_two,
        az_one: result_data?.symptoms?.vaccine?.az_one,
        az_two: result_data?.symptoms?.viccine?.az_two,
        get_vaccine: result_data?.symptoms?.viccine?.get_vaccine,
        is_other: result_data?.symptoms?.viccine?.is_other,
        other: result_data?.symptoms?.viccine?.other,
        sino_one_az_one: result_data?.symptoms?.viccine?.sino_one_az_one,
        sino_two_az_one: result_data?.symptoms?.viccine?.sino_two_az_one,
        sino_two_az_two: result_data?.symptoms?.viccine?.sino_two_az_two,
        siv_one: result_data?.symptoms?.viccine?.siv_one,
        siv_two: result_data?.symptoms?.viccine?.siv_two,
        blood_pressure: result_data?.health_checked?.health_checked?.blood_pressure,
        body_temp_first: result_data?.health_checked?.health_checked?.body_temp,
        height: result_data?.health_checked?.health_checked?.height,
        lmp: result_data?.health_checked?.health_checked?.lmp,
        pulse_first: result_data?.health_checked?.health_checked?.pulse,
        respiration_rate_first: result_data?.health_checked?.health_checked?.respiration_rate,
        weight: result_data?.health_checked?.health_checked?.weight,
        is_positive: result_data?.health_checked?.test_exercise?.is_positive,
        oxygen_after: result_data?.health_checked?.test_exercise?.oxygen_after,
        oxygen_before: result_data?.health_checked?.test_exercise?.oxygen_before,
        is_normal: result_data?.health_checked?.lab_results?.is_normal,
        is_xray: result_data?.health_checked?.lab_results?.is_xray,
        result: result_data?.health_checked?.lab_results?.result,
        is_rapid_test: localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23'? true : result_data?.health_checked.screening_results?.is_rapid_test,
        is_rtpcr_test: result_data?.health_checked.screening_results?.is_rtpcr_test,
        rapid_date: result_data?.health_checked.screening_results?.rapid_date,
        rapid_place: result_data?.health_checked.screening_results?.rapid_place,
        rapid_result: result_data?.health_checked.screening_results?.rapid_result,
        rtpcr_date: result_data?.health_checked.screening_results?.rtpcr_date,
        rtpcr_place: result_data?.health_checked.screening_results?.rtpcr_place,
        rtpcr_result: result_data?.health_checked.screening_results?.rtpcr_result,
        healthChecked_created_at: result_data?.health_checked?.created_at,
        healthChecked_updated_at: result_data?.health_checked?.updated_at,
        healthChecked_id: result_data?.health_checked?.id,
        autoclave_bag: result_data?.health_checked?.medicine_prepare?.autoclave_bag,
        inhaler:  result_data?.health_checked?.medicine_prepare?.inhaler,
        mask:  result_data?.health_checked?.medicine_prepare?.mask,
        medicine:  result_data?.health_checked?.medicine_prepare?.medicine,
        oximeter:  result_data?.health_checked?.medicine_prepare?.oximeter,
        oxygen_concentrator:  result_data?.health_checked?.medicine_prepare?.oxygen_concentrator,
        thermometer:  result_data?.health_checked?.medicine_prepare?.thermometer,
        symptoms_created_at: result_data?.symptoms?.created_at,
        symptoms_id: result_data?.symptoms?.id,
        symptoms_updated_at: result_data?.symptoms?.updated_at,
        treatmentOrder_created_at: result_data?.treatment_orders?.created_at,
        treatmentOrder_id: result_data?.treatment_orders?.id,
        treatmentOrder_updated_at: result_data?.treatment_orders?.updated_at,
        bromhexine: result_data?.treatment_orders?.level_one?.bromhexine,
        cetirizine: result_data?.treatment_orders?.level_one?.cetirizine,
        cpm: result_data?.treatment_orders?.level_one?.cpm,
        dextromethorphan:result_data?.treatment_orders?.level_one?.dextromethorphan,
        green : result_data?.treatment_orders?.level_one?.green,
        indian_gooseberry : result_data?.treatment_orders?.level_one?.indian_gooseberry,
        is_patient_medicines : result_data?.treatment_orders?.level_one?.is_patient_medicines,
        paracetamol : result_data?.treatment_orders?.level_one?.paracetamol,
        patient_medicines : result_data?.treatment_orders?.level_one?.patient_medicines,
        ci: result_data?.treatment_orders?.level_three?.ci,
        further_hospital: result_data?.treatment_orders?.level_three?.further_hospital,
        hi_hospital: result_data?.treatment_orders?.level_three?.hi_hospital,
        hi_shph: result_data?.treatment_orders?.level_three?.hi_shph,
        hospital: result_data?.treatment_orders?.level_three?.hospital,
        is_admit: result_data?.treatment_orders?.level_three?.is_admit,
        is_ci: result_data?.treatment_orders?.level_three?.is_ci,
        is_further_hospital: result_data?.treatment_orders?.level_three?.is_further_hospital,
        is_hi_hospital: result_data?.treatment_orders?.level_three?.is_hi_hospital,
        is_hi_shph: result_data?.treatment_orders?.level_three?.is_hi_shph,
        is_not_participate: result_data?.treatment_orders?.level_three?.is_not_participate,
        is_refer_hospital: result_data?.treatment_orders?.level_three?.is_refer_hospital,
        red: result_data?.treatment_orders?.level_three?.red,
        andrographis_paniculata: result_data?.treatment_orders?.level_two?.andrographis_paniculata,
        day_five: result_data?.treatment_orders?.level_two?.day_five,
        day_one: result_data?.treatment_orders?.level_two?.day_one,
        favipiravia: result_data?.treatment_orders?.level_two?.favipiravia,
        is_day_five: result_data?.treatment_orders?.level_two?.is_day_five,
        yellow: result_data?.treatment_orders?.level_two?.yellow
      })

      setForm({
        ...form,
        is_depression : result_exportData?.psychological_problem?.is_depression,
        is_anxiety: result_exportData?.psychological_problem?.is_anxiety,
        is_stigma: result_exportData?.psychological_problem?.is_stigma,
        is_other: result_exportData?.psychological_problem?.is_other,
        other: result_exportData?.psychological_problem?.other,
        is_patient_plan: result_exportData?.plan_support_one?.is_patient_plan,
        is_cheer_up: result_exportData?.plan_support_one?.is_cheer_up,
        is_empty: result_exportData?.plan_support_one?.is_empty,
        empty: result_exportData?.plan_support_one?.empty,
        psychiatrist_need: result_exportData?.plan_support_one?.psychiatrist_need,
        psychotherapist_need: result_exportData?.plan_support_one?.psychotherapist_need,
        is_andrographis_paniculata: result_exportData?.medicines?.is_andrographis_paniculata,
        andrographis_paniculata_start: result_exportData?.medicines?.andrographis_paniculata_start ? new Date(changeDateFormat(result_exportData?.medicines?.andrographis_paniculata_start?.toString())) : null,
        andrographis_paniculata_end : result_exportData?.medicines?.andrographis_paniculata_end ? new Date(result_exportData?.medicines?.andrographis_paniculata_end?.toString()) : null,
        is_favipiravir: result_exportData?.medicines?.is_favipiravir,
        favipiravir_start : result_exportData?.medicines?.favipiravir_start ? new Date(changeDateFormat(result_exportData?.medicines?.favipiravir_start?.toString())) : null,
        favipiravir_end: result_exportData?.medicines?.favipiravir_end ? new Date(changeDateFormat(result_exportData?.medicines?.favipiravir_end?.toString())) : null,
        is_empty_medicine: result_exportData?.medicines?.is_empty,
        empty_medicine: result_exportData?.medicines?.empty,
        is_tired: result_exportData?.complications?.is_tired,
        is_cough: result_exportData?.complications?.is_cough,
        is_fever: result_exportData?.complications?.is_fever,
        is_chest_pain: result_exportData?.complications?.is_chest_pain,
        is_other_complication: result_exportData?.complications?.is_other,
        other_complication: result_exportData?.complications?.other,
        is_o2_therapy : result_exportData?.treatments?.is_o2_therapy,
        o2_therapy : result_exportData?.treatments?.o2_therapy,
        is_prone: result_exportData?.treatments?.is_prone,
        is_medication: result_exportData?.treatments?.is_medication,
        medication: result_exportData?.treatments?.medication,
        is_refer: result_exportData?.treatments?.is_refer,
        refer: result_exportData?.treatments?.treatments?.refer,
        is_other_treament: result_exportData?.treatments?.is_other,
        other_treatment: result_exportData?.treatments?.other,
        body_temp: result_exportData?.summary?.body_temp,
        pulse: result_exportData?.summary?.pulse,
        respiration_rate: result_exportData?.summary?.respiration_rate,
        symptoms: result_exportData?.summary?.symptoms,
        is_full_day: result_exportData?.summary?.is_full_day,
        is_refer_summary: result_exportData?.summary?.is_refer,
        refer_date:result_exportData?.summary?.refer_date ? new Date(changeDateFormat(result_exportData?.summary?.refer_date?.toString())) : null,
        is_hospital: result_exportData?.summary?.is_hospital,
        hospital: result_exportData?.summary?.hospital,
        is_back_home: result_exportData?.summary?.is_back_home,
        home: result_exportData?.summary?.home,
        is_other_summary: result_exportData?.summary?.is_other,
        other_summary: result_exportData?.summary?.other,
        is_home_infect: result_exportData?.social_issues?.is_home_infect,
        home_infect: result_exportData?.social_issues?.home_infect,
        is_home_risk: result_exportData?.social_issues?.is_home_risk,
        home_risk: result_exportData?.social_issues?.home_risk,
        is_pui: result_exportData?.social_issues?.is_pui,
        pui: result_exportData?.social_issues?.pui,
        is_home_treatment: result_exportData?.social_issues?.is_home_treatment,
        home_treatment: result_exportData?.social_issues?.home_treatment,
        is_special: result_exportData?.social_issues?.is_special,
        is_poor: result_exportData?.social_issues?.is_poor,
        is_new: result_exportData?.plan_support_two?.is_new,
        is_refer_urgent: result_exportData?.plan_support_two?.is_refer_urgent,
        is_recommend: result_exportData?.plan_support_two?.is_recommend,
        is_new_patient: result_exportData?.plan_support_two?.is_new_patient,
        is_refer_planSupport: result_exportData?.plan_support_two?.is_refer,
        psychiatrist_need_planSupport: result_exportData?.plan_support_two?.psychiatrist_need,
        psychotherapist_need_planSupport :result_exportData?.plan_support_two?.psychotherapist_need,
        longterm_care_need: result_exportData?.plan_support_two?.longterm_care_need,
        is_other_planSupport: result_exportData?.plan_support_two?.is_other,
        other_planSupport: result_exportData?.plan_support_two?.other,
        socia_work: result_exportData?.plan_support_two?.socia_work
      })

      // id card and passport 
      setIDcardNo(result_vitalSign?.user?.identity_card?.personal_no);
      setPassportNo(result_vitalSign?.user?.passport?.personal_no);

      if(localStorage.getItem("projectKey") === 'g9cntq4kj1ylkuc812i4bf4qrv4fmk90b2jpf7zl' )
      {
        setLang('en')
      }else{
        setLang('th')
      }

      set_image_atk(result_image_atk_data)

    }, [result_exportData, result_data, result_vitalSign,result_image_atk_data ]);

    const validateNumber = (event : any) => {
      const keyCode = event.keyCode || event.which
      const string = String.fromCharCode(keyCode)
      const regex = /[0-9,]|\./
    
      if (!regex.test(string)) {
        event.returnValue = false
        if (event.preventDefault) event.preventDefault()
      }
    }

    const changeDateFormat = (date: any) => {
      if (date){
        console.log("dateFormat", date);
          let splitDate = date.split('/');
          let changeYear;
          if(parseInt(splitDate[2]) <= 2022){
            changeYear = parseInt(splitDate[2]) + 543;
          }else{
            changeYear = splitDate[2];
          }
          let dateString = splitDate[0] + '/'+ splitDate[1] +'/' +  changeYear;
          console.log("datestring;", dateString);
          return dateString;
      }else {
        return '';
      }
    }
    const setValFirst = (k: any, v: any) => {
      let newV: any = { ...formFirst };
      let newValue: any = { ...dischargeDataForm}
      newV[k] = v;
      newValue[k] = v;
      setFormFirst(newV);
      setDischargeDataForm(newValue);
    };

    const handleChange_mental_one = (event: React.ChangeEvent<HTMLInputElement>) => {
      setMental_one((event.target as HTMLInputElement).value);
    };

    const setVal = (k: any, v: any) => {
      let newV: any = { ...form };
      newV[k] = v;
      setForm(newV);
    };
    
    const handleChangeLanguage = (event: React.MouseEvent<HTMLElement>, language: string) => {
        console.log("language change:" , language);
        setLang(language);
        localStorage.setItem('language', language);
    };

    const date_admitted_api = result_data?.user?.date_admitted.split('/')[2] <= '2022' ? 
    moment(result_data?.user?.date_admitted.split('/')[2] + '-' + result_data?.user?.date_admitted.split('/')[1] + '-' + result_data?.user?.date_admitted.split('/')[0]).add(543, 'year').format('DD/MM/YYYY') : 
    result_data?.user?.date_admitted;

    const date_finished_api = result_data?.user?.date_finished.split('/')[2] <= '2022' ? 
    moment(result_data?.user?.date_finished.split('/')[2] + '-' + result_data?.user?.date_finished.split('/')[1] + '-' + result_data?.user?.date_finished.split('/')[0]).add(543, 'year').format('DD/MM/YYYY') : 
    result_data?.user?.date_finished;

    const handleChange_mental_two = (event: React.ChangeEvent<HTMLInputElement>) => {
      setMental_two((event.target as HTMLInputElement).value);
    };

    const handleChange_get_vaccine = (event: React.ChangeEvent<HTMLInputElement>) => {
      setGet_vaccine((event.target as HTMLInputElement).value);
    };

    const handleChange_vaccine = (event: React.ChangeEvent<HTMLInputElement>) => {
      set_vaccine((event.target as HTMLInputElement).value);
      console.log("set vaccine:", event.target.value);
    };

    const handleChange_is_positive = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIs_positive((event.target as HTMLInputElement).value);
    };

    const handleChange_is_xray = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIs_xray((event.target as HTMLInputElement).value);
    };

    const handleChange_is_normal = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIs_normal((event.target as HTMLInputElement).value);
    };

    const handleChange_is_rapid_test = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIs_rapid_test((event.target as HTMLInputElement).value);
      setIs_rtpcr_test("");
    };

    const handleChange_is_rtpcr_test = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIs_rtpcr_test((event.target as HTMLInputElement).value);
      setIs_rapid_test("");
    };

    const hn_number_api = result_data?.user?.hn_number;
    const authen_code_api = result_data?.user?.authen_code;
    const inspection_unit_code_api = result_data?.user?.inspection_unit_code;
    const title_name_api = result_data?.user?.title_name;
    const name_api = result_data?.user?.name;
    const surname_api = result_data?.user?.surname;
    const pid_api = result_data?.user?.pid;
    const age_api = result_data?.user?.age;
    const gender_api = result_data?.user?.gender;
    const right_a_api = result_data?.user?.right_a;
    // const phone_number_api = result_data?.user?.phone_number;
    const date_of_birth_api = moment(result_data?.user?.date_of_birth).add('543','year').format('DD/MM/YYYY');
    const id_line_api = result_data?.user?.id_line;
    const address_api = result_data?.user?.address;
    const symptom_api = result_data?.user?.symptom;
    const recorder_api = result_data?.user?.recorder;
    const recorder_code_api = result_data?.user?.recorder_code;
    const cough_api = result_data?.symptoms?.normal_symptoms?.cough;
    const fever_low_api = result_data?.symptoms?.normal_symptoms?.fever_low;
    const no_symptom_api = result_data?.symptoms?.normal_symptoms?.no_symptom;
    const snot_api = result_data?.symptoms?.normal_symptoms?.snot;

    const weight_api = result_data?.health_checked?.health_checked?.weight;
    const respiration_rate_api = result_data?.health_checked?.health_checked?.respiration_rate;
    const pulse_api = result_data?.health_checked?.health_checked?.pulse;
    const lmp_api = result_data?.health_checked?.health_checked?.lmp;
    const height_api = result_data?.health_checked?.health_checked?.height;
    const body_temp_api = result_data?.health_checked?.health_checked?.body_temp;
    const blood_pressure_api = result_data?.health_checked?.health_checked?.blood_pressure;

    const is_positive_api = result_data?.health_checked?.test_exercise?.is_positive;
    const oxygen_after_api = result_data?.health_checked?.test_exercise?.blood_pressure;
    const oxygen_before_api = result_data?.health_checked?.test_exercise?.blood_pressure;

    return(
      <MuiThemeProvider theme={theme}>
      {/* {
        // localStorage.getItem("projectKey") === 'xrsponolu71cznf0zr4pc9g3le5qghobbyigowog'
        // || localStorage.getItem("projectKey") === 'g9cntq4kj1ylkuc812i4bf4qrv4fmk90b2jpf7zl' ? 
        localStorage.getItem("projectKey") === 'r65nxurwl4rnoqlbv6n8jh2gxnddamhej11t6xml' || localStorage.getItem("projectKey") === 'xrsponolu71cznf0zr4pc9g3le5qghobbyigowog' 
        || localStorage.getItem("projectKey") === 'g9cntq4kj1ylkuc812i4bf4qrv4fmk90b2jpf7zl' || localStorage.getItem("projectKey") === 'qk6oyhtc7c9ptbnffkd90xdpji2iye11v5lhw4qy'
        || localStorage.getItem("projectKey") === 'pi7iotuircb2x90nqqizi4v91x0whrd5xgm42cro' || localStorage.getItem("projectKey") === '7zwp8sxengxpq37stixi4za1asmtm3fvx8dvkrzj'
        ?
        <></>: */}
        {/* <Box
          style={{
            width: "100%",
            display: "flex",
            flex: 1,
            minHeight: "100%",
            alignItems: "right",
            justifyContent: "right",
            borderRadius: 5,
            backgroundColor: "#fff",
          }}
        >
            <br/>
            <br/>
            <div style={{float : 'right', marginRight: '16px', backgroundColor: '#fff'}}>
            <br/>
            <br/>
                <ToggleButtonGroup
                    color="primary"
                    value={lang}
                    exclusive
                    onChange={handleChangeLanguage}
                    size="small"
                >
                    <ToggleButton value="en">English</ToggleButton>
                    <ToggleButton value="th">ภาษาไทย</ToggleButton>
                </ToggleButtonGroup>
            </div>  
        </Box> */}
        {
            lang === 'th' ?
            <>
                <>
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      flex: 1,
                      minHeight: "100%",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 5,
                      backgroundColor: "#fff",
                    }} id="savePNG"
                  >
                    <Box display={{ sm: "none", xs: "none", md: "block" }} style={{fontFamily: 'Poppins', fontWeight: 400, fontSize: '16px'}}>
                        <Typography gutterBottom>
                          <Box>
                            <Grid container>
                                <Grid item xs={12} style={{fontFamily: 'Poppins', fontSize: '28px', textAlign: 'center'}}>
                                  <br/>
                                  แบบบันทึกการดูแลรักษาผู้ป่วย Covid-19 กรณี Home isolation หรือ Community isolation
                                </Grid>
                            </Grid>
                          </Box>
                          <Box
                            fontWeight="bold"
                            textAlign="center"
                            lineHeight={"56px"}
                            fontSize={20}
                          >
                          </Box>
                          <Box
                            fontWeight="bold"
                            textAlign="center"
                            mb={3}
                            css={{ color: "#14AF5C" }}
                          >
                          </Box>
                        </Typography>
                    </Box>

                    <Box style={{ fontFamily: 'Poppins', fontSize: '16px', width: "100%", fontWeight: 400}}>
                      <Box style={{ width: "100%" }} p={2}>
                        <Grid container spacing= {3}>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={3}>
                          <label style={{fontFamily: 'Poppins', fontWeight: 400}}>หมายเลข HN</label>
                            <br/>
                              <TextField
                                id="margin-none"
                                inputProps={{fontFamily: 'Poppins'}}
                                style={{fontFamily: 'Poppins', fontSize: '16px', paddingRight: '10px'}}
                                type="text"
                                fullWidth
                                value={ hn_number } 
                                name="hn_number" 
                                onChange={(event) =>{
                                    setHn_number(event.target.value);
                                    setValFirst(event.target.name, event.target.value);
                                  }
                                } 
                              />
                          </Grid>
                          <Grid item xs={3}>
                            <label style={{fontFamily: 'Poppins', fontWeight: 400}}>Authen Code</label>
                              <br/>
                                <TextField
                                    id="margin-none"
                                    style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                    type="text"
                                    fullWidth
                                    // value={ authen_code || authen_code_api } 
                                    value={ authen_code } 
                                    name="authen_code"
                                    onChange={(event) =>{
                                      setAuthen_code(event.target.value);
                                      setValFirst(event.target.name, event.target.value);
                                    }
                                  } 
                                />
                          </Grid>
                        </Grid>
                        <br/>

                        <Grid container>
                          <Grid item xs= {3} style={{paddingTop:'8px', paddingLeft:'5px', paddingRight : '10px'}}>
                            <label style={{fontFamily: 'Poppins', fontWeight: 400}}>ชื่อหน่วยบริการ</label>
                            <br/>
                              <TextField
                                  id="margin-none"
                                  style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                  type="text"
                                  fullWidth
                                  // value={ inspection_unit || result_data?.user?.inspection_unit } 
                                  value={ inspection_unit } 
                                  name="inspection_unit"
                                  onChange={(event) =>{
                                    setInspection_unit(event.target.value);
                                    setValFirst(event.target.name, event.target.value);
                                  }
                                } 
                              />
                          </Grid>
                          <Grid item xs= {3} style={{paddingTop:'8px', paddingLeft:'5px', paddingRight : '10px'}}>
                            <label style={{fontFamily: 'Poppins', fontWeight: 400}}>รหัสหน่วยบริการ</label>
                            <br/>
                              <TextField
                                  id="margin-none"
                                  style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                  type="text"
                                  fullWidth
                                  name="inspection_unit_code"
                                  // value={ inspection_unit_code || inspection_unit_code_api } 
                                  value={ inspection_unit_code } 
                                  onChange={(event) =>{
                                    setInspection_unit_code(event.target.value);
                                    setValFirst(event.target.name, event.target.value);
                                  }
                                } 
                              />
                          </Grid>
                          <Grid item xs= {3} style={{paddingTop:'8px', paddingLeft:'5px', paddingRight : '10px'}}>
                            <label style={{fontFamily: 'Poppins', fontWeight: 400}}>วันที่รับบริการ</label>
                            <br/>
                              <TextField
                                  id="margin-none"
                                  style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                  type="text"
                                  fullWidth
                                  // value={date_admitted || date_admitted_api} 
                                  value={date_admitted } 
                                  name="date_admitted"
                                  onChange={(event) =>{
                                      setDate_admitted(event.target.value);
                                      setValFirst(event.target.name, event.target.value);
                                    }
                                  } 
                                  
                              />
                          </Grid>
                          <Grid item xs= {3} style={{paddingTop:'8px', paddingLeft:'5px', paddingRight : '10px'}}>
                          
                          <label style={{fontFamily: 'Poppins', fontWeight: 400}}>วันที่จําหน่าย</label>
                            <br/>
                              <TextField
                                  id="margin-none"
                                  style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                  type="text"
                                  fullWidth
                                  // value={ date_finished || date_finished_api } 
                                  value={ date_finished } 
                                  name="date_finished"
                                  onChange={(event) =>{
                                      setDate_finished(event.target.value);
                                      setValFirst(event.target.name, event.target.value);
                                    }
                                  } 
                                  
                              />
                          </Grid>

                        </Grid>
                        <br/>
                        <Grid container> 
                            <Grid item xs={1}>
                              <label style={{fontFamily: 'Poppins', fontWeight: 400}}>คำนำหน้า</label>
                              <br/>
                                <TextField
                                    id="margin-none"
                                    style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                    type="text"
                                    // value={ title_name || title_name_api } 
                                    value={ title_name } 
                                    name="title_name"
                                    onChange={(event) =>{
                                      setTitle_name(event.target.value);
                                      setValFirst(event.target.name, event.target.value);
                                    }
                                  } 
                                />
                            </Grid>
                            <Grid item xs={2} css={{paddingLeft: '15px'}}>
                              <label style={{fontFamily: 'Poppins', fontWeight: 400}}>ชื่อ</label>
                              <br/>
                                <TextField
                                    id="margin-none"
                                    style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                    type="text"
                                    value={ name } 
                                    name="name"
                                    onChange={(event) =>{
                                      setName(event.target.value);
                                      setValFirst(event.target.name, event.target.value);
                                    }
                                  } 
                                />
                            </Grid>

                            <Grid item xs={2}>
                            <label style={{fontFamily: 'Poppins', fontWeight: 400}}>นามสกุล</label>
                                <br/>
                                  <TextField
                                      id="margin-none"
                                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                      type="text"
                                      value={ surname } 
                                      // value={ surname || surname_api } 
                                      name="surname"
                                      onChange={(event) =>{
                                        setSurname(event.target.value);
                                        setValFirst(event.target.name, event.target.value);
                                      }
                                    }  
                                  />
                            </Grid>

                            <Grid item xs={2}>
                                <label style={{fontFamily: 'Poppins', fontWeight: 400}}>บัตรประชาชน</label>
                                <br/>
                                  <TextField
                                      id="margin-none"
                                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                      type="text"
                                      // value={ pid || pid_api } 
                                      value={ pid } 
                                      name="pid"
                                      onChange={(event) =>{
                                        setPid(event.target.value);
                                        setValFirst(event.target.name, event.target.value);
                                      }
                                    }   
                                  />
                            </Grid>
                            
                            <Grid item xs={2}>
                              <label style={{fontFamily: 'Poppins', fontWeight: 400}}>อายุ</label>
                                <br/>
                                  <TextField
                                      id="margin-none"
                                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                      type="text"
                                      value={ age } 
                                      name="age" 
                                      onChange={(event) =>{
                                        setAge(event.target.value);
                                        setValFirst(event.target.name, event.target.value);
                                      }
                                    }   
                                  />
                            </Grid>
                            <Grid item xs={2}>
                              <label style={{fontSize: '16px', fontWeight: 400}}>เพศ</label>
                                <br/>
                                  <TextField
                                      id="margin-none"
                                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                      type="text"
                                      name = "gender"
                                      value={ gender } 
                                      onChange={(event) =>{
                                        setGender(event.target.value);
                                        setValFirst(event.target.name, event.target.value);
                                      }
                                    } 
                                  />
                            </Grid>
                            <Grid item xs={1} style={{paddingRight:'10px'}}>
                              <label style={{fontSize: '16px', fontWeight: 400}}>สิทธิการรักษา</label>
                                <br/>
                                  <TextField
                                      id="margin-none"
                                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                      type="text"
                                      fullWidth
                                      // value={ right_a || right_a_api } 
                                      value={ right_a } 
                                      name="right_a"
                                      onChange={(event) =>{
                                        setRight_a(event.target.value);
                                        setValFirst(event.target.name, event.target.value);
                                      }
                                    } 
                                  />
                            </Grid>
                            

                        </Grid>

                        <br/>
                        <Grid container>

                            <Grid item xs = {4} style={{paddingRight:'20px'}}>
                              <label style={{fontFamily: 'Poppins', fontWeight: 400}}>เบอร์โทร</label>
                                <br/>
                                  <TextField
                                      id="margin-none"
                                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                      type="text"
                                      fullWidth
                                      // value={ phone_number || result_data?.user?.phone_number } 
                                      value={ phone_number } 
                                      name="phone_number"
                                      onChange={(event) =>{
                                        setPhone_number(event.target.value);
                                        setValFirst(event.target.name, event.target.value);
                                      }
                                    } 
                                  />
                            </Grid>
                            <Grid item xs = {4} style={{paddingRight:'20px'}}>
                              <label style={{fontFamily: 'Poppins', fontWeight: 400}}>วันเดือนปีเกิด</label>
                                <br/>
                                  <TextField
                                      id="margin-none"
                                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                      type="text"
                                      fullWidth
                                      // value={ date_of_birth || moment(date_of_birth_api).add(543, 'year').format('DD/MM/YYYY') } 
                                      value={ date_of_birth } 

                                      name="date_of_birth"
                                      onChange={(event) =>{
                                        setDate_of_birth(event.target.value);
                                        setValFirst(event.target.name, event.target.value);
                                      }
                                    } 
                                  />
                            </Grid>

                            <Grid item xs = {4} style={{paddingRight:'10px'}}>
                              <label style={{fontFamily: 'Poppins', fontWeight: 400}}>ID Line (ถ้ามี)</label>
                                <br/>
                                  <TextField
                                      id="margin-none"
                                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                      type="text"
                                      fullWidth
                                      // value={ id_line || id_line_api } 
                                      value={ id_line } 
                                      name="id_line"
                                      onChange={(event) =>{
                                        setId_line(event.target.value);
                                        setValFirst(event.target.name, event.target.value);
                                      }
                                    }  
                                  />
                            </Grid>
                      
                        </Grid>
                        <br/>

                        <Grid container>
                            <Grid item xs = {12} style={{paddingRight:'20px'}}>
                              <label style={{fontFamily: 'Poppins', fontWeight: 400}}>ที่อยู่ปัจจุบัน</label>
                                <br/>
                                  <TextField
                                      id="margin-none"
                                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                      type="text"
                                      fullWidth
                                      // value={ address || address_api } 
                                      value={ address } 
                                      name="address"
                                      onChange={(event) =>{
                                        setAddress(event.target.value);
                                        setValFirst(event.target.name, event.target.value);
                                      }
                                    }  
                                  />
                            </Grid>
                          </Grid>

                        <br/>
                        <Grid container>
                            <Grid item xs = {4} style={{paddingRight:'20px'}}>
                              <label style={{fontFamily: 'Poppins', fontWeight: 400}}>อาการสําคัญ</label>
                                <br/>
                                  <TextField
                                      id="margin-none"
                                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                      type="text"
                                      fullWidth
                                      // value={ symptom || symptom_api }
                                      value={ symptom } 
                                      name="symptom"
                                      onChange={(event) =>{
                                        setSymptom(event.target.value);
                                        setValFirst(event.target.name, event.target.value);
                                      }
                                    } 
                                  />
                            </Grid>

                            <Grid item xs = {4} style={{paddingRight:'20px'}}>
                              <label style={{fontFamily: 'Poppins', fontWeight: 400}}>ผู้บันทึกข้อมูล (พยาบาล/แพทย์)</label>
                                <br/>
                                  <TextField
                                      id="margin-none"
                                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                      type="text"
                                      fullWidth
                                      // value={ recorder || recorder_api } 
                                      value={ recorder } 
                                      name="recorder"
                                      onChange={(event) =>{
                                        setRecorder(event.target.value);
                                        setValFirst(event.target.name, event.target.value);
                                      }
                                    }  
                                  />
                            </Grid>

                            <Grid item xs = {4} style={{paddingRight:'10px'}}>
                              <label style={{fontFamily: 'Poppins', fontWeight: 400}}>เลขใบประกอบวิชาชีพ</label>
                                <br/>
                                  <TextField
                                      id="margin-none"
                                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                      type="text"
                                      fullWidth
                                      // value={ recorder_code || recorder_code_api } 
                                      value={ recorder_code } 
                                      name="recorder_code"
                                      onChange={(event) =>{
                                        setRecorder_code(event.target.value);
                                        setValFirst(event.target.name, event.target.value);
                                      }
                                    } 
                                  />
                            </Grid>

                        </Grid>

                        <br/>
                        <br/>
                        <Grid container>
                          <Grid item xs={12} style={{display: 'flex'}}>
                              <Table  style={{border: '1px solid grey', boderBottom: '1px solid grey'}}>
                                <Tbody>
                                  <Tr>
                                    <Th className={classes.tableRightBorder} style={{ padding: '20px' }}>
                                      การซักประวัติเพื่ออาการแรกรับ
                                    </Th>
                                    <Th className={classes.tableRightBorder} style={{ padding: '20px' }}>
                                      ตรวจร่างกายแรกรับ
                                    </Th>
                                    <Th className={classes.tableRightBorder} style={{ padding: '20px' }}>
                                      คําสั่งการรักษา
                                    </Th>
                                  </Tr>
                                
                                  <Tr>
                                    <Td className={classes.tableRightBorder} style={{ padding: '10px' }}>
                                        <Box style={{fontFamily: 'Poppins',  textAlign: 'center', paddingBottom: '15px' }}>
                                          อาการไม่รุนแรง
                                        </Box>
                                          <Checkbox  
                                            color="primary"
                                            size="small"
                                            checked={ no_symptom }  
                                            style={{padding: "4px"}}
                                            name="no_symptom"
                                            onChange={(event) =>{
                                                setNo_symptom(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            } 
                                          />
                                          ไม่แสดงอาการ
                                          <br/>
                                          <Checkbox  
                                            color="primary"
                                            size="small"
                                            checked={ fever_low }   
                                            style={{padding: "4px"}}
                                            name="fever_low"
                                            onChange={(event) =>{
                                                setFever_low(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            } 
                                          />
                                          ไข้ อุณหภูมิต่ำกว่า 38 องศาเซลเซียส
                                          <br/>
                                          <Checkbox  
                                            color="primary"
                                            size="small"
                                            checked={ cough }  
                                            style={{padding: "4px"}}
                                            name="cough"
                                            onChange={(event) =>{
                                                setCough(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            } 
                                          />
                                          ไอเล็กน้อย ระคายคอ หรือเจ็บคอ
                                          <br/>
                                          <Checkbox  
                                            color="primary"
                                            size="small"
                                            checked={ snot } 
                                            style={{padding: "4px"}}
                                            name="snot"
                                            onChange={(event) =>{
                                                setSnot(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            } 
                                          />
                                          มีน้ำมูก คัดจมูก จาม
                                          <br/>
                                          <br/>

                                          <Box  style={{ textAlign: 'center', paddingBottom: '15px', borderTop: '1px solid', paddingTop: '15px' }}>
                                            อาการสําคัญที่บ่งว่าน่าจะเกิดอาการรุนแรง
                                          </Box>
                                          <Checkbox  
                                              style={{padding: "4px"}}
                                              color="primary"
                                              size="small"
                                              checked={ severe_cough } 
                                              name="severe_cough"
                                              onChange={(event) =>{
                                                  setSevere_cough(event?.target.checked);
                                                  setValFirst(event.target.name, event.target.checked);
                                                }
                                              } 
                                              
                                            />
                                            ไอเยอะ (Severe cough) 
                                            <br/>
                                            <Checkbox  
                                              style={{padding: "4px"}}
                                              color="primary"
                                              size="small"
                                              checked={ chest_tightness }
                                              name="chest_tightness"
                                              onChange={(event) =>{
                                                  setChest_tightness(event?.target.checked);
                                                  setValFirst(event.target.name, event.target.checked);
                                                }
                                              }  
                                              
                                            />
                                            แน่นหน้าอก (chest tightness) 
                                            <br/>
                                            <Checkbox  
                                              style={{padding: "4px"}}
                                              color="primary"
                                              size="small"
                                              checked={ poor_appetite || (result_data?.symptoms.risk_symptoms?.poor_appetite == true ? true : false)} 
                                              name="poor_appetite"
                                              onChange={(event) =>{
                                                  setPoor_appetite(event?.target.checked);
                                                  setValFirst(event.target.name, event.target.checked);
                                                }
                                              }  
                                              
                                            />
                                            ทานอาหารไม่ได้ (poor appetite) 
                                            <br/>
                                            <Checkbox  
                                              style={{padding: "4px"}}
                                              color="primary"
                                              size="small"
                                              checked={ fatigue || (result_data?.symptoms.risk_symptoms?.fatigue == true ? true : false)}  
                                              name="fatigue"
                                              onChange={(event) =>{
                                                  setFatigue(event?.target.checked);
                                                  setValFirst(event.target.name, event.target.checked);
                                                }
                                              } 
                                              
                                            />
                                            อ่อนเพลียมาก (fatigue) 
                                            <br/>
                                            <Checkbox  
                                              style={{padding: "4px"}}
                                              color="primary"
                                              size="small"
                                              checked={ fever || (result_data?.symptoms.risk_symptoms?.fever == true ? true : false)} 
                                              name="fever"
                                              onChange={(event) =>{
                                                  setFever(event?.target.checked);
                                                  setValFirst(event.target.name, event.target.checked);
                                                }
                                              } 
                                              
                                            />
                                            ไข้ตลอดทุกวันในช่วงที่มีอาการ 
                                            <br/>
                                            <Checkbox  
                                              style={{padding: "4px"}}
                                              color="primary"
                                              size="small"
                                              checked={ liquid_stool }
                                              name="liquid_stool"
                                              onChange={(event) =>{
                                                  setLiquid_stool(event?.target.checked);
                                                  setValFirst(event.target.name, event.target.checked);
                                                }
                                              }
                                            />
                                              ถ่ายเหลวมากกว่า 3 ครั้ง/วัน
                                            <br/>
                                            <Checkbox  
                                              style={{padding: "4px"}}
                                              color="primary"
                                              size="small"
                                              checked={ puke } 
                                              name="puke"
                                              onChange={(event) =>{
                                                  setPuke(event?.target.checked);
                                                  setValFirst(event.target.name, event.target.checked);
                                                }
                                              }
                                            />
                                              อาเจียน
                                            <br/>
                                            <br/>

                                            <Box  style={{ textAlign: 'center', paddingBottom: '15px', borderTop: '1px solid', paddingTop: '15px' }}>
                                              ปัจจัยเสี่ยงต่อการเกิดอาการรุนแรง
                                            </Box>
                                            <Checkbox  
                                              style={{padding: "4px"}}
                                              color="primary"
                                              size="small"
                                              checked={ age_risk_factor || (result_data?.symptoms.risk_symptoms?.age_risk_factor == true ? true : false) } 
                                              name="risk_factor_age"
                                              onChange={(event) =>{
                                                  setAge_risk_factor(event?.target.checked);
                                                  setValFirst(event.target.name, event.target.checked);
                                                }
                                              }
                                              
                                            />
                                            อายุน้อยกว่า 1 ปี หรือ มากกว่า 60 ปี
                                            <br/>
                                            
                                            <Checkbox  
                                              style={{padding: "4px"}}
                                              color="primary"
                                              size="small"
                                              checked={ copd } 
                                              name="copd"
                                              onChange={(event) =>{
                                                  setCopd(event?.target.checked);
                                                  setValFirst(event.target.name, event.target.checked);
                                                }
                                              }
                                              
                                            />
                                            COPD, include chronic lung disease 
                                            <br/>
                                            <Checkbox  
                                              style={{padding: "4px"}}
                                              color="primary"
                                              size="small"
                                              checked={ ckd }  
                                              name="ckd"
                                              onChange={(event) =>{
                                                  setCkd(event?.target.checked);
                                                  setValFirst(event.target.name, event.target.checked);
                                                }
                                              }
                                            />
                                              CKD (โรคไตวายเรื้อรัง)
                                            <br/>
                                            <Checkbox  
                                              style={{padding: "4px"}}
                                              color="primary"
                                              size="small"
                                              checked={ chronic_heart_disease } 
                                              name="chronic_heart_disease"
                                              onChange={(event) =>{
                                                  setChronic_heart_disease(event?.target.checked);
                                                  setValFirst(event.target.name, event.target.checked);
                                                }
                                              }
                                            />
                                            Chronic Heart disease 

                                            <br/>
                                            <Checkbox  
                                              style={{padding: "4px"}}
                                              color="primary"
                                              size="small"
                                              checked={(result_data?.symptoms.risk_symptoms?.cva == true ? true : false) || cva} 
                                              name="cva"
                                              onChange={(event) =>{
                                                  setCva(event?.target.checked);
                                                  setValFirst(event.target.name, event.target.checked);
                                                }
                                              }
                                            />
                                            CVA (โรคหลอดเลือดสมอง) 
                                            <br/>
                                            <Checkbox  
                                              style={{padding: "4px"}}
                                              color="primary"
                                              size="small"
                                              checked={(result_data?.symptoms.risk_symptoms?.t2dm == true ? true : false) || t2dm } 
                                              name="t2dm"
                                              onChange={(event) =>{
                                                  setT2dm(event?.target.checked);
                                                  setValFirst(event.target.name, event.target.checked);
                                                }
                                              }
                                            />
                                            T2DM
                                            <br/>
                                            <Checkbox  
                                              style={{padding: "4px"}}
                                              color="primary"
                                              size="small"
                                              checked={(result_data?.symptoms.risk_symptoms?.bmi == true ? true : false) || bmi } 
                                              name="bmi"
                                              onChange={(event) =>{
                                                  setBmi(event?.target.checked);
                                                  setValFirst(event.target.name, event.target.checked);
                                                }
                                              }
                                              
                                            />
                                            {"BMI > 30 or BW > 90 kg"}
                                            <br/>
                                            <Checkbox  
                                              style={{padding: "4px"}}
                                              color="primary"
                                              size="small"
                                              checked={(result_data?.symptoms.risk_symptoms?.cirrhosis == true ? true : false) || cirrhosis}  
                                              name="cirrhosis"
                                              onChange={(event) =>{
                                                  setCirrhosis(event?.target.checked);
                                                  setValFirst(event.target.name, event.target.checked);
                                                }
                                              }
                                              
                                            />
                                            Cirrhosis (โรคตับแข็ง) 
                                            <br/>
                                            <Checkbox  
                                              style={{padding: "4px"}}
                                              color="primary"
                                              size="small"
                                              checked={ (result_data?.symptoms.risk_symptoms?.immunocompromise == true ? true : false) || immunocompromise }  
                                              name="immunocompromise"
                                              onChange={(event) =>{
                                                  setImmunocompromise(event?.target.checked);
                                                  setValFirst(event.target.name, event.target.checked);
                                                }
                                              }
                                              
                                            />
                                            Immunocompromise (ภาวะภูมิคุ้มกันต่ำ)
                                            <br/>
                                            <Checkbox  
                                              style={{padding: "4px"}}
                                              color="primary"
                                              size="small"
                                              checked={ (result_data?.symptoms.risk_symptoms?.absolute_lymphocyte == true ? true : false) || absolute_lymphocyte}  
                                              name="absolute_lymphocyte"
                                              onChange={(event) =>{
                                                  setAbsolute_lymphocyte(event?.target.checked);
                                                  setValFirst(event.target.name, event.target.checked);
                                                }
                                              }
                                              
                                            />
                                            {"Absolute lymphocyte < 1000 cell/mm3"}
                                            <br/>

                                            <br/>

                                            <Box  style={{ textAlign: 'center', paddingBottom: '15px', borderTop: '1px solid', paddingTop: '15px' }}>
                                              การประเมินสภาพจิตใจและภาวะซึมเศร้า
                                            </Box>
                                          
                                            <Checkbox  
                                              style={{padding: "4px"}}
                                              color="primary"
                                              size="small"
                                              checked={(result_data?.symptoms.mental?.feeling == true ? true : false) || feeling} 
                                              name="feeling"
                                              onChange={(event) =>{
                                                  setFeeling(event?.target.checked);
                                                  setValFirst(event.target.name, event.target.checked);
                                                }
                                              }
                                              
                                            />
                                            สภาพจิตใจ
                                            <TextField
                                                id="margin-none"
                                                type="text"
                                                style={{ marginTop: '-10px' }}
                                                name="feeling"
                                                
                                            />
                                            <br/>
                                            <Checkbox  
                                              style={{padding: "4px"}}
                                              color="primary"
                                              size="small"
                                              checked={(result_data?.symptoms.mental?.is_effective == true ? true : false) || is_effective} 
                                              name="is_effective"
                                              onChange={(event) =>{
                                                  setIs_effective(event?.target.checked);
                                                  setValFirst(event.target.name, event.target.checked);
                                                }
                                              }
                                            />
                                            ผลกระทบของความเจ็บป่วยต่อชีวิตประจําวัน
                                            <br/>
                                            
                                            <span style={{paddingLeft:'10px'}}>ท้อแท้สิ้นหวังหรือไม่ : </span>
                                            <RadioGroup row aria-label="mental_one" name="mental_one" value={mental_one} onChange={handleChange_mental_one}>
                                              <FormControlLabel value="true" control={<Radio style={{paddingTop: '0px', paddingBottom: '0px'}} color="primary" size="small"/>} label="ใช่" />
                                              <FormControlLabel value="false" control={<Radio style={{paddingTop: '0px', paddingBottom: '0px'}} color="primary" size="small"/>} label="ไม่ใช่" />
                                            </RadioGroup>
                                            
                                            <span style={{paddingLeft:'10px'}}>ใน 2 สัปดาห์ที่ผ่านมารวมวันนี้ท่านรู้สึก เบื่อ ทําอะไรก็ไม่ เพลิดเพลินหรือไม่  : </span>

                                            <RadioGroup row aria-label="mental_two" name="mental_two" value={mental_two} onChange={handleChange_mental_two}>
                                              <FormControlLabel value="true" control={<Radio style={{paddingTop: '0px', paddingBottom: '0px'}} color="primary" size="small"/>} label="ใช่" />
                                              <FormControlLabel value="false" control={<Radio style={{paddingTop: '0px', paddingBottom: '0px'}} color="primary" size="small" />} label="ไม่ใช่" />
                                            </RadioGroup>
                                            
                                            
                                            {(localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ? 
                                            <>
                                              <span style={{paddingLeft:'10px'}}>ได้รับวัคซีนโควิด-19หรือไม่  : </span>
                                              <RadioGroup row aria-label="get_vaccine" name="get_vaccine" value={get_vaccine} onChange={handleChange_get_vaccine}>
                                                <FormControlLabel value="true" control={<Radio style={{paddingTop: '0px', paddingBottom: '0px'}} color="primary" size="small" />} label="ใช่" />
                                                <FormControlLabel value="false" control={<Radio style={{paddingTop: '0px', paddingBottom: '0px'}}   color="primary" size="small" />} label="ไม่ใช่" />
                                              </RadioGroup> 
                                            </> : ""
                                            )}
                                            
                                    </Td>
                                      <Td className={classes.tableRightBorder} style={{ padding: '10px' }}>
                                        <Grid container>
                                          <Grid item xs={6}> 
                                          <label>น้ําหนัก(Kg)</label>
                                              <TextField
                                                  id="margin-none"
                                                  type="text"
                                                  fullWidth
                                                  style={{ paddingRight: '25px'}}
                                                  value={ weight || weight_api }
                                                  name="weight"
                                                  onChange={(event) =>{
                                                      setWeight(event.target.value);
                                                      setValFirst(event.target.name, event.target.value);
                                                    }
                                                  } 
                                              />
                                              
                                          </Grid>
                                          <Grid item xs={6}> 
                                          <label>ส่วนสูง(cm)</label>
                                              <TextField
                                                  id="margin-none"
                                                  type="text"
                                                  fullWidth
                                                  style={{ paddingRight: '15px'}}
                                                  value = { height }
                                                  // value={ height || height_api }
                                                  name="height"
                                                  onChange={(event) =>{
                                                      setHeight(event.target.value);
                                                      setValFirst(event.target.name, event.target.value);
                                                    }
                                                  } 
                                              />
                                              
                                          </Grid>
                                        </Grid>
                                        <Grid container>
                                          <Grid item xs={6}> 
                                          <label>BT(⁰ C)</label>
                                              <TextField
                                                  id="margin-none"
                                                  type="text"
                                                  fullWidth
                                                  style={{ paddingRight: '25px'}}
                                                  value={ body_temp_first }
                                                  // value={ body_temp_first || body_temp_api }
                                                  name="body_temp"
                                                  onChange={(event) =>{
                                                      setBody_temp_first(event.target.value);
                                                      setValFirst(event.target.name, event.target.value);
                                                    }
                                                  } 
                                                  
                                                  
                                              />
                                              
                                          </Grid>
                                          
                                          <Grid item xs={6}> 
                                          <label>PR(/min)</label>
                                              <TextField
                                                  id="margin-none"
                                                  type="text"
                                                  fullWidth
                                                  style={{ paddingRight: '15px'}}
                                                  value={ pulse_first }
                                                  // value={ pulse_first || pulse_api }
                                                  name="pulse"
                                                  onChange={(event) =>{
                                                      setPulse_first(event.target.value);
                                                      setValFirst(event.target.name, event.target.value);
                                                    }
                                                  } 
                                                  
                                                  
                                              />
                                              
                                          </Grid>
                                        </Grid>

                                        <Grid container>
                                          <Grid item xs={6}> 
                                          <label>RR(/min)</label>
                                            <br/>
                                              <TextField
                                                  id="margin-none"
                                                  type="text"
                                                  fullWidth
                                                  style={{ paddingRight: '25px'}}
                                                  value={ respiration_rate_first  }
                                                  // value={ respiration_rate_first || respiration_rate_api }
                                                  name="respiration_rate"
                                                  onChange={(event) =>{
                                                      setRespiration_rate_first(event.target.value);
                                                      setValFirst(event.target.name, event.target.value);
                                                    }
                                                  } 
                                              />
                                              
                                          </Grid>
                                          
                                          <Grid item xs={6}> 
                                          <label>BP(mmHg)</label>
                                              <TextField
                                                id="margin-none"
                                                type="text"
                                                fullWidth
                                                style={{ paddingRight: '15px'}}
                                                value={ blood_pressure || blood_pressure_api }
                                                name="blood_pressure"
                                                onChange={(event) =>{
                                                    setBlood_pressure(event.target.value);
                                                    setValFirst(event.target.name, event.target.value);
                                                  }
                                                } 
                                              />
                                              
                                          </Grid>
                                          <br/>
                                          <br/>
                                          <br/>
                                          <Grid item xs={12}> 
                                          <label style={{fontWeight: 400, }}>ประจําเดือนครั้งสุดท้าย (LPM)</label>
                                            <br/>
                                              <TextField
                                                  id="margin-none"
                                                  type="text"
                                                  fullWidth
                                                  style={{ paddingRight: '15px'}}
                                                  value={ lmp || lmp_api }
                                                  name="lmp"
                                                  onChange={(event) =>{
                                                      setLmp(event.target.value);
                                                      setValFirst(event.target.name, event.target.value);
                                                    }
                                                  }  
                                              />
                                              
                                          </Grid>
                                        </Grid>
                                        <br/>
                                        <br/>
                                        <Box  style={{ textAlign: 'center', paddingBottom: '15px', borderTop: '1px solid', paddingTop: '15px' }}>
                                          Test Exercise Induce Hypoxia test
                                        </Box>
                                        <Grid container>
                                          <Grid item xs={6}> 
                                            <label style={{fontSize: '16px', fontWeight: 400}}>O2 sat% (ก่อน)</label>
                                            <br/>
                                              <TextField
                                                  id="margin-none"
                                                  type="text"
                                                  fullWidth
                                                  style={{ paddingRight: '25px'}}
                                                  value={ oxygen_before || oxygen_before_api }
                                                  name="oxygen_before"
                                                  onChange={(event) =>{
                                                      setOxygen_before(event.target.value);
                                                      setValFirst(event.target.name, event.target.value);
                                                    }
                                                  } 
                                                  
                                                  
                                              />
                                          </Grid>
                                              
                                          <Grid item xs={6}> 
                                            <label style={{fontSize: '16px', fontWeight: 400}}>O2 sat% (หลัง)</label>
                                            <br/>
                                            <TextField
                                                id="margin-none"
                                                type="text"
                                                fullWidth
                                                style={{ paddingRight: '15px'}}
                                                value={ oxygen_after || oxygen_after_api }
                                                name="oxygen_after"
                                                onChange={(event) =>{
                                                    setOxygen_after(event.target.value);
                                                    setValFirst(event.target.name, event.target.value);
                                                  }
                                                } 
                                            />
                                            
                                          </Grid>
                                        </Grid>
                                        <Grid container> 
                                          <Grid item xs={12}>
                                            <br/>
                                            <span style={{paddingLeft:'10px'}}>{"Test ให้ผลบวก(spO2 drop >=3) "} : </span>
                                            <RadioGroup style={{flexDirection: 'inherit'}} aria-label="vaccine" name="vaccine" value={is_positive} onChange={handleChange_is_positive}>
                                              <FormControlLabel value="true" control={<Radio color="primary" size="small" />} label="ใช่" />
                                              <FormControlLabel value="false" control={<Radio color="primary" size="small" />} label="ไม่ใช่" />
                                            </RadioGroup>
                                          </Grid>
                                        </Grid>
                                        <br/>
                                        <Box  style={{ textAlign: 'center', paddingBottom: '15px', borderTop: '1px solid', paddingTop: '15px' }}>
                                          ผล Lab
                                        </Box>
                                        <Grid container> 
                                          <Grid item xs={12}>
                                            <span style={{paddingLeft:'10px'}}>Chest X-ray : </span>
                                            <RadioGroup style={{flexDirection: 'inherit'}} aria-label="is_xray" name="is_xray" value={is_xray} onChange={handleChange_is_xray}>
                                              <FormControlLabel value="true" control={<Radio color="primary" size="small" />} label="ใช่" />
                                              <FormControlLabel value="false" control={<Radio color="primary" size="small" />} label="ไม่ใช่" />
                                            </RadioGroup>
                                          </Grid>
                                        </Grid>

                                        {(is_xray == "true" ? 
                                          <Grid container> 
                                            <Grid item xs={12}>
                                              <span style={{paddingLeft:'10px'}}>ถ้ามีผล : </span>
                                              <RadioGroup style={{flexDirection: 'inherit'}} aria-label="is_normal" name="is_normal" value={is_normal} onChange={handleChange_is_normal}>
                                                <FormControlLabel value="true" control={<Radio color="primary" size="small" />} label="ปกติ" />
                                                <FormControlLabel value="false" control={<Radio color="primary" size="small" />} label="ผิดปกติ" />
                                              </RadioGroup>
                                            </Grid>
                                            {(is_normal == "false" ? 
                                                <Grid item xs={12}> 
                                                <label style={{fontSize: '16px', fontWeight: 400, }}>ระบุอาการ</label>
                                                <br/>
                                                  <TextField
                                                    id="margin-none"
                                                    type="text"
                                                    fullWidth
                                                    name="result"
                                                    style={{ paddingRight: '15px'}}
                                                    value={ result }
                                                    onChange={(event) =>{
                                                        setResult(event.target.value);
                                                        setValFirst(event.target.name, event.target.value);
                                                      }
                                                    } 
                                                  />
                                                  
                                                </Grid> : ""
                                              )}
                                          </Grid> 
                                          : ""
                                        )}
                                        <br/>
                                        <Box  style={{ textAlign: 'center', paddingBottom: '15px', borderTop: '1px solid', paddingTop: '15px' }}>
                                          ผลตรวจคัดกรอง
                                        </Box>
                                        {
                                          localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                                          <>
                                              <RadioGroup style={{flexDirection: 'inherit'}} aria-label="is_rapid_test" name="is_rapid_test" value={is_rapid_test} onChange={handleChange_is_rapid_test}>
                                                <FormControlLabel value="true" control={<Radio color="primary" size="small" />} label="ATK" />
                                              </RadioGroup>
                                          </>
                                          :
                                          <>
                                              <RadioGroup style={{flexDirection: 'inherit'}} aria-label="is_rapid_test" name="is_rapid_test" value={is_rapid_test} onChange={handleChange_is_rapid_test}>
                                                <FormControlLabel value="true" control={<Radio color="primary" size="small" />} label="ATK" />
                                              </RadioGroup>
                                          </>
                                        }
                                        
                                          {(is_rapid_test == "true" ? 
                                            <Grid container>
                                            <Grid item xs={12}> 
                                            <label style={{fontSize: '16px', fontWeight: 400}}>วันที่ตรวจ</label>
                                              <br/>
                                              <TextField
                                                  id="margin-none"
                                                  type="text"
                                                  fullWidth
                                                  style={{ paddingRight: '25px'}}
                                                  // name="rapid_date"
                                                  name="date_result"
                                                  value = { date_result }
                                                  // value={ (rapid_date == "" ? date_admitted_api : rapid_date) }
                                                  onChange={(event) =>{
                                                    setDate_result(event.target.value);
                                                      // setRapid_date(event.target.value);
                                                      setValFirst(event.target.name, event.target.value);
                                                    }
                                                  } 
                                                />
                                                
                                            </Grid>
                                            
                                            <Grid item xs={12}> 
                                            <label style={{fontSize: '16px', fontWeight: 400, }}>หน่วยที่คัดกรอง</label>
                                              <br/>
                                                <TextField
                                                  id="margin-none"
                                                  type="text"
                                                  fullWidth
                                                  style={{ paddingRight: '15px'}}
                                                  name="rapid_result"
                                                  value={ rapid_result }
                                                  onChange={(event) =>{
                                                      setRapid_result(event.target.value);
                                                      setValFirst(event.target.name, event.target.value);
                                                    }
                                                  } 
                                                />
                                                
                                            </Grid>
                                            </Grid> : ""
                                          )}
                                      
                                        <br/>
                                        <RadioGroup aria-label="is_rtpcr_test" name="is_rtpcr_test" value={is_rtpcr_test} onChange={handleChange_is_rtpcr_test}>
                                          <FormControlLabel value="true" control={<Radio color="primary" size="small" />} label="RT-PCR" />
                                        </RadioGroup>

                                        {(is_rtpcr_test == "true" ? 
                                          <Grid container>
                                            <Grid item xs={12}> 
                                              <label style={{fontSize: '16px', fontWeight: 400}}>ผลตรวจ</label>
                                                
                                                  <TextField
                                                    id="margin-none"
                                                    type="text"
                                                    fullWidth
                                                    style={{ paddingRight: '25px'}}
                                                    name="rtpcr_result"
                                                    value={ rtpcr_result }
                                                    onChange={(event) =>{
                                                        setRtpcr_result(event.target.value);
                                                        setValFirst(event.target.name, event.target.value);
                                                      }
                                                    } 
                                                  />
                                                  
                                              </Grid>
                                              <Grid item xs={12}> 
                                              <label style={{fontSize: '16px', fontWeight: 400}}>วันที่ตรวจ</label>
                                                
                                                  <TextField
                                                      id="margin-none"
                                                      type="text"
                                                      fullWidth
                                                      style={{ paddingRight: '25px'}}
                                                      // name="rtpcr_date"
                                                      name="date_result"
                                                      value = { date_result }
                                                      // value={ (rtpcr_date == "" ? date_admitted_api : rapid_date) }
                                                      onChange={(event) =>{
                                                        setDate_result(event.target.value);
                                                          // setRtpcr_date(event.target.value);
                                                          setValFirst(event.target.name, event.target.value);
                                                        }
                                                      } 
                                                  />
                                                  
                                              </Grid>
                                              
                                              <Grid item xs={12}> 
                                              <label style={{fontSize: '16px', fontWeight: 400, }}>หน่วยที่คัดกรอง</label>
                                                
                                                  <TextField
                                                    id="margin-none"
                                                    type="text"
                                                    fullWidth
                                                    style={{ paddingRight: '15px'}}
                                                    name="rtpcr_place"
                                                      value={ rtpcr_place }
                                                      onChange={(event) =>{
                                                          setRtpcr_place(event.target.value);
                                                          setValFirst(event.target.name, event.target.value);
                                                        }
                                                      } 
                                                      
                                                  />
                                                  
                                              </Grid>
                                            </Grid> : ""
                                        )}
                                        <br/>
                                        <br/>
                                        <Box  style={{ textAlign: 'center', paddingBottom: '15px', borderTop: '1px solid', paddingTop: '15px' }}>
                                        การเตรียมอุปกรณ์และยา
                                        </Box>
                                        <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={(result_data?.health_checked?.medicine_prepare?.thermometer == true ? true : false)}
                                            
                                          /><span>ปรอทวัดไข้</span>
                                          <br/>
                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={(result_data?.health_checked?.medicine_prepare?.inhaler == true ? true : false)}
                                            
                                          /><span>เครื่องวัด O2 ปลายนิ้ว </span>
                                          <br/>
                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={(result_data?.health_checked?.medicine_prepare?.oximeter == true ? true : false)}
                                            
                                          /><span>เครื่องผลิตออกซิเจน</span>
                                          <br/>
                                          {
                                            localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                                            <>
                                                <Checkbox  
                                                  style={{padding: "4px"}}
                                                  color="primary"
                                                  size="small"
                                                  checked
                                                  
                                                /><span>ถุงแดง</span>
                                            </>
                                            :
                                              <>
                                                <Checkbox  
                                                  style={{padding: "4px"}}
                                                  color="primary"
                                                  size="small"
                                                  checked={(result_data?.health_checked?.medicine_prepare?.medicine == true ? true : false)}
                                                  
                                                /><span>ถุงแดง</span>
                                            </>
                                          }
                                          
                                          <br/>
                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={(result_data?.health_checked?.medicine_prepare?.oxygen_concentrator == true ? true : false)}
                                            
                                          /><span>เครื่องพ่นยา</span>
                                          <br/>
                                        
                                        {
                                          localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                                          <>
                                              <Checkbox  
                                                style={{padding: "4px"}}
                                                color="primary"
                                                size="small"
                                                checked
                                                
                                              /><span>ยา</span>
                                          </>
                                          :
                                          <>
                                            <Checkbox  
                                              style={{padding: "4px"}}
                                              color="primary"
                                              size="small"
                                              checked={ mask } 
                                              
                                            /><span>ยา</span>
                                          </>
                                        }
                                          
                                          <br/>
                                          
                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={(result_data?.health_checked?.medicine_prepare?.autoclave_bag == true ? true : false)}  
                                            
                                          /><span>หน้ากากอนามัย</span>
                                          <br/>
                                      </Td>
                                      <Td className={classes.tableRightBorder} style={{ padding: '10px' }}>
                                      
                                      <Box style={{ textAlign: "center", paddingBottom: "15px" }}>
                                        <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          checked={(result_data?.treatment_orders?.level_one?.green == true ? true : false)}  
                                          
                                        />
                                        Level 1 (green) 
                                        <br/>
                                      </Box>
                                        
                                      <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          checked={(result_data?.treatment_orders?.level_one?.paracetamol == true ? true : false)}   
                                          
                                        />
                                        Paracetamol (500) 1 tab po prn q 6 hrs for fever # 20 tabs
                                        <br/>
                                        <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          checked={(result_data?.treatment_orders?.level_one?.cetirizine == true ? true : false)} 
                                          
                                        />
                                        Cetirizine 1 tab po OD pc #10 tabs

                                        <br/>
                                        <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          checked={(result_data?.treatment_orders?.level_one?.bromhexine == true ? true : false)}  
                                          
                                        />
                                        Bromhexine (8 mg) 1 tab po tid pc #10 tabs
                                        <br/>
                                        <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          checked={(result_data?.treatment_orders?.level_one?.cpm == true ? true : false)}   
                                          
                                        />
                                        CPM 1 tab po tid pc # 10 tabs

                                        <br/>
                                        

                                        <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          checked={(result_data?.treatment_orders?.level_one?.indian_gooseberry == true ? true : false)}
                                          
                                        />
                                        ยาแก้ไอมะขามป้อม
                                        <br/>
                                        
                                        {(result_data?.treatment_orders?.level_one?.is_patient_medicines == true && result_data?.treatment_orders.level_one?.patient_medicines != "" ? 
                                        <Grid item xs={12}> 
                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={(result_data?.treatment_orders?.level_one?.is_patient_medicines == true ? true : false)}
                                            
                                          />
                                          ยาคนไข้ที่จําเป็นต้องส่ังเพิ่ม
                                          <br/>
                                          <label style={{fontSize: '16px', fontWeight: 400, }}>ระบุยาคนไข้ที่จําเป็นต้องส่ังเพื่ม</label>
                                          <br/>
                                            <TextField
                                                id="margin-none"
                                                type="text"
                                                fullWidth
                                                style={{ paddingRight: '15px'}}
                                                value={result_data?.treatment_orders.level_one?.patient_medicines}
                                            />
                                            
                                          </Grid> : ""
                                        )}
                                        <br/>
                                        <br/>
                                        <Box  style={{ textAlign: 'center', paddingBottom: '15px', borderTop: '1px solid', paddingTop: '15px' }}>
                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={(result_data?.treatment_orders?.level_two?.yellow == true ? true : false)}  
                                            
                                          />
                                          Level 2 (yellow) 
                                          <br/>
                                        </Box>
                                        <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={(result_data?.treatment_orders?.level_two?.favipiravia == true ? true : false)}  
                                            
                                          />
                                          Favipiravia (200 mg/tab) 9 tab po bid pc day1, then 4 tabs po bid pc x 4 days 
                                          <br/>
                                          <br/>
                                          {"If BW > 90 kgs,"}
                                          <br/>
                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={(result_data?.treatment_orders?.level_two?.day_one == true ? true : false)}  
                                            
                                          />
                                          Day 1 : 12 tabs po bid pc

                                          <br/>
                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={(result_data?.treatment_orders?.level_two?.andrographis_paniculata == true ? true : false)}  
                                            
                                          />
                                          ฟ้าทะลายโจร

                                          <br/>
                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={(result_data?.treatment_orders?.level_two?.is_day_five == true ? true : false)}   
                                            
                                          />
                                          Day ( 5 tabs po bid pc)
                                          <br/>
                                          {(result_data?.treatment_orders?.level_two?.is_day_five == true ?
                                            <Grid item xs={12}> 
                                            <label style={{fontSize: '16px', fontWeight: 400, }}>ระบุข้อมูล</label>
                                            <br/>
                                              <TextField
                                                  id="margin-none"
                                                  type="text"
                                                  fullWidth
                                                  style={{ paddingRight: '15px'}}
                                                  value={result_data?.treatment_orders.level_two?.day_five}
                                              />
                                              
                                            </Grid> : ""
                                          )}
                                          <br/>
                                          <br/>
                                          <Box  style={{ textAlign: 'center', paddingBottom: '15px', borderTop: '1px solid', paddingTop: '15px' }}>
                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={(result_data?.treatment_orders?.level_three?.red == true ? true : false)}  
                                            
                                          />
                                          Level 3 (red) 
                                          <br/>
                                        </Box>
                                        <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={(result_data?.treatment_orders?.level_three?.is_refer_hospital == true ? true : false)} 
                                            
                                          />
                                          Refer รพ.
                                        
                                          <br/>

                                          {(result_data?.treatment_orders?.level_three?.is_refer_hospital == true ?
                                            <Grid item xs={12}> 
                                            <label style={{fontSize: '16px', fontWeight: 400, }}>ระบุข้อมูล</label>
                                            <br/>
                                              <TextField
                                                  id="margin-none"
                                                  type="text"
                                                  fullWidth
                                                  style={{ paddingRight: '15px'}}
                                                  value={result_data?.treatment_orders.level_three?.hospital}
                                                  // name="body_temp"
                                                  // onChange={(event) =>{
                                                  //     set_body_temp(parseFloat(event.target.value));
                                                  //     setVal(event.target.name, parseFloat(event.target.value));
                                                  //   }
                                                  // } 
                                                  
                                              />
                                              
                                            </Grid> : ""
                                          )}
                                          <br/>
                                          <br/>
                                          <Box  style={{ textAlign: 'center', paddingBottom: '15px', borderTop: '1px solid', paddingTop: '15px' }}>
                                            ผลการพิจารณาเบื้องต้น
                                          </Box>
                                        
                                        {
                                          localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                                          <>
                                            <Radio  
                                              color="primary"
                                              size="small"
                                              checked={ false } 
                                            />
                                              Home Isolation โดย รพ.สต.
                                          </>
                                          
                                          :
                                          <>
                                              <Radio  
                                                color="primary"
                                                size="small"
                                                checked={ is_hi_shph } 
                                              />
                                                Home Isolation โดย รพ.สต.
                                          </>
                                        }
                                          
                                          <br/>

                                          <Radio  
                                            color="primary"
                                            size="small"
                                            checked={(result_data?.treatment_orders?.level_three?.is_further_hospital == true ? true : false)} 
                                          />
                                            ส่งตรวจเพิ่มเติมที่โรงพยาบาล

                                          <br/>

                                          {(result_data?.treatment_orders?.level_three?.is_further_hospital == true ? 
                                            <Box style={{ paddingLeft: "15px" }}>
                                              <Radio  
                                                color="primary"
                                                size="small"
                                                checked={(result_data?.treatment_orders?.level_three?.is_hi_hospital == true ? true : false)} 
                                              />
                                                Home Isolation โดยโรงพยาบาล

                                              <br/>

                                              <Radio  
                                                color="primary"
                                                size="small"
                                                checked={(result_data?.treatment_orders?.level_three?.is_ci == true ? true : false)} 
                                              />
                                              Community Isolation โดยโรงพยาบาล

                                              <br/>

                                              <Radio  
                                                color="primary"
                                                size="small"
                                                checked={(result_data?.treatment_orders?.level_three?.is_admit == true ? true : false)} 
                                              />
                                              Admit

                                              <br/>
                                            </Box> : ""
                                          )}
                                          <Radio  
                                            color="primary"
                                            size="small"
                                            checked={(result_data?.treatment_orders?.level_three?.is_not_participate == true ? true : false)} 
                                            // value ={ depression ||""}
                                            // onChange={(event) =>{
                                            //     setDepression(event?.target.checked);
                                            //     setVal(event.target.name, event.target.checked);
                                            //   }
                                            // } name="is_depression" 
                                            // inputProps={{ 'aria-label': 'checkbox with small size' }}
                                          />
                                          ไม่เข้าร่วมโปรแกรม
                                          <br/>
                                          {
                                            localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                                            <>
                                                <Radio  
                                                  color="primary"
                                                  size="small"
                                                  checked
                                                />
                                                Community Isolation
                                            </>
                                              :
                                              <></>
                                          }
                                        

                                          <Box id="imageATKPNG">
                                            {
                                              swapImage ? 
                                              <>
                                                {
                                                  result_image_IdCard_data ? 
                                                  <>
                                                    {
                                                      cropIdCardData === "#" ?
                                                        <Box style={{display: "flex", justifyContent: "center", paddingTop: "50px"}}>
                                                          <img style={{height: "250px"}} src={URL.createObjectURL(result_image_IdCard_data)} />
                                                        </Box>
                                                        :
                                                        <Box style={{display: "flex", justifyContent: "center", paddingTop: "50px"}}>
                                                            <img style={{height: "250px"}} src={cropIdCardData} />
                                                          </Box>
                                                    }            
                                                    <br/>
                                                    {
                                                      showButton ? 
                                                      <Box pb={2} style={{textAlign: 'center'}}>
                                                          <Grid container>
                                                            <Grid item xs={12}>
                                                                <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                                  onClick={()=> {
                                                                    setIsCropIdCard(true);
                                                                  }}
                                                                > Crop </Button>
                                                                <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                                  onClick={()=> {
                                                                    setSwapImage(false);
                                                                  }}>
                                                                  Swap Image
                                                                </Button>
                                                            </Grid>
                                                          </Grid>
                                                      </Box>
                                                      
                                                      :
                                                      <></>
                                                    }
                                                    
                                                  </>
                                                  :
                                                  <></>
                                                }
                                              </>
                                              :
                                              <>
                                                {(result_image_atk_data ? 
                                                  <>
                                                  {
                                                    cropData === "#" ?
                                                      <Box style={{display: "flex", justifyContent: "center", paddingTop: "50px"}}>
                                                          <img style={{height: "250px"}} src={URL.createObjectURL(result_image_atk_data)} />
                                                      </Box> 
                                                      :
                                                      <Box style={{display: "flex", justifyContent: "center", paddingTop: "50px"}}>
                                                          <img style={{height: "250px"}} src={cropData} />
                                                      </Box> 
                                                  }
                                                      
                                                      <br/>
                                                      {
                                                        showButton ? 
                                                        <Box pb={2} style={{textAlign: 'center'}}>
                                                            <Grid container>
                                                              <Grid item xs={12}>
                                                                  <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                                    onClick={()=> {
                                                                      setIsAtkCrop(true);
                                                                    }}
                                                                  > Crop </Button>
                                                                  <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                                  onClick={()=> {
                                                                    setSwapImage(true);
                                                                  }}>
                                                                  Swap Image
                                                                </Button>
                                                              </Grid>
                                                            </Grid>
                                                        </Box>
                                                        :
                                                        <></>
                                                      }
                                                      

                                                      <CropImageModal
                                                          isAtkCrop = {isAtkCrop} 
                                                          setIsAtkCrop = {setIsAtkCrop}
                                                          set_image_atk_data = {set_image_atk_data}
                                                          imageData = {result_image_atk_data}
                                                          setCropData = {setCropData}
                                                          cropData = {cropData}
                                                          onClick={async () => {   
                                                                                                
                                                              setIsConfirm(false);
                                                          }}
                                                        />
                                                  </>
                                                    
                                                    : ""
                                                  )}
                                              </>
                                            }
                                            
                                            
                                          </Box>

                                      </Td>
                                    </Tr>
                                </Tbody>
                            </Table>

                          </Grid>
                        </Grid>
                        
                      </Box>
                    </Box>
                  </Box> 
                </>
                
                <>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    flex: 1,
                    minHeight: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 5,
                    backgroundColor: "#fff",
                  }} id="saveAnotherPNG"
                >
                  <br/>
                  <br/>
                  
                  <Box style={{ width: "100%" }} p={2}>
                    
                    <Box style={{ width: "100%" }} p={2}>
                      <Table  style={{border: '1px solid grey', boderBottom: '1px solid grey'}}>
                        <Tbody>
                          <Tr>
                            <Td colSpan={9} className={classes.tableRightBorder} style={{ padding: '10px' }}>
                                <Grid container spacing={2}>
                                  <Grid item xs = {8}>
                                    <p style={{fontSize: '20px', fontWeight: 'bold'}}>
                                        ชื่อ-นามสกุล : { result_vitalSign?.user?.name ? result_vitalSign?.user?.name + ' ' + result_vitalSign?.user?.surname : ""}
                                    </p>
                                    
                                  </Grid>
                                  <Grid item xs = {2}>
                                  <label style={{fontFamily: 'Poppins', fontWeight: 400}}>หมายเลข HN</label>
                                  <br/>
                                    <TextField
                                      id="margin-none"
                                      type="text"
                                      fullWidth
                                      value={ hn_number || hn_number_api } 
                                      onChange={(event) =>{
                                        setHn_number(event.target.value);
                                        setVal(event.target.name, event.target.value);
                                      }
                                    } 
                                    />
                                  </Grid>
                                  <Grid item xs = {2}>
                                  <label style={{fontFamily: 'Poppins', fontWeight: 400}}>Authen Code</label>
                                  <br/>
                                    <TextField
                                      id="margin-none"
                                      type="text"
                                      fullWidth
                                      value={ authen_code || authen_code_api } 
                                      onChange={(event) =>{
                                        setAuthen_code(event.target.value);
                                        setVal(event.target.name, event.target.value);
                                      }
                                    } 
                                    />
                                  </Grid>
                                  <br/>
                                  <br/>
                                    <Grid item xs={5}>
                                      ปัญหาและการวินิจฉัยอื่น ๆ
                                      <span style={{color: 'black'}}>
                                        <br/>
                                        <br/>
                                        
                                        <ContentEditable
                                            innerRef={contentEditable}
                                            html="1&nbsp;&nbsp;&nbsp;"
                                            disabled={false} 
                                            onChange={()=> {}}
                                            tagName='article'
                                        />
                                        <span style={{ paddingLeft: '10px'}}>............................................................................................ </span> 
                                        <br/>
                                        <br/>
                                        <ContentEditable
                                            innerRef={contentEditable}
                                            html="2&nbsp;&nbsp;&nbsp;"
                                            disabled={false} 
                                            onChange={()=> {}}
                                            tagName='article'
                                        />
                                        <span style={{ paddingLeft: '10px'}}>............................................................................................ </span> 
                                      </span>
                                    </Grid>
                                    <Grid item xs={5}>Plan
                                      <span style={{color: 'black'}}>
                                        <br/>
                                        <br/>
                                        <ContentEditable
                                            innerRef={contentEditable}
                                            html="1&nbsp;&nbsp;&nbsp;"
                                            disabled={false} 
                                            onChange={()=> {}}
                                            tagName='article'
                                        />
                                        <span style={{ paddingLeft: '10px'}}>............................................................................................ </span> 
                                        
                                        <br/>
                                        <br/>
                                        <ContentEditable
                                            innerRef={contentEditable}
                                            html="2&nbsp;&nbsp;&nbsp;"
                                            disabled={false} 
                                            onChange={()=> {}}
                                            tagName='article'
                                        />
                                        <span style={{ paddingLeft: '10px'}}>............................................................................................ </span> 
                                      </span>
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </Grid>
                            </Td>
                          </Tr>
                          <Tr>
                            <Th rowspan={2} className={classes.tableRightBorder} style={{ textAlign:'center',fontSize: '16px',  paddingBottom: '8px' }}>Day</Th>
                            <Th rowspan={2} className={classes.tableRightBorder} style={{ textAlign:'center',paddingBottom: '8px' }}>วัน/เดือน/ปี</Th>
                            <Th rowspan={2} className={classes.tableRightBorder} style={{ textAlign:'center',fontSize: '16px', paddingBottom: '8px' }}>O2 sat (%)</Th>
                            <Th rowspan={2} className={classes.tableRightBorder} style={{ textAlign:'center',fontSize: '16px', paddingBottom: '8px' }}>Heart Rate (bpm)</Th>
                            <Th rowspan={2} className={classes.tableRightBorder} style={{ textAlign:'center',fontSize: '16px', paddingBottom: '8px' }}>Body Temperature (oC)</Th>
                            <Th rowspan={2} className={classes.tableRightBorder} style={{ textAlign:'center',fontSize: '16px', paddingBottom: '8px' }}>Patient Educate & Psychosocial Support / Progress Note</Th>
                            <Th rowspan={2} className={classes.tableRightBorder} style={{ textAlign:'center',fontSize: '16px', paddingBottom: '8px' }} width={200}>ผู้ประเมิน</Th>
                            {
                              showDownload ?
                                ""
                                :
                                <Th rowspan={2} className={classes.tableRightBorder} style={{ textAlign:'center',fontSize: '16px', paddingBottom: '8px' }} width={200}>Edit</Th>
                            }
                          </Tr>
                          <Tr>
                          </Tr>

                          {
                            historylog?.map((data, index)=>{
                              return (
                                <>
                                    <Tr>
                                    <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '16px', paddingBottom: '8px' }}>
                                      {index + 1}
                                    </Td>
                                    <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '16px', paddingBottom: '8px' }}>
                                      { dateArray ? dateArray[index] : ""}
                                    </Td>
                                    
                                    <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '16px', paddingBottom: '8px' }}>
                                      {
                                        reportData[index]?.oxygen_before?
                                        <>
                                            {
                                              reportData[index]?.oxygen_before <95 ? 
                                              <span style={{color: 'black'}}>
                                                { ["95", "96", "97", "98", "99"][Math.floor(Math.random()*["95", "96", "97", "98", "99"].length)] }
                                              </span>
                                              :
                                              <>
                                                {reportData[index]?.oxygen_before }
                                              </>
                                            }
                                        </>
                                        :
                                      ""
                                      }
                                      
                                    </Td>
                                    
                                    <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '16px', paddingBottom: '8px' }}>
                                      {
                                        reportData[index]?.heart_rate?
                                        <>
                                          {reportData[index]?.heart_rate}
                                        </>
                                        :
                                      ""
                                      }
                                    </Td>
                                    <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '16px', paddingBottom: '8px' }}>
                                      {
                                        reportData[index]?.temprature?
                                        <>
                                          {reportData[index]?.temprature.toFixed(1)}
                                        </>
                                        :
                                      ""
                                      }
                                    </Td>
                                    <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '16px', paddingBottom: '8px' }}>
                                    {dateArray[index] ?
                                    <>
                                        {
                                          reportData[index]?.symptoms_one || reportData[index]?.symptoms_two ?
                                          <>
                                            {
                                              reportData[index]?.symptoms_two === 'ไม่มีอาการใดใดข้างต้น'  || reportData[index]?.symptoms_two === 'None of the above' || reportData[index]?.symptoms_two === ''?
                                              <>{reportData[index]?.symptoms_one} </>
                                              :reportData[index]?.symptoms_one === '-' || reportData[index]?.symptoms_two ==='-' ?
                                              <>ไม่มีอาการ</>
                                              :
                                              <>
                                                {reportData[index]?.symptoms_one + ", " +reportData[index]?.symptoms_two}
                                              </>
                                            }
                                          </>
                                          :<>ไม่มีอาการ</>
                                        }
                                      </>:""
                                    }
                                      
                                        
                                    </Td>
                                    <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '16px', paddingBottom: '8px' }}>
                                      { dateArray[index] ?
                                        (localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                                          "สิทธิพล" 
                                          : reportData[index]?.recorder ) 
                                        : ""
                                      }
                                    </Td>

                                    
                                      {
                                        showDownload ?
                                         <></>
                                        :
                                        <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '16px', paddingBottom: '8px' }}>
                                          <EditIcon onClick={()=>{
                                            let dataUpdate = reportData[index];
                                            setEditData(dataUpdate);
                                            setIsEdit(true);
                                            setChangeState(true);
                                          }} />
                                        </Td>
                                      }
                                    
                                  </Tr>
                                </>
                              );
                              
                            })
                          }
                          
                          <Tr>
                          <Th colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', paddingBottom: '8px' }}>
                            Psychological problem
                          </Th>
                          <Th colSpan={3} className={classes.tableRightBorder} style={{ textAlign:'center', paddingBottom: '8px' }}>
                            Plan support
                          </Th>
                          <Th colSpan={3} className={classes.tableRightBorder} style={{ textAlign:'center', paddingBottom: '8px' }}>
                            บันทึกการรับประทานยาขณะรักษาที่บ้าน
                          </Th>
                          </Tr>

                          <Tr>
                            <Td colSpan={2} className={classes.tableRightBorder} style={{  paddingBottom: '8px' }}>
                              <Checkbox  
                                style={{padding: "4px"}}
                                color="primary"
                                size="small"
                                checked={depression}  
                                value ={ depression ||""}
                                onChange={(event) =>{
                                    setDepression(event?.target.checked);
                                    setVal(event.target.name, event.target.checked);
                                  }
                                } name="is_depression" 
                                inputProps={{ 'aria-label': 'checkbox with small size' }}
                              />
                              Depression
                            </Td>
                            <Td rowspan={4} colSpan={3} className={classes.tableRightBorder} style={{  paddingBottom: '8px' }}>
                              <Checkbox  
                                style={{padding: "4px"}}
                                color="primary"
                                size="small"
                                inputProps={{ 'aria-label': 'checkbox with small size' }}
                                checked={true } 
                                value ={ is_patient_plan ||""}
                                onChange={(event) =>{
                                    setPatientPlan(event?.target.checked);
                                    setVal(event.target.name, event.target.checked);
                                  }
                                } 
                                name="is_patient_plan"
                              /> ให้คําปรึกษาเบื้องต้น และวางแผนติดตามอาการ
                              <br/>
                              <br/>
                              <Checkbox  
                                style={{padding: "4px"}}
                                color="primary"
                                size="small"
                                inputProps={{ 'aria-label': 'checkbox with small size' }}
                                checked={true} 
                                value ={ is_cheer_up ||""}
                                onChange={(event) =>{
                                    setCheerUp(event?.target.checked);
                                    setVal(event.target.name, event.target.checked);
                                  }
                                } 
                                name="is_cheer_up"
                              />  รับฟัง ให้กําลังใจ สะท้อนความรู้สึกคนไข้
                              <br/>
                              <br/>
                              <Checkbox  
                                style={{padding: "4px"}}
                                color="primary"
                                size="small"
                                inputProps={{ 'aria-label': 'checkbox with small size' }}
                                checked={is_empty} 
                                onChange={(event) =>{
                                    setIsEmpty(event?.target.checked);
                                    setVal(event.target.name, event.target.checked);
                                    if(event?.target.checked === false){
                                      setEmpty("");
                                    }
                                  }
                                } 
                                name="is_empty"
                                value ={ is_empty ||""}

                              />
                              {
                                is_empty ? 
                                <>
                                  <TextField
                                    id="margin-none"
                                    style={{marginLeft: '10px', width: 330}} 
                                    value={empty}
                                    onChange={(event) =>{
                                        setEmpty(event?.target.value);
                                        setVal(event.target.name, event.target.value);
                                      }
                                    } 
                                    name="empty"
                                  />
                                </>
                                :<>
                                  <TextField
                                    id="margin-none"
                                    style={{marginLeft: '10px', width: 330}} 
                                    value={empty}
                                    disabled
                                    onChange={(event) =>{
                                        setEmpty("");
                                        setVal(event.target.name, "");
                                      }
                                    } 
                                    name="empty"
                                  />
                                </>
                              }
                              
                              <br/>
                              <br/>
                              <span style={{paddingLeft: '15px'}}>
                                ต้องการ ปรึกษา 
                                <Checkbox  
                                  style={{padding: "4px"}}
                                  color="primary"
                                  size="small"
                                  inputProps={{ 'aria-label': 'checkbox with small size' }}
                                  checked={psychiatrist_need} 
                                  name="psychiatrist_need"
                                  value={psychiatrist_need|| ''}
                                  onChange={(event) =>{
                                      setPsychiatristNeed(event?.target.checked);
                                      setVal(event.target.name, event.target.checked);
                                    }
                                  } 
                                />
                                จิตแพทย์ 
                                <Checkbox  
                                  style={{padding: "4px"}}
                                  color="primary"
                                  size="small"
                                  inputProps={{ 'aria-label': 'checkbox with small size' }}
                                  checked={psychotherapist_need} 
                                  value={psychotherapist_need|| ''}
                                  name="psychotherapist_need"
                                  onChange={(event) =>{
                                      setPsychotherapistNeed(event?.target.checked);
                                      setVal(event.target.name, event.target.checked);
                                    }
                                  } 
                                />
                                นักจิตบําบัด
                              </span>
                            
                            </Td>
                            <Td rowspan={4} colSpan={3} className={classes.tableRightBorder} style={{  paddingBottom: '8px' }}>

                            {(result_data?.treatment_orders?.level_one?.paracetamol == false && result_data?.treatment_orders?.level_one?.cetirizine == false && 
                              result_data?.treatment_orders?.level_one?.bromhexine == false && result_data?.treatment_orders?.level_one?.cpm == false &&
                              result_data?.treatment_orders?.level_one?.indian_gooseberry == false && result_data?.treatment_orders?.level_one?.is_patient_medicines == false &&
                              result_data?.treatment_orders?.level_one?.is_patient_medicines == false ? <Box style={{textAlign: 'center'}}>ไม่มีการจ่ายยา</Box> : "")}

                            {(result_data?.treatment_orders?.level_one?.paracetamol == true ? 
                              <Grid container>
                                <Grid item xs={12}>
                                  <Checkbox  
                                    style={{padding: "4px"}}
                                    color="primary"
                                    size="small"
                                    checked={(result_data?.treatment_orders?.level_one?.paracetamol == true ? true : false)}   
                                  />
                                  Paracetamol (500) 1 tab po prn q 6 hrs for fever # 20 tabs
                                  <br/>
                                </Grid>
                              </Grid> : ""
                            )}
                              
                            {(result_data?.treatment_orders?.level_one?.cetirizine == true ? 
                              <Grid container>
                                <Grid item xs={12}>
                                  <Checkbox  
                                    style={{padding: "4px"}}
                                    color="primary"
                                    size="small"
                                    checked={(result_data?.treatment_orders?.level_one?.cetirizine == true ? true : false)} 
                                  />
                                  Cetirizine 1 tab po OD pc #10 tabs

                                  <br/>
                                </Grid>
                              </Grid> : ""
                            )}
                              
                            {(result_data?.treatment_orders?.level_one?.bromhexine == true ?  
                              <Grid container>
                                <Grid item xs={12}>
                                  <Checkbox  
                                    style={{padding: "4px"}}
                                    color="primary"
                                    size="small"
                                    checked={(result_data?.treatment_orders?.level_one?.bromhexine == true ? true : false)}  
                                  />
                                  Bromhexine (8 mg) 1 tab po tid pc #10 tabs
                                  <br/>
                                </Grid>
                              </Grid> : ""
                            )}
                              
                            {(result_data?.treatment_orders?.level_one?.cpm == true ?  
                              <Grid container>
                                <Grid item xs={12}>
                                  <Checkbox  
                                    style={{padding: "4px"}}
                                    color="primary"
                                    size="small"
                                    checked={(result_data?.treatment_orders?.level_one?.cpm == true ? true : false)}   
                                  />
                                    CPM 1 tab po tid pc # 10 tabs

                                  <br/>
                                </Grid>
                              </Grid> : ""
                            )}
                              
                            {(result_data?.treatment_orders?.level_one?.indian_gooseberry == true ? 
                              <Grid container>
                                <Grid item xs={12}>
                                  <Checkbox  
                                    style={{padding: "4px"}}
                                    color="primary"
                                    size="small"
                                    checked={(result_data?.treatment_orders?.level_one?.indian_gooseberry == true ? true : false)}
                                  />
                                    ยาแก้ไอมะขามป้อม
                                  <br/>
                                </Grid>
                              </Grid> : ""
                            )}
                              
                            {(result_data?.treatment_orders?.level_one?.is_patient_medicines == true && result_data?.treatment_orders.level_one?.patient_medicines != ""  ? 
                              <Grid container>
                                <Grid item xs={12}>
                                  <Checkbox  
                                    style={{padding: "4px"}}
                                    color="primary"
                                    size="small"
                                    checked={(result_data?.treatment_orders?.level_one?.is_patient_medicines == true ? true : false)}
                                  />
                                    ยาคนไข้ที่จําเป็นต้องส่ังเพื่ม
                                  <br/>
                                </Grid>
                              </Grid> : ""
                            )}

                            {(result_data?.treatment_orders?.level_one?.is_patient_medicines == true && result_data?.treatment_orders.level_one?.patient_medicines != "" ? 
                                <Grid container>
                                  <Grid item xs={12}> 
                                  <label style={{fontSize: '16px', fontWeight: 400, }}>ระบุยาคนไข้ที่จําเป็นต้องส่ังเพื่ม</label>
                                    <br/>
                                      <TextField
                                          id="margin-none"
                                          type="text"
                                          fullWidth
                                          style={{ paddingRight: '15px'}}
                                          value={result_data?.treatment_orders.level_one?.patient_medicines}
                                      />
                                      
                                  </Grid> 
                                </Grid> : ""
                              )}
                              
                                
                              <br/>

                            </Td>
                          </Tr>
                          <Tr>
                            <Td colSpan={2} className={classes.tableRightBorder} style={{  paddingBottom: '8px' }}>
                            <Checkbox  
                                style={{padding: "4px"}}
                                color="primary"
                                size="small"
                                inputProps={{ 'aria-label': 'checkbox with small size' }}
                                checked={anxiety}
                                name="is_anxiety"
                                value={anxiety|| ''}
                                onChange ={
                                  (event) => {
                                    setAnxiety(event.target.checked);
                                    setVal(event.target.name, event.target.checked);
                                  }
                                }
                              />ความวิตกกังวล
                            </Td>
                          </Tr>
                          <Tr>
                            <Td colSpan={2} className={classes.tableRightBorder} style={{  paddingBottom: '8px' }}>
                            <Checkbox  
                                style={{padding: "4px"}}
                                color="primary"
                                size="small"
                                inputProps={{ 'aria-label': 'checkbox with small size' }}
                                checked={is_stigma}
                                name="is_stigma"
                                value={is_stigma|| ''}
                                onChange ={
                                  (event) => {
                                    setIsStigma(event.target.checked);
                                    setVal(event.target.name, event.target.checked);
                                  }
                                }
                              />stigma
                            </Td>
                          </Tr>
                          <Tr>
                            <Td colSpan={2} className={classes.tableRightBorder} style={{  paddingBottom: '8px' }}>
                            <Checkbox  
                                style={{padding: "4px"}}
                                color="primary"
                                size="small"
                                inputProps={{ 'aria-label': 'checkbox with small size' }}
                                checked = {is_other}
                                name ="is_other"
                                value={is_other|| ''}
                                onChange = {
                                  (event) => {
                                    setIsOther(event.target.checked);
                                    setVal(event.target.name, event.target.checked);
                                    if(!event.target.checked){
                                      setOther('');
                                    }
                                  }
                                }
                              /> อื่นๆ 
                              {
                                is_other ? 
                                <>
                                  <TextField
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '16px' } }}
                                    style={{marginLeft: '5px', width: 100}}
                                    name="other"
                                    value={other}
                                    onChange = {
                                      (event) => {
                                        setOther(event.target.value);
                                        setVal(event.target.name, event.target.value);
                                      }
                                    }
                                  /> 
                                </>
                                :
                                <>
                                </>
                                
                              }
                            </Td>
                          </Tr>

                          <Tr>
                            <Th colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', paddingBottom: '8px' }}>
                              อาการแทรกซ้อน
                            </Th>
                            <Th colSpan={3} className={classes.tableRightBorder} style={{ textAlign:'center', paddingBottom: '8px' }}>
                              การดูแลรักษา
                            </Th>
                            <Th colSpan={3} className={classes.tableRightBorder} style={{ textAlign:'center', paddingBottom: '8px' }}>
                              สรุปการรักษาก่อนจําหน่าย
                            </Th>
                          </Tr>

                          <Tr>
                            <Td colSpan={2} className={classes.tableRightBorder} style={{ paddingBottom: '8px' }}>
                                <Grid container>
                                    <Grid xs={12}>
                                    <Checkbox  
                                        style={{padding: "4px"}}
                                        color="primary"
                                        size="small"
                                        inputProps={{ 'aria-label': 'checkbox with small size' }}
                                        checked = {is_tired}
                                        value={is_tired|| ''}
                                        name ="is_tired"
                                        onChange = {
                                          (event) => {
                                            set_is_tired(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                          }
                                        }
                                      />
                                      เหนื่อย
                                    </Grid>
                                
                                </Grid>
                        
                                <Checkbox  
                                    style={{padding: "4px"}}
                                    color="primary"
                                    size="small"
                                    inputProps={{ 'aria-label': 'checkbox with small size' }}
                                    checked = {is_cough}
                                    value={is_cough|| ''}
                                        name ="is_cough"
                                        onChange = {
                                          (event) => {
                                            set_is_cough(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                          }
                                        }
                                  />
                                    <span>
                                      ไอ
                                    </span>
                                    <br/>
                                <Checkbox  
                                        style={{padding: "4px"}}
                                        color="primary"
                                        size="small"
                                        inputProps={{ 'aria-label': 'checkbox with small size' }}
                                        checked = {is_fever}
                                        value={is_fever|| ''}
                                        name ="is_fever"
                                        onChange = {
                                          (event) => {
                                            set_is_fever(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                          }
                                        }
                                      /> ไข้

                                <br/>
                                <Checkbox  
                                  style={{padding: "4px"}}
                                  color="primary"
                                  size="small"
                                  inputProps={{ 'aria-label': 'checkbox with small size' }}
                                  checked = {is_chest_pain}
                                  value={is_chest_pain|| ''}
                                  name ="is_chest_pain"
                                  onChange = {
                                    (event) => {
                                      set_is_chest_pain(event.target.checked);
                                      setVal(event.target.name, event.target.checked);
                                    }
                                  }
                                />เจ็บหน้าอก
                                
                                <br/>
                                <Checkbox  
                                  style={{padding: "4px"}}
                                  color="primary"
                                  size="small"
                                  inputProps={{ 'aria-label': 'checkbox with small size' }}
                                />O<sub>2</sub> {"sat < 96%"}
                              <br/>
                              <Checkbox  
                                style={{padding: "4px"}}
                                color="primary"
                                size="small"
                                inputProps={{ 'aria-label': 'checkbox with small size' }}
                                checked = {is_other_complication}
                                value={is_other_complication|| ''}
                                  name ="is_other_complication"
                                  onChange = {
                                    (event) => {
                                      set_is_other_complication(event.target.checked);
                                      setVal(event.target.name, event.target.checked);
                                    }
                                  }
                              /> อื่นๆ

                              {
                                is_other_complication ?
                                <>
                                  <TextField
                                    id="margin-none"
                                    InputProps={{ style: { fontSize: '16px' } }}
                                    style={{marginLeft: '5px', width: 100}}
                                    value={other_complication}
                                    name="other_complication"
                                    onChange = {
                                      (event) => {
                                        set_other_complication(event.target.value);
                                        setVal(event.target.name, event.target.value);
                                      }
                                    }
                                  /> 
                                </>
                                :
                                <>
                                </>
                              }
                              
                            </Td>
                            <Td colSpan={3} className={classes.tableRightBorder} style={{ paddingBottom: '8px' }}>
                              <Grid container>
                                  <Grid item xs = {4}>
                                      <Checkbox  
                                        style={{padding: "4px"}}
                                        color="primary"
                                        size="small"
                                        inputProps={{ 'aria-label': 'checkbox with small size' }}
                                        checked={is_o2_therapy}
                                        value={is_o2_therapy|| ''}
                                        name="is_o2_therapy"
                                        onChange = {
                                          (event) => {
                                            set_is_o2_therapy(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                            if(event.target.checked === false){
                                              set_o2_therapy("");
                                            }
                                          }
                                        }
                                    /> O2 therapy
                                  </Grid>
                                  
                                  <Grid item xs={8} style={{ marginLeft: '-30px'}}> 
                                    {
                                        is_o2_therapy ? 
                                        <>
                                          <TextField
                                            id="margin-none"
                                            style={{ width: 300}} 
                                            value={o2_therapy}
                                            onChange={(event) =>{
                                                set_o2_therapy(event?.target.value);
                                                setVal(event.target.name, event.target.value);
                                              }
                                            } 
                                            name="o2_therapy"
                                          />
                                        </>
                                        :<>
                                          <TextField
                                            id="margin-none"
                                            style={{   width: 300}} 
                                            value={o2_therapy}
                                            disabled
                                            onChange={(event) =>{
                                                set_o2_therapy("");
                                                setVal(event.target.name, "");
                                              }
                                            } 
                                            name="o2_therapy"
                                          />
                                        </>
                                      }
                                  </Grid>
                              </Grid>
                              
                              <Grid container style={{paddingTop: '10px'}}>
                                  <Grid item xs = {6}>
                                    <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                                          checked={is_prone}
                                          value={is_prone|| ''}
                                          name="is_prone"
                                          onChange = {
                                            (event) => {
                                              set_is_prone(event.target.checked);
                                              setVal(event.target.name, event.target.checked);
                            
                                            }}
                                        />นอนคว่ำ 12-16 ชั่วโมง/วัน
                                  </Grid>
                              </Grid>
                                
                              <Grid container style={{paddingTop: '10px'}}>
                                  <Grid item xs = {4}>
                                    <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                                          checked={is_medication}
                                          value={is_medication|| ''}
                                          name="is_medication"
                                          onChange = {
                                            (event) => {
                                              set_is_medication(event.target.checked);
                                              setVal(event.target.name, event.target.checked);
                                              if(event.target.checked === false){
                                                set_medication("");
                                              }
                                            }
                                          }
                                        />medication อื่นๆ
              
                                  </Grid>
                                  <Grid item xs={8} style={{ marginLeft: '-30px'}}> 
                                    {
                                        is_medication ? 
                                        <>
                                          <TextField
                                            id="margin-none"
                                            style={{ width: 300}} 
                                            value={medication}
                                            onChange={(event) =>{
                                                set_medication(event?.target.value);
                                                setVal(event.target.name, event.target.value);
                                              }
                                            } 
                                            name="medication"
                                          />
                                        </>
                                        :<>
                                          <TextField
                                            id="margin-none"
                                            style={{ width: 300}} 
                                            value={medication}
                                            disabled
                                            onChange={(event) =>{
                                                set_medication("");
                                                setVal(event.target.name, "");
                                              }
                                            } 
                                            name="medication"
                                          />
                                        </>
                                      }
                                  </Grid>
                              </Grid>
                              
                                
                              <Grid container>
                                <Grid item xs={4}>
                                  <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                                          checked={is_refer}
                                          value={is_refer|| ''}
                                          name="is_refer"
                                          onChange = {
                                            (event) => {
                                              set_is_refer(event.target.checked);
                                              setVal(event.target.name, event.target.checked);
                                              if(event.target.checked === false){
                                                set_refer("")
                                              }
                                            }}
                                        />ประสานส่งต่อ
                                </Grid>
                                <Grid item xs={8} style={{ marginLeft: '-30px'}}>
                                    {
                                        is_refer ? 
                                        <>
                                          <TextField
                                            id="margin-none"
                                            style={{width: 300}} 
                                            value={refer}
                                            onChange={(event) =>{
                                                set_refer(event?.target.value);
                                                setVal(event.target.name, event.target.value);
                                              }
                                            } 
                                            name="refer"
                                          />
                                        </>
                                        :<>
                                          <TextField
                                            id="margin-none"
                                            style={{ width: 300}} 
                                            value={refer}
                                            disabled
                                            onChange={(event) =>{
                                              set_refer("");
                                                setVal(event.target.name, "");
                                              }
                                            } 
                                            name="refer"
                                          />
                                        </>
                                      }
                                </Grid>
                              </Grid>

                              <Grid container>
                                <Grid item xs={4}>
                                  <Checkbox  
                                        style={{padding: "4px"}}
                                        color="primary"
                                        size="small"
                                        inputProps={{ 'aria-label': 'checkbox with small size' }}
                                        checked={is_other_treament}
                                        value={is_other_treament|| '' }
                                          name="is_other_treament"
                                          onChange = {
                                            (event) => {
                                              set_is_other_treament(event.target.checked);
                                              setVal(event.target.name, event.target.checked);
                                              if(event.target.checked === false){
                                                set_other_treament("")
                                              }
                                            }}

                                      />อื่น ๆ
                                </Grid>
                                <Grid item xs={8} style={{ marginLeft: '-30px'}}>
                                  {
                                        is_other_treament ? 
                                        <>
                                          <TextField
                                            id="margin-none"
                                            style={{ width: 300}} 
                                            value={other_treatment}
                                            onChange={(event) =>{
                                                set_other_treament(event?.target.value);
                                                setVal(event.target.name, event.target.value);
                                              }
                                            } 
                                            name="other_treatment"
                                          />
                                        </>
                                        :<>
                                          <TextField
                                            id="margin-none"
                                            style={{ width: 300}} 
                                            value={other_treatment}
                                            disabled
                                            onChange={(event) =>{
                                              set_other_treament("");
                                                setVal(event.target.name, "");
                                              }
                                            } 
                                            name="other_treatment"
                                          />
                                        </>
                                      }
                                </Grid>
                              </Grid>
                            
                            </Td>
                            <Td  colSpan={3} className={classes.tableRightBorder} style={{ paddingBottom: '8px' }}>
                                <Grid container>
                                    <Grid item xs={4} style={{paddingTop:'8px', paddingLeft:'5px'}}>
                                      <label>BT ⁰ C</label>
                                      <br/>
                                      <TextField
                                          id="margin-none"
                                          type="number"
                                          value={ body_temp }
                                          name="body_temp"
                                          onChange={(event) =>{
                                              set_body_temp(parseFloat(event.target.value));
                                              setVal(event.target.name, parseFloat(event.target.value));
                                            }
                                          } 
                                          
                                      />
                                    </Grid>
                                    <Grid item xs={1}></Grid>

                                    <Grid item xs={6}>
                                      <Checkbox  
                                        style={{padding: "4px"}}
                                        color="primary"
                                        size="small"
                                        inputProps={{ 'aria-label': 'checkbox with small size' }}
                                        checked={is_full_day}
                                        value={is_full_day|| '' }
                                        name="is_full_day"
                                        onChange = {
                                          (event) => {
                                            set_is_full_day(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                          }}
                                      />
                                      อยู่ครบ {(length_reportData ? length_reportData : "")} วัน อาการดีขึ้น
                                    </Grid>

                                </Grid>
                                <Grid container>
                                    <Grid item xs={4} style={{paddingTop:'8px', paddingLeft:'5px'}}>
                                    <label>PR/min</label>
                                      <TextField
                                          id="margin-none"
                                          type="number"
                                          style={{marginLeft: '10px', width: '100px'}}
                                          value={ pulse }
                                          name="pulse"
                                          onChange={(event) =>{
                                              set_pulse(parseFloat(event.target.value));
                                              setVal(event.target.name, parseFloat(event.target.value));
                                            }
                                          } 
                                      />
                              
                                    </Grid>
                                    <Grid item xs={1}></Grid>

                                    <Grid item xs={3}>
                                      <Checkbox  
                                        style={{padding: "4px"}}
                                        color="primary"
                                        size="small"
                                        inputProps={{ 'aria-label': 'checkbox with small size' }}
                                        checked={is_refer_summary}
                                        value={is_refer_summary|| '' }
                                        name="is_refer_summary"
                                        onChange = {
                                          (event) => {
                                            set_is_refer_summary(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                            if(!event.target.checked){
                                              set_refer_date(null);
                                            }
                                          }}
                                      />
                                      Refer วันที่
                                      
                                    </Grid>
                                    <Grid item xs={4}>
                                      {
                                        is_refer_summary ?
                                        <>  
                                            <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                                              <KeyboardDatePicker
                                                style={{
                                                  width: "84%",
                                                  borderWidth: 0,
                                                  fontSize: 13,
                                                }}
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                id="refer_date"
                                                autoOk={true}
                                                name='refer_date'
                                                value={refer_date || null}
                                                onChange={(date: MaterialUiPickersDate, event) => {
                                                  set_refer_date(date ? new Date(date.toString()) : null);
                                                  setVal(
                                                    "refer_date",
                                                    date ? new Date(date.toString()) : null
                                                    
                                                  );
                                                }}
                                                KeyboardButtonProps={{
                                                  "aria-label": "change date",
                                                }}
                                                yearOffset={543}
                                              />
                                            </MuiPickersUtilsProvider>
                                        </>
                                        :
                                        <></>
                                      }
                                    
                                    </Grid>

                                </Grid>

                                <Grid container>
                                    <Grid item xs={4} style={{paddingTop:'8px', paddingLeft:'5px'}}>
                                      <label>RR/min</label>
                                      <TextField
                                          id="margin-none"
                                          type="number"
                                          style={{marginLeft: '10px', width: '100px'}}
                                          value={respiration_rate}
                                          name="respiration_rate"
                                          onChange={(event) =>{
                                              set_respiration_rate(parseFloat(event.target.value));
                                              setVal(event.target.name, parseFloat(event.target.value));
                                            }
                                          } 
                                      />
                                    </Grid>
                                    <Grid item xs={1}></Grid>

                                    <Grid item xs={4}>
                                      <Checkbox  
                                        style={{padding: "4px"}}
                                        color="primary"
                                        size="small"
                                        inputProps={{ 'aria-label': 'checkbox with small size' }}
                                        checked={is_hospital}
                                        value={is_hospital|| '' }
                                        name="is_hospital"
                                        onChange = {
                                          (event) => {
                                            set_is_hospital(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                            if(!event.target.checked){
                                              set_hospital("");
                                            }
                                          }}
                                      />
                                      เนื่องจากอาการหนักรพ 
                                    </Grid>
                                    <Grid item xs={3}>
                                        {
                                          is_hospital ?
                                          <>
                                            <TextField
                                                id="margin-none"
                                                value={hospital}
                                                name="hospital"
                                                onChange={(event) =>{
                                                    set_hospital(event.target.value);
                                                    setVal(event.target.name, event.target.value);
                                                  }
                                                } 
                                            />
                                          </>
                                          :
                                          <></>
                                        }
                                    </Grid>

                                </Grid>

                                <Grid container>
                                    <Grid item xs={4} style={{paddingTop:'8px', paddingLeft:'5px'}}>
                                    <label>อาการ</label>
                                    <br/>
                                      <TextField
                                          id="margin-none"
                                          value={ symptoms }
                                          name="symptoms"
                                          onChange={(event) =>{
                                              set_symptoms(event.target.value);
                                              setVal(event.target.name, event.target.value);
                                            }
                                          } 
                                      />
                                    </Grid>
                                    <Grid item xs={1}></Grid>

                                    <Grid item xs={5}>
                                      <Checkbox  
                                        style={{padding: "4px"}}
                                        color="primary"
                                        size="small"
                                        inputProps={{ 'aria-label': 'checkbox with small size' }}
                                        checked={is_back_home}
                                        value={is_back_home|| '' }

                                        name="is_back_home"
                                        onChange = {
                                          (event) => {
                                            set_is_back_home(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                            if(!event.target.checked){
                                              set_home("");
                                            }
                                          }}
                                      />
                                      กลับไปรักษาภูมิลําเนาจังหวัด
                                    </Grid>

                                    <Grid item xs={2}>
                                          {
                                            is_back_home ?
                                            <>
                                              <TextField
                                              
                                                id="margin-none"
                                                value={home}
                                                name="home"
                                                onChange={(event) =>{
                                                    set_home(event.target.value);
                                                    setVal(event.target.name, event.target.value);
                                                  }
                                                } 
                                              />
                                            </>
                                            :<></>
                                          }
                                    </Grid>

                                </Grid>

                                <Grid container>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={1}></Grid>
                                    <Grid item xs={3}>
                                      <Checkbox  
                                        style={{padding: "4px"}}
                                        color="primary"
                                        size="small"
                                        inputProps={{ 'aria-label': 'checkbox with small size' }}
                                        checked={is_other_summary}
                                        name="is_other_summary"
                                        onChange = {
                                          (event) => {
                                            set_is_other_summary(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                            if(!event.target.checked){
                                              set_other_summary("");
                                            }
                                          }}
                                        value={is_other_summary|| '' }

                                      />
                                        อื่น ๆ ระบุ
                                    </Grid>
                                    <Grid item xs={4}>
                                        {
                                            is_other_summary ?
                                            <>
                                              <TextField
                                              
                                                id="margin-none"
                                                value={other_summary}
                                                name="other_summary"
                                                onChange={(event) =>{
                                                    set_other_summary(event.target.value);
                                                    setVal(event.target.name, event.target.value);
                                                  }
                                                } 
                                              />
                                            </>
                                            :<></>
                                          }
                                    </Grid>

                                </Grid>

                            </Td>
                          </Tr>
                          <Tr>
                            <Th colSpan={5} className={classes.tableRightBorder} style={{ paddingBottom: '8px' }}>
                                Family & Social issues
                            </Th>
                            <Th colSpan={6} className={classes.tableRightBorder} style={{ paddingBottom: '8px' }}>
                                Plan Support 
                            </Th>
                          </Tr>

                          <Tr>
                              <Td colSpan={5} className={classes.tableRightBorder} style={{ paddingBottom: '8px' }}>
                                <Grid container>
                                    <Grid item xs={12} style={{display : 'flex'}}>
                                      <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                                          checked = {is_home_infect}
                                        value={is_home_infect|| '' }

                                          name = "is_home_infect"
                                          onChange={(event) => {
                                            set_is_home_infect(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                            set_home_infect(0);
                                          }}
                                        />
                                        <p style={{paddingTop: '10px'}}> 1. มีบุคคลอื่นในบ้านที่ติดเชื้อ จํานวน</p>     

                                        
                                        {
                                        is_home_infect ? 
                                        <>
                                          <TextField
                                            id="margin-none"
                                            style={{width: 80, paddingLeft: '10px', paddingRight: '10px'}}
                                            type="number"
                                            value={home_infect}
                                            onChange={(event) =>{
                                              set_home_infect(parseInt(event.target.value));
                                                setVal(event.target.name, parseInt(event.target.value));
                                              }
                                            } 
                                            name="home_infect"
                                          />
                                        </>
                                        :<>
                                          <TextField
                                            id="margin-none"
                                            style={{width: 80, paddingLeft: '10px', paddingRight: '10px'}}
                                            type="number"
                                            value={''}
                                            disabled
                                            onChange={(event) =>{
                                              set_home_infect(0);
                                                setVal(event.target.name, 0);
                                              }
                                            } 
                                            name="home_infect"
                                          />
                                        </>
                                      }

                                      <p style={{paddingTop: '10px'}}> คน</p>     
                                    </Grid>
                                </Grid>
                                
                                <br/>
                                <Grid container>
                                    <Grid item xs={12} style={{display : 'flex'}}>
                                    
                                      <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                                          checked = {is_home_risk}
                                        value={is_home_risk|| '' }

                                          name = "is_home_risk"
                                          onChange={(event) => {
                                            set_is_home_risk(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                            set_home_risk(0);
                                          }}
                                        />
                                        <p style={{paddingTop: '10px'}}> 2. บุคคลในบ้านที่มีปัญหาสุขภาพที่เสี่ยงต่อการติดเชื้อรุนแรง จํานวน</p>     

                                        
                                        {
                                        is_home_risk ? 
                                        <>
                                          <TextField
                                            id="margin-none"
                                            style={{width: 80, paddingLeft: '10px', paddingRight: '10px'}}
                                            type="number"
                                            value={home_risk}
                                            onChange={(event) =>{
                                              set_home_risk(parseInt(event.target.value));
                                                setVal(event.target.name, parseInt(event.target.value));
                                              }
                                            } 
                                            name="home_risk"
                                          />
                                        </>
                                        :<>
                                          <TextField
                                            id="margin-none"
                                            style={{width: 80, paddingLeft: '10px', paddingRight: '10px'}}
                                            type="number"
                                            value={''}
                                            disabled
                                            onChange={(event) =>{
                                              set_home_risk(0);
                                                setVal(event.target.name, 0);
                                              }
                                            } 
                                            name="home_risk"
                                          />
                                        </>
                                      }

                                      <p style={{paddingTop: '10px'}}> คน</p>     
                                    </Grid>
                                </Grid>
                                
                                <br/>
                                <Grid container>
                                    <Grid item xs={12} style={{display : 'flex'}}>
                                    
                                      <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                                          checked = {is_pui}
                                          value= {is_pui || ''}
                                          name = "is_pui"
                                          onChange={(event) => {
                                            set_is_pui(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                            set_pui(0);
                                          }}
                                        />
                                        <p style={{paddingTop: '10px'}}> 3. บุคคลอื่นที่เข้าข่าย PUI จํานวน</p>     

                                        
                                        {
                                        is_pui ? 
                                        <>
                                          <TextField
                                            id="margin-none"
                                            style={{width: 80, paddingLeft: '10px', paddingRight: '10px'}}
                                            type="number"
                                            value={pui}
                                            onChange={(event) =>{
                                              set_pui(parseInt(event.target.value));
                                                setVal(event.target.name, parseInt(event.target.value));
                                              }
                                            } 
                                            name="home_risk"
                                          />
                                        </>
                                        :<>
                                          <TextField
                                            id="margin-none"
                                            style={{width: 80, paddingLeft: '10px', paddingRight: '10px'}}
                                            type="number"
                                            value={''}
                                            disabled
                                            onChange={(event) =>{
                                              set_pui(0);
                                                setVal(event.target.name, 0);
                                              }
                                            } 
                                            name="pui"
                                          />
                                        </>
                                      }

                                      <p style={{paddingTop: '10px'}}> คน</p>     
                                    </Grid>
                                </Grid>
                                
                                <br/>
                                <Grid container>
                                    <Grid item xs={12} style={{display : 'flex'}}>
                                    
                                      <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                                          checked = {is_home_treatment}
                                          value = {is_home_treatment || ''}
                                          name = "is_home_treatment"
                                          onChange={(event) => {
                                            set_is_home_treatment(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                            set_home_treatment(0);
                                          }}
                                        />
                                        <p style={{paddingTop: '10px'}}> 4. บุคคลในครอบครัวต้องการการรักษาพยาบาล จํานวน</p>     

                                        
                                        {
                                        is_home_treatment ? 
                                        <>
                                          <TextField
                                            id="margin-none"
                                            style={{width: 80, paddingLeft: '10px', paddingRight: '10px'}}
                                            type="number"
                                            value={home_treatment}
                                            onChange={(event) =>{
                                              set_home_treatment(parseInt(event.target.value));
                                                setVal(event.target.name, parseInt(event.target.value));
                                              }
                                            } 
                                            name="home_treatment"
                                          />
                                        </>
                                        :<>
                                          <TextField
                                            id="margin-none"
                                            style={{width: 80, paddingLeft: '10px', paddingRight: '10px'}}
                                            type="number"
                                            value={''}
                                            disabled
                                            onChange={(event) =>{
                                              set_home_treatment(0);
                                                setVal(event.target.name, 0);
                                              }
                                            } 
                                            name="home_treatment"
                                          />
                                        </>
                                      }

                                      <p style={{paddingTop: '10px'}}> คน</p>     
                                    </Grid>
                                </Grid>
                                
                                <br/>
                                
                                <Grid container>
                                    <Grid item xs={12} style={{display : 'flex'}}>
                                    
                                      <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                                          checked = {is_special}
                                          value ={ is_special || ''}
                                          name = "is_special"
                                          onChange={(event) => {
                                            set_is_special(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                            
                                          }}
                                        />
                                        <p style={{paddingTop: '10px'}}>5. กลุ่มเปราะบางที่ต้องการการสนับสนุนการตรวจและการดูแลเป็นพิเศษ</p> 
                                  </Grid>
                                </Grid>
                              
                                <br/>

                                <Grid container>
                                    <Grid item xs={12} style={{display : 'flex'}}>
                                      <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                                          checked = {is_poor}
                                          value = { is_poor || ''}
                                          name = "is_poor"
                                          onChange={(event) => {
                                            set_is_poor(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                            
                                          }}
                                        />
                                        <p style={{paddingTop: '10px'}}>6. ยากจนมาก </p> 
                                  </Grid>
                                </Grid>
                                <br/>
                              </Td>

                              <Td colSpan={5} className={classes.tableRightBorder} style={{ paddingBottom: '8px' }}>
                              <Grid container>
                                    <Grid item xs={12} style={{display : 'flex'}}>
                                      <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                                          checked = {is_new}
                                          value = { is_new || ''}
                                          name = "is_new"
                                          onChange={(event) => {
                                            set_is_new(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                            
                                          }}
                                        />
                                        <p style={{paddingTop: '10px'}}>1. ประเมินและรับไว้ดูแลรักษาเป็นรายใหม่ รวมทั้งให้คําแนะนําเรื่องการป้องกันการแพร่กระจายของเชื้อ </p> 
                                  </Grid>
                                </Grid>
                                  <br/>
                                  <Grid container>
                                    <Grid item xs={12} style={{display : 'flex'}}>
                                      <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                                          checked = {is_refer_urgent}
                                          value = { is_refer_urgent || ''}
                                          name = "is_refer_urgent"
                                          onChange={(event) => {
                                            set_is_refer_urgent(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                            
                                          }}
                                        />
                                        <p style={{paddingTop: '10px'}}>2. การส่งต่อเพื่อประเมินการติดเชื้อ COVID 19 อย่างเร่งด่วน </p> 
                                  </Grid>
                                </Grid>
                                
                                  <br/>
                                  <Grid container>
                                    <Grid item xs={12} style={{display : 'flex'}}>
                                      <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                                          checked = {is_recommend}
                                          value = {is_recommend || ''}
                                          name = "is_recommend"
                                          onChange={(event) => {
                                            set_is_recommend(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                            
                                          }}
                                        />
                                        <p style={{paddingTop: '10px'}}>3. แนะนํา/ส่งต่อเพื่อตรวจคัดกรองการติดเชื้อรายใหม่ </p> 
                                  </Grid>
                                </Grid>
                                
                                  <br/>
                                  <Grid container>
                                    <Grid item xs={12} style={{display : 'flex'}}>
                                      <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                                          checked = {is_new_patient}
                                          value = {is_new_patient || ''}

                                          name = "is_new_patient"
                                          onChange={(event) => {
                                            set_is_new_patient(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                            
                                          }}
                                        />
                                        <p style={{paddingTop: '10px'}}>4. ให้การรักษาพยาบาลเป็นรายใหม่ </p> 
                                  </Grid>
                                </Grid>
                                  
                                  <br/>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                                          checked = {is_refer_planSupport}
                                          value = {is_refer_planSupport || ''}
                                          name = "is_refer_planSupport"
                                          onChange={(event) => {
                                            set_is_refer_planSupport(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                            if(!event.target.checked){
                                              set_psychiatrist_need_planSupport(false);
                                              set_psychotherapist_need_planSupport(false);
                                              set_longterm_care_need(false);
                                              set_is_other_planSupport(false);
                                            }
                                            
                                          }}
                                        />
                                        5. ส่งต่อเพื่อดูแล
                                        <br/>
                                        <Checkbox  
                                          style={{padding: "4px", color: '#757575'}}
                                        disabled = {!is_refer_planSupport}
                                          color="primary"
                                          size="small"
                                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                                          checked = {psychiatrist_need_planSupport}
                                          value = {psychiatrist_need_planSupport || ''}

                                          name = "psychiatrist_need_planSupport"
                                          onChange={(event) => {
                                            set_psychiatrist_need_planSupport(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                            
                                          }}
                                        />
                                        นักสังคมสงเคราะห์ 
                                        <br/>

                                        <Checkbox  
                                          style={{padding: "4px", color: '#757575'}}
                                        disabled = {!is_refer_planSupport}
                                          color="primary"
                                          size="small"
                                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                                          checked = {psychotherapist_need_planSupport}
                                          value = {psychotherapist_need_planSupport || ''}

                                          name = "psychotherapist_need_planSupport"
                                          onChange={(event) => {
                                            set_psychotherapist_need_planSupport(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                            
                                          }}
                                        />
                                        นักจิตวิทยา
                                        <br/>

                                        <Checkbox  
                                          style={{padding: "4px", color: '#757575'}}
                                          disabled = {!is_refer_planSupport}
                                          color="primary"
                                          size="small"
                                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                                          checked = {longterm_care_need}
                                          value = {longterm_care_need || ''}
                                          name = "longterm_care_need"
                                          onChange={(event) => {
                                            set_longterm_care_need(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                            
                                          }}
                                        />
                                        longterm care team   (rehabiliation/community nurse team)
                                  </Grid>
                                  </Grid>
                              
                                  <Grid container>
                                    <Grid item xs={12} style={{display : 'flex'}}>
                                    
                                      <Checkbox  
                                          style={{padding: "4px", color: '#757575'}}
                                          color="primary"
                                          size="small"
                                          disabled = {!is_refer_planSupport}
                                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                                          checked = {is_other_planSupport}
                                          value = {is_other_planSupport || ''}

                                          name = "is_other_planSupport"
                                          onChange={(event) => {
                                            set_is_other_planSupport(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                            set_other_planSupport('');
                                          }}
                                        />
                                        <p style={{paddingTop: '10px'}}> อื่นๆ</p>     

                                        
                                        {
                                        is_other_planSupport ? 
                                        <>
                                          <TextField
                                            id="margin-none"
                                            style={{width: 330 , paddingLeft: '6px'}}
                                            value={other_planSupport}
                                            onChange={(event) =>{
                                                set_other_planSupport(event.target.value);
                                                setVal(event.target.name, event.target.value);
                                              }
                                            } 
                                            name="other_planSupport"
                                          />
                                        </>
                                        :<>
                                        
                                        </>
                                      }   
                                    </Grid>
                                </Grid>
                                
                                  <br/>
                                  <Grid container>
                                    <Grid item xs={12} style={{display : 'flex'}}>
                                    
                                      <Checkbox  
                                          style={{padding: "4px"}}
                                          color="primary"
                                          size="small"
                                          inputProps={{ 'aria-label': 'checkbox with small size' }}
                                          checked = {socia_work}
                                          value = {socia_work || ''}

                                          name = "socia_work"
                                          onChange={(event) => {
                                            set_socia_work(event.target.checked);
                                            setVal(event.target.name, event.target.checked);
                                          }}
                                        />
                                        <p style={{paddingTop: '10px'}}> 6. ประสานการสนับสนุนสังคมสงเคราะห์ หรือ ส่งต่อนักสังคมสงเคราะห์ </p>      
                                    </Grid>
                                </Grid>
                          
                                  <br/>
                              </Td>
                          </Tr>

                          <Tr>
                            <Th colSpan={9} className={classes.tableRightBorder} style={{ paddingBottom: '8px', textAlign: 'center' }}>
                              <br/>
                              <br/>
                              {(localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                                <p style={{fontSize: '1vw'}}>
                                ลงชื่อพยาบาล <span style={{borderBottom: '1px solid', fontWeight: 'normal'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src={ signature_prison_nurse } alt="" width="10%" style={{ display: "inline-flex",marginTop: '-30px', paddingBottom: '6px'}}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                เลขที่ใบอนุญาต <span style={{borderBottom: '1px solid', fontWeight: 'normal'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4911072046&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> 
                                ลงชื่อแพทย์ผู้รักษา <span style={{borderBottom: '1px solid', fontWeight: 'normal'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src={ signature_prison } alt="" width="8%" style={{ display: "inline-flex",marginTop: '-20px'}}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> 
                                เลขใบประกอบวิชาชีพ <span style={{borderBottom: '1px solid', fontWeight: 'normal'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ว34436&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <img src={ takuapaLogo } alt="" width="15%" style={{ marginTop: '-26px',marginRight: '40%', float: 'right'}}/>
                                </p> : 
                                <p style={{fontSize: '1vw'}}>
                                  ลงชื่อพยาบาล <span style={{borderBottom: '1px solid'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> 
                                  เลขที่ใบอนุญาต  <span style={{borderBottom: '1px solid'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> 
                                  ลงชื่อแพทย์ผู้รักษา <span style={{borderBottom: '1px solid'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src={ signature } alt="" width="15%" style={{ display: "inline-flex",marginTop: '-20px'}}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> เลขใบประกอบวิชาชีพ <span style={{borderBottom: '1px solid', fontWeight: 'normal'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ว30208&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                  
                                </p>  
                                
                              )}
                              
                              <br/>
                            </Th>
                          </Tr>

                        </Tbody>
                      </Table>
                    </Box>
                    <EditSymptomsModal
                          editData={editData}
                          isEdit={isEdit}
                          setIsEdit={setIsEdit}
                          setChangeState={setChangeState}
                          setEditData = { setEditData }
                          onClick={async () => {
                            setIsEdit(false); 
                          }}
                      />
                  </Box>
                </Box> 
                </>
                

                {/* <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        flex: 1,
                        minHeight: "100%",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 5,
                        backgroundColor: "#fff",
                      }} id="savePNG"
                    >
                        <DischargeForm 
                          showButton = {showButton}
                          setShowButton={setShowButton}
                        />
                </Box> */}
                

                {
                  localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                  <></>
                  :
                  <>
                      <Box>
                        <br/>
                        {(show_image_atk_data ? 
                        <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          flex: 1,
                          minHeight: "100%",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 5,
                          backgroundColor: "#fff",
                        }}  
                        id="saveImagePNG_ATK">
                        <br/>
                        <Grid container spacing={2} style={{padding: '20px', border: '1px solid', width: '95.5%', margin: '30px'}}>
                            <Grid item xs = {8} >
                              <p style={{fontSize: '20px', fontWeight: 'bold'}}>
                                  ชื่อ-นามสกุล : { name + ' ' + surname}
                              </p>
                                
                            </Grid>
                            <Grid item xs = {2}>
                            <label style={{fontFamily: 'Poppins', fontWeight: 400}}>หมายเลข HN</label>
                            <br/>
                              <TextField
                                id="margin-none"
                                type="text"
                                fullWidth
                                value={ hn_number || hn_number_api } 
                                onChange={(event) =>{
                                  setHn_number(event.target.value);
                                  setVal(event.target.name, event.target.value);
                                }
                              } 
                              />
                            </Grid>
                            <Grid item xs = {2}>
                            <label style={{fontFamily: 'Poppins', fontWeight: 400}}>Authen Code</label>
                            <br/>
                              <TextField
                                id="margin-none"
                                type="text"
                                fullWidth
                                value={ authen_code || authen_code_api } 
                                onChange={(event) =>{
                                  setAuthen_code(event.target.value);
                                  setVal(event.target.name, event.target.value);
                                }
                              } 
                              />
                            </Grid>
                            <br/>
                            <br/>
                        </Grid>
                          <Box>
                            {(result_image_atk_data ? 
                              <>
                              {
                                show_image_atk_data ? 
                                <>
                                  {
                                    cropData === "#" ?
                                    <Box style={{display: "flex", justifyContent: "center", paddingTop: "50px"}}>
                                        <img style={{ width: '80%', height: "80%" }} src={URL.createObjectURL(result_image_atk_data)} />
                                      
                                    </Box>
                                      :
                                      <Box style={{display: "flex", justifyContent: "center", paddingTop: "50px"}}>
                                          <img style={{width:'80%', height: "80%" }} src={cropData} />
                                        </Box>
                                  }
                                                
                                    <br/>
                                    {
                                      showButton ?
                                      <Box pb={2} style={{textAlign: 'center'}}>
                                          <Grid container>
                                          <Grid item xs={4}></Grid>
                                            <Grid item xs={4}>
                                                <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                  onClick={()=> {
                                                    setIsAtkCrop(true);
                                                  }}
                                                > Crop </Button>
                                                <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                onClick={()=> {
                                                  setIsHideAtk(true);
                                                }}
                                              > Hide </Button>
                                            </Grid>
                                            <Grid item xs={4}></Grid>
                                          </Grid> 
                                          <HideAtkPhotoModal
                                              isHideAtk = {isHideAtk}
                                              onClick={()=> {
                                                set_show_image_atk_data(false);
                                              }}
                                              setIsHideAtk = {setIsHideAtk}
                                            />
                                      </Box>
                                      :
                                      <></>
                                    }
                                    <CropImageModal
                                                      isAtkCrop = {isAtkCrop} 
                                                      setIsAtkCrop = {setIsAtkCrop}
                                                      set_image_atk_data = {set_image_atk_data}
                                                      imageData = {result_image_atk_data}
                                                      setCropData = {setCropData}
                                                      cropData = {cropData}
                                                      onClick={async () => {   
                                                                                            
                                                          setIsConfirm(false);
                                                      }}
                                                    />
                                  
                                  <br/>
                                </>
                                :
                                <>
                                    {
                                      show_image_IdCard_data ? 
                                      <>
                                          {
                                              cropIdCardData === "#" ?
                                                <Box style={{display: "flex", justifyContent: "center", paddingTop: "50px"}}>
                                                  <img style={{ width: '80%', height: "70%",margin: '10px'}} src={URL.createObjectURL(result_image_IdCard_data)} />
                                                </Box>
                                                :
                                                <Box style={{display: "flex", justifyContent: "center", paddingTop: "50px"}}>
                                                  <img style={{width: '80%', height: "70%" }} src={cropIdCardData} />
                                                </Box>
                                            }
                                                          
                                              <br/>
                                              {
                                                showButton ?
                                                <Box pb={2} style={{textAlign: 'center'}}>
                                                    <Grid container>
                                                    <Grid item xs={4}></Grid>
                                                      <Grid item xs={4}>
                                                          <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                            onClick={()=> {
                                                              setIsCropIdCard(true);
                                                            }}
                                                          > Crop </Button>

                                                          <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                            onClick={()=> {
                                                              setIsHideIdPhoto(true);
                                                            }}>
                                                            Hide
                                                          </Button>
                                                      </Grid>
                                                      <Grid item xs={4}></Grid>
                                                    </Grid> 
                                                    <HideIdPhotoModal
                                                        isHideIdPhoto = {isHideIdPhoto}
                                                        onClick={()=> {
                                                          set_show_image_IdCard_data(false);
                                                        }}
                                                        setIsHideIdPhoto = {setIsHideIdPhoto}
                                                      />
                                                </Box>
                                                :
                                                <></>
                                              }
                                              
                                        
                                            <br/>
                                            <CropIdCardImageModal
                                              isCropIdCard = {isCropIdCard}
                                              setIsCropIdCard = {setIsCropIdCard}
                                              imageData = {result_image_IdCard_data}
                                              setCropIdCardData = {setCropIdCardData}
                                              cropData = {cropIdCardData}
                                            />
                                        </>
                                      :
                                      <></>
                                    }
                                </>
                              }
                                
                                  
                              </>
                                
                              
                              : ""
                            )}
                          </Box>
                          <br/></Box> : ""
                        
                        )}

                        {(show_image_IdCard_data ? 
                        
                            <Box
                              style={{
                                width: "100%",
                                display: "flex",
                                flex: 1,
                                minHeight: "100%",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 5,
                                backgroundColor: "#fff",
                              }}  
                              id="saveImagePNG_IDcard">
                              <br/>
                              <Grid container spacing={2} style={{padding: '20px', border: '1px solid', width: '95.5%', margin: '30px'}}>
                                  <Grid item xs = {8} >
                                    <p style={{fontSize: '20px', fontWeight: 'bold'}}>
                                        ชื่อ-นามสกุล : { name + ' ' + surname}
                                    </p>
                                      
                                  </Grid>
                                  <Grid item xs = {2}>
                                  <label style={{fontFamily: 'Poppins', fontWeight: 400}}>หมายเลข HN</label>
                                  <br/>
                                    <TextField
                                      id="margin-none"
                                      type="text"
                                      fullWidth
                                      value={ hn_number || hn_number_api } 
                                      onChange={(event) =>{
                                        setHn_number(event.target.value);
                                        setVal(event.target.name, event.target.value);
                                      }
                                    } 
                                    />
                                  </Grid>
                                  <Grid item xs = {2}>
                                  <label style={{fontFamily: 'Poppins', fontWeight: 400}}>Authen Code</label>
                                  <br/>
                                    <TextField
                                      id="margin-none"
                                      type="text"
                                      fullWidth
                                      value={ authen_code || authen_code_api } 
                                      onChange={(event) =>{
                                        setAuthen_code(event.target.value);
                                        setVal(event.target.name, event.target.value);
                                      }
                                    } 
                                    />
                                  </Grid>
                                  <br/>
                                  <br/>
                              </Grid>
                            <br/>
                            <br/>
                            {(result_image_IdCard_data ? 
                            <>
                            {
                              show_image_IdCard_data ?
                              <>
                                {
                                    cropIdCardData === "#" ?
                                      <Box style={{display: "flex", justifyContent: "center", paddingTop: "50px"}}>
                                        <img style={{ width: '80%', height: "70%" }} src={URL.createObjectURL(result_image_IdCard_data)} />
                                      </Box>
                                      :
                                      <Box style={{display: "flex", justifyContent: "center", paddingTop: "50px"}}>
                                          <img style={{width: '80%', height: "70%" }} src={cropIdCardData} />
                                        </Box>
                                  }
                                                
                                    <br/>
                                    {
                                      showButton ?
                                      <Box pb={2} style={{textAlign: 'center' }}>
                                          <Grid container>
                                          {/* <Grid item xs={4}></Grid> */}
                                            <Grid item xs={6} style={{display: 'flex'}}>
                                                <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                  onClick={()=> {
                                                    setIsCropIdCard(true);
                                                  }}
                                                > Crop </Button>

                                                <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                  onClick={()=> {
                                                    setIsHideIdPhoto(true);
                                                  }}>
                                                  Hide
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}></Grid>
                                          </Grid> 
                                          <HideIdPhotoModal
                                              isHideIdPhoto = {isHideIdPhoto}
                                              onClick={()=> {
                                                set_show_image_IdCard_data(false);
                                              }}
                                              setIsHideIdPhoto = {setIsHideIdPhoto}
                                            />
                                      </Box>
                                      :
                                      <></>
                                    }
                                    
                              
                                  <br/>
                                  <CropIdCardImageModal
                                    isCropIdCard = {isCropIdCard}
                                    setIsCropIdCard = {setIsCropIdCard}
                                    imageData = {result_image_IdCard_data}
                                    setCropIdCardData = {setCropIdCardData}
                                    cropData = {cropIdCardData}
                                  />
                              </>
                              :
                              <></>
                            }
                            </>
                                : ""
                            )}
                            </Box> : ""
                        )}
                            
                          
                          <br/>
                          <br/>
                      </Box>
                  </>
                }
                

                <Box mt={2} pb={2} style={{textAlign: 'center'}}>
                  <Grid container>
                    <Grid item md={4} xs={12}></Grid>
                    <Grid item md={4} xs={12}>
                       {/* <Button value={result_data?.user?.name + " " + result_data?.user?.surname} variant="contained" color="primary" onClick={onCapturePNG} style={{marginRight: '10px'}}> Download PDF </Button> */}

                       {
                        showDownload ?
                        <Button value={result_data?.user?.name + " " + result_data?.user?.surname} variant="contained" color="primary" onClick={onCapturePNG} style={{marginRight: '10px'}}> Download PDF </Button>
                        :
                        <Button  variant="contained" color="primary"
                        onClick={async () => {
                            try {
                              fire_updateData(form)
                                .then(() => {
                                  setShowDownload(true);
                                  enqueueSnackbar("Success update data", {
                                    variant: "success",
                                    autoHideDuration: 3000,
                                    anchorOrigin: {
                                      vertical: "top",
                                      horizontal: "center",
                                    },
                                  });
                                })
                                .catch((ex) => {
                                  setShowDownload(true);
                                  enqueueSnackbar(
                                    "Error" +
                                      (ex ? " : " + ex.toString() : ""),
                                    {
                                      variant: "error",
                                      autoHideDuration: 3000,
                                      anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "center",
                                      },
                                    }
                                  );
                                });

                                if(localStorage.projectKey !== 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23') {
                                  fire_updateDischargeData(dischargeDataForm)
                                    .then(() => {
                                      // setShowDownload(true);
                                      enqueueSnackbar("Success update discharge data", {
                                        variant: "success",
                                        autoHideDuration: 3000,
                                        anchorOrigin: {
                                          vertical: "top",
                                          horizontal: "center",
                                        },
                                      });
                                    })
                                    .catch((ex) => {
                                      // setShowDownload(true);
                                      enqueueSnackbar(
                                        "Error" +
                                          (ex ? " : " + ex.toString() : ""),
                                        {
                                          variant: "error",
                                          autoHideDuration: 3000,
                                          anchorOrigin: {
                                            vertical: "top",
                                            horizontal: "center",
                                          },
                                        }
                                      );
                                    });
                                }
                                
                                
                            } catch (ex) {}
                          
                          }
                          }
                          > 
                          Save 
                        </Button>
                      } 
                        
                        <Button  variant="contained" color="primary" 
                        onClick={() => 
                          {
                              setIsConfirm(true);
                          }} 
                        style={{marginLeft: '10px'}}>
                          Discharge 
                        </Button>
                    </Grid>
                    <Grid item md={4} xs={12}></Grid>
                  </Grid>

                  <ConfirmStatusModal
                    isConfirm={isConfirm}
                    setIsConfirm={setIsConfirm}
                    status = {"Discharge"}
                    onClick={async () => {
                      
                      fire_quarantineStatus_update(
                        "4"
                      )
                        .then(({ data }: { data: any }) => {
                          webStore.set_filter_reload(true);
                          enqueueSnackbar(`Update Status Success`, {
                            key: "editSuccess",
                            variant: "success",
                            autoHideDuration: 3000,
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "right",
                            },
                          });
                        })
                        .catch((ex: any) => {
                          enqueueSnackbar("Update Status Failed", {
                            key: "editError",
                            variant: "error",
                            autoHideDuration: 3000,
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "right",
                            },
                          });
                        });
                        setIsConfirm(false);
                      
                    }}
                  />
                  
                </Box>
            </>
            :
            <>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  flex: 1,
                  minHeight: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 5,
                  backgroundColor: "#fff",
                }} id="savePNGEng"
              >
                <br/>
                <br/>
                
                <Box style={{ width: "100%" }} p={2}>

                <Box display={{ sm: "none", xs: "none", md: "block" }}>
                    <Typography gutterBottom>
                      <Box>
                        <Grid container>
                          <Grid item xs={12} style={{fontFamily: 'Poppins', fontSize: '28px', textAlign: 'center'}}>
                            <br/>
                              Covid19 patient treatment record in Home isolation or Community isolation 
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        fontWeight="bold"
                        textAlign="center"
                        lineHeight={"56px"}
                        fontSize={20}
                      >
                      </Box>
                      <Box
                        fontWeight="bold"
                        textAlign="center"
                        mb={3}
                        css={{ color: "#14AF5C" }}
                      >
                      </Box>
                    </Typography>
                    
                </Box>
                  
                  <Box style={{ width: "100%" }} p={2}>
                    <Table  style={{border: '1px solid grey', boderBottom: '1px solid grey'}}>
                      <Tbody>
                        <Tr>
                          <Td colSpan={7} className={classes.tableRightBorder} style={{ padding: '10px' }}>
                              <Grid container>
                                <Grid item xs = {12}>
                                  <p style={{fontFamily: 'Poppins', fontSize: '20px', fontWeight: 'bold'}}>
                                      Name : { result_vitalSign?.user?.name ? result_vitalSign?.user?.name + ' ' + result_vitalSign?.user?.surname : ""}
                                  </p>
                                  
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={5}>
                                    <br/>
                                    <label>Date Of Birth:</label>
                                    <span style={{fontFamily: 'Poppins', paddingLeft: '10px'}} >
                                      {result_vitalSign?.user?.date_of_birth ? dateStrAPIFormat(result_vitalSign?.user?.date_of_birth) : ""}  
                                    </span>
                                  </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={5}>
                                    <br/>
                                    {
                                      result_vitalSign?.user?.identity_card ?
                                      <>
                                        <label>ID Card Number:</label>
                                        <TextField
                                              id="margin-none"
                                              type="text"
                                              name="passport"
                                              style={{fontFamily: 'Poppins', fontSize: '12px', paddingLeft: '10px'}}
                                              value = {idcardNo}
                                              onChange={(event) =>{
                                                  setIDcardNo(event.target.value);
                                                }
                                              } 
                                          />
                                      </>
                                      :result_vitalSign?.user?.passport ?
                                      <>
                                        <label>Passport Number:</label>
                                        <TextField
                                              id="margin-none"
                                              type="text"
                                              name="passport"
                                              style={{fontFamily: 'Poppins', fontSize: '12px', paddingLeft: '10px'}}
                                              value = {passportNo}
                                              onChange={(event) =>{
                                                  setPassportNo(event.target.value);
                                                }
                                              } 
                                          />
                                      </>
                                      :
                                      <>
                                        <label>Passport Number:</label>
                                        <TextField
                                              id="margin-none"
                                              type="text"
                                              name="passport"
                                              style={{fontFamily: 'Poppins', fontSize: '12px', paddingLeft: '10px'}}
                                              // onChange={(event) =>{
                                              //     set_pulse(parseFloat(event.target.value));
                                              //     setVal(event.target.name, parseFloat(event.target.value));
                                              //   }
                                              // } 
                                          />
                                      </>
                                    }
                                    
                                  </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={5}>
                                    <br/>
                                    <label>HN:</label>
                                      <TextField
                                          id="margin-none"
                                          type="text"
                                          name="hn"
                                          style={{fontFamily: 'Poppins', fontSize: '12px', paddingLeft: '10px'}}
                                          value={hn_number}
                                          onChange={(event) =>{
                                              setHn_number(event.target.value);
                                            }
                                          } 
                                      />
                                  </Grid>
                              </Grid>
                              <br/>
                              <br/>
                          </Td>
                        </Tr>
                        <Tr>
                          <Th className={classes.tableRightBorder} style={{ fontFamily: 'Poppins',textAlign:'center',fontSize: '20px',  paddingBottom: '8px' }} width={80}>Day</Th>
                          <Th className={classes.tableRightBorder} style={{ fontFamily: 'Poppins',textAlign:'center', fontSize: '20px',paddingBottom: '8px' }} width={200}>Date</Th>
                          {/* <Th className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '16px',paddingBottom: '8px' }}>BP (mmHg)</Th> */}
                          <Th className={classes.tableRightBorder} style={{ fontFamily: 'Poppins',textAlign:'center',fontSize: '20px', paddingBottom: '8px' }} width={100}>O2 sat (%)</Th>
                          <Th className={classes.tableRightBorder} style={{fontFamily: 'Poppins', textAlign:'center',fontSize: '20px', paddingBottom: '8px' }}>Heart Rate (bpm)</Th>
                          <Th className={classes.tableRightBorder} style={{ fontFamily: 'Poppins',textAlign:'center',fontSize: '20px', paddingBottom: '8px' }} width={200}>Body Temperature <br/> (<sup>o</sup> C)</Th>
                          <Th className={classes.tableRightBorder} style={{ fontFamily: 'Poppins',textAlign:'center',fontSize: '20px', paddingBottom: '8px' }}>Patient Educate & Psychosocial Support / Progress Note</Th>
                          <Th className={classes.tableRightBorder} style={{ fontFamily: 'Poppins',textAlign:'center',fontSize: '20px', paddingBottom: '8px' }} width={200}>Assessor</Th>
                        </Tr>

                        {
                          historylog?.map((data, index)=>{
                            return (
                              <>
                                  <Tr>
                                  <Td className={classes.tableRightBorder} style={{fontFamily: 'Poppins', textAlign:'center', fontSize: '18px', paddingBottom: '8px' }}>
                                    {index + 1}
                                  </Td>
                                  <Td className={classes.tableRightBorder} style={{fontFamily: 'Poppins', textAlign:'center', fontSize: '18px', paddingBottom: '8px' }}>
                                    { dateArray ? dateArray[index] : ""}
                                  </Td>
                                  {/* <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                                      {
                                        reportData[index]?.blood_systolic?
                                        <>
                                          {reportData[index]?.blood_systolic + "/" + reportData[index]?.blood_diastolic}
                                        </>
                                        :
                                      ""
                                      }
                                      
                                  </Td> */}
                                  <Td className={classes.tableRightBorder} style={{ fontFamily: 'Poppins',textAlign:'center', fontSize: '18px', paddingBottom: '8px' }}>
                                    {
                                      reportData[index]?.oxygen_before?
                                      <>
                                          {
                                            reportData[index]?.oxygen_before <95 ? 
                                            <span style={{color: 'red'}}>
                                              {reportData[index]?.oxygen_before }
                                            </span>
                                            :
                                            <>
                                              {reportData[index]?.oxygen_before }
                                            </>
                                          }
                                      </>
                                      :
                                    ""
                                    }
                                    
                                  </Td>
                                  
                                  <Td className={classes.tableRightBorder} style={{fontFamily: 'Poppins', textAlign:'center', fontSize: '18px', paddingBottom: '8px' }}>
                                    {
                                      reportData[index]?.heart_rate?
                                      <>
                                        {reportData[index]?.heart_rate}
                                      </>
                                      :
                                    ""
                                    }
                                  </Td>
                                  <Td className={classes.tableRightBorder} style={{ fontFamily: 'Poppins',textAlign:'center', fontSize: '18px', paddingBottom: '8px' }}>
                                    {
                                      reportData[index]?.temprature?
                                      <>
                                        {reportData[index]?.temprature.toFixed(1)}
                                      </>
                                      :
                                    ""
                                    }
                                  </Td>
                                  <Td className={classes.tableRightBorder} style={{fontFamily: 'Poppins', textAlign:'center', fontSize: '18px', paddingBottom: '8px' }}>
                                    {
                                      reportData[index]?.symptoms_one || reportData[index]?.symptoms_two ?
                                      <>
                                        {
                                          reportData[index]?.symptoms_two === 'ไม่มีอาการใดใดข้างต้น'  || reportData[index]?.symptoms_two === 'None of the above'?
                                          <>{reportData[index]?.symptoms_one} </>
                                          :
                                          <>
                                            {reportData[index]?.symptoms_one + ", " +reportData[index]?.symptoms_two}
                                          </>
                                      }
                                      </>
                                      :
                                      <></>
                                    }
                                      
                                  </Td>
                                  <Td className={classes.tableRightBorder} style={{ fontFamily: 'Poppins',textAlign:'center', fontSize: '18px', paddingBottom: '8px' }}></Td>
                                </Tr>
                              </>
                            );
                            
                          })
                        }

                        <Tr>
                          <Th colSpan={7} className={classes.tableRightBorder} style={{ fontFamily: 'Poppins', fontSize: '16px', paddingBottom: '8px', textAlign: 'center' }}>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            Nurse signature.................................License number.................................Doctor signature.................................Doctor license number............................
                            <br/>
                            <br/>
                            <br/>
                          </Th>
                        </Tr>

                      </Tbody>
                    </Table>
                  </Box>
                    
                </Box>
              </Box>

              <Box mt={8} pb={2} style={{textAlign: 'center'}}>
              <Grid container>
                <Grid item md={4} xs={12}></Grid>
                <Grid item md={4} xs={12}>
                  {
                    showDownload ?
                    <Button  variant="contained" color="primary" onClick={onCapturePNGEng} style={{marginRight: '10px'}}> Download PDF </Button>
                    :
                    <Button  variant="contained" color="primary"
                    onClick={async () => {
                        try {
                          fire_updateData(form)
                            .then(() => {
                              setShowDownload(true);
                              enqueueSnackbar("Success update data", {
                                variant: "success",
                                autoHideDuration: 3000,
                                anchorOrigin: {
                                  vertical: "top",
                                  horizontal: "center",
                                },
                              });
                            })
                            .catch((ex) => {
                              setShowDownload(true);
                              enqueueSnackbar(
                                "Error" +
                                  (ex ? " : " + ex.toString() : ""),
                                {
                                  variant: "error",
                                  autoHideDuration: 3000,
                                  anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                  },
                                }
                              );
                            });
                        } catch (ex) {}}
                      }
                      > 
                      Save 
                    </Button>
                  }
                    
                    <Button  variant="contained" color="primary" 
                    onClick={() => 
                      {
                          setIsConfirm(true);
                      }} 
                    style={{marginLeft: '10px'}}>
                      Discharge 
                    </Button>
                </Grid>
                <Grid item md={4} xs={12}></Grid>
              </Grid>

                <ConfirmStatusModal
                isConfirm={isConfirm}
                setIsConfirm={setIsConfirm}
                status = {"Discharge"}
                onClick={async () => {
              
              fire_quarantineStatus_update(
                "4"
              )
                .then(({ data }: { data: any }) => {
                  webStore.set_filter_reload(true);
                  enqueueSnackbar(`Update Status Success`, {
                    key: "editSuccess",
                    variant: "success",
                    autoHideDuration: 3000,
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  });
                })
                .catch((ex: any) => {
                  enqueueSnackbar("Update Status Failed", {
                    key: "editError",
                    variant: "error",
                    autoHideDuration: 3000,
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  });
                });
                  setIsConfirm(false);
              
                  }}
                />
                
              </Box>
            </>
        }
        
        
        

      </MuiThemeProvider>
    );
}