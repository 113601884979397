import React, { Fragment, useEffect, useRef, useState,useMemo } from "react";

import {
  Avatar,
  Box,
  Container,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  makeStyles,
  withStyles,
  InputBase,
  Typography,
  Tabs,
  Tab,
  Modal,
  createMuiTheme,
  MuiThemeProvider,
  Radio ,
  RadioGroup,
  FormControlLabel,
  FormLabel 
} from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import { CardMap, CardMapMemo } from "../Dashboard/CardMap";
import { CardUserList } from "../Dashboard/CardUserList";
import { CardUserListByStatus } from "../Dashboard/CardUserListByStatus";
import { SubBar } from "../Dashboard/Dashboard";
import LuxonUtils from "@date-io/luxon";
import { DateFilter } from "../../components/DateFilter";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { webStore } from "../../stores/webStore";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { Filter, Value, ReloadValue } from "../../types/user";
import AppBar from "@material-ui/core/AppBar";
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from "material-ui-thai-datepickers";
import { Search } from "@material-ui/icons";
import MaterialTable from "material-table";
import tableIcons from "./MaterialTableIcons";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveAlt from "@material-ui/icons/SaveAlt";
import CloseIcon from "@material-ui/icons/Close";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
// import 'moment/locale/en';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import {useDropzone} from 'react-dropzone';
import './Messenger.css';
import { GetAppointmentData } from "../../services/api/telemedicine/BookingService";  
import { dateStr, dateStrAPI,dateString,dateStringFullMothName,dateStrFormatTime,dateStrFormatLogin } from "../../utils";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AttachmentIcon from '@material-ui/icons/Attachment';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  
  const focusedStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };


  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: '#303f9f',
      },
    },

  });


const AddApprovedModal = observer(
    ({
      isApproved,
      label,
      onClick,
      onClose,
      setIsApproved,
      setAddApprovedValue,
      buttonNameConfirm = "CONFIRM",
      buttonNameCANCEL = "CANCEL",
      isDelivery,
      status

    }: {
      isApproved: boolean;
      setIsApproved: any;
      label: string;
      onClick?: any;
      onClose?: any;
      type?: string;
      setAddApprovedValue?: any;
      buttonNameConfirm?: string;
      buttonNameCANCEL?: string;
      isDelivery: boolean;
      status?: string;
    }) => {
  
        const [value, setValue] = React.useState('delivered');

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          setValue((event.target as HTMLInputElement).value);
        };
  
      return (
        <Modal
          open={isApproved}
          onClose={() => {}}
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
            <Box
                css={{ backgroundColor: "#fff", width: 400, borderRadius: 30, border: 'solid 2px #3f51b5' }}
                p={2}
            >
                <Box
                    display="flex"
                    justifyContent="right"
                    alignItems="center"
                    mb={1}
                    mt={1}
                >
                <Box onClick={onClose}>
                    <CloseIcon css={{ cursor: "pointer" }} />
                </Box>
            </Box>

            <Box
                display="flex"
                justifyContent="center"
                alignItems="center" 
                css={{ color: "#4B4C56" }}
            >
                <h1 css={{ color: "#0c1679", fontSize: "24px" }}> Update Status</h1>
                
            </Box>
            <Box
                className="mt-3"
                textAlign= "center"

                // display="flex"
                // justifyContent="center"
                // alignItems="center"
            >
                 {
                     isDelivery ?
                     <>
                        <p css={{ color: "#0c1679", fontSize: "18px" }}>{label}</p> 
                        <br/>
                        <FormControl component="fieldset">
                        <FormLabel component="legend">Status will be updated to </FormLabel>
                        <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                            <FormControlLabel value="delivered" control={<Radio size="small" color="primary" />} label="Delivered" />
                            <FormControlLabel value="completed" control={<Radio  size="small" color="primary" />} label="Completed" />
                        </RadioGroup>
                        </FormControl>
                     </>
                     :
                     <>
                        <p css={{ color: "#0c1679", fontSize: "18px" }}>{label}</p> 
                        <p className="mt-3" css={{ color: "#4B4C56", fontSize: "18px" }}>{"Status will be updated to '" +status + "'"}</p>
                     </>
                 }
                
                
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt ={2}
                >
                <Button
                    css={{margin: "10px"}}
                    onClick={onClick}
                    color="primary"
                    size="medium"
                    variant="contained"
                >
                    {buttonNameConfirm}
                </Button>

                <Button
                    css={{margin: "10px"}}
                    onClick={onClick}
                    size="medium"
                    variant="contained"
                >
                    {buttonNameCANCEL}
                </Button>
                </Box>
                <br/>
          </Box>
        </Modal>
      );
    }
);

const UploadFileModal = observer(
    ({
      isUploadFile,
      onClick,
      setIsUploadFile,
      buttonNameConfirm = "CONFIRM",
      buttonNameCANCEL = "CANCEL",

    }: {
      isUploadFile: boolean;
      setIsUploadFile: any;
      onClick?: any;
      type?: string;
      buttonNameConfirm?: string;
      buttonNameCANCEL?: string;
    }) => {
  
      return (
        <Modal
          open={isUploadFile}
          onClose={() => {}}
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
            <Box
                css={{ backgroundColor: "#fff", width: 400, borderRadius: 30, border: 'solid 2px #3f51b5' }}
                p={2}
            >
                <Box
                    display="flex"
                    justifyContent="right"
                    alignItems="center"
                    mb={1}
                    mt={1}
                >
                <Box onClick={()=> {setIsUploadFile(false);}}>
                    <CloseIcon css={{ cursor: "pointer" }} />
                </Box>
            </Box>

            <Box
                display="flex"
                justifyContent="center"
                alignItems="center" 
                css={{ color: "#4B4C56" }}
            >
                <h1 css={{ color: "#0c1679", fontSize: "24px" }}> Upload File </h1>
                
            </Box>
            <Box
                className="mt-3"
                textAlign= "center"

                // display="flex"
                // justifyContent="center"
                // alignItems="center"
            >
                <BasicDropZone/>
                
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt ={2}
                >
                <Button
                    css={{margin: "10px"}}
                    onClick={onClick}
                    color="primary"
                    size="medium"
                    variant="contained"
                >
                    {buttonNameConfirm}
                </Button>

                <Button
                    css={{margin: "10px"}}
                    onClick={onClick}
                    size="medium"
                    variant="contained"
                >
                    {buttonNameCANCEL}
                </Button>
                </Box>
                <br/>
          </Box>
        </Modal>
      );
    }
);

const DetailInfoModal = observer(
    ({
      isDetail,
      address,
      phoneNumber,
      onClick,
      onClose,
      setIsDetail,
      setAddApprovedValue,
      buttonNameConfirm = "OK",
      buttonNameCANCEL = "CANCEL",

    }: {
      isDetail: boolean;
      setIsDetail: any;
      address: string;
      phoneNumber: string;
      onClick?: any;
      onClose?: any;
      type?: string;
      setAddApprovedValue?: any;
      buttonNameConfirm?: string;
      buttonNameCANCEL?: string;
    }) => {
  
      const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setAddApprovedValue({note: event.target.value as string});
      };
  
      return (
        <Modal
          open={isDetail}
          onClose={() => {}}
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
            <Box
                css={{ backgroundColor: "#fff", width: 400, borderRadius: 30, border: 'solid 2px #3f51b5' }}
                p={2}
            >
                <Box
                    display="flex"
                    justifyContent="right"
                    alignItems="center"
                    mb={1}
                    mt={1}
                >
                <Box onClick={() =>{setIsDetail(false)}}>
                    <CloseIcon css={{ cursor: "pointer" }} />
                </Box>
            </Box>

            <Box
                display="flex"
                css={{ color: "#4B4C56" }}
            >
                <h1 css={{ color: "#0c1679", fontSize: "20px" }}> More Details </h1>
                
            </Box>
            <Box mt={4}>
                <p css={{ color: "#4B4C56", fontSize: "15px" }}>Address: {address}</p> 
                <p className="mt-3" css={{ color: "#4B4C56", fontSize: "15px" }}>Phone Number: {phoneNumber}</p>
                
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt ={2}
                >
                <Button
                    css={{margin: "10px"}}
                    onClick={onClick}
                    color="primary"
                    size="medium"
                    variant="contained"
                >
                    {buttonNameConfirm}
                </Button>

                </Box>
                <br/>
          </Box>
        </Modal>
      );
    }
);

const ExportButton = withStyles({
    root: {
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 16,
      padding: '6px 12px',
      border: '1px solid',
      lineHeight: 1.5,
      backgroundColor: '#0CA789',
      borderColor: '#0CA789',
      '&:hover': {
        backgroundColor: '#0CA789',
        borderColor: '#0CA789',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0CA789',
        borderColor: '#0CA789',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },
})(Button);

function tapsStyles() {
    return {
        indicator: {
        // backgroundColor: "#fff",
        },
    };
}
  
export interface customeLabels {
    data: any;
    labels: any;
    color: any;
    showValue?: boolean;
    itemsCountPerPage?: number;
}

    interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
  
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}
  
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const Pharmacy_recent = observer(() => {
    const history = useHistory();
    const rando = (max: any) => Math.floor(Math.random() * max);

    const words = ["Paper", "Rock", "Scissors"];
    const addresses = ['11 แขวงมีนบุรี เขตมีนบุรี กรุงเทพมหานคร 10510', 'BKK', 'CNX'];
    const phoneNumbers = [ '0891234567', '0820340266', '0987654321']
    const gender = ["ชาย", "หญิง"];
    const invoice = "invoice";
    const rawData = [];
        for (let i = 0; i < 100; i++) {
        rawData.push({
            idTable: i+1,
            id: rando(300),
            name: words[i % words.length],
            gender: gender[i % gender.length],
            date: moment(randomDate(new Date(2012, 0, 1), new Date()).toLocaleString()).format('DD MMMM yy'),
            invoice: "invoice",
            prescription: "testing",
            address: addresses[i % addresses.length],
            phone: phoneNumbers[i % phoneNumbers.length]
        });
    }
    
    const [patient_name, setPatient_name] = useState("");
    const [startDate, setStartDate] = useState<Date | null>(webStore.startDate);
    const [endDate, setEndDate] = useState<Date | null>(webStore.endDate);
    const [data, setData] = useState(rawData);
    const [addApprovedValue, setAddApprovedValue] = useState<any>();
    const [isApproved, setIsApproved] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [ showUploadFileModal, setShowUploadFileModal ] = React.useState(false); 
    const [ isDetail, setIsDetail ] = useState(false);
    const [ detailData, setDetailData ] = useState<any>();
    return(
        <Box className="p-4">
            <Box 
                css={{
                    paddingLeft: "10%", 
                    paddingRight: "10%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <TextField 
                    id="standard-name"
                    value={patient_name} 
                    label="Patient Name"  
                    margin="normal"
                    onChange={(e) => setPatient_name(e.target.value)} 
                    css={{marginLeft: "10px", marginRight: "10px"}}
                />

                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="from"
                    label="Start Date"
                    value={startDate || new Date()}
                    onChange={(date: MaterialUiPickersDate) => {
                        setStartDate(date ? new Date(date.toString()) : null);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    yearOffset={543}
                    css={{marginLeft: "10px", marginRight: "10px"}}
                    />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="from"
                    label="End Date"
                    value={endDate || new Date()}
                    onChange={(date: MaterialUiPickersDate) => {
                        setEndDate(date ? new Date(date.toString()) : null);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    yearOffset={543}
                    css={{marginLeft: "10px", marginRight: "10px"}}
                    />
                </MuiPickersUtilsProvider> 

                <Button
                    color="primary"
                    onClick={() => {
                        console.log("search");
                    }}
                    css={{
                    padding: 8,
                    minWidth: "unset",
                    boxShadow: "unset",
                    marginRight: '2%'
                    }}
                >
                    <SearchIcon />
                </Button>

                <Button
                    type="submit"
                    onClick={() => {
                        console.log("Clear");
                    }}
                    variant="contained"
                    color="primary"
                    css={{marginLeft: "10px", marginRight: "10px"}}
                >
                    Clear
                </Button>

                <ExportButton
                    type="submit"
                    // style= {{marginLeft: "2%", fontSize : '12px'}}
                    onClick={() => {
                        console.log("ExportButton");
                    }}
                    variant="contained"
                    color="primary" disableRipple 
                    css={{ marginLeft: "10px", marginRight: "10px", color:'#fff' }}
                >
                    Export to .xls
                </ExportButton>   

            </Box>
            <Box className="pt-5">
            <MuiThemeProvider theme={theme}>
            <MaterialTable
                    icons={tableIcons}
                    columns={[
                        { title: 'No.', field: 'idTable' },
                        { title: 'Name', field: 'name', type: 'string' },
                        { title: 'Gender', field: 'gender', type: 'string' },
                        { title: 'Date', field: 'date', type:'date'},
                        { title: 'Invoice', field: 'invoice',
                            render: rowData => <span style={{color: '#6371ff'}}><u>invoice.pdf</u></span>
                        },
                        { title: 'Prescription', field: 'prescription',
                            render: rowData => <span 
                            onClick={() => {
                                setShowUploadFileModal(true);
                            }}
                            style= {{color : '#303f9f'}}
                            > <u> <AttachFileIcon style={{fontSize: '20px'}}/> Upload file </u> </span>
                        },
                        {
                            title: "Actions",
                            render: rawData => {
                              return (
                                  <IconButton
                                    aria-label="edit"
                                    // disabled={false}
                                    onClick={() => {
                                      setLoading(true);
                                      setIsApproved(rawData);
                                    //   setTimeout(() => {
                                    //     setIsApproved(rawData);
                                    //   }, 1400);
                                    }}
                                  >
                                    <EditIcon />
                                    {/* {loading===true && (
                                      <CircularProgress
                                        size={38}
                                      />
                                    )} */}
                                  </IconButton>
                              );
                            }
                        },
                        {
                            title: "Detail",
                            render: rowData => {
                              return (
                                <Box
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flex: 1,
                                  borderRadius: 5,
                                }}
                                onClick={() =>{ setIsDetail(true)
                                        setDetailData(rowData);
                                    }}
                              >
                                <Button  variant="contained" color="primary"> More </Button>
                              </Box>
                              );
                            }
                        }
                    ]}
                    data={data}
                     
                    options={{
                        selection: true,
                        showTitle: false,
                        search: false,
                    }}
                    actions={[
                        {
                          tooltip: 'Update Status for selected users',
                          icon: () => <EditIcon />,
                          onClick: (evt, data) => {
                            setLoading(true);
                            setIsApproved(data);
                            //     setTimeout(() => {
                            //     setIsApproved(data);
                            // }, 1400);
                          }

                        },
                        {
                            tooltip: 'Export for selected users',
                            icon: () => <SaveAlt />,
                            onClick: (evt, data) => alert('You want to Exports ' + data + ' rows')
                        },

                    ]}
                />
                <UploadFileModal 
                    isUploadFile={showUploadFileModal}
                    onClick={() =>{
                        setShowUploadFileModal(false);
                    }}
                    setIsUploadFile= {setShowUploadFileModal} />
            </MuiThemeProvider>
                

                <AddApprovedModal
                    {...addApprovedValue}
                    isApproved={isApproved}
                    setIsApproved={setIsApproved}
                    setAddApprovedValue={setAddApprovedValue}
                    isDelivery= {false}
                    status = "Delivery"
                    label={isApproved?.length > 0 ? isApproved?.length + " Item(s) Selected" : isApproved?.name}
                    onClose={async () => {
                        setIsApproved(false);
                        setLoading(false);
                    }}
                    onClick={async () => {
                    setLoading(true);
                    setIsApproved(false);
                    setLoading(false);
                    
                    }}
                />

                    <DetailInfoModal
                    isDetail={isDetail}
                    setIsDetail={setIsDetail}
                    setAddApprovedValue={setAddApprovedValue}
                    address={ detailData?.address }
                    phoneNumber = { detailData?.phone }
                    onClose={async () => {
                        setIsDetail(false);
                    }}
                    onClick={async () => {
                        setIsDetail(false);
                    }}
                />
            </Box>
        </Box>
    );
});

export const Pharmacy_delivery = observer(() => {
    const history = useHistory();
    const rando = (max: any) => Math.floor(Math.random() * max);

    const words = ["Paper", "Rock", "Scissors"];
    const addresses = ['11 แขวงมีนบุรี เขตมีนบุรี กรุงเทพมหานคร 10510', 'BKK', 'CNX'];
    const phoneNumbers = [ '0891234567', '0820340266', '0987654321']
    const gender = ["ชาย", "หญิง"];
    const invoice = "invoice";
    const rawData = [];
        for (let i = 0; i < 100; i++) {
        rawData.push({
            idTable: i+1,
            id: rando(300),
            name: words[i % words.length],
            gender: gender[i % gender.length],
            date: moment(randomDate(new Date(2012, 0, 1), new Date()).toLocaleString()).format('DD MMMM yy'),
            invoice: "invoice",
            prescription: "testing",
            address: addresses[i % addresses.length],
            phone: phoneNumbers[i % phoneNumbers.length],
            deliveryDate: moment(randomDate(new Date(2012, 0, 1), new Date()).toLocaleString()).format('DD MMMM yy')
        });
    }
    
    const [patient_name, setPatient_name] = useState("");
    const [startDate, setStartDate] = useState<Date | null>(webStore.startDate);
    const [endDate, setEndDate] = useState<Date | null>(webStore.endDate);
    const [data, setData] = useState(rawData);
    const [addApprovedValue, setAddApprovedValue] = useState<any>();
    const [isApproved, setIsApproved] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [ showUploadFileModal, setShowUploadFileModal ] = React.useState(false); 
    const [ isDetail, setIsDetail ] = useState(false);
    const [ detailData, setDetailData ] = useState<any>();
    return(
        <Box className="p-4">
            <Box 
                css={{
                    paddingLeft: "10%", 
                    paddingRight: "10%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <TextField 
                    id="standard-name"
                    value={patient_name} 
                    label="Patient Name"  
                    margin="normal"
                    onChange={(e) => setPatient_name(e.target.value)} 
                    css={{marginLeft: "10px", marginRight: "10px"}}
                />

                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="from"
                    label="Start Date"
                    value={startDate || new Date()}
                    onChange={(date: MaterialUiPickersDate) => {
                        setStartDate(date ? new Date(date.toString()) : null);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    yearOffset={543}
                    css={{marginLeft: "10px", marginRight: "10px"}}
                    />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="from"
                    label="End Date"
                    value={endDate || new Date()}
                    onChange={(date: MaterialUiPickersDate) => {
                        setEndDate(date ? new Date(date.toString()) : null);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    yearOffset={543}
                    css={{marginLeft: "10px", marginRight: "10px"}}
                    />
                </MuiPickersUtilsProvider> 

                <Button
                    color="primary"
                    onClick={() => {
                        console.log("search");
                    }}
                    css={{
                    padding: 8,
                    minWidth: "unset",
                    boxShadow: "unset",
                    marginRight: '2%'
                    }}
                >
                    <SearchIcon />
                </Button>

                <Button
                    type="submit"
                    onClick={() => {
                        console.log("Clear");
                    }}
                    variant="contained"
                    color="primary"
                    css={{marginLeft: "10px", marginRight: "10px"}}
                >
                    Clear
                </Button>

                <ExportButton
                    type="submit"
                    // style= {{marginLeft: "2%", fontSize : '12px'}}
                    onClick={() => {
                        console.log("ExportButton");
                    }}
                    variant="contained"
                    color="primary" disableRipple 
                    css={{ marginLeft: "10px", marginRight: "10px", color:'#fff' }}
                >
                    Export to .xls
                </ExportButton>   

            </Box>
            <Box className="pt-5">
            <MuiThemeProvider theme={theme}>
            <MaterialTable
                    icons={tableIcons}
                    columns={[
                        { title: 'No.', field: 'idTable' },
                        { title: 'Name', field: 'name', type: 'string' },
                        { title: 'Gender', field: 'gender', type: 'string' },
                        { title: 'Date', field: 'date', type:'date'},
                        { title: 'Invoice', field: 'invoice',
                            render: rowData => <span style={{color: '#6371ff'}}><u>invoice.pdf</u></span>
                        },
                        { title: 'Delivery Date', field: 'deliveryDate', type:'string'},
                        { title: 'Invoice', field: 'invoice',
                            render: rowData => <span style={{color: '#101fbf'}}>Delivery</span>
                        },
                        {
                            title: "Actions",
                            render: rawData => {
                              return (
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() => {
                                      setLoading(true);
                                      setIsApproved(rawData);
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                              );
                            }
                        },
                        {
                            title: "Detail",
                            render: rowData => {
                              return (
                                <Box
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flex: 1,
                                  borderRadius: 5,
                                }}
                                onClick={() =>{ setIsDetail(true)
                                        setDetailData(rowData);
                                    }}
                              >
                                <Button  variant="contained" color="primary"> More </Button>
                              </Box>
                              );
                            }
                        }
                    ]}
                    data={data}
                     
                    options={{
                        selection: true,
                        showTitle: false,
                        search: false,
                    }}
                    actions={[
                        {
                          tooltip: 'Update Status for selected users',
                          icon: () => <EditIcon />,
                          onClick: (evt, data) => {
                            setLoading(true);
                            setIsApproved(data);
                          }

                        },
                        {
                            tooltip: 'Export for selected users',
                            icon: () => <SaveAlt />,
                            onClick: (evt, data) => alert('You want to Exports ' + data + ' rows')
                        },

                    ]}
                />
            </MuiThemeProvider>
                

                <AddApprovedModal
                    {...addApprovedValue}
                    isApproved={isApproved}
                    setIsApproved={setIsApproved}
                    setAddApprovedValue={setAddApprovedValue}
                    isDelivery={true}
                    label={isApproved?.length > 0 ? isApproved?.length + " Item(s) Selected" : isApproved?.name}
                    onClose={async () => {
                        setIsApproved(false);
                        setLoading(false);
                    }}
                    onClick={async () => {
                    setLoading(true);
                    setIsApproved(false);
                    setLoading(false);
                    
                    }}
                />

                    <DetailInfoModal
                    isDetail={isDetail}
                    setIsDetail={setIsDetail}
                    setAddApprovedValue={setAddApprovedValue}
                    address={ detailData?.address }
                    phoneNumber = { detailData?.phone }
                    onClose={async () => {
                        setIsDetail(false);
                    }}
                    onClick={async () => {
                        setIsDetail(false);
                    }}
                />
            </Box>
        </Box>
    );
});

export const Pharmacy_delivered = observer(() => {
    const history = useHistory();
    const rando = (max: any) => Math.floor(Math.random() * max);

    const words = ["Paper", "Rock", "Scissors"];
    const addresses = ['11 แขวงมีนบุรี เขตมีนบุรี กรุงเทพมหานคร 10510', 'BKK', 'CNX'];
    const phoneNumbers = [ '0891234567', '0820340266', '0987654321']
    const gender = ["ชาย", "หญิง"];
    const invoice = "invoice";
    const rawData = [];
        for (let i = 0; i < 100; i++) {
        rawData.push({
            idTable: i+1,
            id: rando(300),
            name: words[i % words.length],
            gender: gender[i % gender.length],
            date: moment(randomDate(new Date(2012, 0, 1), new Date()).toLocaleString()).format('DD MMMM yy'),
            invoice: "invoice",
            prescription: "testing",
            address: addresses[i % addresses.length],
            phone: phoneNumbers[i % phoneNumbers.length],
            deliveryDate: moment(randomDate(new Date(2012, 0, 1), new Date()).toLocaleString()).format('DD MMMM yy')
        });
    }
    
    const [patient_name, setPatient_name] = useState("");
    const [startDate, setStartDate] = useState<Date | null>(webStore.startDate);
    const [endDate, setEndDate] = useState<Date | null>(webStore.endDate);
    const [data, setData] = useState(rawData);
    const [addApprovedValue, setAddApprovedValue] = useState<any>();
    const [isApproved, setIsApproved] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [ showUploadFileModal, setShowUploadFileModal ] = React.useState(false); 
    const [ isDetail, setIsDetail ] = useState(false);
    const [ detailData, setDetailData ] = useState<any>();
    return(
        <Box className="p-4">
            <Box 
                css={{
                    paddingLeft: "10%", 
                    paddingRight: "10%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <TextField 
                    id="standard-name"
                    value={patient_name} 
                    label="Patient Name"  
                    margin="normal"
                    onChange={(e) => setPatient_name(e.target.value)} 
                    css={{marginLeft: "10px", marginRight: "10px"}}
                />

                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="from"
                    label="Start Date"
                    value={startDate || new Date()}
                    onChange={(date: MaterialUiPickersDate) => {
                        setStartDate(date ? new Date(date.toString()) : null);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    yearOffset={543}
                    css={{marginLeft: "10px", marginRight: "10px"}}
                    />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="from"
                    label="End Date"
                    value={endDate || new Date()}
                    onChange={(date: MaterialUiPickersDate) => {
                        setEndDate(date ? new Date(date.toString()) : null);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    yearOffset={543}
                    css={{marginLeft: "10px", marginRight: "10px"}}
                    />
                </MuiPickersUtilsProvider> 

                <Button
                    color="primary"
                    onClick={() => {
                        console.log("search");
                    }}
                    css={{
                    padding: 8,
                    minWidth: "unset",
                    boxShadow: "unset",
                    marginRight: '2%'
                    }}
                >
                    <SearchIcon />
                </Button>

                <Button
                    type="submit"
                    onClick={() => {
                        console.log("Clear");
                    }}
                    variant="contained"
                    color="primary"
                    css={{marginLeft: "10px", marginRight: "10px"}}
                >
                    Clear
                </Button>

                <ExportButton
                    type="submit"
                    // style= {{marginLeft: "2%", fontSize : '12px'}}
                    onClick={() => {
                        console.log("ExportButton");
                    }}
                    variant="contained"
                    color="primary" disableRipple 
                    css={{ marginLeft: "10px", marginRight: "10px", color:'#fff' }}
                >
                    Export to .xls
                </ExportButton>   

            </Box>
            <Box className="pt-5">
            <MuiThemeProvider theme={theme}>
            <MaterialTable
                    icons={tableIcons}
                    columns={[
                        { title: 'No.', field: 'idTable' },
                        { title: 'Name', field: 'name', type: 'string' },
                        { title: 'Gender', field: 'gender', type: 'string' },
                        { title: 'Date', field: 'date', type:'date'},
                        { title: 'Invoice', field: 'invoice',
                            render: rowData => <span style={{color: '#6371ff'}}><u>invoice.pdf</u></span>
                        },
                        { title: 'Delivery Date', field: 'deliveryDate', type:'string'},
                        { title: 'Invoice', field: 'invoice',
                            render: rowData => <span style={{color: '#101fbf'}}>Delivered</span>
                        },
                        {
                            title: "Actions",
                            render: rawData => {
                              return (
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() => {
                                      setLoading(true);
                                      setIsApproved(rawData);
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                              );
                            }
                        },
                        {
                            title: "Detail",
                            render: rowData => {
                              return (
                                <Box
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flex: 1,
                                  borderRadius: 5,
                                }}
                                onClick={() =>{ setIsDetail(true)
                                        setDetailData(rowData);
                                    }}
                              >
                                <Button  variant="contained" color="primary"> More </Button>
                              </Box>
                              );
                            }
                        }
                    ]}
                    data={data}
                     
                    options={{
                        selection: true,
                        showTitle: false,
                        search: false,
                    }}
                    actions={[
                        {
                          tooltip: 'Update Status for selected users',
                          icon: () => <EditIcon />,
                          onClick: (evt, data) => {
                            setLoading(true);
                            setIsApproved(data);
                          }

                        },
                        {
                            tooltip: 'Export for selected users',
                            icon: () => <SaveAlt />,
                            onClick: (evt, data) => alert('You want to Exports ' + data + ' rows')
                        },

                    ]}
                />
            </MuiThemeProvider>
                

                <AddApprovedModal
                    {...addApprovedValue}
                    isApproved={isApproved}
                    setIsApproved={setIsApproved}
                    setAddApprovedValue={setAddApprovedValue}
                    status = "Completed"
                    label={isApproved?.length > 0 ? isApproved?.length + " Item(s) Selected" : isApproved?.name}
                    isDelivery={false}
                    onClose={async () => {
                        setIsApproved(false);
                        setLoading(false);
                    }}
                    onClick={async () => {
                    setIsApproved(false);
                    setLoading(false);
                    
                    }}
                />

                    <DetailInfoModal
                    isDetail={isDetail}
                    setIsDetail={setIsDetail}
                    setAddApprovedValue={setAddApprovedValue}
                    address={ detailData?.address }
                    phoneNumber = { detailData?.phone }
                    onClose={async () => {
                        setIsDetail(false);
                    }}
                    onClick={async () => {
                        setIsDetail(false);
                    }}
                />
            </Box>
        </Box>
    );
});

export const Pharmacy_Complete = observer(() => {
    const history = useHistory();
    const rando = (max: any) => Math.floor(Math.random() * max);

    const words = ["Paper", "Rock", "Scissors"];
    const addresses = ['11 แขวงมีนบุรี เขตมีนบุรี กรุงเทพมหานคร 10510', 'BKK', 'CNX'];
    const phoneNumbers = [ '0891234567', '0820340266', '0987654321']
    const gender = ["ชาย", "หญิง"];
    const invoice = "invoice";
    const rawData = [];
        for (let i = 0; i < 100; i++) {
        rawData.push({
            idTable: i+1,
            id: rando(300),
            name: words[i % words.length],
            gender: gender[i % gender.length],
            date: moment(randomDate(new Date(2012, 0, 1), new Date()).toLocaleString()).format('DD MMMM yy'),
            invoice: "invoice",
            prescription: "testing",
            address: addresses[i % addresses.length],
            phone: phoneNumbers[i % phoneNumbers.length],
            deliveryDate: moment(randomDate(new Date(2012, 0, 1), new Date()).toLocaleString()).format('DD MMMM yy'),
            completedDate: moment(randomDate(new Date(2012, 0, 1), new Date()).toLocaleString()).format('DD MMMM yy')
        });
    }
    
    const [patient_name, setPatient_name] = useState("");
    const [startDate, setStartDate] = useState<Date | null>(webStore.startDate);
    const [endDate, setEndDate] = useState<Date | null>(webStore.endDate);
    const [data, setData] = useState(rawData);
    const [addApprovedValue, setAddApprovedValue] = useState<any>();
    const [isApproved, setIsApproved] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [ showUploadFileModal, setShowUploadFileModal ] = React.useState(false); 
    const [ isDetail, setIsDetail ] = useState(false);
    const [ detailData, setDetailData ] = useState<any>();
    return(
        <Box className="p-4">
            <Box 
                css={{
                    paddingLeft: "10%", 
                    paddingRight: "10%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <TextField 
                    id="standard-name"
                    value={patient_name} 
                    label="Patient Name"  
                    margin="normal"
                    onChange={(e) => setPatient_name(e.target.value)} 
                    css={{marginLeft: "10px", marginRight: "10px"}}
                />

                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="from"
                    label="Start Date"
                    value={startDate || new Date()}
                    onChange={(date: MaterialUiPickersDate) => {
                        setStartDate(date ? new Date(date.toString()) : null);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    yearOffset={543}
                    css={{marginLeft: "10px", marginRight: "10px"}}
                    />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="from"
                    label="End Date"
                    value={endDate || new Date()}
                    onChange={(date: MaterialUiPickersDate) => {
                        setEndDate(date ? new Date(date.toString()) : null);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    yearOffset={543}
                    css={{marginLeft: "10px", marginRight: "10px"}}
                    />
                </MuiPickersUtilsProvider> 

                <Button
                    color="primary"
                    onClick={() => {
                        console.log("search");
                    }}
                    css={{
                    padding: 8,
                    minWidth: "unset",
                    boxShadow: "unset",
                    marginRight: '2%'
                    }}
                >
                    <SearchIcon />
                </Button>

                <Button
                    type="submit"
                    onClick={() => {
                        console.log("Clear");
                    }}
                    variant="contained"
                    color="primary"
                    css={{marginLeft: "10px", marginRight: "10px"}}
                >
                    Clear
                </Button>

                <ExportButton
                    type="submit"
                    // style= {{marginLeft: "2%", fontSize : '12px'}}
                    onClick={() => {
                        console.log("ExportButton");
                    }}
                    variant="contained"
                    color="primary" disableRipple 
                    css={{ marginLeft: "10px", marginRight: "10px", color:'#fff' }}
                >
                    Export to .xls
                </ExportButton>   

            </Box>
            <Box className="pt-5">
            <MuiThemeProvider theme={theme}>
            <MaterialTable
                    icons={tableIcons}
                    columns={[
                        { title: 'No.', field: 'idTable' },
                        { title: 'Name', field: 'name', type: 'string' },
                        { title: 'Gender', field: 'gender', type: 'string' },
                        { title: 'Date', field: 'date', type:'date'},
                        { title: 'Invoice', field: 'invoice',
                            render: rowData => <span style={{color: '#6371ff'}}><u>invoice.pdf</u></span>
                        },
                        { title: 'Delivery Date', field: 'deliveryDate', type:'string'},
                        { title: 'Completed Date', field: 'completedDate', type:'string'},
                        { title: 'Status', field: 'status',
                            render: rowData => <span style={{color: '#101fbf'}}>Completed</span>
                        },
                        {
                            title: "Detail",
                            render: rowData => {
                              return (
                                <Box
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flex: 1,
                                  borderRadius: 5,
                                }}
                                onClick={() =>{ setIsDetail(true)
                                        setDetailData(rowData);
                                    }}
                              >
                                <Button  variant="contained" color="primary"> More </Button>
                              </Box>
                              );
                            }
                        }
                    ]}
                    data={data}
                     
                    options={{
                        selection: true,
                        showTitle: false,
                        search: false,
                    }}
                    actions={[
                        {
                          tooltip: 'Update Status for selected users',
                          icon: () => <EditIcon />,
                          onClick: (evt, data) => {
                            setLoading(true);
                            setIsApproved(data);
                          }

                        },
                        {
                            tooltip: 'Export for selected users',
                            icon: () => <SaveAlt />,
                            onClick: (evt, data) => alert('You want to Exports ' + data + ' rows')
                        },

                    ]}
                />
            </MuiThemeProvider>
                

                <AddApprovedModal
                    {...addApprovedValue}
                    isApproved={isApproved}
                    setIsApproved={setIsApproved}
                    setAddApprovedValue={setAddApprovedValue}
                    status = "Completed"
                    label={isApproved?.length > 0 ? isApproved?.length + " Item(s) Selected" : isApproved?.name}
                    isDelivery={false}
                    onClose={async () => {
                        setIsApproved(false);
                        setLoading(false);
                    }}
                    onClick={async () => {
                    setIsApproved(false);
                    setLoading(false);
                    
                    }}
                />

                    <DetailInfoModal
                    isDetail={isDetail}
                    setIsDetail={setIsDetail}
                    setAddApprovedValue={setAddApprovedValue}
                    address={ detailData?.address }
                    phoneNumber = { detailData?.phone }
                    onClose={async () => {
                        setIsDetail(false);
                    }}
                    onClick={async () => {
                        setIsDetail(false);
                    }}
                />
            </Box>
        </Box>
    );
});

function randomDate(start: any, end: any) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}



const BasicDropZone = (props : any) => {
    const {acceptedFiles, getRootProps, getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone();

    const styleDropZone = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
      }), [
        isFocused,
        isDragAccept,
        isDragReject
      ]);

    
    const files = acceptedFiles.map(file => (
      <li key={file.name}>
        {/* {file.name} - {file.size} bytes */}
        {file.name}
      </li>
    ));
  
    return (
      <section>
        <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>Drag and drop files, or click to select files</p>
        </div>
        <aside>
            {
                files? 
                <>
                    <ul style={{paddingLeft: '25px', color: '#111a79'}}>{files}</ul>
                </>
                :
                <></>
            }
          
        </aside>
      </section>
    );
  }


export const Pharmacy = observer(() => {
  const history = useHistory();

  const [value, setValue] = React.useState(0);
  const [patient_name, setPatient_name] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(webStore.startDate);
  const [endDate, setEndDate] = useState<Date | null>(webStore.endDate);
  const [file, setFile] = useState(null);

  const handleChangeDragDrop = (file :any) => {
    setFile(file);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  
  return(
    <Container maxWidth="xl" className="py-4">
        <Grid container>
            <Grid item xs={12}>
                <Box
                    className="flex justify-between items-center flex-wrap"
                    pt={4}
                    mb={3}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        css={{
                            "@media (max-width: 992px)": {
                            width: "100%",
                            justifyContent: "space-between",
                            },
                        }}
                        mb={{ xs: 3, md: 0 }}
                    >
                        <Box fontSize="h5.fontSize" fontWeight={500} mr={3}>
                            Pharmacy
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <AppBar position="static" css={{backgroundColor: "transparent"}}>
                    <Tabs value={value} onChange={handleChange} css={{color: "#000"}}>
                        <Tab label="Recent" />
                        <Tab label="Delivery" />
                        <Tab label="Delivered" />
                        <Tab label="Completed" />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <Grid item xs={12} css={{border: "1px solid"}}>
                        <Box className="py-4">
                            <Pharmacy_recent/>
                        </Box>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Grid item xs={12} css={{border: "1px solid"}}>
                        <Pharmacy_delivery/>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Grid item xs={12} css={{border: "1px solid"}}>
                        <Pharmacy_delivered/>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Grid item xs={12} css={{border: "1px solid"}}>
                        <Pharmacy_Complete/>
                    </Grid>
                </TabPanel>
            </Grid>
        </Grid>
    </Container>
  );
});
export const MobilePharmacy = Pharmacy;