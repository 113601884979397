import { Filter, User, UserFromAPI,dailyReport,UserFromAPIUpdate,MentalReport,
    UpdateStatusData,CreateNoteData,ContactTracerInfo, UserProfile } from "../../../types/user";
 import _, { parseInt } from "lodash";
 import { callAPI, useCallAPI, useCallAPISpacetrax, callAPISpacetrax } from "../../useCallAPI";
 import { userInfo } from "os";
 import { isNull } from "util";
 import { DateTime } from "luxon";
 import dayjs from "dayjs";
 import { dateStr, dateStrAPI, timeStr, DateString,DateStringAPI,dateStrFormat,dateStrFormatBuddhist, dateStrAPIFormat} from "../../../utils";
 import { useHistory } from "react-router-dom";
 import {
   BatteryHistoryReport,
   BloodPressureHistoryReport,
   HeartRateHistoryReport,
   TempHistoryReport,
   WearingHistoryReport,
   LocationsReport,
   OximeterHistoryReport,
   BreathHistoryReport,
   BloodSugarHistoryReport,
   ContactInfo
 } from "../../../types/personalReport";
import { ContactSupportOutlined } from "@material-ui/icons";
import { ExportUpdateData } from "../../../types/ExportUpdateData"; 
import { FirstReceipt, UpdateDischargeData , DischargeForUpdate, VitalSignData} from "../../../types/first_receipt";

 export const GetVitalSignData = (userId: string) => {
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
      data: any;
    }>
    ({
      url: `/v2/dashboard/user/${userId}/export-vital`,
      method: "GET",
      data: {
        userId,
      },
    });
  
    let resultData = res?.data;
    let report:{
        "assessmentPeriod": number,
        "date" : Date | string | null,
        "oxygen_before": number,
        "oxygen_after": number,
        "urination_among" : number,
        "defecation_among" : number,
        "breath" : number,
        "heart_rate" : number,
        "blood_diastolic": number,
        "blood_systolic": number
    }[] = [];
    let tempData: any = [];
    let pulseData: any = [];
    let startDate;
    let dateArray:string[] = [];
    let xAxisArray: any= [];
    let weightHeightData: number[] = [];
    let value : number = 41;
    if (resultData) {
      let resultDataArray = resultData?.result;
      
      let startdate = DateStringAPI(resultData.to);
        startDate = new Date(startdate);
        for(let i= 0; i<resultDataArray.length; i++){
            if(dateArray[dateArray.length - 1] !== DateStringAPI(resultDataArray[i]?.created_at)){
                dateArray.push(DateStringAPI(resultDataArray[i]?.created_at));
                xAxisArray.push("("+ DateString(resultDataArray[i]?.created_at) + ") 8:00");
                xAxisArray.push("("+ DateString(resultDataArray[i]?.created_at) + ") 14:00");
                xAxisArray.push("("+ DateString(resultDataArray[i]?.created_at) + ") 20:00");
            } 
        }
        if(dateArray.length < 10){
            let dateArrayLength = 10 - dateArray.length;
            for(let i=0; i< dateArrayLength; i++){
                startDate.setDate(startDate.getDate() + 1);
                let date = DateStringAPI(startDate)?.toString();
                if(date){
                    dateArray.push(date);
                } 
                xAxisArray.push("("+ DateString(startDate) + ") 8:00");
                xAxisArray.push("("+ DateString(startDate) + ") 14:00");
                xAxisArray.push("("+ DateString(startDate) + ") 20:00");
            }
        }

        for(let i=0; i<10; i ++){
            weightHeightData.push(resultData?.user?.health?.weight);
            weightHeightData.push(resultData?.user?.health?.height);
        }
      for(let i= 0; i<resultDataArray.length; i++){
        if(resultDataArray[0].assesment_period === 1){
            if(report.length === 0 || report.length === 4 || report.length === 8 || report.length === 12  ||
                report.length === 16 || report.length === 20 || report.length === 24 ||
                report.length === 28 || report.length === 32 || report.length === 36){
                    if(DateString(resultDataArray[i]?.created_at) !== DateString(resultDataArray[i-1]?.created_at) &&
                    resultDataArray[i]?.assesment_period === 2){
                        if(DateString(resultDataArray[i]?.created_at) === DateString(resultDataArray[i + 1]?.created_at) &&
                        resultDataArray[i+1]?.assesment_period === 1 ) {
                            report.push({
                                "assessmentPeriod": resultDataArray[i+1]?.assesment_period,
                                "date" : DateString(resultDataArray[i+1]?.created_at),
                                "oxygen_before": resultDataArray[i+1]?.oxygen_after,
                                "oxygen_after": resultDataArray[i+1]?.oxygen_before,
                                "urination_among" : resultDataArray[i+1]?.urination_among,
                                "defecation_among" : resultDataArray[i+1]?.defecation_among,
                                "breath" : resultDataArray[i+1]?.breath,
                                "heart_rate" : resultDataArray[i+1]?.heart_rate,
                                "blood_diastolic": resultDataArray[i+1]?.blood_diastolic,
                                "blood_systolic": resultDataArray[i+1]?.blood_systolic
                            });
                            
                            
                            tempData.push(resultDataArray[i+1].tempurature);
                            pulseData.push(resultDataArray[i+1]?.heart_rate);
    
                            value = i + 1;
                            
                        }else {
                            report.push({
                                "assessmentPeriod": 1,
                                "date" : DateString(resultDataArray[i]?.created_at),
                                "oxygen_before": 0,
                                "oxygen_after": 0,
                                "urination_among" : 0,
                                "defecation_among" : 0,
                                "breath" : 0,
                                "heart_rate" : 0,
                                "blood_diastolic": 0,
                                "blood_systolic": 0
                            });
                            tempData.push(30);
                            pulseData.push(40);
    
                        }
                    }
                    
            }
            
            if(value !== i ){
                report.push({
                    "assessmentPeriod": resultDataArray[i]?.assesment_period,
                    "date" : DateString(resultDataArray[i]?.created_at),
                    "oxygen_before": resultDataArray[i]?.oxygen_after,
                    "oxygen_after": resultDataArray[i]?.oxygen_before,
                    "urination_among" : resultDataArray[i]?.urination_among,
                    "defecation_among" : resultDataArray[i]?.defecation_among,
                    "breath" : resultDataArray[i]?.breath,
                    "heart_rate" : resultDataArray[i]?.heart_rate,
                    "blood_diastolic": resultDataArray[i]?.blood_diastolic,
                    "blood_systolic": resultDataArray[i]?.blood_systolic
                });
                tempData.push(resultDataArray[i].tempurature);
                pulseData.push(resultDataArray[i]?.heart_rate);

                if( DateString(resultDataArray[i]?.created_at) !== DateString(resultDataArray[i+1]?.created_at) && 
                resultDataArray[i]?.assesment_period === 1){
                    report.push({
                        "assessmentPeriod": 2,
                        "date" : DateString(resultDataArray[i]?.created_at),
                        "oxygen_before": 0,
                        "oxygen_after": 0,
                        "urination_among" : 0,
                        "defecation_among" : 0,
                        "breath" : 0,
                        "heart_rate" : 0,
                        "blood_diastolic": 0,
                        "blood_systolic": 0
                    });
                    tempData.push(30);
                    pulseData.push(40);
                }
    
                if( DateString(resultDataArray[i]?.created_at) !== DateString(resultDataArray[i+1]?.created_at) && 
                    resultDataArray[i]?.assesment_period === 1 && resultDataArray[i+1]?.assesment_period !== 3){
                    report.push({
                        "assessmentPeriod": 3,
                        "date" : DateString(resultDataArray[i]?.created_at),
                        "oxygen_before": 0,
                        "oxygen_after": 0,
                        "urination_among" : 0,
                        "defecation_among" : 0,
                        "breath" : 0,
                        "heart_rate" : 0,
                        "blood_diastolic": 0,
                        "blood_systolic": 0
                    });
                    report.push({
                        "assessmentPeriod": 0,
                        "date" : DateString(resultDataArray[i]?.created_at),
                        "oxygen_before": 0,
                        "oxygen_after": 0,
                        "urination_among" : 0,
                        "defecation_among" : 0,
                        "breath" : 0,
                        "heart_rate" : 0,
                        "blood_diastolic": 0,
                        "blood_systolic": 0
                    });
                    tempData.push(30);
                    pulseData.push(40);
                }
            
                if( DateString(resultDataArray[i]?.created_at) === DateString(resultDataArray[i+1]?.created_at) && 
                resultDataArray[i]?.assesment_period === 1 && resultDataArray[i+1]?.assesment_period !== 2){
                report.push({
                    "assessmentPeriod": 2,
                    "date" : DateString(resultDataArray[i]?.created_at),
                    "oxygen_before": 0,
                    "oxygen_after": 0,
                    "urination_among" : 0,
                    "defecation_among" : 0,
                    "breath" : 0,
                    "heart_rate" : 0,
                    "blood_diastolic": 0,
                    "blood_systolic": 0
                });
                tempData.push(30);
                pulseData.push(40);

                if(resultDataArray[i+1]?.assesment_period !== 3) {
                    report.push({
                        "assessmentPeriod": 3,
                        "date" : DateString(resultDataArray[i]?.created_at),
                        "oxygen_before": 0,
                        "oxygen_after": 0,
                        "urination_among" : 0,
                        "defecation_among" : 0,
                        "breath" : 0,
                        "heart_rate" : 0,
                        "blood_diastolic": 0,
                        "blood_systolic": 0
                    });
                    tempData.push(30);
                    pulseData.push(40);
                }
             }
            
                if( DateString(resultDataArray[i]?.created_at) !== DateString(resultDataArray[i+1]?.created_at) && 
                    resultDataArray[i]?.assesment_period === 2){
                    report.push({
                        "assessmentPeriod": 3,
                        "date" : DateString(resultDataArray[i]?.created_at),
                        "oxygen_before": 0,
                        "oxygen_after": 0,
                        "urination_among" : 0,
                        "defecation_among" : 0,
                        "breath" : 0,
                        "heart_rate" : 0,
                        "blood_diastolic": 0,
                        "blood_systolic": 0
                    });
                    report.push({
                        "assessmentPeriod": 0,
                        "date" : DateString(resultDataArray[i]?.created_at),
                        "oxygen_before": 0,
                        "oxygen_after": 0,
                        "urination_among" : 0,
                        "defecation_among" : 0,
                        "breath" : 0,
                        "heart_rate" : 0,
                        "blood_diastolic": 0,
                        "blood_systolic": 0
                    });
                    tempData.push(30);
                    pulseData.push(40);
                }

                if( DateString(resultDataArray[i]?.created_at) === DateString(resultDataArray[i+1]?.created_at) && 
                    resultDataArray[i]?.assesment_period === 2 && resultDataArray[i+1]?.assesment_period === 1){
                    report.push({
                        "assessmentPeriod": 3,
                        "date" : DateString(resultDataArray[i]?.created_at),
                        "oxygen_before": 0,
                        "oxygen_after": 0,
                        "urination_among" : 0,
                        "defecation_among" : 0,
                        "breath" : 0,
                        "heart_rate" : 0,
                        "blood_diastolic": 0,
                        "blood_systolic": 0
                    });
                    report.push({
                        "assessmentPeriod": 0,
                        "date" : DateString(resultDataArray[i]?.created_at),
                        "oxygen_before": 0,
                        "oxygen_after": 0,
                        "urination_among" : 0,
                        "defecation_among" : 0,
                        "breath" : 0,
                        "heart_rate" : 0,
                        "blood_diastolic": 0,
                        "blood_systolic": 0
                    });
                    tempData.push(30);
                    pulseData.push(40);
                }
            }
            
    
            

        }else if(resultDataArray[0].assesment_period === 2){
            // if(i === 0){
            //     report.push({
            //         "assessmentPeriod": 1,
            //         "date" : DateString(resultDataArray[i]?.created_at),
            //         "oxygen_before": 0,
            //         "oxygen_after": 0,
            //         "urination_among" : 0,
            //         "defecation_among" : 0,
            //         "breath" : 0,
            //         "heart_rate" : 0,
            //         "blood_diastolic": 0,
            //         "blood_systolic": 0
            //     });
            //     tempData.push(30);
            //     pulseData.push(40);
            // }

            if(report.length === 0 || report.length === 4 || report.length === 8 || report.length === 12  ||
                report.length === 16 || report.length === 20 || report.length === 24 ||
                report.length === 28 || report.length === 32 || report.length === 36){
                    if(DateString(resultDataArray[i]?.created_at) !== DateString(resultDataArray[i-1]?.created_at) &&
                    resultDataArray[i]?.assesment_period === 2){
                        if(DateString(resultDataArray[i]?.created_at) === DateString(resultDataArray[i + 1]?.created_at) &&
                        resultDataArray[i+1]?.assesment_period === 1 ) {
                            report.push({
                                "assessmentPeriod": resultDataArray[i+1]?.assesment_period,
                                "date" : DateString(resultDataArray[i+1]?.created_at),
                                "oxygen_before": resultDataArray[i+1]?.oxygen_after,
                                "oxygen_after": resultDataArray[i+1]?.oxygen_before,
                                "urination_among" : resultDataArray[i+1]?.urination_among,
                                "defecation_among" : resultDataArray[i+1]?.defecation_among,
                                "breath" : resultDataArray[i+1]?.breath,
                                "heart_rate" : resultDataArray[i+1]?.heart_rate,
                                "blood_diastolic": resultDataArray[i+1]?.blood_diastolic,
                                "blood_systolic": resultDataArray[i+1]?.blood_systolic
                            });
                            
                            
                            tempData.push(resultDataArray[i+1].tempurature);
                            pulseData.push(resultDataArray[i+1]?.heart_rate);
    
                            value = i + 1;
                            
                        }else {
                            report.push({
                                "assessmentPeriod": 1,
                                "date" : DateString(resultDataArray[i]?.created_at),
                                "oxygen_before": 0,
                                "oxygen_after": 0,
                                "urination_among" : 0,
                                "defecation_among" : 0,
                                "breath" : 0,
                                "heart_rate" : 0,
                                "blood_diastolic": 0,
                                "blood_systolic": 0
                            });
                            tempData.push(30);
                            pulseData.push(40);
    
                        }
                    }
                    
            }
            
            if(value !== i ){
                report.push({
                    "assessmentPeriod": resultDataArray[i]?.assesment_period,
                    "date" : DateString(resultDataArray[i]?.created_at),
                    "oxygen_before": resultDataArray[i]?.oxygen_after,
                    "oxygen_after": resultDataArray[i]?.oxygen_before,
                    "urination_among" : resultDataArray[i]?.urination_among,
                    "defecation_among" : resultDataArray[i]?.defecation_among,
                    "breath" : resultDataArray[i]?.breath,
                    "heart_rate" : resultDataArray[i]?.heart_rate,
                    "blood_diastolic": resultDataArray[i]?.blood_diastolic,
                    "blood_systolic": resultDataArray[i]?.blood_systolic
                });
                tempData.push(resultDataArray[i].tempurature);
                pulseData.push(resultDataArray[i]?.heart_rate);

                if( DateString(resultDataArray[i]?.created_at) !== DateString(resultDataArray[i+1]?.created_at) && 
                resultDataArray[i]?.assesment_period === 1){
                    report.push({
                        "assessmentPeriod": 2,
                        "date" : DateString(resultDataArray[i]?.created_at),
                        "oxygen_before": 0,
                        "oxygen_after": 0,
                        "urination_among" : 0,
                        "defecation_among" : 0,
                        "breath" : 0,
                        "heart_rate" : 0,
                        "blood_diastolic": 0,
                        "blood_systolic": 0
                    });
                    tempData.push(30);
                    pulseData.push(40);
                }
    
                if( DateString(resultDataArray[i]?.created_at) !== DateString(resultDataArray[i+1]?.created_at) && 
                    resultDataArray[i]?.assesment_period === 1 && resultDataArray[i+1]?.assesment_period !== 3){
                    report.push({
                        "assessmentPeriod": 3,
                        "date" : DateString(resultDataArray[i]?.created_at),
                        "oxygen_before": 0,
                        "oxygen_after": 0,
                        "urination_among" : 0,
                        "defecation_among" : 0,
                        "breath" : 0,
                        "heart_rate" : 0,
                        "blood_diastolic": 0,
                        "blood_systolic": 0
                    });
                    report.push({
                        "assessmentPeriod": 0,
                        "date" : DateString(resultDataArray[i]?.created_at),
                        "oxygen_before": 0,
                        "oxygen_after": 0,
                        "urination_among" : 0,
                        "defecation_among" : 0,
                        "breath" : 0,
                        "heart_rate" : 0,
                        "blood_diastolic": 0,
                        "blood_systolic": 0
                    });
                    tempData.push(30);
                    pulseData.push(40);
                }
            
                if( DateString(resultDataArray[i]?.created_at) === DateString(resultDataArray[i+1]?.created_at) && 
                resultDataArray[i]?.assesment_period === 1 && resultDataArray[i+1]?.assesment_period !== 2){
                report.push({
                    "assessmentPeriod": 2,
                    "date" : DateString(resultDataArray[i]?.created_at),
                    "oxygen_before": 0,
                    "oxygen_after": 0,
                    "urination_among" : 0,
                    "defecation_among" : 0,
                    "breath" : 0,
                    "heart_rate" : 0,
                    "blood_diastolic": 0,
                    "blood_systolic": 0
                });
                tempData.push(30);
                pulseData.push(40);

                if(resultDataArray[i+1]?.assesment_period !== 3) {
                    report.push({
                        "assessmentPeriod": 3,
                        "date" : DateString(resultDataArray[i]?.created_at),
                        "oxygen_before": 0,
                        "oxygen_after": 0,
                        "urination_among" : 0,
                        "defecation_among" : 0,
                        "breath" : 0,
                        "heart_rate" : 0,
                        "blood_diastolic": 0,
                        "blood_systolic": 0
                    });
                    tempData.push(30);
                    pulseData.push(40);
                }
             }
            
                if( DateString(resultDataArray[i]?.created_at) !== DateString(resultDataArray[i+1]?.created_at) && 
                    resultDataArray[i]?.assesment_period === 2){
                    report.push({
                        "assessmentPeriod": 3,
                        "date" : DateString(resultDataArray[i]?.created_at),
                        "oxygen_before": 0,
                        "oxygen_after": 0,
                        "urination_among" : 0,
                        "defecation_among" : 0,
                        "breath" : 0,
                        "heart_rate" : 0,
                        "blood_diastolic": 0,
                        "blood_systolic": 0
                    });
                    report.push({
                        "assessmentPeriod": 0,
                        "date" : DateString(resultDataArray[i]?.created_at),
                        "oxygen_before": 0,
                        "oxygen_after": 0,
                        "urination_among" : 0,
                        "defecation_among" : 0,
                        "breath" : 0,
                        "heart_rate" : 0,
                        "blood_diastolic": 0,
                        "blood_systolic": 0
                    });
                    tempData.push(30);
                    pulseData.push(40);
                }

                if( DateString(resultDataArray[i]?.created_at) === DateString(resultDataArray[i+1]?.created_at) && 
                    resultDataArray[i]?.assesment_period === 2 && resultDataArray[i+1]?.assesment_period === 1){
                    report.push({
                        "assessmentPeriod": 3,
                        "date" : DateString(resultDataArray[i]?.created_at),
                        "oxygen_before": 0,
                        "oxygen_after": 0,
                        "urination_among" : 0,
                        "defecation_among" : 0,
                        "breath" : 0,
                        "heart_rate" : 0,
                        "blood_diastolic": 0,
                        "blood_systolic": 0
                    });
                    report.push({
                        "assessmentPeriod": 0,
                        "date" : DateString(resultDataArray[i]?.created_at),
                        "oxygen_before": 0,
                        "oxygen_after": 0,
                        "urination_among" : 0,
                        "defecation_among" : 0,
                        "breath" : 0,
                        "heart_rate" : 0,
                        "blood_diastolic": 0,
                        "blood_systolic": 0
                    });
                    tempData.push(30);
                    pulseData.push(40);
                }
            }
        }
            
        if(resultDataArray[i].assesment_period === 3){
            report.push({
                "assessmentPeriod": 0,
                "date" : DateString(resultDataArray[i]?.created_at),
                "oxygen_before": 0,
                "oxygen_after": 0,
                "urination_among" : 0,
                "defecation_among" : 0,
                "breath" : 0,
                "heart_rate" : 0,
                "blood_diastolic": 0,
                "blood_systolic": 0
            });
        }
            
        
      }

      if(tempData.length<30){
        let leftCount = 30 - tempData.length;
        for(let i=0; i< leftCount; i++){
          tempData.push(30);
          pulseData.push(40);
        }
      }

      if(report.length<40){
        let leftCount = 40 - report.length;
        for(let i=0; i< leftCount; i++){
          report.push({
            "assessmentPeriod": 0,
           "date" : "",
           "oxygen_before": 0,
           "oxygen_after": 0,
           "urination_among" : 0,
           "defecation_among" : 0,
           "breath" : 0,
           "heart_rate" : 0,
           "blood_diastolic": 0,
           "blood_systolic": 0
          })
        }
      }
    }
    
    return {
      result_vitalSign: res?.data ? res?.data : null,
      reportData: report,
      tempData: tempData ,
      pulseData : pulseData,
      dateArray: dateArray,
      xAxisArray: xAxisArray,
      weightHeightData: weightHeightData,
      loading_vitalSign: loading,
      error_vitalSign: error,
    };
  };



  export const GetDiagnosesData = (userId: string, changeState?: boolean) => {
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
      data: any;
    }>
    ({
      url: `/v2/dashboard/user/${userId}/export-vital`,
      method: "GET",
      data: {
        userId,
      },
      params:{
        changeState: changeState
      }
    });
  
    let resultData = res?.data;
    let report:{
        "id": string,
        "assessmentPeriod": number,
        "date" : Date | string | null,
        "oxygen_before": number,
        "oxygen_after": number,
        "temprature" : number,
        "breath" : number,
        "heart_rate" : number,
        "blood_diastolic": number,
        "blood_systolic": number,
        "blood_sugar" : number;
        "symptoms_one" : string,
        "symptoms_two": string,
        "recorder": string,
        "defecation_among": number,
        "urination_among": number,
        "is_abnormal": boolean,
        "symptoms_case": any
    }[] = [];
    let tempData: any = [];
    let pulseData: any = [];
    let startDate;
    let dateArray:string[] = [];
    let xAxisArray: any= [];
    let weightHeightData: number[] = [];
    let value : number = 41;
    if (resultData) {
      let resultDataArray = resultData?.result;
      
      let startdate = DateStringAPI(resultData.to);
      startDate = new Date(startdate);
      let endDate = DateStringAPI(resultDataArray[resultDataArray.length - 1]?.created_at)
      endDate = new Date(endDate);
      
        // dateArray.push(dateStrFormat(resultData.from));
        for(let i= 0; i<resultDataArray.length; i++){
            // if(dateArray[dateArray.length - 1] !== dateStrFormatBuddhist(resultDataArray[i]?.created_at)){
                dateArray.push(dateStrFormatBuddhist(resultDataArray[i]?.created_at));
            // }  
        }
        if(dateArray.length < 11){
            let dateArrayLength = 11 - dateArray.length;
            for(let i=0; i< dateArrayLength; i++){
                // startDate.setDate(startDate.getDate() + 1);
                // let date = dateStrFormatBuddhist(startDate)?.toString();
                // if(date){
                //     dateArray.push(date);
                // } 
              //   endDate.setDate(endDate.getDate() + 1);
              // let date = dateStrFormatBuddhist(endDate)?.toString();
              // if(date){
              //     dateArray.push(date);
              // }
              dateArray.push('');
            }
        }
        
        for(let i=0; i<10; i ++){
            weightHeightData.push(resultData?.user?.health?.weight);
            weightHeightData.push(resultData?.user?.health?.height);
        }
      for(let i= 0; i<resultDataArray.length; i++){
        report.push({
            "id": resultDataArray[i]?.id,
            "assessmentPeriod": resultDataArray[i]?.assesment_period,
            "date" : DateString(resultDataArray[i]?.created_at),
            "oxygen_before": resultDataArray[i]?.oxygen_after,
            "oxygen_after": resultDataArray[i]?.oxygen_before,
            "temprature" : resultDataArray[i]?.tempurature,
            "breath" : resultDataArray[i]?.breath,
            "heart_rate" : resultDataArray[i]?.heart_rate,
            "blood_diastolic": resultDataArray[i]?.blood_diastolic,
            "blood_systolic": resultDataArray[i]?.blood_systolic,
            "blood_sugar" : resultDataArray[i]?.blood_sugar,
            "symptoms_one" : resultDataArray[i]?.symptoms_one,
            "symptoms_two": resultDataArray[i]?.symptoms_two,
            "recorder": resultDataArray[i]?.recorder,
            "defecation_among": resultDataArray[i]?.defecation_among,
            "urination_among" : resultDataArray[i]?.urination_among,
            "is_abnormal" : resultDataArray[i]?.is_abnormal,
            "symptoms_case": resultDataArray[i]?.symptom_case
        });
      }


      if(report.length<10){
        let leftCount = 10 - report.length;
        for(let i=0; i< leftCount; i++){
          report.push({
            "assessmentPeriod": 0,
           "date" : "",
           "oxygen_before": 0,
           "oxygen_after": 0,
           "temprature" : 0,
           "breath" : 0,
           "heart_rate" : 0,
           "blood_diastolic": 0,
           "blood_systolic": 0,
           "symptoms_one" : "",
            "symptoms_two": "",
            "recorder": "",
            "id" : '',
            "blood_sugar": 0,
            "urination_among": 0,
            "defecation_among": 0,
            "is_abnormal": false,
            "symptoms_case": 0
          })
        }
      }
    }
    
    return {
      result_vitalSign: res?.data ? res?.data : null,
      reportData: report,
      dateArray: dateArray,
      loading_vitalSign: loading,
      error_vitalSign: error,
    };
  };

  export const GetDiagnosesExportData = (userId: string) => {
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
      data: any;
    }>
    ({
      url: `/v2/dashboard/user/detail/${userId}/export-diagnose`,
      method: "GET",
      data: {
        userId,
      },
    });
  
    return {
      result_exportData: res?.data ? res?.data : null,
      loading_exportData: loading,
      error_exportData: error,
    };
  };

  export const UpdateDiagnosesExportData = (userId: string) => {
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
        code: 0 | 1;
        message: string;
        data : any;
      }>(
        {
          url:  `/v2/dashboard/user/export-diagnose/${userId}/create`,
          method: "POST",
        },
        {
          manual: true,
        }
      );
    
      return {
        result_updateData: res?.code === 1 ? res?.data : null,
        loading_updateData: loading,
        error_updateData: res?.code === 0 ? res?.message : null,
        fire_updateData: (userInput: ExportUpdateData) => {
          console.log("userInput:", userInput);
          let inputData = {};
          inputData = {
            "psychological_problem": {
                "is_depression": userInput.is_depression,
                "is_anxiety": userInput.is_anxiety,
                "is_stigma": userInput.is_stigma,
                "is_other": userInput.is_other,
                "other": userInput.other
            },
            "plan_support_one": {
                "is_patient_plan": userInput.is_patient_plan,
                "is_cheer_up": userInput.is_cheer_up,
                "is_empty": userInput.is_empty,
                "empty": userInput.empty,
                "psychiatrist_need": userInput.psychiatrist_need,
                "psychotherapist_need": userInput.psychotherapist_need
            },
            "medicines": {
                "is_andrographis_paniculata": userInput.is_andrographis_paniculata,
                "andrographis_paniculata_start": dateStrAPIFormat(userInput.andrographis_paniculata_start),
                "andrographis_paniculata_end": dateStrAPIFormat(userInput.andrographis_paniculata_end),
                "is_favipiravir": userInput.is_favipiravir,
                "favipiravir_start": dateStrAPIFormat(userInput.favipiravir_start),
                "favipiravir_end": dateStrAPIFormat(userInput.favipiravir_end),
                "is_empty": userInput.is_empty_medicine,
                "empty": userInput.empty_medicine
            },
            "complications": {
                "is_tired": userInput.is_tired,
                "is_cough": userInput.is_cough,
                "is_fever": userInput.is_fever,
                "is_chest_pain": userInput.is_chest_pain,
                "is_other": userInput.is_other_complication,
                "other": userInput.other_complication
            },
            "treatments": {
                "is_o2_therapy": userInput.is_o2_therapy,
                "o2_therapy": userInput.o2_therapy,
                "is_prone": userInput.is_prone,
                "is_medication": userInput.is_medication,
                "medication": userInput.medication,
                "is_refer": userInput.is_refer,
                "refer": userInput.refer,
                "is_other": userInput.is_other_treament,
                "other": userInput.other_treatment
            },
            "summary": {
                "body_temp": userInput.body_temp,
                "pulse": userInput.pulse,
                "respiration_rate": userInput.respiration_rate,
                "symptoms": userInput.symptoms,
                "is_full_day": userInput.is_full_day,
                "is_refer": userInput.is_refer_summary,
                "refer_date": dateStrAPIFormat(userInput.refer_date),
                "is_hospital": userInput.is_hospital,
                "hospital": userInput.hospital,
                "is_back_home": userInput.is_back_home,
                "home": userInput.home,
                "is_other": userInput.is_other_summary,
                "other": userInput.other_summary
            },
            "social_issues": {
                "is_home_infect": userInput.is_home_infect,
                "home_infect": userInput.home_infect,
                "is_home_risk": userInput.is_home_risk,
                "home_risk": userInput.home_risk,
                "is_pui": userInput.is_pui,
                "pui": userInput.pui,
                "is_home_treatment": userInput.is_home_treatment,
                "home_treatment": userInput.home_treatment,
                "is_special": userInput.is_special,
                "is_poor": userInput.is_poor
            },
            "plan_support_two": {
                "is_new": userInput.is_new,
                "is_refer_urgent": userInput.is_refer_urgent,
                "is_recommend": userInput.is_recommend,
                "is_new_patient": userInput.is_new_patient,
                "is_refer": userInput.is_refer_planSupport,
                "psychiatrist_need": userInput.psychiatrist_need,
                "psychotherapist_need": userInput.psychotherapist_need,
                "longterm_care_need": userInput.longterm_care_need,
                "is_other":userInput.is_other_planSupport,
                "other":userInput.other_planSupport,
                "socia_work":userInput.socia_work
            }
        }
    
          console.log("input data:", inputData)
          
          return new Promise((resolve, reject) => {
            fire({
              data: inputData,
            })
              .then(({ data: { data, code, message } }) => {
                if (code === 0) {
                  reject(message);
                } else {
                  resolve(data);
                }
              })
              .catch((ex) => {
                reject(ex.toString());
              });
          });
        },
      };
  }

//   /v2/chalong/user/758d71d7-0f68-4f88-97e4-022eb6f16f6f/detail

export const GetFirstPageData = (userId: string) => {
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
        data: any
      }>(
        {
          url:  `/v2/dashboard/user/pocc/${userId}/form-check`,
          method: "GET",
          data: {
            userId,
          },
        }
      );
    //   7499514b-af6e-44c7-b945-87c30ef8559d
      return {
        result_data: res?.data ? res?.data : null,
        loading_data: loading,
        error_data: error
      };
  }

  export const Image_atk_Data = (userId: string) => {
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
        data: any
      }>(
        {
          headers: {
              'Content-Type': 'image/png'
          },
          url:  `/v2/dashboard/user/id-card-atk/${userId}/photo`,
          method: "GET",
          data: {
            userId,
          },
          responseType: 'blob',
        }
      );
    //   7499514b-af6e-44c7-b945-87c30ef8559d
      return {
        result_image_atk_data:  res,
        loading_image_atk_data: loading,
        error_image_atk_data: error
      };
  }

  export const Image_IdCard_Data = (userId: string) => {
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
        data: any
      }>(
        {
          headers: {
              'Content-Type': 'image/png'
          },
          url:  `/v2/dashboard/user/id-card/${userId}/photo`,
          method: "GET",
          data: {
            userId,
          },
          responseType: 'blob',
        }
      );
    //   7499514b-af6e-44c7-b945-87c30ef8559d
      return {
        result_image_IdCard_data:  res,
        loading_image_IdCard_data: loading,
        error_image_IdCard_data: error
      };
  }

  // https://api.space-trax.com/v2/dashboard/patient-admitted/101803ce-75d3-441c-85fb-2e5426703ca9/update
  export const UpdateDischargeInfo = (userId: string) => {
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
        code: 0 | 1;
        message: string;
        data : any;
      }>(
        {
          url:  `/v2/dashboard/patient-admitted/${userId}/update`,
          method: "PUT",
        },
        {
          manual: true,
        }
      );
    
      return {
        result_updateDischargeData: res?.code === 1 ? res?.data : null,
        loading_updateDischargeData: loading,
        error_updateDischargeData: res?.code === 0 ? res?.message : null,
        fire_updateDischargeData: (userInput: UpdateDischargeData) => {
          console.log("discharge data:", userInput);
          console.log("vaccine value:", userInput.vaccine);
          let inputData = {};
          if( userInput.date_of_birth ){
            let date = userInput.date_of_birth;
            if(date.includes("/")){
              let splitDate = date.split('/');
              let changeYear;
              changeYear = parseInt(splitDate[2]) - 543;
              
              let dateString = changeYear + '-' +splitDate[1] + '-'+ splitDate[0] ;
              console.log("datestring;", dateString);
              userInput.date_of_birth = dateString;
            }
          }else {
            userInput.date_of_birth= '';
          }
          if(userInput.gender === 'ชาย'){
            userInput.gender = 'M';
          }else{
            userInput.gender = 'F';
          }
          inputData =  userInput;
          if(userInput){
            inputData= {
              "user": {
                "address": userInput.address,
                "age": userInput.age,
                "authen_code": userInput.authen_code,
                "created_at": userInput.created_at,
                "date_admitted": userInput.date_admitted,
                "date_finished": userInput.date_finished,
                "date_of_birth": userInput.date_of_birth,
                "date_result": userInput.date_result,
                "gender": userInput.gender,
                "hn_number": userInput.hn_number,
                "id" : userInput.id,
                "id_line": userInput.id_line,
                // "inspection_unit": userInput.inspection_unit,
                // "inspection_unit_code": userInput.inspection_unit_code,
                "name": userInput.name,
                "phone_number": userInput.phone_number,
                "pid": userInput.pid,
                "process_status": 0,
                "recorder": userInput.recorder,
                "recorder_code": userInput.recorder_code,
                "right_a": userInput.right_a,
                "surname": userInput.surname,
                "symptom": userInput.symptom,
                "title_name" : userInput.title_name,
                "updated_at": userInput.updated_at
              },
              "health_checked":{
                "created_at": userInput.healthChecked_created_at,
                "health_checked":{
                   "blood_pressure": userInput.blood_pressure,
                   "body_temp": userInput.body_temp_first,
                   "height": userInput.height,
                   "lmp": userInput.lmp,
                   "pulse": userInput.pulse_first,
                   "respiration_rate": userInput.respiration_rate_first,
                   "weight": userInput.weight
                },
                "id": userInput.healthChecked_id,
                "lab_results":{
                   "is_normal": userInput.is_normal,
                   "is_xray": userInput.is_xray,
                   "result": userInput.result
                },
                "medicine_prepare":{
                   "autoclave_bag": userInput.autoclave_bag,
                   "inhaler": userInput.inhaler,
                   "mask": userInput.mask,
                   "medicine": userInput.medicine,
                   "oximeter": userInput.oximeter,
                   "oxygen_concentrator": userInput.oxygen_concentrator,
                   "thermometer": userInput.thermometer
                },
                "screening_results":{
                   "is_rapid_test": userInput.is_rapid_test,
                   "is_rtpcr_test": userInput.is_rtpcr_test,
                   "rapid_date": userInput.rapid_date,
                   "rapid_place": userInput.rapid_place,
                   "rapid_result":  userInput.rapid_result,
                   "rtpcr_date": userInput.rtpcr_date,
                   "rtpcr_place": userInput.rtpcr_place,
                   "rtpcr_result": userInput.rtpcr_result
                },
                "test_exercise":{
                   "is_positive": userInput.is_positive,
                   "oxygen_after": userInput.oxygen_after,
                   "oxygen_before": userInput.oxygen_before
                },
                "updated_at": userInput.healthChecked_updated_at
              },
             "symptoms":{
                  "created_at": userInput.symptoms_created_at,
                  "id": userInput.symptoms_id,
                  "mental":{
                      "feeling": userInput.feeling,
                      "is_effective": userInput.is_effective,
                      "mental_one": userInput.mental_one,
                      "mental_two": userInput.mental_two
                  },
                  "normal_symptoms":{
                      "cough": userInput.cough,
                      "fever_low": userInput.fever_low,
                      "no_symptom": userInput.no_symptom,
                      "snot": userInput.snot
                  },
                  "risk_factor":{
                      "absolute_lymphocyte": userInput.absolute_lymphocyte,
                      "age": userInput.risk_factor_age,
                      "bmi":userInput.bmi,
                      "chronic_heart_disease": userInput.chronic_heart_disease,
                      "cirrhosis": userInput.cirrhosis,
                      "ckd": userInput.ckd,
                      "copd": userInput.copd,
                      "cva": userInput.cva,
                      "immunocompromise": userInput.immunocompromise,
                      "t2dm": userInput.t2dm
                  },
                  "risk_symptoms":{
                      "chest_tightness": userInput.chest_tightness,
                      "fatigue": userInput.fatigue,
                      "fever": userInput.fever,
                      "liquid_stool": userInput.liquid_stool,
                      "poor_appetite": userInput.poor_appetite,
                      "puke": userInput.puke,
                      "severe_cough": userInput.severe_cough
                  },
                  "updated_at": userInput.updated_at,
                  "vaccine":{
                      "az_one": false,
                      "az_two": false,
                      "get_vaccine": false,
                      "is_other": false,
                      "other": "",
                      "sino_one_az_one": false,
                      "sino_two_az_one": false,
                      "sino_two_az_two": false,
                      "siv_one": false,
                      "siv_two": false
                  }
              },
              "treatment_orders":{
                "created_at": userInput.treatmentOrder_created_at,
                "id": userInput.treatmentOrder_id,
                "level_one":{
                   "bromhexine": userInput.bromhexine,
                   "cetirizine": userInput.cetirizine,
                   "cpm": userInput.cpm,
                   "dextromethorphan": userInput.dextromethorphan,
                   "green": userInput.green,
                   "indian_gooseberry": userInput.indian_gooseberry,
                   "is_patient_medicines": userInput.is_patient_medicines,
                   "paracetamol": userInput.paracetamol,
                   "patient_medicines": userInput.patient_medicines
                },
                "level_three":{
                   "ci": userInput.ci,
                   "further_hospital": userInput.further_hospital,
                   "hi_hospital": userInput.hi_hospital,
                   "hi_shph": userInput.hi_shph,
                   "hospital": userInput.hospital,
                   "is_admit": userInput.is_admit,
                   "is_ci": userInput.is_ci,
                   "is_further_hospital": userInput.is_further_hospital,
                   "is_hi_hospital": userInput.is_hi_hospital,
                   "is_hi_shph": userInput.is_hi_shph,
                   "is_not_participate": userInput.is_not_participate,
                   "is_refer_hospital": userInput.is_refer_hospital,
                   "red": userInput.red
                },
                "level_two":{
                   "andrographis_paniculata": userInput.andrographis_paniculata,
                   "day_five": userInput.day_five,
                   "day_one": userInput.day_one,
                   "favipiravia": userInput.favipiravia,
                   "is_day_five": userInput.is_day_five,
                   "yellow": userInput.yellow
                },
                "updated_at": userInput.treatmentOrder_updated_at
              },
            }
          }
          
          console.log("discharge update data:", inputData)
          
          return new Promise((resolve, reject) => {
            fire({
              data: inputData,
            })
              .then(({ data: { data, code, message } }) => {
                if (code === 0) {
                  reject(message);
                } else {
                  resolve(data);
                }
              })
              .catch((ex) => {
                reject(ex.toString());
              });
          });
        },
      };
  }

  export const GetDischargeFormData = (userId: string) => {
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
        data: any
      }>(
        {
          url:  `v2/pocc/user/detail/${userId}/patient`,
          method: "GET",
          data: {
            userId,
          },
        }
      );
        console.log("dischargeData: ", res?.data);
      return {
        result_dischargeData: res?.data ? res?.data : null,
        loading_dischargeData: loading,
        error_dischargeData: error
      };
  }
  

  export const UpdateDischargeForm = (userId: string) => {
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
        code: 0 | 1;
        message: string;
        data : any;
      }>(
        {
          url:  `/v2/dashboard/patient-admitted/${userId}/update`,
          method: "PUT",
        },
        {
          manual: true,
        }
      );
    
      return {
        result_updateDischargeData: res?.code === 1 ? res?.data : null,
        loading_updateDischargeData: loading,
        error_updateDischargeData: res?.code === 0 ? res?.message : null,
        fire_updateDischargeData: (userInput: DischargeForUpdate) => {
          let inputData = {};
          let checkDateTime;
          let afterCheckDateTime;
          console.log('check date time:', userInput.check_time?.toString());
          console.log('after check date time:', userInput.after_check_time?.toString());
          if( userInput.date_of_birth ){
            let date = userInput.date_of_birth;
            if(date.includes("/")){
              let splitDate = date.split('/');
              let changeYear;
              changeYear = parseInt(splitDate[2]) - 543;
              
              let dateString = changeYear + '-' +splitDate[1] + '-'+ splitDate[0] ;
              console.log("datestring;", dateString);
              userInput.date_of_birth = dateString;
            }
          }else {
            userInput.date_of_birth= '';
          }
          if(userInput.gender === 'ชาย'){
            userInput.gender = 'M';
          }else{
            userInput.gender = 'F';
          }

          if(userInput.after_check_date === '0001-01-01T00:00:00Z'){
            // userInput.after_check_date = "";
            afterCheckDateTime = "";
          }
          
          if((userInput.after_check_date)?.toString().includes("+")){
            let date = userInput.after_check_date.toString();
            let splitDate = date.split('T');
            userInput.after_check_date = splitDate[0];
          }else if((userInput.after_check_date)?.toString().includes("Z")){
            let date = userInput.after_check_date.toString();
            let splitDate = date.split('T');
            userInput.after_check_date = splitDate[0];
          }

          if((userInput.after_check_time)?.toString().includes("+")){
            let date = userInput.after_check_time.toString();
            let splitDate = date.split('T');
            let splitTime = splitDate[1]?.split('+');
            afterCheckDateTime = userInput.after_check_date+"T"+splitTime[0];
          }else if((userInput.after_check_time)?.toString().includes("Z")){
            let date = userInput.after_check_time.toString();
            let splitDate = date.split('T');
            let splitTime = splitDate[1]?.split('Z');
            afterCheckDateTime = userInput.after_check_date+"T"+splitTime[0];
          }

          if(userInput.check_date === '0001-01-01T00:00:00Z'){
            // userInput.check_date = "";
            checkDateTime = "";
          };

          if((userInput.check_date)?.toString().includes("+")){
            let date = userInput.check_date.toString();
            let splitDate = date.split('T');
            userInput.check_date = splitDate[0];
          }else if((userInput.check_date)?.toString().includes("Z")){
            let date = userInput.check_date.toString();
            let splitDate = date.split('T');
            userInput.check_date = splitDate[0];
          }

          if((userInput.check_time)?.toString().includes("+")){
            let date = userInput.check_time.toString();
            let splitDate = date.split('T');
            let splitTime = splitDate[1]?.split('+');
            checkDateTime = userInput.check_date+"T"+splitTime[0];
          }else if((userInput.check_time)?.toString().includes("Z")){
            let date = userInput.check_time.toString();
            let splitDate = date.split('T');
            let splitTime = splitDate[1]?.split('Z');
            checkDateTime = userInput.check_date+"T"+splitTime[0];
          }

          inputData =  userInput;
          if(userInput){
            inputData= {
              "user": {
                "address": userInput.address,
                "age": userInput.age,
                "authen_code": userInput.authen_code,
                // "created_at": userInput.created_at,
                "date_admitted": userInput.date_admitted,
                "date_finished": userInput.date_finished,
                "date_of_birth": userInput.date_of_birth,
                "date_result": userInput.date_result,
                "gender": userInput.gender,
                "hn_number": userInput.hn_number,
                "id" : userInput.id,
                "id_line": userInput.id_line,
                "inspection_unit": userInput.inspection_unit,
                "inspection_unit_code": userInput.inspection_unit_code,
                "name": userInput.name,
                "phone_number": userInput.phone_number,
                "pid": userInput.pid,
                "process_status": 0,
                "recorder": userInput.recorder,
                "recorder_code": userInput.recorder_code,
                "right_a": userInput.right_a,
                "staff_id": userInput.staff_id,
                "staff_type": "",
                "surname": userInput.surname,
                "symptom": userInput.symptom,
                "title_name" : userInput.title_name,
                // "updated_at": userInput.updated_at
              },
              "health_checked":{
                "created_at": userInput.healthChecked_created_at,
                "health_checked":{
                   "blood_pressure": userInput.blood_pressure,
                   "body_temp": userInput.body_temp_first,
                   "height": userInput.height,
                   "lmp": userInput.lmp,
                   "pulse": userInput.pulse_first,
                   "respiration_rate": userInput.respiration_rate_first,
                   "weight": userInput.weight
                },
                "id": userInput.healthChecked_id,
                "lab_results":{
                   "is_normal": userInput.is_normal,
                   "is_xray": userInput.is_xray,
                   "result": userInput.result
                },
                "medicine_prepare":{
                   "autoclave_bag": userInput.autoclave_bag,
                   "inhaler": userInput.inhaler,
                   "mask": userInput.mask,
                   "medicine": userInput.medicine,
                   "oximeter": userInput.oximeter,
                   "oxygen_concentrator": userInput.oxygen_concentrator,
                   "thermometer": userInput.thermometer
                },
                "screening_results":{
                   "is_rapid_test": userInput.is_rapid_test,
                   "is_rtpcr_test": userInput.is_rtpcr_test,
                   "rapid_date": userInput.rapid_date,
                   "rapid_place": userInput.rapid_place,
                   "rapid_result":  userInput.rapid_result,
                   "rtpcr_date": userInput.rtpcr_date,
                   "rtpcr_place": userInput.rtpcr_place,
                   "rtpcr_result": userInput.rtpcr_result
                },
                "test_exercise":{
                   "is_positive": userInput.is_positive,
                   "oxygen_after": userInput.oxygen_after,
                   "oxygen_before": userInput.oxygen_before
                },
                "updated_at": userInput.healthChecked_updated_at
              },
             "symptoms":{
                  "created_at": userInput.symptoms_created_at,
                  "id": userInput.symptoms_id,
                  "mental":{
                      "feeling": userInput.feeling,
                      "is_effective": userInput.is_effective,
                      "mental_one": userInput.mental_one,
                      "mental_two": userInput.mental_two
                  },
                  "normal_symptoms":{
                      "cough": userInput.cough,
                      "fever_low": userInput.fever_low,
                      "no_symptom": userInput.no_symptom,
                      "snot": userInput.snot
                  },
                  "risk_factor":{
                      "absolute_lymphocyte": userInput.absolute_lymphocyte,
                      "age": userInput.risk_factor_age,
                      "bmi":userInput.bmi,
                      "cancer": userInput.cancer,
                      "cardiovascular_diseases" : userInput.cardiovascular_diseases,
                      "chronic_heart_disease": userInput.chronic_heart_disease,
                      "cirrhosis": userInput.cirrhosis,
                      "ckd": userInput.ckd,
                      "copd": userInput.copd,
                      "cva": userInput.cva,
                      "diabetes": userInput.diabetes,
                      "immunocompromise": userInput.immunocompromise,
                      "plan": userInput.plan,
                      "pregnant" : userInput.pregnant,
                      "problems_diagnoses" : userInput.problems_diagnoses,
                      "respiratory_disease" : userInput.respiratory_disease,
                      "t2dm": userInput.t2dm
                  },
                  "risk_symptoms":{
                      "chest_tightness": userInput.chest_tightness,
                      "fatigue": userInput.fatigue,
                      "fever": userInput.fever,
                      "liquid_stool": userInput.liquid_stool,
                      "poor_appetite": userInput.poor_appetite,
                      "puke": userInput.puke,
                      "severe_cough": userInput.severe_cough
                  },
                  "updated_at": userInput.symptoms_updated_at,
                  "vaccine":{
                      "az_one": userInput.az_one,
                      "az_two": userInput.az_two,
                      "get_vaccine": userInput.get_vaccine,
                      "is_other": userInput.is_other,
                      "other": userInput.other,
                      "sino_one_az_one": userInput.sino_one_az_one,
                      "sino_two_az_one": userInput.sino_two_az_one,
                      "sino_two_az_two": userInput.sino_two_az_one,
                      "siv_one": userInput.siv_one,
                      "siv_two": userInput.siv_two
                  }
              },
              "treatment_orders":{
                "created_at": userInput.treatmentOrder_created_at,
                "id": userInput.treatmentOrder_id,
                "level_one":{
                   "bromhexine": userInput.bromhexine,
                   "cetirizine": userInput.cetirizine,
                   "cpm": userInput.cpm,
                   "cpm_amount"  : userInput.cpm_amount,
                   "dextromethorphan": userInput.dextromethorphan,
                   "dextromethorphan_amount" : userInput.dextromethorphan_amount,
                   "green": userInput.green,
                   "indian_gooseberry": userInput.indian_gooseberry,
                   "ors": userInput.ors,
                   "ors_amount" : userInput.ors_amount,
                   "is_patient_medicines": userInput.is_patient_medicines,
                   "paracetamol": userInput.paracetamol,
                   "paracetamol_amount": userInput.paracetamol_amount,
                   "patient_medicines": userInput.patient_medicines
                },
                "level_three":{
                   "ci": userInput.ci,
                   "further_hospital": userInput.further_hospital,
                   "hi_hospital": userInput.hi_hospital,
                   "hi_shph": userInput.hi_shph,
                   "hospital": userInput.hospital,
                   "is_admit": userInput.is_admit,
                   "is_ci": userInput.is_ci,
                   "is_further_hospital": userInput.is_further_hospital,
                   "is_hi_hospital": userInput.is_hi_hospital,
                   "is_hi_shph": userInput.is_hi_shph,
                   "is_not_participate": userInput.is_not_participate,
                   "is_op_shph" : userInput.is_op_shph,
                   "is_refer_hospital": userInput.is_refer_hospital,
                   "red": userInput.red,
                   "refer_cause" : userInput.refer_cause,
                   "refer_for" : userInput.refer_for
                },
                "level_two":{
                   "andrographis_paniculata": userInput.andrographis_paniculata,
                   "andrographis_paniculata_amount" : userInput.andrographis_paniculata_amount,
                   "day_five": userInput.day_five,
                   "day_one": userInput.day_one,
                   "favipiravia": userInput.favipiravia,
                   "favipiravia_amount": userInput.favipiravia_amount,
                   "is_day_five": userInput.is_day_five,
                   "yellow": userInput.yellow
                },
                "updated_at": userInput.treatmentOrder_updated_at
              },
              "followup_form": {
                // "after_check_date": userInput.after_check_date,
                "after_check_date": afterCheckDateTime,
                // "check_date": userInput.check_date,
                "check_date": checkDateTime,
                "created_at": userInput.followup_created_at,
                "id": userInput.followup_id,
                "symptom_check": {
                    "chest_pain": userInput.chest_pain_before,
                    "cough": userInput.cough_before,
                    "fever": userInput.fever_before,
                    "other": userInput.other_before,
                    "other_after_ans": userInput.other_after_ans_before,
                    "other_before_ans": userInput.other_before_ans_before,
                    "rest_o2": userInput.rest_o2_before,
                    "tired": userInput.tired_before,
                    "treatment": userInput.treatment_before
                },
                "symptom_check_after": {
                    "chest_pain": userInput.chest_pain_after,
                    "cough": userInput.cough_after,
                    "fever": userInput.fever_after,
                    "other": userInput.other_after,
                    "other_after_ans": userInput.other_after_ans_after,
                    "other_before_ans": userInput.other_before_ans_after,
                    "rest_o2": userInput.rest_o2_after,
                    "tired": userInput.tired_after,
                    "treatment": userInput.treatment_after
                },
                "updated_at": userInput.followup_updated_at
            }
            }
          }
          
          console.log("discharge update data:", inputData)
          
          return new Promise((resolve, reject) => {
            fire({
              data: inputData,
            })
              .then(({ data: { data, code, message } }) => {
                if (code === 0) {
                  reject(message);
                } else {
                  resolve(data);
                }
              })
              .catch((ex) => {
                reject(ex.toString());
              });
          });
        },
      };
  }

  export const UpdateVitalSigns = () => {
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
        code: 0 | 1;
        message: string;
        data : any;
      }>(
        {
          url:  `/v2/dashboard/user/daily/update`,
          method: "POST",
        },
        {
          manual: true,
        }
      );
    
      return {
        result_updateVitalSigns: res?.code === 1 ? res?.data : null,
        loading_updateVitalSigns: loading,
        error_updateVitalSigns: res?.code === 0 ? res?.message : null,
        fire_updateVitalSigns: (userInput: VitalSignData) => {
          console.log("userInput:", userInput);
          let inputData = {};
          if(userInput.symptoms_one || userInput.symptoms_two){
            userInput.is_abnormal = true;
          }else{
            userInput.is_abnormal = false;
          }
          inputData = {
            "id": userInput.id,
            "assesment_period": userInput.assesment_period,
            "blood_diastolic": userInput.blood_diastolic,
            "blood_sugar": userInput.blood_sugar,
            "blood_systolic": userInput.blood_systolic,
            "breath":  userInput.breath,
            "defecation_among": userInput.defecation_among,
            "heart_rate": parseInt(userInput.heart_rate),
            "is_abnormal": userInput.is_abnormal,
            "oxygen_after": parseInt(userInput.oxygen_after),
            "oxygen_before": userInput.oxygen_before,
            "recorder": userInput.recorder,
            "symptom_case": userInput.symptom_case,
            "symptoms_one": userInput.symptoms_one,
            "symptoms_two": userInput.symptoms_two,
            "tempurature": parseFloat(userInput.tempurature),
            "urination_among": userInput.urination_among
          }
    
          console.log("update vitalsigns data:", inputData)
          
          return new Promise((resolve, reject) => {
            fire({
              data: inputData,
            })
              .then(({ data: { data, code, message } }) => {
                if (code === 0) {
                  reject(message);
                } else {
                  resolve(data);
                }
              })
              .catch((ex) => {
                reject(ex.toString());
              });
          });
        },
      };
  }