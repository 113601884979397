import React, { Fragment, useEffect, useState,useRef } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
  withStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Modal,
  LinearProgress,
  Avatar,
  TextField
} from "@material-ui/core";

import SaveAltIcon from "@material-ui/icons/SaveAlt";
import Skeleton from "@material-ui/lab/Skeleton";
import { StickyContainer, Sticky } from "react-sticky";
import { SubBar } from "../Dashboard/Dashboard";
import { useParams } from "react-router-dom";
import axios, { AxiosRequestConfig, Method } from 'axios';
import {
  ReadUserFilter,
  useReadUser,
  useReadUserUpdate,
  GetStatusForUser,
  UpdateQuarantineStatus,
  CallDailyReports,
  CreateNote,
  GetContactTracerInfo,
  ContactTracerHistroyLog
} from "../../services/api/user/useReadUser";
import LuxonUtils from "@date-io/luxon";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { webStore } from "../../stores/webStore";
import { observer } from "mobx-react-lite";
import { EditField, UserInfoCard, UserProfile } from "./UserInfoCard";
import { DeviceHistoryDatagrid } from "./DeviceHistoryDatagrid";
import { DateFilter } from "../../components/DateFilter";
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import { BodyTempGraph } from "./BodyTempGraph";
import { HeartRateGraph } from "./HeartRateGraph";
import { BloodPressureGraph } from "./BloodPressureGrph";
import { OximeterGraph } from "./OximeterGraph";
import { Map } from "../../components/Map";
import { ContactTracerMap } from "../../components/ContactTracerMap";
import CheckIcon from "@material-ui/icons/Check";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordion from "@material-ui/core/Accordion";
import WatchIcon from "@material-ui/icons/Watch";
import NightsStayIcon from '@material-ui/icons/NightsStay';
import NightsStayOutlinedIcon from '@material-ui/icons/NightsStayOutlined';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined';
import { dateStr, dateStrAPI, timeStr, dateStrAPIFormat,DateTimeString } from "../../utils";
import { useUpdateUser } from "../../services/api/user/useUpdateUser";
import { useSnackbar } from "notistack";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

import {
  BatteryHistoryReport,
  HeartRateHistoryReport,
  BloodPressureHistoryReport,
  WearingHistoryReport,
  TempHistoryReport,
  LocationsReport,
  OximeterHistoryReport,
  BreathHistoryReport,
  BloodSugarHistoryReport
} from "../../types/personalReport";
import { dailyReport } from "../../types/user";
import { RightEmptyCell } from "@material-ui/data-grid";
import { useHistory } from "react-router-dom";
import CachedIcon from "@material-ui/icons/Cached";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from '@material-ui/icons/Edit';
import BorderColorIcon from "@material-ui/icons/BorderColor";
import CloseIcon from "@material-ui/icons/Close";
// import { Graph } from "react-d3-graph";
import Graph  from 'react-graph-vis';
import mapBackground from '../../components/assets/mapBackground.jpg';
import  BlueUserIcon  from '../../components/assets/BlueUserIcon.jpg';
import  RedUserIcon  from '../../components/assets/RedUserIcon.jpg';

// const QuarantineOptions = [
//   { value: "1", label: "In Quarantine" },
//   { value: "2", label: "Waiting For Quarantine" },
//   { value: "3", label: "Waiting For Approve" },
//   { value: "4", label: "Completed" },
//   { value: "5", label: "Re-Quarantine" }
// ];

let QuarantineOptions = [];

const TitleBar = observer(
  ({
    title,
    back,
    reload,
    status, 
    id,
    children,
  }: {
    title: string | React.ReactElement;
    children?: React.ReactElement;
    back?: boolean;
    reload?: any;
    id: any;
    status?: string;
  }) => {
    const [ statusArray, setStatusArray] = useState<any>();
    useEffect(() => {
      webStore.setLatestTimeStamp(new Date());
      webStore.resetFilter();
    }, []);
    const history = useHistory();
    const [isEdit, setIsEdit] = useState(false);
    const [isUpdateStatus, setIsUpdateStatus] = useState(false);
    const [editValue, setEditValue] = useState<any>();
    const [remarkValue, setRemarkValue] = useState<any>("");
    const [loading, setLoading] = useState(false);
    const [QuarantineOptions, setQuarantineOptions] = useState<any>();
    let quarantineStatus = status;
    const { enqueueSnackbar } = useSnackbar();
    const { fire_quarantineStatus_update, loading_quarantineStatus_update } = UpdateQuarantineStatus(id);
    const { result_getStatus, loading_getStatus } = GetStatusForUser(id);

    // const [statusValue, setStatusValue] = useState<string>('');

    const getStatus = (status: string) => {
     
      let qStatus : string;
      if (status === "1") {
        qStatus = localStorage.getItem("projectKey") === "gmjsfwyncjckmfywgiswygpkmekjrqmsuifwnjnc" ||  localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ? "Not Checked In" :"In Quarantine";
      }else if ( status === "2") {
        qStatus = localStorage.getItem("projectKey") === "gmjsfwyncjckmfywgiswygpkmekjrqmsuifwnjnc" ||  localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3"? "Waiting for Checked In" :"Waiting for Quarantine";
      }else if ( status === "3") {
        qStatus = "Waiting For Approve";
      }else if (status === "4"){
        qStatus= localStorage.getItem("projectKey") === "gmjsfwyncjckmfywgiswygpkmekjrqmsuifwnjnc" ||  localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3"? "Waiting for Checked In" :"Completed";
      }else if (status === "5"){
        qStatus = localStorage.getItem("projectKey") === "gmjsfwyncjckmfywgiswygpkmekjrqmsuifwnjnc" ||  localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3"? "Waiting for Re-Checked In" :"Re-Quarantine";
      }else {
        qStatus = "";
      }
      return qStatus;
    }

    const getStatusOption = () => {
      let statusOption = [];
       if(result_getStatus?.length > 0){
         for(let i = 0; i<result_getStatus.length; i++){
           if(localStorage.getItem("projectKey") === "gmjsfwyncjckmfywgiswygpkmekjrqmsuifwnjnc" ||  localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3"){
              if(result_getStatus[i] === 1){
                statusOption.push({ value: "1", label: "Not Checked In" });
              }else if (result_getStatus[i] === 2){
                statusOption.push({ value: "2", label: "Waiting For Checked In" });
              }
              else if (result_getStatus[i] === 3){
                statusOption.push({ value: "3", label: "Waiting For Approve" });
              }
              else if (result_getStatus[i] === 4){
                statusOption.push({ value: "4", label: "Checked In" });
              }else if (result_getStatus[i] === 5){
                statusOption.push({ value: "5", label: "Re-check In" });
              }else {
                statusOption.push({ value: "1", label: "Not Checked In" })
              }
           }else{
            if(result_getStatus[i] === 1){
              statusOption.push({ value: "1", label: "In Quarantine" });
            }else if (result_getStatus[i] === 2){
              statusOption.push({ value: "2", label: "Waiting For Quarantine" });
            }
            else if (result_getStatus[i] === 3){
              statusOption.push({ value: "3", label: "Waiting For Approve" });
            }
            else if (result_getStatus[i] === 4){
              statusOption.push({ value: "4", label: "Completed" });
            }else if (result_getStatus[i] === 5){
              statusOption.push({ value: "5", label: "Re-Quarantine" });
            }else {
              statusOption.push({ value: "1", label: "In Quarantine" })
            } 
           }
         }
       }
       
       setQuarantineOptions(statusOption);
      return statusOption;
    }
    const [statusQuarantine, setStatusQuarantine] = useState<string>('');

    return (
      <Box className="flex justify-between items-center" pt={4} pb={0}>
        <Grid container alignItems="center">
          {back && (
            <IconButton
              onClick={() => {
                const res = history.goBack();
                // console.log(res);
              }}
            >
              <ArrowBackIcon css={{ color: "black" }} />
            </IconButton>
          )}

          <Box fontSize="h5.fontSize" fontWeight={500}>
            {title}
          </Box>
        </Grid>
        {status && (
          <Box width={400}>
            <Box className="flex justify-between items-center" css={{float:'right'}}>
              <Box style={{fontSize: '16px'}} fontWeight={400}>
                  <span>
                    Status:{statusQuarantine ?  <span>{statusQuarantine}</span> : <span> {getStatus(status)} </span> }
                  </span>
                  {/* Status: {getStatus(status)} */}
                  <Button
                  color="primary"
                  onClick={() => {
                    getStatusOption();
                    // if(statusQuarantine){
                    //   console.log("statusQurarntine on click edit:", statusQuarantine);
                    //   let statusValue = "";
                    //   if (statusQuarantine === "In Quarantine") {
                    //     statusValue = "1";
                    //   }else if ( statusQuarantine === "Waiting for Quarantine") {
                    //     statusValue = "2";
                    //   }else if ( statusQuarantine === "Waiting For Approve") {
                    //     statusValue = "3";
                    //   }else if (statusQuarantine === "Completed"){
                    //     statusValue= "4";
                    //   }else if (statusQuarantine === "Re-Quarantine"){
                    //     statusValue = "5";
                    //   }else {
                    //     statusValue = "";
                    //   }
                    //   setEditValue({
                    //     value: statusValue
                    //   });
                    // }else{
                    //   setEditValue({
                    //     value: status
                    //   });
                    // }
                    
                    setIsEdit(true);
                  }}
                  startIcon={<BorderColorIcon />}
                  size="small"
                  variant="text"
                  style={{float: 'right'}}
                >
                </Button>
              </Box>
              
              
            </Box>
            
          </Box>
        )}

        {children}

        <EditQuarantine
        {...editValue}
        {...remarkValue}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        setRemarkValue={setRemarkValue}
        setValue={setEditValue}
        label = {localStorage.getItem("projectKey") === "gmjsfwyncjckmfywgiswygpkmekjrqmsuifwnjnc" ||  localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ? "Update Status" :"Update Quarantine Status"}
        status = {quarantineStatus}
        getStatusOption = {QuarantineOptions}
        onClick={async () => {
          setLoading(true);
            fire_quarantineStatus_update(
                editValue.value, remarkValue.remark
            )
              .then(({ data }: { data: any }) => {
                let resultStatus: string;
                resultStatus = getStatus(data.data.quarantine_result_status.toString());
                setStatusQuarantine(resultStatus);
                setRemarkValue({remark: ""});
                enqueueSnackbar(`Update Status Success`, {
                  key: "editSuccess",
                  variant: "success",
                  autoHideDuration: 3000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                });
              })
              .catch((ex: any) => {
                enqueueSnackbar("Update Status Failed", {
                  key: "editError",
                  variant: "error",
                  autoHideDuration: 3000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                });
              });
            setIsEdit(false);
            setLoading(false);
        }}
      />

      </Box>
    );
  }
);

const EditQuarantine = observer(
  ({
    isEdit,
    label,
    value,
    remark,
    field,
    onClick,
    setIsEdit,
    getStatusOption,
    type = "text",
    setRemarkValue,
    setValue,
    status,
    buttonName = "CONFIRM",
  }: {
    isEdit: boolean;
    setIsEdit: any;
    label: string;
    field: string;
    value: any;
    remark: any;
    onClick?: any;
    type?: string;
    setRemarkValue: any;
    setValue: any;
    getStatusOption: any;
    status: string;
    buttonName?: string;
  }) => {
    
    const [selectValue, setSelectedValue] = React.useState('');

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      // setSelectedValue(event.target.value as string);
      setValue({value: event.target.value as string});
      if(localStorage.getItem("projectKey") === "gmjsfwyncjckmfywgiswygpkmekjrqmsuifwnjnc" ||  localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3"){
        if (event.target.value === "1") {
          setSelectedValue("Status will be changed to \n Not Checked In");
        }else if ( event.target.value === "2") {
          setSelectedValue("Status will be changed to \n Waiting for Checked In");
        }else if ( event.target.value === "3") {
          setSelectedValue("Status will be changed to \n Waiting For Approve");
        }else if (event.target.value === "4"){
          setSelectedValue("Status will be changed to \n Checked In");
        }else if (event.target.value === "5"){
          setSelectedValue("Status will be changed to \n Re-Checked In");
        }else {
          setSelectedValue("Please choose status");
        }
      }else{
        if (event.target.value === "1") {
          setSelectedValue("Status will be changed to \n In Quarantine");
        }else if ( event.target.value === "2") {
          setSelectedValue("Status will be changed to \n Waiting for Quarantine");
        }else if ( event.target.value === "3") {
          setSelectedValue("Status will be changed to \n Waiting For Approve");
        }else if (event.target.value === "4"){
          setSelectedValue("Status will be changed to \n Completed");
        }else if (event.target.value === "5"){
          setSelectedValue("Status will be changed to \n Re-Quarantine");
        }else {
          setSelectedValue("Please choose status");
        }
      }
      
    };

    const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setRemarkValue({remark: event.target.value as string});
    };

    return (
      <Modal
        open={isEdit}
        onClose={() => {}}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          css={{ backgroundColor: "#fff", width: 350, borderRadius: 5 }}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Box css={{ color: "#4B4C56" }} fontSize={14}>
              {label}
            </Box>
            <Box
              onClick={() => {
                setIsEdit(false);
                setSelectedValue("");
                setValue({value: ""});
                setRemarkValue({remark: ""});
              }}
            >
              <CloseIcon css={{ cursor: "pointer" }} />
            </Box>
          </Box>
          <Box>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
            margin="dense"
            required
          >
            {
              localStorage.getItem("projectKey") === "gmjsfwyncjckmfywgiswygpkmekjrqmsuifwnjnc" ||  localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ?
                <InputLabel id="demo-simple-select-outlined-label">
                  Status
                </InputLabel>
                :
                <InputLabel id="demo-simple-select-outlined-label">
                  Quarantine Status
                </InputLabel>
            }
            
            
            <Select
              labelId="demo-simple-select-outlined-label"
              name={"Quarantine_Status"}
              label={localStorage.getItem("projectKey") === "gmjsfwyncjckmfywgiswygpkmekjrqmsuifwnjnc"||  localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ? "Status" : "Quarantine Status"}
              value={value}
              onChange={handleChange}
              // fullWidth
            >
              {getStatusOption?.map((v: any) => {
                return <MenuItem value={v.value}>{v.label}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <br/>
              
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            <p style={{ color:'#2B5698', textAlign: 'center', fontSize: '13px'}}>{selectValue}</p>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt ={2}
          >
            <p style={{ color:'grey', fontSize: '13px'}}>Remark</p>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
            margin="dense"
          >
            <TextareaAutosize  name="remark-label"
             rowsMin={4} value={remark} onChange={handleOnChange}
             style={{border:'solid 1px', borderColor:'#c4c4c4', padding: "10px"}}
             />
             {/* to do textarea inside textbox padding */}
          </FormControl>
            
          </Box>
          {/* <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            {
              setIsUpdateStatus ? <p style={{ color:'red', textAlign: 'center', fontSize: '13px'}}>Please select the status to update.</p>
              : <></>
            }
            
          </Box> */}

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            <Button
              onClick={() => {
                setIsEdit(false);
                setSelectedValue("");
                setValue({value: ""});
                setRemarkValue({remark: ""});
              }}
              size="medium"
              variant="outlined"
            >
              CANCEL
            </Button>

            <Button
              onClick={onClick}
              color="primary"
              size="medium"
              variant="outlined"
              style={{marginLeft: '3%'}}
            >
              {buttonName}
            </Button>
          </Box>
          <br/>
        </Box>
      </Modal>
    );
  }
);

const AddNoteModal = observer(
  ({
    isAddNote,
    label,
    note,
    onClick,
    setIsAddNote,
    setAddNoteValue,
    buttonName = "Save",
  }: {
    isAddNote: boolean;
    setIsAddNote: any;
    label: string;
    note?: any;
    onClick?: any;
    type?: string;
    setAddNoteValue?: any;
    buttonName?: string;
  }) => {

    const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setAddNoteValue({note: event.target.value as string});
    };

    return (
      <Modal
        open={isAddNote}
        onClose={() => {}}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          css={{ backgroundColor: "#fff", width: 350, borderRadius: 30, border: 'solid 2px #3f51b5' }}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
            mt={1}
          >
            <Box css={{ color: "#4B4C56" }}>
              <h3>{label}</h3> 
            </Box>
            <Box
              onClick={() => {
                setIsAddNote(false);
                setAddNoteValue({note: ""});
              }}
            >
              <CloseIcon css={{ cursor: "pointer" }} />
            </Box>
          </Box>

          

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
            margin="dense"
            >
            <TextareaAutosize  name="remark-label" placeholder="Please Enter Note"
             rowsMin={3} value={note} onChange={handleOnChange}
             />
            </FormControl>
            
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            <Button
              onClick={onClick}
              color="primary"
              size="medium"
              variant="contained"
            >
              {buttonName}
            </Button>
          </Box>
          <br/>
        </Box>
      </Modal>
    );
  }
);

export type UserPreviewData = {
  loading: boolean;
  result_temp_history: TempHistoryReport | null;
  result_oximeter_history: OximeterHistoryReport | null;
  result_heart_rate_history: HeartRateHistoryReport | null;
  result_blood_pressure_history: BloodPressureHistoryReport | null;
  result_battery_history?: BatteryHistoryReport | null;
  result_wearing_history?: WearingHistoryReport | null;
  result_locations_history?: LocationsReport | null;
  result_breath_history?: BreathHistoryReport | null;
  result_blood_sugar_history?: BloodSugarHistoryReport | null;
};

export type DailyReportPreviewData ={
  loading : boolean;
  result_dailyReport: dailyReport | null;
}

const DeviceField = observer((props: any) => {
  return (
    <Box
      borderRight={props.border ? props.border : 0}
      css={{ borderColor: "rgba(0, 0, 0, 0.2)" }}
    >
      <Box css={{ color: "#8F919C" }} fontSize={12} mb={0.5}>
        {props.label}
      </Box>
      <Box fontSize={18}>{props.children}</Box>
    </Box>
  );
});

function badgeStyles() {
  return {
    badgeActive: {
      backgroundColor: "#10940E",
    },
  };
}

export const UserPreview = observer(() => {
  const { userId }: any = useParams();

  const {
    result_userRead,
    result_userRead_raw,
    fire_userRead,
    loading_userRead,
    ...rest
  } = useReadUserUpdate(userId, webStore.readUserFilter);
  useEffect(() => {
    return () => {
      webStore.resetFilter();
      webStore.setStartDate(startDate);
      webStore.setEndDate(endDate);
      webStore.set_filter_hour(filterHour);
    };
  }, []);
  const parseData: UserPreviewData = {
    loading: loading_userRead,
    ...rest,
  };

  const {
    result_dailyReport,
    loading_dailyReport,
    error_dailyReport
  } = CallDailyReports();

  // const dailyReportData : DailyReportPreviewData = {
  //   loading: loading_dailyReport,
  //   result_dailyReport : result_dailyReport 
  // };

  const [expanded, setExpanded] = React.useState<string | false>("panel0");
  const [dailyReportExpanded, setDailyReportExpanded] = React.useState<String | false>("panel1");
  const [showHistory, setShowHistory] = React.useState(false);
  // const { fire_createNote, loading_createNote } = CreateNote(result_userRead_raw?.id);
  const { fire_createNote, loading_createNote } = CreateNote(userId);

  const mapRef = useRef<any>();
  const rootUserName = result_userRead_raw?.user?.name;

  const options = {
    layout: {
      hierarchical: false,
    },
    edges: {
      dashes: true,
    },
  };

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangeDailyReport = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setDailyReportExpanded(newExpanded ? panel : false);
  };


  const Accordion = withStyles({
    root: {
      borderBottom: "1px solid rgba(0, 0, 0, .1)",
      boxShadow: "none",
      "&:last-child": {
        borderBottom: 0,
      },
      "&$expanded": {
        margin: "auto",
      },
    },
    expanded: {},
  })(MuiAccordion);

  const classes = makeStyles(badgeStyles)();
  const [startDate, setStartDate] = useState<Date | null>(
    webStore.startDate || new Date()
  );
  const [endDate, setEndDate] = useState<Date | null>(
    webStore.endDate || new Date()
  );
  const [filterHour, setFilterHour] = useState<number | null>(
    webStore.filter_hour
  );
  const [startDateTracer, setStartDateTracer] = useState<Date | null>(
    webStore.startDateTracer || new Date()
  );
  const [endDateTracer, setEndDateTracer] = useState<Date | null>(
    webStore.endDateTracer || new Date()
  );
  const { result_historyLog, loading_historyLog } = ContactTracerHistroyLog(userId, startDateTracer, endDateTracer);
  const { result_getContactTracerInfo,
    result_contactInfo, 
    loading_getContactTracerInfo,
    result_nodeInfo,
    result_edgeArray
    } = GetContactTracerInfo(userId);
  
  const graphList = {
    nodes: result_nodeInfo,
    edges: result_edgeArray
  };

  const onSearch = () => {
    webStore.setStartDate(startDate);
    webStore.setEndDate(endDate);
    webStore.set_filter_hour(filterHour);
  };

  const getHistory = () => {
    webStore.setStartDateTracer(startDateTracer);
    webStore.setEndDateTracer(endDateTracer);
  }

  const excelExport = () => {
    let instance = axios.create({  baseURL: "https://api.space-trax.com" }); 
    const method = 'GET';
    // /v2/dashboard/user/8dd0e063-79dd-4db0-866a-971df493a2c2/export?start_date=2021-11-05&end_date=2021-11-10

    const url = "https://api.space-trax.com/v2/dashboard/user/"+ userId 
    +"/export?start_date="+ dateStrAPI(startDate)
    +"&end_date=" +  dateStrAPI(endDate);
    const headers = {
      "Project-Key": localStorage.getItem("projectKey"),
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    let options: AxiosRequestConfig = { 
      url,
      method,
      responseType: 'blob' ,
      headers
    };

    return instance.request<any>(options)
    .then(response => { 
      // let filename = response.headers['content-disposition']
      //   .split(';')
      //   .find((n: any) => n.includes('filename='))
      //   .replace('filename=', '')
      //   .trim();  
      let filename = "Report_" + result_userRead_raw?.user.name + " " 
      + result_userRead_raw?.user.surname+ "_"+ dateStrAPI(new Date()) + ":" + timeStr(new Date()) + ".xlsx" 
      let url = window.URL
        .createObjectURL(new Blob([response.data]));     
      saveAs(url, filename);    
  });
  }

  const [isAddDevice, setIsAddDevice] = useState(false);
  const [newIMEI, setNewIMEI] = useState("");
  const [isAddNote, setIsAddNote] = useState(false);
  const [ addNoteValue, setAddNoteValue] = useState<any>();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  return (
    <Container maxWidth="xl" style={{ maxWidth: 1500 }} className="py-4 pb-16">
      {/* <SubBar title={"User Detail"} reload={false} back={true} /> */}
      {
        localStorage.getItem("projectKey") === "gmjsfwyncjckmfywgiswygpkmekjrqmsuifwnjnc" ||  localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ?
        <TitleBar title={"Guest Detail"} reload={false} back={true} 
          status={result_userRead_raw?.quarantine_result_status.toString()}
          id = {result_userRead_raw?.id}
          />
        :
        <TitleBar title={"User Detail"} reload={false} back={true} 
          status={result_userRead_raw?.quarantine_result_status.toString()}
          id = {result_userRead_raw?.id}
          />
      }
      

      {result_userRead_raw?.user.isSos && (
        <Box mt={2} mb={1}>
          <Box
            css={{ borderRadius: 5, backgroundColor: "#BC4538", color: "#fff" }}
          >
            <Box
              p={2}
              display={{ xs: "block", md: "flex" }}
              justifyContent="space-between"
              alignItems="center"
              textAlign="center"
            >
              <Box
                display={{ xs: "block", md: "flex" }}
                alignItems="center"
                mb={{ xs: 2, md: 0 }}
              >
                <ReportProblemIcon />
                <Box mx={2} fontWeight={600}>
                  This person trigger sos
                </Box>
                <Box>15:30 22/Nov/2020 </Box>
              </Box>
              <Button
                style={{ width: "fit-content" }}
                onClick={() => {}}
                color="inherit"
                startIcon={<CheckIcon />}
              >
                Mark resolve
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      <Box>
        <Box
          css={{ borderRadius: 5, backgroundColor: "#2B5698", color: "#fff" }}
        >
          <Box display={{ xs: "block", md: "flex" }}>
            <Box
              p={4}
              width={{ xs: "100%", md: "40%" }}
              css={{ borderRight: "1px solid rgba(255,255,255,0.5)" }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {result_userRead && (
                <UserProfile reload={fire_userRead} user={result_userRead} userRaw={result_userRead_raw} />
              )}
            </Box>
            <Box py={4} px={6} width={{ xs: "100%", md: "60%" }}>
              {result_userRead && (
                <UserInfoCard
                  reload={fire_userRead}
                  user={result_userRead}
                  userRaw={result_userRead_raw}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="flex justify-between items-center" mt={8} pb={2}>
        <Box fontSize="h5.fontSize" fontWeight={500}>
          Devices
        </Box>
        {/* <Button
          startIcon={<AddIcon />}
          color="primary"
          size="small"
          variant="text"
          onClick={() => {
            setIsAddDevice(true);
          }}
        >
          CONNECT NEW DEVICES
        </Button> */}
      </Box>
      {/* <EditField
        label="Connect new IMEI"
        field="imei"
        buttonName="CONNECT"
        isEdit={isAddDevice}
        setIsEdit={setIsAddDevice}
        value={result_userRead_raw?.device?.imei}
        setValue={setNewIMEI}
        onClick={async () => {
          // todo
          // console.log("field", field);
          console.log("CONNECT NEW DEVICES");
        }}
      /> */}
      <Box>
        {/* {(result_userRead_raw?.devices || []).map((deivce, key) => { */}
        {(result_userRead_raw?.device || []).map((deivce, key) => {
          return (
            <Accordion
              square
              expanded={expanded === `panel${key}`}
              onChange={handleChange(`panel${key}`)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box
                  display={{ xs: "block", md: "flex" }}
                  alignItems="center"
                  px={{ xs: 0, md: 3 }}
                  py={2}
                  css={{
                    color: deivce.device_status === 3 ? "#B12143"
                    : deivce.device_status === 4 ? "#B12143"
                    : deivce.device_status === 9 ? "#B12143"
                    : "#10940E",
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <WatchIcon />
                    <Box ml={2} mr={4} fontSize="h5.fontsize" fontWeight={600}>
                      {`Device ${key + 1 < 10 ? `0${key + 1}` : key + 1} - ${
                        deivce.model
                      }`}
                    </Box>
                  </Box>
                  <Box
                    fontSize={12}
                    css={{ color: "#4B4C56" }}
                    mt={{ xs: 2, md: 0 }}
                  >
                    Latest update : {dateStrAPI(deivce.updated_at)} : {timeStr(deivce.updated_at)}
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box px={{ xs: 0, md: 3 }} width="100%">
                  <Grid container xs={12}>
                    <Grid item xs={12} md={4}>
                      <Box
                        display="flex"
                        width="100%"
                        paddingRight={{ md: 5 }}
                        mb={{ xs: 4, md: 0 }}
                      >
                        <Grid item xs={7}>
                          <DeviceField label="IMEI">{deivce.imei}</DeviceField>
                        </Grid>
                        <Grid item xs={5}>
                          <DeviceField
                            label="Device status"
                            border={{ xs: 0, md: 1 }}
                          >
                            {deivce.device_status === 3 ? (
                            <Box css={{ color: "#B12143" }} fontWeight={600}>
                              Offline
                            </Box>
                            ) 
                            :deivce.device_status === 4 ? (
                              <Box css={{ color: "#B12143" }} fontWeight={600}>
                                Not Wear
                              </Box>
                              ) 
                            :deivce.device_status === 9 ? (
                                <Box css={{ color: "#B12143" }} fontWeight={600}>
                                  Not Wear & Offline
                                </Box>
                                )
                            : (
                              <Box display="flex" alignItems="center">
                              <Box
                                mr={1}
                                css={{ color: "#10940E" }}
                                fontWeight={600}
                              >
                                Active
                              </Box>
                              <Badge
                                variant="dot"
                                classes={{ badge: classes.badgeActive }}
                                css={{ backgroundColor: "#10940E" }}
                              ></Badge>
                            </Box>
                            )}
                          </DeviceField>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box
                        display="flex"
                        width="100%"
                        paddingRight={{ md: 5 }}
                        mb={{ xs: 4, md: 0 }}
                      >
                        <Grid item xs={4}>
                          <DeviceField label="Body temp">
                            <Box fontWeight={600}>{deivce.body_temp}</Box>
                          </DeviceField>
                        </Grid>
                        <Grid item xs={4}>
                          <DeviceField label="Heart rate">
                            <Box fontWeight={600}>{deivce.heart_rate}</Box>
                          </DeviceField>
                        </Grid>
                        <Grid item xs={4}>
                          <DeviceField
                            label="Blood pressure"
                            border={{ xs: 0, md: 1 }}
                          >
                            <Box
                              fontWeight={600}
                            >{`${deivce.blood_systolic}/${deivce.blood_diastolic}`}</Box>
                          </DeviceField>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Box display="flex" width="100%" mb={{ xs: 4, md: 0 }}>
                        <Grid item xs={6}>
                          <DeviceField label="Latitude">
                            {deivce.latitude.toFixed(6)}
                          </DeviceField>
                        </Grid>
                        <Grid item xs={6}>
                          <DeviceField
                            label="Longtitude"
                            border={{ xs: 0, md: 1 }}
                          >
                            {deivce.longitude.toFixed(6)}
                          </DeviceField>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Box display="flex" width="100%" paddingLeft={{ md: 5 }}>
                        <Grid item xs={6}>
                          <DeviceField label="Battery">
                            {deivce.battery}
                          </DeviceField>
                        </Grid>
                        <Grid item xs={6}>
                          <DeviceField label="Steps">{deivce.step}</DeviceField>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>

      {
        localStorage.getItem("projectKey") === "gmjsfwyncjckmfywgiswygpkmekjrqmsuifwnjnc" ||  localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3"?
        <>
          <Box
                        className="justify-between items-center"
                        mt={8}
                        pb={2}
                        display={{ xs: "block", md: "flex" }}
                      >
                        <Box fontSize="h5.fontSize" fontWeight={500} mb={{ xs: 2, md: 0 }}>
                          
                        </Box>
                        <Grid item md={8} xs={12}>
                            <Button
                              color="primary"
                              style={{
                                fontWeight: "bold",
                                width: 170,
                                justifyContent: "flex-end",
                                float: "right",
                                marginRight: '20px'
                              }}
                              onClick={excelExport}
                              startIcon={<SaveAltIcon />}
                              size="small"
                              variant="text"
                            >
                              Export Report
                            </Button>
                        </Grid>
                        
                        <Grid container md={4} xs={12}>
                          <Grid item xs={5} css={{ padding: "0 5px" }}>
                            <MuiPickersUtilsProvider utils={LuxonUtils}>
                              <KeyboardDatePicker
                                style={{
                                  margin: 0,
                                  width: "100%",
                                  backgroundColor: "#fff",
                                }}
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="from"
                                inputVariant="outlined"
                                label="Date Start"
                                autoOk={true}
                                value={startDate}
                                onChange={(date) => {
                                  setStartDate(date ? new Date(date.toString()) : null);
                                  // onSearch();
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={5} css={{ padding: "0 5px" }}>
                            <MuiPickersUtilsProvider utils={LuxonUtils}>
                              <KeyboardDatePicker
                                style={{ margin: 0, width: "100%", backgroundColor: "#fff" }}
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="to"
                                label="Date End"
                                inputVariant="outlined"
                                value={endDate}
                                autoOk={true}
                                onChange={(date) => {
                                  setEndDate(date ? new Date(date.toString()) : null);
                                  // onSearch();
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={2} css={{ padding: "0 5px" }}>
                            <Box display="flex" justifyContent="center" alignItems="center">
                              <IconButton onClick={onSearch} color="primary">
                                <SearchIcon fontSize="large" />
                              </IconButton>
                            </Box>
                          </Grid>
                        </Grid>
                    </Box>
                    <Box mt={2}>
                      
                        <Grid container spacing={3}>
                              <Grid xs={12} md={8}>
                              <Box
                                  style={{ height: 610, position: "relative" }}
                                  css={{ borderRadius: 5, backgroundColor: "#fff" }}
                                >
                              {result_userRead_raw && (
                                <Map
                                  locations={(result_userRead_raw.locations || [])
                                    .filter(({ latitude, longitude }) => {
                                      return (
                                        // latitude > 0 &&
                                        // longitude > 0 &&
                                        // Math.abs(latitude) <= 90 &&
                                        // Math.abs(longitude) <= 180
                                        Math.abs(latitude) &&
                                        Math.abs(longitude) 
                                      );
                                    })
                                    .map(({ latitude, longitude }, i) => {
                                      return {
                                        lat: latitude,
                                        lng: longitude,
                                        active: true,
                                        name: (i + 1).toString(),
                                        hoverText: "",
                                        dot: true,
                                      };
                                    })}
                                ></Map>
                              )}
                               </Box>
                              </Grid>
                              <Grid item md={4} xs={12}>
                                  <StickyContainer
                                    style={{
                                      width: "100%",
                                      flex: 1,
                                      minHeight: "100%",
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Sticky topOffset={-80}>
                                      {({ style, isSticky }: any) => (
                                        <div style={style}>
                                          <div
                                            style={{
                                              position: "relative",
                                              height: "calc(100vh - 4rem)",
                                              top: !isSticky ? 0 : "4rem",
                                            }}
                                          >
                                            <DeviceHistoryDatagrid data={parseData} />
                                          </div>
                                        </div>
                                      )}
                                    </Sticky>
                                  </StickyContainer>
                                </Grid> 
                        </Grid>

                        
                     
                    </Box>
        </>
        :
        <>
            <Box className="flex justify-between items-center" mt={8} pb={2}>
                <Grid container>
                  <Grid item md={8} xs={12}>
                    <Box fontSize="h5.fontSize" fontWeight={500}>
                      Daily Report
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={12}>
                      
                      <Button
                        color="primary"
                        size="medium"
                        variant="contained"
                        style={{marginLeft: '3%', float:'right'}}
                        onClick={() => {
                          if(userId){
                            history.push("/user/" + userId + "/noteList");
                          }else{
                            history.push("/user/" + "empty_Id" + "/noteList");
                          }
                          // if(result_userRead_raw?.id){
                          //   history.push("/user/" + result_userRead_raw?.id + "/noteList");
                          // }else{
                          //   history.push("/user/" + "empty_Id" + "/noteList");
                          // }
                          
                        }}
                      >
                        View Notes
                      </Button>

                      <Button startIcon={<NoteAddIcon />} 
                      color="primary" size="medium" variant="contained" style={{float: 'right'}}
                      onClick={()=> {
                        setIsAddNote(true);
                      }}
                      >
                            Add Note
                      </Button>
                  </Grid>
                </Grid>

                <AddNoteModal
                {...addNoteValue}
                isAddNote={isAddNote}
                setIsAddNote={setIsAddNote}
                setAddNoteValue={setAddNoteValue}
                label = {dateStrAPIFormat(new Date())}
                onClick={async () => {
                  setLoading(true);
                  fire_createNote(
                      addNoteValue.note
                  )
                    .then(({ data }: { data: any }) => {    
                      setAddNoteValue({note: ""});
                      enqueueSnackbar(`Create Note Success`, {
                        key: "editSuccess",
                        variant: "success",
                        autoHideDuration: 3000,
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                      });
                    })
                    .catch((ex: any) => {
                      enqueueSnackbar("Create Note Failed", {
                        key: "editError",
                        variant: "error",
                        autoHideDuration: 3000,
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                      });
                    });
                  setIsAddNote(false);
                  setLoading(false);
                  
                }}
              />
                
            </Box>
            <Box css={{ borderRadius: 5, backgroundColor: "#fff" }}>
        {
          result_dailyReport?.length > 0 ?
          <Button
          startIcon={<ExpandMoreIcon style={{fontSize: '26px', marginLeft: '3px'}}/>}
          size="small"
          onClick={()=> setShowHistory(!showHistory)}
          style={{float: 'right', color: 'grey', borderRadius: '50%', padding: '12px'}}
        >
        </Button> : <>
        <p style={{color:'grey', textAlign: 'center', paddingTop:'3%'}}> There is no data. </p>
        </>
        }      
        
        <br/>  
        {
                    result_dailyReport?.length > 1 ?
                    <>
                    <Box px={{ xs: 0, md: 3 }} width="100%">
                        <Grid container xs={12}>
                        {/* #0000ff blue */}
                        {
                          result_dailyReport[result_dailyReport?.length - 2].assesment_period === 1 ? 
                            <Grid item xs={12} md={1}>
                              <Box
                                  display="flex"
                                  width="100%"
                                  paddingRight={{ md: 5 }}
                                  paddingTop = {{ md: 5 }}
                                  mb={{ xs: 3, md: 0 }}>
                                    <Grid item>
                                      <Box display="flex" alignItems="center">
                                        <WbSunnyOutlinedIcon style={{fontSize: '28px', color: 'gold'}}/>
                                        <Box ml={2} mr={4} fontSize="h5.fontsize" fontWeight={600} style={{color:'gold'}}>
                                              เช้า
                                        </Box>
                                      </Box>
                                  </Grid>
                            </Box>
                          </Grid> :

                          <Grid item xs={12} md={1}>
                          <Box
                              display="flex"
                              width="100%"
                              paddingRight={{ md: 5 }}
                              paddingTop = {{ md: 5 }}
                              mb={{ xs: 3, md: 0 }}>
                                <Grid item>
                                    <Box display="flex" alignItems="center">
                                      <NightsStayOutlinedIcon style={{fontSize: '28px', color: 'midnightblue'}}/>
                                      <Box ml={2} mr={4} fontSize="h5.fontsize" fontWeight={600} style={{color: 'midnightblue'}}>
                                          เย็น
                                      </Box>
                                    </Box>
                                </Grid>
                          </Box>
                          </Grid>

                        }
                          <Grid item xs={12} md={2}>
                              <Box
                                  display="flex"
                                  width="100%"
                                  paddingRight={{ md: 5 }}
                                  paddingTop = {{ md: 5 }}
                                  mb={{ xs: 3, md: 0 }}>
                                {
                                  result_dailyReport[result_dailyReport?.length - 2].is_abnormal ?
                                  <Grid item>
                                    <Box display="flex" alignItems="center">
                                      <Box ml={2} 
                                        css={{ color: "#ff0000" }}
                                      fontSize="h5.fontsize" fontWeight={600}>
                                          มีอาการผิดปกติ
                                      </Box>
                                    </Box>  
                                  </Grid>
                                  :
                                  <Grid item>
                                    <Box display="flex" alignItems="center">
                                      <Box ml={2}
                                        css={{ color: "#10940E" }}
                                      fontSize="h5.fontsize" fontWeight={600}>
                                          สุขภาพปกติ
                                      </Box>
                                    </Box>
                                  </Grid>
                                }
                              
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={4}>
                              <Box
                                display="flex"
                                width="100%"
                                paddingRight={{ md: 5 }}
                                mb={{ xs: 3, md: 0 }}
                              >
                                <Grid item xs={4}>
                                  <DeviceField
                                    label="Temperature"
                                  >
                                    <Box
                                      fontWeight={600} mt={2.6}
                                    >{result_dailyReport[result_dailyReport?.length - 2].tempurature}</Box>
                                  </DeviceField>
                                </Grid>

                                <Grid item xs={4}>
                                  <DeviceField
                                    label="Heart Rate"
                                  >
                                    <Box
                                      fontWeight={600} mt={2.6}
                                    >{result_dailyReport[result_dailyReport?.length - 2].heart_rate}</Box>
                                  </DeviceField>
                                </Grid>

                                <Grid item xs={4}>
                                <DeviceField
                                    label="Blood Pressure"
                                  >
                                    <Box
                                      fontWeight={600} mt={2.6}
                                    >{result_dailyReport[result_dailyReport?.length - 2].blood_systolic + "/" + result_dailyReport[result_dailyReport?.length - 2].blood_diastolic}</Box>
                                  </DeviceField>
                                </Grid>
                              </Box>
                          </Grid>

                          <Grid item xs={12} md={3} style={{marginLeft:'-2%'}}>
                              <Box
                                display="flex"
                                width="100%"
                                paddingRight={{ md: 5 }}
                                mb={{ xs: 3, md: 0 }}
                              >
                              <Grid item xs={3}>
                                  <Box>
                                      <Box css={{ color: "#8F919C" }} fontSize={12} mb={0.5}>
                                          SPO<sub>2</sub>
                                      </Box>
                                      <Box css={{ color: "#8F919C" }} fontSize={8} mb={0.5}>
                                          (before)
                                      </Box>
                                      <Box fontSize={18}>
                                        <Box fontWeight={600}>{result_dailyReport[result_dailyReport?.length - 2].oxygen_before}</Box>
                                      </Box>
                                  </Box>
                                </Grid>
                                
                                <Grid item xs={3}>
                                  <Box>
                                      <Box css={{ color: "#8F919C" }} fontSize={12} mb={0.5}>
                                          SPO<sub>2</sub>
                                      </Box>
                                      <Box css={{ color: "#8F919C" }} fontSize={8} mb={0.5}>
                                          (after)
                                      </Box>
                                      <Box fontSize={18}>
                                        <Box fontWeight={600}>{result_dailyReport[result_dailyReport?.length - 2].oxygen_after}</Box>
                                      </Box>
                                  </Box>
                                </Grid>
                                
                                <Grid item xs={3}>
                                  <DeviceField
                                    label="ปัสสาวะ"
                                  >
                                    <Box
                                      fontWeight={600} mt={2.6}
                                    >{result_dailyReport[result_dailyReport?.length - 2].urination_among}</Box>
                                  </DeviceField>
                                </Grid>
                                
                                <Grid item xs={3}>
                                  <DeviceField
                                    label="อุจจาระ"
                                  >
                                    <Box
                                      fontWeight={600} mt={2.6}
                                    >{result_dailyReport[result_dailyReport?.length - 2].defecation_among}</Box>
                                  </DeviceField>
                                </Grid>
                              </Box>
                          </Grid>

                          <Grid item xs={12} md={1} style={{marginRight :"2%"}}>
                                  <DeviceField
                                    label="อาการผิดปกติ"
                                  >
                                    <Box
                                      mt={2.6}
                                    >
                                      {
                                        result_dailyReport[result_dailyReport?.length - 2].symptoms_one ?
                                        <Grid item xs={12}>
                                          <Box
                                            fontSize={12}
                                            css={{ color: "#ff0000" }}
                                            mt={{ xs: 2, md: 0 }}
                                          >
                                          {result_dailyReport[result_dailyReport?.length - 2].symptoms_one + ", "+ result_dailyReport[result_dailyReport?.length - 2].symptoms_two}
                                          </Box>
                                        </Grid>
                                        :
                                        <></>
                                      }
                                    
                                    </Box>
                                  </DeviceField>
                          </Grid>

                          <Grid item xs={12} md={1}>
                                  <DeviceField
                                    label="Latest update:"
                                  >
                                    <Box
                                    fontSize={12}
                                    css={{ color: "#4B4C56" }}
                                      mt={2.6}
                                    >
                                        {dateStrAPI(result_dailyReport[result_dailyReport?.length - 2].updated_at)} : <br/>
                                        {timeStr(result_dailyReport[result_dailyReport?.length - 2].updated_at)}
                                    
                                    </Box>
                                  </DeviceField>
                          </Grid>
                        </Grid>
                        </Box>
                          <br/></>
                        : <></>
                  }

          {
            result_dailyReport.length>1 ? 
            <hr style={{
              color: 'grey',
              backgroundColor: 'grey',
              height: '5',
              width: '100%'
          }} /> : <> 

          </>
          }        
      </Box>
      <Box css={{ borderRadius: 5, backgroundColor: "#fff"}}>
        <br/>
      {
                    result_dailyReport?.length > 0 ? <>
                    <Box px={{ xs: 0, md: 3 }} width="100%">
                      <Grid container xs={12}>
                      {/* #0000ff blue */}
                      {
                        result_dailyReport[result_dailyReport?.length - 1].assesment_period === 1 ? 
                        <Grid item xs={12} md={1}>
                          <Box
                              display="flex"
                              width="100%"
                              paddingRight={{ md: 5 }}
                              paddingTop = {{ md: 5 }}
                              mb={{ xs: 3, md: 0 }}>
                                <Grid item>
                                  <Box display="flex" alignItems="center">
                                    <WbSunnyOutlinedIcon style={{fontSize: '28px', color: 'gold'}}/>
                                    <Box ml={2} mr={4} fontSize="h5.fontsize" fontWeight={600} style={{color:'gold'}}>
                                          เช้า
                                    </Box>
                                  </Box>
                              </Grid>
                        </Box>
                      </Grid> :

                      <Grid item xs={12} md={1}>
                      <Box
                          display="flex"
                          width="100%"
                          paddingRight={{ md: 5 }}
                          paddingTop = {{ md: 5 }}
                          mb={{ xs: 3, md: 0 }}>
                            <Grid item>
                                <Box display="flex" alignItems="center">
                                  <NightsStayOutlinedIcon style={{fontSize: '28px', color: 'midnightblue'}}/>
                                  <Box ml={2} mr={4} fontSize="h5.fontsize" fontWeight={600} style={{color: 'midnightblue'}}>
                                      เย็น
                                  </Box>
                                </Box>
                            </Grid>
                      </Box>
                      </Grid>

                    }
                      <Grid item xs={12} md={2}>
                          <Box
                              display="flex"
                              width="100%"
                              paddingRight={{ md: 5 }}
                              paddingTop = {{ md: 5 }}
                              mb={{ xs: 3, md: 0 }}>
                            {
                              result_dailyReport[result_dailyReport?.length - 1].is_abnormal ?
                              <Grid item>
                                <Box display="flex" alignItems="center">
                                  <Box ml={2} 
                                    css={{ color: "#ff0000" }}
                                  fontSize="h5.fontsize" fontWeight={600}>
                                      มีอาการผิดปกติ
                                  </Box>
                                </Box>  
                              </Grid>
                              :
                              <Grid item>
                                <Box display="flex" alignItems="center">
                                  <Box ml={2}
                                    css={{ color: "#10940E" }}
                                  fontSize="h5.fontsize" fontWeight={600}>
                                      สุขภาพปกติ
                                  </Box>
                                </Box>
                              </Grid>
                            }
                          
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4}>
                          <Box
                            display="flex"
                            width="100%"
                            paddingRight={{ md: 5 }}
                            mb={{ xs: 3, md: 0 }}
                          >
                            <Grid item xs={4}>
                              <DeviceField
                                label="Temperature"
                              >
                                <Box
                                  fontWeight={600} mt={2.6}
                                >{result_dailyReport[result_dailyReport?.length - 1].tempurature}</Box>
                              </DeviceField>
                            </Grid>

                            <Grid item xs={4}>
                              <DeviceField
                                label="Heart Rate"
                              >
                                <Box
                                  fontWeight={600} mt={2.6}
                                >{result_dailyReport[result_dailyReport?.length - 1].heart_rate}</Box>
                              </DeviceField>
                            </Grid>

                            <Grid item xs={4}>
                            <DeviceField
                                label="Blood Pressure"
                              >
                                <Box
                                  fontWeight={600} mt={2.6}
                                >{result_dailyReport[result_dailyReport?.length - 1].blood_systolic + "/" + result_dailyReport[result_dailyReport?.length - 1].blood_diastolic}</Box>
                              </DeviceField>
                            </Grid>
                          </Box>
                      </Grid>

                      <Grid item xs={12} md={3} style={{marginLeft:'-2%'}}>
                          <Box
                            display="flex"
                            width="100%"
                            paddingRight={{ md: 5 }}
                            mb={{ xs: 3, md: 0 }}
                          >
                          <Grid item xs={3}>
                              <Box>
                                  <Box css={{ color: "#8F919C" }} fontSize={12} mb={0.5}>
                                      SPO<sub>2</sub>
                                  </Box>
                                  <Box css={{ color: "#8F919C" }} fontSize={8} mb={0.5}>
                                      (before)
                                  </Box>
                                  <Box fontSize={18}>
                                    <Box fontWeight={600}>{result_dailyReport[result_dailyReport?.length - 1].oxygen_before}</Box>
                                  </Box>
                              </Box>
                            </Grid>
                            
                            <Grid item xs={3}>
                              <Box>
                                  <Box css={{ color: "#8F919C" }} fontSize={12} mb={0.5}>
                                      SPO<sub>2</sub>
                                  </Box>
                                  <Box css={{ color: "#8F919C" }} fontSize={8} mb={0.5}>
                                      (after)
                                  </Box>
                                  <Box fontSize={18}>
                                    <Box fontWeight={600}>{result_dailyReport[result_dailyReport?.length - 1].oxygen_after}</Box>
                                  </Box>
                              </Box>
                            </Grid>
                            
                            <Grid item xs={3}>
                              <DeviceField
                                label="ปัสสาวะ"
                              >
                                <Box
                                  fontWeight={600} mt={2.6}
                                >{result_dailyReport[result_dailyReport?.length - 1].urination_among}</Box>
                              </DeviceField>
                            </Grid>
                            
                            <Grid item xs={3}>
                              <DeviceField
                                label="อุจจาระ"
                              >
                                <Box
                                  fontWeight={600} mt={2.6}
                                >{result_dailyReport[result_dailyReport?.length - 1].defecation_among}</Box>
                              </DeviceField>
                            </Grid>
                          </Box>
                      </Grid>

                      <Grid item xs={12} md={1} style={{marginRight :"2%"}}>
                              <DeviceField
                                label="อาการผิดปกติ"
                              >
                                <Box
                                  mt={2.6}
                                >
                                  {
                                    result_dailyReport[result_dailyReport?.length - 1].symptoms_one ?
                                    <Grid item xs={12}>
                                      <Box
                                        fontSize={12}
                                        css={{ color: "#ff0000" }}
                                        mt={{ xs: 2, md: 0 }}
                                      >
                                      {result_dailyReport[result_dailyReport?.length - 1].symptoms_one + ", "+ result_dailyReport[result_dailyReport?.length - 1].symptoms_two}
                                      </Box>
                                    </Grid>
                                    :
                                    <></>
                                  }
                                
                                </Box>
                              </DeviceField>
                      </Grid>

                      <Grid item xs={12} md={1}>
                              <DeviceField
                                label="Latest update:"
                              >
                                <Box
                                fontSize={12}
                                css={{ color: "#4B4C56" }}
                                  mt={2.6}
                                >
                                    {dateStrAPI(result_dailyReport[result_dailyReport?.length - 1].updated_at)} : <br/>
                                    {timeStr(result_dailyReport[result_dailyReport?.length - 1].updated_at)}
                                
                                </Box>
                              </DeviceField>
                      </Grid>
                    </Grid>
                    </Box> <br/> </>
                    : <></>
                  }
                  </Box>
                  {
                    showHistory ? <>
                    <Box className="flex justify-between items-center" mt={8} pb={2}>
                      <Box fontSize="h6.fontSize" fontWeight={300}>
                        Daily Report History
                      </Box>
                    </Box>
                    <Box css={{ borderRadius: 5, backgroundColor: "#fff"}}>
                      {(result_dailyReport || []).map((dailyReport, key) => {
                              return (
                                <>
                                <br/>
                                <Box px={{ xs: 0, md: 3 }} width="100%">
                                <Grid container xs={12}>
                                {/* #0000ff blue */}
                                {
                                  dailyReport.assesment_period === 1 ? 
                                    <Grid item xs={12} md={1}>
                                      <Box
                                          display="flex"
                                          width="100%"
                                          paddingRight={{ md: 5 }}
                                          paddingTop = {{ md: 5 }}
                                          mb={{ xs: 3, md: 0 }}>
                                            <Grid item>
                                              <Box display="flex" alignItems="center">
                                                <WbSunnyOutlinedIcon style={{fontSize: '28px', color: 'gold'}}/>
                                                <Box ml={2} mr={4} fontSize="h5.fontsize" fontWeight={600} style={{color:'gold'}}>
                                                      เช้า
                                                </Box>
                                              </Box>
                                          </Grid>
                                    </Box>
                                  </Grid> :

                                  <Grid item xs={12} md={1}>
                                  <Box
                                      display="flex"
                                      width="100%"
                                      paddingRight={{ md: 5 }}
                                      paddingTop = {{ md: 5 }}
                                      mb={{ xs: 3, md: 0 }}>
                                        <Grid item>
                                            <Box display="flex" alignItems="center">
                                              <NightsStayOutlinedIcon style={{fontSize: '28px', color: 'midnightblue'}}/>
                                              <Box ml={2} mr={4} fontSize="h5.fontsize" fontWeight={600} style={{color: 'midnightblue'}}>
                                                  เย็น
                                              </Box>
                                            </Box>
                                        </Grid>
                                  </Box>
                                  </Grid>

                                }
                                  <Grid item xs={12} md={2}>
                                      <Box
                                          display="flex"
                                          width="100%"
                                          paddingRight={{ md: 5 }}
                                          paddingTop = {{ md: 5 }}
                                          mb={{ xs: 3, md: 0 }}>
                                        {
                                          dailyReport.is_abnormal ?
                                          <Grid item>
                                            <Box display="flex" alignItems="center">
                                              <Box ml={2} 
                                                css={{ color: "#ff0000" }}
                                              fontSize="h5.fontsize" fontWeight={600}>
                                                  มีอาการผิดปกติ
                                              </Box>
                                            </Box>  
                                          </Grid>
                                          :
                                          <Grid item>
                                            <Box display="flex" alignItems="center">
                                              <Box ml={2}
                                                css={{ color: "#10940E" }}
                                              fontSize="h5.fontsize" fontWeight={600}>
                                                  สุขภาพปกติ
                                              </Box>
                                            </Box>
                                          </Grid>
                                        }
                                      
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                      <Box
                                        display="flex"
                                        width="100%"
                                        paddingRight={{ md: 5 }}
                                        mb={{ xs: 3, md: 0 }}
                                      >
                                        <Grid item xs={4}>
                                          <DeviceField
                                            label="Temperature"
                                          >
                                            <Box
                                              fontWeight={600} mt={2.6}
                                            >{dailyReport.tempurature}</Box>
                                          </DeviceField>
                                        </Grid>

                                        <Grid item xs={4}>
                                          <DeviceField
                                            label="Heart Rate"
                                          >
                                            <Box
                                              fontWeight={600} mt={2.6}
                                            >{dailyReport.heart_rate}</Box>
                                          </DeviceField>
                                        </Grid>

                                        <Grid item xs={4}>
                                        <DeviceField
                                            label="Blood Pressure"
                                          >
                                            <Box
                                              fontWeight={600} mt={2.6}
                                            >{dailyReport.blood_systolic + "/" + dailyReport.blood_diastolic}</Box>
                                          </DeviceField>
                                        </Grid>
                                      </Box>
                                  </Grid>

                                  <Grid item xs={12} md={3} style={{marginLeft:'-2%'}}>
                                      <Box
                                        display="flex"
                                        width="100%"
                                        paddingRight={{ md: 5 }}
                                        mb={{ xs: 3, md: 0 }}
                                      >
                                      <Grid item xs={3}>
                                          <Box>
                                              <Box css={{ color: "#8F919C" }} fontSize={12} mb={0.5}>
                                                  SPO<sub>2</sub>
                                              </Box>
                                              <Box css={{ color: "#8F919C" }} fontSize={8} mb={0.5}>
                                                  (before)
                                              </Box>
                                              <Box fontSize={18}>
                                                <Box fontWeight={600}>{dailyReport.oxygen_before}</Box>
                                              </Box>
                                          </Box>
                                        </Grid>
                                        
                                        <Grid item xs={3}>
                                          <Box>
                                              <Box css={{ color: "#8F919C" }} fontSize={12} mb={0.5}>
                                                  SPO<sub>2</sub>
                                              </Box>
                                              <Box css={{ color: "#8F919C" }} fontSize={8} mb={0.5}>
                                                  (after)
                                              </Box>
                                              <Box fontSize={18}>
                                                <Box fontWeight={600}>{dailyReport.oxygen_after}</Box>
                                              </Box>
                                          </Box>
                                        </Grid>
                                        
                                        <Grid item xs={3}>
                                          <DeviceField
                                            label="ปัสสาวะ"
                                          >
                                            <Box
                                              fontWeight={600} mt={2.6}
                                            >{dailyReport.urination_among}</Box>
                                          </DeviceField>
                                        </Grid>
                                        
                                        <Grid item xs={3}>
                                          <DeviceField
                                            label="อุจจาระ"
                                          >
                                            <Box
                                              fontWeight={600} mt={2.6}
                                            >{dailyReport.defecation_among}</Box>
                                          </DeviceField>
                                        </Grid>
                                      </Box>
                                  </Grid>

                                  <Grid item xs={12} md={1} style={{marginRight :"2%"}}>
                                          <DeviceField
                                            label="อาการผิดปกติ"
                                          >
                                            <Box
                                              mt={2.6}
                                            >
                                              {
                                                dailyReport.symptoms_one ?
                                                <Grid item xs={12}>
                                                  <Box
                                                    fontSize={12}
                                                    css={{ color: "#ff0000" }}
                                                    mt={{ xs: 2, md: 0 }}
                                                  >
                                                  {dailyReport.symptoms_one + ", "+ dailyReport.symptoms_two}
                                                  </Box>
                                                </Grid>
                                                :
                                                <></>
                                              }
                                            
                                            </Box>
                                          </DeviceField>
                                  </Grid>

                                  <Grid item xs={12} md={1}>
                                          <DeviceField
                                            label="Latest update:"
                                          >
                                            <Box
                                            fontSize={12}
                                            css={{ color: "#4B4C56" }}
                                              mt={2.6}
                                            >
                                                {dateStrAPI(dailyReport.updated_at)} : <br/>
                                                {timeStr(dailyReport.updated_at)}
                                            
                                            </Box>
                                          </DeviceField>
                                  </Grid>
                                </Grid>
                                </Box></>
                                );
                              })}
                      </Box> </>: 
                      <>
                      
                      </>
                 }
                    <Box
                        className="justify-between items-center"
                        mt={8}
                        pb={2}
                        display={{ xs: "block", md: "flex" }}
                      >
                        <Box fontSize="h5.fontSize" fontWeight={500} mb={{ xs: 2, md: 0 }}>
                          Health Log
                        </Box>
                        <Grid item md={6} xs={12}>
                          <Button
                              color="primary"
                              css={{
                                fontWeight: "bold",
                                width: 210,
                                justifyContent: "flex-end",
                                float: "right"
                              }}
                              onClick={excelExport}
                              startIcon={<SaveAltIcon />}
                              size="small"
                              variant="text"
                            >
                              Export Health Report
                            </Button>
                        </Grid>
                        {/* <Grid item xs={3}>
                          
                            <InputLabel id="filter_hour">Filter by Hour</InputLabel>
                            <Select
                              labelId="filter_hour"
                              id="filter_hour"
                              label="Filter by Hour"
                              value={filterHour || ""}
                              onChange={(event) => {
                                const input = event.target.value;
                                setFilterHour(typeof input === "number" ? input : null);
                              }}
                            >
                              <MenuItem value="">None</MenuItem>
                              <MenuItem value={1}>1 Hour</MenuItem>
                              <MenuItem value={6}>6 Hours</MenuItem>
                              <MenuItem value={24}>1 Day</MenuItem>
                            </Select>
                          
                        </Grid> */}
                        <Grid container md={4} xs={12}>
                          <Grid item xs={5} css={{ padding: "0 5px" }}>
                            <MuiPickersUtilsProvider utils={LuxonUtils}>
                              <KeyboardDatePicker
                                style={{
                                  margin: 0,
                                  width: "100%",
                                  backgroundColor: "#fff",
                                }}
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="from"
                                inputVariant="outlined"
                                label="Date Start"
                                autoOk={true}
                                value={startDate}
                                onChange={(date) => {
                                  setStartDate(date ? new Date(date.toString()) : null);
                                  // onSearch();
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={5} css={{ padding: "0 5px" }}>
                            <MuiPickersUtilsProvider utils={LuxonUtils}>
                              <KeyboardDatePicker
                                style={{ margin: 0, width: "100%", backgroundColor: "#fff" }}
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="to"
                                label="Date End"
                                inputVariant="outlined"
                                value={endDate}
                                autoOk={true}
                                onChange={(date) => {
                                  setEndDate(date ? new Date(date.toString()) : null);
                                  // onSearch();
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={2} css={{ padding: "0 5px" }}>
                            <Box display="flex" justifyContent="center" alignItems="center">
                              <IconButton onClick={onSearch} color="primary">
                                <SearchIcon fontSize="large" />
                              </IconButton>
                            </Box>
                          </Grid>
                        </Grid>
                    </Box>

                  <Grid container>
                    <Grid item md={8} xs={12}>
                      <Box
                        paddingRight={{ xs: 0, md: 3 }}
                        css={{ backgroundColor: "transparent" }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{ height: 380, overflow: "hidden", marginBottom: 15 }}
                        >
                          <BodyTempGraph data={parseData} />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ height: 380, overflow: "hidden", marginBottom: 15 }}
                        >
                          <HeartRateGraph data={parseData} />
                        </Grid>
                        <Grid item xs={12} style={{ height: 380, overflow: "hidden" , marginBottom: 15}}>
                          <BloodPressureGraph data={parseData} />
                        </Grid>
                        <Grid item xs={12} style={{ height: 380, overflow: "hidden" , marginBottom: 15 }}>
                          <OximeterGraph data={parseData} />
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <StickyContainer
                        style={{
                          width: "100%",
                          flex: 1,
                          minHeight: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Sticky topOffset={-80}>
                          {({ style, isSticky }: any) => (
                            <div style={style}>
                              <div
                                style={{
                                  position: "relative",
                                  height: "calc(100vh - 4rem)",
                                  top: !isSticky ? 0 : "4rem",
                                }}
                              >
                                <DeviceHistoryDatagrid data={parseData} />
                              </div>
                            </div>
                          )}
                        </Sticky>
                      </StickyContainer>
                    </Grid>
                  </Grid>

                  <Box
                    className="justify-between items-center"
                    mt={8}
                    pb={2}
                    display={{ xs: "block", md: "flex" }}
                  >
                    <Box fontSize="h5.fontSize" fontWeight={500} mb={{ xs: 2, md: 0 }}>
                      
                    </Box>
                    <Grid item md={6} xs={12}>
                      
                    </Grid>
                    
                    <Grid container md={4} xs={12}>
                      <Grid item xs={5} css={{ padding: "0 5px" }}>
                        <MuiPickersUtilsProvider utils={LuxonUtils}>
                          <KeyboardDatePicker
                            style={{
                              margin: 0,
                              width: "100%",
                              backgroundColor: "#fff",
                            }}
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="from"
                            inputVariant="outlined"
                            label="Date Start"
                            autoOk={true}
                            value={startDateTracer}
                            onChange={(date) => {
                              setStartDateTracer(date ? new Date(date.toString()) : null);
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={5} css={{ padding: "0 5px" }}>
                        <MuiPickersUtilsProvider utils={LuxonUtils}>
                          <KeyboardDatePicker
                            style={{ margin: 0, width: "100%", backgroundColor: "#fff" }}
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="to"
                            label="Date End"
                            inputVariant="outlined"
                            value={endDateTracer}
                            autoOk={true}
                            onChange={(date) => {
                              setEndDateTracer(date ? new Date(date.toString()) : null);
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={2} css={{ padding: "0 5px" }}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <IconButton onClick={getHistory} color="primary">
                            <SearchIcon fontSize="large" />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Grid container spacing={2} >
                    <Grid item md={8} xs={12}>
                          <Box css={{ borderRadius: 5, backgroundColor: "#fff", padding: 20}}
                            paddingRight={{ xs: 0, md: 3 }}
                          >
                              {loading_getContactTracerInfo && (
                              <LinearProgress
                                  style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
                              />
                              )}
                              <Box fontWeight={600} mb={2}>
                                Contact Tracing 
                              </Box>
                              <Box
                                  style={{ height: 440, position: "relative"}}
                                  css={{ borderRadius: 5}}
                              >
                                {
                                  result_getContactTracerInfo?.id ? 
                                  <div style={{
                                    backgroundImage: `url(${mapBackground})`,
                                    backgroundSize: "cover",
                                    color: "#f5f5f5" 
                                  }}>
                                    <Graph key={Math.random()} graph={graphList} options={options} style={{ height: "420px" }} />
                                  </div>
                                  : 
                                  <div
                                    style={{
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%,-50%)",
                                      position: "absolute",
                                      opacity: "0.5",
                                    }}
                                  >
                                    There is no contact tracer info.
                                  </div>
                                
                                }
                                
                                  {/* {result_contactInfo && (
                                    <ContactTracerMap
                                      mapRef={mapRef}
                                      locations={(result_contactInfo || [])
                                        .filter(({ latitude, longitude } : {latitude:any, longitude:any}) => {
                                          return (
                                            latitude > 0 &&
                                            longitude > 0 &&
                                            Math.abs(latitude) <= 90 &&
                                            Math.abs(longitude) <= 180
                                          );
                                        })
                                        .map(({ latitude, longitude,distance,name } : {latitude:any, longitude:any, distance: any,name: any}, i:any) => {
                                          let hoverText = "";
                                          if(distance !== null){
                                            hoverText= "Name:"+ name + ", Distance:" + distance +"m from " + rootUserName;
                                          }else{
                                            hoverText= "Name:"+ name;
                                          }
                                          
                                          return {
                                            lat: latitude,
                                            lng: longitude,
                                            active: distance < 2 ? false
                                                  : true,
                                            name: name,
                                            hoverText: hoverText,
                                            dot: true,
                                          };
                                        })}
                                    ></ContactTracerMap>
                                  )} */}
                              </Box>
                          </Box>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Box css={{ borderRadius: 5, backgroundColor: "#fff", padding: 20}}>
                        <Box
                        className="justify-between items-center"
                        pb={2}
                        display={{ xs: "block", md: "flex" }}
                        >
                            <Box fontWeight={600} mb={2}>
                                Tracing Log
                            </Box>
                            <Grid item md={6} xs={12}>
                              <>
                                <Button style={{float:'right', marginTop: '-14px'}}
                                    onClick={() => {
                                      if(userId){
                                        history.push("/user/" + userId + "/contactTracerHistory/"+startDateTracer+"/"+endDateTracer);
                                      }else{
                                        history.push("/user/" + "empty_Id" + "/contactTracerHistory");
                                      }
                                    }}
                                    size="small"
                                    variant="text"
                                  >
                                    <u>History</u>
                                </Button>
                              </>
                            </Grid>
                        </Box>
                        <Box
                            style={{ height: 424, position: "relative", overflow: "auto" }}
                            css={{ borderRadius: 5, backgroundColor: "#fff" }}
                          >
                            {(result_historyLog?.social || []).map((contactInfo : any) => {
                                
                                return (
                                <Box
                                    css={{ backgroundColor: "#fff", 
                                    width: "100%", 
                                    borderRadius: 40, 
                                    border: 'solid 2px #3f51b5',
                                    "&:hover": {
                                        backgroundColor: "rgba(3,169,244,0.08)",
                                        borderTop: 3,
                                      },
                                  }}
                                    p={2}
                                    mb={1}
                                    mt={1}
                                    display="flex"
                                    onClick={() => {
                                      if(contactInfo?.user?.id){
                                        history.push("/user/" + contactInfo?.user?.id + "/preview");
                                      }else{
                                        history.push("/user/userId_empty/preview");
                                      }
                                      
                                    }}
                                >
                                  <Box pr={2}
                                  >
                                    {
                                      contactInfo.distance<=2 ?
                                        <Avatar css={{ width: 32, height: 32 }} src={RedUserIcon}>
                                        </Avatar>
                                        :
                                        <Avatar css={{ width: 32, height: 32 }} src={BlueUserIcon}>
                                        </Avatar>
                                      
                                    }
                                    
                                  </Box>

                                  <Grid container>
                                    <Grid item xs={12} md={6}>
                                      <Box mb={2} position="relative">
                                      <Box
                                          display="flex"
                                          alignItems="center"
                                          css={{ color: "#35353F" }}
                                        >
                                          <Box fontWeight={600} fontSize={14} mr={1}>
                                            {`${contactInfo?.user?.name} ${contactInfo?.user.surname}`}
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box>
                                          {
                                            contactInfo?.distance <= 2 ?
                                            <><p style={{fontSize: '14px', textAlign:"right", color: 'red'}}>Warning ! </p>
                                            <p style={{fontSize: '12px', textAlign:"right", color: 'gray'}}>{DateTimeString(contactInfo?.BTUtcTime)}</p></>
                                            :
                                            <><p style={{fontSize: '12px', textAlign:"right", color: 'gray'}}>{DateTimeString(contactInfo?.BTUtcTime)}</p></>
                                          }
                                        </Box>
                                        
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <p style={{fontSize: '14px', color:"gray", fontWeight:"bold"}}>IMEI </p>
                                      <p style={{fontSize: '14px'}}> {contactInfo?.device?.imei} </p>
                                      
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <p style={{fontSize: '14px', color:"gray", fontWeight:"bold"}}>Phone No. </p>
                                      <p style={{fontSize: '14px'}}> {contactInfo?.user?.phone_number ? contactInfo?.user?.phone_number : "-"} </p>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <p style={{fontSize: '14px', color:"gray", fontWeight:"bold"}}>Distance </p>
                                      <p style={{fontSize: '14px'}}> {contactInfo?.distance} m </p>

                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <p style={{fontSize: '14px', color:"gray", fontWeight:"bold"}}>Latitude </p>
                                      <p style={{fontSize: '14px'}}> {contactInfo?.device?.latitude} </p>

                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <p style={{fontSize: '14px', color:"gray", fontWeight:"bold"}}>Longitude </p>
                                      <p style={{fontSize: '14px'}}> {contactInfo?.device?.longitude} </p>
                                    </Grid>
                                  </Grid>

                              </Box>
                                );
                            })}
                        </Box>
                        </Box>
                      </Grid>
                  </Grid>
                  <Box mt={2}>
                    <Box
                      style={{ height: 400, position: "relative" }}
                      css={{ borderRadius: 5, backgroundColor: "#fff" }}
                    >
                      {result_userRead_raw && (
                        <Map
                          locations={(result_userRead_raw.locations || [])
                            .filter(({ latitude, longitude }) => {
                              return (
                                latitude > 0 &&
                                longitude > 0 &&
                                Math.abs(latitude) <= 90 &&
                                Math.abs(longitude) <= 180
                              );
                            })
                            .map(({ latitude, longitude }, i) => {
                              return {
                                lat: latitude,
                                lng: longitude,
                                active: true,
                                name: (i + 1).toString(),
                                dot: true,
                              };
                            })}
                        ></Map>
                      )}
                    </Box>
                  </Box>
      
        </>
      }
      
      
      {/* <Box>
          
            <Accordion
              square
              expanded={dailyReportExpanded === `panel2`}
              onChange={handleChangeDailyReport(`panel2`)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <br/>
                  <br/>

              </AccordionSummary>
              <AccordionDetails>
              
              </AccordionDetails>
            </Accordion>
          
        
      </Box> */}

      
      
      
      
    </Container>
  );
});
export const UserPreviewMobile = UserPreview;
