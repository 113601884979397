import React, { Fragment, useEffect, useState, useRef, useContext } from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import ReactDOM from "react-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  Container,
  Grid,
  GridList,
  GridListTile,
  Paper,
  LinearProgress,
  Typography,
  Select,
  MenuItem,
  Badge,
  FormControl,
  FormLabel ,
  FormControlLabel ,
  RadioGroup ,
  Radio 
} from "@material-ui/core";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { webStore } from "../../stores/webStore";
import { DataGrid, ColDef, ValueGetterParams } from "@material-ui/data-grid";

import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useReadUserByGender } from "../../services/api/dashboard_report/useReadUserByGender";

import { useReadUserByAge } from "../../services/api/dashboard_report/useReadUserByAge";
import { useReadUser } from "../../services/api/user/useReadUser";
import { useListUser } from "../../services/api/user/useListUser";
import { useReadLengthOfStay } from "../../services/api/dashboard_report/useReadLengthOfStay";
import {
  Chart,
  PieSeries,
  Title,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { useParams } from "react-router-dom";

import { Animation } from "@devexpress/dx-react-chart";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";

import { useHistory } from "react-router-dom";
import { useListSOSUser } from "../../services/api/user/useListSOSUser";
import { useListSOSUserComplete } from "../../services/api/user/useListSOSUserComplete";
import { User, UserSOS } from "../../types/user";
import WarningIcon from "@material-ui/icons/Warning";
import { DeviceValueTab, OnlineTab } from "../Dashboard/CardUserList";
import { userDevice } from "../../types/device";
import CheckIcon from "@material-ui/icons/Check";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Pagination from "@material-ui/lab/Pagination";
import { Places } from "../../types/places";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import DoneIcon from '@material-ui/icons/Done';
import AgoraRTC from "agora-rtc-sdk-ng";
import VideocamIcon from '@material-ui/icons/Videocam';
import { dateStr, dateStrAPI,dateString,dateStringFullMothName,dateStrFormatTime } from "../../utils";

import {
    ClientConfig,
    IAgoraRTCRemoteUser,
    ICameraVideoTrack,
    IMicrophoneAudioTrack,
    VideoPlayerConfig
  } from "agora-rtc-sdk-ng";

import AgoraUIKit, { layout, 
  StylePropInterface,
  LocalControls,
  TracksConfigure, 
  MaxUidContext, 
  MaxVideoView, 
  MinUidContext, MinVideoView, 
  RtcContext,
  RtcPropsInterface
 }from 'agora-react-uikit';
import './VideoCallStyle.css';
import { SubBar } from "../Dashboard/DashboardSI";
import  BlueUserIcon  from '../../components/assets/BlueUserIcon.jpg';
import PersonIcon from '@material-ui/icons/Person';
import './Messenger.css';

import { NotificationToken, GetAgoraToken } from "../../services/api/telemedicine/Videocall"; 
import PropsContext, { PropsProvider,PropsInterface } from "agora-react-uikit/dist/PropsContext";
import RtcConfigure  from "agora-react-uikit/dist/RTCConfigure";
import AppointmentJsonData from './Booking.json';
import { GetPatientData } from "../../services/api/telemedicine/BookingService";  
import {
  GetUserProfile
} from "../../services/api/user/useReadUser";
import { createChannel, createClient, RtmMessage } from 'agora-rtm-react';

const useClient = createClient('26b3a1815afb4620ab77119bd84b2747');
const useChannel = createChannel('channelName')

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customBadge: {
      backgroundColor: "green",
      border: "2px solid #fff",
      width: 15,
      height: 15,
      borderRadius: "100%",
    },
  })
);


export const Chat  = observer(() => {
  const { index, name, surname, chatChannel, chatChannelType }: any = useParams();

  const [inCall, setInCall] = useState(false);
  const [channelName, setChannelName] = useState("");
  const [videocall, setVideocall] = useState(true);
  const [role, setRole] = useState(false)
  const [isPinned, setPinned] = useState(false);
  const [ agoraToken, setAgoraToken ] = useState('');
  const [ agoraUserId, setAgoraUserId ] = useState<number>();

  const jsonData  = AppointmentJsonData;
  const appointment_data = jsonData.data[index];
  const docter_info = jsonData.data[0].doctor;

  // const { result_notification, loading_notification, fire_notification} = NotificationToken();
    const { result_agorToken,loading_agoraToken,error_Token } = GetAgoraToken(chatChannel);
    console.log("get agora token from api:", result_agorToken );
    const { result_patientData, loading_patientData } = GetPatientData(index);
    const { result_getUserProfile,fire_userRead, loading_getUserProfile, ...rest } = GetUserProfile(index);

  const [value, setValue] = React.useState('yes');
  const [labReportVale, setLabReportValue ] = React.useState('yes');
  const [followUpValue, setFollowupValue ] = React.useState('yes');

  const useClient = createClient('26b3a1815afb4620ab77119bd84b2747');
  const useChannel = createChannel(channelName);

  const client = useClient();
  const testChannel = useChannel(client)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleChangeLabReport = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabReportValue((event.target as HTMLInputElement).value);
  };

  const handleChangeFollowup = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFollowupValue((event.target as HTMLInputElement).value);
  };

  let historyLog = [
    { 
      id: 1,
      name: 'Test TestingSurname 1'
    },
    { 
      id: 2,
      name: 'Test TestingSurname 2'
    },
    { 
      id: 3,
      name: 'Test TestingSurname 3'
    },
    { 
      id: 4,
      name: 'Test TestingSurname 4'
    },
    { 
      id: 5,
      name: 'Test TestingSurname 5'
    }
  ];

  useEffect(() => {
    if(result_agorToken){
      setChannelName(result_agorToken.channel_name);
      setAgoraToken(result_agorToken.token);
      setAgoraUserId(result_agorToken.user_id);
    }
    if(historyLog.length >1 ){
    }
    
    
  }, [result_agorToken]);
 
  
  return (
    <>
          <SubBar title={"Messages"} back={true} reload={false}/>
          
          <Grid container style={{padding: '10px'}}>
                  <Grid item md={4} xs={12}>
                    <Box style={{position: "relative", overflow: "auto" }}
                      css={{ borderRadius: 5, backgroundColor: "#c5cfe1" , padding : '20px', height: '90vh' }}>
                          <p> <span style={{fontWeight: 'bold'}}>Name :</span> 
                            {name + surname}
                          </p> 
                          <br/>
                          <p> <span style={{fontWeight: 'bold'}}>Date :</span>
                          {
                                        result_patientData ?
                                        <>
                                            { dateStringFullMothName(result_patientData[0]?.start_time) ? 
                                              <>
                                                  {dateStringFullMothName(result_patientData[0]?.start_time) + ", " + dateStrFormatTime(result_patientData[0]?.start_time)}
                                              </>
                                              :
                                              <></>
                                            }
                                        </>
                                        :
                                        <></>
                                    }
                          </p>
                          <br/>
                          <p>
                            <span style={{fontWeight: 'bold'}}>Health Report :</span> 
                            <br/>
                            
                            <span style={{fontWeight: 'bold', paddingRight: '10%', fontSize: '12px', color: '#585151'}}>Body Temp</span> 
                            <span style={{fontWeight: 'bold', paddingRight: '10%', fontSize: '12px', color: '#585151'}}>Heart Rate</span> 
                            <span style={{fontWeight: 'bold',  fontSize: '12px', color: '#585151'}}>
                              Blood Pressure
                            </span> 
                            <br/>
                            <span style={{paddingRight: '78px'}}> {result_getUserProfile?.health?.body_temp ? result_getUserProfile?.health?.body_temp : "N/A"} </span>

                            <span style={{paddingRight: '90px'}}> {result_getUserProfile?.health?.heart_rate ? result_getUserProfile?.health?.heart_rate : "N/A"} </span>

                            <span> 
                                { result_getUserProfile?.health?.blood_systolic && result_getUserProfile?.health?.blood_diastolic ?
                                `${result_getUserProfile?.health?.blood_systolic}/${result_getUserProfile?.health?.blood_diastolic}`
                                :
                                "N/A"
                                }
                            </span>
                            <br/>

                            <span style={{fontWeight: 'bold', paddingRight: '9%', fontSize: '12px', color: '#585151'}}>Blood Sugar</span> 
                            <span style={{fontWeight: 'bold', paddingRight: '18%', fontSize: '12px', color: '#585151'}}>SPO<sub>2</sub></span> 
                            <span style={{fontWeight: 'bold',  fontSize: '12px', color: '#585151'}}>Respiration</span> 
                            <br/>
                            <span style={{paddingRight: '92px'}}> {result_getUserProfile?.health?.body_temp ? result_getUserProfile?.health?.blood_sugar : "N/A"} </span>

                            <span style={{paddingRight: '90px'}}> {result_getUserProfile?.health?.heart_rate ? result_getUserProfile?.health?.blood_oxygen : "N/A"} </span>

                            <span> 
                              {result_getUserProfile?.health?.breath ? result_getUserProfile?.health?.breath : "N/A"}
                            </span>
                            <br/>
                            
                          </p>
                          <br/>
                          <br/>
                          <p>
                             <span style={{fontWeight: 'bold'}}>Symptom Case :</span> 
                             {
                                result_patientData ?
                                <>
                                    {result_patientData[0]?.symptom}
                                </>
                                :
                                <></>
                            }
                          </p>
                          <br/>

                          <hr/>
                          <br/>
                          <p>
                             <span style={{fontWeight: 'bold'}}>Diagnoses </span> 
                          </p>
                          <br/>
                          <Box
                              css={{ backgroundColor: "#fff", borderRadius: 30, border: 'solid 2px #3f51b5' }}
                              p={2}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                mb={1}
                                mt={1}
                              >
                                
                              </Box>

                              

                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <FormControl
                                variant="outlined"
                                style={{ width: "100%" }}
                                size="small"
                                margin="dense"
                                >
                                <TextareaAutosize  name="remark-label" placeholder="Type ....."
                                rowsMin={5} 
                                // value={note} onChange={handleOnChange}
                                />
                                </FormControl>
                                
                              </Box>
                              <br/>
                            </Box>
                            <br/>
                           
                            <FormControl component="fieldset" style={{display :'felx'}}>
                              <FormLabel component="legend">Medicine</FormLabel>
                              <RadioGroup row aria-label="medicine" name="medicine" value={value} onChange={handleChange}>
                                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                              </RadioGroup>
                            </FormControl>
                            <FormControl component="fieldset" style={{display :'flex'}}>
                              <FormLabel component="legend">Lab Report</FormLabel>
                              <RadioGroup row aria-label="medicine" name="medicine" value={labReportVale} onChange={handleChangeLabReport}>
                                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                              </RadioGroup>
                            </FormControl>
                            <FormControl component="fieldset" style={{display :'flex'}}>
                              <FormLabel component="legend">Follow Up</FormLabel>
                              <RadioGroup row aria-label="medicine" name="medicine" value={followUpValue} onChange={handleChangeFollowup}>
                                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                              </RadioGroup>
                            </FormControl>

                          
                            <Box
                              style={{
                                width: "100%",
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 5,
                                marginTop: '20px'
                              }}
                            >
                              <Button  variant="contained" color="primary"> Submit</Button>
                            </Box>
                    </Box>
                  </Grid>
                  <Grid item  md={8} xs = {12}>
                        
                    </Grid>
                {/* </>} */}
           </Grid>
    </>
  );
});