import React, { Fragment, useEffect, useState, useRef, useContext } from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import ReactDOM from "react-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  Container,
  Grid,
  GridList,
  GridListTile,
  Paper,
  LinearProgress,
  Typography,
  Select,
  MenuItem,
  Badge,
  FormControl,
  FormLabel ,
  FormControlLabel ,
  RadioGroup ,
  Radio 
} from "@material-ui/core";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { webStore } from "../../stores/webStore";
import { DataGrid, ColDef, ValueGetterParams } from "@material-ui/data-grid";

import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useReadUserByGender } from "../../services/api/dashboard_report/useReadUserByGender";

import { useReadUserByAge } from "../../services/api/dashboard_report/useReadUserByAge";
import { useReadUser } from "../../services/api/user/useReadUser";
import { useListUser } from "../../services/api/user/useListUser";
import { useReadLengthOfStay } from "../../services/api/dashboard_report/useReadLengthOfStay";
import {
  Chart,
  PieSeries,
  Title,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { useParams } from "react-router-dom";

import { Animation } from "@devexpress/dx-react-chart";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";

import { useHistory } from "react-router-dom";
import { useListSOSUser } from "../../services/api/user/useListSOSUser";
import { useListSOSUserComplete } from "../../services/api/user/useListSOSUserComplete";
import { User, UserSOS } from "../../types/user";
import WarningIcon from "@material-ui/icons/Warning";
import { DeviceValueTab, OnlineTab } from "../Dashboard/CardUserList";
import { userDevice } from "../../types/device";
import CheckIcon from "@material-ui/icons/Check";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Pagination from "@material-ui/lab/Pagination";
import { Places } from "../../types/places";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import DoneIcon from '@material-ui/icons/Done';
import AgoraRTC from "agora-rtc-sdk-ng";
import VideocamIcon from '@material-ui/icons/Videocam';
import { dateStr, dateStrAPI,dateString,dateStringFullMothName,dateStrFormatTime } from "../../utils";

import {
    ClientConfig,
    IAgoraRTCRemoteUser,
    ICameraVideoTrack,
    IMicrophoneAudioTrack,
    VideoPlayerConfig
  } from "agora-rtc-sdk-ng";

  import {
    AgoraVideoPlayer,
    createClient,
    createMicrophoneAndCameraTracks
  } from "agora-rtc-react";

import AgoraUIKit, { layout, 
  StylePropInterface,
  LocalControls,
  TracksConfigure, 
  MaxUidContext, 
  MaxVideoView, 
  MinUidContext, MinVideoView, 
  RtcContext,
  RtcPropsInterface
 }from 'agora-react-uikit';
import './VideoCallStyle.css';
import { SubBar } from "../Dashboard/DashboardSI";
import  BlueUserIcon  from '../../components/assets/BlueUserIcon.jpg';
import PersonIcon from '@material-ui/icons/Person';
import './Messenger.css';

import { NotificationToken, GetAgoraToken } from "../../services/api/telemedicine/Videocall"; 
import PropsContext, { PropsProvider,PropsInterface } from "agora-react-uikit/dist/PropsContext";
import RtcConfigure  from "agora-react-uikit/dist/RTCConfigure";
import AppointmentJsonData from './Booking.json';
import { GetPatientData } from "../../services/api/telemedicine/BookingService";  
import {
  GetUserProfile
} from "../../services/api/user/useReadUser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customBadge: {
      backgroundColor: "green",
      border: "2px solid #fff",
      width: 15,
      height: 15,
      borderRadius: "100%",
    },
  })
);

const DeviceField = observer((props: any) => {
  return (
    <Box
      borderRight={props.border ? props.border : 0}
      css={{ borderColor: "rgba(0, 0, 0, 0.2)" }}
    >
      <Box css={{ color: "#8F919C" }} fontSize={12} mb={0.5}>
        {props.label}
      </Box>
      <Box fontSize={18}>{props.children}</Box>
    </Box>
  );
});

const appId: string = "4ee10d0e65864b05a058e60290c27352"; //ENTER APP ID HERE
const token: string | null = "0064ee10d0e65864b05a058e60290c27352IABp4L+zYVYQJ0vu/icp47IOjCL9wACpM2jRluWUF6SfhQZa8+gAAAAAEAAxc9bWNw4KYgEAAQA4Dgpi";

const config: ClientConfig = { 
 mode: "rtc", codec: "vp8",
};

const useClient = createClient(config);
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

const VideoCallComponent = (props: {
  setInCall: React.Dispatch<React.SetStateAction<boolean>>;
  channelName: string;
}) => {
  const { setInCall, channelName } = props;
  const [users, setUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const [start, setStart] = useState<boolean>(false);
  const client = useClient();
  const { ready, tracks } = useMicrophoneAndCameraTracks();
  useEffect(() => {
    // function to initialise the SDK
    let init = async (name: string) => {
      console.log("init", name);
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        console.log("subscribe success");
        if (mediaType === "video") {
          setUsers((prevUsers) => {
            return [...prevUsers, user];
          });
        }
        if (mediaType === "audio") {
          user.audioTrack?.play();
        }
      });

      client.on("user-unpublished", (user, type) => {
        console.log("unpublished", user, type);
        if (type === "audio") {
          user.audioTrack?.stop();
        }
        if (type === "video") {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid);
          });
        }
      });

      client.on("user-left", (user) => {
        console.log("leaving", user);
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      await client.join(appId, name, token, null);
      if (tracks) await client.publish([tracks[0], tracks[1]]);
      setStart(true);

    };

    if (ready && tracks) {
      console.log("init ready");
      init(channelName);
    }

  }, [channelName, client, ready, tracks]);

  return (
    <div className="App">
      {ready && tracks && (
        <Controls tracks={tracks} setStart={setStart} setInCall={setInCall} />
      )}
      {start && tracks && <Videos users={users} tracks={tracks} />}
    </div>
  );
};

const Videos = (props: {
  users: IAgoraRTCRemoteUser[];
  tracks: [IMicrophoneAudioTrack, ICameraVideoTrack];
}) => {
  const { users, tracks } = props;

  return (
    <div>
      <div id="videos">
        <AgoraVideoPlayer className='vid' videoTrack={tracks[1]} style={{height: '95%', width: '95%', position: 'absolute'}} />
        {users.length > 0 &&
          users.map((user) => {
            if (user.videoTrack) {
              return (
                <AgoraVideoPlayer className='vid' videoTrack={user.videoTrack} style={{height: '95%', width: '95%', position: 'absolute'}} key={user.uid} />
              );
            } else return null;
          })}
      </div>
    </div>
  );
};


export const Controls = (props: {
  tracks: [IMicrophoneAudioTrack, ICameraVideoTrack];
  setStart: React.Dispatch<React.SetStateAction<boolean>>;
  setInCall: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const client = useClient();
  const { tracks, setStart, setInCall } = props;
  const [trackState, setTrackState] = useState({ video: true, audio: true });

  const mute = async (type: "audio" | "video") => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
    }
  };

  const leaveChannel = async () => {
    await client.leave();
    client.removeAllListeners();
    tracks[0].close();
    tracks[1].close();
    setStart(false);
    setInCall(false);
  };

  return (
    <div className="controls">
      <p className={trackState.audio ? "on" : ""}
        onClick={() => mute("audio")}>
        {trackState.audio ? "MuteAudio" : "UnmuteAudio"}
      </p>
      <p className={trackState.video ? "on" : ""}
        onClick={() => mute("video")}>
        {trackState.video ? "MuteVideo" : "UnmuteVideo"}
      </p>
      {<p onClick={() => leaveChannel()}>Leave</p>}
    </div>
  );
};  

const ChannelForm = (props: {
  setInCall: React.Dispatch<React.SetStateAction<boolean>>;
  setChannelName: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { setInCall, setChannelName } = props;

  return (
    <form className="join">
    {appId === '' && <p style={{color: 'red'}}>Please enter your Agora App ID in App.tsx and refresh the page</p>}
      <input type="text"
        placeholder="Enter Channel Name"
        onChange={(e) => setChannelName(e.target.value)}
      />
      <button onClick={(e) => {
        e.preventDefault();
        setInCall(true);
      }}>
        Join
      </button>
    </form>
  );
};

const styles = {
  container: { width:'100%',height: '100%', display: 'inline-flex', flex: 1, backgroundColor: '#e6eff8'},
  heading: { textAlign: 'center' as const, marginBottom: 0 },
  videoContainer: { display: 'flex', flexDirection: 'column', flex: 1 } as React.CSSProperties,
  nav: { display: 'flex', justifyContent: 'space-around' },
  btn: { backgroundColor: '#3f51b5', cursor: 'pointer', borderRadius: 5, padding: 5, color: '#ffffff', fontSize: 20 },
}
const style: StylePropInterface = {
  UIKitContainer: {margin: 20},
  maxViewStyles: {
    height: '100%',
    alignSelf: 'center',
    marginTop: '100',
    width: '80%'
  }
}

export const VideoCall  = observer(() => {
  const { index, name, surname,videoChannel,videoChannelType }: any = useParams();
  
  const [inCall, setInCall] = useState(false);
  const [channelName, setChannelName] = useState("");
  const [videocall, setVideocall] = useState(true);
  const [role, setRole] = useState(false)
  const [isPinned, setPinned] = useState(false);
  const [ agoraToken, setAgoraToken ] = useState('');
  const [ agoraUserId, setAgoraUserId ] = useState<number>();
  // const [ name, setName] = useState('');
  const [ gridChange, setGridChange ] = useState(2);

  const jsonData  = AppointmentJsonData;
  const appointment_data = jsonData.data[index];
  const docter_info = jsonData.data[0].doctor;

  // const { result_notification, loading_notification, fire_notification} = NotificationToken();
    const { result_agorToken,loading_agoraToken,error_Token } = GetAgoraToken(videoChannel);
    console.log("get agora token from api:", result_agorToken );
    const { result_patientData, loading_patientData } = GetPatientData(index);
    const { result_getUserProfile,fire_userRead, loading_getUserProfile, ...rest } = GetUserProfile(index);

  const [value, setValue] = React.useState('yes');
  const [labReportVale, setLabReportValue ] = React.useState('yes');
  const [followUpValue, setFollowupValue ] = React.useState('yes');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleChangeLabReport = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabReportValue((event.target as HTMLInputElement).value);
  };

  const handleChangeFollowup = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFollowupValue((event.target as HTMLInputElement).value);
  };

  let historyLog = [
    { 
      id: 1,
      name: 'Test TestingSurname 1'
    },
    { 
      id: 2,
      name: 'Test TestingSurname 2'
    },
    { 
      id: 3,
      name: 'Test TestingSurname 3'
    },
    { 
      id: 4,
      name: 'Test TestingSurname 4'
    },
    { 
      id: 5,
      name: 'Test TestingSurname 5'
    }
  ];

  useEffect(() => {
    // fire_notification()
    // .then(() => {
    //   console.log("result notification",result_notification);
    // })
    // .catch((ex) => {
    //   console.log("error");
    // });
    if(result_agorToken){
      setChannelName(result_agorToken.channel_name);
      setAgoraToken(result_agorToken.token);
      setAgoraUserId(result_agorToken.user_id);
    }
    if(historyLog.length >1 ){
      // setName(historyLog[0].name);
    }
    
    
  }, [result_agorToken]);
 
  
  return (
    <>
          <SubBar title={"Video Call"} back={true} reload={false}/>
          
           {/* <div className="messenger"> 
            
           <div className="scrollable sidebar">
            <div className="message-list-container">
              <div className="toolbar">
                <h1 className="toolbar-title">Video Calls List</h1>
              </div>  

              <Box
                  style={{position: "relative", overflow: "auto" }}
                  css={{ borderRadius: 5, backgroundColor: "#fff" }}
                >
                  {(historyLog || []).map((contactInfo : any) => {
                      
                    return ( 
                      <Box
                          css={{ backgroundColor: "#fff", 
                          width: "100%", 
                          borderRadius: 20, 
                          border: 'solid 2px #3f51b5',
                          "&:hover": {
                              backgroundColor: "rgba(3,169,244,0.08)",
                              marginTop: '2%'
                            }
                        }}
                          p={2}
                          mb={1}
                          mt={1}
                          display="flex"
                          onClick={() => {
                            setName(contactInfo.name);
                          }}
                      >
                        <Box pr={2}
                        >
                        <Avatar css={{ width: 55, height: 55 }}>
                            <PersonIcon style={{fontSize: '40px'}}/>
                          </Avatar>
                        </Box>

                        <Grid container>
                          <Grid item xs={12}>
                            <Box mb={2} position="relative">
                            <Box
                                display="flex"
                                alignItems="center"
                                css={{ color: "#35353F", marginTop:'4px' }}
                              >
                                <Box fontWeight={600} fontSize={15} mr={1}>
                                  {contactInfo.name}
                                </Box>
                                
                              </Box>
                            <Box>
                                  <p style={{fontSize: '12px',color: 'grey'}}> Hello.This is testing video call.</p>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>

                    </Box>
                      );
                  })}
             </Box>
             
            </div>    
           </div>
            <div className="scrollable content">
            
            </div>
           </div> */}

          <Grid container style={{padding: '10px'}}>
              {/* {
                videocall ?(
                <>
                  <Grid item md={12} xs={12}>
                    <div style={styles.container}>
                      <div style={styles.videoContainer}>
                        
                          </div>
                        </div>
                  </Grid>
                </>)
                : 
                <>*/}
                  <Grid item md={4} xs={12}>
                    <Box style={{position: "relative", overflow: "auto" }}
                      css={{ borderRadius: 5, backgroundColor: "#c5cfe1" , padding : '20px', height: '90vh' }}>
                          <p> <span style={{fontWeight: 'bold'}}>Name :</span> 
                            {name + surname}
                          </p> 

                          {/* <p> <span style={{fontWeight: 'bold'}}>Name :</span>  {appointment_data?.patient?.first_name + " " + appointment_data?.patient?.last_name } </p>  */}
                          {/* <br/> */}
                          {/* <p> <span style={{fontWeight: 'bold'}}>Gender :</span> Male </p> */}
                          <br/>
                          <p> <span style={{fontWeight: 'bold'}}>Date :</span>
                          {
                                        result_patientData ?
                                        <>
                                            { dateStringFullMothName(result_patientData[0]?.start_time) ? 
                                              <>
                                                  {dateStringFullMothName(result_patientData[0]?.start_time) + ", " + dateStrFormatTime(result_patientData[0]?.start_time)}
                                              </>
                                              :
                                              <></>
                                            }
                                        </>
                                        :
                                        <></>
                                    }
                          </p>
                          <br/>
                          <p>
                            <span style={{fontWeight: 'bold'}}>Health Report :</span> 
                            <br/>
                            
                            <span style={{fontWeight: 'bold', paddingRight: '10%', fontSize: '12px', color: '#585151'}}>Body Temp</span> 
                            <span style={{fontWeight: 'bold', paddingRight: '10%', fontSize: '12px', color: '#585151'}}>Heart Rate</span> 
                            <span style={{fontWeight: 'bold',  fontSize: '12px', color: '#585151'}}>
                              Blood Pressure
                            </span> 
                            <br/>
                            <span style={{paddingRight: '78px'}}> {result_getUserProfile?.health?.body_temp ? result_getUserProfile?.health?.body_temp : "N/A"} </span>

                            <span style={{paddingRight: '90px'}}> {result_getUserProfile?.health?.heart_rate ? result_getUserProfile?.health?.heart_rate : "N/A"} </span>

                            <span> 
                                { result_getUserProfile?.health?.blood_systolic && result_getUserProfile?.health?.blood_diastolic ?
                                `${result_getUserProfile?.health?.blood_systolic}/${result_getUserProfile?.health?.blood_diastolic}`
                                :
                                "N/A"
                                }
                            </span>
                            <br/>

                            <span style={{fontWeight: 'bold', paddingRight: '9%', fontSize: '12px', color: '#585151'}}>Blood Sugar</span> 
                            <span style={{fontWeight: 'bold', paddingRight: '18%', fontSize: '12px', color: '#585151'}}>SPO<sub>2</sub></span> 
                            <span style={{fontWeight: 'bold',  fontSize: '12px', color: '#585151'}}>Respiration</span> 
                            <br/>
                            <span style={{paddingRight: '92px'}}> {result_getUserProfile?.health?.body_temp ? result_getUserProfile?.health?.blood_sugar : "N/A"} </span>

                            <span style={{paddingRight: '90px'}}> {result_getUserProfile?.health?.heart_rate ? result_getUserProfile?.health?.blood_oxygen : "N/A"} </span>

                            <span> 
                              {result_getUserProfile?.health?.breath ? result_getUserProfile?.health?.breath : "N/A"}
                            </span>
                            <br/>
                            
                          </p>
                          <br/>
                          <br/>
                          <p>
                             <span style={{fontWeight: 'bold'}}>Symptom Case :</span> 
                             {
                                result_patientData ?
                                <>
                                    {result_patientData[0]?.symptom}
                                </>
                                :
                                <></>
                            }
                          </p>
                          <br/>

                          <hr/>
                          <br/>
                          <p>
                             <span style={{fontWeight: 'bold'}}>Diagnoses </span> 
                          </p>
                          <br/>
                          <Box
                              css={{ backgroundColor: "#fff", borderRadius: 30, border: 'solid 2px #3f51b5' }}
                              p={2}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                mb={1}
                                mt={1}
                              >
                                
                              </Box>

                              

                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <FormControl
                                variant="outlined"
                                style={{ width: "100%" }}
                                size="small"
                                margin="dense"
                                >
                                <TextareaAutosize  name="remark-label" placeholder="Type ....."
                                rowsMin={5} 
                                // value={note} onChange={handleOnChange}
                                />
                                </FormControl>
                                
                              </Box>
                              <br/>
                            </Box>
                            <br/>
                           
                            <FormControl component="fieldset" style={{display :'felx'}}>
                              <FormLabel component="legend">Medicine</FormLabel>
                              <RadioGroup row aria-label="medicine" name="medicine" value={value} onChange={handleChange}>
                                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                              </RadioGroup>
                            </FormControl>
                            <FormControl component="fieldset" style={{display :'flex'}}>
                              <FormLabel component="legend">Lab Report</FormLabel>
                              <RadioGroup row aria-label="medicine" name="medicine" value={labReportVale} onChange={handleChangeLabReport}>
                                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                              </RadioGroup>
                            </FormControl>
                            <FormControl component="fieldset" style={{display :'flex'}}>
                              <FormLabel component="legend">Follow Up</FormLabel>
                              <RadioGroup row aria-label="medicine" name="medicine" value={followUpValue} onChange={handleChangeFollowup}>
                                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                              </RadioGroup>
                            </FormControl>

                          
                            <Box
                              style={{
                                width: "100%",
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 5,
                                marginTop: '20px'
                              }}
                            >
                              <Button  variant="contained" color="primary"> Submit</Button>
                            </Box>
                    </Box>
                  </Grid>
                  <Grid item  md={8} xs = {12}>
                        <div style={styles.container}>
                          <div style={styles.videoContainer}>
                            {
                               videocall ? 
                               <>
                              <AgoraUIKit
                              rtcProps={{
                                // appId: '26b3a1815afb4620ab77119bd84b2747',
                                appId: '4ee10d0e65864b05a058e60290c27352',        
                                // channel: channelName,
                                channel: 'testing',
                                // token: agoraToken,
                                token: '0064ee10d0e65864b05a058e60290c27352IACrC5t8GH562jzfFlqnwwTzERNxyaR/CaNGnKZYfZb/FAZa8+gAAAAAEAB9OJJ5r6uNYgEAAQCtq41i',
                                uid: agoraUserId, 
                              }}
                              styleProps =  {{
                                theme: '#ffffffee', 
                                minViewContainer: {
                                  position: 'absolute',
                                  top: '14%',
                                  right: '2%',
                                  width: '12%',
                                  minWidth: '20%',
                                  minHeight: '18%',
                                  backgroundColor: '#fff',
                                  borderColor: 'grey',
                                  borderWidth: 2,
                                  height: '26%',
                                },
                                minViewStyles: {
                                  height: '100%',
                                },
                                maxViewContainer: {
                                  minWidth: '100%',
                                  minHeight: '100%'
                                },
                                maxViewStyles: {
                                  height: '100%',
                                  width: '100%'
                                },
                              }}
                              callbacks={{
                                EndCall: () => setVideocall(false),
                              }} />
                               </>:
                               <>
                                  <div style={styles.nav}>
                                      <Box
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        flex: 1,
                                        minHeight: "100%",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: 5,
                                        marginTop: '200px'
                                      }}
                                    >
                                          <Button startIcon={<VideocamIcon /> }  variant="contained" color="primary" onClick={() => setVideocall(true)}> Join Video Call  </Button>
                                      </Box>
                                    {/* <h3 style={styles.btn} >Start Call </h3> */}
                                  </div>
                               </>
                            }
                                
                              </div>
                          </div>
                          
                    </Grid>
                {/* </>} */}
           </Grid>
    </>
  );
});