import { useCallAPISpacetrax } from "../../useCallAPI";
export const NotificationToken = () => {
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
        code: 0 | 1;
        message: string;
        data: any;
    }>(
      {
        url: `/v2/notification-token/create`,
        method: "POST",
      },
      { manual: true }
    );
    return {
      result_notification: res?.data,
      loading_notification: loading,
      error_notification: error,
      fire_notification: () => {
      var formData = {
        provider: "firebase",
        token: localStorage.getItem("firebaseToken"),
        user_id: localStorage.getItem("userId")
      }
      return new Promise((resolve, reject) => {
        fire({
          data: formData,
        })
          .then(({ data: { data, code, message } }) => {
            if (code === 0) {
              reject(message);
            } else {
              resolve(data);
            }
          })
          .catch((ex) => {
            reject(ex.toString());
          });
      });
      },
    };
  };

  export const OutgoingCall = () =>{
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
        code: 0 | 1;
        message: string;
        data: any;
    }>(
      {
        url: `/v2/notification-token/create`,
        method: "POST",
      },
      { manual: true }
    );
    return {
      result_notification: res?.data,
      loading_notification: loading,
      error_notification: error,
      fire_notification: () => {
      var formData = {
        provider: "firebase",
        token: localStorage.getItem("firebaseToken"),
        user_id: localStorage.getItem("userId")
      }
      return new Promise((resolve, reject) => {
        fire({
          data: formData,
        })
          .then(({ data: { data, code, message } }) => {
            if (code === 0) {
              reject(message);
            } else {
              resolve(data);
            }
          })
          .catch((ex) => {
            reject(ex.toString());
          });
      });
      },
    };
  };

  export const GetAgoraToken = (channelName: any ) => {
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
        data: {
          "channel_name": string,
          "token": string,
          "user_id": number
        }
      }>(
        {
          // url:  `/v2/video-call/token?channel_name=11121150`,
          // url:  `/v2/video-call/token?channel_name=25022022`,
          url:  `/v2/video-call/token?channel_name=${channelName}`,
          method: "GET",
          // data: {
          //   userId,
          // },
        }
      );
    
      return {
        result_agorToken: res?.data ? res?.data : null,
        loading_agoraToken: loading,
        error_Token: error
      };
  }