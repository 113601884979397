import React, { Fragment, useEffect } from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
  Box,
  Button,
  CardActionArea,
  Container,
  Grid,
  Paper,
  Typography,
  LinearProgress,
  Divider,
  Tab,
} from "@material-ui/core";
import { webStore } from "../../stores/webStore";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
// import { Table } from 'react-bootstrap';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
// import TableRow from '@material-ui/core/TableRow';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
// import Chart from "react-apexcharts"; 
import { ApexOptions } from "apexcharts";
import { Line } from "react-chartjs-2";
// import Chart from 'react-chartjs-2';
// import ChartDataLabels from "chartjs-plugin-datalabels";
import { useParams } from "react-router-dom";
import {
  GetVitalSignData,GetDiagnosesData,GetDiagnosesExportData, UpdateDiagnosesExportData,GetFirstPageData
} from "../../services/api/user/GetVitalSignsData";

import {  makeStyles, useTheme, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { RemoveShoppingCartOutlined } from "@material-ui/icons";
import Checkbox from '@material-ui/core/Checkbox';
import ContentEditable from 'react-contenteditable';
import TextField from '@material-ui/core/TextField';
import { ExportUpdateData } from "../../types/ExportUpdateData";
import { Input } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from "material-ui-thai-datepickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import LuxonUtils from "@date-io/luxon";
import { referenceEnhancer } from "mobx/dist/internal";
import printJS from "print-js";
import { useSnackbar } from "notistack";
import { isNull } from "lodash";
import { SubBar } from "../Dashboard/DashboardSI";
import Logo from '../../components/assets/Logo_Navamin_1.png';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%'
  },
  container: {
    maxHeight: 1000,
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderColor: 'grey',
    borderStyle: 'solid',
  },
});

export const MedicalCertificateTelemed = () =>{
    const onCapturePNG = () =>{
        htmlToImage.toPng(document.getElementById("saveAnotherPNG") as HTMLElement)
        .then(function (dataUrl) {
            const pdf = new jsPDF();
            var link = document.createElement('a');
            link.download = 'my-image-name.jpeg';
            const imgProps= pdf.getImageProperties(dataUrl);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            console.log("pdfHeight:", pdfHeight, imgProps.height);
            pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight, undefined,'FAST');
            pdf.autoPrint({variant: 'non-conform'});
            // window.open(pdf.output('bloburl'), '_blank');
            pdf.save("Medical Certificate.pdf"); 
        });
        
      }
    
      return (
        <>
            <Container maxWidth="xl" style={{ maxWidth: 1500 }} className="py-4 pb-16" >
                <SubBar title={"Medical Certificate"} back={true} reload={false}/>
                <br/>
                <Grid container id="saveAnotherPNG">
                  <Grid container style={{marginTop: '5%', marginLeft: '30px', fontSize: '18px'}}>
                      <img src={Logo} />
                  </Grid>
                    <Grid container>
                        <Grid item xs={12}> 
                          <p style={{textAlign:'center', fontSize: '30px', color: '#4d56a7'}}> ใบรับรองแพทย์ </p>
                         </Grid>
                    </Grid>
                    <Grid container style={{marginTop: '5%', marginLeft: '30px'}}>
                        <Grid item xs={12}> 
                          <p style={{textAlign:'right', fontSize: '25px', color: '#4d56a7'}}> 
                            วันที่ 
                            <TextField
                              id="margin-none"
                              InputProps={{ style: { fontSize: '23px', color: '#4d56a7' } }}
                              style={{marginLeft: '5px', width: 150, marginRight: '20px'}}
                            />  
                          </p>
                         </Grid>
                    </Grid>   
                    <Grid container style={{marginTop: '5%', marginLeft: '30px'}}>
                        <Grid item xs={12}> 
                          <p style={{textAlign:'left', fontSize: '1.5vw', color: '#4d56a7'}}> 
                              ข้าพเจ้า   
                            <TextField
                              id="margin-none"
                              InputProps={{ style: { fontSize: '1.5vw', color: '#4d56a7' } }}
                              style={{marginLeft: '5px', width: '20vw', marginRight: '10px'}}
                            />  
                            ใบอนุญาตประกอบโรคศิลปะแผนปัจจุบันชั้นหนึ่ง สาขาเวชกรรมเลขที่
                            <TextField
                              id="margin-none"
                              InputProps={{ style: { fontSize: '1.5vw', color: '#4d56a7' } }}
                              style={{marginLeft: '5px', width: '23vw'}}
                            />
                          </p>
                         </Grid>
                    </Grid> 

                    <Grid container style={{marginTop: '1%', marginLeft: '30px'}}>
                        <Grid item xs={12}> 
                          <p style={{textAlign:'left', fontSize: '1.5vw', color: '#4d56a7'}}> 
                            ขอรับรอวว่า   
                            <TextField
                              id="margin-none"
                              InputProps={{ style: { fontSize: '1.5vw', color: '#4d56a7' } }}
                              style={{marginLeft: '5px', width: '83vw', marginRight: '10px'}}
                            />  
                            
                          </p>
                         </Grid>
                    </Grid> 
                    <Grid container style={{marginTop: '1%', marginLeft: '30px'}}>
                        <Grid item xs={12}> 
                          <p style={{textAlign:'left', fontSize: '1.5vw', color: '#4d56a7'}}> 
                          ได้มารับการตรวจรักษา เมื่อ   
                            <TextField
                              id="margin-none"
                              InputProps={{ style: { fontSize: '1.5vw', color: '#4d56a7' } }}
                              style={{marginLeft: '5px', width: '20vw', marginRight: '10px'}}
                            />  
                            มีอาการ
                            <TextField
                              id="margin-none"
                              InputProps={{ style: { fontSize: '1.5vw', color: '#4d56a7' } }}
                              style={{marginLeft: '5px', width: '48vw'}}
                            />
                          </p>
                         </Grid>
                    </Grid> 

                    <Grid container style={{marginTop: '1%', marginLeft: '30px'}}>
                        <Grid item xs={12}> 
                          <p style={{textAlign:'left', fontSize: '1.5vw', color: '#4d56a7'}}> 
                            วินิจฉัยว่าเป็นโรค   
                            <TextField
                              id="margin-none"
                              InputProps={{ style: { fontSize: '1.5vw', color: '#4d56a7' } }}
                              style={{marginLeft: '5px', width: '80vw', marginRight: '10px'}}
                            />  
                            
                          </p>
                         </Grid>
                    </Grid> 

                    <Grid container style={{marginTop: '30%', marginLeft: '30px'}}>
                        <Grid item xs={12}> 
                          <p style={{textAlign:'right', fontSize: '1.5vw', color: '#4d56a7', marginRight: '10vw'}}> 
                            ( พญ. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)  
                          </p>
                          <p style={{textAlign:'right', fontSize: '1.5vw', color: '#4d56a7', marginRight: '10vw'}}> 
                          แพทย์ผู้รับรองการตรวจ
                          </p>
                         </Grid>
                  </Grid> 

                </Grid>
                <Box mt={8} pb={2} style={{textAlign: 'center'}}>
                <Grid container>
                    <Grid item md={4} xs={12}></Grid>
                    <Grid item md={4} xs={12}> 
                        <Button  variant="contained" color="primary" onClick={onCapturePNG} style={{marginRight: '10px'}}> 
                              Submit
                        </Button>
                    </Grid>
                    <Grid item md={4} xs={12}></Grid>
                </Grid>
                </Box>
            </Container>
              
        </>
      );
}
