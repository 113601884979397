import React, { Fragment, useEffect, useState,useCallback,useRef } from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
  Box,
  Button,
  CardActionArea,
  Container,
  Grid,
  Paper,
  Typography,
  LinearProgress,
  Divider,
  Tab,
  Modal,
  FormControlLabel ,
  RadioGroup ,
  CircularProgress,
} from "@material-ui/core";
import { webStore } from "../../stores/webStore";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
// import { Table } from 'react-bootstrap';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
// import TableRow from '@material-ui/core/TableRow';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
// import Chart from "react-apexcharts"; 
import { ApexOptions } from "apexcharts";
import { Line } from "react-chartjs-2";
// import Chart from 'react-chartjs-2';
// import ChartDataLabels from "chartjs-plugin-datalabels";
import { useParams } from "react-router-dom";
import {
  GetVitalSignData,GetDiagnosesData,GetDiagnosesExportData, UpdateDiagnosesExportData,
  GetFirstPageData, Image_atk_Data, Image_IdCard_Data,UpdateDischargeForm,GetDischargeFormData
} from "../../services/api/user/GetVitalSignsData";

import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import { calculateAge } from './UserInfoCardSI';

// Chart.register(ChartDataLabels);

import {  makeStyles, useTheme, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { RemoveShoppingCartOutlined } from "@material-ui/icons";
import Checkbox from '@material-ui/core/Checkbox';
import Radio from "@material-ui/core/Radio";
import ContentEditable from 'react-contenteditable';
import TextField from '@material-ui/core/TextField';
import { ExportUpdateData } from "../../types/ExportUpdateData";
import { FirstReceipt, UpdateDischargeData, DischargeForUpdate } from "../../types/first_receipt";
import { Input } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker, KeyboardTimePicker } from "material-ui-thai-datepickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import LuxonUtils from "@date-io/luxon";
import { referenceEnhancer } from "mobx/dist/internal";
import printJS from "print-js";
import { useSnackbar } from "notistack";
import { isNull } from "lodash";
import CloseIcon from "@material-ui/icons/Close";
import {
  UpdateQuarantineStatus,
  UpdatePhuketUserQuarantineStatus,
  GetStatusForPhuketUser
} from "../../services/api/user/useReadUser";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import moment from 'moment';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { dateStr,dateStrAPI,dateStrAPIFormat, dateStrFormatBuddhist,timeString } from "../../utils";
import { AnyRecord } from "dns";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
// import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import takuapaLogo from '../../components/assets/takuapaLogo.png';
import hospitalLogo from '../../components/assets/hospitalLogo.png';
import signature from '../../components/assets/signature.png';
import signature_prison from '../../components/assets/signature_prison.png';
import signature_prison_nurse from '../../components/assets/signature_prison_nurse.png';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import { ConfirmStatusModal, CropImageModal, CropIdCardImageModal, HideIdPhotoModal,HideAtkPhotoModal,
      onCapturePNGEng,useStyles } from "./DiagnosesReport";
const theme = createMuiTheme({
  typography: {
    "fontFamily": "Poppins"
   }
});

export const DischargeForm = observer(
  () =>{
    const { userId, onboardId }: any = useParams();
    const classes = useStyles();

    const { result_dischargeData, loading_dischargeData } = GetDischargeFormData(userId);
    const { result_data, error_data,loading_data } = GetFirstPageData(userId); 
    const { result_updateDischargeData, fire_updateDischargeData, loading_updateDischargeData, error_updateDischargeData } = UpdateDischargeForm(userId);

    const [hn_number, setHn_number] = React.useState("");
    const [authen_code, setAuthen_code] = React.useState("");
    const [inspection_unit, setInspection_unit] = React.useState("");
    const [inspection_unit_code, setInspection_unit_code] = React.useState("");
    const [date_admitted, setDate_admitted] = React.useState("");
    const [date_finished, setDate_finished] = React.useState("");
    const [title_name, setTitle_name] = React.useState("");
    const [name, setName] = React.useState("");
    const [surname, setSurname] = React.useState("");
    const [pid, setPid] = React.useState("");
    const [age, setAge] = React.useState("");
    const [gender, setGender] = React.useState("");
    const [right_a, setRight_a] = React.useState("");
    const [phone_number, setPhone_number] = React.useState("");
    const [date_of_birth, setDate_of_birth] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [symptom, setSymptom] = React.useState("");
    const [recorder, setRecorder] = React.useState("");
    const [recorder_code, setRecorder_code] = React.useState("");
    const [ id, setId ] = React.useState('');
    const [id_line, setId_line] = React.useState("");
    const [staff_id, set_staff_id ] = useState("");
    const [ staff_type, set_staff_type ] = useState("");
    
    const [age_risk_factor, setAge_risk_factor] =  React.useState(false);
    const [chronic_heart_disease, setChronic_heart_disease] =  React.useState(false); 
    const [copd, setCopd] =  React.useState(false);
    const [cva, setCva] =  React.useState(false);
    const [immunocompromise, setImmunocompromise] =  React.useState(false);
    const [t2dm, setT2dm] =  React.useState(false);

    // symptoms
    // normal_symptoms
    const [cough, setCough] =  React.useState(false);
    const [fever_low, setFever_low] =  React.useState(false);
    const [no_symptom, setNo_symptom] =  React.useState(false);
    const [snot, setSnot] =  React.useState(false);

    //risk_symptoms
    const [chest_tightness, setChest_tightness] =  React.useState(false);
    const [fatigue, setFatigue] =  React.useState(false);
    const [fever, setFever] =  React.useState(false);
    const [liquid_stool, setLiquid_stool] =  React.useState(false);
    const [poor_appetite, setPoor_appetite] =  React.useState(false);
    const [puke, setPuke] =  React.useState(false);
    const [severe_cough, setSevere_cough] =  React.useState(false);

    const [feeling, setFeeling] =  React.useState(false);
    const [is_effective, setIs_effective] =  React.useState(false);
    const [mental_one, setMental_one] =  React.useState("");
    const [mental_two, setMental_two] =  React.useState("false");

    const [az_one, setAz_one] =  React.useState(false);
    const [az_two, setAz_two] =  React.useState(false);
    const [get_vaccine, setGet_vaccine] =  React.useState("false");
    const [vaccine, set_vaccine ] = React.useState("");
    const [is_other_vaccine, setIs_other_vaccine] =  React.useState(false);
    const [other_vaccine, setOther_vaccine] =  React.useState("");
    const [sino_one_az_one, setSino_one_az_one] =  React.useState(false);
    const [sino_two_az_one, setSino_two_az_one] =  React.useState(false);
    const [sino_two_az_two, setSino_two_az_two] =  React.useState(false);
    const [siv_one, setSiv_one] =  React.useState(false);
    const [siv_two, setSiv_two] =  React.useState(false);


    const [ age_60, set_age_60 ] = React.useState(false);
    const [ respiratory_disease, set_respiratory_disease ] = useState(false);
    const [ckd, setCkd] =  React.useState(false);
    const [ cardiovascular_diseases, set_cardiovascular_diseases ] = React.useState(false);
    const [ cancer, set_cancer ] = useState(false);
    const [ diabetes, set_diabetes ] = useState(false);
    const [bmi, setBmi] =  React.useState(false);
    const [cirrhosis, setCirrhosis] =  React.useState(false);
    const [ pregnant, set_pregnant ] = useState(false);
    const [absolute_lymphocyte, setAbsolute_lymphocyte] =  React.useState(false);
    const [ problems_diagnoses, set_problems_diagnoses ] = useState("");
    const [ plan, set_plan ] = useState("");

    const [blood_pressure, setBlood_pressure] =  React.useState("");
    const [body_temp_first, setBody_temp_first] =  React.useState("");
    const [height, setHeight] =  React.useState("");

    const [lmp, setLmp] =  React.useState<Date | null>(null);
    const [pulse_first, setPulse_first] =  React.useState("");
    const [respiration_rate_first, setRespiration_rate_first] =  React.useState("");
    const [weight, setWeight] =  React.useState("");

    const [is_normal, setIs_normal] =  React.useState("");
    const [is_xray, setIs_xray] =  React.useState("false");
    const [result, setResult] =  React.useState(""); 

    const [ idcardNo, setIDcardNo ] = React.useState("");
    const [ passportNo , setPassportNo ] = React.useState("");
    const [is_rapid_test, setIs_rapid_test] =  React.useState(""); 
    const [is_rtpcr_test, setIs_rtpcr_test] =  React.useState(""); 
    const [rapid_place, setRapid_place] =  React.useState(""); 
    const [rapid_result, setRapid_result] =  React.useState(""); 
    const [rtpcr_date, setRtpcr_date] =  React.useState(""); 
    const [rtpcr_place, setRtpcr_place] =  React.useState(""); 
    const [rtpcr_result, setRtpcr_result] =  React.useState(""); 
    const [rapid_date, setRapid_date] =  React.useState(""); 
    const [ healthChecked_created_at, set_healthChecked_created_at ] = React.useState("");
    const [ healthChecked_updated_at, set_healthChecked_updated_at ] = React.useState("");
    const [ healthChecked_id, set_healthChecked_id ] = useState("");
    const [ date_result, setDate_result ] = React.useState<Date | null>(null);

    const [ autoclave_bag, set_autoclave_bag ] = useState(false);
    const [ inhaler, set_inhaler ] = useState(false);
    const [ mask, set_mask ] = useState(false);
    const [ medicine, set_medicine ] = useState(false);
    const [ oximeter, set_oximeter ] = useState(false);
    const [ oxygen_concentrator, set_oxygen_concentrator ] = useState(false);
    const [ thermometer, set_thermometer ] = useState(false);

    const [is_positive, setIs_positive] =  React.useState("");
    const [oxygen_after, setOxygen_after] =  React.useState("");
    const [oxygen_before, setOxygen_before] =  React.useState("");

    const [ paracetamol, setParacetamol ] = useState(false);
    const [ ceterine, setCetrine ] = useState(false);
    const [ bromhexine, setBromhexine ] = useState(false);
    const [ CPM , setCPM ] = useState(false);
    const [ indian_gooseberry, set_indian_gooseberry ] = useState(false);
    const [ is_other_medicine, set_is_other_medicine ] = useState(false);
    const [ other_medicine, set_other_medicine ] = useState(""); 

    const [ self_isolation, set_self_isolation ] = useState(true);
    const [ is_further_hospital, set_is_further_hospital ] = useState(false);
    const [ is_hi_hospital, set_is_hi_hospital ] = useState(false);
    const [ is_ci, set_is_ci ] = useState(false);
    const [ is_admit, set_is_admit ] = useState(false);
    const [ is_not_participate , set_is_not_participate ] = useState(false);
    
    const [ swapImage, setSwapImage ] = useState(false);
    const { result_image_atk_data, error_image_atk_data,loading_image_atk_data } = Image_atk_Data(userId);
    const { result_image_IdCard_data, error_image_IdCard_data,loading_image_IdCard_data } = Image_IdCard_Data(userId);
    const [ show_image_atk_data, set_show_image_atk_data] = React.useState(true);
    const [ show_image_IdCard_data, set_show_image_IdCard_data] = React.useState(true);
    const [ image_atk, set_image_atk ] = useState<any>();
    const [ image_atk_data, set_image_atk_data ] = React.useState("");
    const [cropData, setCropData] = useState("#");
    const [ cropIdCardData, setCropIdCardData ] = useState("#");
    const [ isCropIdCard, setIsCropIdCard ] = useState(false);
    const [ isHideAtk, setIsHideAtk ] = useState(false);
    const [ isHideIdPhoto, setIsHideIdPhoto ] = useState(false);
    const [ showButton, setShowButton ] = useState(true);
    const [ isAtkCrop, setIsAtkCrop ] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);

    const [ cough_before, set_cough_before ] = useState(false);
    const [ tired_before, set_tired_before ] = useState(false);
    const [ fever_before, set_fever_before ] = useState(false);
    const [ chest_pain_before, set_chest_pain_before ] = useState(false);
    const [ rest_o2_before, set_rest_o2_before ] = useState(false);
    const [ other_before, set_other_before ] = useState(false);
    const [ treatment_before, set_treatment_before ] = useState("");
    const [ other_ans_before, set_other_ans_before ] = useState("");

    const [ cough_after, set_cough_after ] = useState(false);
    const [ tired_after, set_tired_after ] = useState(false);
    const [ fever_after, set_fever_after ] = useState(false);
    const [ chest_pain_after, set_chest_pain_after ] = useState(false);
    const [ rest_o2_after, set_rest_o2_after ] = useState(false);
    const [ other_after, set_other_after ] = useState(false);
    const [ treatment_after, set_treatment_after ] = useState("");
    const [ other_ans_after, set_other_ans_after ] = useState("");

    const [ hospital, set_hospital ] = useState("");
    const [ refer_cause, set_refer_cause ] = useState("");
    const [ refer_for, set_refer_for ] = useState("");

    const [ check_date, set_check_date ] = useState<Date | null>(null);
    const [ check_date_time, set_check_date_time ] = useState<Date | null>(null);
    const [ after_check_date, set_after_check_date ] = useState<Date | null>(null);
    const [ after_check_date_time, set_after_check_date_time ] = useState<Date | null>(null);
    const [ after_check_date_string, set_after_check_date_string ] = useState<any>();
    const [ check_date_string, set_check_date_string ] = useState<any>();

    const [showDownload, setShowDownload] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [dischargeDataForm, setDischargeDataForm ] = React.useState<DischargeForUpdate>({
      address: address,
      age: age,
      authen_code: authen_code,
      date_admitted: date_admitted,
      date_finished: date_finished,
      date_of_birth: date_of_birth,
      date_result: date_result,
      gender: gender,
      hn_number: hn_number,
      id: id,
      id_line: id_line,
      inspection_unit: inspection_unit,
      inspection_unit_code: inspection_unit_code,
      name: name,
      phone_number: phone_number,
      pid: pid,
      recorder: recorder,
      recorder_code: recorder_code,
      right_a: right_a,
      staff_id: staff_id,
      staff_type: staff_type,
      surname: surname,
      symptom: symptom,
      title_name: title_name,

      healthChecked_created_at: healthChecked_created_at,
      healthChecked_updated_at: healthChecked_updated_at,
      healthChecked_id: healthChecked_id,
      blood_pressure: blood_pressure,
      body_temp_first: body_temp_first,
      height: height,
      lmp: lmp,
      pulse_first: pulse_first,
      respiration_rate_first: respiration_rate_first,
      weight: weight,
      is_normal: is_normal,
      is_xray: is_xray,
      result: result,
      autoclave_bag: autoclave_bag, 
      inhaler: inhaler,
      mask: mask,
      medicine: medicine,
      oximeter: oximeter,
      oxygen_concentrator: oxygen_concentrator,
      thermometer: thermometer,
      is_rapid_test: is_rapid_test,
      is_rtpcr_test: is_rtpcr_test,
      rapid_date: rapid_date,
      rapid_place: rapid_place,
      rapid_result: rapid_result,
      rtpcr_date: rtpcr_date,
      rtpcr_place: rtpcr_place,
      rtpcr_result: rtpcr_result,
      is_positive: is_positive,
      oxygen_after: oxygen_after,
      oxygen_before: oxygen_before,

      symptoms_created_at: "",
      symptoms_id: "",
      symptoms_updated_at: "",
      feeling: feeling,
      is_effective: is_effective,
      mental_one: mental_one,
      mental_two: mental_two,
      cough: cough,
      fever_low: fever_low,
      no_symptom: no_symptom,
      snot: snot,
      absolute_lymphocyte: absolute_lymphocyte,
      risk_factor_age: age_risk_factor,
      bmi: bmi,
      cancer: cancer,
      cardiovascular_diseases: cardiovascular_diseases,
      chronic_heart_disease: chronic_heart_disease,
      cirrhosis: cirrhosis,
      ckd: ckd,
      copd: copd,
      cva: cva,
      diabetes: diabetes,
      immunocompromise: immunocompromise,
      plan: plan,
      pregnant: pregnant,
      problems_diagnoses: problems_diagnoses,
      respiratory_disease: respiratory_disease,
      t2dm: t2dm,
      chest_tightness: chest_tightness,
      fatigue: fatigue,
      fever: fever,
      liquid_stool: liquid_stool,
      poor_appetite: poor_appetite,
      puke: puke,
      severe_cough: severe_cough,
      az_one: az_one,
      az_two: az_two,
      get_vaccine: get_vaccine,
      is_other: is_other_vaccine,
      other: other_vaccine,
      sino_one_az_one: sino_one_az_one,
      sino_two_az_one: sino_two_az_one,
      sino_two_az_two: sino_two_az_two,
      siv_one: siv_one,
      siv_two: siv_two,

      treatmentOrder_created_at: "",
      treatmentOrder_id: "",
      treatmentOrder_updated_at: "",
      bromhexine: bromhexine,
      cetirizine: ceterine,
      cpm: CPM,
      cpm_amount: "",
      dextromethorphan: null,
      dextromethorphan_amount: "",
      green: false,
      indian_gooseberry: indian_gooseberry,
      is_patient_medicines: is_other_medicine,
      ors: null,
      ors_amount: "",
      paracetamol: paracetamol,
      paracetamol_amount: "",
      patient_medicines: other_medicine,
      ci: "",
      further_hospital: "",
      hi_hospital: "",
      hi_shph: "",
      hospital: "",
      is_admit: is_admit,
      is_ci: is_ci,
      is_ci_shph: false,
      is_further_hospital: is_further_hospital,
      is_hi_hospital: is_hi_hospital,
      is_hi_shph: false,
      is_not_participate: is_not_participate,
      is_op_shph: self_isolation,
      is_refer_hospital: false,
      red: false,
      refer_cause: refer_cause,
      refer_for: refer_for,
      andrographis_paniculata: null,
      andrographis_paniculata_amount: "",
      day_five: "",
      day_one: null,
      favipiravia: null,
      favipiravia_amount: "",
      is_day_five: null,
      yellow: false,

      followup_created_at: "",
      followup_id: "",
      followup_updated_at: "",
      after_check_date: after_check_date,
      check_date: check_date,
      chest_pain_before: chest_pain_before,
      cough_before: cough_before,
      fever_before: fever_before,
      other_before: other_before,
      other_after_ans_before: "",
      other_before_ans_before: other_ans_before,
      rest_o2_before: rest_o2_before,
      tired_before: tired_before,
      treatment_before: treatment_before,
      chest_pain_after: chest_pain_after,
      cough_after: cough_after,
      fever_after: fever_after,
      other_after: other_after,
      other_after_ans_after: other_ans_after,
      other_before_ans_after: "",
      rest_o2_after: rest_o2_after,
      tired_after: tired_after,
      treatment_after: treatment_after,
      check_time: check_date_time,
      after_check_time: after_check_date_time
  }); 

    useEffect(() => {
      setHn_number(result_data?.user?.hn_number);
      setAuthen_code(result_data?.user?.authen_code);
      if(localStorage.getItem("projectKey") === "xrsponolu71cznf0zr4pc9g3le5qghobbyigowog"){
        setInspection_unit("โรงพยาบาลถลาง");
        setInspection_unit_code("11356");
      }else{
        setInspection_unit(result_data?.user?.inspection_unit);
        setInspection_unit_code(result_data?.user?.inspection_unit_code);
      }
      setDate_admitted(changeDateFormat(result_data?.user?.date_admitted));
      setDate_finished(changeDateFinishFormat(result_data?.user?.date_admitted));

      setTitle_name(result_data?.user?.title_name);
      setName(result_data?.user?.name);
      setSurname(result_data?.user?.surname);
      setPid(result_data?.user?.pid);
      setAge(result_data?.user?.age);
      setGender((result_data?.user?.gender == 'M' ? 'ชาย' : 'หญิง'));
      setRight_a(result_data?.user?.right_a);
      setPhone_number(result_data?.user?.phone_number);
      setDate_of_birth(moment(result_data?.user?.date_of_birth).add('543','year').format('DD/MM/YYYY'));
      setAddress(result_data?.user?.address);
      setSymptom(result_data?.user?.symptom);
      setRecorder(result_data?.user?.recorder);
      
      // setDate_result(dateStrFormatBuddhist(result_data?.user?.date_result));
      setDate_result(result_data?.user?.date_result ? result_data?.user?.date_result : null);
      
      setAbsolute_lymphocyte(result_data?.symptoms?.risk_factor?.absolute_lymphocyte);
      setAge_risk_factor(result_data?.symptoms?.risk_factor?.age);
      set_age_60(result_data?.symptoms?.risk_factor?.age);
      setBmi(result_data?.symptoms?.risk_factor?.bmi);
      setCirrhosis(result_data?.symptoms?.risk_factor?.cirrhosis);
      setChronic_heart_disease(result_data?.symptoms?.risk_factor?.chronic_heart_disease);
      setCkd(result_data?.symptoms?.risk_factor?.ckd);
      setCopd(result_data?.symptoms?.risk_factor?.copd);
      setCva(result_data?.symptoms?.risk_factor?.cva);
      setImmunocompromise(result_data?.symptoms?.risk_factor?.immunocompromise);
      setT2dm(result_data?.symptoms?.risk_factor?.t2dm);
      set_respiratory_disease(result_data?.symptoms?.risk_factor?.respiratory_disease ? result_data?.symptoms?.risk_factor?.respiratory_disease : false );
      set_cardiovascular_diseases(result_data?.symptoms?.risk_factor?.cardiovascular_diseases ? result_data?.symptoms?.risk_factor?.cardiovascular_diseases : false );
      set_cancer(result_data?.symptoms?.risk_factor?.cancer ? result_data?.symptoms?.risk_factor?.cancer : false );
      set_diabetes(result_data?.symptoms?.risk_factor?.diabetes ? result_data?.symptoms?.risk_factor?.diabetes : false);
      set_pregnant(result_data?.symptoms?.risk_factor?.pregnant ? result_data?.symptoms?.risk_factor?.pregnant : false)
      set_problems_diagnoses(result_data?.symptoms?.risk_factor?.problems_diagnoses);
      set_plan(result_data?.symptoms?.risk_factor?.plan);

      setBlood_pressure(result_data?.health_checked?.health_checked?.blood_pressure);
      setBody_temp_first(result_data?.health_checked?.health_checked?.body_temp);
      setHeight(result_data?.health_checked?.health_checked?.height);
      setLmp(result_data?.health_checked?.health_checked?.lmp ? result_data?.health_checked?.health_checked?.lmp : null);
      setPulse_first(result_data?.health_checked?.health_checked?.pulse);
      setRespiration_rate_first(result_data?.health_checked?.health_checked?.respiration_rate);
      setWeight(result_data?.health_checked?.health_checked?.weight);

      setIs_positive(result_data?.health_checked?.test_exercise?.is_positive ? "true" : "false");
      setOxygen_after(result_data?.health_checked?.test_exercise?.oxygen_after);
      setOxygen_before(result_data?.health_checked?.test_exercise?.oxygen_before);

      setIs_normal(result_data?.health_checked?.lab_results?.is_normal ? "true" : "false");
      setIs_xray(result_data?.health_checked?.lab_results?.is_xray ? "true" : "false");
      setResult(result_data?.health_checked?.lab_results?.result);

      setParacetamol(result_data?.treatment_orders?.level_one?.paracetamol);
      setCetrine(result_data?.treatment_orders?.level_one?.cetirizine);
      setBromhexine(result_data?.treatment_orders?.level_one?.bromhexine);
      setCPM(result_data?.treatment_orders?.level_one?.cpm);
      set_indian_gooseberry(result_data?.treatment_orders?.level_one?.indian_gooseberry);
      set_is_other_medicine(result_data?.treatment_orders?.level_one?.is_patient_medicines);
      set_other_medicine(result_data?.treatment_orders?.level_one?.patient_medicines);
      
      set_self_isolation(result_data?.treatment_orders?.level_three?.is_op_shph);
      // set_self_isolation(true);
      set_is_further_hospital(result_data?.treatment_orders?.level_three?.is_further_hospital);
      set_is_hi_hospital(result_data?.treatment_orders?.level_three?.is_hi_hospital);
      set_is_ci(result_data?.treatment_orders?.level_three?.is_ci);
      set_is_admit(result_data?.treatment_orders?.level_three?.is_admit);
      set_is_not_participate(result_data?.treatment_orders?.level_three?.is_not_participate);

      set_hospital(result_data?.treatment_orders?.level_three?.hospital);
      set_refer_cause(result_data?.treatment_orders?.level_three?.refer_cause);
      set_refer_for(result_data?.treatment_orders?.level_three?.refer_for);

      if(localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23') {
        setIs_rapid_test("true");
      }else{
        setIs_rapid_test(result_data?.health_checked.screening_results?.is_rapid_test ? "true" : "false");
      }
      setRapid_date(result_data?.health_checked.screening_results?.rapid_date);
      setRapid_result(result_data?.health_checked.screening_results?.rapid_result);
      setRapid_place(result_data?.health_checked?.screening_results?.rapid_place);
      setIs_rtpcr_test(result_data?.health_checked.screening_results?.is_rtpcr_test ? "true" : "false");
      setRtpcr_place(result_data?.health_checked.screening_results?.rtpcr_place);
      setRtpcr_date(result_data?.health_checked.screening_results?.rtpcr_date);
      setRtpcr_result(result_data?.health_checked.screening_results?.rtpcr_result);

      set_healthChecked_created_at(result_data?.health_checked?.created_at);
      set_healthChecked_updated_at(result_data?.health_checked?.updated_at);
      set_healthChecked_id(result_data?.health_checked?.id);

      if(result_data?.followup_form?.check_date === '0001-01-01T00:00:00Z'){
        set_check_date(null);
        set_check_date_time(null);
      }else{
        const a = new Date(result_data?.followup_form?.check_date);
        const a_time = a.toLocaleString('en-US', { timeZone: 'Europe/London' });
        const a_new_date = new Date(a_time);
        const a_set_hour = a_new_date.setHours(a_new_date.getHours()-1);
        const a_new_date_final = new Date(a_set_hour);
        set_check_date(a_new_date_final);
        set_check_date_time(a_new_date_final);
      }
      if(result_data?.followup_form?.after_check_date === '0001-01-01T00:00:00Z'){
        set_after_check_date(null);
        set_after_check_date_time(null);
      }else{
        // set_after_check_date(a_new_date_final);
        const d = new Date(result_data?.followup_form?.after_check_date);
        const d_time = d.toLocaleString('en-US', { timeZone: 'Europe/London' });
        const d_new_date = new Date(d_time);
        const d_set_hour = d_new_date.setHours(d_new_date.getHours()-1);
        const d_new_date_final = new Date(d_set_hour);
        set_after_check_date(d_new_date_final);
        set_after_check_date_time(d_new_date_final);
      }
      

      set_cough_before(result_data?.followup_form?.symptom_check?.cough);
      set_tired_before(result_data?.followup_form?.symptom_check?.tired);
      set_fever_before(result_data?.followup_form?.symptom_check?.fever);
      set_chest_pain_before(result_data?.followup_form?.symptom_check?.chest_pain);
      set_rest_o2_before(result_data?.followup_form?.symptom_check?.rest_o2);
      set_other_before(result_data?.followup_form?.symptom_check?.other);
      set_treatment_before(result_data?.followup_form?.symptom_check?.treatment);
      set_other_ans_before(result_data?.followup_form?.symptom_check?.other_before_ans);

      set_cough_after(result_data?.followup_form?.symptom_check_after?.cough);
      set_tired_after(result_data?.followup_form?.symptom_check_after?.tired);
      set_fever_after(result_data?.followup_form?.symptom_check_after?.fever);
      set_chest_pain_after(result_data?.followup_form?.symptom_check_after?.chest_pain);
      set_rest_o2_after(result_data?.followup_form?.symptom_check_after?.rest_o2);
      set_other_after(result_data?.followup_form?.symptom_check_after?.other);
      set_treatment_after(result_data?.followup_form?.symptom_check_after?.treatment);
      set_other_ans_after(result_data?.followup_form?.symptom_check_after?.other_after_ans);

      set_autoclave_bag(result_data?.health_checked?.medicine_prepare?.autoclave_bag);
      set_inhaler(result_data?.health_checked?.medicine_prepare?.inhaler);
      set_mask(result_data?.health_checked?.medicine_prepare?.mask);
      set_medicine(result_data?.health_checked?.medicine_prepare?.medicine);
      set_oximeter(result_data?.health_checked?.medicine_prepare?.oximeter);
      set_oxygen_concentrator(result_data?.health_checked?.medicine_prepare?.oxygen_concentrator);
      set_thermometer(result_data?.health_checked?.medicine_prepare?.thermometer);
      
      set_image_atk(result_image_atk_data);

      setDischargeDataForm({
        ...dischargeDataForm,
        address: result_data?.user?.address,
        age: result_data?.user?.age,
        authen_code: result_data?.user?.authen_code,
        date_admitted: result_data?.user?.date_admitted,
        date_finished: result_data?.user?.date_finished,
        date_of_birth: dateStrAPI(result_data?.user?.date_of_birth),
        date_result: dateStrFormatBuddhist(result_data?.user?.date_result),
        gender: result_data?.user?.gender,
        hn_number: result_data?.user?.hn_number,
        id: result_data?.user?.id,
        id_line: result_data?.user?.id_line,
        inspection_unit: result_data?.user?.inspection_unit,
        inspection_unit_code: result_data?.user?.inspection_unit_code,
        name: result_data?.user?.name,
        phone_number: result_data?.user?.phone_number,
        pid: result_data?.user?.pid,
        recorder: result_data?.user?.recorder,
        recorder_code: result_data?.user?.recorder_code,
        right_a: result_data?.user?.right_a,
        staff_id: result_data?.user?.staff_id,
        staff_type: result_data?.user?.staff_type,
        surname: result_data?.user?.surname,
        symptom: result_data?.user?.symptom,
        title_name: result_data?.user?.title_name,

        healthChecked_created_at: result_data?.health_checked?.created_at,
        healthChecked_updated_at: result_data?.health_checked?.updated_at,
        healthChecked_id: result_data?.health_checked?.id,
        blood_pressure: result_data?.health_checked?.health_checked?.blood_pressure,
        body_temp_first: result_data?.health_checked?.health_checked?.body_temp,
        height: result_data?.health_checked?.health_checked?.height,
        lmp: result_data?.health_checked?.health_checked?.lmp,
        pulse_first: result_data?.health_checked?.health_checked?.pulse,
        respiration_rate_first: result_data?.health_checked?.health_checked?.respiration_rate,
        weight: result_data?.health_checked?.health_checked?.weight,
        is_normal: result_data?.health_checked?.lab_results?.is_normal,
        is_xray: result_data?.health_checked?.lab_results?.is_xray,
        result: result_data?.health_checked?.lab_results?.result,
        autoclave_bag: result_data?.health_checked?.medicine_prepare?.autoclave_bag,
        inhaler:  result_data?.health_checked?.medicine_prepare?.inhaler,
        mask:  result_data?.health_checked?.medicine_prepare?.mask,
        medicine:  result_data?.health_checked?.medicine_prepare?.medicine,
        oximeter:  result_data?.health_checked?.medicine_prepare?.oximeter,
        oxygen_concentrator:  result_data?.health_checked?.medicine_prepare?.oxygen_concentrator,
        thermometer:  result_data?.health_checked?.medicine_prepare?.thermometer,
        is_rapid_test: localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23'? true : result_data?.health_checked.screening_results?.is_rapid_test,
        is_rtpcr_test: result_data?.health_checked.screening_results?.is_rtpcr_test,
        rapid_date: result_data?.health_checked.screening_results?.rapid_date,
        rapid_place: result_data?.health_checked.screening_results?.rapid_place,
        rapid_result: result_data?.health_checked.screening_results?.rapid_result,
        rtpcr_date: result_data?.health_checked.screening_results?.rtpcr_date,
        rtpcr_place: result_data?.health_checked.screening_results?.rtpcr_place,
        rtpcr_result: result_data?.health_checked.screening_results?.rtpcr_result,
        is_positive: result_data?.health_checked?.test_exercise?.is_positive,
        oxygen_after: result_data?.health_checked?.test_exercise?.oxygen_after,
        oxygen_before: result_data?.health_checked?.test_exercise?.oxygen_before,

        symptoms_created_at: result_data?.symptoms?.created_at,
        symptoms_id: result_data?.symptoms?.id,
        symptoms_updated_at: result_data?.symptoms?.updated_at,
        feeling: result_data?.symptoms?.mental?.feeling,
        is_effective: result_data?.symptoms?.mental?.is_effective,
        mental_one: result_data?.symptoms?.mental?.mental_one,
        mental_two: result_data?.symptoms?.mental?.mental_two,
        cough: result_data?.symptoms?.normal_symptoms?.cough,
        fever_low: result_data?.symptoms?.normal_symptoms?.fever_low,
        no_symptom: result_data?.symptoms?.normal_symptoms?.no_symptom,
        snot: result_data?.symptoms?.normal_symptoms?.snot,
        absolute_lymphocyte: result_data?.symptoms?.risk_factor?.absolute_lymphocyte,
        risk_factor_age: result_data?.symptoms?.risk_factor?.age,
        bmi: result_data?.symptoms?.risk_factor?.bmi,
        cancer: result_data?.symptoms?.risk_factor?.cancer,
        cardiovascular_diseases: result_data?.symptoms?.risk_factor?.cardiovascular_diseases,
        chronic_heart_disease: result_data?.symptoms?.risk_factor?.chronic_heart_disease,
        cirrhosis: result_data?.symptoms?.risk_factor?.cirrhosis,
        ckd: result_data?.symptoms?.risk_factor?.ckd,
        copd: result_data?.symptoms?.risk_factor?.copd,
        cva: result_data?.symptoms?.risk_factor?.cva,
        diabetes: result_data?.symptoms?.risk_factor?.diabetes,
        immunocompromise: result_data?.symptoms?.risk_factor?.immunocompromise,
        plan: result_data?.symptoms?.risk_factor?.plan,
        pregnant: result_data?.symptoms?.risk_factor?.pregnant,
        problems_diagnoses: result_data?.symptoms?.risk_factor?.problems_diagnoses,
        respiratory_disease: result_data?.symptoms?.risk_factor?.respiratory_disease,
        t2dm: result_data?.symptoms?.risk_factor?.t2dm,
        chest_tightness: result_data?.symptoms?.risk_symptoms?.chest_tightness,
        fatigue: result_data?.symptoms?.risk_symptoms?.fatigue,
        fever: result_data?.symptoms?.risk_symptoms?.fever,
        liquid_stool: result_data?.symptoms?.risk_symptoms?.liquid_stool,
        poor_appetite: result_data?.symptoms?.risk_symptoms?.poor_appetite,
        puke: result_data?.symptoms?.risk_symptoms?.puke,
        severe_cough: result_data?.symptoms?.risk_symptoms?.severe_cough,
        az_one: result_data?.symptoms?.vaccine?.az_one,
        az_two: result_data?.symptoms?.vaccine?.az_two,
        get_vaccine: result_data?.symptoms?.vaccine?.get_vaccine,
        is_other: result_data?.symptoms?.vaccine?.is_other,
        other: result_data?.symptoms?.vaccine?.other,
        sino_one_az_one: result_data?.symptoms?.vaccine?.sino_one_az_one,
        sino_two_az_one: result_data?.symptoms?.vaccine?.sino_two_az_one,
        sino_two_az_two: result_data?.symptoms?.vaccine?.sino_two_az_two,
        siv_one: result_data?.symptoms?.vaccine?.siv_one,
        siv_two: result_data?.symptoms?.vaccine?.siv_two,

        treatmentOrder_created_at: result_data?.treatment_orders?.created_at,
        treatmentOrder_id: result_data?.treatment_orders?.id,
        treatmentOrder_updated_at: result_data?.treatment_orders?.updated_at,
        bromhexine: result_data?.treatment_orders?.level_one?.bromhexine,
        cetirizine: result_data?.treatment_orders?.level_one?.cetirizine,
        cpm: result_data?.treatment_orders?.level_one?.cpm,
        cpm_amount: result_data?.treatment_orders?.level_one?.cpm_amount,
        dextromethorphan: result_data?.treatment_orders?.level_one?.dextromethorphan,
        dextromethorphan_amount: result_data?.treatment_orders?.level_one?.dextromethorphan_amount,
        green: result_data?.treatment_orders?.level_one?.green,
        indian_gooseberry: result_data?.treatment_orders?.level_one?.indian_gooseberry,
        is_patient_medicines: result_data?.treatment_orders?.level_one?.is_patient_medicines,
        ors: result_data?.treatment_orders?.level_one?.ors,
        ors_amount: result_data?.treatment_orders?.level_one?.ors_amount,
        paracetamol: result_data?.treatment_orders?.level_one?.paracetamol,
        paracetamol_amount: result_data?.treatment_orders?.level_one?.paracetamol_amount,
        patient_medicines: result_data?.treatment_orders?.level_one?.patient_medicines,
        ci: result_data?.treatment_orders?.level_three?.ci,
        further_hospital: result_data?.treatment_orders?.level_three?.further_hospital,
        hi_hospital: result_data?.treatment_orders?.level_three?.hi_hospital,
        hi_shph: result_data?.treatment_orders?.level_three?.hi_shph,
        hospital: result_data?.treatment_orders?.level_three?.hospital,
        is_admit: result_data?.treatment_orders?.level_three?.is_admit,
        is_ci: result_data?.treatment_orders?.level_three?.is_ci,
        is_ci_shph: result_data?.treatment_orders?.level_three?.is_ci_shph,
        is_further_hospital: result_data?.treatment_orders?.level_three?.is_further_hospital,
        is_hi_hospital: result_data?.treatment_orders?.level_three?.is_hi_hospital,
        is_hi_shph: result_data?.treatment_orders?.level_three?.is_hi_shph,
        is_not_participate: result_data?.treatment_orders?.level_three?.is_not_participate,
        is_op_shph: result_data?.treatment_orders?.level_three?.is_op_shph,
        is_refer_hospital: result_data?.treatment_orders?.level_three?.is_refer_hospital,
        red: result_data?.treatment_orders?.level_three?.red,
        refer_cause: result_data?.treatment_orders?.level_three?.refer_cause,
        refer_for: result_data?.treatment_orders?.level_three?.refer_for,
        andrographis_paniculata: result_data?.treatment_orders?.level_two?.andrographis_paniculata,
        andrographis_paniculata_amount: result_data?.treatment_orders?.level_two?.andrographis_paniculata_amount,
        day_five: result_data?.treatment_orders?.level_two?.day_five,
        day_one: result_data?.treatment_orders?.level_two?.day_one,
        favipiravia: result_data?.treatment_orders?.level_two?.favipiravia,
        favipiravia_amount: result_data?.treatment_orders?.level_two?.favipiravia_amount,
        is_day_five: result_data?.treatment_orders?.level_two?.is_day_five,
        yellow: result_data?.treatment_orders?.level_two?.yellow,

        followup_created_at: result_data?.followup_form?.created_at,
        followup_id: result_data?.followup_form?.id,
        followup_updated_at: result_data?.followup_form?.updated_at,
        after_check_date: result_data?.followup_form?.after_check_date,
        check_date: result_data?.followup_form?.check_date,
        chest_pain_before: result_data?.followup_form?.symptom_check?.chest_pain,
        cough_before: result_data?.followup_form?.symptom_check?.cough,
        fever_before: result_data?.followup_form?.symptom_check?.fever,
        other_before: result_data?.followup_form?.symptom_check?.other,
        other_after_ans_before: result_data?.followup_form?.symptom_check?.other_after_ans,
        other_before_ans_before: result_data?.followup_form?.symptom_check?.other_before_ans,
        rest_o2_before: result_data?.followup_form?.symptom_check?.rest_o2,
        tired_before: result_data?.followup_form?.symptom_check?.tired,
        treatment_before: result_data?.followup_form?.symptom_check?.treatment,
        chest_pain_after: result_data?.followup_form?.symptom_check_after?.chest_pain,
        cough_after: result_data?.followup_form?.symptom_check_after?.cough,
        fever_after: result_data?.followup_form?.symptom_check_after?.fever,
        other_after: result_data?.followup_form?.symptom_check_after?.other,
        other_after_ans_after: result_data?.followup_form?.symptom_check_after?.other_after_ans,
        other_before_ans_after: result_data?.followup_form?.symptom_check_after?.other_before_ans,
        rest_o2_after: result_data?.followup_form?.symptom_check_after?.rest_o2,
        tired_after: result_data?.followup_form?.symptom_check_after?.tired,
        treatment_after: result_data?.followup_form?.symptom_check_after?.treatment,
        check_time: result_data?.followup_form?.check_date,
        after_check_time: result_data?.followup_form?.after_check_date

      })

    }, [ result_data,result_image_atk_data ]);

    const changeDateFormat = (date: any) => {
      if (date){
        console.log("dateFormat", date);
          let splitDate = date.split('/');
          let changeYear;
          if(parseInt(splitDate[2]) <= 2022){
            changeYear = parseInt(splitDate[2]) + 543;
          }else{
            changeYear = splitDate[2];
          }
          let dateString = splitDate[0] + '/'+ splitDate[1] +'/' +  changeYear;
          console.log("datestring;", dateString);
          return dateString;
      }else {
        return '';
      }
    }

    const changeDateFinishFormat = (date: any) => {
      if (date){
        console.log("dateFormat", date);
          let splitDate = date.split('/');
          let changeYear;
          if(parseInt(splitDate[2]) <= 2022){
            changeYear = parseInt(splitDate[2]) + 543;
          }else{
            changeYear = splitDate[2];
          }
          let month = parseInt(splitDate[1]);
          let day = parseInt(splitDate[0]);
          let dayString = "";
          if(month === 4 || month === 6 || month === 9 || month === 11 ){
            if(day === 30){
              day = 2;
            }else if(day === 29){
              day = 1;
            }
            else {
              day = day + 2;
            }
          }else if( month === 2) {
            if(day === 28 || day === 29){
              day = 2;
            }else if( day === 27){
              day = 1;
            }else{
              day = day + 2;
            }
          }else{
            if(day === 31){
              day = 2;
            }else if( day === 30){
              day = 1;
            }
            else {
              day = day + 2;
            }
          }

          if (day <10){
            dayString = "0"+ day;
          }else{
            dayString = day.toString();
          }


          let dateString = dayString + '/'+ splitDate[1] +'/' +  changeYear;
          console.log("datestring;", dateString);
          return dateString;
      }else {
        return '';
      }
    }

    const setValFirst = (k: any, v: any) => {
      // let newV: any = { ...formFirst };
      let newValue: any = { ...dischargeDataForm}
      // newV[k] = v;
      newValue[k] = v;
      // setFormFirst(newV);
      setDischargeDataForm(newValue);
    };

    const handleChange_is_xray = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIs_xray((event.target as HTMLInputElement).value);
    };

    const handleChange_is_normal = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIs_normal((event.target as HTMLInputElement).value);
    };

    const handleChange_is_rapid_test = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIs_rapid_test((event.target as HTMLInputElement).value);
      setIs_rtpcr_test("");
    };

    const handleChange_is_rtpcr_test = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIs_rtpcr_test((event.target as HTMLInputElement).value);
      setIs_rapid_test("");
    };

    const onCapturePNG = (event: React.MouseEvent<HTMLButtonElement>) =>{
      setShowButton(false);
      event.preventDefault();
      const full_name = event.currentTarget.value;  
        htmlToImage.toPng(document.getElementById("savePNG") as HTMLElement)
          .then(function (dataUrl) {
          // saveAs(dataUrl,  'Pateint_Care_Record.png');
              var link = document.createElement('a');
              link.download = 'my-image-name.jpeg';
              const pdf = new jsPDF();
              const imgProps= pdf.getImageProperties(dataUrl);
              const pdfWidth = pdf.internal.pageSize.getWidth();
              const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
              console.log("pdfHeight:", pdfHeight, imgProps.height);
              pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight, undefined,'FAST');
              // pdf.addImage(dataUrl, 'PNG', 13, 0, 185 , 297, undefined,'FAST');


                  if(localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23'){
                    pdf.autoPrint({variant: 'non-conform'});
                    pdf.save(full_name + ".pdf");
                  }else{
                    pdf.addPage();
                    if( show_image_IdCard_data === true && show_image_atk_data === true ) {
                      htmlToImage.toPng(document.getElementById("saveImagePNG_ATK") as HTMLElement)
                      .then(function (dataUrl) {
                        // btn_close
                      // saveAs(dataUrl,  'Pateint_Care_Record.png');
                          var link = document.createElement('a');
                          link.download = 'my-image-name.jpeg';
                          const imgProps= pdf.getImageProperties(dataUrl);
                          const pdfWidth = pdf.internal.pageSize.getWidth();
                          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                          console.log("pdfHeight:", pdfHeight, imgProps.height);
                          pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight, undefined,'FAST');
                          pdf.addPage();
                      });
                      htmlToImage.toPng(document.getElementById("saveImagePNG_IDcard") as HTMLElement)
                      .then(function (dataUrl) {
                        var link = document.createElement('a');
                        link.download = 'my-image-name.jpeg';
                        const imgProps= pdf.getImageProperties(dataUrl);
                        const pdfWidth = pdf.internal.pageSize.getWidth();
                        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                        console.log("pdfHeight:", pdfHeight, imgProps.height);
                        pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight, undefined,'FAST');
                        pdf.autoPrint({variant: 'non-conform'});
                        pdf.save(full_name + ".pdf"); 
                      });
                    }else if(show_image_IdCard_data === true && show_image_atk_data === false){
                      htmlToImage.toPng(document.getElementById("saveImagePNG_IDcard") as HTMLElement)
                      .then(function (dataUrl) {
                        var link = document.createElement('a');
                        link.download = 'my-image-name.jpeg';
                        const imgProps= pdf.getImageProperties(dataUrl);
                        const pdfWidth = pdf.internal.pageSize.getWidth();
                        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                        console.log("pdfHeight:", pdfHeight, imgProps.height);
                        pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight, undefined,'FAST');
                        pdf.autoPrint({variant: 'non-conform'});
                        pdf.save(full_name + ".pdf"); 
                      });
                    }else if(show_image_IdCard_data === false && show_image_atk_data === true){
                      htmlToImage.toPng(document.getElementById("saveImagePNG_ATK") as HTMLElement)
                      .then(function (dataUrl) {
                        var link = document.createElement('a');
                        link.download = 'my-image-name.jpeg';
                        const imgProps= pdf.getImageProperties(dataUrl);
                        const pdfWidth = pdf.internal.pageSize.getWidth();
                        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                        console.log("pdfHeight:", pdfHeight, imgProps.height);
                        pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight, undefined,'FAST');
                        pdf.autoPrint({variant: 'non-conform'});
                        pdf.save(full_name + ".pdf"); 
                      });
                    }else{
                      pdf.autoPrint({variant: 'non-conform'});
                      pdf.save(full_name + ".pdf");
                    }
                  }
                  
                    
              // });
          });
      // }
      
    }

    return(
        <MuiThemeProvider theme={theme}>
            <>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 5,
                    backgroundColor: "#fff",
                  }} id="savePNG"
                >
                    <br/>
                    <Box display={{ sm: "none", xs: "none", md: "block" }} style={{fontFamily: 'Poppins', fontWeight: 400, fontSize: '16px'}}>
                      <Typography gutterBottom>
                        <Box>
                          <Grid container style={{paddingTop: '10px'}}>
                              <Grid item xs={12} style={{fontFamily: 'Poppins', fontSize: '28px', textAlign: 'center'}}>

                                แบบบันทึกการดูแลรักษาผู้ป่วย Covid-19 กรณี OP With Self Isolation
                              </Grid>
                          </Grid>
                        </Box>
                        <Box
                          fontWeight="bold"
                          textAlign="center"
                          lineHeight={"56px"}
                          fontSize={20}
                        >
                        </Box>
                        <Box
                          fontWeight="bold"
                          textAlign="center"
                          mb={3}
                          css={{ color: "#14AF5C" }}
                        >
                        </Box>
                      </Typography>
                  </Box>

                  <Box style={{ fontFamily: 'Poppins', fontSize: '16px', width: "100%", fontWeight: 400}}>
                    <Box style={{ width: "100%" }} p={2}>
                        <Grid container spacing= {3}>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}>
                                <label style={{fontFamily: 'Poppins', fontWeight: 400}}>หมายเลข HN</label>
                                <br/>
                                    <TextField
                                    id="margin-none"
                                    inputProps={{fontFamily: 'Poppins'}}
                                    style={{fontFamily: 'Poppins', fontSize: '16px', paddingRight: '10px'}}
                                    type="text"
                                    fullWidth
                                    value={ hn_number } 
                                    name="hn_number" 
                                    onChange={(event) =>{
                                        setHn_number(event.target.value);
                                        setValFirst(event.target.name, event.target.value);
                                        }
                                    } 
                                    />
                            </Grid>
                            <Grid item xs={3}>
                                <label style={{fontFamily: 'Poppins', fontWeight: 400}}>Authen Code</label>
                                    <br/>
                                    <TextField
                                        id="margin-none"
                                        style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                        type="text"
                                        fullWidth
                                        // value={ authen_code || authen_code_api } 
                                        value={ authen_code } 
                                        name="authen_code"
                                        onChange={(event) =>{
                                            setAuthen_code(event.target.value);
                                            setValFirst(event.target.name, event.target.value);
                                        }
                                        } 
                                    />
                            </Grid>
                        </Grid>
                        {/* <br/> */}

                        <Grid container style={{paddingTop:'15px'}}>
                          <Grid item xs= {3} style={{paddingTop:'8px', paddingLeft:'5px', paddingRight : '10px'}}>
                            <label style={{fontFamily: 'Poppins', fontWeight: 400}}>ชื่อหน่วยบริการ</label>
                            <br/>
                              <TextField
                                  id="margin-none"
                                  style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                  type="text"
                                  fullWidth
                                  // value={ inspection_unit || result_data?.user?.inspection_unit } 
                                  value={ inspection_unit } 
                                  name="inspection_unit"
                                  onChange={(event) =>{
                                    setInspection_unit(event.target.value);
                                    setValFirst(event.target.name, event.target.value);
                                  }
                                } 
                              />
                          </Grid>
                          <Grid item xs= {3} style={{paddingTop:'8px', paddingLeft:'5px', paddingRight : '10px'}}>
                            <label style={{fontFamily: 'Poppins', fontWeight: 400}}>รหัสหน่วยบริการ</label>
                            <br/>
                              <TextField
                                  id="margin-none"
                                  style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                  type="text"
                                  fullWidth
                                  name="inspection_unit_code"
                                  // value={ inspection_unit_code || inspection_unit_code_api } 
                                  value={ inspection_unit_code } 
                                  onChange={(event) =>{
                                    setInspection_unit_code(event.target.value);
                                    setValFirst(event.target.name, event.target.value);
                                  }
                                } 
                              />
                          </Grid>
                          <Grid item xs= {3} style={{paddingTop:'8px', paddingLeft:'5px', paddingRight : '10px'}}>
                            <label style={{fontFamily: 'Poppins', fontWeight: 400}}>วันที่รับบริการ</label>
                            <br/>
                              <TextField
                                  id="margin-none"
                                  style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                  type="text"
                                  fullWidth
                                  // value={date_admitted || date_admitted_api} 
                                  value={date_admitted } 
                                  name="date_admitted"
                                  onChange={(event) =>{
                                      setDate_admitted(event.target.value);
                                      setValFirst(event.target.name, event.target.value);
                                    }
                                  } 
                                  
                              />
                          </Grid>
                          <Grid item xs= {3} style={{paddingTop:'8px', paddingLeft:'5px', paddingRight : '10px'}}>
                          
                          <label style={{fontFamily: 'Poppins', fontWeight: 400}}>วันที่จําหน่าย</label>
                            <br/>
                              <TextField
                                  id="margin-none"
                                  style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                  type="text"
                                  fullWidth
                                  // value={ date_finished || date_finished_api } 
                                  value={ date_finished } 
                                  name="date_finished"
                                  onChange={(event) =>{
                                      setDate_finished(event.target.value);
                                      setValFirst(event.target.name, event.target.value);
                                    }
                                  } 
                                  
                              />
                          </Grid>

                        </Grid>
                        {/* <br/> */}

                        <Grid container style={{paddingTop:'15px'}}> 
                            <Grid item xs={1}>
                              <label style={{fontFamily: 'Poppins', fontWeight: 400}}>คำนำหน้า</label>
                              <br/>
                                <TextField
                                    id="margin-none"
                                    style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                    type="text"
                                    // value={ title_name || title_name_api } 
                                    value={ title_name } 
                                    name="title_name"
                                    onChange={(event) =>{
                                      setTitle_name(event.target.value);
                                      setValFirst(event.target.name, event.target.value);
                                    }
                                  } 
                                />
                            </Grid>
                            <Grid item xs={2} css={{paddingLeft: '15px'}}>
                              <label style={{fontFamily: 'Poppins', fontWeight: 400}}>ชื่อ</label>
                              <br/>
                                <TextField
                                    id="margin-none"
                                    style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                    type="text"
                                    value={ name } 
                                    name="name"
                                    onChange={(event) =>{
                                      setName(event.target.value);
                                      setValFirst(event.target.name, event.target.value);
                                    }
                                  } 
                                />
                            </Grid>

                            <Grid item xs={2}>
                            <label style={{fontFamily: 'Poppins', fontWeight: 400}}>นามสกุล</label>
                                <br/>
                                  <TextField
                                      id="margin-none"
                                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                      type="text"
                                      value={ surname } 
                                      // value={ surname || surname_api } 
                                      name="surname"
                                      onChange={(event) =>{
                                        setSurname(event.target.value);
                                        setValFirst(event.target.name, event.target.value);
                                      }
                                    }  
                                  />
                            </Grid>

                            <Grid item xs={2}>
                                <label style={{fontFamily: 'Poppins', fontWeight: 400}}>บัตรประชาชน</label>
                                <br/>
                                  <TextField
                                      id="margin-none"
                                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                      type="text"
                                      // value={ pid || pid_api } 
                                      value={ pid } 
                                      name="pid"
                                      onChange={(event) =>{
                                        setPid(event.target.value);
                                        setValFirst(event.target.name, event.target.value);
                                      }
                                    }   
                                  />
                            </Grid>
                            
                            <Grid item xs={2}>
                              <label style={{fontFamily: 'Poppins', fontWeight: 400}}>อายุ</label>
                                <br/>
                                  <TextField
                                      id="margin-none"
                                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                      type="text"
                                      value={ age } 
                                      name="age" 
                                      onChange={(event) =>{
                                        setAge(event.target.value);
                                        setValFirst(event.target.name, event.target.value);
                                      }
                                    }   
                                  />
                            </Grid>
                            <Grid item xs={2}>
                              <label style={{fontSize: '16px', fontWeight: 400}}>เพศ</label>
                                <br/>
                                  <TextField
                                      id="margin-none"
                                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                      type="text"
                                      name = "gender"
                                      value={ gender } 
                                      onChange={(event) =>{
                                        setGender(event.target.value);
                                        setValFirst(event.target.name, event.target.value);
                                      }
                                    } 
                                  />
                            </Grid>
                            <Grid item xs={1} style={{paddingRight:'10px'}}>
                              <label style={{fontSize: '16px', fontWeight: 400}}>สิทธิการรักษา</label>
                                <br/>
                                  <TextField
                                      id="margin-none"
                                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                      type="text"
                                      fullWidth
                                      // value={ right_a || right_a_api } 
                                      value={ right_a } 
                                      name="right_a"
                                      onChange={(event) =>{
                                        setRight_a(event.target.value);
                                        setValFirst(event.target.name, event.target.value);
                                      }
                                    } 
                                  />
                            </Grid>
                            

                        </Grid>
                        {/* <br/> */}
                        
                        <Grid container style={{paddingTop:'15px'}}>
                            <Grid item xs = {3} style={{paddingRight:'20px'}}>
                              <label style={{fontFamily: 'Poppins', fontWeight: 400}}>เบอร์โทร</label>
                                <br/>
                                  <TextField
                                      id="margin-none"
                                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                      type="text"
                                      fullWidth
                                      // value={ phone_number || result_data?.user?.phone_number } 
                                      value={ phone_number } 
                                      name="phone_number"
                                      onChange={(event) =>{
                                        setPhone_number(event.target.value);
                                        setValFirst(event.target.name, event.target.value);
                                      }
                                    } 
                                  />
                            </Grid>
                            <Grid item xs = {3} style={{paddingRight:'20px'}}>
                              <label style={{fontFamily: 'Poppins', fontWeight: 400}}>วันเดือนปีเกิด</label>
                                <br/>
                                  <TextField
                                      id="margin-none"
                                      style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                      type="text"
                                      fullWidth
                                      // value={ date_of_birth || moment(date_of_birth_api).add(543, 'year').format('DD/MM/YYYY') } 
                                      value={ date_of_birth } 

                                      name="date_of_birth"
                                      onChange={(event) =>{
                                        setDate_of_birth(event.target.value);
                                        setValFirst(event.target.name, event.target.value);
                                      }
                                    } 
                                  />
                            </Grid>
                            <Grid item xs = {6} style={{paddingRight:'20px'}}>
                            <label style={{fontFamily: 'Poppins', fontWeight: 400}}>ที่อยู่ปัจจุบัน</label>
                              <br/>
                                <TextField
                                    id="margin-none"
                                    style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                    type="text"
                                    fullWidth
                                    // value={ address || address_api } 
                                    value={ address } 
                                    name="address"
                                    onChange={(event) =>{
                                      setAddress(event.target.value);
                                      setValFirst(event.target.name, event.target.value);
                                    }
                                  }  
                                />
                          </Grid>
                        </Grid>
                        {/* <br/> */}

                        {/* <Grid container>
                          
                        </Grid>
                        <br/> */}

                        <Grid container style={{paddingTop:'15px'}}>
                          <Grid item xs = {6} style={{paddingRight:'20px'}}>
                            <label style={{fontFamily: 'Poppins', fontWeight: 400}}>อาการสําคัญ</label>
                              <br/>
                                <TextField
                                    id="margin-none"
                                    style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                    type="text"
                                    fullWidth
                                    // value={ symptom || symptom_api }
                                    value={ symptom } 
                                    name="symptom"
                                    onChange={(event) =>{
                                      setSymptom(event.target.value);
                                      setValFirst(event.target.name, event.target.value);
                                    }
                                  } 
                                />
                          </Grid>

                          <Grid item xs = {6} style={{paddingRight:'20px'}}>
                            <label style={{fontFamily: 'Poppins', fontWeight: 400}}>ผู้บันทึกข้อมูล (พยาบาล/แพทย์)</label>
                              <br/>
                                <TextField
                                    id="margin-none"
                                    style={{fontFamily: 'Poppins', fontSize: '16px'}}
                                    type="text"
                                    fullWidth
                                    // value={ recorder || recorder_api } 
                                    value={ recorder } 
                                    name="recorder"
                                    onChange={(event) =>{
                                      setRecorder(event.target.value);
                                      setValFirst(event.target.name, event.target.value);
                                    }
                                  }  
                                />
                          </Grid>
                      </Grid>

                      <br/>

                      {/* <Grid container>
                        <Grid item xs={12}> */}
                          <Table  style={{border: '1px solid grey', boderBottom: '1px solid grey'}}>
                            <Tbody>
                                <Tr>
                                  <Th className={classes.tableRightBorder} style={{ padding: '20px' }}>
                                    การซักประวัติเพื่ออาการแรกรับ
                                  </Th>
                                  <Th className={classes.tableRightBorder} style={{ padding: '20px' }}>
                                    ตรวจร่างกายแรกรับ
                                  </Th>
                                  <Th className={classes.tableRightBorder} style={{ padding: '20px' }} colspan={2}>
                                    คําสั่งการรักษา
                                  </Th>
                                </Tr>

                                <Tr>
                                <Td className={classes.tableRightBorder} style={{ padding: '10px' }} width={400}>
                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ age_60 } 
                                            name="risk_factor_age"
                                            onChange={(event) =>{
                                                set_age_60(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                            
                                          />
                                            อายุมากกว่า 60 ปี
                                          <br/>
                                          
                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ respiratory_disease } 
                                            name="respiratory_disease"
                                            onChange={(event) =>{
                                                set_respiratory_disease(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                            
                                          />
                                          โรคระบบทางเดินหายใจ
                                          <br/>
                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ ckd }  
                                            name="ckd"
                                            onChange={(event) =>{
                                                setCkd(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                          />
                                            โรคหลอดเลือดสมอง
                                          <br/>
                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ cardiovascular_diseases } 
                                            name="cardiovascular_diseases"
                                            onChange={(event) =>{
                                                set_cardiovascular_diseases(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                          />
                                          โรคหัวใจและหลอดเลือด

                                          <br/>
                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={cancer} 
                                            name="cancer"
                                            onChange={(event) =>{
                                                set_cancer(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                          />
                                         โรคมะเร็ง
                                          <br/>
                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ diabetes } 
                                            name="diabetes"
                                            onChange={(event) =>{
                                                set_diabetes(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                          />
                                          โรคเบาหวาน
                                          <br/>
                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ bmi } 
                                            name="bmi"
                                            onChange={(event) =>{
                                                setBmi(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                            
                                          />
                                          {"(โรคอ้วน BMI > 30 or BW > 90 kg)"}
                                          <br/>
                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ cirrhosis}  
                                            name="cirrhosis"
                                            onChange={(event) =>{
                                                setCirrhosis(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                            
                                          />
                                          CKD (โรคไตวายเรื้อรัง)
                                          <br/>
                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ pregnant }  
                                            name="pregnant"
                                            onChange={(event) =>{
                                                set_pregnant(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                            
                                          />
                                          หญิงตั้งครรภ์ 12 สัปดาห์ขึ้นไป
                                          <br/>
                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ absolute_lymphocyte}  
                                            name="absolute_lymphocyte"
                                            onChange={(event) =>{
                                                setAbsolute_lymphocyte(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                            
                                          />
                                          อื่น ๆ
                                          <br/>

                                          <br/>
                                          
                                          <Box  style={{ textAlign: 'center', paddingBottom: '15px', borderTop: '1px solid', paddingTop: '15px' }}>
                                              ปัญหาและการวินิจฉัยอื่น ๆ
                                          </Box>
                                          
                                          <TextareaAutosize
                                          style={{width: '100%', border: 'none', 
                                            backgroundAttachment: 'local',
                                            backgroundImage: 'linear-gradient(to right, white 10px, transparent 10px), linear-gradient(to left, white 10px, transparent 10px), repeating-linear-gradient(white, white 30px, #ccc 30px, #ccc 31px, white 31px)',
                                            lineHeight: '31px',
                                            paddingLeft: "10px"
                                          }}
                                          rowsMin={3}
                                          value={problems_diagnoses}
                                          aria-label="problems_diagnoses"
                                          name= "problems_diagnoses"
                                          onChange={(event) => {
                                            set_problems_diagnoses(event.target.value);
                                            setValFirst(event.target.name, event.target.value);
                                          }}
                                        />

                                          <br/>

                                          <br/>

                                          <Box  style={{ textAlign: 'center', paddingBottom: '15px', borderTop: '1px solid', paddingTop: '15px' }}>
                                              Plan
                                          </Box>

                                          <TextareaAutosize
                                          style={{width: '100%', border: 'none', 
                                            backgroundAttachment: 'local',
                                            backgroundImage: 'linear-gradient(to right, white 10px, transparent 10px), linear-gradient(to left, white 10px, transparent 10px), repeating-linear-gradient(white, white 30px, #ccc 30px, #ccc 31px, white 31px)',
                                            lineHeight: '31px',
                                            paddingLeft: "10px"
                                          }}
                                          rowsMin={3}
                                          aria-label="plan"
                                          value={plan}
                                          name="plan"
                                          onChange={(event) =>{
                                            set_plan(event.target.value);
                                            setValFirst(event.target.name, event.target.value);
                                          }}
                                          />
                                  </Td>
                                  <Td className={classes.tableRightBorder} style={{ padding: '10px' }}>
                                      <Grid container>
                                        <Grid item xs={6}> 
                                        <label>น้ําหนัก(Kg)</label>
                                            <TextField
                                                id="margin-none"
                                                type="text"
                                                fullWidth
                                                style={{ paddingRight: '25px'}}
                                                value={ weight }
                                                name="weight"
                                                onChange={(event) =>{
                                                    setWeight(event.target.value);
                                                    setValFirst(event.target.name, event.target.value);
                                                  }
                                                } 
                                            />
                                            
                                        </Grid>
                                        <Grid item xs={6}> 
                                        <label>ส่วนสูง(cm)</label>
                                            <TextField
                                                id="margin-none"
                                                type="text"
                                                fullWidth
                                                style={{ paddingRight: '15px'}}
                                                value = { height }
                                                // value={ height || height_api }
                                                name="height"
                                                onChange={(event) =>{
                                                    setHeight(event.target.value);
                                                    setValFirst(event.target.name, event.target.value);
                                                  }
                                                } 
                                            />
                                            
                                        </Grid>
                                      </Grid>
                                      <Grid container>
                                        <Grid item xs={6}> 
                                        <label>BT(⁰ C)</label>
                                            <TextField
                                                id="margin-none"
                                                type="text"
                                                fullWidth
                                                style={{ paddingRight: '25px'}}
                                                value={ body_temp_first }
                                                // value={ body_temp_first || body_temp_api }
                                                name="body_temp"
                                                onChange={(event) =>{
                                                    setBody_temp_first(event.target.value);
                                                    setValFirst(event.target.name, event.target.value);
                                                  }
                                                } 
                                                
                                                
                                            />
                                            
                                        </Grid>
                                        
                                        <Grid item xs={6}> 
                                        <label>PR(/min)</label>
                                            <TextField
                                                id="margin-none"
                                                type="text"
                                                fullWidth
                                                style={{ paddingRight: '15px'}}
                                                value={ pulse_first }
                                                // value={ pulse_first || pulse_api }
                                                name="pulse"
                                                onChange={(event) =>{
                                                    setPulse_first(event.target.value);
                                                    setValFirst(event.target.name, event.target.value);
                                                  }
                                                } 
                                                
                                                
                                            />
                                            
                                        </Grid>
                                      </Grid>

                                      <Grid container>
                                        <Grid item xs={6}> 
                                        <label>RR(/min)</label>
                                          <br/>
                                            <TextField
                                                id="margin-none"
                                                type="text"
                                                fullWidth
                                                style={{ paddingRight: '25px'}}
                                                value={ respiration_rate_first  }
                                                // value={ respiration_rate_first || respiration_rate_api }
                                                name="respiration_rate"
                                                onChange={(event) =>{
                                                    setRespiration_rate_first(event.target.value);
                                                    setValFirst(event.target.name, event.target.value);
                                                  }
                                                } 
                                            />
                                            
                                        </Grid>
                                        
                                        <Grid item xs={6}> 
                                        <label>BP(mmHg)</label>
                                            <TextField
                                              id="margin-none"
                                              type="text"
                                              fullWidth
                                              style={{ paddingRight: '15px'}}
                                              value={ blood_pressure }
                                              name="blood_pressure"
                                              onChange={(event) =>{
                                                  setBlood_pressure(event.target.value);
                                                  setValFirst(event.target.name, event.target.value);
                                                }
                                              } 
                                            />
                                            
                                        </Grid>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <Grid item xs={12}> 
                                        <label style={{fontWeight: 400, }}>ประจําเดือนครั้งสุดท้าย (LPM)</label>
                                          <br/>
                                            {/* <TextField
                                                id="margin-none"
                                                type="text"
                                                fullWidth
                                                style={{ paddingRight: '15px'}}
                                                value={ lmp }
                                                name="lmp"
                                                onChange={(event) =>{
                                                    setLmp(event.target.value);
                                                    setValFirst(event.target.name, event.target.value);
                                                  }
                                                }  
                                            /> */}
                                            <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                                            <KeyboardDatePicker
                                              style={{
                                                margin: 0,
                                                width: "100%",
                                                borderWidth: 0,
                                                fontSize: 13,
                                                paddingRight: '20px'
                                              }}
                                              disableToolbar
                                              variant="inline"
                                              format="dd/MM/yyyy"
                                              margin="normal"
                                              id="from"
                                              // label="Date Start"
                                              autoOk={true}
                                              value={lmp}
                                              onChange={(date: MaterialUiPickersDate) => {
                                                setLmp(date ? new Date(date.toString()) : null);
                                                setValFirst("lmp", moment(date ? new Date(date.toString()) : null).add('543','year').format('DD/MM/YYYY'));
                                              }}
                                              KeyboardButtonProps={{
                                                "aria-label": "change date",
                                              }}
                                              yearOffset={543}
                                              keyboardIcon={ showButton ? <CalendarTodayIcon/> : <></>}

                                            />
                                          </MuiPickersUtilsProvider>
                                            
                                        </Grid>
                                      </Grid>

                                      <br/>
                                      <Box  style={{ textAlign: 'center', paddingBottom: '15px', borderTop: '1px solid', paddingTop: '15px' }}>
                                        ผล Lab
                                      </Box>
                                      <Grid container> 
                                        <Grid item xs={12}>
                                            <span style={{paddingLeft:'10px'}}>Chest X-ray : </span>
                                            <RadioGroup style={{flexDirection: 'inherit'}} aria-label="is_xray" name="is_xray" value={is_xray} onChange={handleChange_is_xray}>
                                              <FormControlLabel value="true" control={<Radio color="primary" size="small" />} label="ใช่" />
                                              <FormControlLabel value="false" control={<Radio color="primary" size="small" />} label="ไม่ใช่" />
                                            </RadioGroup>
                                          </Grid>
                                        </Grid>

                                        {(is_xray == "true" ? 
                                          <Grid container> 
                                            <Grid item xs={12}>
                                              <span style={{paddingLeft:'10px'}}>ถ้ามีผล : </span>
                                              <RadioGroup style={{flexDirection: 'inherit'}} aria-label="is_normal" name="is_normal" value={is_normal} onChange={handleChange_is_normal}>
                                                <FormControlLabel value="true" control={<Radio color="primary" size="small" />} label="ปกติ" />
                                                <FormControlLabel value="false" control={<Radio color="primary" size="small" />} label="ผิดปกติ" />
                                              </RadioGroup>
                                            </Grid>
                                            {(is_normal == "false" ? 
                                                <Grid item xs={12}> 
                                                <label style={{fontSize: '16px', fontWeight: 400, }}>ระบุอาการ</label>
                                                <br/>
                                                  <TextField
                                                    id="margin-none"
                                                    type="text"
                                                    fullWidth
                                                    name="result"
                                                    style={{ paddingRight: '15px'}}
                                                    value={ result }
                                                    onChange={(event) =>{
                                                        setResult(event.target.value);
                                                        setValFirst(event.target.name, event.target.value);
                                                      }
                                                    } 
                                                  />
                                                  
                                                </Grid> : ""
                                              )}
                                          </Grid> 
                                          : ""
                                        )}
                                        <br/>
                                      <Box  style={{ textAlign: 'center', paddingBottom: '15px', borderTop: '1px solid', paddingTop: '15px' }}>
                                        ผลตรวจคัดกรอง
                                      </Box>
                                        <>
                                            <RadioGroup style={{flexDirection: 'inherit'}} aria-label="is_rapid_test" name="is_rapid_test" value={is_rapid_test} onChange={handleChange_is_rapid_test}>
                                              <FormControlLabel value="true" control={<Radio color="primary" size="small" />} label="ATK" />
                                            </RadioGroup>
                                        </>
                                        {(is_rapid_test == "true" ? 
                                          <Grid container>
                                          <Grid item xs={12}> 
                                          <label style={{fontSize: '16px', fontWeight: 400}}>วันที่ตรวจ</label>
                                            <br/>
                                            {/* <TextField
                                                id="margin-none"
                                                type="text"
                                                fullWidth
                                                style={{ paddingRight: '25px'}}
                                                // name="rapid_date"
                                                name="date_result"
                                                value = { date_result }
                                                // value={ (rapid_date == "" ? date_admitted_api : rapid_date) }
                                                onChange={(event) =>{
                                                  setDate_result(event.target.value);
                                                    // setRapid_date(event.target.value);
                                                    setValFirst(event.target.name, event.target.value);
                                                  }
                                                } 
                                              /> */}
                                              <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                                                <KeyboardDatePicker
                                                  style={{
                                                    margin: 0,
                                                    width: "100%",
                                                    borderWidth: 0,
                                                    fontSize: 13,
                                                    paddingRight: '25px'
                                                  }}
                                                  disableToolbar
                                                  variant="inline"
                                                  format="dd/MM/yyyy"
                                                  margin="normal"
                                                  id="from"
                                                  // label="Date Start"
                                                  autoOk={true}
                                                  value={date_result}
                                                  onChange={(date: MaterialUiPickersDate) => {
                                                    setDate_result(date ? new Date(date.toString()) : null);
                                                    // console.log('date_result:', date? new Date(date?.toString()) : null , );
                                                    setValFirst("date_result", moment(date ? new Date(date.toString()) : null).add('543','year').format('DD/MM/YYYY'));
                                                  }}
                                                  KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                  }}
                                                  yearOffset={543}
                                                  keyboardIcon={ showButton ? <CalendarTodayIcon/> : <></>}

                                                />
                                              </MuiPickersUtilsProvider>
                                              
                                          </Grid>
                                          
                                          <Grid item xs={12}> 
                                          <label style={{fontSize: '16px', fontWeight: 400, }}>หน่วยที่คัดกรอง</label>
                                            <br/>
                                              <TextField
                                                id="margin-none"
                                                type="text"
                                                fullWidth
                                                style={{ paddingRight: '25px'}}
                                                name="rapid_result"
                                                value={ rapid_result }
                                                onChange={(event) =>{
                                                    setRapid_result(event.target.value);
                                                    setValFirst(event.target.name, event.target.value);
                                                  }
                                                } 
                                              />
                                              
                                          </Grid>
                                          </Grid> : ""
                                        )}
                                    
                                      {/* <br/> */}
                                      <RadioGroup aria-label="is_rtpcr_test" name="is_rtpcr_test" value={is_rtpcr_test} onChange={handleChange_is_rtpcr_test}>
                                        <FormControlLabel value="true" control={<Radio color="primary" size="small" />} label="RT-PCR" />
                                      </RadioGroup>

                                      {(is_rtpcr_test == "true" ? 
                                        <Grid container>
                                          <Grid item xs={12}> 
                                            <label style={{fontSize: '16px', fontWeight: 400}}>ผลตรวจ</label>
                                              
                                                <TextField
                                                  id="margin-none"
                                                  type="text"
                                                  fullWidth
                                                  style={{ paddingRight: '25px'}}
                                                  name="rtpcr_result"
                                                  value={ rtpcr_result }
                                                  onChange={(event) =>{
                                                      setRtpcr_result(event.target.value);
                                                      setValFirst(event.target.name, event.target.value);
                                                    }
                                                  } 
                                                />
                                                
                                            </Grid>
                                            <Grid item xs={12}> 
                                            <label style={{fontSize: '16px', fontWeight: 400}}>วันที่ตรวจ</label>
                                              
                                                {/* <TextField
                                                    id="margin-none"
                                                    type="text"
                                                    fullWidth
                                                    style={{ paddingRight: '25px'}}
                                                    // name="rtpcr_date"
                                                    name="date_result"
                                                    value = { date_result }
                                                    // value={ (rtpcr_date == "" ? date_admitted_api : rapid_date) }
                                                    onChange={(event) =>{
                                                      setDate_result(event.target.value);
                                                        // setRtpcr_date(event.target.value);
                                                        setValFirst(event.target.name, event.target.value);
                                                      }
                                                    } 
                                                /> */}

                                                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                                                <KeyboardDatePicker
                                                  style={{
                                                    margin: 0,
                                                    width: "100%",
                                                    borderWidth: 0,
                                                    fontSize: 13,
                                                    paddingRight: '25px'
                                                  }}
                                                  disableToolbar
                                                  variant="inline"
                                                  format="dd/MM/yyyy"
                                                  margin="normal"
                                                  id="from"
                                                  // label="Date Start"
                                                  autoOk={true}
                                                  value={date_result}
                                                  onChange={(date: MaterialUiPickersDate) => {
                                                    setDate_result(date ? new Date(date.toString()) : null);
                                                    setValFirst("date_result", moment(date ? new Date(date.toString()) : null).add('543','year').format('DD/MM/YYYY'));
                                                  }}
                                                  KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                  }}
                                                  yearOffset={543}
                                                  keyboardIcon={ showButton ? <CalendarTodayIcon/> : <></>}

                                                />
                                              </MuiPickersUtilsProvider>
                                                
                                            </Grid>
                                            
                                            <Grid item xs={12}> 
                                            <label style={{fontSize: '16px', fontWeight: 400, }}>หน่วยที่คัดกรอง</label>
                                              
                                                <TextField
                                                  id="margin-none"
                                                  type="text"
                                                  fullWidth
                                                  style={{ paddingRight: '25px'}}
                                                  name="rtpcr_place"
                                                    value={ rtpcr_place }
                                                    onChange={(event) =>{
                                                        setRtpcr_place(event.target.value);
                                                        setValFirst(event.target.name, event.target.value);
                                                      }
                                                    } 
                                                    
                                                />
                                                
                                            </Grid>
                                          </Grid> : ""
                                      )}
                                      <br/>
                                      <br/>
                                  </Td>
                                  <Td className={classes.tableRightBorder} style={{ padding: '10px' }} colspan={2}>
                                  <Checkbox  
                                      style={{padding: "4px"}}
                                      color="primary"
                                      size="small"
                                      checked={paracetamol}  
                                      name = "paracetamol" 
                                      onChange={(event) => {
                                        setParacetamol(!paracetamol);
                                        setValFirst(event.target.name, event.target.checked);
                                      }}
                                    />
                                    Paracetamol (500) 1 tab po prn q 6 hrs for fever # 20 tabs
                                    <br/>
                                    <Checkbox  
                                      style={{padding: "4px"}}
                                      color="primary"
                                      size="small"
                                      checked={ceterine} 
                                      name ="cetirizine"
                                      onChange={(event)=>{
                                        setCetrine(!ceterine);
                                        setValFirst(event.target.name, event.target.checked);
                                      }}
                                    />
                                    Cetirizine 1 tab po OD pc #10 tabs

                                    <br/>
                                    <Checkbox  
                                      style={{padding: "4px"}}
                                      color="primary"
                                      size="small"
                                      checked={bromhexine}  
                                      name="bromhexine"
                                      onChange={(event)=>{
                                        setBromhexine(!bromhexine);
                                        setValFirst(event.target.name, event.target.checked);
                                      }}
                                    />
                                    Bromhexine (8 mg) 1 tab po tid pc #10 tabs
                                    <br/>
                                    <Checkbox  
                                      style={{padding: "4px"}}
                                      color="primary"
                                      size="small"
                                      checked={CPM}  
                                      name="cpm" 
                                      onChange={(event)=>{
                                        setCPM(!CPM);
                                        setValFirst(event.target.name, event.target.checked);
                                      }}
                                    />
                                    CPM 1 tab po tid pc # 10 tabs

                                    <br/>
                                    {/* <Checkbox  
                                      style={{padding: "4px"}}
                                      color="primary"
                                      size="small"
                                      checked={(result_data?.treatment_orders?.level_one?.dextromethorphan == true ? true : false)}   
                                      
                                    />
                                    Dextromethorphan 1 tab po tid pc # 20 tabs
                                    <br/> */}

                                    <Checkbox  
                                      style={{padding: "4px"}}
                                      color="primary"
                                      size="small"
                                      checked={indian_gooseberry}
                                      name="indian_gooseberry"
                                      onChange={(event)=>{
                                        set_indian_gooseberry(!indian_gooseberry)
                                        setValFirst(event.target.name, event.target.checked);
                                      }}
                                    />
                                    ยาแก้ไอมะขามป้อม
                                    <br/>
                      
                                    <Grid item xs={12}> 
                                      <Checkbox  
                                        style={{padding: "4px"}}
                                        color="primary"
                                        size="small"
                                        checked={is_other_medicine}
                                        name="is_patient_medicines"
                                        onChange={(event) =>{
                                          set_is_other_medicine(!is_other_medicine);
                                          setValFirst(event.target.name, event.target.checked);
                                        }}
                                      />
                                      ยาคนไข้ที่จําเป็นต้องส่ังเพิ่ม
                                      <br/>
                                      {
                                        is_other_medicine ? 
                                        <>
                                          <label style={{fontSize: '16px', fontWeight: 400, }}>ระบุยาคนไข้ที่จําเป็นต้องส่ังเพื่ม</label>
                                          <br/>
                                            <TextField
                                                id="margin-none"
                                                type="text"
                                                fullWidth
                                                style={{ paddingRight: '15px'}}
                                                value={other_medicine}
                                                name="patient_medicines"
                                                onChange={(event) =>{
                                                    set_other_medicine(event.target.value);
                                                    setValFirst(event.target.name,event.target.value);
                                                  }
                                                } 
                                                
                                            />
                                        </>
                                        :
                                        <></>
                                      }
                                      
                                      </Grid>
                                    <br/>
                                    
                                    <Box  style={{ textAlign: 'center', paddingBottom: '15px', borderTop: '1px solid', paddingTop: '15px' }}>
                                      ผลการพิจารณาเบื้องต้น
                                    </Box>
                                    <Radio  
                                      color="primary"
                                      size="small"
                                      checked = {self_isolation}
                                      onChange={()=> {
                                        set_self_isolation(!self_isolation)
                                      }}
                                    />
                                      OP With Self Isolation

                                    <br/>

                                    <Radio  
                                      color="primary"
                                      size="small"
                                      checked={is_further_hospital} 
                                      onChange={()=> {
                                        set_is_further_hospital(!is_further_hospital)
                                      }}
                                    />
                                      ส่งตรวจเพิ่มเติมที่โรงพยาบาล

                                    <br/>

                                    {(is_further_hospital ? 
                                      <Box style={{ paddingLeft: "15px" }}>
                                        <Radio  
                                          color="primary"
                                          size="small"
                                          checked={is_hi_hospital} 
                                        />
                                          Home Isolation โดยโรงพยาบาล

                                        <br/>

                                        <Radio  
                                          color="primary"
                                          size="small"
                                          checked={is_ci} 
                                        />
                                        Community Isolation โดยโรงพยาบาล

                                        <br/>

                                        <Radio  
                                          color="primary"
                                          size="small"
                                          checked={is_admit} 
                                        />
                                        Admit
                                        <br/>
                                      </Box> : ""
                                    )}
                                    <Radio  
                                        color="primary"
                                        size="small"
                                        checked={is_not_participate} 
                                        onChange={(event) =>{
                                            set_is_not_participate(event?.target.checked);
                                            setValFirst(event.target.name, event.target.checked);
                                          }
                                        } name="is_not_participate" 
                                        // inputProps={{ 'aria-label': 'checkbox with small size' }}
                                      />
                                      ไม่เข้าร่วมโปรแกรม
                                      <br/>

                                      <Box id="imageATKPNG">
                                        {
                                          swapImage ? 
                                          <>
                                            {
                                              result_image_IdCard_data ? 
                                              <>
                                                {
                                                  cropIdCardData === "#" ?
                                                    <Box style={{display: "flex", justifyContent: "center", paddingTop: "20px"}}>
                                                      <img style={{height: "200px"}} src={URL.createObjectURL(result_image_IdCard_data)} />
                                                    </Box>
                                                    :
                                                    <Box style={{display: "flex", justifyContent: "center", paddingTop: "20px"}}>
                                                        <img style={{height: "200px"}} src={cropIdCardData} />
                                                      </Box>
                                                }            
                                                <br/>
                                                {
                                                  showButton ? 
                                                  <Box pb={2} style={{textAlign: 'center'}}>
                                                      <Grid container>
                                                        <Grid item xs={12}>
                                                            <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                              onClick={()=> {
                                                                setIsCropIdCard(true);
                                                              }}
                                                            > Crop </Button>
                                                            <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                              onClick={()=> {
                                                                setSwapImage(false);
                                                              }}>
                                                              Swap Image
                                                            </Button>
                                                            <CropIdCardImageModal
                                                              isCropIdCard = {isCropIdCard}
                                                              setIsCropIdCard = {setIsCropIdCard}
                                                              imageData = {result_image_IdCard_data}
                                                              setCropIdCardData = {setCropIdCardData}
                                                              cropData = {cropIdCardData}
                                                            />
                                                        </Grid>
                                                      </Grid>
                                                  </Box>
                                                  
                                                  
                                                  :
                                                  <></>
                                                }
                                                
                                              </>
                                              :
                                              <></>
                                            }
                                          </>
                                          :
                                          <>
                                            {(result_image_atk_data ? 
                                              <>
                                              {
                                                cropData === "#" ?
                                                  <Box style={{display: "flex", justifyContent: "center", paddingTop: "10px"}}>
                                                      <img style={{height: "200px"}} src={URL.createObjectURL(result_image_atk_data)} />
                                                  </Box> 
                                                  :
                                                  <Box style={{display: "flex", justifyContent: "center", paddingTop: "10px"}}>
                                                      <img style={{height: "200px"}} src={cropData} />
                                                  </Box> 
                                              }
                                                  
                                                  <br/>
                                                  {
                                                    showButton ? 
                                                    <Box pb={2} style={{textAlign: 'center'}}>
                                                        <Grid container>
                                                          <Grid item xs={12}>
                                                              <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                                onClick={()=> {
                                                                  setIsAtkCrop(true);
                                                                }}
                                                              > Crop </Button>
                                                              <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                              onClick={()=> {
                                                                setSwapImage(true);
                                                              }}>
                                                              Swap Image
                                                            </Button>
                                                          </Grid>
                                                        </Grid>
                                                    </Box>
                                                    :
                                                    <></>
                                                  }
                                                  

                                                  <CropImageModal
                                                      isAtkCrop = {isAtkCrop} 
                                                      setIsAtkCrop = {setIsAtkCrop}
                                                      set_image_atk_data = {set_image_atk_data}
                                                      imageData = {result_image_atk_data}
                                                      setCropData = {setCropData}
                                                      cropData = {cropData}
                                                      onClick={async () => {   
                                                                                            
                                                          setIsConfirm(false);
                                                      }}
                                                    />
                                                    
                                              </>
                                                
                                                : ""
                                              )}
                                          </>
                                        }
                                        
                                        
                                      </Box>
                                  </Td>
                                  
                                </Tr>
                            </Tbody>
                          </Table>
                          <br/>
                          <Table  style={{border: '1px solid grey', boderBottom: '1px solid grey', width: '98%'}} >
                            <Tbody>
                                <Tr>
                                  <Th colspan={2} className={classes.tableRightBorder} style={{ padding: '10px' }}>
                                    <span style={{fontWeight: 400, fontSize: '18px'}}>การติดตามประเมินอาการ</span> <u style={{fontSize: '18px'}}>  เมื่อครบ 48 ชั่วโมง </u>
                                    <br/>
                                    
                                     <span style={{fontWeight: 400}}>วันที่</span>
                                    <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                                      <KeyboardDatePicker
                                        style={{
                                          margin: 0,
                                          width: '35%',
                                          borderWidth: 0,
                                          fontSize: 13,
                                          paddingRight: '25px',
                                          paddingLeft: '10px'
                                        }}
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="from"
                                        // label="Date Start"
                                        autoOk={true}
                                        value={check_date}
                                        onChange={(date: MaterialUiPickersDate) => {
                                          set_check_date(date ? new Date(date.toString()) : null);
                                          setValFirst("check_date", date);
                                        }}
                                        KeyboardButtonProps={{
                                          "aria-label": "change date",
                                        }}
                                        yearOffset={543}
                                        keyboardIcon={ showButton ? <CalendarTodayIcon/> : <></>}
                                      />
                                    </MuiPickersUtilsProvider>
                                    <span style={{fontWeight: 400}}>เวลา </span>
                                      {/* <TextField
                                        id="time"
                                        type="time"
                                        value ={check_date_time}
                                        onChange={(event) => {
                                            console.log("time changes:", event.target.value);
                                            set_check_date_time(event.target.value);
                                        }}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        inputProps={{
                                        step: 900
                                        }}
                                        style={{paddingLeft: '10px'}}
                                    /> */}
                                    <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                                            <KeyboardTimePicker
                                              style={{
                                                margin: 0,
                                                width: "25%",
                                                borderWidth: 0,
                                                fontSize: 13,
                                                paddingRight: '20px'
                                              }}
                                              disableToolbar
                                              variant="inline"
                                              format="HH:mm"
                                              margin="normal"
                                              id="check_date_time"
                                              // label="Date Start"
                                              autoOk={true}
                                              value={check_date_time}
                                              onChange={(date: MaterialUiPickersDate) => {
                                                set_check_date_time(date ? new Date(date.toString()) : null);
                                                setValFirst("check_time", date);
                                              }}
                                              KeyboardButtonProps={{
                                                "aria-label": "change date",
                                              }}
                                              yearOffset={543}
                                              keyboardIcon={ showButton ? <ScheduleIcon/> : <></>}
                                            />
                                    </MuiPickersUtilsProvider>
                                  </Th>
                                  <Th colspan={2} className={classes.tableRightBorder} style={{ padding: '10px' }}>
                                  <span style={{fontWeight: 400, fontSize: '18px'}}>การติดตามประเมินอาการ</span> <u style={{fontSize: '18px'}}> เมื่อเกิน 48 ชั่วโมง </u>
                                  <br/>
                                    
                                     <span style={{fontWeight: 400}}>วันที่</span>
                                    <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                                      <KeyboardDatePicker
                                        style={{
                                          margin: 0,
                                          width: '35%',
                                          borderWidth: 0,
                                          fontSize: 13,
                                          paddingRight: '25px',
                                          paddingLeft: '10px'
                                        }}
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="from"
                                        // label="Date Start"
                                        autoOk={true}
                                        value={after_check_date}
                                        onChange={(date: MaterialUiPickersDate) => {
                                          set_after_check_date(date ? new Date(date.toString()) : null);
                                          setValFirst("after_check_date", date);
                                        }}
                                        KeyboardButtonProps={{
                                          "aria-label": "change date",
                                        }}
                                        yearOffset={543}
                                        keyboardIcon={ showButton ? <CalendarTodayIcon/> : <></>}
                                      />
                                    </MuiPickersUtilsProvider>
                                    <span style={{fontWeight: 400}}>เวลา </span>
                                      {/* <TextField
                                        id="time"
                                        type="time"
                                        value={after_check_date_time}
                                        onChange={(date: MaterialUiPickersDate) => {
                                            // console.log("time changes:", event.target.value);
                                            set_after_check_date(date ? timeString(new Date(date.toString())) : null);
                                        }}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                        inputProps={{
                                        step: 900
                                        }}
                                        style={{paddingLeft: '10px'}}
                                    /> */}
                                    <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                                            <KeyboardTimePicker
                                              style={{
                                                margin: 0,
                                                width: "25%",
                                                borderWidth: 0,
                                                fontSize: 13,
                                                paddingRight: '20px'
                                              }}
                                              disableToolbar
                                              variant="inline"
                                              format="HH:mm"
                                              margin="normal"
                                              id="from"
                                              // label="Date Start"
                                              autoOk={true}
                                              value={after_check_date_time}
                                              onChange={(date: MaterialUiPickersDate) => {
                                                set_after_check_date_time(date ? new Date(date.toString()) : null);
                                                setValFirst("after_check_time", date);
                                              }}
                                              KeyboardButtonProps={{
                                                "aria-label": "change date",
                                              }}
                                              yearOffset={543}
                                              keyboardIcon={ showButton ? <ScheduleIcon/> : <></>}
                                            />
                                    </MuiPickersUtilsProvider>
                                  </Th>
                                </Tr>
                                <Tr>
                                  <Th className={classes.tableRightBorder} style={{ padding: '10px' }}>
                                    อาการแทรกซ้อน
                                  </Th>
                                  <Th className={classes.tableRightBorder} style={{ padding: '10px' }}>
                                    การดูแลรักษา
                                  </Th>
                                  <Th className={classes.tableRightBorder} style={{ padding: '10px' }}>
                                    อาการแทรกซ้อน
                                  </Th>
                                  <Th className={classes.tableRightBorder} style={{ padding: '10px' }}>
                                    การดูแลรักษา
                                  </Th>
                                </Tr>
                                <Tr>
                                   <Td className={classes.tableRightBorder} style={{ padding: '10px' }}>
                                        <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ cough_before } 
                                            name="cough_before"
                                            onChange={(event) =>{
                                                set_cough_before(event?.target.checked);
                                                setValFirst("cough_before", event.target.checked);
                                              }
                                            }
                                            
                                          />
                                            ไอ
                                          <br/>

                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ tired_before } 
                                            name="tired_before"
                                            onChange={(event) =>{
                                                set_tired_before(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                            
                                          />
                                            เหนื่อย
                                          <br/>

                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ fever_before } 
                                            name="fever_before"
                                            onChange={(event) =>{
                                                set_fever_before(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                            
                                          />
                                            ไข้
                                          <br/>

                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ chest_pain_before } 
                                            name="chest_pain_before"
                                            onChange={(event) =>{
                                                set_chest_pain_before(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                            
                                          />
                                            เจ็บหน้าอก
                                          <br/>

                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ rest_o2_before } 
                                            name="rest_o2_before"
                                            onChange={(event) =>{
                                                set_rest_o2_before(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                            
                                          />
                                            {"Resting O2 sat <- 94"}
                                          <br/>

                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ other_before } 
                                            name="other_before"
                                            onChange={(event) =>{
                                                set_other_before(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                            
                                          />
                                            
                                            อื่น ๆ
                                          
                                          <br/>

                                          {
                                            other_before ? 
                                            <>
                                              <br/>
                                                <TextField
                                                    id="margin-none"
                                                    type="text"
                                                    fullWidth
                                                    style={{ paddingRight: '15px'}}
                                                    value={other_ans_before}
                                                    name="other_before_ans_before"
                                                    onChange={(event) =>{
                                                        set_other_ans_before(event.target.value);
                                                        setValFirst(event.target.name,event.target.value);
                                                      }
                                                    } 
                                                    
                                                />
                                            </>
                                            :
                                            <></>
                                          }
                                   </Td>
                                   <Td className={classes.tableRightBorder} style={{ padding: '10px' }}>
                                       <TextareaAutosize
                                          style={{width: '100%', border: 'none', 
                                            backgroundAttachment: 'local',
                                            backgroundImage: 'linear-gradient(to right, white 10px, transparent 10px), linear-gradient(to left, white 10px, transparent 10px), repeating-linear-gradient(white, white 30px, #ccc 30px, #ccc 31px, white 31px)',
                                            lineHeight: '31px',
                                            paddingLeft: "10px"
                                          }}
                                          rowsMin={4}
                                          aria-label="plan"
                                          value={treatment_before}
                                          name="treatment_before"
                                          onChange={(event)=> {
                                            set_treatment_before(event.target.value);
                                            setValFirst(event.target.name,event.target.value);
                                          }}
                                        />
                                   </Td>
                                   <Td className={classes.tableRightBorder} style={{ padding: '10px' }}>
                                   <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ cough_after } 
                                            name="cough_after"
                                            onChange={(event) =>{
                                                set_cough_after(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                            
                                          />
                                            ไอ
                                          <br/>

                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ tired_after } 
                                            name="tired_after"
                                            onChange={(event) =>{
                                                set_tired_after(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                            
                                          />
                                            เหนื่อย
                                          <br/>

                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ fever_after } 
                                            name="fever_after"
                                            onChange={(event) =>{
                                                set_fever_after(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                            
                                          />
                                            ไข้
                                          <br/>

                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ chest_pain_after } 
                                            name="chest_pain_after"
                                            onChange={(event) =>{
                                                set_chest_pain_after(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                            
                                          />
                                            เจ็บหน้าอก
                                          <br/>

                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ rest_o2_after } 
                                            name="rest_o2_after"
                                            onChange={(event) =>{
                                                set_rest_o2_after(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                            
                                          />
                                            {"Resting O2 sat <- 94"}
                                          <br/>

                                          <Checkbox  
                                            style={{padding: "4px"}}
                                            color="primary"
                                            size="small"
                                            checked={ other_after } 
                                            name="other_after"
                                            onChange={(event) =>{
                                                set_other_after(event?.target.checked);
                                                setValFirst(event.target.name, event.target.checked);
                                              }
                                            }
                                            
                                          />
                                            
                                            อื่น ๆ
                                          
                                          <br/>
                                          {
                                            other_after ? 
                                            <>
                                              <br/>
                                                <TextField
                                                    id="margin-none"
                                                    type="text"
                                                    fullWidth
                                                    style={{ paddingRight: '15px'}}
                                                    value={other_ans_after}
                                                    name="other_after_ans_after"
                                                    onChange={(event) =>{
                                                        set_other_ans_after(event.target.value);
                                                        setValFirst(event.target.name,event.target.value);
                                                      }
                                                    } 
                                                    
                                                />
                                            </>
                                            :
                                            <></>
                                          }
                                   </Td>
                                   <Td className={classes.tableRightBorder} style={{ padding: '10px' }}>
                                        <TextareaAutosize
                                          style={{width: '100%', border: 'none', 
                                            backgroundAttachment: 'local',
                                            backgroundImage: 'linear-gradient(to right, white 10px, transparent 10px), linear-gradient(to left, white 10px, transparent 10px), repeating-linear-gradient(white, white 30px, #ccc 30px, #ccc 31px, white 31px)',
                                            lineHeight: '31px',
                                            paddingLeft: "10px"
                                          }}
                                          rowsMin={4}
                                          aria-label="plan"
                                          value={treatment_after}
                                          name="treatment_after"
                                          onChange={(event)=> {
                                            set_treatment_after(event.target.value);
                                            setValFirst(event.target.name,event.target.value);
                                          }}
                                        />

                                   </Td>

                                </Tr>
                                <Tr >
                                  <Td className={classes.tableRightBorder} style={{ padding: '10px' }} colspan={2}>
                                     <p style={{textAlign: 'center'}}> การส่งต่อ </p>
                                  </Td>
                                  <Td className={classes.tableRightBorder} style={{ padding: '10px' }} rowspan={2}>
                                    ลงชื่อแพทย์ผู้รักษา
                                    <br/>
                                    <img src={ signature } alt="" width="15%" style={{ display: "inline-flex", marginBottom: '-43px', width:'15vw', marginLeft: '26%'}}/>
                                    <TextField
                                        id="margin-none"
                                        type="text"
                                        style={{ marginRight: '10px', width:'100%' }}
                                        name="sign_physician" 
                                    />
                                    <br/>
                                    <TextField
                                        id="margin-none"
                                        type="text"
                                        style={{ marginRight: '10px', width: '100%' }}
                                        name="sign_physician_name"
                                        value= {"(นพ. บรรพต ปานเคลือบ)"}
                                        inputProps={{ style: {
                                          marginLeft: '30%'
                                        }}}
                                    />
                                    <br/>
                                    <br/>
                                    <Grid item xs={12}>
                                    เลขใบอนุญาตประกอบวิชาชีพ
                                      <br/>
                                    <TextField
                                        id="margin-none"
                                        type="text"
                                        style={{ marginRight: '10px', width: '100%' }}
                                        name="license_number"
                                        value= {"ว30208"}
                                        inputProps={{ style: {
                                           textAlign: 'center'
                                        }}} 
                                    />
                                    </Grid>
                                   

                                  </Td>
                                  <Td className={classes.tableRightBorder} style={{ padding: '10px' }} rowspan={2}>
                                      
                                    ลงชื่อพยาบาลผู้รักษา
                                    <br/>
                                    <br/>
                                    <TextField
                                        id="margin-none"
                                        type="text"
                                        style={{ marginRight: '10px', width:'100%' }}
                                        name="sign_physician" 
                                    />
                                    <br/>
                                    <TextField
                                        id="margin-none"
                                        type="text"
                                        style={{ marginRight: '10px', width: '100%' }}
                                        name="sign_physician" 
                                    />
                                    <br/>
                                    <br/>
                                    <Grid item xs={12}>
                                    เลขใบอนุญาตประกอบวิชาชีพ
                                    <br/>
                                    <TextField
                                        id="margin-none"
                                        type="text"
                                        style={{ marginRight: '10px', width: '100%' }}
                                        name="license_number" 
                                    />
                                    </Grid>
                                    
                                  </Td>
                                </Tr>
                                <Tr>
                                  <Td className={classes.tableRightBorder} style={{ padding: '10px' }} colspan={2}>
                                    Refer ไปยัง :
                                    <br/>
                                    <TextField
                                        id="margin-none"
                                        type="text"
                                        style={{ marginRight: '10px', width: '100%' }}
                                        name="hospital" 
                                        value={hospital}
                                        onChange={(event) => {
                                          set_hospital(event.target.value);
                                          setValFirst(event.target.name,event.target.value);
                                        }}
                                    />

                                    <br/>
                                    <br/>
                                    ส่งตัวเพื่อ
                                    <br/>
                                    <TextField
                                        id="margin-none"
                                        type="text"
                                        style={{ marginRight: '10px', width: '100%' }}
                                        // placeholder = "กรอกข้อมูล"
                                        name="refer_for" 
                                        value={refer_for}
                                        onChange={(event) => {
                                          set_refer_for(event.target.value);
                                          setValFirst(event.target.name,event.target.value);
                                        }}
                                    />
                                    <br/>
                                    <br/>
                                    สาเหตุที่ส่ง
                                    <br/>
                                    <TextField
                                        id="margin-none"
                                        type="text"
                                        style={{ marginRight: '10px', width: '100%' }}
                                        // placeholder = "กรอกข้อมูล"
                                        name="refer_cause" 
                                        value={refer_cause}
                                        onChange={(event) => {
                                          set_refer_cause(event.target.value);
                                          setValFirst(event.target.name,event.target.value);
                                        }}
                                    />
                                  </Td>
                                </Tr>
                            </Tbody>
                          </Table>
                        {/* </Grid>
                      </Grid> */}
                    </Box>
                  </Box>
                </Box>
                {
                  localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                  <></>
                  :
                  <>
                      <Box>
                        <br/>
                        {(show_image_atk_data ? 
                        <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          flex: 1,
                          minHeight: "100%",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 5,
                          backgroundColor: "#fff",
                        }}  
                        id="saveImagePNG_ATK">
                        <br/>
                        <Grid container spacing={2} style={{padding: '20px', border: '1px solid', width: '95.5%', margin: '30px'}}>
                            <Grid item xs = {8} >
                              <p style={{fontSize: '20px', fontWeight: 'bold'}}>
                                  ชื่อ-นามสกุล : { name + ' ' + surname}
                              </p>
                                
                            </Grid>
                            <Grid item xs = {2}>
                            <label style={{fontFamily: 'Poppins', fontWeight: 400}}>หมายเลข HN</label>
                            <br/>
                              <TextField
                                id="margin-none"
                                type="text"
                                fullWidth
                                value={ hn_number } 
                                onChange={(event) =>{
                                  setHn_number(event.target.value);
                                  // setVal(event.target.name, event.target.value);
                                }
                              } 
                              />
                            </Grid>
                            <Grid item xs = {2}>
                            <label style={{fontFamily: 'Poppins', fontWeight: 400}}>Authen Code</label>
                            <br/>
                              <TextField
                                id="margin-none"
                                type="text"
                                fullWidth
                                value={ authen_code  } 
                                onChange={(event) =>{
                                  setAuthen_code(event.target.value);
                                  // setVal(event.target.name, event.target.value);
                                }
                              } 
                              />
                            </Grid>
                            <br/>
                            <br/>
                        </Grid>
                          <Box>
                            {(result_image_atk_data ? 
                              <>
                              {
                                show_image_atk_data ? 
                                <>
                                  {
                                    cropData === "#" ?
                                    <Box style={{display: "flex", justifyContent: "center", paddingTop: "50px"}}>
                                        <img style={{ width: '80%', height: "80%" }} src={URL.createObjectURL(result_image_atk_data)} />
                                      
                                    </Box>
                                      :
                                      <Box style={{display: "flex", justifyContent: "center", paddingTop: "50px"}}>
                                          <img style={{width:'80%', height: "80%" }} src={cropData} />
                                        </Box>
                                  }
                                                
                                    <br/>
                                    {
                                      showButton ?
                                      <Box pb={2} style={{textAlign: 'center'}}>
                                          <Grid container>
                                          <Grid item xs={4}></Grid>
                                            <Grid item xs={4}>
                                                <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                  onClick={()=> {
                                                    setIsAtkCrop(true);
                                                  }}
                                                > Crop </Button>
                                                <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                onClick={()=> {
                                                  setIsHideAtk(true);
                                                }}
                                              > Hide </Button>
                                            </Grid>
                                            <Grid item xs={4}></Grid>
                                          </Grid> 
                                          <HideAtkPhotoModal
                                              isHideAtk = {isHideAtk}
                                              onClick={()=> {
                                                set_show_image_atk_data(false);
                                              }}
                                              setIsHideAtk = {setIsHideAtk}
                                            />
                                      </Box>
                                      :
                                      <></>
                                    }
                                    <CropImageModal
                                                      isAtkCrop = {isAtkCrop} 
                                                      setIsAtkCrop = {setIsAtkCrop}
                                                      set_image_atk_data = {set_image_atk_data}
                                                      imageData = {result_image_atk_data}
                                                      setCropData = {setCropData}
                                                      cropData = {cropData}
                                                      onClick={async () => {   
                                                                                            
                                                          setIsConfirm(false);
                                                      }}
                                                    />
                                  
                                  <br/>
                                </>
                                :
                                <>
                                    {
                                      show_image_IdCard_data ? 
                                      <>
                                          {
                                              cropIdCardData === "#" ?
                                                <Box style={{display: "flex", justifyContent: "center", paddingTop: "50px"}}>
                                                  <img style={{ width: '80%', height: "70%",margin: '10px'}} src={URL.createObjectURL(result_image_IdCard_data)} />
                                                </Box>
                                                :
                                                <Box style={{display: "flex", justifyContent: "center", paddingTop: "50px"}}>
                                                  <img style={{width: '80%', height: "70%" }} src={cropIdCardData} />
                                                </Box>
                                            }
                                                          
                                              <br/>
                                              {
                                                showButton ?
                                                <Box pb={2} style={{textAlign: 'center'}}>
                                                    <Grid container>
                                                    <Grid item xs={4}></Grid>
                                                      <Grid item xs={4}>
                                                          <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                            onClick={()=> {
                                                              setIsCropIdCard(true);
                                                            }}
                                                          > Crop </Button>

                                                          <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                            onClick={()=> {
                                                              setIsHideIdPhoto(true);
                                                            }}>
                                                            Hide
                                                          </Button>
                                                      </Grid>
                                                      <Grid item xs={4}></Grid>
                                                    </Grid> 
                                                    <HideIdPhotoModal
                                                        isHideIdPhoto = {isHideIdPhoto}
                                                        onClick={()=> {
                                                          set_show_image_IdCard_data(false);
                                                        }}
                                                        setIsHideIdPhoto = {setIsHideIdPhoto}
                                                      />
                                                </Box>
                                                :
                                                <></>
                                              }
                                              
                                        
                                            <br/>
                                            <CropIdCardImageModal
                                              isCropIdCard = {isCropIdCard}
                                              setIsCropIdCard = {setIsCropIdCard}
                                              imageData = {result_image_IdCard_data}
                                              setCropIdCardData = {setCropIdCardData}
                                              cropData = {cropIdCardData}
                                            />
                                        </>
                                      :
                                      <></>
                                    }
                                </>
                              }
                                
                                  
                              </>
                                
                              
                              : ""
                            )}
                          </Box>
                          <br/></Box> : ""
                        
                        )}

                        {(show_image_IdCard_data ? 
                        
                            <Box
                              style={{
                                width: "100%",
                                display: "flex",
                                flex: 1,
                                minHeight: "100%",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 5,
                                backgroundColor: "#fff",
                              }}  
                              id="saveImagePNG_IDcard">
                              <br/>
                              <Grid container spacing={2} style={{padding: '20px', border: '1px solid', width: '95.5%', margin: '30px'}}>
                                  <Grid item xs = {8} >
                                    <p style={{fontSize: '20px', fontWeight: 'bold'}}>
                                        ชื่อ-นามสกุล : { name + ' ' + surname}
                                    </p>
                                      
                                  </Grid>
                                  <Grid item xs = {2}>
                                  <label style={{fontFamily: 'Poppins', fontWeight: 400}}>หมายเลข HN</label>
                                  <br/>
                                    <TextField
                                      id="margin-none"
                                      type="text"
                                      fullWidth
                                      value={ hn_number } 
                                      onChange={(event) =>{
                                        setHn_number(event.target.value);
                                        // setVal(event.target.name, event.target.value);
                                      }
                                    } 
                                    />
                                  </Grid>
                                  <Grid item xs = {2}>
                                  <label style={{fontFamily: 'Poppins', fontWeight: 400}}>Authen Code</label>
                                  <br/>
                                    <TextField
                                      id="margin-none"
                                      type="text"
                                      fullWidth
                                      value={ authen_code } 
                                      onChange={(event) =>{
                                        setAuthen_code(event.target.value);
                                        // setVal(event.target.name, event.target.value);
                                      }
                                    } 
                                    />
                                  </Grid>
                                  <br/>
                                  <br/>
                              </Grid>
                            <br/>
                            <br/>
                            {(result_image_IdCard_data ? 
                            <>
                            {
                              show_image_IdCard_data ?
                              <>
                                {
                                    cropIdCardData === "#" ?
                                      <Box style={{display: "flex", justifyContent: "center", paddingTop: "50px"}}>
                                        <img style={{ width: '80%', height: "70%" }} src={URL.createObjectURL(result_image_IdCard_data)} />
                                      </Box>
                                      :
                                      <Box style={{display: "flex", justifyContent: "center", paddingTop: "50px"}}>
                                          <img style={{width: '80%', height: "70%" }} src={cropIdCardData} />
                                        </Box>
                                  }
                                                
                                    <br/>
                                    {
                                      showButton ?
                                      <Box pb={2} style={{textAlign: 'center' }}>
                                          <Grid container>
                                          {/* <Grid item xs={4}></Grid> */}
                                            <Grid item xs={6} style={{display: 'flex'}}>
                                                <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                  onClick={()=> {
                                                    setIsCropIdCard(true);
                                                  }}
                                                > Crop </Button>

                                                <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                                  onClick={()=> {
                                                    setIsHideIdPhoto(true);
                                                  }}>
                                                  Hide
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}></Grid>
                                          </Grid> 
                                          <HideIdPhotoModal
                                              isHideIdPhoto = {isHideIdPhoto}
                                              onClick={()=> {
                                                set_show_image_IdCard_data(false);
                                              }}
                                              setIsHideIdPhoto = {setIsHideIdPhoto}
                                            />
                                      </Box>
                                      :
                                      <></>
                                    }
                                    
                              
                                  <br/>
                                  <CropIdCardImageModal
                                    isCropIdCard = {isCropIdCard}
                                    setIsCropIdCard = {setIsCropIdCard}
                                    imageData = {result_image_IdCard_data}
                                    setCropIdCardData = {setCropIdCardData}
                                    cropData = {cropIdCardData}
                                  />
                              </>
                              :
                              <></>
                            }
                            </>
                                : ""
                            )}
                            </Box> : ""
                        )}
                            
                          
                          <br/>
                          <br/>
                      </Box>
                  </>
                }

                

                <Box mt={2} pb={2} style={{textAlign: 'center'}}>
                  <Grid container>
                    <Grid item md={4} xs={12}></Grid>
                    <Grid item md={4} xs={12}>
                     
                        {/* <Button value={result_data?.user?.name + " " + result_data?.user?.surname} variant="contained"
                         color="primary" onClick={onCapturePNG} style={{marginRight: '10px'}}> 
                         Download PDF 
                        </Button>  */}
                        {
                          showDownload ?
                          <Button value={result_data?.user?.name + " " + result_data?.user?.surname} variant="contained" color="primary" onClick={onCapturePNG} style={{marginRight: '10px'}}> Download PDF </Button>
                        :
                          <Button  variant="contained" color="primary"
                          onClick={async () => {
                              try {
                                  if(localStorage.projectKey !== 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23') {
                                    fire_updateDischargeData(dischargeDataForm)
                                      .then(() => {
                                        setShowDownload(true);
                                        enqueueSnackbar("Success update discharge data", {
                                          variant: "success",
                                          autoHideDuration: 3000,
                                          anchorOrigin: {
                                            vertical: "top",
                                            horizontal: "center",
                                          },
                                        });
                                      })
                                      .catch((ex) => {
                                        setShowDownload(true);
                                        enqueueSnackbar(
                                          "Error" +
                                            (ex ? " : " + ex.toString() : ""),
                                          {
                                            variant: "error",
                                            autoHideDuration: 3000,
                                            anchorOrigin: {
                                              vertical: "top",
                                              horizontal: "center",
                                            },
                                          }
                                        );
                                      });
                                  }
                                  
                                  
                              } catch (ex) {}
                            
                            }
                            }
                            > 
                            Save 
                          </Button>
                        }   
                    </Grid>
                    <Grid item md={4} xs={12}></Grid>
                  </Grid>

                  
                  
                </Box>
            </>
        </MuiThemeProvider>
    );
}
);