import React, { Fragment, useEffect, useRef, useState,useMemo } from "react";

import {
  Avatar,
  Box,
  Container,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  makeStyles,
  withStyles,
  InputBase,
  Typography,
  Tabs,
  Tab,
  Modal,
  createMuiTheme,
  MuiThemeProvider
} from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import { CardMap, CardMapMemo } from "../Dashboard/CardMap";
import { CardUserList } from "../Dashboard/CardUserList";
import { CardUserListByStatus } from "../Dashboard/CardUserListByStatus";
import { SubBar } from "../Dashboard/Dashboard";
import LuxonUtils from "@date-io/luxon";
import { DateFilter } from "../../components/DateFilter";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { webStore } from "../../stores/webStore";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { Filter, Value, ReloadValue } from "../../types/user";
import AppBar from "@material-ui/core/AppBar";
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from "material-ui-thai-datepickers";
import { Search } from "@material-ui/icons";
import MaterialTable from "material-table";
import tableIcons from "./MaterialTableIcons";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveAlt from "@material-ui/icons/SaveAlt";
import CloseIcon from "@material-ui/icons/Close";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
// import 'moment/locale/en';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import {useDropzone} from 'react-dropzone';
import './Messenger.css';
import { GetAppointmentData } from "../../services/api/telemedicine/BookingService";  
import { dateStr, dateStrAPI,dateString,dateStringFullMothName,dateStrFormatTime,dateStrFormatLogin } from "../../utils";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  
  const focusedStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };


  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: '#303f9f',
      },
    },

  });


const AddApprovedModal = observer(
    ({
      isApproved,
      label,
      onClick,
      onClose,
      setIsApproved,
      setAddApprovedValue,
      buttonNameConfirm = "CONFIRM",
      buttonNameCANCEL = "CANCEL",

    }: {
      isApproved: boolean;
      setIsApproved: any;
      label: string;
      onClick?: any;
      onClose?: any;
      type?: string;
      setAddApprovedValue?: any;
      buttonNameConfirm?: string;
      buttonNameCANCEL?: string;
    }) => {
  
      const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setAddApprovedValue({note: event.target.value as string});
      };
  
      return (
        <Modal
          open={isApproved}
          onClose={() => {}}
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
            <Box
                css={{ backgroundColor: "#fff", width: 400, borderRadius: 30, border: 'solid 2px #3f51b5' }}
                p={2}
            >
                <Box
                    display="flex"
                    justifyContent="right"
                    alignItems="center"
                    mb={1}
                    mt={1}
                >
                <Box onClick={onClose}>
                    <CloseIcon css={{ cursor: "pointer" }} />
                </Box>
            </Box>

            <Box
                display="flex"
                justifyContent="center"
                alignItems="center" 
                css={{ color: "#4B4C56" }}
            >
                <h1 css={{ color: "#0c1679", fontSize: "24px" }}> Update Status</h1>
                
            </Box>
            <Box
                className="mt-3"
                textAlign= "center"

                // display="flex"
                // justifyContent="center"
                // alignItems="center"
            >
                <p css={{ color: "#0c1679", fontSize: "18px" }}>{label}</p> 
                <p className="mt-3" css={{ color: "#4B4C56", fontSize: "18px" }}>Status was updated to "Completed."</p>
                
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt ={2}
                >
                <Button
                    css={{margin: "10px"}}
                    onClick={onClick}
                    color="primary"
                    size="medium"
                    variant="contained"
                >
                    {buttonNameConfirm}
                </Button>

                <Button
                    css={{margin: "10px"}}
                    onClick={onClick}
                    size="medium"
                    variant="contained"
                >
                    {buttonNameCANCEL}
                </Button>
                </Box>
                <br/>
          </Box>
        </Modal>
      );
    }
);

const ExportButton = withStyles({
    root: {
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 16,
      padding: '6px 12px',
      border: '1px solid',
      lineHeight: 1.5,
      backgroundColor: '#0CA789',
      borderColor: '#0CA789',
      '&:hover': {
        backgroundColor: '#0CA789',
        borderColor: '#0CA789',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0CA789',
        borderColor: '#0CA789',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },
})(Button);

function tapsStyles() {
    return {
        indicator: {
        // backgroundColor: "#fff",
        },
    };
}
  
export interface customeLabels {
    data: any;
    labels: any;
    color: any;
    showValue?: boolean;
    itemsCountPerPage?: number;
}

    interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
  
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}
  
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const Finance_recent = observer(() => {
    const history = useHistory();
    const [patient_name, setPatient_name] = useState("");
    const [startDate, setStartDate] = useState<Date | null>(webStore.startDate);
    const [endDate, setEndDate] = useState<Date | null>(webStore.endDate);
    const { result_appointment, loading_appointment } = GetAppointmentData();
    const [ name, setName ] = useState('');
    const [ genderInfo , setGender ] = useState('');
    const [ date, setDate ] = useState('');

    const rando = (max: any) => Math.floor(Math.random() * max);

    const words = ["Paper", "Rock", "Scissors"];
    const gender = ["ชาย", "หญิง"];
    const invoice = "invoice";
    const rawData = [];
        for (let i = 0; i < 100; i++) {
        rawData.push({
            idTable: i+1,
            id: rando(300),
            name: words[i % words.length],
            gender: gender[i % gender.length],
            invoice: "invoice",
            amount: rando(300),
            status: "Waiting for approve"
        });
    }

    const [data, setData] = useState(rawData);

    useEffect(() => {
        if(result_appointment){
            if(result_appointment?.length>0){
                setName(result_appointment[0].patient?.name + " " + result_appointment[0].patient?.surname);
                setGender(result_appointment[0]?.patient?.gender === 'm' ? "Male"
                : result_appointment[0]?.patient?.gender === 'f' ? "Female"
                : "");
                let dateInfo = dateStringFullMothName(result_appointment[0]?.start_time);
                setDate(dateInfo ? dateInfo : "");
            }
        }

        
    }, [result_appointment]);

    return(
        <Box className="p-4">
           <Grid container>
                <Grid item xs={12} md={4}>
                <Grid item xs={12} css={{ padding: "0 5px" }}>
                        {/* <span style={{padding: '10px', fontWeight: 'bold', color: '#3f51b5', fontSize:'22px'}}>
                            Today {" " + dateString(new Date())}
                        </span> */}
                        
                        <Box
                            style={{position: "relative", overflow: "auto", height: '70vh' }}
                            css={{ borderRadius: 5 }}
                            >
                            {(result_appointment || []).map((contactInfo : any, index) => {
                            // {(appointment_data || []).map((contactInfo : any, index) => {
                                
                                return ( 
                                <Box
                                    css={{ backgroundColor: "#fff", 
                                    width: "100%", 
                                    borderRadius: 20, 
                                    border: 'solid 2px #3f51b5',
                                    "&:hover": {
                                        backgroundColor: "rgba(3,169,244,0.08)",
                                        marginTop: '2%'
                                        }
                                    }}
                                    p={2}
                                    mb={1}
                                    mt={1}
                                    display="flex"
                                    onClick={() => {
                                        setName(contactInfo?.patient?.name + " " + contactInfo?.patient?.surname);
                                        setGender(contactInfo?.patient?.gender === 'm' ? "Male"
                                        : contactInfo?.patient?.gender === 'f' ? "Female"
                                        : "");
                                        let dateInfo = dateStringFullMothName(contactInfo?.start_time);
                                        setDate(dateInfo ? dateInfo : "");
                                    }}
                                >
                                    <Box pr={4} pt={1}
                                    >
                                    <Avatar style={{ width: 50, height: 50, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                        {/* <PersonIcon style={{fontSize: '60px'}}/> */}
                                        {/* <img src={contactInfo?.patient?.avartar} alt="" style={{height:'100%'}}/> */}
                                        {
                                            contactInfo?.patient?.avartar ? 
                                            <img src={contactInfo?.patient?.avartar} alt="" style={{height:'100%'}}/>
                                            :
                                            <PersonIcon style={{fontSize: '30px'}}/>
                                        }
                                    </Avatar>
                                    </Box>

                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Box mb={2} position="relative">
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                css={{ color: "#35353F", marginTop:'4px' }}
                                            >
                                                <Box fontSize={15} mr={1}>
                                                <span style={{fontWeight: 'bold'}}> {contactInfo?.patient?.name + " " + contactInfo?.patient?.surname} </span>  
                                                </Box>
                                            </Box>
                                                <Box fontSize={15} mr={1} mt={1}>
                                                  {dateStringFullMothName(contactInfo?.start_time)}
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </Box>
                                );
                            })}
                        </Box>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={8} css={{ padding: "0 5px" }}>
                        <Box fontSize={15} mr={1}>
                            <span style={{fontWeight: 'bold', paddingLeft: '25px'}}> Name: </span>  {name}
                        </Box>
                        <br/>
                        <Box fontSize={15} mr={1}>
                            <span style={{fontWeight: 'bold', paddingLeft: '25px'}}> Gender: </span>  
                            {genderInfo}
                        </Box>
                        <br/>
                        <Box fontSize={15} mr={1}>
                            <span style={{fontWeight: 'bold', paddingLeft: '25px'}}> Date: </span>  {date}
                        </Box>

                        <br/>
                            <p style={{fontWeight: 'bold', textAlign: 'center', fontSize: '20px'}}> Upload Invoices* </p> 
                            <BasicDropZone/>
                            <br/>
                            <Box
                              style={{
                                width: "100%",
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 5,
                                marginTop: '20px'
                              }}
                            >
                              <Button  variant="contained" color="primary"> Submit</Button>
                            </Box>
                </Grid>
            </Grid>
            
        </Box>
    );
});

export const Finance_Wait = observer(() => {
    const history = useHistory();
    const [patient_name, setPatient_name] = useState("");
    const [startDate, setStartDate] = useState<Date | null>(webStore.startDate);
    const [endDate, setEndDate] = useState<Date | null>(webStore.endDate);

    const rando = (max: any) => Math.floor(Math.random() * max);

    const words = ["Paper", "Rock", "Scissors"];
    const gender = ["ชาย", "หญิง"];
    const invoice = "invoice";
    const rawData = [];
        for (let i = 0; i < 100; i++) {
        rawData.push({
            idTable: i+1,
            id: rando(300),
            name: words[i % words.length],
            gender: gender[i % gender.length],
            invoice: "invoice",
            amount: rando(300),
            status: "Waiting for approve"
        });
    }

    const [data, setData] = useState(rawData);

    return(
        <Box className="p-4">
            <Box 
                css={{
                    paddingLeft: "10%", 
                    paddingRight: "10%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <TextField 
                    id="standard-name"
                    value={patient_name} 
                    label="Patient Name"  
                    margin="normal"
                    onChange={(e) => setPatient_name(e.target.value)} 
                    css={{marginLeft: "10px", marginRight: "10px"}}
                />

                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="from"
                    label="Start Date"
                    value={startDate || new Date()}
                    onChange={(date: MaterialUiPickersDate) => {
                        setStartDate(date ? new Date(date.toString()) : null);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    yearOffset={543}
                    css={{marginLeft: "10px", marginRight: "10px"}}
                    />
                </MuiPickersUtilsProvider>
                {/* <TextField 
                    label="-"  
                    margin="normal"
                    css={{marginLeft: "10px", marginRight: "10px"}}
                /> */}
                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="from"
                    label="End Date"
                    value={endDate || new Date()}
                    onChange={(date: MaterialUiPickersDate) => {
                        setEndDate(date ? new Date(date.toString()) : null);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    yearOffset={543}
                    css={{marginLeft: "10px", marginRight: "10px"}}
                    />
                </MuiPickersUtilsProvider> 

                <Button
                    color="primary"
                    onClick={() => {
                        console.log("search");
                    }}
                    css={{
                    padding: 8,
                    minWidth: "unset",
                    boxShadow: "unset",
                    marginRight: '2%'
                    }}
                >
                    <SearchIcon />
                </Button>

                <Button
                    type="submit"
                    onClick={() => {
                        console.log("Clear");
                    }}
                    variant="contained"
                    color="primary"
                    css={{marginLeft: "10px", marginRight: "10px"}}
                >
                    Clear
                </Button>

                <ExportButton
                    type="submit"
                    // style= {{marginLeft: "2%", fontSize : '12px'}}
                    onClick={() => {
                        console.log("ExportButton");
                    }}
                    variant="contained"
                    color="primary" disableRipple 
                    css={{ marginLeft: "10px", marginRight: "10px", color:'#fff' }}
                    // onClick = {excelExport}
                >
                    Export to .xls
                </ExportButton>   

            </Box>
            <Box className="pt-5">
                <MaterialTable
                    icons={tableIcons}
                    columns={[
                        { title: 'No.', field: 'idTable' },
                        { title: 'Name', field: 'name', type: 'string' },
                        { title: 'Gender', field: 'gender', type: 'string' },
                        { title: 'Invoice', field: 'invoice', type: 'string',
                            render: rowData => <span style={{color: '#6371ff'}}><u>invoice.pdf</u></span>
                        },
                        { title: 'Amount', field: 'amount', type: 'string' },
                        { title: 'Status', 
                          field: 'status', 
                          type: 'string',
                          render: rowData => <span style={{color: 'orange'}}>Waiting for Approve</span>
                        },
                    ]}
                    data={data}
                     
                    options={{
                        showTitle: false,
                        search: false
                    }}
                />

            </Box>
        </Box>
    );
});

export const Finance_Approved = observer(() => {
    const history = useHistory();
    const rando = (max: any) => Math.floor(Math.random() * max);

    const words = ["Paper", "Rock", "Scissors"];
    const gender = ["ชาย", "หญิง"];
    const invoice = "invoice";
    const rawData = [];
        for (let i = 0; i < 100; i++) {
        rawData.push({
            idTable: i+1,
            id: rando(300),
            name: words[i % words.length],
            gender: gender[i % gender.length],
            invoice: "invoice",
            amount: rando(300),
            status: "Approved"
        });
    }
    
    const [patient_name, setPatient_name] = useState("");
    const [startDate, setStartDate] = useState<Date | null>(webStore.startDate);
    const [endDate, setEndDate] = useState<Date | null>(webStore.endDate);
    const [data, setData] = useState(rawData);
    const [addApprovedValue, setAddApprovedValue] = useState<any>();
    const [isApproved, setIsApproved] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);

    return(
        <Box className="p-4">
            <Box 
                css={{
                    paddingLeft: "10%", 
                    paddingRight: "10%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <TextField 
                    id="standard-name"
                    value={patient_name} 
                    label="Patient Name"  
                    margin="normal"
                    onChange={(e) => setPatient_name(e.target.value)} 
                    css={{marginLeft: "10px", marginRight: "10px"}}
                />

                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="from"
                    label="Start Date"
                    value={startDate || new Date()}
                    onChange={(date: MaterialUiPickersDate) => {
                        setStartDate(date ? new Date(date.toString()) : null);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    yearOffset={543}
                    css={{marginLeft: "10px", marginRight: "10px"}}
                    />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="from"
                    label="End Date"
                    value={endDate || new Date()}
                    onChange={(date: MaterialUiPickersDate) => {
                        setEndDate(date ? new Date(date.toString()) : null);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    yearOffset={543}
                    css={{marginLeft: "10px", marginRight: "10px"}}
                    />
                </MuiPickersUtilsProvider> 

                <Button
                    color="primary"
                    onClick={() => {
                        console.log("search");
                    }}
                    css={{
                    padding: 8,
                    minWidth: "unset",
                    boxShadow: "unset",
                    marginRight: '2%'
                    }}
                >
                    <SearchIcon />
                </Button>

                <Button
                    type="submit"
                    onClick={() => {
                        console.log("Clear");
                    }}
                    variant="contained"
                    color="primary"
                    css={{marginLeft: "10px", marginRight: "10px"}}
                >
                    Clear
                </Button>

                <ExportButton
                    type="submit"
                    // style= {{marginLeft: "2%", fontSize : '12px'}}
                    onClick={() => {
                        console.log("ExportButton");
                    }}
                    variant="contained"
                    color="primary" disableRipple 
                    css={{ marginLeft: "10px", marginRight: "10px", color:'#fff' }}
                >
                    Export to .xls
                </ExportButton>   

            </Box>
            <Box className="pt-5">
            <MuiThemeProvider theme={theme}>
            <MaterialTable
                    icons={tableIcons}
                    columns={[
                        { title: 'No.', field: 'idTable' },
                        { title: 'Name', field: 'name', type: 'string' },
                        { title: 'Gender', field: 'gender', type: 'date' },
                        { title: 'Invoice', field: 'invoice', type: 'string',
                        render: rowData => <span style={{color: '#6371ff'}}><u>invoice.pdf</u></span>
                        },
                        { title: 'Amount', field: 'amount', type: 'string' },
                        { title: 'Status', 
                          field: 'status', 
                          type: 'string',
                          render: rowData => <span style={{color: '#101fbf'}}>Approved</span>
                        },
                        {
                            title: "Actions",
                            render: rawData => {
                              return (
                                  <IconButton
                                    aria-label="edit"
                                    // disabled={false}
                                    onClick={() => {
                                      setLoading(true);
                                      setIsApproved(rawData);
                                    //   setTimeout(() => {
                                    //     setIsApproved(rawData);
                                    //   }, 1400);
                                    }}
                                  >
                                    <EditIcon />
                                    {/* {loading===true && (
                                      <CircularProgress
                                        size={38}
                                      />
                                    )} */}
                                  </IconButton>
                              );
                            }
                        }
                    ]}
                    data={data}
                     
                    options={{
                        selection: true,
                        showTitle: false,
                        search: false,
                    }}
                    actions={[
                        {
                          tooltip: 'Update Status for selected users',
                          icon: () => <EditIcon />,
                          onClick: (evt, data) => {
                            setLoading(true);
                            setIsApproved(data);
                            //     setTimeout(() => {
                            //     setIsApproved(data);
                            // }, 1400);
                          }

                        },
                        {
                            tooltip: 'Export for selected users',
                            icon: () => <SaveAlt />,
                            onClick: (evt, data) => alert('You want to Exports ' + data + ' rows')
                        },

                    ]}
                />
            </MuiThemeProvider>
                

                <AddApprovedModal
                    {...addApprovedValue}
                    isApproved={isApproved}
                    setIsApproved={setIsApproved}
                    setAddApprovedValue={setAddApprovedValue}
                    label={isApproved?.length > 0 ? isApproved?.length + " Item Selected" : isApproved?.name}
                    onClose={async () => {
                        setIsApproved(false);
                        setLoading(false);
                    }}
                    onClick={async () => {
                    setLoading(true);
                    // fire_createNote(
                    //     addNoteValue.note
                    // )
                    //     .then(({ data }: { data: any }) => {    
                    //     setAddApprovedValue({note: ""});
                    //     enqueueSnackbar(`Create Note Success`, {
                    //         key: "editSuccess",
                    //         variant: "success",
                    //         autoHideDuration: 3000,
                    //         anchorOrigin: {
                    //         vertical: "top",
                    //         horizontal: "right",
                    //         },
                    //     });
                    //     })
                    //     .catch((ex: any) => {
                    //     enqueueSnackbar("Create Note Failed", {
                    //         key: "editError",
                    //         variant: "error",
                    //         autoHideDuration: 3000,
                    //         anchorOrigin: {
                    //         vertical: "top",
                    //         horizontal: "right",
                    //         },
                    //     });
                    // });
                    setIsApproved(false);
                    setLoading(false);
                    
                    }}
                />
            </Box>
        </Box>
    );
});

function randomDate(start: any, end: any) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

export const Finance_Completed = observer(() => {
    const rando = (max: any) => Math.floor(Math.random() * max);

    const words = ["Paper", "Rock", "Scissors"];
    const gender = ["ชาย", "หญิง"];
    const invoice = "invoice";
    const rawData = [];
        for (let i = 0; i < 100; i++) {
        rawData.push({
            idTable: i+1,
            id: rando(300),
            name: words[i % words.length],
            gender: gender[i % gender.length],
            invoice: "invoice",
            amount: rando(300),
            status: "Complete",
            completed_Date: moment(randomDate(new Date(2012, 0, 1), new Date()).toLocaleString()).format('DD MMMM yy')
        });
    }

    const [patient_name, setPatient_name] = useState("");
    const [startDate, setStartDate] = useState<Date | null>(webStore.startDate);
    const [endDate, setEndDate] = useState<Date | null>(webStore.endDate);
    const [data, setData] = useState(rawData);
    const [addApprovedValue, setAddApprovedValue] = useState<any>();
    const [isApproved, setIsApproved] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);

    return(
        <Box className="p-4">
            <Box 
                css={{
                    paddingLeft: "10%", 
                    paddingRight: "10%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                
                <TextField 
                    id="standard-name"
                    value={patient_name} 
                    label="Patient Name"  
                    margin="normal"
                    onChange={(e) => setPatient_name(e.target.value)} 
                    css={{marginLeft: "10px", marginRight: "10px"}}
                />

                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="from"
                    label="Start Date"
                    value={startDate || new Date()}
                    onChange={(date: MaterialUiPickersDate) => {
                        setStartDate(date ? new Date(date.toString()) : null);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    yearOffset={543}
                    css={{marginLeft: "10px", marginRight: "10px"}}
                    />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="from"
                    label="End Date"
                    value={endDate || new Date()}
                    onChange={(date: MaterialUiPickersDate) => {
                        setEndDate(date ? new Date(date.toString()) : null);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    yearOffset={543}
                    css={{marginLeft: "10px", marginRight: "10px"}}
                    />
                </MuiPickersUtilsProvider> 

                <Button
                    color="primary"
                    onClick={() => {
                        console.log("search");
                    }}
                    css={{
                    padding: 8,
                    minWidth: "unset",
                    boxShadow: "unset",
                    marginRight: '2%'
                    }}
                >
                    <SearchIcon />
                </Button>

                <Button
                    type="submit"
                    onClick={() => {
                        console.log("Clear");
                    }}
                    variant="contained"
                    color="primary"
                    css={{marginLeft: "10px", marginRight: "10px"}}
                >
                    Clear
                </Button>

                <ExportButton
                    type="submit"
                    // style= {{marginLeft: "2%", fontSize : '12px'}}
                    onClick={() => {
                        console.log("ExportButton");
                    }}
                    variant="contained"
                    color="primary" disableRipple 
                    css={{ marginLeft: "10px", marginRight: "10px", color:'#fff' }}
                    // onClick = {excelExport}
                >
                    Export to .xls
                </ExportButton>   

            </Box>
            <Box className="pt-5">
                <MuiThemeProvider theme={theme}>
                    <MaterialTable
                        icons={tableIcons}  
                        columns={[
                            { title: 'No.', field: 'idTable' },
                            { title: 'Name', field: 'name', type: 'string' },
                            { title: 'Gender', field: 'gender', type: 'date' },
                            { title: 'Invoice', field: 'invoice', type: 'string' },
                            { title: 'Amount', field: 'amount', type: 'string' },
                            { title: 'Status', field: 'status', type: 'string' },
                            { title: 'Completed Date', field: 'completed_Date', type: 'date' },
                        ]}
                        data={data}
                            
                        options={{
                            selection: true,
                            showTitle: false,
                            search: false
                        }}
                        actions={[
                            {
                            tooltip: 'Action Selected Users',
                            icon: () => <EditIcon />,
                            onClick: (evt, data) => {
                                    setTimeout(() => {
                                    setIsApproved(data);
                                }, 1400);
                            }
                            }

                        ]}
                    />
                </MuiThemeProvider>
            </Box>
            <AddApprovedModal
                {...addApprovedValue}
                isApproved={isApproved}
                setIsApproved={setIsApproved}
                setAddApprovedValue={setAddApprovedValue}
                label={isApproved?.length > 0 ? isApproved?.length + " Item Selected" : isApproved?.name}
                onClose={async () => {
                    setIsApproved(false);
                    setLoading(false);
                }}
                onClick={async () => {
                setLoading(true);
                // fire_createNote(
                //     addNoteValue.note
                // )
                //     .then(({ data }: { data: any }) => {    
                //     setAddApprovedValue({note: ""});
                //     enqueueSnackbar(`Create Note Success`, {
                //         key: "editSuccess",
                //         variant: "success",
                //         autoHideDuration: 3000,
                //         anchorOrigin: {
                //         vertical: "top",
                //         horizontal: "right",
                //         },
                //     });
                //     })
                //     .catch((ex: any) => {
                //     enqueueSnackbar("Create Note Failed", {
                //         key: "editError",
                //         variant: "error",
                //         autoHideDuration: 3000,
                //         anchorOrigin: {
                //         vertical: "top",
                //         horizontal: "right",
                //         },
                //     });
                // });
                setIsApproved(false);
                setLoading(false);
                
                }}
            />
        </Box>
    );
});

const BasicDropZone = (props : any) => {
    const {acceptedFiles, getRootProps, getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone();

    const styleDropZone = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
      }), [
        isFocused,
        isDragAccept,
        isDragReject
      ]);

    
    const files = acceptedFiles.map(file => (
      <li key={file.name}>
        {/* {file.name} - {file.size} bytes */}
        {file.name}
      </li>
    ));
  
    return (
      <section>
        <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>Drag and drop files, or click to select files</p>
        </div>
        <aside>
            {
                files? 
                <>
                    <ul style={{paddingLeft: '25px', color: '#111a79'}}>{files}</ul>
                </>
                :
                <></>
            }
          
        </aside>
      </section>
    );
  }


export const Finance = observer(() => {
  const history = useHistory();

  const [value, setValue] = React.useState(0);
  const [patient_name, setPatient_name] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(webStore.startDate);
  const [endDate, setEndDate] = useState<Date | null>(webStore.endDate);
  const [file, setFile] = useState(null);

  const handleChangeDragDrop = (file :any) => {
    setFile(file);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  
  return(
    <Container maxWidth="xl" className="py-4">
        <Grid container>
            <Grid item xs={12}>
                <Box
                    className="flex justify-between items-center flex-wrap"
                    pt={4}
                    mb={3}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        css={{
                            "@media (max-width: 992px)": {
                            width: "100%",
                            justifyContent: "space-between",
                            },
                        }}
                        mb={{ xs: 3, md: 0 }}
                    >
                        <Box fontSize="h5.fontSize" fontWeight={500} mr={3}>
                            Finance
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <AppBar position="static" css={{backgroundColor: "transparent"}}>
                    <Tabs value={value} onChange={handleChange} css={{color: "#000"}}>
                        <Tab label="Recent" />
                        <Tab label="Waiting for Approve" />
                        <Tab label="Approved" />
                        <Tab label="Completed" />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <Grid item xs={12} css={{border: "1px solid"}}>
                        <Box className="py-4">
                            <Finance_recent/>
                        </Box>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Grid item xs={12} css={{border: "1px solid"}}>
                        <Finance_Wait/>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Grid item xs={12} css={{border: "1px solid"}}>
                        <Finance_Approved/>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Grid item xs={12} css={{border: "1px solid"}}>
                        <Finance_Completed/>
                    </Grid>
                </TabPanel>
            </Grid>
        </Grid>
    </Container>
  );
});
export const MobileFinance = Finance;